<template>
  <div class="container__unit__link">
    <ModalWarning
      ref="modal_warning_save"
      title="warning"
      size="modal__counter_v2"
      identifier="user"
      :confirm="true"
      :cancel="true"
    >
    </ModalWarning>
    <div
      class=""
      v-for="(element, index) in dataMenu"
      :key="index"
      @click="selectRoute(element.id)"
    >
      <div class="container__link__navbar">
        <a class="">
          <ProfileIcon v-if="element.id == '1'" />
          <TenancyIcon v-if="element.id == '2'" />
          <SettingsIcon v-if="element.id == '3'" />
          <!-- <SupportIcon v-if="element.id == '4'" /> -->
          <LogoutIcon v-if="element.id == '5'" />

          <div class="title-container">
            {{ element.title }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LogoutIcon from "../../assets/NavBar_icons/logout.svg";
import ProfileIcon from "../../assets/NavBar_icons/profile.svg";
import SettingsIcon from "../../assets/NavBar_icons/settings.svg";
// import SupportIcon from "../../assets/NavBar_icons/support.svg";
import TenancyIcon from "../../assets/NavBar_icons/tenancy.svg";
import ModalWarning from "@/components/modal/modalSaveWarning.vue";

export default {
  components: {
    LogoutIcon,
    ProfileIcon,
    SettingsIcon,
    // SupportIcon,
    TenancyIcon,
    ModalWarning,
  },

  props: ["dataMenu"],
  computed: {
    ...mapGetters("Login", {
      group: "group",
    }),
    ...mapGetters("Config", {
      inSwitch: "inSwitch",
    }),
    ...mapGetters("SettingsApp", {
      defaultValueLenguageUser: "defaultValueLenguageUser",
      defaultValueDarkMode: "defaultValueDarkMode",
      userDarkModeInstanceChange: "userDarkModeInstanceChange",
    }),
  },

  methods: {
    logOut() {
      this.$store.dispatch("Login/logOut", null).then(() => {
        if (this.inSwitch) {
          this.$store.dispatch("Config/cleanSwitchTenancy");
        }
        this.$router.push({ name: "LoginPage" });
      });
    },
    pushRoute(route, routeParam) {
      // if (route == "/soporte/" && routeParam == "Support") {
      //   return;
      // } else if (routeParam == "Support") {
      //   this.$router.push({ name: routeParam });
      // }
      if (route == "/users/" && routeParam == "user") {
        return;
      } else if (routeParam == "user") {
        this.$router.push({ name: routeParam });
      }
      if (route == "/settings/" && routeParam == "dashboardSettings") {
        return;
      } else if (routeParam == "dashboardSettings") {
        this.$router.push({ name: routeParam });
      }
      if (route == "/companys/" && routeParam == "ListCompanyV2") {
        return;
      } else if (routeParam == "ListCompanyV2") {
        this.$router.push({ name: routeParam });
      }
    },
    selectRoute(param) {
      let route = this.$route.path;
      if (param == "1") {
        let userUrl = "user";
        this.pushRoute(route, userUrl);
      } else if (param == "2") {
        if (this.userDarkModeInstanceChange) {
          let param = "modal_warning_save";
          this.$store.dispatch("Config/setModalRef", param);
          let element = this.$refs.modal_warning_save;
          element.open();
        }
        let companysUrl = "ListCompanyV2";
        this.pushRoute(route, companysUrl);
      }
      //  else if (param == "4") {
      //   if (this.userDarkModeInstanceChange) {
      //     let param = "modal_warning_save";
      //     this.$store.dispatch("Config/setModalRef", param);
      //     let element = this.$refs.modal_warning_save;
      //     element.open();
      //   }
      //   let supportUrl = "Support";
      //   this.pushRoute(route, supportUrl);
      // }
       else if (param == "3") {
        let settingstUrl = "dashboardSettings";
        this.pushRoute(route, settingstUrl);
      } else if (param == "5") {
        this.logOut();
      }
    },
  },
};
</script>

<style lang="scss">
.container__link__navbar {
  display: flex;
  margin: 8px 11px 8px 14px;

  > a {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  > a svg {
    width: 24px;
    height: 24px;
    fill: var(--header__svg__icon);
  }
  > a .title-container {
    margin-top: 0px !important;
    color: var(--base-title-header) !important  ;
  }
}
.container__unit__link {
  display: flex;
  flex-direction: column;
  > div:hover {
    background: var(--header__svg__icon_hover);
  }
  > div:last-child:hover {
    border-radius: 0px 0px 12px 12px;
  }
}
</style>
