<template>
  <div
    class="container__settings__user title-container inner"
    v-if="UserSettingsInstance != null"
  >
    <Modal
      ref="modal"
      title="Guardar cambios"
      :confirm="true"
      :cancel="true"
      size="modal__counter_v2"
      identifier="user"
    >
      <div class="modal__body">
        <div class="modal__user__settings">
          <div class="container__description value-primary">
            Se realizarán cambios en los siguientes parámetros:
          </div>
          <div
            class="container_settings"
            v-if="Languages != this.defaultValueLenguageUser"
          >
            <div class="label-primary">idioma de Aplicación</div>
            <div class="">
              {{ Languages }}
            </div>

          </div>
          <div
            class="container_settings"
            v-if="DarkMode != this.defaultValueDarkMode"
          >
            <div class="label-primary">Modo oscuro</div>
            <div>{{ DarkMode }}</div>
          </div>
        </div>
      </div>
    </Modal>

    <div
      class="container__lenguage m-bottom-l"
      v-for="(el, index) in UserSettingsInstance"
      :key="index"
    >
      <span class="label-primary color__tertiary">{{ el.title }} </span>
      <div class="custom-select">

      <select
        class="input-form"
        @change="selectedOptionForSpecificInstancec(el, $event)"
      >
        <option selected hidden disabled>
          {{ el.defaultValue }}
        </option>

        <option :value="opt" v-for="(opt, index) in el.optionData" :key="index">
          {{ opt }}
        </option>

      </select>
      <span class="custom-arrow-b"></span>
      </div>
    </div>

    <div class="container__footer__settings">
      <div
        :class="
          Languages != this.defaultValueLenguageUser ||
          DarkMode != this.defaultValueDarkMode
            ? 'btn-vue-primary fix-btn-mac color_hover_button_standard'
            : 'desactive_draf_settings fix-btn-mac'
        "
        class=""
        @click="show_modal_save_confirm()"
      >
        <span> Confirmar </span>
      </div>
    </div>
  </div>

  <div class="" v-else>
    <LoaderGeneric class="m-top-xl" />
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import LoaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import DarkModeScript from "@/utils/darkmode.js";
import LightModeScript from "@/utils/lightmode.js";
import { mapGetters } from "vuex";
export default {
  components: {
    Modal,
    LoaderGeneric,
  },
  data: () => {
    return {
      Languages: null,
      DarkMode: null,
    };
  },
  created() {
    if (this.UserSettingsInstance != null) {
      this.Languages = this.defaultValueLenguageUser;
      this.DarkMode = this.defaultValueDarkMode;
    }
  },

  computed: {
    ...mapGetters("SettingsApp", {
      UserSettingsInstance: "UserSettingsInstance",
      defaultValueLenguageUser: "defaultValueLenguageUser",
      defaultValueDarkMode: "defaultValueDarkMode",
    }),
  },
  methods: {
    show_modal_save_confirm() {
      let element = this.$refs.modal;
      element.open();
    },
    selectedOptionForSpecificInstancec(el, e) {
      let params = {
        id: el.id,
        value: e.target.value,
      };
      //--------------------------------
      // @Input: element from array user instance and $event value input
      // Description: set id/value of select/opt selected for saving changes,
      // @Output: dispatch action for persistance values of changes inside user instances, open modal if user leave user instance interface.
      //--------------------------------

      //el.bindReactiveData represent type of user settings instance for use logic specific
      if (el.bindReactiveData == "Languages") {
        // in case fiel bindReactiveData is Lenguaje set values for that

        this.Languages = e.target.value;

        if (e.target.value != this.defaultValueLenguageUser) {
          //if value selected is != to default value set value for change

          this.$store.dispatch(
            "SettingsApp/activeChangeUserLenguajeInstance",
            params
          );
          //set value for template
          this.Languages = e.target.value;
        } else {
          //in case value are equal set false to action set values changes
          this.$store.dispatch(
            "SettingsApp/activeChangeUserLenguajeInstance",
            false
          );
          //in case value are equal to default value set null for template
          this.Languages = e.target.value;
        }
        // in case fiel el.bindReactiveData is darkMode set values for that
      } else if (el.bindReactiveData == "DarkMode") {
        if (e.target.value != this.defaultValueDarkMode) {
          //if value selected is != to default value set value for change

          if (e.target.value == "Desactivado") {
            // apply light mode
            LightModeScript.applyLightmode();
          } else {
            // apply darkmode
            DarkModeScript.applyDarkmoke();
          }

          this.$store.dispatch(
            "SettingsApp/activeChangeUserDarkModeInstance",
            params
          );
          //set value for template
          this.DarkMode = e.target.value;
        } else {

          if (e.target.value == "Desactivado") {
            // apply light mode
            LightModeScript.applyLightmode();
          } else {
            // apply darkmode
            DarkModeScript.applyDarkmoke();
          }

          //in case value are equal set false to action set values changes
          this.$store.dispatch(
            "SettingsApp/activeChangeUserDarkModeInstance",
            false
          );
          //in case value are equal to default value set null for template
          this.DarkMode = e.target.value;
        }
      }
    },
  },
  watch: {
    // TODO: watch values of user setting instance for persiste new default values in case of update
  },
};
</script>

<style lang="scss">
.container__settings__user {
  margin-left: 16px;

  > .container__lenguage,
  .container__dark__mode {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;
    @media screen and (max-width: 500px) and (min-width: 200px) {
        width: 100%;
      }
    > div select {
      // width: 384px;
      height: 44px;
      border: 1px solid #a5a5a5;
      box-sizing: border-box;
      border-radius: 8px;
      color: var(--input_color_placeholder) !important;

      // @media screen and (max-width: 500px) and (min-width: 200px) {
      //   width: 100%;
      // }
    }

    > .label-primary {
      color: var(--title__labels) !important;
    }
  }
}
.container_settings {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.modal__user__settings {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
</style>
