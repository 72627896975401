var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__onboarding__home",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'onboarding'
      ? 'modal__onboarding'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : 'modal__default',attrs:{"id":"modal__onboarding__home"}},[_c('div',{},[_c('div',{},[(_vm.stepHomeOnBoarding == 1)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnBoardingFirstStepHome')],1):_vm._e(),(_vm.stepHomeOnBoarding == 2)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnBoardingSecondStepHome')],1):_vm._e(),(_vm.stepHomeOnBoarding == 3)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnBoardingThirdStepHome')],1):_vm._e(),(_vm.stepHomeOnBoarding == 4)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnboardingFifthStepHome')],1):_vm._e()],1),_c('div',{staticClass:"container_footer_onboarding"},[_c('div',{staticClass:"container_elements_footer"},[_c('div',{staticClass:"footer_modal footer__modal__cancel footer__modal__fix_onboarding"},[(!_vm.automaticValidation)?_c('div',{staticClass:"cancel_button_onboarding",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Omitir")])]):_c('div',{}),_c('div',{class:_vm.stepHomeOnBoarding == 4
                ? 'button_step_fourth '
                : 'fix-btn-mac button_onboarding_accept',on:{"click":function($event){return _vm.AcceptOnBoarding()}}},[(_vm.stepHomeOnBoarding == 1)?_c('span',[_vm._v(" Comenzar ")]):_vm._e(),(
                _vm.stepHomeOnBoarding == 2 ||
                _vm.stepHomeOnBoarding == 3
              )?_c('span',[_vm._v(" Siguiente ")]):_vm._e(),(_vm.stepHomeOnBoarding == 4)?_c('span',[_vm._v(" Ingresar nueva solicitud ")]):_vm._e()])]),_c('div',{class:_vm.automaticValidation
              ? 'container__progressBar__onboarding onbording_container_automatic'
              : 'container__progressBar__onboarding'},[_c('div',{class:_vm.stepHomeOnBoarding == 1
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(1)}}}),_c('div',{class:_vm.stepHomeOnBoarding == 2
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(2)}}}),_c('div',{class:_vm.stepHomeOnBoarding == 3
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(3)}}}),_c('div',{class:_vm.stepHomeOnBoarding == 4
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(4)}}})]),(_vm.automaticValidation)?_c('div',{staticClass:"footer_check_box_modal_on_boarding"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkNoShowOnBoarding),expression:"checkNoShowOnBoarding"}],attrs:{"type":"checkbox","id":"checkbox"},domProps:{"checked":Array.isArray(_vm.checkNoShowOnBoarding)?_vm._i(_vm.checkNoShowOnBoarding,null)>-1:(_vm.checkNoShowOnBoarding)},on:{"change":[function($event){var $$a=_vm.checkNoShowOnBoarding,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkNoShowOnBoarding=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkNoShowOnBoarding=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkNoShowOnBoarding=$$c}},function($event){return _vm.checkedOnBoarding(_vm.checkNoShowOnBoarding)}]}}),_c('label',{attrs:{"for":"checkbox"}},[_vm._v("No volver a mostrar este mensaje")])]):_c('div',{})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }