import api from "../../../service/api";
export default {
  requestHomeCards(company_id, payload) {
    return api
      .post(`/api/v1/compute/${company_id}`, payload)
      .then((response) => response.data);
  },

  list_document_type(company_id, fields, limit, offset) {
    return api.get(`/api/v1/document_type/${company_id}`, {
        params: {
            fields: fields,
            limit: limit,
            offset: offset
        }
    }).then(response => response.data);
},
};
