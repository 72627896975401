import * as types from "./mutations-types";

export default {
  [types.SET_SWITCH_TENANCY](state, data) {
    let name =
      data.name.length >= 15 ? `${data.name.slice(0, 15)}...` : data.name;
    state.inSwitch = true;
    state.idMainTenant = data.id;
    state.nameMainTenant = name;
    state.idInSwitch = data.idSwitch;
  },
  [types.CLEAN_SWITCH_TENANCY](state) {
    state.inSwitch = false;
    state.idMainTenant = null;
    state.nameMainTenant = "";
  },

  [types.SET_MODAL_REF](state, param) {
    state.modalRef = param;
  },
  [types.CLEAN_MODAL_REF](state) {
    state.modalRef = "";
  },
};
