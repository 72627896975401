<template>
  <div class="header_single_assitance_skeleton">
    <div class="skeleton_assistance_left">
      <VueSkeletonLoader
        type="circle"
        :width="70"
        :height="70"
        animation="fade"
      />
      <div class="skeleton_assistance_left_name">
        <VueSkeletonLoader
          type="rect"
          :width="130"
          :height="15"
          animation="fade"
        />
        <VueSkeletonLoader
          type="rect"
          :width="80"
          :height="15"
          animation="fade"
        />
      </div>
    </div>
    <div class="skeleton_assistance_rigth">
      <div class="code_assistance_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="90"
          :height="15"
          animation="fade"
        />
      </div>
      <div class="code_date_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="110"
          :height="15"
          animation="fade"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
    components: {
    VueSkeletonLoader,
  },
};
</script>

<style></style>
