<template>
  <div class="container-slots container-base">
    <div class="border__bottom">
      <div class="breadcrumb">
        <span class="sign__digital text_document" @click="goToListSignature()">
          Firma Digital >
        </span>
        <span class="text_document"> Borradores </span>
      </div>
    </div>
    <div class="label-primary title_draf m-top-l">Selecciona un documento</div>

    <div class="table_past_meetings m-top-s">
      <div class="table table__documents">
        <div class="table__head">
          <div class="sub-title-table">
            <div class="title_documents_draft">
              <div class="">Identificador</div>
              <div class="">Tipo de documento</div>
            </div>
            <div class="">Fecha de creación</div>
          </div>
        </div>
        <div class="table__body">
          <!-- <div class="m-top-xl" v-if="loader_documents">
            <loaderGeneric></loaderGeneric>
          </div> -->

          <div class="document__fields cursor_document" v-if="draftData">
            <div
              v-for="(document, index) in draftData"
              :key="index"
              @click="selectedDocument(document)"
              class=""
              :class="
                document_id == document.id ? 'active__document' : 'desactive'
              "
            >
              <div class="title_documents_draft">
                <div class="text_document document_draf_child">
                  {{
                    document.tags != null
                      ? document.tags[0]
                      : "sin identificador"
                  }}
                </div>
                <div class="text_document">
                  {{ document.name }}
                </div>
              </div>

              <div class="text_document mr-2">
                {{
                  document.startDate != null
                    ? document.startDate.slice(0, 10)
                    : "Sin fecha"
                }}
              </div>
            </div>
          </div>

          <div class="" v-else>Sin Documentos</div>
        </div>
      </div>
    </div>

    <div class="signature__footer__buttons m-top-s m-r-s">
      <button
        class=""
        :class="document_id ? 'active_draf' : 'desactive_draf'"
        @click="deleteDraft()"
      >
        Eliminar Borrador
      </button>
      <button
        :class="
          document_id
            ? 'active_set_draf color_hover_button_standard'
            : 'desactive_set_draf'
        "
        @click="setDocument()"
      >
        Seleccionar Borrador
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
// import loaderGeneric from "../../../components/loaderGeneric/Loading.vue";
export default {
  name: "DraftDocuments",
  components: {
    // loaderGeneric,
  },

  data: () => {
    return {
      document_id: null,
      // almacenamos los miembros firmantes de un documento seleccionado
      draftSigner: null,
      name: null,
      id: null,
      tags: null,
      signers: null,
      sign_type: null,
    };
  },

  created() {
    this.fetchDraft();
  },

  computed: {
    ...mapGetters("DigitalSignature", {
      loader_documents: "loader_documents",
    }),
    ...mapState("DigitalSignature", {
      draftData: "draftData",
      flagDraftData: "flagDraftData",
    }),

    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    goToListSignature() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
      this.$router.push({ name: "DigitalSignature" });
    },
    async setDocument() {
      this.$store.dispatch("DigitalSignature/loadDocumentOn");
      let name = this.name;
      let id = this.id;
      let tags = this.tags;

      let payload = this.signers;
      let sign_type = this.sign_type != null ? this.sign_type : null;

      let data = {
        name,
        id,
        tags,
        sign_type,
      };

      // quitar pre step pantalla
      // this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);

      // this.$store.dispatch("DigitalSignature/loadDocumentOff");
      await this.$store.dispatch("DigitalSignature/setDocument", data);
      this.$store.dispatch("DigitalSignature/setDataFromDraft");

      // let data = draftSigner;
      this.$store.dispatch("DigitalSignature/addSignerFromDraft", payload);

      setTimeout(() => {
        this.$router.push({ name: "AddSigners" });
        this.$store.dispatch("DigitalSignature/loadDocumentOff");
      }, 2000);
    },
    selectedDocument(d) {
      let members = d.sign_user_body ? d.sign_user_body : 0;
      this.name = d.name;
      this.id = d.id;
      this.tags = d.tags[0];
      this.signers = members;
      this.sign_type = d.sign_type;

      // se utiliza para pintar background del doc seleccionado
      this.document_id = d.id;
    },
    async deleteDraft() {
      let admin_company_id = this.inSwitch
        ? this.idInSwitch
        : this.admin_company_id;

      let id_document = this.document_id;

      let data = {
        admin_company_id,
        id_document,
      };

      await this.$store.dispatch("DigitalSignature/deleteDocument", data);

      setTimeout(() => {
        this.fetchDraft();
      }, 1000);
    },

    fetchDraft() {
      let sign_status = 1;

      let company_id = this.inSwitch ? this.idInSwitch : this.admin_company_id;

      this.$store.dispatch("DigitalSignature/fetchDraftDigitalSignature", {
        company_id,
        sign_status,
      });

      this.$store.dispatch("DigitalSignature/loadDocumentOff");
    },
  },
};
</script>

<style lang="scss">
.title_documents_draft {
  display: flex;
  gap: 5rem;

  & > .document_draf_child {
    color: var(--text-document-draf) !important;
    width: 120px;
    white-space: nowrap;
  }
}

.title_documents_draft .text_document {
  color: var(--text-document-draf) !important;
}
.signature__footer__buttons {
  justify-content: flex-end;
}

.desactive .text_document,
.active__document .text_document {
  color: var(--text-document-draf) !important;
}

.desactive_draf,
.desactive_set_draf,
.active_draf,
.active_set_draf {
  font-family: Conv_HelveticaLTStd-Light;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  text-align: center !important;
  transition: all 250ms ease;
  cursor: pointer !important;
  color: #9c9c9c !important;
  // border-color: #555 !important;
}
.active_draf {
  color: #464646 !important;
  border: 1.5px solid #464646;
  cursor: pointer;
}

.desactive_draf {
  background-color: white !important;
  // box-shadow: inse  0 0 0 1px #333;
  border: 1px solid #9c9c9c;
  pointer-events: none;
}

.desactive_set_draf {
  border: none !important;
  color: #ffffff !important;
  background-color: #9c9c9c !important;
  pointer-events: none;
}

.title_draf {
  color: var(--title_origin) !important;
}
.table_past_meetings {
  margin-right: 1rem;
}
</style>
