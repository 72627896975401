<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : size == 'modal__counter_v2'
        ? 'modal__counter_v2'
        : 'modal__default'
    "
    id="modal"
    ref="modal"
  >
    <div class="modal__content">
      <div class="header_modal">
        <CancelIcon @click="cancelModal()" />
      </div>
      <div class="container__title title-primary">
        <span>{{ title }}</span>
      </div>
      <slot v-if="!responseApiKeyAction" />
      <!-- only api key case -->
      <div class="m-bottom-xl" v-else-if="loader">
        <LoaderGeneric class="center-loader fix_mt" />
      </div>
      <div class="modal_flex_body" v-else>
        <span class="value-primary">
          Estimado
          <span class="label-primary f-14"
            >{{ userstate.first_name }} {{ userstate.surname }}</span
          >
          , has solicitado modificar tu Api-Key desde las configuraciones del
          sistema
        </span>
        <span class="label-primary f-14 italic__font"
          >Por favor revise su bandeja de entrada de correos.</span
        >
        <span class="value-primary">
          Esta acción implica que la antigua Api-Key ha sido dada de baja.
          Conserva esta Api-Key en un lugar seguro.
        </span>
        <div class="footer__logo">
          <span class="text-filter m-top-l"> Atte. Equipo de MisAbogados </span>
          <svg
            version="1.1"
            id="Capa_1"
            class="logo__modal"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 200 200"
            style="enable-background: new 0 0 200 200"
            xml:space="preserve"
          >
            <g>
              <rect style="fill: #000032" width="200" height="200" />
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        style="fill: #5a96ff"
                        d="M58.91,105.13H38.42c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13H58.9
						c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13C64.04,107.43,61.74,105.13,58.91,105.13z"
                      />
                      <path
                        style="fill: #5a96ff"
                        d="M161.58,105.13H79.39c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13c0,0,0.01,0,0.01,0
						h82.17c2.83,0,5.13-2.3,5.13-5.13C166.71,107.43,164.41,105.13,161.58,105.13z"
                      />
                      <path
                        style="fill: #5a96ff"
                        d="M38.42,74.35h61.48c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13c0-2.83-2.3-5.13-5.13-5.13
						H38.42c-2.83,0-5.13,2.3-5.13,5.13C33.29,72.05,35.59,74.35,38.42,74.35z"
                      />
                      <path
                        style="fill: #5a96ff"
                        d="M161.58,125.65H99.92c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13c0,0,0.01,0,0.01,0
						h61.65c2.83,0,5.13-2.3,5.13-5.13C166.71,127.95,164.41,125.65,161.58,125.65z"
                      />
                      <path
                        style="fill: #5a96ff"
                        d="M79.39,125.65H38.42c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13h40.96
						c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13C84.52,127.95,82.23,125.65,79.39,125.65z"
                      />
                      <path
                        style="fill: #5a96ff"
                        d="M120.44,84.61L120.44,84.61l-82.02,0c-2.83,0-5.13,2.3-5.13,5.13s2.3,5.13,5.13,5.13h81.98
						c0.01,0,0.02,0,0.03,0c2.83,0,5.13-2.3,5.13-5.13C125.57,86.91,123.27,84.61,120.44,84.61z"
                      />
                      <path
                        style="fill: #5a96ff"
                        d="M161.58,84.61h-20.57v0c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13
						c0.01,0,0.02,0,0.03,0h20.54c2.83,0,5.13-2.3,5.13-5.13S164.41,84.61,161.58,84.61z"
                      />
                      <path
                        style="fill: #5a96ff"
                        d="M120.44,74.35c0,0,0.01,0,0.01,0h41.13c2.83,0,5.13-2.3,5.13-5.13c0-2.83-2.3-5.13-5.13-5.13
						h-41.14c-2.83,0-5.13,2.3-5.13,5.13C115.31,72.05,117.61,74.35,120.44,74.35z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>

      <!-- end api key case        -->
      <div class="error-span container__error" v-if="errorFieldApiKey">
        {{ errorField }}
      </div>
      <div v-if="!responseApiKeyAction" class="footer_modal footer_counter">
        <div
          class="btn-vue-primary fix-btn-mac"
          @click="confirmModalChanges(identifier)"
          v-if="confirm"
        >
          <span> Confirmar </span>
        </div>
        <div
          v-if="cancel"
          disabled="disabled"
          class="secondary_button"
          @click="cancelModal()"
        >
          <span> Cancelar </span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "./assets/cancel.svg";
import { mapState, mapGetters } from "vuex";
import LoaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import updateSettins from "../../modulesAdmin/modules/Settings/services/SettingService";
import Vue from "vue";

export default {
  components: {
    CancelIcon,
    LoaderGeneric,
  },
  /*--------------------------------
   @Input: 1. size 'xl' = modal__xl
           2. size 'null'  = modal__default
           3. size 'm'  = modal__m
           4. size 's'  = modal__s
           5. size 'l'  = modal__l
           6. size 'ls' = modal__ls
   Description: Diferent props to control modal size
   @Output: 1.  modal__xl  =      height: 450px; width: 600px;
            2.  modal__default =  height: 350px; width: 500px;
            3.  modal__m   =      height: 300px;; width: 410px;
            4.  modal__s  =       height: 250px; width: 410px;
            5.  modal__l  =       height: 450px; width: 410px;
            6.  modal__ls =       height: 370px; width: 410px;
  //--------------------------------*/

  /*--------------------------------
   @Input: confirm / cancel
   Description: Props to control show/hide funcionality of two buttons
                (confirm and  cancel)
   @Output: confirm =true/false and cancel =true/false
  //--------------------------------*/
  props: ["title", "confirm", "cancel", "size", "identifier"],
  data: () => {
    return {
      errorFieldApiKey: false,
      errorField: "",
      responseApiKeyAction: false,
      loader: false,
      successMessage: "",
      errorMessages: "",
      lenguageSucces: null,
      darkModeSucces: null,
    };
  },
  computed: {
    ...mapState({
      userstate: (state) => state.Login.user_profile,
    }),
    ...mapGetters("Login", {
      client_company_id: "company_id",
      countryCode: "countryCode",
    }),
    ...mapGetters("SettingsApp", {
      TenantSettingsInstanceID: "TenantSettingsInstanceID",
      userLenguageInstanceChange: "userLenguageInstanceChange",
      userDarkModeInstanceChange: "userDarkModeInstanceChange",
      reportLimitUseValueChange: "reportLimitUseValueChange",
      groupIdforDocumentTypePermByRol: "groupIdforDocumentTypePermByRol",
      documentTypeTypePermByRolValues: "documentTypeTypePermByRolValues",
      documentTypeByRolIdInstance: "documentTypesByRolId",
      countryCodeForRegionalSettings: "getDefaultCountryCode",
      activeDesactiveDocumentTypesId: "activeDesactiveDocumentTypesId",
      documentTypeByRegionValues: "documentTypeByRegionValues",
    }),
  },
  methods: {
    open() {
      this.errorFieldApiKey = false;
      this.responseApiKeyAction = false;
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
          this.errorFieldApiKey = false;
          this.responseApiKeyAction = false;
        },
        { once: true }
      );
    },
    confirmModalChanges(indentifier) {
      if (indentifier == "user") {
        this.saveSettingsUser();
      }
      if (indentifier == "company") {
        this.saveSettingsCompany();
      }
      if (indentifier == "report") {
        this.saveSettinsRegionalReportLimit();
      }
      if (indentifier == "documentRegion") {
        this.saveSettinsRegionalDocumentTypeByRegion();
      }
      if (indentifier == "documentRol") {
        this.saveSettinsRegionalDocumentTypeByRol();
      }
    },
    saveSettingsUser() {
      let userLenguaje = this.userLenguageInstanceChange;
      let darkmode = this.userDarkModeInstanceChange;

      let lenguajeChanes = null;
      let darkmodeChanges = null;
      let payload = [];

      this.lenguageSucces = null;
      this.darkModeSucces = null;


      if (userLenguaje != false) {
        this.lenguageSucces = "Idioma modificado con éxito";
        lenguajeChanes = {
          id: userLenguaje.id,
          data: userLenguaje.value,
        };
        payload.push(lenguajeChanes);
      }
      if (darkmode != false) {
        this.darkModeSucces = "Color de la interfaz modificada con éxito";
        darkmodeChanges = {
          id: darkmode.id,
          data: darkmode.value,
        };
        payload.push(darkmodeChanges);
      }

      let admin_company_id = this.client_company_id;

      //params to get user settings instance
      let params = {
        client_company_id: this.client_company_id,
        scope: 0,
        country: this.countryCode,
      };

      updateSettins
        .sendChangeUserInstance(admin_company_id, payload)
        .then(() => {
          this.responseApiKeyAction = true;
          this.loader = true;

          setTimeout(() => {
            this.$store.dispatch("SettingsApp/getSettingsInstances", params);
            if (this.lenguageSucces != null && this.darkModeSucces != null) {
              let message = `Configuraciones de usuario modificadas con éxito`;
              this.$toasted.success(message);
              this.$store.dispatch(
                "SettingsApp/activeChangeUserDarkModeInstance",
                false
              );
              this.$store.dispatch(
                "SettingsApp/activeChangeUserLenguajeInstance",
                false
              );
            } else if (this.lenguageSucces != null) {
              this.$store.dispatch(
                "SettingsApp/activeChangeUserLenguajeInstance",
                false
              );
              let messageLenaujeSuccess = `Idioma modificado con éxito`;
              this.$toasted.success(messageLenaujeSuccess);
            } else if (this.darkModeSucces != null) {
              this.$store.dispatch(
                "SettingsApp/activeChangeUserDarkModeInstance",
                false
              );
              let messageDarkModeSucces = `Color de la interfaz modificada con éxito`;
              this.$toasted.success(messageDarkModeSucces);
            }

            this.cancelModal();
          }, 2000);
        })

        .catch(() => {
          this.errorMessages = "Error al guardar";
          this.loader = false;
          this.responseApiKeyAction = false;
          this.errorFieldApiKey = true;
          this.errorField = "Error en la solicitud";
          Vue.toasted.error(this.errorMessages);
        });
    },
    saveSettingsCompany() {
      this.loader = true;

      setTimeout(() => {
        this.loader = false;
      }, 1000);

      this.responseApiKeyAction = true;

      let id = this.TenantSettingsInstanceID;
      let data = true;

      let dataApiKey = {
        id: id,
        data: data,
      };

      let arrayApiKey = [];
      arrayApiKey.push(dataApiKey);
      let admin_company_id = this.client_company_id;

      updateSettins
        .sendChangeApiKey(admin_company_id, arrayApiKey)
        .then(() => {
          // this.loader = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.loader = false;
            this.responseApiKeyAction = false;
            this.errorFieldApiKey = true;
          }, 1000);
          this.errorField = "Error en la solicitud";
        });
    },
    saveSettinsRegionalReportLimit() {
      let admin_company_id = this.client_company_id;
      let payload = [];

      let country__code = this.countryCodeForRegionalSettings;

      let params = {
        client_company_id: this.client_company_id,
        scope: 2,
        country: country__code,
      };

      let reportvaluesChange = this.reportLimitUseValueChange;

      let data = {
        id: reportvaluesChange.id,
        data: reportvaluesChange.data,
      };

      payload.push(data);

      updateSettins
        .sendChangeReportLimitSettingRegional(admin_company_id, payload)
        .then(() => {
          this.responseApiKeyAction = true;
          this.loader = true;
          setTimeout(() => {
            this.$store.dispatch("SettingsApp/getSettingsInstances", params);
            let message = `Configuraciones de limites modificadas con éxito`;
            this.$toasted.success(message);
            this.cancelModal();
          }, 2000);
        })
        .catch(() => {
          this.loader = false;
          this.responseApiKeyAction = false;
          this.errorFieldApiKey = true;
          this.errorField = "Error en la solicitud";
          Vue.toasted.error(this.errorMessages);
        });
    },
    saveSettinsRegionalDocumentTypeByRegion() {
      let idInstance = this.activeDesactiveDocumentTypesId;

      let arrayChanges = this.documentTypeByRegionValues;

      let data = {
        id: idInstance,
        data: arrayChanges,
      };

      let payload = [];
      payload.push(data);

      let admin_company_id = this.client_company_id;

      this.$store.dispatch(
        "SettingsApp/cleanDefaultValuesForDocumentTypeByRegion"
      );
      updateSettins
        .sendChangeDocumentTypeByRegion(admin_company_id, payload)
        .then(() => {
          this.responseApiKeyAction = true;
          this.loader = true;
          setTimeout(() => {
            updateSettins.list_document_type_category().then((data) => {
              let document_category_list = data.results;
              // recorrer 3 veces el arreglo para filtrar los datos correspondientes al default
              for (
                let index = 0;
                index < document_category_list.length;
                index++
              ) {
                let categoryElement =
                  document_category_list[index].document_category_children;

                for (let index = 0; index < categoryElement.length; index++) {
                  let grandChildCategoryElement =
                    categoryElement[index].document_type;

                  for (
                    let index = 0;
                    index < grandChildCategoryElement.length;
                    index++
                  ) {
                    this.$store.dispatch(
                      "SettingsApp/setDefaultDocumentTypeByRegion",
                      grandChildCategoryElement[index]
                    );
                  }
                }
              }
              this.$store.dispatch("SettingsApp/clearElementDocumentByRegion");
              let message = `DocumentTypes activos en esta región modificados con éxito`;
              this.$toasted.success(message);
              this.cancelModal();
            });
          }, 2000);
        })
        .catch(() => {
          this.loader = false;
          this.responseApiKeyAction = false;
          this.errorFieldApiKey = true;
          this.errorField = "Error en la solicitud";
        });
    },
    saveSettinsRegionalDocumentTypeByRol() {
      let idInstance = this.documentTypeByRolIdInstance;
      let group_id = this.groupIdforDocumentTypePermByRol;
      let document_type = this.documentTypeTypePermByRolValues;

      let data = {
        id: idInstance,
        data: {
          group_id: group_id,
          document_type: document_type,
        },
      };

      let payload = [];
      payload.push(data);
      let admin_company_id = this.client_company_id;

      let params = {
        admin_company_id: admin_company_id,
        employee_group_id: group_id,
      };

      updateSettins
        .sendChangeDocumentTypeByRol(admin_company_id, payload)
        .then(() => {
          this.responseApiKeyAction = true;
          this.loader = true;
          setTimeout(() => {
            this.$store.dispatch(
              "SettingsApp/listDocumentTypePermByRol",
              params
            );
             let message = `Enlace de DocumentTypes a roles modificados con éxito`;
              this.$toasted.success(message);
              this.cancelModal();
          }, 2000);
        })
        .catch(() => {
          this.loader = false;
          this.responseApiKeyAction = false;
          this.errorFieldApiKey = true;
          this.errorField = "Error en la solicitud";
        });
      this.$store.dispatch("SettingsApp/clearElementDocumentPermByRol");
    },
  },
};
</script>

<style lang="scss">
@import "./modal.scss";

.italic__font {
  font-style: italic !important;
}

.container__error {
  margin-left: 20px;
  margin-right: 26px;
}
</style>
