import EmployeesModule from "./EmployeesModule";
import ListEmployees from "./pages/ListEmployees.vue";
import CreateEmployee from "./pages/CreateEmployee.vue";
import EditEmployee from "./pages/EditEmployee.vue";
import FirstEmployeeCreation from "./pages/FirstEmployeeCreation.vue";
import ViewEmployee from "./pages/ViewEmployee.vue";
import SelectedEmployee from "./pages/SelectedEmployee.vue";
import SelectedEmployeeAdmin from "./pages/SelectedEmployeeAdmin.vue";

export const EmployeesRoutesV2 = [
  {
    path: "/first-employee-v2",
    name: "FirstEmployeeCreation",
    component: FirstEmployeeCreation,
  },
  {
    path: "/admin-empleado-v2",
    component: EmployeesModule,

    children: [
      {
        path: "/",
        name: "ListEmployeesV2",
        component: ListEmployees,
      },
      {
        path: "crear-empleado",
        name: "createEmployeeV2",
        component: CreateEmployee,
      },
      {
        path: "/seleccionar-empleado",
        name: "selectedEmployee",
        component: SelectedEmployee,
      },
      {
        path: "/seleccionar-empleado-admin",
        name: "selectedEmployeeAdmin",
        component: SelectedEmployeeAdmin,
      },

      {
        path: "/editar-empleado",
        name: "EditEmployeeV2",
        component: EditEmployee,
      },
      {
        path: "/ver-empleado/:name",
        name: "ViewEmployee",
        component: ViewEmployee,
      },
    ],
  },
];
