// Data para data obtenida del servicio computed
export const FETCH_REPORT_USE_TENANT_CATEGORY_SUCCESS =
  "FETCH_REPORT_USE_TENANT_CATEGORY_SUCCESS";
export const FETCH_REPORT_USE_TENANT_CATEGORY_FAILURE =
  "FETCH_REPORT_USE_TENANT_CATEGORY_FAILURE";
export const FETCH_REPORT_USE_TENANT_CATEGORY_DATA =
  "FETCH_REPORT_USE_TENANT_CATEGORY_DATA";

// SETEAMOS EL VALOR FINAL PARA EL ARRAY DE SELECCIONES EN CHECK
export const SET_REPORTS_USE_TENANTS = "SET_REPORTS_USE_TENANTS";

// FETCH CSV CON CATEGORIAS
export const FETCH_CSV_SUCCESS = "FETCH_CSV_SUCCESS";
export const FETCH__CSV_FAILURE = "FETCH__CSV_FAILURE";
export const FETCH_CSV_DATA = "FETCH_CSV_DATA";
export const CLEAN_CSV_DATA = "CLEAN_CSV_DATA";



// clean csv for download

