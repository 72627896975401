<template>
  <div class="container-slots container-base">
    <div class="border__bottom">
      <div class="breadcrumb">
        <span class="sign__digital text_document" @click="goToListSignature()">
          Firma Digital >
        </span>
        <span class="text_document">Firmar Documento</span>
      </div>
    </div>

    <div class="container_from_local">
      <div class="container_local">
        <div class="label-primary">
          Adjunta un documento
          <span class="sub-title-table"
            >(Adjunta un documento en formato PDF)</span
          >
        </div>

        <div class="container-file">
          <div class="input__custom__file">
            <span> Seleccionar un archivo</span>
            <input
              type="file"
              id="test"
              class="input-add"
              accept="application/pdf"
              @change="setNameDocument($event)"
            />
          </div>
          <div :class="namePdf != null ? 'name__pfd' : 'no__pdf'">
            {{ namePdf }}
          </div>
        </div>

        <div class="label-primary">Nombre del documento a firmar</div>
        <div class="name__of__pdf" :class="namePdf != null ? '' : 'no__pdf'">
          {{ nameView }}
        </div>
        <div class="input_tags">
          <div class="label-primary m-top-s">
            Identificador del documento
            <span class="sub-title-table"> (ej. contrato juan perez) </span>
          </div>
          <input class="input-form" type="text" v-model="tags" />
        </div>
      </div>

      <div class="container_loca_right">
        <div class="preview_doc"></div>
        <div class="signature_buttons_from_local">
          <button class="secondary_button" @click="backButton()">Volver</button>
          <button
            class="btn-vue-primary"
            :class="file != null ? '' : 'bloqued'"
            :disabled="!file"
            @click="setDocumentFromLocal()"
          >
            Seleccionar Documento
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OriginFromLocal",

  data: () => {
    return {
      file: null,
      namePdf: null,
      nameView: null,
      tags: null,
    };
  },

  methods: {
    goToListSignature() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
      this.$router.push({ name: "DigitalSignature" });
    },

    backButton() {
      this.$router.push({ name: "NewDigitalSignature" });
    },
    setNameDocument(e) {
      this.file = e.target.files[0];
      this.namePdf = e.target.files[0].name;
      this.nameView = this.namePdf.substring(0, this.namePdf.length - 4);
    },
    setDocumentFromLocal() {
      this.$store.dispatch("DigitalSignature/cleanSignerFromDraft");

      let data = {
        file: this.file,
        name: this.nameView,
        tags: this.tags,
      };

      this.$store.dispatch("DigitalSignature/setDocumentFromLocal", data);
      this.$router.push({ name: "AddSigners" });
    },
  },
};
</script>

<style lang="scss" scoped>
.container_from_local {
  @media screen and (max-width: 600px) and (min-width: 200px) {
    gap: 2rem;
    flex-wrap: wrap;

    > .container_loca_right .preview_doc {
      display: none;
    }

    > .container_loca_right,
    .container_local {
      width: 100%;
    }

    > .container_local .input_tags {
      width: 100%;
    }
  }
}
.bloqued {
  // algo
  background: #9c9c9c;
  border-radius: 2px;
  border: 1px solid #9c9c9c;

  &:hover {
    background: #9c9c9c !important;
  }
}
.container_from_local {
  display: flex;
  justify-content: space-between;
}
.container_loca_right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.preview_doc {
  height: 300px;
}
.input_tags {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 70%;
  > .label-primary {
    color: var(--description_text_document) !important;

    > .sub-title-table {
      color: var(--sub-title-user) !important;
    }
  }
}
.container_local {
  width: 75%;
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  > .label-primary {
    color: var(--description_text_document) !important;
    > .sub-title-table {
      color: var(--sub-title-user) !important;
    }
  }
}
.signature_buttons_from_local {
  display: flex;
  gap: 1rem;

  > .active_draf {
    background: var(--bg_active_delete_draf) !important;
    color: #000032 !important;
  }

  > .desactive_draf {
    color: #9c9c9c !important;
  }

  > .desactive_set_draf {
    background: #9c9c9c !important;
    color: white !important;
  }
}
</style>
