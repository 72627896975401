<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__max__file__message"
    ref="modal__max__file__message"
  >
    <div class="modal__content">
      <div class="warning_icon_message">
        <WarningSymbolMessage />
      </div>
      <div class="content_onboarding_employee">
        <div class="container_onboarding_title">
          <div class="title_warning_modal_message">
            Máximo de documentos alcanzado
          </div>
        </div>
        <div class="text_container_warning_message">
          Sólo puede adjuntar un máximo de 5 archivos por mensaje, para enviar
          nuevos adjunta los archivos en un nuevo mensaje.
        </div>
      </div>
      <div class="footer_modal footer__modal__cancel">
        <div class="fix-btn-mac button_send_message" @click="cancelModal()">
          <span> Aceptar </span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import WarningSymbolMessage from "../../assets/Warning_icon_msg.svg";
export default {
  props: ["size"],
  components: {
    WarningSymbolMessage,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__max__file__message");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__max__file__message");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.footer__modal__cancel {
  justify-content: center !important;
}

.warning_icon_message {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center;
  > svg {
    width: 200px !important;
    height: 200px !important;
  }
}
.title_warning_modal_message {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 49px;
}
.container_onboarding_title {
  margin-top: 1rem;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.content_onboarding_employee {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.text_container_warning_message {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
}
.button_onboarding_accept {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 104px !important;
  font-family: Conv_HelveticaLTStd-Light;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  text-align: center !important;
  transition: all 250ms ease;
  white-space: nowrap;
  background: #0064ff !important;
  border: 1px solid #0064ff;
  color: white;
  cursor: pointer !important;
}
.footer_check_box_modal {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.button_send_message {
  display: flex;
  align-items: center;
  height: 40px !important;
  background: #0064ff !important;
  font-family: Conv_HelveticaLTStd-Light;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  width: fit-content;
  text-align: center !important;
  transition: all 250ms ease;
  cursor: pointer !important;
  white-space: nowrap;
  color: white;
  &:hover {
    background-color: #000032 !important;
    color: white !important;
    box-shadow: inset 0 0 0 1px;
  }
}

</style>
