// export for regional settings only

export const SET__ACTIVE_VIEW__REGIONAL__SETTINGS =
  "SET__ACTIVE_VIEW__REGIONAL__SETTINGS";

export const SET_REGION_SETTING_INSTANCE = "SET_REGION_SETTING_INSTANCE";
export const SET_REPORT_LIMIT_USE_VALID_CHANGE =
  "SET_REPORT_LIMIT_USE_VALID_CHANGE";
//Set default values for document type by region
export const CLEAN_DEFAULT_DOCUMENT_TYPE_BY_REGION =
  "CLEAN_DEFAULT_DOCUMENT_TYPE_BY_REGION";
export const SET_DEFAULT_DOCUMENT_TYPE_BY_REGION =
  "SET_DEFAULT_DOCUMENT_TYPE_BY_REGION";
export const ADD_ELEMENT_DOCUMENT_TYPE_BY_REGION =
  "ADD_ELEMENT_DOCUMENT_TYPE_BY_REGION";
export const CLEAR_ELEMENT_DOCUMENT_TYPE_BY_REGION =
  "CLEAR_ELEMENT_DOCUMENT_TYPE_BY_REGION";
export const SET_DOCUMENT_TYPE_PERM_BY_ROL = "SET_DOCUMENT_TYPE_PERM_BY_ROL";
export const SET_ID_GROUP_DOCUMENT_TYPE_PERM_BY_ROL = "SET_ID_GROUP_DOCUMENT_TYPE_PERM_BY_ROL";
export const ADD_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL =
  "ADD_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL";
export const CLEAR_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL =
  "CLEAR_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL";

// retrive settings
export const GET_RETRIVE_SETTINGS = "GET_RETRIVE_SETTINGS";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const FAILURE_RETRIVE_SETTINGS = "FAILURE_RETRIVE_SETTINGS";
export const CLEAN_LIMIT_USE_VALID_CHANGE = "CLEAN_LIMIT_USE_VALID_CHANGE";
// set user settings instance
export const SET_USER_SETTING_INSTANCE = "SET_USER_SETTING_INSTANCE";
export const SET_LENGUAGE_VALID_CHANGE = "SET_LENGUAGE_VALID_CHANGE";
export const SET_DARKMODE_VALID_CHANGE = "SET_DARKMODE_VALID_CHANGE";

// set category for template
export const SET_CATEGORY_ACTIVE_TEMPLATE = "SET_CATEGORY_ACTIVE_TEMPLATE";

//Set initial values for settings tenant (company)
export const SET_TENANT_SETTING_INSTANCE = "SET_TENANT_SETTING_INSTANCE";
// bind document type to rol
export const SET_LIST_GROUP = "SET_LIST_GROUP";
