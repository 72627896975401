<template>
  <div class="container_module">
    <div class="container-slots">
      <router-view />
    </div>
  </div>
</template>

<script>
import homeService from "./services/homeCardServices";

import { mapGetters } from "vuex";
export default {
  name: "HomeModule",
  components: {},
  data: function () {
    return {
      payload: {},
    };
  },

  created() {
    this.loadDataforHOme();
  },
  mounted() {
    // this.loadDataforHOme();
  },

  computed: {
    ...mapGetters("Login", {
      user: "user_profile",
      is_staff: "is_staff",
      group: "group",
    }),
  },

  methods: {
    loadDataforHOme() {
      this.$store.dispatch("Home/loadingInitialDataForCards", false);
      if (this.group === "Admin" || this.group === "Company-Admin") {
        this.payload = {
          computes: [
            "home_docs_card",
            "home_quotations_card",
            "home_questions_card",
            "home_users_card",
          ],
        };

        homeService
          .requestHomeCards(this.user.company.id, this.payload)
          .then((data) => {
            this.$store.dispatch("Home/setDataCards", data);
            setTimeout(() => {
              this.$store.dispatch("Home/loadingInitialDataForCards", true);
            }, 800);

            console.lo("home modules:", data);
          })

          .catch(() => {
            // this.$store.dispatch("Home/loadingInitialDataForCards", false);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.rect_skeleton {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  gap: 0.7rem;
  width: 200px;
}
</style>
