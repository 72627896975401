<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : size == 'modal__test_ios'
        ? 'modal__test_ios'
        : 'modal__default'
    "
    id="modal__send__message"
    ref="modal__send__message"
  >
    <div class="modal__content">
      <div class="image_onboarding_end_message">
        <img :src="webpImagePath" alt="Imagen en formato WebP" />

        <!-- <SendMessage /> -->
      </div>
      <div class="content_onboarding_employee">
        <div class="container_onboarding_title_message">
          <div class="title_onboarding_message">Enviar mensajes</div>
        </div>
        <div class="text_container_onboarding_message">
          <div class="">
            Cada solicitud que hagas dentro de la plataforma, tendrá una sección
            para <b>intercambiar mensajes</b> con tu abogado asignado.
            <div class="">
              Este responderá a cada solicitud que hagas, siempre dentro de la
              plataforma.
            </div>
            <div class="">
              <b> Cada solicitud tendrá su sección de mensajes única </b>, y
              <b> recibirás notificaciones </b> cada vez que tu abogado o su
              staff te escriba algo por aquí”
            </div>
          </div>
        </div>
      </div>
      <div class="footer_modal footer__modal__cancel">
        <div disabled="disabled" class="button_back_message" @click="backModal">
          <span> Volver</span>
        </div>
        <div class="fix-btn-mac button_send_message" @click="gotoWall">
          <span> Aceptar </span>
        </div>
      </div>
      <div class="footer_check_box_modal">
        <input
          type="checkbox"
          id="checkbox"
          v-model="checkNoShowOnBoardingMessage"
          @change="checkedOnBoarding(checkNoShowOnBoardingMessage)"
        />
        <label for="checkbox">No volver a mostrar este mensaje</label>
      </div>
    </div>
  </dialog>
</template>

<script>
// Replace svg for jpg because IOS compability
// import SendMessage from "../../assets/send-message-img.svg";
import employeeService from "../../../employeesV2/services/employeService";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    // SendMessage,
  },
  data() {
    return {
      webpImagePath: require("../../assets/messagemuro2.jpg"),
      stepOnBoarding: 1,
      checkNoShowOnBoardingMessage: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
      user: "user_profile",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__send__message");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__send__message");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    gotoWall() {
      this.$router.push({ name: "messageWall" });
    },
    backModal() {
      let modal = document.querySelector("#modal__send__message");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    checkedOnBoarding(checkNoShowOnBoardingMessage) {
      if (checkNoShowOnBoardingMessage) {
        let payload = {
          show_chat_info: false,
        };
        employeeService
          .edit_own_profile(this.user.company.id, payload)
          .then(() => {
            this.$store.dispatch("Login/setUserProfile", this.user.company.id);
          });
      } else {
        let payload = {
          show_chat_info: true,
        };
        employeeService
          .edit_own_profile(this.user.company.id, payload)
          .then(() => {
            this.$store.dispatch("Login/setUserProfile", this.user.company.id);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.footer__modal__cancel {
  justify-content: center !important;
}

.image_onboarding_end_message {
  display: flex;
  margin-top: 1.5rem;
  margin-left: 9rem;
  align-items: center;
  justify-content: center;
  width: 353px;
  height: 352px;
  @media screen and (max-width: 500px) and (min-width: 376px) {
    width: 320px !important;
    height: 206px !important;
    margin-left: 1.5rem !important;
    margin-top: 4.8rem !important;
  }
  @media screen and (max-width: 376px) and (min-width: 200px) {
    width: 250px !important;
    height: 160px !important;
    margin-left: 2.5rem !important;
    margin-top: 3.8rem !important;
  }
}
.title_onboarding_message {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  @media screen and (max-width: 500px) and (min-width: 376px) {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
  }
  @media screen and (max-width: 376px) and (min-width: 200px) {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
  }
}
.container_onboarding_title_message {
  margin-top: 1rem;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  @media screen and (max-width: 500px) and (min-width: 376px) {
    margin-top: 5rem !important;
  }
  @media screen and (max-width: 376px) and (min-width: 200px) {
    margin-top: 5rem !important;
  }
}
.content_onboarding_employee {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.text_container_onboarding_message {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  @media screen and (max-width: 500px) and (min-width: 375px) {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
  }
  @media screen and (max-width: 376px) and (min-width: 200px) {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
  }
}
.button_onboarding_accept {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 104px !important;
  font-family: Conv_HelveticaLTStd-Light;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  text-align: center !important;
  transition: all 250ms ease;
  white-space: nowrap;
  background: #0064ff !important;
  border: 1px solid #0064ff;
  color: white;
  cursor: pointer !important;
}
.footer_check_box_modal {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.button_send_message {
  display: flex;
  align-items: center;
  height: 40px !important;
  background: #0064ff !important;
  font-family: Conv_HelveticaLTStd-Light;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  width: fit-content;
  text-align: center !important;
  transition: all 250ms ease;
  cursor: pointer !important;
  white-space: nowrap;
  color: white;
  &:hover {
    background-color: #000032 !important;
    color: white !important;
    box-shadow: inset 0 0 0 1px;
  }
}
.button_back_message {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  box-shadow: inset 0 0 0 1px;
  font-family: Conv_HelveticaLTStd-Light;
  color: #0064ff !important;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border-color: #0064ff !important;
  border: none;
  text-align: center !important;
  transition: all 250ms ease;
  cursor: pointer !important;
  width: 100px;
}
</style>
