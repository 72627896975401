<template>
  <div
    class="container_options container_navbar float__button__card"
    @click="show_options_employee()"
  >
    <span class="icon_options_employee_edit"> <SettingsIcon /></span>

    <div :class="activeDropDownV2 ? 'open_options' : 'close_options'">
      <nav class="nav_options_employee">
        <div
          class="menu_options_nav adjust_padding_employee_dropdown"
          @click="AllActivated()"
        >
          <div
            :class="getAllActiveState ? 'active_color' : ''"
            class="sub_title_card fix_style_nav adjust_padding_employee fix_first_element"
          >
            Activar Todos
          </div>
        </div>

        <div
          class="menu_options_nav adjust_padding_employee_dropdown"
          @click="AllDesactivated()"
        >
          <div
            :class="getAllDesactiveState ? 'active_color' : ''"
            class="sub_title_card fix_style_nav adjust_padding_employee"
          >
            Desactivar Todos
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import SettingsIcon from "../../assets/fi-rs-settings.svg";
import { mapGetters } from "vuex";

export default {
  components: {
    SettingsIcon
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__employee: "list__employee",
      activeDropDownV2: "activeDropDownV2",
      activeDropDownVerified: "activeDropDownVerified",
      getAllActiveState: "getAllActiveState",
      getAllDesactiveState: "getAllDesactiveState",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  created() {},
  methods: {
    show_options_employee() {
      let activeDropDownFlag = this.activeDropDownV2;

      if (!activeDropDownFlag) {
        setTimeout(() => {
          this.$store.dispatch("EmployeesV2/setDropDown", true);
        }, 100);
      } else if (activeDropDownFlag) {
        this.$store.dispatch("EmployeesV2/setDropDown", false);
      }
    },
    AllActivated() {
      let list_employees = this.list__employee;
      let payload = {
        data: "",
        status: true,
        list_employees: list_employees,
      };

      let users = [];
      for (let i = 0; i < list_employees.length; i++) {
        if (list_employees[i].is_active == false) {
          users.push(list_employees[i].id);
        }
      }

      let id_company = this.inSwitch ? this.idInSwitch : this.company_id;
      let data = {
        id_company: id_company,
        users: users,
      };
      this.$store.dispatch("EmployeesV2/setSelectedOptionState", payload);
      this.$store.dispatch("EmployeesV2/setToggleStatusEmployees", data);
    },
    AllDesactivated() {
      let list_employees = this.list__employee;

      let payload = {
        data: "",
        status: false,
        list_employees: list_employees,
      };
      let users = [];
      for (let i = 0; i < list_employees.length; i++) {
        if (list_employees[i].is_active == true) {
          users.push(list_employees[i].id);
        }
      }

      let id_company = this.inSwitch ? this.idInSwitch : this.company_id;
      let data = {
        id_company: id_company,
        users: users,
      };
      this.$store.dispatch("EmployeesV2/setSelectedOptionState", payload);
      this.$store.dispatch("EmployeesV2/setToggleStatusEmployees", data);
    },
  },
};
</script>

<style lang="scss"></style>
