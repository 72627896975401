var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal_confirm_asisstance",class:_vm.size == 'xl'
    ? 'modal__xl'
    : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
        ? 'modal__s'
        : _vm.size == 'xs'
          ? 'modal__xs'
          : _vm.size == 'l'
            ? 'modal__l'
            : _vm.size == 'ls'
              ? 'modal__ls'
              : _vm.size == 'fltr'
                ? 'modal__filter'
                : _vm.size == 'count'
                  ? 'modal__counter'
                  : _vm.size == 'modal__filter_v2'
                    ? 'modal__filter_v2'
                    : 'modal__default',attrs:{"id":"modal_confirm_asisstance"}},[_c('div',{staticClass:"modal__content fix_mobile_ios"},[_c('div',{staticClass:"modal__body fix_modal_content m-bottom-xl"},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"footer__modal__confirm footer_counter"},[_c('div',{staticClass:"btn-vue-primary fix-btn-mac",on:{"click":function($event){return _vm.viewNewAssistanceModal()}}},[_c('span',[_vm._v(" Ver asistencia solicitada ")])]),_c('div',{staticClass:"btn-vue-primary fix-btn-mac",on:{"click":function($event){return _vm.createNewAssistanceModal()}}},[_c('span',[_vm._v(" Solicitar nueva asistencia ")])]),_c('div',{staticClass:"secondary_button",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Cerrar ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container__title title-primary fix_warning_title m-bottom-xl"},[_c('span',[_vm._v(" ¡Tu solicitud de asistencia ha sido creada exitosamente! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal__user__settings modal_assistance_confirm"},[_c('div',{staticClass:"container__description container_save_assitance value-primary"},[_c('div',{},[_vm._v("¿Qué viene a continuación?")]),_c('div',{},[_vm._v(" 1. Tu solicitud está siendo revisada, y asignada a un abogado(a), por nuestros administradores ")]),_c('div',{},[_vm._v(" 2. En breve, tu abogado(a) asignado te contactará por cualquier clarificación adicional ")]),_c('div',{},[_vm._v(" 3. Recibirás tu asistencia en el plazo señalado por el abogado(a) asignado ")])])])
}]

export { render, staticRenderFns }