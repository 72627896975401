<template>
  <div class="">
    <CancelModal ref="modal__cancel__creation__employee" size="ls" />

    <div class="container_edit_employee">
      <div class="left-side-edit">
        <div class="field_edit_employee">
          <div class="label-primary">
            Primer Nombre <span class="error__required">*</span>
          </div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee required"
              :class="first_name_required ? 'error_field_required' : ''"
              type="text"
              name="name"
              @change="setValidatorClear('first_name')"
              v-model="user_profile.first_name"
            />
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">
            Apellido Paterno <span class="error__required">*</span>
          </div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              :class="!surname_required ? '' : 'error_field_required'"
              type="text"
              name=""
              @change="setValidatorClear('surname')"
              v-model="user_profile.surname"
            />
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">
            RUT <span class="error__required">*</span>
          </div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              :class="!nin_required ? '' : 'error_field_required'"
              type="text"
              name=""
              @change="setValidatorClear('nin')"
              @keyup="formatRUT(user_profile)"
              v-model="user_profile.nin"
            />
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Fecha de nacimiento</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="date"
              name=""
              v-model="user_profile.birth_date"
            />
          </div>
        </div>

        <div class="field_edit_employee option__create">
          <div class="label-primary">Estado Civil</div>
          <div class="custom-select">
            <select class="input-form" v-model="user_profile.marital_status">
              <option
                v-for="(option, index) in marital_status"
                :key="index"
                :value="option"
              >
                {{ index }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Telefono</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.phone"
            />
          </div>
        </div>
        <div class="field_edit_employee">
          <div class="label-primary">
            Email <span class="error__required">*</span>
          </div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              :class="!email_required ? '' : 'error_field_required'"
              type="text"
              name="email"
              @blur="setValidatorClear('email')"
              v-model="user_profile.email"
            />
          </div>
          <span
            :class="errorColorEmail == false ? 'error-span' : 'approved-span'"
            v-if="errorfieldEmail"
            >{{ displayErrorMsg }}</span
          >
        </div>
      </div>
      <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

      <div class="right-side-edit">
        <div class="field_edit_employee">
          <div class="label-primary">Segundo Nombre</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.middle_name"
            />
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Apellido Materno</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.second_surname"
            />
          </div>
        </div>

        <div class="field_edit_employee option__create">
          <div class="label-primary">
            Nacionalidad <span class="error__required">*</span>
          </div>
          <div class="value-primary fix__empty__field">
            <select
              class="input-form input_edit_employee"
              type="text"
              v-model="user_profile.nationality_id"
              :class="!nationality_required ? '' : 'error_field_required'"
              @change="setNationality(), setValidatorClear('nationality_id')"
            >
              <option selected hidden disabled>
                Selecciona tu nacionalidad
              </option>
              <option
                v-for="(country, index2) in country_list"
                :key="index2"
                :value="country.code"
              >
                {{ country.demonym }}
              </option>
            </select>
          </div>
        </div>

        <div class="field_edit_employee option__create">
          <div class="label-primary">Región</div>
          <div class="value-primary fix__empty__field">
            <div class="custom-select">
              <select
                class="input-form"
                v-model="country_name"
                @change="setState($event)"
              >
                <option
                  v-for="(state, index2) in state_list"
                  :key="index2"
                  :value="state.name"
                >
                  {{ state.name }}
                </option>
              </select>
              <span class="custom-arrow-b"></span>
            </div>
          </div>
        </div>

        <div class="field_edit_employee option__create">
          <div class="label-primary">Comuna</div>
          <div class="value-primary fix__empty__field">
            <div class="custom-select">
              <select
                class="input-form"
                v-model="country_city"
                @change="setCity($event)"
              >
                <option
                  v-for="(city, index) in city_list"
                  :key="index"
                  :value="city.name"
                >
                  {{ city.name }}
                </option>
              </select>
              <span class="custom-arrow-b"></span>
            </div>
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Dirección particular</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.address"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="footer__profile__employees">
      <div class="edit__user" @click="backStep">Volver</div>
      <div class="footer__double__button">
        <div class="edit__user__secondary" @click="CancelCreationEmployee()">
          Cancelar
        </div>
        <div class="edit__user__continue" @click="nextStep">
          Guardar y continuar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CancelModal from "../modals/ModalCancelCreationEmployee.vue";
import formatRut from "@/utils/scriptRUT";
import employeeServiceV2 from "../../services/employeService";
export default {
  created() {
    this.user_profile = this.baseInformation;

    setTimeout(() => {
      this.state_list = this.regionListEmployee;
      this.city_list = this.cityListEmployee;

      let validUpdateRegionValue = this.baseInformation.state_id;
      let validUpdateComunaValue = this.baseInformation.city_id;
      if (validUpdateRegionValue != null) {
        this.setStateReload(this.user_profile.state_id);
      }
      if (validUpdateComunaValue != null) {
        this.setComunaReload(this.user_profile.city_id);
      }
    }, 500);
    if (this.typeUser == "Miembro") {
      this.user_profile.is_member = true;
    } else {
      this.user_profile.is_member = false;
    }
  },
  mounted() {
    this.country_list = this.nationalityEmployee;
  },
  data: () => {
    return {
      user_profile: {
        first_name: null,
        middle_name: null,
        surname: null,
        second_surname: null,
        nin: null,
        email: null,
        marital_status: null,
        address: null,
        nationality_id: null,
        state_id: null,
        city_id: null,
        birth_date: null,
        phone: null,
        is_member: null,
      },
      marital_status: {
        "Soltero/a": 0,
        "Casado/a": 1,
        "Conviviente civil": 2,
        "Divorciado/a": 3,
        "Viudo/a": 4,
      },
      //validators
      first_name_required: false,
      surname_required: false,
      nin_required: false,
      email_required: false,
      nationality_required: false,
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      displayErrorMsg: "",
      errorColorEmail: false,
      errorfieldEmail: false,
      errorMsgEmailCode200: "Nuevo usuario",
      errorMsgEmailCode202: "Usuario existente en otra organización",
      errorMsgEmailCode403:
        "Este usuario no puede ser creado en esta organización",

      //select option required
      country_name: "",
      country_city: "",

      country_list: [],
      city_list: [],
      state_list: [],
    };
  },
  components: { CancelModal },
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployees: "stepEmployees",
      typeUser: "typeUser",
      baseInformation: "baseInformation",
      nationalityEmployee: "nationalityEmployee",
      regionListEmployee: "regionListEmployee",
      cityListEmployee: "cityListEmployee",
      typeUserCompanyId: "typeUserCompanyId",
    }),
    ...mapGetters("Login", {
      group: "group",
      user_profile_data: "user_profile",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
      nameMainTenant: "nameMainTenant",
    }),
  },
  methods: {
    CancelCreationEmployee() {
      let param = "modal__cancel__creation__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__cancel__creation__employee;
      element.open();
    },
    backStep() {
      if (this.stepEmployees <= 0) {
        if (this.group == "Admin") {
          this.$store.dispatch("EmployeesV2/cleancounterStepEmployee");
          this.$router.push({ name: "selectedEmployeeAdmin" });
        } else {
          this.$store.dispatch("EmployeesV2/cleancounterStepEmployee");
          this.$router.push({ name: "selectedEmployee" });
        }
      } else {
        let decrement = this.stepEmployees - 1;
        this.$store.dispatch("EmployeesV2/counterStepEmployee", decrement);
      }
    },
    setValidatorClear(el) {
      //set validator for mandatory fields
      let validatorName =
        this.user_profile.first_name.length > 0 ? false : true;

      let validatorSurname =
        this.user_profile.surname.length > 0 ? false : true;

      let validatorNin = this.user_profile.nin.length > 0 ? false : true;

      let validatorEmailRegex = false;
      let email = this.user_profile.email;

      let validatorNationality =
        this.user_profile.nationality_id.length > 0 ? false : true;

      if (el == "nationality_id") {
        this.nationality_required = validatorNationality;
      }
      if (el == "first_name") {
        this.first_name_required = validatorName;
      }
      if (el == "surname") {
        this.surname_required = validatorSurname;
      }
      if (el == "nin") {
        let flagRutValidator = this.validateRutInnitialEmployee(
          this.user_profile.nin
        );

        if (!flagRutValidator) {
          this.nin_required = false;
          validatorNin = false;
        } else {
          // set error in rut validator
          this.nin_required = true;
          validatorNin = true;
        }
      }
      if (el == "email") {
        let validEmail = this.reg.test(this.user_profile.email);

        let admin_company_id = "";
        if (validEmail) {
          if (this.typeUserCompanyId != null) {
            admin_company_id = this.typeUserCompanyId;
          } else {
            admin_company_id = this.inSwitch
              ? this.idInSwitch
              : this.user_profile_data.company.id;
          }
          employeeServiceV2
            .getVerificationEmail(admin_company_id, email)
            .then((data) => {
              this.errorColorEmail = true;
              if (data == "200") {
                this.errorfieldEmail = true;
                this.email_required = false;
                validatorEmailRegex = false;
                this.displayErrorMsg = this.errorMsgEmailCode200;
              } else if (data == "202") {
                this.errorfieldEmail = true;
                validatorEmailRegex = false;
                this.email_required = false;
                this.displayErrorMsg = this.errorMsgEmailCode202;
              }
            })
            .catch(() => {
              this.errorColorEmail = false;
              this.errorfieldEmail = true;
              validatorEmailRegex = true;
              this.email_required = true;
              this.displayErrorMsg = this.errorMsgEmailCode403;
            });
        } else {
          // set true invalid email
          validatorEmailRegex = true;
          // set color error in input email field
          this.email_required = true;
        }
      }

      if (el == "all") {
        this.nationality_required = validatorNationality;
        this.first_name_required = validatorName;
        this.surname_required = validatorSurname;

        let flagRutValidator = this.validateRutInnitialEmployee(
          this.user_profile.nin
        );

        if (!flagRutValidator) {
          this.nin_required = false;
          validatorNin = false;
        } else {
          // set error in rut validator
          this.nin_required = true;
          validatorNin = true;
        }

        let validEmail = this.reg.test(this.user_profile.email);
        if (validEmail) {
          let admin_company_id = this.inSwitch
            ? this.idInSwitch
            : this.user_profile_data.company.id;

          employeeServiceV2
            .getVerificationEmail(admin_company_id, email)
            .then((data) => {
              this.errorColorEmail = true;
              if (data == "200") {
                this.errorfieldEmail = true;
                this.email_required = false;
                validatorEmailRegex = false;
                this.displayErrorMsg = this.errorMsgEmailCode200;
              } else if (data == "202") {
                this.errorfieldEmail = true;
                validatorEmailRegex = false;
                this.email_required = false;
                this.displayErrorMsg = this.errorMsgEmailCode202;
              }
            })
            .catch(() => {
              this.errorColorEmail = false;
              this.errorfieldEmail = true;
              validatorEmailRegex = true;
              this.email_required = true;
              this.displayErrorMsg = this.errorMsgEmailCode403;
            });
        } else {
          // set true invalid email
          validatorEmailRegex = true;
          // set color error in input email field
          this.email_required = true;
        }
      }

      // validatorNin return true if rut is invalid

      if (
        validatorName ||
        validatorSurname ||
        validatorNin ||
        validatorNationality ||
        validatorEmailRegex
      ) {
        //return error set as true stop increment step
        return true;
      } else {
        // increment step in +1
        return false;
      }
    },
    validateRutInnitialEmployee(rut) {
      rut = rut.replace(/\./g, "");
      rut = rut.trim();
      if (rut.length >= 8) {
        let rutFormatted = formatRut.format(rut);
        let rutValid = formatRut.validaRut(rutFormatted.trim());
        rut = rutFormatted;
        this.validatorRutInnitalSigner = rutValid;
        this.rutInnitialValue = rutFormatted;
        if (!this.validatorRutInnitalSigner) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    formatRUT(user_profile) {
      user_profile.nin = user_profile.nin.replace(/\./g, "");
      if (user_profile.nin.length >= 8) {
        let rutformatted = formatRut.format(user_profile.nin);
        user_profile.nin = rutformatted;
      }
    },
    nextStep() {
      if (this.stepEmployees >= 2) {
        this.$router.push({ name: "ListEmployeesV2" });
      } else {
        let validtorFlow = this.setValidatorClear("all");

        // if validatorflow is false go to next step
        if (!validtorFlow) {
          this.$store.dispatch(
            "EmployeesV2/saveBaseUserInformation",
            this.user_profile
          );
          let increment = this.stepEmployees + 1;
          this.$store.dispatch("EmployeesV2/counterStepEmployee", increment);
        }
      }
    },
    setNationality() {
      let codeNationality = this.nationality_state;

      this.country_list.filter((el) => {
        if (el.name == codeNationality) {
          this.user_profile.nationality_id = el.id;
        }
      });
    },
    setState(evt) {
      let searchState = this.state_list.filter(
        (state) => state.name === evt.target.value
      );

      this.user_profile.state_id = searchState[0].id;
      this.country_name = searchState[0].name;

      let payload = {
        countryID: this.company_id_regionV2,
        stateID: this.user_profile.state_id,
      };

      this.$store.dispatch("EmployeesV2/get_list_city", payload).then(() => {
        this.city_list = this.cityListEmployee;
      });
    },
    setStateReload(evt) {
      let searchSatate = this.state_list.filter((state) => state.id === evt);

      this.country_name = searchSatate[0].name;
    },
    setCity(evt) {
      let name_city = evt.target.value;
      this.country_city = name_city;

      let searchCity = this.city_list.filter((city) => city.name === name_city);

      this.user_profile.city_id = searchCity[0].id;
    },
    setComunaReload(evt) {
      let searchComuna = this.city_list.filter((state) => state.id === evt);

      this.country_city = searchComuna[0].name;
    },
  },
  watch: {
    cityListEmployee() {
      this.city_list = this.cityListEmployee;
    },
  },
};
</script>

<style></style>
