var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__warning__empty__signers",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : 'modal__default',attrs:{"id":"modal__warning__empty__signers"}},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_c('div',{staticClass:"company_svg"},[_c('logoTipoEmpresa'),_c('div',{staticClass:"logo_name"},[_vm._v("MisAbogados")])],1),_c('div',{staticClass:"modal__user__empty"},[_c('div',{staticClass:"text-filter-light"},[_vm._v("Tienes algunos datos sin ingresar")]),_c('div',{staticClass:"text-filter-light m-top-s font__size_subtitle_empty body_empty_signers"},[_vm._m(0),_c('div',{staticClass:"container__add__signer__modal fix_color_empty_modal"},[_c('input',{staticClass:"input-form disable__input",attrs:{"type":"text","disabled":"disabled","value":"Fransico"}}),_vm._m(1),_vm._m(2),_c('div',{staticClass:"svg_empty_signer"},[_c('confirmFirma')],1)])]),_c('div',{staticClass:"svg_empty_signer_hand"},[_c('handClick')],1)]),_c('div',{staticClass:"footer_modal__empty_signers"},[_c('div',{staticClass:"btn-vue-primary m-bottom-xl btn__width",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Volver ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-filter font__size_subtitle_empty"},[_vm._v(" Para ingresar el firmante recuerda hacer click en el "),_c('span',{staticClass:"check"},[_vm._v("check")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field__rut fix_color_empty_modal"},[_c('input',{staticClass:"input-form disable__input",attrs:{"type":"text","disabled":"disabled","value":"tucorreo@gmail.com"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field__rut fix_color_empty_modal"},[_c('input',{staticClass:"input-form disable__input",attrs:{"type":"text","disabled":"disabled","value":"1234567-8"}})])
}]

export { render, staticRenderFns }