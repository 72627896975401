<template>
  <div class="cards_container">
    <div class="cards metrics">
      <cardDocuments
        title="Documentos"
        v-bind:DocumentosEmitidos="DocumentosEmitidos"
      />

      <cardRequest title="Solicitudes" v-bind:Request="Request" />
    </div>
    <div class="cards metrics">
      <cardQuestion title="Preguntas Realizadas" v-bind:Question="Question" />
      <cardUsers title="Usuarios" v-bind:Users="Users" />
    </div>
  </div>
</template>

<script>
import cardDocuments from "@/modules/home/components/card-features/cardDocuments.vue";
import cardRequest from "@/modules/home/components/card-features/cardRequest.vue";
import cardQuestion from "@/modules/home/components/card-features/cardQuestion.vue";
import cardUsers from "@/modules/home/components/card-features/cardUsers.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    cardDocuments,
    cardRequest,
    cardQuestion,
    cardUsers,
  },

  data: () => {
    return {
      DocumentosEmitidos: [],
      Request: {},
      Question: {},
      Users: {},
      flagLoadDataCards: false,
    };
  },

  computed: {
    ...mapGetters("Home", {
      dataDocuments: "dataDocuments",
      dataRequest: "dataRequest",
      dataQuestion: "dataQuestion",
      dataUsers: "dataUsers",
    }),

    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
    }),
  },

  methods: {
    loadDataDocuments() {
      const aux = this.dataDocuments;
      const arrayDocuments = Object.entries(aux);

      let resultArray = [];

      arrayDocuments.forEach((element) => {
        let data = {
          name: element[0],
          value: element[1].toString(),
          valueNumber: element[1],
        };

        resultArray.push(data);
      });

      this.DocumentosEmitidos = resultArray;
    },
    loadDataRequest() {
      const aux = this.dataRequest;
      const arrayRequest = Object.entries(aux);

      let resultArray = [];

      arrayRequest.forEach((element) => {
        let data = {
          month: element[0].slice(0, 3),
          value: element[1].toString(),
          valueNumber: element[1],
        };

        resultArray.push(data);
      });

      this.Request = resultArray;
    },
    loadDataQuestion() {
      const aux = this.dataQuestion;
      const arrayQuestions = Object.entries(aux);

      let resultArray = [];

      arrayQuestions.forEach((element) => {
        let data = {
          month: element[0].slice(0, 3),
          value: element[1].toString(),
          valueNumber: element[1],
        };

        resultArray.push(data);
      });

      this.Question = resultArray;
    },
    loadDataUsers() {
      let aux = this.dataUsers;
      let resultArray = [];

      aux.forEach((element) => {
        let data = {
          name: element.user,
          rol:
            element.role === "Company-Admin"
              ? "Administrador"
              : element.role === "Company-Executive"
              ? "Ejecutivo"
              : element.role === "Company-Employee"
              ? "Empleado"
              : element.role === "Admin"
              ? "Superadmin"
              : element.role === "Agent"
              ? "Agente"
              : element.role === "Lawyer"
              ? "Abogado"
              : element.role === "Entel-Employee"
              ? "Entel"
              : "",
          date: element.date,
        };

        resultArray.push(data);
      });
      this.Users = resultArray;
    },
  },

  created() {
    this.loadDataDocuments();
    this.loadDataRequest();
    this.loadDataQuestion();
    this.loadDataUsers();
  },
};
</script>

<style lang="scss">
@import "../../../modules/home/home.scss";

.cards_container {
  display: grid;
  grid-gap: 15px;
  > .cards {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr)) !important;
    @media screen and (max-width: 765px) and (min-width: 200px) {
      grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr)) !important;
    }
  }
}
</style>
