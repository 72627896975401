//container signatures
export const FETCH_DIGITAL_SIGNATURE_SUCCESS =
  "FETCH_DIGITAL_SIGNATURE_SUCCESS";
export const FETCH_DIGITAL_SIGNATURE_FAILURE =
  "FETCH_DIGITAL_SIGNATURE_FAILURE";

export const FETCH_DIGITAL_SIGNATURE_DATA = "FETCH_DIGITAL_SIGNATURE_DATA";
export const FETCH_DRAFT_DIGITAL_SIGNATURE_DATA =
  "FETCH_DRAFT_DIGITAL_SIGNATURE_DATA";
export const SET_DATA_CHECK = "SET_DATA_CHECK";
export const SET_FLAG = "SET_FLAG";
export const SET_TOTAL_DATA = "SET_TOTAL_DATA";
export const SET_TOTAL_DATA_VIEW = "SET_TOTAL_DATA_VIEW";
export const SET_PREV_PAGE = "SET_PREV_PAGE";
export const SET_NEXT_PAGE = "SET_NEXT_PAGE";
export const SET_TAGS_REQUEST = "SET_TAGS_REQUEST";
export const SET_TAGS_REQUEST_FRONT = "SET_TAGS_REQUEST_FRONT";
export const SET_TAGS = "SET_TAGS";
export const SET_GLOBAL_LOADER = "SET_GLOBAL_LOADER";
export const MISSING_SIGNER_VALIDATOR_NAME = "MISSING_SIGNER_VALIDATOR_NAME";
export const MISSING_SIGNER_VALIDATOR_EMAIL = "MISSING_SIGNER_VALIDATOR_EMAIL";
export const MISSING_SIGNER_VALIDATOR_RUT = "MISSING_SIGNER_VALIDATOR_RUT";

// status pending

export const FETCH_DIGITAL_SIGNATURE_PENDING_SUCCESS =
  "FETCH_DIGITAL_SIGNATURE_PENDING_SUCCESS";
export const FETCH_DIGITAL_SIGNATURE_PENDING_FAILURE =
  "FETCH_DIGITAL_SIGNATURE_PENDING_FAILURE";
export const FETCH_DIGITAL_SIGNATURE_DATA_PENDING =
  "FETCH_DIGITAL_SIGNATURE_DATA_PENDING";

// Status completed

export const FETCH_DIGITAL_SIGNATURE_COMPLETED_SUCCESS =
  "FETCH_DIGITAL_SIGNATURE_COMPLETED_SUCCESS";
export const FETCH_DIGITAL_SIGNATURE_COMPLETED_FAILURE =
  "FETCH_DIGITAL_SIGNATURE_COMPLETED_FAILURE";
export const FETCH_DIGITAL_SIGNATURE_DATA_COMPLETED =
  "FETCH_DIGITAL_SIGNATURE_DATA_COMPLETED";

// draft

export const FETCH_DIGITAL_SIGNATURE_DRAFT_SUCCESS =
  "FETCH_DIGITAL_SIGNATURE_DRAFT_SUCCESS";
export const FETCH_DIGITAL_SIGNATURE_DRAFT_FAILURE =
  "FETCH_DIGITAL_SIGNATURE_DRAFT_FAILURE";
export const FETCH_DIGITAL_SIGNATURE_DATA_DRAFT =
  "FETCH_DIGITAL_SIGNATURE_DATA_DRAFT";
export const ADD_SIGNER_FROM_DRAFT = "ADD_SIGNER_FROM_DRAFT";
export const CLEAN_SIGNER_FROM_DRAFT = "CLEAN_SIGNER_FROM_DRAFT";
export const DELETE_SIGNER_FROM_DRAFT = "DELETE_SIGNER_FROM_DRAFT";
export const CLEAN_FLAG_DRAFT_DATA = "CLEAN_FLAG_DRAFT_DATA";
export const SET_FLAG_DRAFT_DATA = "SET_FLAG_DRAFT_DATA";

// categorias de documentos

export const LIST_CATEGORYS = "LIST_CATEGORYS";

// documentos
export const LIST_DOCUMENTS = "LIST_DOCUMENTS";
export const CLEAN_DOCUMENTS = "CLEAN_DOCUMENTS";
export const LOADER_DOCUMENTS_OFF = "LOADER_DOCUMENTS_OFF";
export const LOADER_DOCUMENTS_ON = "LOADER_DOCUMENTS_ON";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const SET_DOCUMENT_FROM_LOCAL = "SET_DOCUMENT_FROM_LOCAL";
export const CLEAN_DOCUMENTS_NAME = "CLEAN_DOCUMENTS_NAME";
export const SET_DOCUMENT_FROM_APP_ACTIVE = "SET_DOCUMENT_FROM_APP_ACTIVE";
export const SET_DOCUMENT_FROM_APP_DESACTIVE =
  "SET_DOCUMENT_FROM_APP_DESACTIVE";
export const CLEAN_LOAD_DOCUMENT = "CLEAN_LOAD_DOCUMENT";
export const DELETE_DIGITAL_SIGNATURE = "DELETE_DIGITAL_SIGNATURE";
export const FLAG_DELETE_DIGITAL_SIGNATURE_ON =
  "FLAG_DELETE_DIGITAL_SIGNATURE_ON";
export const FLAG_DELETE_DIGITAL_SIGNATURE_OFF =
  "FLAG_DELETE_DIGITAL_SIGNATURE_OFF";
export const SET_FILE_URL = "SET_FILE_URL";
export const CLEAN_FILE_URL = "CLEAN_FILE_URL";

// FIRMANTES
export const ADD_SIGNER = "ADD_SIGNER";
export const CLEAN_SIGNER = "CLEAN_SIGNER";
export const NEW_TOTAL_SIGNERS = "NEW_TOTAL_SIGNERS";

//add check signer main user
export const ADD_SIGNER_FROM_CHECK = "ADD_SIGNER_FROM_CHECK";
export const CLEAN_SIGNER_FROM_CHECK = "CLEAN_SIGNER_FROM_CHECK";

//add initial signer for interface
export const ADD_INITIAL_SIGNER = "ADD_INITIAL_SIGNER";
export const CLEAN_INITIAL_SIGNER = "CLEAN_INITIAL_SIGNER";

// tracking elements
export const ADD_TRACKING = "ADD_TRACKING";
export const CLEAN_TRACKING = "CLEAN_TRACKING";

// enviar documentos a firmar

export const SEND_DOCUMENT_TO_SING = "SEND_DOCUMENT_TO_SING";
export const SEND_DOCUMENT_TO_SING_FROM_APP = "SEND_DOCUMENT_TO_SING_FROM_APP";

// flags para setear el tipo de busqueda desde pre-step
export const SET_SEARCH_FINALIZADOS = "SET_SEARCH_FINALIZADOS";
export const SET_SEARCH_PENDING = "SET_SEARCH_PENDING";
export const SET_SEARCH_ALL = "SET_SEARCH_ALL";

//loader document from app
export const LOAD_DOCUMENT_FROM_APP = "LOAD_DOCUMENT_FROM_APP";
export const CANCEL_LOAD_DOCUMENT_FROM_APP = "CANCEL_LOAD_DOCUMENT_FROM_APP";

export const CLEAN_COUNTER__STEP__DIGITAL_ONBOARDING =
  "CLEAN_COUNTER__STEP__DIGITAL_ONBOARDING";
export const COUNTER__STEP__DIGITAL_ONBOARDING =
  "COUNTER__STEP__DIGITAL_ONBOARDING";
