<template>
  <div class="container_module">
    <div class="container-slots container-base">
      <div>
        <ModalConfirmDrafDocument
          ref="modal__confirm__document__draft"
          :documentForModalConfirm="documentForModalConfirm"
          :isFile="isFile"
          :name_document="name_document"
          :id_document="id_document"
          :Tags="tags"
          :File="File"
          :signers="singerToModal"
          :totalSigners="totalSigners"
          :sign_type="sign_type"
          :sign_status="sign_status"
          size="count"
        />
      </div>
      <div>
        <ModalForAlertMissingSigners
          ref="modal__warning__empty__signers"
          size="m"
        />
      </div>
      <div>
        <ModalComingSoon ref="modal__coming__soon" size="xs" />
      </div>
      <div>
        <ModalConfirmSendDocument
          ref="modal__confirm__document"
          :documentForModalConfirm="documentForModalConfirm"
          :isFile="isFile"
          :name_document="name_document"
          :id_document="id_document"
          :Tags="tags"
          :File="File"
          :signers="singerToModal"
          :totalSigners="totalSigners"
          :sign_type="sign_type"
          size="count"
        />
      </div>

      <div class="border__bottom">
        <div class="breadcrumb">
          <span
            class="sign__digital text_document"
            @click="goToListSignature()"
          >
            Firma Digital >
          </span>
          <span class="text_document">Firmar Documento</span>
        </div>
      </div>
      <div class="">
        <div class="container__add__signers">
          <div class="left__add__signer">
            <div class="label-primary">Seleccione el tipo de firma</div>
            <div class="select__type__digital__signature m-top-s">
              <div class="container__digital_simple">
                <div
                  v-if="disable_signature_simple"
                  class="signature__simple"
                  :class="f_simple ? 'f_simple' : 'desactive'"
                  @click="selectedSimpleSignature()"
                >
                  <div class="">Firma Electrónica Simple (FES)</div>
                </div>
                <div
                  class="signature__simple"
                  v-else
                  :class="f_simple ? 'f_simple' : 'desactive'"
                  @click="selectedSimpleSignature()"
                >
                  <div class="">Firma Electrónica Simple (FES)</div>
                </div>

                <div class="sub_title_signature">
                  La firma electrónica simple (FES) es una propiedad digital no
                  certificada que permite identificar al autor de un documento.
                  Su definición está basada en un concepto residual:
                  “corresponde a toda aquella firma que no es avanzada”.
                </div>
              </div>

              <div class="container__digital_simple container_difital_advanced">
                <div
                  v-if="disable_signature_advanced"
                  class="signature_advanced signature__off"
                  :class="f_advanced ? 'f_simple' : 'desactive'"
                  @click="show_modal_coming()"
                >
                  <div class="">Firma Electrónica Avanzada (FEA)</div>

                  <div class=""></div>
                </div>
                <div
                  v-else
                  class="signature_advanced signature__off"
                  :class="f_advanced ? 'f_simple' : 'desactive'"
                  @click="show_modal_coming()"
                >
                  <div class="">Firma Electrónica Avanzada (FEA)</div>
                </div>
                <div class="sub_title_signature">
                  <!-- La firma electrónica avanzada (FEA), a diferencia de la simple,
                es certificada por un prestador acreditado y permite que
                cualquier documento e instrumento -sea privado o público- pueda
                ser firmado digitalmente. -->
                  Firma Electrónica Avanzada estará disponible próximamente
                </div>
              </div>
            </div>
            <div class="label-primary title_signer">
              Añadir firmantes del documento
            </div>
            <!-- add user -->
            <Signer />
            <div
              class="border__bottom m-top-s"
              v-if="signerDraft != null"
            ></div>
            <div class="label-primary m-top-s" v-if="signerDraft != null">
              Firmantes guardados previamente
            </div>

            <SignerFromDraft />
          </div>

          <div class="right__add_signer">
            <div class="container__data__signer">
              <div class="label-primary">Documento</div>
              <!-- //TODO: Data from state name document -->
              <div class="sub-title-table">
                {{
                  dataDumentToSingFromMB != null
                    ? dataDumentToSingFromMB.name
                    : documentFromLocal != null
                    ? documentFromLocal.name
                    : "vacio"
                }}
              </div>
            </div>
            <div class="container__data__signer">
              <div class="label-primary">Identificador</div>
              <!-- //TODO: Data from state name document -->
              <div class="sub-title-table">
                {{
                  dataDumentToSingFromMB != null
                    ? dataDumentToSingFromMB.tags
                    : documentFromLocal != null
                    ? documentFromLocal.tags
                    : "vacio"
                }}
              </div>
            </div>
            <div class="container__data__signer">
              <div class="label-primary">Tipo de Firma</div>
              <!-- //TODO: Data from state name document -->
              <div v-if="f_simple_name != null" class="sub-title-table">
                {{ f_simple_name }}
              </div>
              <div v-else-if="f_advanced_name != null" class="sub-title-table">
                {{ f_advanced_name }}
              </div>

              <div v-else class=""></div>
            </div>
            <div class="container__data__signer">
              <div class="label-primary">Firmantes</div>
              <!-- //TODO: Data from state name document -->

              <div
                class="sub-title-table signer__data"
                v-if="signerFromCheck != null"
              >
                <div class="bold_title">
                  {{ signerFromCheck.name ? signerFromCheck.name : "" }}
                </div>
                <div class="">
                  {{ signerFromCheck.rut ? signerFromCheck.rut : "" }}
                </div>
                <div class="">
                  {{ signerFromCheck.email ? signerFromCheck.email : "" }}
                </div>
              </div>

              <div
                class="sub-title-table signer__data"
                v-if="initialSigner != null"
              >
                <div class="bold_title">
                  {{ initialSigner.name }}
                </div>
                <div class="">
                  {{ initialSigner.rut }}
                </div>
                <div class="">
                  {{ initialSigner.email }}
                </div>
              </div>

              <div
                class="sub-title-table signer__data"
                v-for="(sign, index) in signers"
                :key="index"
              >
                <div class="bold_title">
                  {{ sign.name }}
                </div>
                <div class="">
                  {{ sign.rut }}
                </div>
                <div class="">
                  {{ sign.email }}
                </div>
              </div>
            </div>
            <div class="container__data__signer">
              <div class="label-primary" v-if="signerDraft">
                Firmantes guardados previamente
              </div>

              <div
                class="sub-title-table signer__data"
                v-for="(signerElement, indexDraft) in signerDraft"
                v-bind:key="indexDraft"
              >
                <div class="bold_title">
                  {{ signerElement.name ? signerElement.name : "" }}
                </div>
                <div class="">
                  {{ signerElement.rut ? signerElement.rut : "" }}
                </div>
                <div class="">
                  {{ signerElement.email ? signerElement.email : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="signature__footer__buttons footer_signers m-top-l">
          <button class="secondary_button" @click="backButton()">Volver</button>
          <button
            class=""
            :class="
              f_simple || f_advanced ? 'btn-vue-primary' : 'desactive_set_draf'
            "
            @click="sendDataModalDraft()"
          >
            Guardar borrador
          </button>
          <button
            class=""
            :class="
              f_simple || f_advanced ? 'btn-vue-primary' : 'desactive_set_draf'
            "
            @click="sendDataModal()"
          >
            Enviar Documento
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Signer from "../components/Signer.vue";
import { mapGetters, mapState } from "vuex";
import SignerFromDraft from "../components/SignerFromDraft.vue";
import ModalComingSoon from "../components/modals/modalComingSoonV2.vue";
import ModalConfirmSendDocument from "../components/modals/modalConfirmSendDocumentV2.vue";
import ModalConfirmDrafDocument from "../components/modals/modalConfirmDrafDocumentV2.vue";
import ModalForAlertMissingSigners from "../components/modals/modalForAlertMissingSigners.vue";

export default {
  name: "AddSigners",
  components: {
    Signer,
    SignerFromDraft,
    ModalComingSoon,
    ModalConfirmSendDocument,
    ModalConfirmDrafDocument,
    ModalForAlertMissingSigners,
  },
  mounted() {
    window.onpopstate = () => {
      if (this.dataDumentToSingFromMB == null) {
        if (this.documentFromLocal == null) {
          this.$router.push({ name: "NewDigitalSignature" });
        }
      }
    };
    // desde borrador con esto seteamos el tipo de firma en la vista
    if (this.dataDumentToSingFromMB != null) {
      if (this.dataDumentToSingFromMB.sign_type == 1) {
        this.f_simple = true;
        this.f_simple_name = "Firma Electrónica Simple (FES)";
      } else if (this.dataDumentToSingFromMB.sign_type == 2) {
        this.f_advanced = true;
        this.f_advanced_name = "Firma Electrónica Avanzada (FEA)";
      }
    }
  },
  data: () => {
    return {
      f_simple: false,
      f_simple_name: null,
      f_advanced: false,
      f_advanced_name: null,
      disable_signature_simple: true,
      disable_signature_advanced: true,
      signerInitialTemp: null,
      signerInitialTempData: null,
      signerunitsTempData: null,
      full_signers: [],
      documentForModalConfirm: null,
      isFile: false,
      name_document: "",
      id_document: "",
      tags: "",
      File: "",
      sign_type: null,
      singerToModal: null,
      totalSigners: null,
      sign_status: null,
    };
  },

  computed: {
    ...mapGetters("DigitalSignature", {
      signerFromCheck: "signerFromCheck",
      initialSigner: "initialSigner",
      signers: "signers",
      signerDraft: "signerDraft",
      missingSingerValidatorName: "missingSingerValidatorName",
      missingSingerValidatorEmail: "missingSingerValidatorEmail",
      missingSingerValidatorRut: "missingSingerValidatorRut",
    }),

    ...mapState("DigitalSignature", {
      documentFromApp: "documentFromApp",
      documentFromLocal: "documentFromLocal",
      signersUnit: "signers",
      dataDumentToSingFromMB: "dataDumentToSingFromMB",
      FlagDataFromDraft: "flagDraftData",
    }),
  },

  methods: {
    goToListSignature() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
      this.$router.push({ name: "DigitalSignature" });
    },
    backButton() {
      if (this.documentFromApp) {
        if (this.FlagDataFromDraft) {
          this.$router.push({ name: "DraftElements" });
        } else {
          this.$router.push({ name: "OriginFromApp" });
        }
      } else if (this.FlagDataFromDraft) {
        this.$router.push({ name: "DraftElements" });
      } else {
        this.$router.push({ name: "OriginFromLocal" });
      }
    },
    show_modal_confirm_draf() {
      let element = this.$refs.modal_draft;
      element.open();
    },

    sendDataModalDraft() {
      if (
        this.missingSingerValidatorName ||
        this.missingSingerValidatorEmail ||
        this.missingSingerValidatorRut
      ) {
        this.show_modal_empty_warning();
      } else {
        let dataDocument;

        if (this.dataDumentToSingFromMB != null) {
          this.isFile = false;
          dataDocument = this.dataDumentToSingFromMB;
          this.id_document = dataDocument.id;
          this.File = null;
        } else {
          this.isFile = true;
          dataDocument = this.documentFromLocal;
          this.File = dataDocument.file;
        }

        this.name_document = dataDocument.name;

        if (dataDocument.tags != null) {
          this.tags = dataDocument.tags;
        } else {
          this.tags = null;
        }

        if (this.initialSigner != null) {
          this.full_signers = [];
          this.signerInitialTempData = null;
          this.signerunitsTempData = null;

          let data = {
            name:
              this.initialSigner.name != null ? this.initialSigner.name : null,
            email:
              this.initialSigner.email != null
                ? this.initialSigner.email
                : null,
            rut: this.initialSigner.rut != null ? this.initialSigner.rut : null,
          };

          this.signerInitialTempData = data;
          this.signerunitsTempData = this.signersUnit;

          if (this.signersUnit.length > 0) {
            this.full_signers.push(...this.signerunitsTempData);
          }

          if (this.signerFromCheck != null) {
            this.full_signers.push(this.signerFromCheck);
          }

          //listado de firmantes
          this.full_signers.push(this.signerInitialTempData);

          // tipo de firma

          let sign_type;

          if (this.f_simple_name != null) {
            //firma electronica simple
            sign_type = 0;
          } else if (this.f_advanced_name != null) {
            sign_type = 1;
          }

          this.sign_type = sign_type;

          if (this.signerDraft != null) {
            let signerDraftForSing = this.signerDraft;
            for (const draftSigner of signerDraftForSing) {
              this.full_signers.push(draftSigner);
            }
          }
          this.singerToModal = this.full_signers;
          this.totalSigners = this.full_signers.length;

          // para borradores
          let sign_status = 1;
          this.sign_status = sign_status;

          let param = "modal__confirm__document__draft";
          this.$store.dispatch("Config/setModalRef", param);
          let element = this.$refs.modal__confirm__document__draft;
          element.open();
          // enviar todos los firmantes al modal
        } else if (this.signerFromCheck != null) {
          this.full_signers = [];
          this.full_signers.push(this.signerFromCheck);

          let sign_type;

          if (this.f_simple_name != null) {
            //firma electronica simple
            sign_type = 0;
          } else if (this.f_advanced_name != null) {
            sign_type = 1;
          }
          this.sign_type = sign_type;

          // let sign_status = 2;

          if (this.signerDraft != null) {
            let signerDraftForSing = this.signerDraft;
            for (const draftSigner of signerDraftForSing) {
              this.full_signers.push(draftSigner);
            }
          }
          this.singerToModal = this.full_signers;
          this.totalSigners = this.full_signers.length;

          let sign_status = 1;
          this.sign_status = sign_status;

          let param = "modal__confirm__document__draft";
          this.$store.dispatch("Config/setModalRef", param);
          let element = this.$refs.modal__confirm__document__draft;
          element.open();
          // enviar todos los firmantes al modal
        } else if (this.signerDraft != null) {
          let sign_type;
          this.full_signers = [];

          if (this.f_simple_name != null) {
            //firma electronica simple
            sign_type = 0;
          } else if (this.f_advanced_name != null) {
            sign_type = 1;
          }
          this.sign_type = sign_type;
          let signerDraftForSing = this.signerDraft;
          for (const iterator of signerDraftForSing) {
            this.full_signers.push(iterator);
          }
          this.singerToModal = this.full_signers;
          this.totalSigners = this.full_signers.length;

          let validateSigner = this.totalSigners;

          if (validateSigner <= 0) {
            this.show_modal_empty_warning();
          } else {
            // enviar todos los firmantes al modal

            let sign_status = 1;
            this.sign_status = sign_status;

            let param = "modal__confirm__document__draft";
            this.$store.dispatch("Config/setModalRef", param);
            let element = this.$refs.modal__confirm__document__draft;
            element.open();
          }
        } else if (
          this.signerDraft == null &&
          this.initialSigner == null &&
          this.signerFromCheck == null
        ) {
          this.show_modal_empty_warning();
        }
      }
    },
    sendDataModal() {
      if (
        this.missingSingerValidatorName ||
        this.missingSingerValidatorEmail ||
        this.missingSingerValidatorRut
      ) {
        this.show_modal_empty_warning();
      } else {
        let dataDocument;

        if (this.dataDumentToSingFromMB != null) {
          this.isFile = false;
          dataDocument = this.dataDumentToSingFromMB;
          this.id_document = dataDocument.id;
          this.File = null;
        } else {
          this.isFile = true;
          dataDocument = this.documentFromLocal;
          this.File = dataDocument.file;
        }

        this.name_document = dataDocument.name;

        if (dataDocument.tags != null) {
          this.tags = dataDocument.tags;
        } else {
          this.tags = null;
        }

        if (this.initialSigner != null) {
          this.full_signers = [];
          this.signerInitialTempData = null;
          this.signerunitsTempData = null;

          let data = {
            name:
              this.initialSigner.name != null ? this.initialSigner.name : null,
            email:
              this.initialSigner.email != null
                ? this.initialSigner.email
                : null,
            rut: this.initialSigner.rut != null ? this.initialSigner.rut : null,
          };

          this.signerInitialTempData = data;
          this.signerunitsTempData = this.signersUnit;

          if (this.signersUnit.length > 0) {
            this.full_signers.push(...this.signerunitsTempData);
          }

          if (this.signerFromCheck != null) {
            this.full_signers.push(this.signerFromCheck);
          }

          //listado del primer firmante agregado manualmente
          this.full_signers.push(this.signerInitialTempData);

          // tipo de firma

          let sign_type;

          if (this.f_simple_name != null) {
            //firma electronica simple
            sign_type = 0;
          } else if (this.f_advanced_name != null) {
            sign_type = 1;
          }

          this.sign_type = sign_type;

          if (this.signerDraft != null) {
            let signerDraftForSing = this.signerDraft;
            for (const draftSigner of signerDraftForSing) {
              this.full_signers.push(draftSigner);
            }
          }
          this.singerToModal = this.full_signers;
          this.totalSigners = this.full_signers.length;
          let param = "modal__confirm__document";
          this.$store.dispatch("Config/setModalRef", param);
          let element = this.$refs.modal__confirm__document;
          element.open();
          // enviar todos los firmantes al modal
        } else if (this.signerFromCheck != null) {
          this.full_signers = [];
          this.full_signers.push(this.signerFromCheck);

          let sign_type;

          if (this.f_simple_name != null) {
            //firma electronica simple
            sign_type = 0;
          } else if (this.f_advanced_name != null) {
            sign_type = 1;
          }
          this.sign_type = sign_type;

          // let sign_status = 2;

          if (this.signerDraft != null) {
            let signerDraftForSing = this.signerDraft;
            for (const draftSigner of signerDraftForSing) {
              this.full_signers.push(draftSigner);
            }
          }
          this.singerToModal = this.full_signers;
          this.totalSigners = this.full_signers.length;

          let param = "modal__confirm__document";
          this.$store.dispatch("Config/setModalRef", param);
          let element = this.$refs.modal__confirm__document;
          element.open();

          // enviar todos los firmantes al modal
        } else if (this.signerDraft != null) {
          let sign_type;
          this.full_signers = [];

          if (this.f_simple_name != null) {
            //firma electronica simple
            sign_type = 0;
          } else if (this.f_advanced_name != null) {
            sign_type = 1;
          }
          this.sign_type = sign_type;
          let signerDraftForSing = this.signerDraft;
          for (const iterator of signerDraftForSing) {
            this.full_signers.push(iterator);
          }
          this.singerToModal = this.full_signers;
          this.totalSigners = this.full_signers.length;

          let validateSigner = this.totalSigners;

          if (validateSigner <= 0) {
            this.show_modal_empty_warning();
          } else {
            // enviar todos los firmantes al modal

            let param = "modal__confirm__document";
            this.$store.dispatch("Config/setModalRef", param);
            let element = this.$refs.modal__confirm__document;
            element.open();
          }
        } else if (this.signersUnit.length > 0) {
          let sign_type;
          this.full_signers = [];
          this.signerunitsTempData = this.signersUnit;

          if (this.signersUnit.length > 0) {
            this.full_signers.push(...this.signerunitsTempData);
          }
          if (this.f_simple_name != null) {
            //firma electronica simple
            sign_type = 0;
          } else if (this.f_advanced_name != null) {
            sign_type = 1;
          }
          this.sign_type = sign_type;
          this.singerToModal = this.full_signers;
          this.totalSigners = this.full_signers.length;
          let validateSigner = this.totalSigners;
          if (validateSigner <= 0) {
            this.show_modal_empty_warning();
          } else {
            // enviar todos los firmantes al modal
            let param = "modal__confirm__document";
            this.$store.dispatch("Config/setModalRef", param);
            let element = this.$refs.modal__confirm__document;
            element.open();
          }
        } else if (this.signerDraft == null && this.initialSigner == null) {
          this.show_modal_empty_warning();
        }
      }
    },

    show_modal_empty_warning() {
      let param = "modal__warning__empty__signers";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__warning__empty__signers;
      element.open();
    },
    show_modal_coming() {
      let param = "modal__coming__soon";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__coming__soon;
      element.open();
    },
    show_modal_confirm() {
      let element = this.$refs.modal_confirm;
      element.open_confirm();
    },

    selectedSimpleSignature() {
      this.f_simple = !this.f_simple;
      this.f_advanced_name = null;
      this.f_simple_name = "Firma Electrónica Simple";

      this.f_advanced = false;
      this.disable_signature_advanced = !this.disable_signature_advanced;
      this.disable_signature_simple = !this.disable_signature_simple;
    },
    selectedAdvancedSignature() {
      this.f_advanced = !this.f_advanced;
      this.f_simple_name = null;
      this.f_advanced_name = "Firma Electrónica Avanzada";
      this.f_simple = false;
      this.disable_signature_advanced = !this.disable_signature_advanced;
      this.disable_signature_simple = !this.disable_signature_simple;
    },
  },
  watch: {
    signerFromCheck() {
      this.signerFromCheck == null ? "" : this.signerFromCheck;
      this.full_signers = [];
    },
    initialSigner() {
      this.initialSigner == null ? "" : this.initialSigner;
    },
    signers() {
      this.signers == null ? "" : this.signers;
    },
  },
};
</script>

<style lang="scss">
.block-advanced_sing {
  pointer-events: none !important;
}
.footer_signers {
  justify-content: flex-end;
}
.bold_title {
  font-weight: bold;
}
.container__add__signers {
  display: flex;
  gap: 1.5rem;
  border-top: 0.7px solid #9c9c9c;

  > .left__add__signer {
    width: 65%;
    margin-top: 1rem;

    > .label-primary {
      color: var(--title_origin);
    }
  }
  > .right__add_signer {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.3rem;
  }
}

.container__data__signer {
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
  gap: 0.7rem;
  padding-left: 1rem;
  margin-bottom: 15px;

  > .label-primary {
    font-size: 12px;
    color: var(--title_sing_from_app) !important;
  }

  > .sub-title-table {
    font-size: 14px;
    color: var(--sub_title_selected_sing) !important;
  }
}

.right__add_signer {
  border-left: 0.7px solid #9c9c9c;
}

.signer__data {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

//end righ-side

//left side

.f_simple,
.f_simple div {
  background: var(--background_sign_selection) !important;
  color: white;
}
.select__type__digital__signature {
  @media screen and (max-width: 900px) and (min-width: 200px) {
    flex-wrap: wrap;
  }
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

//TODO: remove when FEA is active
.container_difital_advanced {
  background: var(--background_desactive_signature) !important;
  opacity: 0.5 !important;
}

.container__digital_simple {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  > .sub-title-table {
    width: 390px;
    color: #464646;
    font-style: italic;
  }

  > .sub_title_signature {
    color: var(--sub_title_selected_sing);
  }
}
.auto__selected {
  > .sub-title-table {
    color: var(--sub_title_selected_sing);
    font-size: 14px;
  }
}

.auto__selected {
  display: flex;
  gap: 0.4rem;
}
.title_signer {
  margin-top: 1rem;
  margin-bottom: 0.6rem;
}

.signature_advanced {
  background-color: var(--bg_desactive_sing);
  border: 1px solid var(--bg_desactive_sing_border);
}
.signature__simple {
  background-color: var(--bg_active_sing);
  border: 1px solid var(--bg_active_sing_border);
}
.signature__simple,
.signature_advanced {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 390px;
  height: 64px;
  border-radius: 12px;
}

.sub_title_signature {
  width: 390px;
  color: #464646;
  font-style: italic;
  font-size: 10.5px;
}
@media screen and (max-width: 399px) and (min-width: 200px) {
  .signature__simple,
  .sub_title_signature,
  .signature_advanced {
    font-size: 14px;
    width: 280px !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 200px) {
  .left__add__signer,
  .container__digital_simple {
    width: 100% !important;
  }
  .container__add__signers {
    flex-wrap: wrap !important;

    & > .right__add_signer {
      border-left: none;
      width: 100%;
    }
  }

  .signature__footer__buttons {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) and (min-width: 400px) {
  .signature__simple,
  .sub_title_signature,
  .signature_advanced {
    width: 320px !important;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1000px) {
  .signature__simple,
  .sub_title_signature,
  .signature_advanced {
    width: 330px !important;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1301px) {
  .signature__simple,
  .sub_title_signature,
  .signature_advanced {
    width: 330px !important;
  }
}
</style>
