<template>
  <div class="container_module">
    <ModalOnboardingDigitalSignature
      ref="modal__onboarding__digital__signature"
      size="onboarding"
    />
    <div class="container-pre-step" v-bind:class="loaderActive">
      <div class="loader-pre-step" v-if="checkLoader">
        <loaderGeneric />
        {{ data }}
      </div>
      <div class="" v-else>
        <div class="text_document">Firma Digital</div>
        <div class="sub-title-pre-step">¿Qué acción deseas realizar?</div>

        <div class="btn-digital-signature pointer" @click="goToSing()">
          <div class="title-btn-pre-step title-pre-step">
            <div>Firmar <span>nuevo documento</span></div>
            <DigitalSignatureIcon />
          </div>
        </div>

        <div
          class="btn-digital-signatureV2"
          @click="selectionDigitalSignature($event)"
        >
          <div class="title-btn-pre-stepv2 title-pre-step">
            Ver proceso de firma digital <span>pendientes</span>
          </div>
        </div>

        <div
          class="btn-digital-signatureV2 finalizado"
          v-on:click="selectionDigitalSignature($event)"
        >
          <div class="title-btn-pre-stepv2 title-pre-step finalizado">
            Ver procesos de firma digital <span>finalizados</span>
          </div>
        </div>
        <div class="btn-digital-signatureV2" @click="listAllDigitalSignature()">
          <div class="title-btn-pre-stepv2 title-pre-step">
            Ver <span>todos</span> los procesos de firma digital
          </div>
        </div>
      </div>
    </div>
    <div
      class="container_button_onBoarding container_button_digital_onboarding"
    >
      <div class="button_onBoarding color_hover_button_standard" @click="OpenOnBoarding">
        Explora la App
        <div class="icon_onboarding_button">
          <OnBoardingIconButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import DigitalSignatureIcon from "@/assets/iconos_container/firmadigitalslim_prestep.svg";
import ModalOnboardingDigitalSignature from "../OnBoarding/modalOnboardingDigitalSignature.vue";
import OnBoardingIconButton from "@/assets/buttonIconOnboarding.svg";
import { mapGetters } from "vuex";

export default {
  name: "PreStepSignature",
  components: {
    loaderGeneric,
    DigitalSignatureIcon,
    ModalOnboardingDigitalSignature,
    OnBoardingIconButton,
  },
  data: function () {
    return {
      checkLoader: false,
      data: "",
      route: "",
    };
  },

  mounted() {
    window.onpopstate = () => {
      let currentModule = localStorage.getItem("currentModule");

      if (currentModule == "digitalSignature") {
        let actual = this.route;
        this.setListSignatures(actual);
      }
    };
  },

  created() {
    let name = this.$route.name;
    this.route = name;
    this.$store.dispatch("DigitalSignature/cleanNewSignerValidator", false);
  },

  computed: {
    loaderActive: function () {
      return this.checkLoader ? "container-loader" : "container-pre-step";
    },

    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("DigitalSignature", {
      datacheck: "datacheck",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    OpenOnBoarding() {
      let param = "modal__onboarding__digital__signature";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__digital__signature;
      element.open();
    },
    goToSing() {
      this.$router.push({ name: "NewDigitalSignature" });
      this.$store.dispatch("DigitalSignature/loadDocumentOff");
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);
    },

    selectionDigitalSignature(evt) {
      const isDiv = evt.target.classList;

      isDiv.contains("finalizado")
        ? this.searchFinalizados()
        : this.searchPendientes();
    },

    searchFinalizados() {
      this.data = "Cargando los finalizados";
      this.checkLoader = true;
      this.$store.dispatch("DigitalSignature/SetSearchComplete");

      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";
      let sign_status__range = "3,3";
      this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
      });

      let inputFinalizadoTags = true;
      let tagsForfront = [{ name: "Finalizados", value: inputFinalizadoTags }];

      let tagForReloadRequest = [inputFinalizadoTags];

      let tagsList = true;

      this.$store.dispatch("DigitalSignature/setTags", {
        tagsForfront,
        tagForReloadRequest,
        tagsList,
      });

      setTimeout(() => {
        this.$router.push({ name: "DigitalSignature" });

        // this.checkLoader = false;
      }, 2000);

      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
    },
    searchPendientes() {
      this.data = "Cargando los pendientes";

      this.$store.dispatch("DigitalSignature/SetSearchPending");
      this.checkLoader = true;

      let inputPendientesTags = true;
      let tagsForfront = [{ name: "Pendientes", value: inputPendientesTags }];

      let tagForReloadRequest = [inputPendientesTags];

      let tagsList = true;

      this.$store.dispatch("DigitalSignature/setTags", {
        tagsForfront,
        tagForReloadRequest,
        tagsList,
      });

      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      let sign_status__range = "2,2";
      this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
      });

      setTimeout(() => {
        this.$router.push({ name: "DigitalSignature" });
        // this.checkLoader = false;
      }, 2000);

      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);

      //request for only complete  digital signature vuex managment
    },
    listAllDigitalSignature() {
      this.checkLoader = true;

      let tagsList = false;
      let tagForReloadRequest = [];
      let tagsForfront = [];
      this.$store.dispatch("DigitalSignature/setTags", {
        tagsForfront,
        tagForReloadRequest,
        tagsList,
      });
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let sign_status__range = "2,3";
      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      this.$store.dispatch("DigitalSignature/SetSearchAll");

      this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
      });

      setTimeout(() => {
        this.$router.push({ name: "DigitalSignature" });
        // this.checkLoader = false;
      }, 2000);

      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
    },
    setListSignatures(actual) {
      if (actual == this.$route.name) {
        this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);
      } else {
        this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);

        if (this.$route.name == "preStep") {
          this.$store.dispatch(
            "DigitalSignature/searchDigitalSignature",
            false
          );
        }

        if (this.$route.name != "DigitalSignature") {
          this.$store.dispatch(
            "DigitalSignature/searchDigitalSignature",
            false
          );
        }
      }
      this.route = "";
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
.container-data-loader {
  margin-top: 1rem;
}
.container-loader {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.25);
  width: 584px;
  margin: 100px auto;
  display: flex;
  height: 650px;
  flex-direction: column;
  padding-bottom: 2rem;

  > .loader-pre-step {
    > .loader {
      margin: 0px !important;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}
.container-pre-step {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 90%;
  }
  background: var(--container_pre_step);
  padding: 1rem;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.25);
  width: 584px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  > div .text_document {
    color: var(--title_pre_step);
  }
}

.sub-title-pre-step {
  margin-top: 1rem;
  color: var(--text_card_btn_pre_step);
}

.title-pre-step {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;

  > svg {
    @media screen and (max-width: 768px) and (min-width: 200px) {
      width: 40px;
      height: 60px;
    }
    width: 80px;
    height: 120px;
  }
}

.btn-digital-signature {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--card_btn_pre_step);

  > a {
    width: 100%;
    color: var(--text_card_btn_pre_step);
    transition: all 400ms ease;
  }
  &:hover {
    background: var(--text_card_btn_hover_pre_step) !important;
  }

  &:hover .title-btn-pre-step svg g path {
    fill: var(--text_card_btn_hover_pre_step_invert) !important;
    stroke: var(--text_card_btn_hover_pre_step_invert) !important;
  }

  &:hover .title-btn-pre-step div,
  &:hover .title-btn-pre-step div span {
    color: var(--text_card_btn_hover_pre_step_invert) !important;
  }
}
.btn-digital-signatureV2 {
  padding-top: 32px;
  padding-bottom: 32px;
  cursor: pointer;
  background-color: var(--card_btn_pre_step);

  &:hover {
    background: var(--text_card_btn_hover_pre_step);
  }

  &:hover .title-btn-pre-stepv2 {
    color: var(--text_card_btn_hover_pre_step_invert) !important;
  }
}

.btn-digital-signature,
.btn-digital-signatureV2 {
  margin-top: 17px;
  border: 1px solid var(--border_card_btn_hover_pre_step);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  > .title-btn-pre-stepv2 {
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 26px;
    color: var(--text_card_btn_pre_step);
    @media screen and (max-width: 768px) and (min-width: 200px) {
      font-size: 12px;
    }

    > span {
      // pointer-events: none;
      font-weight: bold;
    }

  }
}

.title-btn-pre-step {
  > div,
  span {
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 31px;
    color: var(--text_card_btn_pre_step);
    font-weight: bold;
    @media screen and (max-width: 768px) and (min-width: 200px) {
      font-size: 15px;
    }
  }

  > svg g path {
    fill: var(--svg_pre_step) !important;
    stroke: var(--svg_pre_step) !important;
  }
}

.pre-step-message {
  margin-top: 2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.warning-pre-step {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.container_button_digital_onboarding {
  @media screen and (max-width: 768px) and (min-width: 200px) {
    margin-right: 0px !important;
    margin-bottom: -5px !important;
    position: fixed !important;
    bottom: 10px !important;
    right: 10px !important;
  }
}
</style>
