<template>
  <div class="modal__content">
    <div class="image_onboarding_home_fifth">
      <FifthImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_assistance title_fifth_home ">
          ¡Todo listo!
        </div>
      </div>
      <div class="text_container_onboarding text_first_assistance">
        <div class="">
          ¿Te parece si ingresamos <b> tu primera solicitud? </b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FifthImageOnBoarding from "./assets/fifthHome.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    FifthImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Home", {
      stepHomeOnBoarding: "stepHomeOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepHomeOnBoarding + 1;

      this.$store.dispatch("Home/counterStepHomeOnBoarding", increment);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__home");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.image_onboarding_home_fifth {
  width: 360px !important;
  height: 300px !important;
  display: flex;
  margin-top: 6rem;
  margin-left: 10.5rem !important;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 220px !important;
    height: 300px !important;
    margin-left: 2.5rem !important;
    margin-top: 1rem !important;
  }
}
.title_fifth_home {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  margin-top: 5rem !important;

  @media screen and (max-width: 500px) and (min-width: 200px) {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
  }
}
</style>
