<template>
  <div class="container_tracking_info">
    <div class="label-primary">
      {{ title }}
    </div>
    <div class="data__tracking">
      {{ data }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "data"],
};
</script>

<style lang="scss">
.container_tracking_info {
  display: flex;
  flex-direction: column;
  gap: 7px;

  > .label-primary, .data__tracking {
    color: var(--content_table_dsignature) !important;
  }
}

.data__tracking {
  margin-bottom: 12px;
}
</style>
