<template>
  <div :class="edit ? 'header_single_assitance__edit' : 'header_single_assitance'">
    <ModalSendMessage name_modal="modal__send__message" size="modal__test_ios" ref="modal__send__message" />
    <div class="header_content_assistance">
      <div class="icon_container_assistance">
        <IconLogoAssistance />
      </div>
      <div class="fix_assistance_style_header">
        <div class="title-container name_assistance" :class="edit ? 'flex-edit' : ''">
          <div class="name__user__assistance">{{ usuario.nombre }}</div>
          <div class="icon__edit__assistance" v-if="edit">
            <EditIcon />
          </div>
        </div>
        <div class="title-container tag_assistance_container">
          <div class="tag_assistance">
            {{ usuario.tag }}
          </div>
        </div>
      </div>
    </div>

    <div class="container_header_code">
      <div class="code_assistance">Código #{{ usuario.caseCode }}</div>
      <div class="code_date">{{ usuario.fecha }}</div>
      <!-- TODO:HABILITAR BOTON PARA CHAT -->
      <div class="button__chat" @click="gotoWallModal">
        Ver / Enviar mensaje
        <ArrowButton />
      </div>
    </div>
  </div>
</template>

<script>
import IconLogoAssistance from "../../../assets/iconos_logo_svg/isotipo_misabogados.svg";
import EditIcon from "../assets/edit-icon.svg";
import ModalSendMessage from "./modals/ModalSendMessage.vue";
import ArrowButton from "../assets/ArrowRightButton.svg";
import { mapGetters } from "vuex";

export default {
  props: ["usuario", "edit"],
  components: {
    IconLogoAssistance,
    EditIcon,
    ModalSendMessage,
    ArrowButton,
  },

  created() { },
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
      user: "user_profile",
    }),
  },
  methods: {
    gotoWallModal() {
      // this.$router.push({ name: "messageWall" });
      if (this.user.show_chat_info) {
        let param = "modal__send__message";
        this.$store.dispatch("Config/setModalRef", param);
        let element = this.$refs.modal__send__message;
        element.open();
      } else {
        this.$router.push({ name: "messageWall" });
      }
    },
  },
};
</script>

<style lang="scss">
.header_single_assitance,
.header_single_assitance__edit {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) and (min-width: 200px) {
    align-items: center;
  }
}

.header_single_assitance {
  height: 104px;
}

.header_single_assitance__edit {
  height: 80px;
}

.header_content_assistance {
  display: flex;
  gap: 1rem;
}

.fix_assistance_style_header {
  >.name_assistance {
    color: var(--title_profile) !important;
    font-size: 17px !important;
  }
}

.fix_assistance_style_header {
  >.tag_assistance_container {
    color: var(--title_profile) !important;
    font-size: 10px !important;
    background-color: rgba(29, 77, 151, 0.8);
    fill: solid;
    border-radius: 4px;
    width: 93px;
  }
}

.fix_assistance_style_header {
  display: flex;
  flex-direction: column;
}

.tag_assistance {
  padding-left: 15px;
  color: white !important;
}

.code_assistance {
  font-size: 14px;
}

.code_date {
  font-size: 12px;
  color: var(--line_primary) !important;
}

.name__user__assistance {
  height: 100%;
  padding-top: 0.2rem;
}

// in edit mode
.flex-edit .icon__edit__assistance svg {
  width: 25px !important;
}

.flex-edit {
  display: flex;
  gap: 1rem;
}

.button__chat {
  margin-top: 2rem;
  display: flex;
  gap: 4px;
  align-items: center;
  height: 40px !important;
  background: #0064ff !important;
  font-family: Conv_HelveticaLTStd-Light;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  width: fit-content;
  text-align: center !important;
  transition: all 250ms ease;
  cursor: pointer !important;
  white-space: nowrap;
  color: white;

  @media screen and (max-width: 600px) and (min-width: 200px) {
    font-size: 12px;
    margin-top: 1rem;
    height: 30px !important;
    padding: 0.5rem 0.5rem;

    >svg {
      display: none;
    }
  }
}

.container_header_code {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>