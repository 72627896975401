export default {
  dataFeatures: (state) => {
    return state.dataCards;
  },
  dataDocuments: (state) => {
    return state.dataCards.home_docs_card.categories;
  },
  dataRequest: (state) => {
    return state.dataCards.home_quotations_card.months;
  },
  dataQuestion: (state) => {
    return state.dataCards.home_questions_card.months;
  },
  CountQuestion: (state) => {
    return state.dataCards.home_questions_card.count;
  },
  dataUsers: (state) => {
    return state.dataCards.home_users_card.users;
  },

  CountUsers: (state) => {
    return state.dataCards.home_users_card.count;
  },
  GetIndicadores: (state) => {
    return state.indicadores;
  },

  GetEuro: (state) => {
    return state.indicadores.Euro;
  },
  GetUF: (state) => {
    return state.indicadores.UF.slice(0,5);
  },
  GetDolar: (state) => {
    return state.indicadores.Dolar;
  },
  GetUTM: (state) => {
    return state.indicadores.UTM;
  },
  GetFlagLoaderCards: (state) => {
    return state.flagLoaderCards;
  },
  GetFlagLoaderEconomic: (state) => {
    return state.flagLoaderEconomic;
  },
  GetFlagLoaderLinks: (state) => {
    return state.flagLoaderLinks;
  },
  GetFlagLoaderUtilities: (state) => {
    return state.flagLoaderUtilities;
  },
  GetFlagLoaderDocuments: (state) => {
    return state.flagLoaderDocument;
  },

  stepHomeOnBoarding: (state) => {
    return state.stepsHomeOnBoarding;
  },


};
