<template>
  <div class="close_tags_container" v-if="status">
    <div class="input_search_filter">
      <div class="container_filter_input_search">
        {{ tag }}
        <CloseIcon @click="deleteTags(tag)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CloseIcon from "../assets/close-input-search.svg";
export default {
  props: ["index", "id", "tag", "status"],
  created() {
    this.selectedtag = this.tagsOfFilters;
    this.selectedtagService = this.initialFilters;
  },
  components: {
    CloseIcon,
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      tagsOfFilters: "tagsOfFilters",
      initialFilters: "initialFilters",
      activeTableMembers: "activeTableMembers",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
      user_profile: "user_profile",
    }),
  },
  data: () => {
    return {
      verifiedFilterModalTags: null,
      activeUserFilterModalTags: null,
    };
  },
  methods: {
    deleteTags(idSelected) {
      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      /*--------------------------------
 @Input: state of tags selected
 Description: we map the array and we test if the id are the same (id selected and id in the array) in the case if true we change the status value to false (for html funcionality)
 @Output: array with changes on status
//--------------------------------*/

      this.selectedtag.map((el) => {
        if (el.name == idSelected) {
          el.status = false;
        }
        return this.selectedtag;
      });

      /*--------------------------------
 @Input: new array with status change (see previous .map)
 Description: we filter the array and we leave only the elements with the status = true , this is for front/Html funcionality
 @Output: array with only status = true
//--------------------------------*/
      let newfilterTags = this.selectedtag.filter((el) => {
        return el.status == true;
      });
      // we save the array with filters(newfilterTags) in a state
      this.$store.dispatch(
        "EmployeesV2/cleanSelectedTagsOfFilter",
        newfilterTags
      );

      /*--------------------------------
 @Input: state of tags (service) selected
 Description: we map the array and we test if the id are the same (id selected and id in the array) in the case if true we change the status value to false (for html funcionality)
 @Output: array with changes on status
//--------------------------------*/
      if (newfilterTags.length > 0) {
        this.selectedtagService.map((el) => {
          if (el.name == idSelected) {
            el.status = false;
          }
          return;
        });

        this.$store.dispatch(
          "EmployeesV2/filterSelectedTags",
          this.selectedtagService
        );

        if (this.selectedtagService[1].status) {
          this.activeUserFilterModalTags = true;
        } else if (this.selectedtagService[2].status) {
          this.activeUserFilterModalTags = false;
        }

        if (this.selectedtagService[3].status) {
          this.verifiedFilterModalTags = true;
        } else if (this.selectedtagService[4].status) {
          this.verifiedFilterModalTags = false;
        }
        if (this.inSwitch) {
          let payload = {
            admin_company_id: admin_company_id,
            company: admin_company_id,
            is_verified: this.verifiedFilterModal,
            is_active: this.activeUserFilterModal,
          };
          if (this.activeTableMembers) {
            this.$store.dispatch("EmployeesV2/filterMembersByModal", payload);
          } else {
            this.$store.dispatch("EmployeesV2/filterEmployeeByModal", payload);
          }
        } else {
          let payload = {
            admin_company_id: admin_company_id,
            company:
              this.selectedtagService[0].status != false
                ? this.selectedtagService[0].value
                : null,
            is_verified: this.verifiedFilterModalTags,
            is_active: this.activeUserFilterModalTags,
          };
          if (this.activeTableMembers) {
            this.$store.dispatch("EmployeesV2/filterMembersByModal", payload);
          } else {
            this.$store.dispatch("EmployeesV2/filterEmployeeByModal", payload);
          }
        }
      } else {
        if (this.inSwitch) {
          let user = this.user_profile;
          let id = user.id;

          let company_id = admin_company_id;
          let offset = 0;

          let payload = {
            id_company: company_id,
            limit: "10",
            offset: offset,
            first_name__icontains: null,
            id: id,
          };
          this.$store.dispatch(
            "EmployeesV2/get_list_employee_InSwitchTenancy",
            payload
          );
          this.$store.dispatch(
            "EmployeesV2/get_list_members_InSwitchTenancy",
            payload
          );
          setTimeout(() => {
            this.moduleIsReady(true);
            this.noResult = false;
          }, 900);
        } else {
          let offset = 0;
          let company_id = admin_company_id;

          let payload = {
            company_id: company_id,
            limit: "10",
            offset: offset,
          };
          this.$store.dispatch("EmployeesV2/get_list_employee", payload);
          this.$store.dispatch(
            "EmployeesV2/get_list_employee_members",
            payload
          );
        }
      }
    },
  },
};
</script>

<style></style>
