<template>
  <div class="container_module">
    <ModalOnboardingAssistance
      ref="modal__onboarding__assistance"
      size="onboarding"
    />
    <div class="container-pre-step" v-bind:class="loaderActive">
      <div class="loader-pre-step" v-if="checkLoader">
        <LoaderGeneric />
        {{ data }}
      </div>
      <div class="" v-else>
        <div class="text_document">Servicios</div>
        <div class="sub-title-pre-step">¿Qué acción deseas realizar?</div>
        <div
          class="btn-digital-signatureV2 pointer"
          @click="createNewAssitance()"
        >
          <div
            class="title-btn-pre-stepv2 title-pre-step pre_step_mayor_container"
          >
            <span>+ Nueva</span>asistencia legal
            <CreateNewAssitanceSVG />
          </div>
        </div>
        <div
          class="btn-digital-signatureV2"
          @click="listRequestAssistenace($event)"
        >
          <div class="title-btn-pre-stepv2 title-pre-step finalizado">
            <span>Ver asistencias </span>legales ya solicitadas
          </div>
        </div>
      </div>
    </div>
    <div class="container_button_onBoarding">
      <div
        class="button_onBoarding color_hover_button_standard"
        @click="OpenOnBoarding"
      >
        Explora la App
        <div class="icon_onboarding_button">
          <OnBoardingIconButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import CreateNewAssitanceSVG from "@/assets/iconos_container/quotation.svg";
import ModalOnboardingAssistance from "../OnBoarding/modalOnboardingAssistance.vue";
import OnBoardingIconButton from "@/assets/buttonIconOnboarding.svg";
import { mapGetters } from "vuex";

export default {
  components: {
    LoaderGeneric,
    CreateNewAssitanceSVG,
    ModalOnboardingAssistance,
    OnBoardingIconButton,
  },
  data: function () {
    return {
      checkLoader: false,
      data: "Cargando...",
      route: "",
    };
  },
  created() {
    if (this.group == "Company-Employee") {
      this.$router.push({ name: "home" });
    }
    // Set true the modal state for "ConfirmAssistanceModal" (this will not show the modal in the list assistance if is not create)
    this.$store.dispatch("Assistance/setTrueAssitance");
    this.$store.dispatch("Assistance/setInactiveInputSearchTags");
  },
  computed: {
    loaderActive: function () {
      return this.checkLoader ? "container-loader" : "container-pre-step";
    },
    ...mapGetters("Login", {
      company_id: "company_id",
      group: "group",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  methods: {
    OpenOnBoarding() {
      let param = "modal__onboarding__assistance";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__assistance;
      element.open();
    },
    createNewAssitance() {
      this.checkLoader = true;
      this.data = "solicitar asistencia...";
      setTimeout(() => {
        this.$router.push({ name: "requestAsistencia" });
        // this.checkLoader = false;
      }, 3000);
    },

    listRequestAssistenace() {
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payload = {
        admin_company_id: company_id,
        fields:
          "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
        limit: "5",
      };

      this.$store.dispatch("Assistance/getGroupsAssitance");
      this.$store.dispatch("Assistance/cleanTagsOfFilter");
      this.$store.dispatch("Assistance/deactiveFilter");
      this.$store.dispatch("Assistance/cleanFiltersResult");
      this.$store.dispatch("Assistance/getAllAssistance", payload);

      this.checkLoader = true;
      this.data = "ver solicitudes...";
      setTimeout(() => {
        this.$router.push({ name: "listRequestIntegrated" });
        // this.checkLoader = false;
        // this.data = "";
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
.container-data-loader {
  margin-top: 1rem;
}
.container-loader {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.25);
  width: 584px;
  margin: 100px auto;
  display: flex;
  height: 650px;
  flex-direction: column;
  padding-bottom: 2rem;

  > .loader-pre-step {
    > .loader {
      margin: 0px !important;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 3rem;
  }
}
.container-pre-step {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 90%;
  }
  background: var(--container_pre_step);
  padding: 1rem;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.25);
  width: 584px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  > div .text_document {
    color: var(--title_pre_step);
  }
}

.sub-title-pre-step {
  margin-top: 1rem;
  color: var(--text_card_btn_pre_step);
}

.btn-digital-signature {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--card_btn_pre_step);

  > a {
    width: 100%;
    color: var(--text_card_btn_pre_step);
    transition: all 400ms ease;
  }
  &:hover {
    background: var(--text_card_btn_hover_pre_step) !important;
  }

  &:hover .title-btn-pre-step svg g path {
    fill: var(--text_card_btn_hover_pre_step_invert) !important;
    stroke: var(--text_card_btn_hover_pre_step_invert) !important;
  }

  &:hover .title-btn-pre-step div,
  &:hover .title-btn-pre-step div span {
    color: var(--text_card_btn_hover_pre_step_invert) !important;
  }
}
.btn-digital-signatureV2 {
  padding-top: 32px;
  padding-bottom: 32px;
  cursor: pointer;
  background-color: var(--card_btn_pre_step);

  &:hover {
    background: var(--text_card_btn_hover_pre_step);
  }

  &:hover .title-btn-pre-stepv2 {
    color: var(--text_card_btn_hover_pre_step_invert) !important;
  }
}

.btn-digital-signature,
.btn-digital-signatureV2 {
  margin-top: 17px;
  border: 1px solid var(--border_card_btn_hover_pre_step);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  > .title-btn-pre-stepv2 {
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 26px;
    color: var(--text_card_btn_pre_step);
    @media screen and (max-width: 768px) and (min-width: 200px) {
      font-size: 12px;
    }

    > span {
      font-weight: bold;
    }
  }
}

.title-btn-pre-step {
  > div,
  span {
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 31px;
    color: var(--text_card_btn_pre_step);
    font-weight: bold;
    @media screen and (max-width: 768px) and (min-width: 200px) {
      font-size: 15px;
    }
  }

  > svg g path {
    fill: var(--svg_pre_step) !important;
    stroke: var(--svg_pre_step) !important;
  }
}

.pre-step-message {
  margin-top: 2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.warning-pre-step {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.fix_text_pre_step {
  font-style: normal !important;
}
.title-btn-pre-stepv2 svg {
  fill: var(--svg_pre_step) !important;
  stroke: var(--svg_pre_step) !important;
  height: 50px !important;
  width: 50px !important;
}
.title-btn-pre-stepv2 {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}
.pre_step_mayor_container {
  height: 100px;
}
</style>
