<template>
  <div class="m-top-s m-right-s">
    <div class="table table__documents table__scheduling">
      <div class="table__head">
        <div class="sub-title-table table__head__scheduling">
          <div class="child_scheduling">
            <div class="first_title">Tipo de documento</div>
            <div class="second_title">Tipo de documento / Usuario</div>
          </div>
          <div class="child_scheduling_header scheduling__user">Usuario</div>
          <div class="child_scheduling_header scheduling__date">
            Fecha de reunión
          </div>
          <div class="child_scheduling_header_d">
            <div class="first_title">Hora</div>

            <div class="second_title">Fecha / Hora</div>
          </div>
        </div>
      </div>
      <div class="table__body table_fix_body">
        <!-- <div class="m-top-xl" v-if="loader_documents">
            <loaderGeneric></loaderGeneric>
          </div> -->

        <div class="document__fields cursor_document" v-if="meetingPastCard">
          <div
            v-for="(item, index) in meetingPastCard"
            :key="index"
            class=""
            :class="selectMeeting == item.id ? 'active__document' : 'desactive'"
          >
            <div class="text_document child_scheduling scheduling__user__title">
              {{ item.title }}
              <div class="name__secondary">
                {{ item.name }}
              </div>
            </div>

            <div class="text_document child_scheduling scheduling__user">
              {{ item.name }}
            </div>

            <div class="text_document child_scheduling ml-2 scheduling__date">
              {{ item.date }}
            </div>
            <div
              class="text_document child_scheduling scheduling__user__title scheduling__user__title_hour"
            >
              {{ item.hour }}
              <div class="name__secondary_date">
                {{ item.date }}
              </div>
            </div>
          </div>
        </div>

        <div class="" v-else>Sin Reuniones</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => {
    return {
      selectMeeting: null,
    };
  },
  computed: {
    ...mapGetters("Schedule", {
      meetingPastCard: "meetingPastCard",
    }),
  },
};
</script>

<style lang="scss">
.category__check {
  margin-right: 2rem;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    margin-right: 0.5rem;
  }
}
.table__scheduling {
  border: 1px solid #a5a5a5 !important;
}
.child_scheduling {
  width: 25%;
}
.child_scheduling_header {
  width: 25%;
  margin-right: 0rem;
}
.child_scheduling_header_d {
  width: 25%;
  margin-right: 1.2rem;
}
.table_fix_body {
  height: 219px !important;
}
.table__head__scheduling {
  border-bottom: none !important;
}

.scheduling__user,
.scheduling__date {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: none !important;
  }
}

.child_scheduling {
  font-size: 12px !important;

  > .second_title {
    display: none;
  }
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 50%;

    > .second_title {
      font-size: 10px !important;
      display: block;
    }

    > .first_title {
      display: none;
    }
  }
}

.first_title {
  font-size: 10px !important;
}

.child_scheduling_header_d {
  > .second_title {
    display: none;
  }
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 50%;
    margin-left: 1.8rem;
    > .second_title {
      display: block;
    }

    > .first_title {
      font-size: 10px !important;
      display: none;
    }
  }
}

.child_scheduling.scheduling__user__title_hour {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    > div {
      order: -1;
    }
  }
}

.document__fields {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    justify-content: space-around !important;
  }
}

.scheduling__user__title {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    flex-direction: column;
  }
}

.scheduling__user__title_hour {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    // align-self: flex-end;
    margin-left: 2rem;
  }
}

.scheduling__user__title {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    align-self: flex-start;
    // flex-wrap: nowrap;
  }
}

.name__secondary_date {
  display: none !important;
}

.name__secondary {
  display: none !important;
}

.scheduling__user__title .name__secondary,
.scheduling__user__title .name__secondary_date {
  display: none;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: block !important;
  }
}
.table__head__scheduling{
color: var(--title_schedule) !important;
}
.child_scheduling {
  > .first_title{
    color: var(--title_schedule) !important;
  }
  color: var(--text_schedule) !important;
}
.table__scheduling{
  background: var(--schedule_background) !important;
}
</style>
