<template>
  <div class="container__view__child__element">
    <Modal
      ref="modal"
      title="Guardar cambios"
      :confirm="true"
      :cancel="true"
      size="m"
      identifier="documentRegion"
    >
      <div class="modal__body">
        <div class="modal__user__regional">
          <div class="container__description value-primary">
            Se realizarán cambios en los siguientes parámetros:
          </div>
          <div class="container_settings value-primary fix__regional__color">
            DocumentTypes activos en esta región
          </div>
        </div>
      </div>
    </Modal>
    <span class="title__child title-container fix_height_mac">
      <IconBackArrow @click="returnMainView()" />
      {{ titleBreadcrumb }}
    </span>

    <span class="title__child__service title_bold_700">
      {{ titleService }}
    </span>

    <div class="container__service__document__region">
      <!-- TODO:llamar Servicio -->
      <!-- TODO:Loader pre-carga de datos -->
      <div class="skeleton_body_region" v-if="flagSkeleton">
        <div class="skeleton_container_region m-top-s">
          <VueSkeletonLoader
            type="rect"
            :height="10"
            :width="10"
            animation="fade"
          />
          <VueSkeletonLoader
            type="rect"
            :height="10"
            :width="150"
            animation="fade"
          />
        </div>

        <div class="skeleton_container_region">
          <VueSkeletonLoader
            type="rect"
            :height="10"
            :width="10"
            animation="fade"
          />

          <VueSkeletonLoader
            type="rect"
            :height="10"
            :width="100"
            animation="fade"
          />
        </div>
      </div>
      <div
        v-for="(category, index) in document_category_list"
        class="item item_label"
        :key="index"
        v-else
      >
        <input class="item-input" type="checkbox" :id="category.id" />
        <img class="arrow" src="../../assets/arrow.png" alt="flecha" />
        <label :for="category.id">{{ category.name }}</label>
        <ul
          v-for="(
            document_children, index2
          ) in category.document_category_children"
          :key="index2"
          class="item-List item-check-lg"
        >
          <li>
            <div class="sub-item">
              <input
                class="sub-item-input"
                type="checkbox"
                :id="document_children.id"
              />
              <img class="arrow" src="../../assets/arrow.png" alt="flecha" />
              <label :for="document_children.id">{{
                document_children.name
              }}</label>
              <ul
                v-for="(
                  document_type, index3
                ) in document_children.document_type"
                :key="index3"
                class="sub-item-List"
              >
                <DocumentTypeCategory :document_type="document_type" />
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="container__settings__regional">
      <div class="container__footer__settings">
        <div
          :class="
            documentTypeByRegionValues.length > 0
              ? 'btn-vue-primary fix-btn-mac'
              : 'desactive_draf_settings fix-btn-mac'
          "
          @click="show_modal_save_confirm()"
        >
          <span> Confirmar </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingService from "../../services/SettingService";
import DocumentTypeCategory from "../../components/documentTypeCategory.vue";
import IconBackArrow from "../../assets/back.svg";
import Modal from "@/components/modal/Modal.vue";
import VueSkeletonLoader from "skeleton-loader-vue";
import { mapGetters } from "vuex";

export default {
  props: ["titleBreadcrumb", "titleService"],
  components: {
    DocumentTypeCategory,
    IconBackArrow,
    Modal,
    VueSkeletonLoader,
  },
  created() {
    this.$store.dispatch(
      "SettingsApp/cleanDefaultValuesForDocumentTypeByRegion"
    );
    this.$store.dispatch("SettingsApp/clearElementDocumentByRegion");
    this.listDocumentTypeCategory();
  },
  data: () => {
    return {
      document_category_list: "",
      flagSkeleton: true,
    };
  },
  computed: {
    ...mapGetters("SettingsApp", {
      documentTypeByRegionValues: "documentTypeByRegionValues",
      defaultDocumentTypeByRegion: "defaultDocumentTypeByRegion",
    }),
  },

  methods: {
    listDocumentTypeCategory() {
      SettingService.list_document_type_category()
        .then((data) => {
          this.document_category_list = data.results;
        })
        .then(() => {
          setTimeout(() => {
            this.flagSkeleton = false;
          }, 600);
        })
        .catch(() => {});
    },

    returnMainView() {
      this.$store.dispatch("SettingsApp/setViewRegionSettings", 0);
    },
    show_modal_save_confirm() {
      let element = this.$refs.modal;
      element.open();
    },
  },
};
</script>

<style lang="scss">
.skeleton_container_region {
  display: flex;
  gap: 1rem;
}
.skeleton_body_region {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 0.8rem;
}
</style>
