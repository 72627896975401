export default {
  // items secciones de consulta legal4
  scheduleLegalItems: [],
  scheduleLegalItemsSuccess: true,
  scheduleLegalItemsFailure: false,
  // items secciones de consulta operaciones
  scheduleOperationlItems: [],
  scheduleOperationlItemsSuccess: true,
  scheduleOperationlItemsFailure: false,

  //item de agendamiento seleccionado
  schedulingSelected: {},

  // cards resumen de agendamientos propios
  resumeMeetingCard: null,
  // cards agendamientos propios
  meetingCard: null,
  loaderAllMeetings: false,
  // state for table past meetings
  meetingPastCard: null,
  // active / desactive flag for views: dashboardScheduling to
  // operationScheduling, legalScheduling, mySchedule
  loadCategorySections: true,

  //loader for fetch scheduling category data
  loadDatacategoryScheduling: true,
  // lista para tabla de agendamientos pasados
  schedulePastMeetings: [],

  //flag for add class to display meeting_description
  selectedMeeting: null,
};
