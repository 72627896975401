export default {
  // getter computado para cada uno de los nombres de los filtros
  dataCategoryFilter: (state) => {
    return state.dataCategoryFilter;
  },
  csvError: (state) => {
    return state.csvError;
  },
  // filter2: (state) => {
  //   return state.dataCategoryFilter.computed_name.description;
  // },
  // filter3: (state) => {
  //   return state.dataCategoryFilter.computed_name.description;
  // },
  // filter4: (state) => {
  //   return state.dataCategoryFilter.computed_name.description;
  // },
  // filter5: (state) => {
  //   return state.dataCategoryFilter.computed_name.description;
  // },
  // filter6: (state) => {
  //   return state.dataCategoryFilter.computed_name.description;
  // },
};
