export default {
  digital_signature_data: (state) => {
    return state.digital_signature_data;
  },

  datacheck: (state) => {
    return state.datacheck;
  },
  flagData: (state) => {
    return state.flagData;
  },
  total_data: (state) => {
    return state.total_data;
  },
  total_data_view: (state) => {
    return state.total_data_view;
  },
  nextPage: (state) => {
    return state.nextPage;
  },
  prevPage: (state) => {
    return state.prevPage;
  },

  tagsForfront: (state) => {
    return state.tagsForfront;
  },
  tags: (state) => {
    return state.tags;
  },
  globalLoader: (state) => {
    return state.globalLoader;
  },

  document_categoty: (state) => {
    return state.document_categoty;
  },
  loader_documents: (state) => {
    return state.loaderDocuments;
  },
  documents: (state) => {
    return state.documents;
  },

  signerFromCheck: (state) => {
    return state.signerFromCheck;
  },
  initialSigner: (state) => {
    return state.initialSigner;
  },
  signers: (state) => {
    return state.signers;
  },
  missingSingerValidatorName: (state) => {
    return state.missingSignerValidatorName;
  },
  missingSingerValidatorEmail: (state) => {
    return state.missingSignerValidatorEmail;
  },
  missingSingerValidatorRut: (state) => {
    return state.missingSignerValidatorRut;
  },
  tracking: (state) => {
    return state.tracking;
  },
  signerDraft: (state) => {
    return state.signerDraft;
  },
  flagDelete: (state) => {
    return state.flagDelete;
  },
  loader_documents_from_app: (state) => {
    return state.loader_documents_from_app;
  },
  urlFileDownload: (state) => {
    return state.urlFileDownload;
  },
  stepDigitalOnBoarding: (state) => {
    return state.stepsDigitalOnBoarding;
  },
};
