<template>
  <div class="modal__content">
    <div class="image_onboarding_third_home">
      <ThirdImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_home_third">
          Agenda sesiones de asesoría con expertos
        </div>
      </div>
      <div class="text_container_onboarding text_container_third_step">
        <div class="">
          Cubre todas las áreas del derecho que necesita controlar la PYME
          chilena. Asesórate con un equipo de abogados especialistas
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThirdImageOnBoarding from "./assets/thirdHome.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    ThirdImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Home", {
      stepHomeOnBoarding: "stepHomeOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepHomeOnBoarding + 1;

      this.$store.dispatch("Home/counterStepHomeOnBoarding", increment);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__home");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.title_onboarding_home_third {
  margin-top: 2.7rem;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    margin-top: 2rem !important;
    font-size: 26px !important;
    line-height: 39px;
    text-align: center;
  }
}

.image_onboarding_third_home{
    width: 360px !important;
    height: 300px !important;
    display: flex;
    margin-top: 3rem;
    margin-left: 10.5rem !important;
    align-items: center;
    justify-content: center;
      @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 220px !important;
    height: 200px !important;
    margin-left: 3.4rem !important;
    margin-top: 5rem !important;
  }
}
</style>
