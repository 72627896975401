<template>
  <Transition v-if="GetFlagLoaderDocuments" name="fade">
    <div class="base-card utility card-documents">
      <h1 class="utility__title title-container">Archivos de utilidad</h1>
      <div class="main-utility">
        <div class="top-utility">
          <div class="container">
            <img class="__image" src="../../../../assets/ventajas.png" alt="" />

            <div class="__content">
              <a
                target="_blank"
                class="text-container"
                href="https://www.misabogados.com/hubfs/ebooks/MisAbogados%20-%20Las%20ventajas%20y%20desventajas%20de%20las%20sociedades%20comerciales%20ma%CC%81s%20constituidas%20en%20Chile.pdf"
                >Ventajas y desventajas de sociedades comerciales más utilizadas
                en Chile</a
              >
              <!-- <p class="description">
              Un gran cuadro comparativo para entender las virtudes y defectos
              que poseen los distintos tipos de sociedades comerciales en Chile.
            </p> -->
            </div>
          </div>
          <div class="container">
            <img class="__image" src="../../../../assets/iceberg.png" alt="" />
            <div class="__content">
              <a
                target="_blank"
                class="text-container"
                href="https://www.misabogados.com/hubfs/2020/landing%20Plan%20Empresas/Iceberg%20legal%20-%202021.pdf"
                >Iceberg Legal de la Pyme</a
              >
              <!-- <p class="description">
              Un repaso por el pronóstico que MisAbogados realiza sobre las
              pautas legales que estarán marcando el 2021 para las Pymes
              chilenas, dentro de un contexto incierto.
            </p> -->
            </div>
          </div>
          <div class="container">
            <img
              class="__image"
              src="../../../../assets/images/guia-legal-2022.png"
              alt=""
            />

            <div class="__content">
              <a
                target="_blank"
                class="text-container"
                href="https://www.misabogados.com/hubfs/Gui%CC%81a%20legal%202022.pdf"
                >Guía Legal para Pymes 2022</a
              >
              <!-- <p class="description">
              Un gran cuadro comparativo para entender las virtudes
              <span
                >y defectos que poseen los distintos tipos de sociedades
                comerciales en Chile.</span
              >
            </p> -->
            </div>
          </div>
          <div class="container">
            <img
              class="__image"
              src="../../../../assets/images/Ley-21327-modernizacion-direccion-trabajo.jpeg"
              alt=""
            />

            <div class="__content">
              <a
                target="_blank"
                class="text-container"
                href="https://www.misabogados.com/blog/es/ley-21327-modernizacion-direccion-del-trabajo"
                >Ley 21.327: Modernización de la Dirección del trabajo</a
              >
              <!-- <p class="description">
              Una síntesis sobre los principales elementos que trae la nueva ley
              21.230 que regula la frecuencia y registro de las cobranzas
              extrajudiciales en Chile.
            </p> -->
            </div>
          </div>
          <div class="container">
            <img
              class="__image"
              src="../../../../assets/Ebook_9_Obligaciones_para_un_negocio_seguro.jpg"
              alt=""
            />

            <div class="__content">
              <a
                target="_blank"
                class="text-container"
                href="https://www.misabogados.com/hubfs/ebooks/Ebook%209%20Obligaciones%20para%20un%20negocio%20seguro%20-%20MisAbogados.pdf"
                >9 obligaciones para un negocio seguro</a
              >
              <!-- <p class="description">
              Una síntesis sobre los principales elementos que trae la nueva ley
              21.230 que regula la frecuencia y registro de las cobranzas
              extrajudiciales en Chile.
            </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
  <div v-else class="base-card utility card-documents">
    <div class="title_skeleton">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="20"
        animation="fade"
      />
    </div>
    <div class="body_skeleton">
      <div class="container_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="50"
          animation="fade"
        />
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>
      <div class="container_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="50"
          animation="fade"
        />
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>
      <div class="container_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="50"
          animation="fade"
        />
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>
      <div class="container_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="50"
          animation="fade"
        />
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>
      <div class="container_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="50"
          animation="fade"
        />
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>
      <div class="container_skeleton">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="50"
          animation="fade"
        />
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
import { mapGetters } from "vuex";
export default {
  components: {
    VueSkeletonLoader,
  },
  computed: {
    ...mapGetters("Home", {
      GetFlagLoaderDocuments: "GetFlagLoaderDocuments",
    }),
  },

  data: () => {
    return {
      isLoad: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../sharedComponents.scss";

.__content a {
  transition: all 200ms ease-in-out;

  &:hover {
    font-size: 12px;
    color: #0064ff;
  }
}
.container_skeleton {
  box-sizing: border-box;

  display: flex;
  gap: 1rem;
  height: 50px;
  > .skeleton_utilities {
    width: 100% !important;
  }
  > div .loader {
    width: 100% !important;
  }
}
</style>
