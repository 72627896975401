import api from "../../../service/api";
import Vue from "vue";

export default {
  // return todas las compañias asociadas al usuario logeado
  user_whitelist() {
    return api
      .get("/api/v1/user_whitelist/", {
        params: {
          is_active: true,
        },
      })
      .then((response) => response.data);
  },

  // retorna el usuario perteneciente a la empresa seleccionada

  own_profile(company_id) {
    return api
      .get(`/api/v1/user_profile/${company_id}/me`)
      .then((response) => response.data);
  },

  onErrorLogin(data) {
    Vue.toasted.error(data.detail.non_field_errors);
  },
};
