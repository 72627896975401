import api from "../../../../service/api";
import apiForm from "../../../../service/apiFormData";

export default {
  list_document_type_category() {
    return api
      .get(`/api/v1/document_category/`, {
        params: {
          parent__isnull: true,
          "name__icontains!": "Documentos Personales",
          country: "CL",
        },
      })
      .then((response) => response.data);
  },
  getRetriveSettings(client_company_id, countryCode) {
    return api
      .get(`/api/v1/setting_scope/${client_company_id}`, {
        params: {
          country: countryCode,
        },
      })
      .then((response) => response.data);
  },
  settingsInstance(client_company_id, scope, country) {
    return api
      .get(`/api/v1/setting_scope/${client_company_id}`, {
        params: {
          scope: scope,
          country: country,
        },
      })
      .then((response) => response.data);
  },
  getListGroups(client_company_id) {
    return api
      .get(`api/v1/group/${client_company_id}`, {
        params: {
          fields: "id,group_name",
          "type!": 0,
        },
      })
      .then((response) => response.data);
  },
  getDocumentTypePermByRol(admin_company_id, employee_group_id, country) {
    return api
      .get(`api/v1/document_type/${admin_company_id}/${employee_group_id}`, {
        params: {
          country: country,
          is_active: true,
        },
      })
      .then((response) => response.data);
  },

  sendChangeApiKey(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/setting/${admin_company_id}`, payload)
      .then((response) => response.data);
  },
  sendChangeUserInstance(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/setting/${admin_company_id}`, payload)
      .then((response) => response.data);
  },
  sendChangeReportLimitSettingRegional(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/setting/${admin_company_id}`, payload)
      .then((response) => response.data);
  },
  sendChangeDocumentTypeByRol(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/setting/${admin_company_id}`, payload)
      .then((response) => response.data);
  },
  sendChangeDocumentTypeByRegion(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/setting/${admin_company_id}`, payload)
      .then((response) => response.data);
  },
};
