<template>
  <Transition v-if="GetFlagLoaderDocuments" name="fade">
    <div class="base-card utility card-documents ">
      <h1 class="utility__title title-container">Redacta un documento</h1>
      <div class="main-utility">
        <div class="top-utility">
          <div
            class="container-list"
            v-for="(document, index) in listDocument"
            :key="index"
          >
            <div class="__content">
              <div @click="selectDocument(document)" class="text-container">
                {{ document.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="bot-button"></div>
      </div>
    </div>
  </Transition>
  <div v-else class="base-card utility card-documents">
    <div class="title_skeleton">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="20"
        animation="fade"
      />
    </div>

    <div class="body_skeleton">
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["listDocument"],
  components: {
    VueSkeletonLoader,
  },
  computed: {
    ...mapGetters("Home", {
      GetFlagLoaderDocuments: "GetFlagLoaderDocuments",
    }),
  },

  data: () => {
    return {
      isLoad: false,
    };
  },

  methods: {
    selectDocument(document) {
      this.$store.dispatch("Documents/selectDocument", document);

      this.$router.push({
        name: "creatDocument",
        params: {
          document_name: document["name"],
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sharedComponents.scss";

.text-container {
  transition: all 200ms ease-in-out;

  &:hover {
    font-size: 12px;
    color: #0064ff;
  }
}
</style>
