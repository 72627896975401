import * as types from "./mutations-types";
export default {
  // set view interface for region settings only

  [types.SET__ACTIVE_VIEW__REGIONAL__SETTINGS](state, param) {
    let RouterViewSettingRegionalActive = {
      default__view__settings__regional: param == 0 ? true : false,
      view__setting_report__limitation: param == 1 ? true : false,
      view__setting_document_region: param == 2 ? true : false,
      view__setting_document_role: param == 3 ? true : false,
    };
    state.RouterViewSettingRegional = RouterViewSettingRegionalActive;
  },

  // end-regional-settings mutations
  //---------------------------------------------------------

  [types.SET_COUNTRY_CODE](state, countryCode) {
    let country;
    if (countryCode == "CL") {
      country = {
        name: "Chile",
        value: countryCode,
      };
    } else if (countryCode == "CO") {
      country = {
        name: "Colombia",
        value: countryCode,
      };
    } else if (countryCode == "MX") {
      country = {
        name: "Mexico",
        value: countryCode,
      };
    }
    state.countryCode = country;
  },

  // Set active category element
  [types.SET_CATEGORY_ACTIVE_TEMPLATE](state, data) {
    state.categoryConfigForTemplate = data;
  },
  //---------------------------------------------------------

  // retrive settings mutations
  //---------------------------------------------------------

  [types.GET_RETRIVE_SETTINGS](state, data) {
    // no error set failure false
    state.failureCategoryInitialSettings = false;

    // push category config for tempalte.

    let categoryConfig = [];



    data.map((el, index) => {
      let newValues = {
        id: index + 1,
        title: el.title,
        status: el.has_access ? el.has_access : false,
        active: index == 0 ? true : false,
      };
      categoryConfig.push(newValues);
    });
    state.categoryConfigForTemplate = categoryConfig;
  },
  [types.FAILURE_RETRIVE_SETTINGS](state) {
    state.failureCategoryInitialSettings = true;
  },

  // end-retrive settings mutations
  //---------------------------------------------------------

  // Set USER settings instance
  [types.SET_USER_SETTING_INSTANCE](state, userInstance) {
    let instanceUserSettings = [];

    let lenguajeOptions = ["Español", "English"];
    let DarkModeOptions = ["Activado", "Desactivado"];

    userInstance.map((el, index) => {
      let newValue = {
        id: el.settings != null ? el.settings.id : "",
        title: el.title,
        defaultValue: el.settings != null ? el.settings.value : "",
        // option index  == 0 is for user lenguage instance
        optionData: index == 0 ? lenguajeOptions : DarkModeOptions,
        bindReactiveData: index == 0 ? "Languages" : "DarkMode",
        accessSetting: el.settings != null ? true : false,
      };
      // push instance settings user
      instanceUserSettings.push(newValue);
    });
    state.UserSettingsInstance = instanceUserSettings;
  },

  [types.SET_LENGUAGE_VALID_CHANGE](state, params) {
    if (!params) {
      state.userLenguageInstanceChange = false;
    } else {
      state.userLenguageInstanceChange = params;
    }
  },
  [types.SET_DARKMODE_VALID_CHANGE](state, params) {
    // state.userDarkModeInstanceChange = data;
    if (!params) {
      state.userDarkModeInstanceChange = false;
    } else {
      state.userDarkModeInstanceChange = params;
    }
  },
  //Set initial values for settings tenant (company)
  [types.SET_TENANT_SETTING_INSTANCE](state, tenantInstance) {
    let instanceTenantSettings = [];

    tenantInstance.map((el) => {
      let newValue = {
        id: el.settings != null ? el.settings.id : "",
        title: el.title,
        description: el.description,
        accessSetting: el.settings != null ? true : false,
      };
      // push instance settings tenant
      instanceTenantSettings.push(newValue);
    });

    let tenantSettingInstance = instanceTenantSettings[0];

    state.tenantSettingsInstance = tenantSettingInstance;
  },

  // Retrive setting for region instances

  [types.SET_REGION_SETTING_INSTANCE](state, regionInstances) {
    let instanceRegionSettings = [];
    let ArrayPermission = [];
    let flagNotData = false;
    state.defaultReportLimit = [];
    regionInstances.map((el, index) => {
      let newValue = {
        id: el.settings != null ? el.settings.id : "",
        title: el.title,
        description: el.description,
        paramsToRenderChild: index + 1,
        accessSetting: el.settings != null ? true : false,
        instanceSetting: el.settings != null ? el.settings : null,
      };
      let hasAnyTruePermission = el.settings != null ? true : false;
      ArrayPermission.push(hasAnyTruePermission);

      // push instance settings tenant
      instanceRegionSettings.push(newValue);
      state.noHasPermission = flagNotData;
    });

    let reportDefaultValues =
      instanceRegionSettings[0].instanceSetting != null
        ? instanceRegionSettings[0].instanceSetting
        : null;

    if (reportDefaultValues != null) {
      let converDaysTemp = reportDefaultValues.value.slice(0, 1);

      let defaultValueReport = {
        id: reportDefaultValues.id,
        value: converDaysTemp,
      };

      state.defaultReportLimit.push(defaultValueReport);
    }

    state.noHasPermission = ArrayPermission.includes(true);
    state.regionalSettingsInstance = instanceRegionSettings;
  },
  //settings regional document type by rol
  [types.SET_LIST_GROUP](state, data) {
    state.listGroup = data;
  },
  [types.SET_DOCUMENT_TYPE_PERM_BY_ROL](state, data) {
    state.defaultDocumentTypePermByRol = data;
  },
  [types.SET_ID_GROUP_DOCUMENT_TYPE_PERM_BY_ROL](state, data) {
    state.groupIdforDocumentTypePermByRol = data;
  },

  [types.SET_REPORT_LIMIT_USE_VALID_CHANGE](state, params) {
    if (!params) {
      state.SaveValidRegionalSettings.limitUseInstanceChange = false;
    } else {
      state.SaveValidRegionalSettings.limitUseInstanceChange = params;
    }
  },
  [types.CLEAN_LIMIT_USE_VALID_CHANGE](state) {
    state.SaveValidRegionalSettings.limitUseInstanceChange = false;
  },

  [types.CLEAN_DEFAULT_DOCUMENT_TYPE_BY_REGION](state) {
    state.defaultListDocumentTypeByregion = [];
  },

  [types.SET_DEFAULT_DOCUMENT_TYPE_BY_REGION](state, params) {
    let newParams = {
      id: params.id,
      is_active: params.is_active,
    };
    state.defaultListDocumentTypeByregion.push(newParams);
  },
  [types.CLEAR_ELEMENT_DOCUMENT_TYPE_BY_REGION](state) {
    state.documentTypeByRegionValues = [];
  },
  [types.CLEAR_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL](state) {
    state.documentTypeByPermByRolValues = [];
  },
  [types.ADD_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL](state, data) {
    //--------------------------------
    // @Input: data = {
    //   id: q231231,
    //   value: true / false
    // }
    // Description: en el footer
    // @Output: state.documentTypeByRegionValues
    //--------------------------------

    let defaultValues = state.defaultDocumentTypePermByRol;
    let documentTypeValues = state.documentTypeByPermByRolValues;

    defaultValues.map((el) => {
      if (el.id == data.id) {
        if (el.has_perm == data.has_perm) {
          let updateValues = documentTypeValues.filter(
            (el) => el.id !== data.id
          );

          state.documentTypeByPermByRolValues = updateValues;
          return;
        } else {
          state.documentTypeByPermByRolValues.push(data);
        }
      }
    });

    /* recibir el valor
       comprar el valor entrante con el arreglo de valores por defecto para:
           validar que el valor entrante es completamente igual al valor por defecto:
                  Si eso es verdad, NO agregamos valores nuevos al state.documentTypeByRegionValues que almacena los valores modificados
                  si existe pero su value son distintos se agrega el objeto al estado que almacena los cambios modificando el value del objeto

          Antes de añadir el objeto entrante al state.documentTypeByRegionValues validamos si este ya existe:
            Si existe actualizamos su value
            sino lo añadimos al arreglo

            */
  },
  [types.ADD_ELEMENT_DOCUMENT_TYPE_BY_REGION](state, data) {
    //--------------------------------
    // @Input: data = {
    //   id: q231231,
    //   value: true / false
    // }
    // Description: en el footer
    // @Output: state.documentTypeByRegionValues
    //--------------------------------

    let defaultValues = state.defaultListDocumentTypeByregion;
    let documentTypeValues = state.documentTypeByRegionValues;

    defaultValues.map((el) => {
      if (el.id == data.id) {
        if (el.is_active == data.is_active) {
          let updateValues = documentTypeValues.filter(
            (el) => el.id !== data.id
          );

          state.documentTypeByRegionValues = updateValues;
          return;
        } else {
          state.documentTypeByRegionValues.push(data);
        }
      }
    });

    /* recibir el valor
       comprar el valor entrante con el arreglo de valores por defecto para:
           validar que el valor entrante es completamente igual al valor por defecto:
                  Si eso es verdad, NO agregamos valores nuevos al state.documentTypeByRegionValues que almacena los valores modificados
                  si existe pero su value son distintos se agrega el objeto al estado que almacena los cambios modificando el value del objeto

          Antes de añadir el objeto entrante al state.documentTypeByRegionValues validamos si este ya existe:
            Si existe actualizamos su value
            sino lo añadimos al arreglo

            */
  },
};
