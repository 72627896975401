<template>
  <div class="container__box__chat" v-if="chatLoaded">
    <!-- In case when the user is admin -->
    <div class="" v-if="is_staff">
      <div class="container__message_single_chat">
        <div class="container__message_pin" v-if="message.user.is_staff">
          <div class="system_messages">
            <div class="fixed__message" v-if="message.pinned">
              <div class="">
                <span class="pinned">
                  {{ message.text }}
                </span>
                <div
                  :class="
                    message.user.is_attendant
                      ? 'background__initial__admin'
                      : 'own__user__not__admin'
                  "
                  class="logo_chat_user_initial"
                  v-if="message.user.initials != null"
                >
                  <div class="circle_initials">
                    {{ message.user.initials }}
                  </div>
                </div>
                <div v-else class="logo_chat_user">
                  <LogoMA />
                </div>
              </div>
              <span class="text__pin text_pin_client">
                <Pinbase /> Mensaje marcado</span
              >
            </div>
            <div class="fixed__message" v-else>
              <div class="">
                <div class="activePin" v-if="message.files.length <= 0 || null">
                  <div
                    class="loader_button_quotation_message loader_pinned"
                    v-if="loaderPineed"
                  ></div>
                  <PinIcon v-else @click="togglePinFun()" />
                </div>
                <span v-if="message.text.length > 0" class="text">
                  {{ message.text }}
                </span>
                <span v-else class="default__messages_files">
                  {{ "Archivos adjuntos" }}
                </span>
                <div
                  :class="
                    message.user.is_attendant
                      ? 'background__initial__admin'
                      : 'own__user__not__admin'
                  "
                  class="logo_chat_user_initial"
                  v-if="message.user.initials != null"
                >
                  <div class="circle_initials">
                    {{ message.user.initials }}
                  </div>
                </div>
                <div v-else class="logo_chat_user">
                  <LogoMA />
                </div>
              </div>
              <div
                class="files_inc"
                v-for="file in message.files"
                :key="file.id"
              >
                <FileAttach />
                <div class="files__name">
                  <span> {{ file.filename.slice(0, 20) }}... </span>
                  <FileDownload @click="downloadPdf(file.url, file.filename)" />
                </div>
              </div>
              <span class="text__date">
                {{ date }}
              </span>
            </div>
          </div>
        </div>
        <div class="container__message_pin" v-else>
          <div class="user_message">
            <div class="fixed__message" v-if="message.pinned">
              <div class="user_name__message">
                <div class="empty"></div>
                <div class="user_text">
                  {{ message.user.full_name }}
                </div>
              </div>
              <div class="">
                <div class="logo_chat_user">
                  <LogoMA />
                </div>
                <div class="activePin" v-if="!message.pinned">
                  <PinIcon @click="togglePinFun()" />
                </div>
                <span class="text">
                  {{ message.text }}
                </span>
              </div>
              <span class="text__pin pin__sistem">
                <Pinbase /> Mensaje marcado</span
              >
            </div>
            <div class="fixed__message" v-else>
              <div class="user_name__message">
                <div class="empty"></div>
                <div class="user_text">
                  {{ message.user.full_name }}
                </div>
              </div>
              <div class="">
                <div class="logo_chat_user">
                  <LogoMA />
                </div>
                <span v-if="message.text.length > 0" class="text">
                  {{ message.text }}
                </span>
                <span v-else class="default__messages_files">
                  {{ "Archivos adjuntos" }}
                </span>
                <div
                  class="activePin"
                  v-if="(!message.pinned && message.files.length <= 0) || null"
                >
                  <PinIcon @click="togglePinFun()" />
                </div>
              </div>
              <div
                class="files_inc"
                v-for="file in message.files"
                :key="file.id"
              >
                <FileAttach />
                <div class="files__name">
                  <span> {{ file.filename.slice(0, 20) }}... </span>
                  <FileDownload @click="downloadPdf(file.url, file.filename)" />
                </div>
              </div>
              <span class="text__date">
                {{ date }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- In case when the user is not admin -->
    <div
      class="container__message_single_chat"
      id="container__user__messages"
      v-else
    >
      <div class="container__message_pin" v-if="!message.user.is_staff">
        <div class="system_messages">
          <div class="fixed__message" v-if="message.pinned">
            <div class="">
              <span :class="message.pinned ? 'pinned' : ''">
                {{ message.text }}
              </span>

              <div
                class="logo_chat_user_initial own__user__not__admin"
                v-if="message.user.initials != null"
              >
                <div class="circle_initials">
                  {{ message.user.initials }}
                </div>
              </div>
              <div v-else class="logo_chat_user">
                <LogoMA />
              </div>
            </div>
            <span class="text__pin text_pin_client">
              <Pinbase /> Mensaje marcado</span
            >
          </div>
          <div class="fixed__message" v-else>
            <div class="">
              <div class="activePin" v-if="message.files.length <= 0 || null">
                <PinIcon @click="togglePinFun()" />
              </div>
              <span v-if="message.text.length > 0" class="text">
                {{ message.text }}
              </span>
              <span v-else class="default__messages_files">
                {{ "Archivos adjuntos" }}
              </span>
              <div
                class="logo_chat_user_initial own__user__not__admin"
                v-if="message.user.initials != null"
              >
                <div class="circle_initials">
                  {{ message.user.initials }}
                </div>
              </div>

              <div v-else class="logo_chat_user">
                <LogoMA />
              </div>
            </div>
            <div class="files_inc" v-for="file in message.files" :key="file.id">
              <FileAttach />
              <div class="files__name">
                <span> {{ file.filename.slice(0, 20) }}... </span>
                <FileDownload @click="downloadPdf(file.url, file.filename)" />
              </div>
            </div>
            <span class="text__date">
              {{ date }}
            </span>
          </div>
        </div>
      </div>

      <div class="container__message_pin" v-else>
        <div class="user_message">
          <div class="fixed__message" v-if="message.pinned">
            <div class="user_name__message">
              <div class="empty"></div>
              <div class="user_text">
                {{ message.user.full_name }} -

                <span class="is_attendant" v-if="message.user.is_attendant">
                  Coordinador
                </span>
                <span class="not_attendant" v-else> Encargado </span>
              </div>
            </div>
            <div class="">
              <div v-if="message.user.is_attendant" class="logo_chat_user_alt">
                <LogoALT />
              </div>
              <div v-else class="logo_chat_user">
                <LogoMA />
              </div>
              <div class="activePin" v-if="!message.pinned">
                <PinIcon @click="togglePinFun()" />
              </div>
              <span class="pinned">
                {{ message.text }}
              </span>
            </div>
            <span class="text__pin pin__sistem">
              <Pinbase /> Mensaje marcado</span
            >
          </div>
          <div class="fixed__message" v-else>
            <div class="user_name__message">
              <div class="empty"></div>
              <div class="user_text">
                {{ message.user.full_name }} -

                <span class="is_attendant" v-if="message.user.is_attendant">
                  Coordinador
                </span>
                <span class="not_attendant" v-else> Encargado </span>
              </div>
            </div>
            <div class="">
              <div v-if="message.user.is_attendant" class="logo_chat_user_alt">
                <LogoALT />
              </div>
              <div v-else class="logo_chat_user">
                <LogoMA />
              </div>
              <span v-if="message.text.length > 0" class="text">
                {{ message.text }}
              </span>
              <span v-else class="default__messages_files">
                {{ "Archivos adjuntos" }}
              </span>
              <div
                class="activePin"
                v-if="(!message.pinned && message.files.length <= 0) || null"
              >
                <PinIcon @click="togglePinFun()" />
              </div>
            </div>
            <div class="files_inc" v-for="file in message.files" :key="file.id">
              <FileAttach />
              <div class="files__name">
                <span> {{ file.filename.slice(0, 20) }}... </span>
                <FileDownload @click="downloadPdf(file.url, file.filename)" />
                <!-- //TODO:iterar mensajes cuando no se es staff -->
              </div>
            </div>
            <span class="text__date">
              {{ date }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PinIcon from "../../assets/Pin.svg";
import Pinbase from "../../assets/pinbase.svg";
import FileDownload from "../../assets/file_download.svg";
import LogoMA from "../../assets/logoMA.svg";
import LogoALT from "../../assets/logo_alt.svg";
import FileAttach from "../../assets/fileAttach.svg";
import moment from "moment";

export default {
  props: ["message"],
  created() {
    let date = this.message.createdAt;
    let ampm = moment(date).format("A").toLowerCase();
    let dateCalendar = moment(date).calendar();

    let dateFormated = moment(date).format("L");

    if (dateFormated == dateCalendar) {
      this.date = `${dateCalendar}.`;
    } else {
      this.date = `${dateCalendar} ${ampm}. `;
    }

    this.chatLoaded = true;
  },
  mounted() {},
  computed: {
    ...mapGetters("Login", {
      is_staff: "is_staff",
    }),
  },
  components: {
    PinIcon,
    Pinbase,
    LogoMA,
    FileAttach,
    FileDownload,
    LogoALT,
  },
  data: () => {
    return {
      togglePin: true,
      date: "",
      chatLoaded: false,
      loaderPineed: false,
    };
  },
  methods: {
    downloadPdf(url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.download = `${name}`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    togglePinFun() {
      this.loaderPineed = true;
      setTimeout(() => {
        this.loaderPineed = false;
      }, 3000);
      this.$emit("messageSelectedEmit", this.message);
    },
  },
};
</script>

<style lang="scss">
.circle_initials {
  width: 25px !important;
  display: flex;
  justify-content: center;
}
.background__initial__admin {
  background-color: #bfd8ff !important;
  color: #000032 !important;
  font-weight: 500;
}
.text__pin {
  color: #0064ff;
}
.text__date {
  color: #c5cbd5;
}
.text__pin,
.text__date {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}
.container__box__chat {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0px;
}
.fixed__message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div.container_pinned_message {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    border: 1px solid #9c9c9c;
    border-radius: 8px;
    padding: 0.5rem;
  }
  > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    > span.default__messages_files {
      background: #bfd8ff !important;
      border-radius: 5px;
      padding: 0.5rem;
    }

    > span.text {
      @media screen and (max-width: 768px) and (min-width: 200px) {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }
      white-space: pre-line !important;
      border: 1px solid #9c9c9c;
      border-radius: 8px;
      padding: 0.5rem;
      width: auto;
    }

    > span.pinned {
      @media screen and (max-width: 768px) and (min-width: 200px) {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }
      border: 1px solid #0064ff;
      border-radius: 8px;
      padding: 0.5rem;
    }

    > .logo_chat_user {
      display: flex;
      justify-content: center;
      width: 25px;
      height: 25px;
      background: #000032;
      border-radius: 50%;
    }
    > .logo_chat_user_alt {
      display: flex;
      justify-content: center;
      width: 25px;
      height: 25px;
      background: #bfd8ff;
      border-radius: 50%;
      > svg {
        width: 18px;
      }
    }

    > .logo_chat_user_initial {
      display: flex;
      justify-content: center;
      width: 25px;
      height: 25px;
      background: #000032;
      border-radius: 50%;
      color: white;
      font-size: 9px;
      align-items: center;
    }
  }
}

.user_name__message {
  display: flex;
  > .user_text {
    color: #000032;
    font-weight: 500;

    > span.is_attendant {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #00d461 !important;
    }

    > span.not_attendant {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #0064ff !important;
    }
  }
}
.user_name__message .empty {
  width: 25px;
}

.own__user__not__admin {
  background: #fa4c35 !important;
}
.activePin {
  display: none;
}
.fixed__message {
  &:hover div .activePin {
    z-index: 1111111111;
    display: block !important;
    cursor: pointer;
  }
}
.container__message_pin {
  > .user__chat_system {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
  }
  > .user__chat {
    display: flex;
  }
}
.container__message_single_chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  > .container__message_pin .system_messages {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;

    > .fixed__message {
      align-items: flex-end;
      margin-left: 5rem;

      > div {
        justify-content: flex-end;

        > .pinned {
          width: auto;
        }
      }
      > .files_inc {
        display: flex;
        justify-content: space-between;
        // width: 100% !important;
        text-align: left;
        padding: 0.3rem 0.5rem;
        margin-right: 2.5rem;
        border: 1px solid #000032;
        border-radius: 5px;
        height: 50px;
        margin-top: 0.4rem;
        width: 282px;
        @media screen and (max-width: 600px) and (min-width: 200px) {
          width: 69%;
        }
        > svg {
          width: 20%;
        }
      }

      > .text__pin {
        display: block;
        width: auto;
        text-align: end;
        white-space: nowrap;
      }

      > .text_pin_client {
        margin-right: 25px;
      }

      > .text__date {
        display: block;
        width: 100%;
        text-align: right;
        padding-top: 0.2rem;
        padding-right: 2.5rem;
      }
    }
  }

  > .container__message_pin .user_message {
    @media screen and (max-width: 768px) and (min-width: 200px) {
      margin-right: 0rem;
    }
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-right: 5rem;
    flex-wrap: nowrap;
    > .fixed__message {
      @media screen and (max-width: 768px) and (min-width: 200px) {
        width: 100%;
      }
      > .files_inc {
        display: flex;
        // justify-content: space-between;
        margin-left: 2.5rem;
        // width: 100% !important;
        text-align: left;
        padding: 0.3rem 0.5rem;
        // margin-left: 1.8rem;
        border: 1px solid #000032;
        border-radius: 5px;
        height: 50px;
        margin-top: 0.4rem;
        width: 282px;
        @media screen and (max-width: 600px) and (min-width: 200px) {
          width: 87%;
        }
        > svg {
          width: 15%;
        }
      }
      > .text__pin {
        display: block;
        width: 100%;
        text-align: start;
        // padding-right: 1rem;
        white-space: nowrap;
      }
      > .pin__sistem {
        margin-left: 20px !important;
      }
      > .text__date {
        display: block;
        width: 100%;
        text-align: left;
        padding-top: 0.2rem;
        padding-left: 2.5rem;
      }
    }
  }
}

.files__name {
  display: flex;
  width: 100%;
  > span {
    @media screen and (max-width: 768px) and (min-width: 200px) {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000032;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  > svg {
    cursor: pointer !important;
  }
}
.loader_pinned {
  display: block;
}
</style>
