<template>
  <div class="modal__content">
    <div class="image_onboarding_employee onboarding_img_fix">
      <FourthStepOnboarding />
    </div>
    <div class="content_onboarding_employee_fourth_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_employee">¡Comencemos!</div>
      </div>
      <div class="text_container_onboarding text_step_fourth ">
        <div class="">
          ¡Estás listo! Ahora agrega tu primer “miembro” o “usuario”
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FourthStepOnboarding from "./assets/Fourth_step_onboarding.svg";
import { mapGetters } from "vuex";

export default {
  props: ["size"],
  components: {
    FourthStepOnboarding,
  },
  data() {
    return {
      stepOnBoarding: 1,
    };
  },
  created() {},
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployeesOnBoarding: "stepEmployeesOnBoarding",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      group: "group",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      this.$store.dispatch("EmployeesV2/cleancounterStepEmployeeOnBoarding");
      if (this.inSwitch) {
        this.$router.push({ name: "selectedEmployee" });
      } else if (this.group == "Admin") {
        this.$router.push({ name: "selectedEmployeeAdmin" });
      } else {
        this.$router.push({ name: "selectedEmployee" });
      }
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__employee");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
@import "./onBoarding.scss";
</style>
