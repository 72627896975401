<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__not_access"
    ref="modal__not_access"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="modal__coming__soon text-filter-light text-center">
      <div class="not__access__modal">
        {{ description }}
        <DeniedIcon />
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/components/modal/assets/cancel.svg";
import DeniedIcon from "@/components/modal/assets/undraw_cancel_re_pkdm.svg";
export default {
  props: ["size", "description"],
  components: {
    CancelIcon,
    DeniedIcon,
  },
  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__not_access");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__not_access");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.modal__coming__soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 73%;
  gap: 1.8rem;
}

.not__access__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: auto;
  height: auto;
  margin-left: 0.6rem;
  margin-right: 0.6rem;

  > svg {
    height: auto !important;
    width: auto !important;
  }
}
</style>
