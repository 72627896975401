var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__max__file__message",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : 'modal__default',attrs:{"id":"modal__max__file__message"}},[_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"warning_icon_message"},[_c('WarningSymbolMessage')],1),_vm._m(0),_c('div',{staticClass:"footer_modal footer__modal__cancel"},[_c('div',{staticClass:"fix-btn-mac button_send_message",on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Aceptar ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_onboarding_employee"},[_c('div',{staticClass:"container_onboarding_title"},[_c('div',{staticClass:"title_warning_modal_message"},[_vm._v(" Máximo de documentos alcanzado ")])]),_c('div',{staticClass:"text_container_warning_message"},[_vm._v(" Sólo puede adjuntar un máximo de 5 archivos por mensaje, para enviar nuevos adjunta los archivos en un nuevo mensaje. ")])])
}]

export { render, staticRenderFns }