<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__confirm__document__draft"
    ref="modal__confirm__document__draft"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="modal__confirm">
      <div class="text-filter-light title_modal_confirm">
        ¿Deseas enviar a borradores
        <span class="text-filter">
          {{ name_document }}
        </span>
        para
        <span class="text-filter"> {{ totalSigners }} firmantes? </span>
      </div>

      <div class="text-container text_modal_user_title">
        El documento será recibido para ser firmado por:
      </div>
      <div
        class="container_signer_modal text-container"
        v-for="(signer, index) in signers"
        :key="index"
      >
        <div class="">
          {{ signer.name }}
        </div>
        <div class="">
          {{ signer.rut }}
        </div>
      </div>
    </div>

    <div class="footer_modal__confirm">
      <div disabled="disabled" class="secondary_button" @click="cancelModal()">
        <span> Cancelar</span>
      </div>
      <div class="btn-vue-primary fix-btn-mac" @click="ConfirmSendDraft()">
        <span> Aceptar </span>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/components/modal/assets/cancel.svg";
import { mapGetters } from "vuex";
export default {
  props: [
    "signers",
    "totalSigners",
    "name_document",
    "id_document",
    "isFile",
    "File",
    "Tags",
    "sign_type",
    "sign_status",
    "size",
  ],
  components: {
    CancelIcon,
  },

  data: () => {
    return {
      dataFromLocal: null,
      dataFromApp: null,
      nameDocument: "",
    };
  },
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__confirm__document__draft");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__confirm__document__draft");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    ConfirmSendDraft() {
      let sign_payload = JSON.stringify(this.signers);
      let document_id = this.id_document;

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let sign_type = this.sign_type;
      let tags = this.Tags;
      let sign_status = this.sign_status;
      let payload;

      // this.$store.dispatch("DigitalSignature/loadDocumentOn");
      if (this.isFile) {
        let payload = new FormData();
        let file = this.File;
        payload.append("file", file);
        payload.append("sign_status", sign_status);
        payload.append("sign_type", sign_type);
        if (tags != null) {
          payload.append("tags", tags);
        }
        payload.append("sign_payload", sign_payload);
        let data = {
          admin_company_id,
          payload,
        };

        this.$store.dispatch("DigitalSignature/sendDocumentFromLocal", data);
        this.$store.dispatch("DigitalSignature/cleanDocumentName");

        setTimeout(() => {
          this.$router.push({ name: "DraftElements" });
          this.$store.dispatch("DigitalSignature/loadDocumentOff");
        }, 3000);
      } else {
        if (tags != null) {
          payload = {
            document_id,
            sign_status,
            sign_type,
            tags,
            sign_payload,
          };
        } else {
          payload = {
            document_id,
            sign_status,
            sign_type,
            sign_payload,
          };
        }

        let data = {
          payload,
          admin_company_id,
        };

        this.$store.dispatch("DigitalSignature/sendDocumentFromApp", data);
        this.$store.dispatch("DigitalSignature/cleanDocumentName");

        setTimeout(() => {
          this.$store.dispatch("DigitalSignature/loadDocumentOff");

          this.$router.push({ name: "DraftElements" });
        }, 5000);
        this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../DigitalSignature.scss";
</style>
