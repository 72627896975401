// setUserLogin(state, payload) {
//   state.token = payload.token;
//   localStorage.setItem("token", payload.token);
//   state.is_staff = payload.is_staff;
//   localStorage.setItem("is_staff", payload.is_staff);
// },
// setUserLoginEmpty() {
//   localStorage.clear();
// },
// setUserCompanies(state, payload) {
//   state.companies = payload
//   localStorage.setItem("companies", payload);
// },
// setSelectedCompanyData(state, payload) {
//   state.selected_company_data = payload;
//   localStorage.setItem("selected_company_data", payload);
// },
// setUserProfile(state, payload) {
//   state.user_profile = payload
//   localStorage.setItem("user_profile", payload);
// },

import * as types from "./mutations-types";
import authService from "../services/authService";
import loginServices from "../services/loginServices";
import tokenService from "@/service/tokenService";

export default {
  authGuardRouteValidator({ commit }, token) {
    return new Promise((resolve, reject) => {
      tokenService
        .verify_token(token)
        .then(() => {
          commit(types.OUTH_LOGIN_SUCCESS);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_LOGIN_FAILURE, { error });
        });
    });
  },

  // fetch usuario en login
  updateCredentialToken({ commit }, token) {
    commit(types.OUTH_LOGIN_SUCCESS, token);
  },
  fetchLogin({ commit }, user) {
    commit(types.FETCH_LOGIN_REQUEST);

    return new Promise((resolve, reject) => {
      authService
        .auth_login(user)
        .then((user) => {
          commit(types.FETCH_LOGIN_SUCCESS, { user });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_LOGIN_FAILURE, { error });
        });
    });
  },
  setLogginFalse({ commit }, error) {
    commit(types.FETCH_LOGIN_FAILURE, { error });
  },

  fetchLoginOauth({ commit }, payload) {
    commit(types.FETCH_LOGIN_REQUEST_OAUTH);
    return new Promise((resolve, reject) => {
      authService
        .verify_oauth_google(payload)
        .then((user) => {
          commit(types.FETCH_LOGIN_SUCCESS_OAUTH, { user });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_LOGIN_REQUEST_OAUTH, { error });
        });
    });
  },

  fetchLoginOauthMS({ commit }, payload) {
    commit(types.FETCH_LOGIN_REQUEST_OAUTH);
    return new Promise((resolve, reject) => {
      authService
        .verify_ms_google(payload)
        .then((user) => {
          commit(types.FETCH_LOGIN_SUCCESS_OAUTH, { user });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_LOGIN_REQUEST_OAUTH, { error });
        });
    });
  },

  // seteamos todas las compañias para cargar el select/option de login
  async setAllCompanies({ commit }) {
    await loginServices.user_whitelist().then((companies) => {
      let companys = companies.results;
      let totalCompanies = companies.count;

      let params = {
        companys,
        totalCompanies,
      };

      commit(types.GET_COMPANYS_BY_USER, params);

      // TODO: ver para eliminar localstorage de companies
      localStorage.setItem("companies", JSON.stringify(companies.results));

      if (companies.results.length < 2) {
        commit(types.SET_COMPANY_SELECTED, companies.results[0].company);
      }
    });
  },

  // Setea las companies a una sola companie para el flujo de multiples compañias
  setCompanySelected({ commit }, company_id) {
    commit(types.SET_COMPANY_SELECTED, company_id);
  },

  logOut({ commit }, value) {
    localStorage.clear();
    commit(types.SET_LOG_OUT, value);
  },
  // Segun la companie seleccionada se setea el perfil del usuario de esa compañia
  setUserProfile({ commit }, company_id) {
    loginServices.own_profile(company_id).then((userProfile) => {
      commit(types.SET_USER_PROFILE, userProfile);
      localStorage.setItem("user_profile", JSON.stringify(userProfile));

      localStorage.setItem("company", JSON.stringify(userProfile.company));
    });
  },

  // modal use only
  modalOpen({ commit }) {
    commit(types.SET_MODAL_OPEN);
  },
  modalClose({ commit }) {
    commit(types.SET_MODAL_CLOSE);
  },
};
