import * as types from "./mutations-types";
import digitalSignatureService from "../services/digitalSignatureService";
// import employeService from "../../employees/services/employeService";

export default {
  searchDigitalSignature({ commit }, data) {
    commit(types.SET_DATA_CHECK, data);
  },
  setGlobalLoader({ commit }) {
    commit(types.SET_GLOBAL_LOADER, true);
    commit(types.LOADER_DOCUMENTS_ON);
  },
  closeGlobalLoader({ commit }) {
    commit(types.SET_GLOBAL_LOADER, false);
  },
  setTags({ commit }, { tagsForfront, tagForReloadRequest, tagsList }) {
    commit(types.LOADER_DOCUMENTS_ON);
    commit(types.SET_TAGS_REQUEST_FRONT, tagsForfront);
    commit(types.SET_TAGS_REQUEST, tagForReloadRequest);
    commit(types.SET_TAGS, tagsList);
  },
  deleteDocument({ commit }, { admin_company_id, id_document }) {
    return new Promise((resolve, reject) => {
      commit(types.LOADER_DOCUMENTS_ON);
      commit(types.FLAG_DELETE_DIGITAL_SIGNATURE_ON);

      digitalSignatureService
        .delete_signature(admin_company_id, id_document)
        .then(() => {
          commit(types.DELETE_DIGITAL_SIGNATURE);
          resolve(true);
        })
        .then(() => {
          // commit(types.LOADER_DOCUMENTS_OFF);
        })

        .catch((error) => {
          reject(error);
          // commit(types.FETCH_DIGITAL_SIGNATURE_FAILURE, { error });
        });
    });
  },
  fetchTags(
    { commit },
    { admin_company_id, sign_status__range, filters, limit = "10" }
  ) {
    return new Promise((resolve, reject) => {
      digitalSignatureService
        .searchTags(admin_company_id, sign_status__range, filters, limit)
        .then((data) => {
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.FETCH_DIGITAL_SIGNATURE_DATA, data);
          resolve(true);
          commit(types.SET_DATA_CHECK, true);
          commit(types.SET_FLAG, true);
          commit(types.SET_GLOBAL_LOADER, false);

          if (data.count == 0) {
            commit(types.SET_FLAG, false);
          }
        })
        .then(() => {
          // commit(types.SET_DATA_CHECK, true)
          commit(types.LOADER_DOCUMENTS_OFF);
        })

        .catch((error) => {
          reject(error);
          commit(types.FETCH_DIGITAL_SIGNATURE_FAILURE, { error });
          commit(types.SET_DATA_CHECK, false);
          commit(types.SET_FLAG, false);
        });
    });
  },
  //TODO: ver tema de los rangos para el tipo de documentos
  fetchDigitalSignature(
    { commit },
    {
      company_id,
      fields,
      sign_status,
      sign_status__range = "2,3",
      sign_init_date__year,
      sign_init_date__month,
      limit = "10",
      offset,
    }
  ) {
    // commit(types.LOADER_DOCUMENTS_ON);
    return new Promise((resolve, reject) => {
      digitalSignatureService
        .document_digital_signature(
          company_id,
          fields,
          sign_status,
          sign_status__range,
          sign_init_date__year,
          sign_init_date__month,
          limit,
          offset
        )
        .then((data) => {
          let dataPrevius = data.previous;
          commit(types.SET_PREV_PAGE, dataPrevius);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.FETCH_DIGITAL_SIGNATURE_DATA, data);
          resolve(true);
          commit(types.SET_DATA_CHECK, true);
          commit(types.SET_FLAG, true);
          commit(types.SET_GLOBAL_LOADER, false);

          if (data.count == 0) {
            commit(types.SET_FLAG, false);
          }

          // this.loading1 = false;
        })
        .then(() => {
          // commit(types.SET_DATA_CHECK, true)
          commit(types.LOADER_DOCUMENTS_OFF);
          commit(types.FLAG_DELETE_DIGITAL_SIGNATURE_OFF);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_DIGITAL_SIGNATURE_FAILURE, { error });
          commit(types.SET_DATA_CHECK, false);
          commit(types.SET_FLAG, false);
        });
    });
  },
  fetchDraftDigitalSignature({ commit }, { company_id, fields, sign_status }) {
    return new Promise((resolve, reject) => {
      digitalSignatureService
        .drafDocument_digital_signature(company_id, fields, sign_status)
        .then((data) => {
          // commit(types.LOADER_DOCUMENTS_ON);
          commit(types.FETCH_DRAFT_DIGITAL_SIGNATURE_DATA, data);
          resolve(true);

          if (data.count >= 0) {
            commit(types.LOADER_DOCUMENTS_OFF);
          }
        })

        .catch((error) => {
          reject(error);
          commit(types.LOADER_DOCUMENTS_OFF);
        });
    });
  },
  nextPagination({ commit }, { nextUrl }) {
    commit(types.LOADER_DOCUMENTS_ON);

    return new Promise((resolve, reject) => {
      digitalSignatureService
        .nextPage(nextUrl)
        .then((data) => {
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.FETCH_DIGITAL_SIGNATURE_DATA, data);
          resolve(true);
          commit(types.SET_DATA_CHECK, true);
          commit(types.SET_FLAG, true);

          if (data.count == 0) {
            commit(types.SET_FLAG, false);
          }
        })
        .then(() => {
          commit(types.LOADER_DOCUMENTS_OFF);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_DIGITAL_SIGNATURE_FAILURE, { error });
          commit(types.SET_DATA_CHECK, false);
          commit(types.SET_FLAG, false);
        });
    });
  },

  listCategorys({ commit }) {
    return new Promise((resolve, reject) => {
      digitalSignatureService
        .list_document_type_category()
        .then((data) => {
          let payload = data.results;
          let arrDocuments = [];
          for (const d of payload) {
            arrDocuments.push({ name: d.name, value: d.id });
          }
          commit(types.LIST_CATEGORYS, arrDocuments);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cleanFields({ commit }) {
    commit(types.CLEAN_DOCUMENTS);
    commit(types.LOADER_DOCUMENTS_OFF);
  },

  setDocument({ commit }, data) {
    commit(types.SET_DOCUMENT, data);
    commit(types.SET_DOCUMENT_FROM_APP_ACTIVE, true);
    commit(types.CLEAN_FLAG_DRAFT_DATA);
  },

  setDocumentFromLocal({ commit }, data) {
    commit(types.SET_DOCUMENT_FROM_LOCAL, data);
    commit(types.SET_DOCUMENT_FROM_APP_DESACTIVE);
  },
  // cargar documentos de mis abogados disponibles para firmar por filtros de fecha año mes dia, categoria.
  loadDocuments(
    { commit },
    {
      company_id,
      sign_status,
      sign_status__range,
      sign_init_date__year,
      sign_init_date__month,
      limit,
      offset,
      created_at__year,
      created_at__month,
      created_at__day,
      document_type__categories__name__icontains,
      document_type__categories__name__icontains_filter,
    }
  ) {
    commit(types.CLEAN_LOAD_DOCUMENT);

    commit(types.LOAD_DOCUMENT_FROM_APP);

    return new Promise((resolve, reject) => {
      digitalSignatureService
        .document_digital_signature(
          company_id,
          "id,document_type,file_url,created_at",
          sign_status,
          sign_status__range,
          sign_init_date__year,
          sign_init_date__month,
          limit,
          offset,
          created_at__year,
          created_at__month,
          created_at__day,
          document_type__categories__name__icontains,
          document_type__categories__name__icontains_filter
        )
        .then((data) => {
          resolve(true);

          if (data.count >= 0) {
            commit(types.CANCEL_LOAD_DOCUMENT_FROM_APP);
          }

          commit(types.LIST_DOCUMENTS, data.results);
        })

        .catch((error) => {
          reject(error);
        });
    });
  },

  setClearLoadDocument({ commit }) {
    commit(types.CLEAN_LOAD_DOCUMENT);
  },

  cleanDocumentName({ commit }) {
    commit(types.CLEAN_DOCUMENTS_NAME);
  },

  loadDocumentOn({ commit }) {
    commit(types.LOADER_DOCUMENTS_ON);
  },
  loadDocumentOff({ commit }) {
    //TODO. desactivar loader
    commit(types.LOADER_DOCUMENTS_OFF);
  },

  setSignerFromCheck({ commit }, data) {
    commit(types.ADD_SIGNER_FROM_CHECK, data);
  },
  cleanSignerFromCheck({ commit }) {
    commit(types.CLEAN_SIGNER_FROM_CHECK);
  },

  //initial signer from interface
  setInitialSigner({ commit }, data) {
    commit(types.ADD_INITIAL_SIGNER, data);
  },

  cleanInitialSigner({ commit }) {
    commit(types.CLEAN_INITIAL_SIGNER);
  },

  // anotherSigner

  setSigner({ commit }, data) {
    commit(types.ADD_SIGNER, data);
  },

  cleanSigner({ commit }) {
    commit(types.CLEAN_SIGNER);
  },
  missingSingerValidatorName({ commit }, data) {
    commit(types.MISSING_SIGNER_VALIDATOR_NAME, data);
  },
  missingSingerValidatorEmail({ commit }, data) {
    commit(types.MISSING_SIGNER_VALIDATOR_EMAIL, data);
  },
  missingSingerValidatorRut({ commit }, data) {
    commit(types.MISSING_SIGNER_VALIDATOR_RUT, data);
  },
  cleanNewSignerValidator({ commit }, data) {
    commit(types.MISSING_SIGNER_VALIDATOR_NAME, data);
    commit(types.MISSING_SIGNER_VALIDATOR_EMAIL, data);
    commit(types.MISSING_SIGNER_VALIDATOR_RUT, data);
  },

  newTotalSigners({ commit }, data) {
    commit(types.NEW_TOTAL_SIGNERS, data);
  },

  //draftSigners

  addSignerFromDraft({ commit }, payload) {
    //edit
    commit(types.ADD_SIGNER_FROM_DRAFT, payload);
  },
  cleanSignerFromDraft({ commit }) {
    commit(types.CLEAN_SIGNER_FROM_DRAFT);
  },

  deleteDraftSigner({ commit }, data) {
    commit(types.DELETE_SIGNER_FROM_DRAFT, data);
  },

  /// tracking

  setTracking({ commit }, data) {
    commit(types.ADD_TRACKING, data);
  },

  cleanTracking({ commit }) {
    commit(types.CLEAN_TRACKING);
  },
  //get url to download

  getFileToDownload({ commit }, { admin_company_id, id_document }) {
    return new Promise((resolve, reject) => {
      digitalSignatureService
        .getSingleDocument(admin_company_id, id_document)
        .then((data) => {
          resolve();
          commit(types.SET_FILE_URL, data.sign_file_url);
        })
        .catch(() => {
          reject();
        });
    });
  },

  cleanFileUrl({ commit }) {
    commit(types.CLEAN_FILE_URL);
  },

  // send document sing

  sendDocumentFromLocalToSign({ commit }, data) {
    commit(types.LOADER_DOCUMENTS_ON);
    commit(types.SEND_DOCUMENT_TO_SING, data);
    commit(types.CLEAN_FLAG_DRAFT_DATA);

    // console.log("data que llega: ", data);

    return new Promise((resolve, reject) => {
      // let admin_company_id = data.admin_company_id;
      // let payload = data.payload;

      // for (var pair of payload.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      // console.log("from app: id admin company id", admin_company_id);
      // console.log("from app: payload", payload);
      digitalSignatureService
        .sendNewDocumentToSing(data.admin_company_id, data.payload)
        .then(() => {
          let company_id = data.admin_company_id;
          let fields = data.fields;
          let sign_status__range = "2,3";
          let limit = "10";

          digitalSignatureService
            .document_digital_signature_for_new_request(
              company_id,
              fields,
              sign_status__range,
              limit
            )
            .then((data) => {
              let dataPrevius = data.previous;
              commit(types.SET_PREV_PAGE, dataPrevius);
              commit(types.SET_NEXT_PAGE, data.next);
              commit(types.SET_TOTAL_DATA, data.count);
              commit(types.FETCH_DIGITAL_SIGNATURE_DATA, data);
              resolve(true);
              commit(types.SET_DATA_CHECK, true);
              commit(types.SET_FLAG, true);
              commit(types.SET_GLOBAL_LOADER, false);

              if (data.count == 0) {
                commit(types.SET_FLAG, false);
              }
            })
            .then(() => {
              // commit(types.SET_DATA_CHECK, true)
              commit(types.LOADER_DOCUMENTS_OFF);
              commit(types.FLAG_DELETE_DIGITAL_SIGNATURE_OFF);
            })
            .catch((error) => {
              reject(error);
              commit(types.FETCH_DIGITAL_SIGNATURE_FAILURE, { error });
              commit(types.SET_DATA_CHECK, false);
              commit(types.SET_FLAG, false);
            });
        });
    });
  },
  sendDocumentFromAppToSign({ commit }, data) {

    commit(types.LOADER_DOCUMENTS_ON);
    commit(types.CLEAN_FLAG_DRAFT_DATA);

    commit(types.SEND_DOCUMENT_TO_SING_FROM_APP, data.payload);

    return new Promise((resolve, reject) => {
      // let admin_company_id = data.admin_company_id;
      // let payload = data.payload;
      // console.log("from app: id admin company id", admin_company_id);
      // console.log("from app: payload", payload);
      digitalSignatureService
        .sendNewDocumentToSing(data.admin_company_id, data.payload)
        .then(() => {
          let company_id = data.admin_company_id;
          let fields = data.fields;
          let sign_status__range = "2,3";
          let limit = "10";

          digitalSignatureService
            .document_digital_signature_for_new_request(
              company_id,
              fields,
              sign_status__range,
              limit
            )
            .then((data) => {
              let dataPrevius = data.previous;
              commit(types.SET_PREV_PAGE, dataPrevius);
              commit(types.SET_NEXT_PAGE, data.next);
              commit(types.SET_TOTAL_DATA, data.count);
              commit(types.FETCH_DIGITAL_SIGNATURE_DATA, data);
              resolve(true);
              commit(types.SET_DATA_CHECK, true);
              commit(types.SET_FLAG, true);
              commit(types.SET_GLOBAL_LOADER, false);

              if (data.count == 0) {
                commit(types.SET_FLAG, false);
              }
            })
            .then(() => {
              // commit(types.SET_DATA_CHECK, true)
              commit(types.LOADER_DOCUMENTS_OFF);
              commit(types.FLAG_DELETE_DIGITAL_SIGNATURE_OFF);
            })
            .catch((error) => {
              reject(error);
              commit(types.FETCH_DIGITAL_SIGNATURE_FAILURE, { error });
              commit(types.SET_DATA_CHECK, false);
              commit(types.SET_FLAG, false);
            });
        });

      // setTimeout(() => {
      //   commit(types.LOADER_DOCUMENTS_OFF);
      // }, 1000);
    });
  },

  sendDocumentFromLocal({ commit }, data) {
    commit(types.LOADER_DOCUMENTS_ON);
    commit(types.SEND_DOCUMENT_TO_SING, data);
    commit(types.CLEAN_FLAG_DRAFT_DATA);


    digitalSignatureService.sendNewDocumentToSing(
      data.admin_company_id,
      data.payload
    );
  },

  sendDocumentFromApp({ commit }, { payload, admin_company_id }) {
    commit(types.LOADER_DOCUMENTS_ON);
    commit(types.CLEAN_FLAG_DRAFT_DATA);

    commit(types.SEND_DOCUMENT_TO_SING_FROM_APP, payload);

    digitalSignatureService.sendNewDocumentToSing(admin_company_id, payload);
    // setTimeout(() => {
    //   commit(types.LOADER_DOCUMENTS_OFF);
    // }, 1000);
  },

  //set flag data from draft
  setDataFromDraft({ commit }) {
    commit(types.SET_FLAG_DRAFT_DATA);
  },

  SetSearchComplete({ commit }) {
    commit(types.SET_SEARCH_FINALIZADOS);
  },

  SetSearchPending({ commit }) {
    commit(types.SET_SEARCH_PENDING);
  },

  SetSearchAll({ commit }) {
    commit(types.SET_SEARCH_ALL);
  },

  cleancounterStepDigitalOnBoarding({ commit }, payload) {
    commit(types.CLEAN_COUNTER__STEP__DIGITAL_ONBOARDING, payload);
  },

  counterStepDigitalOnBoarding({ commit }, payload) {
    commit(types.COUNTER__STEP__DIGITAL_ONBOARDING, payload);
  },
};

// banderas para setear los tipos de busqueda que vienne del pre step
