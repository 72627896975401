<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__confirm__document"
    ref="modal__confirm__document"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="modal__confirm">
      <div class="text-filter-light title_modal_confirm">
        ¿Deseas enviar
        <span class="text-filter">
          {{ name_document }}
        </span>
        para
        <span class="text-filter"> {{ totalSigners }} firmantes? </span>
      </div>

      <div class="text-container text_modal_user_title">
        El documento será recibido para ser firmado por:
      </div>
      <div
        class="container_signer_modal text-container"
        v-for="(signer, index) in signers"
        :key="index"
      >
        <div class="">
          {{ signer.name }}
        </div>
        <div class="">
          {{ signer.rut }}
        </div>
      </div>
    </div>

    <div class="footer_modal__confirm">
      <div disabled="disabled" class="secondary_button" @click="cancelModal()">
        <span> Cancelar</span>
      </div>
      <div class="btn-vue-primary fix-btn-mac" @click="ConfirmSend()">
        <span> Aceptar </span>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/components/modal/assets/cancel.svg";
import { mapGetters } from "vuex";
export default {
  props: [
    "signers",
    "totalSigners",
    "name_document",
    "id_document",
    "isFile",
    "File",
    "Tags",
    "sign_type",
    "size",
  ],
  components: {
    CancelIcon,
  },

  data: () => {
    return {
      dataFromLocal: null,
      dataFromApp: null,
      nameDocument: "",
    };
  },
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__confirm__document");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__confirm__document");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    ConfirmSend() {
      let sign_payload = JSON.stringify(this.signers);
      let document_id = this.id_document;

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let sign_type = this.sign_type;
      let tags = this.Tags;

      let payload;

      let sign_status__range = "2,3";
      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      if (this.isFile) {
        let payload = new FormData();
        let file = this.File;
        payload.append("file", file);
        payload.append("sign_type", sign_type);
        if (tags != null) {
          payload.append("tags", tags);
        }
        payload.append("sign_payload", sign_payload);

        let data = {
          admin_company_id,
          payload,
          sign_status__range,
          fields,
        };

        this.$store.dispatch(
          "DigitalSignature/sendDocumentFromLocalToSign",
          data
        );
        let tagsList = false;
        let tagForReloadRequest = [];
        let tagsForfront = [];
        this.$store.dispatch("DigitalSignature/setTags", {
          tagsForfront,
          tagForReloadRequest,
          tagsList,
        });
        this.$store.dispatch("DigitalSignature/cleanDocumentName");

        setTimeout(() => {
          this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);

          this.$store.dispatch("DigitalSignature/loadDocumentOff");
        }, 6000);

        this.$router.push({ name: "DigitalSignature" });
        // this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);
      } else {
        if (tags != null) {
          payload = {
            document_id,
            sign_type,
            tags,
            sign_payload,
          };
        } else {
          payload = {
            document_id,
            sign_type,
            sign_payload,
          };
        }

        let data = {
          payload,
          admin_company_id,
          sign_status__range,
          fields,
        };

        this.$store.dispatch("DigitalSignature/cleanDocumentName");

        let tagsList = false;
        let tagForReloadRequest = [];
        let tagsForfront = [];
        this.$store.dispatch("DigitalSignature/setTags", {
          tagsForfront,
          tagForReloadRequest,
          tagsList,
        });

        this.$store.dispatch(
          "DigitalSignature/sendDocumentFromAppToSign",
          data
        );
        setTimeout(() => {
          this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
          this.$store.dispatch("DigitalSignature/loadDocumentOff");
        }, 6000);
        this.$router.push({ name: "DigitalSignature" });
        // this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../DigitalSignature.scss";
</style>
