<template>
  <div class="center-loader" v-if="loading">
    <loaderGeneric />
  </div>
  <div class="container-slots container-base" v-else>
    <div class="container-base-title">
      <div class="title-container">
        <span class="icon__breadcrumb">
          <router-link :to="{ name: 'ListEmployeesV2' }">
            {{
              getCurrentEmployeeSelectedFetch.isActiveMember != null
                ? getCurrentEmployeeSelectedFetch.isActiveMember
                : "Mi Perfil"
            }}
          </router-link>
          <RightArrow />
        </span>
        Editar
      </div>
      <div class="">
        <!-- -->
      </div>
    </div>
    <div class="container_mayor_edit_employee">
      <Transition
        class="container_data_general"
        v-if="!this.personalDataActiveOption"
        name="fade"
      >
        <h2
          class="titles titles_edit_employee"
          @click="personalDataContainerChange()"
        >
          Datos personales
          <span
            v-bind:class="
              this.personalDataActiveOption
                ? 'down_options_edit_icon'
                : 'up_options_edit_icon'
            "
          >
            <DownArrowIcon
          /></span>
        </h2>
      </Transition>
      <Transition class="" v-else name="fade">
        <div class="container_general_edit_employee">
          <h2
            class="titles titles_edit_employee"
            @click="personalDataContainerChange()"
          >
            Datos personales
            <span
              v-bind:class="
                this.personalDataActiveOption
                  ? 'down_options_edit_icon'
                  : 'up_options_edit_icon'
              "
            >
              <DownArrowIcon
            /></span>
          </h2>
          <div class="container_edit_employee">
            <div class="left-side-edit">
              <div class="field_edit_employee">
                <div class="label-primary">
                  Primer Nombre<span class="error__required">*</span>
                </div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    :class="first_name_required ? 'error_field_required' : ''"
                    type="text"
                    name="name"
                    v-model="user_profile.first_name"
                    @input="handleDataChange($event, 'first_name')"
                    @change="setValidatorClear('first_name')"
                  />
                </div>
              </div>

              <div class="field_edit_employee">
                <div class="label-primary">
                  Apellido Paterno<span class="error__required">*</span>
                </div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    :class="!surname_required ? '' : 'error_field_required'"
                    type="text"
                    v-model="user_profile.surname"
                    @input="handleDataChange($event, 'surname')"
                    @change="setValidatorClear('surname')"
                  />
                </div>
              </div>

              <div class="field_edit_employee">
                <div class="label-primary">
                  RUT <span class="error__required">*</span>
                </div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    :class="!nin_required ? '' : 'error_field_required'"
                    type="text"
                    name="user_profile.nin"
                    v-model="user_profile.nin"
                    @input="handleDataChange($event, 'nin')"
                    @change="setValidatorClear('nin')"
                    @keyup="formatRUT(user_profile)"
                  />
                </div>
              </div>

              <div class="field_edit_employee">
                <div class="label-primary">Fecha de nacimiento</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="date"
                    name="email"
                    v-model="user_profile.birth_date"
                    @input="handleDataChange($event, 'birth_date')"
                  />
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Estado civil</div>
                <div class="value-primary fix__empty__field custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.marital_status"
                  >
                    <option
                      v-for="(option, index) in marital_status"
                      :key="index"
                      :value="option"
                    >
                      {{ index }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        user_profile.marital_status != null
                          ? user_profile.marital_status
                          : "Ingrese Estado civil"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>

              <div class="field_edit_employee">
                <div class="label-primary">Telefono</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    name="email"
                    v-model="user_profile.phone"
                    @input="handleDataChange($event, 'phone')"
                  />
                </div>
              </div>
            </div>

            <div class="right-side-edit">
              <div class="field_edit_employee">
                <div class="label-primary">Segundo Nombre</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    name="email"
                    v-model="user_profile.middle_name"
                    @input="handleDataChange($event, 'middle_name')"
                  />
                </div>
              </div>

              <div class="field_edit_employee">
                <div class="label-primary">Apellido Materno</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    name="email"
                    v-model="user_profile.second_surname"
                    @input="handleDataChange($event, 'second_surname')"
                  />
                </div>
              </div>

              <div class="field_edit_employee option__create">
                <div class="label-primary">Nacionalidad</div>
                <div class="value-primary fix__empty__field">
                  <select
                    class="input-form input_edit_employee"
                    type="text"
                    v-model="nationality_state.code"
                    @change="setNationality()"
                  >
                    <option selected hidden disabled>
                      {{
                        getCurrentEmployeeSelectedFetch.nationality != null
                          ? getCurrentEmployeeSelectedFetch.nationality
                          : "Ingrese Nacionalidad"
                      }}
                    </option>
                    <option
                      v-for="(country, index2) in country_list"
                      :key="index2"
                      :value="country.code"
                    >
                      {{ country.demonym }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="field_edit_employee option__create">
                <div class="label-primary">Región</div>
                <div class="value-primary fix__empty__field">
                  <div v-if="getCurrentEmployeeSelectedFetch.state">
                    <div class="custom-select">
                      <select
                        class="input-form"
                        v-model="country_name"
                        @change="setState($event)"
                      >
                        <option selected hidden disabled>
                          {{
                            this.getCurrentEmployeeSelectedFetch.state != null
                              ? this.getCurrentEmployeeSelectedFetch.state
                              : "Ingrese Nacionalidad"
                          }}
                        </option>
                        <option
                          v-for="(state, index2) in state_list"
                          :key="index2"
                          :value="state.name"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                      <span class="custom-arrow-b"></span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="custom-select">
                      <select
                        class="input-form"
                        v-model="user_profile.state_id"
                        @change="setState($event)"
                      >
                        <option selected hidden disabled>
                          {{
                            this.getCurrentEmployeeSelectedFetch.state != null
                              ? this.getCurrentEmployeeSelectedFetch.state
                              : "Ingrese Nacionalidad"
                          }}
                        </option>
                        <option value="" selected="selected">
                          Selecciona una Region
                        </option>
                        <option
                          v-for="(state, index2) in state_list"
                          :key="index2"
                          :value="state.name"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                      <span class="custom-arrow-b"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Comuna</div>
                <div class="value-primary fix__empty__field">
                  <div v-if="getCurrentEmployeeSelectedFetch.city">
                    <div class="custom-select">
                      <select
                        class="input-form"
                        v-model="country_city"
                        @change="setCity($event)"
                      >
                        <option selected hidden disabled>
                          {{
                            getCurrentEmployeeSelectedFetch.city != null
                              ? getCurrentEmployeeSelectedFetch.city
                              : "Ingrese Nacionalidad"
                          }}
                        </option>
                        <option
                          v-for="(city, index2) in city_list"
                          :key="index2"
                          :value="city.name"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                      <span class="custom-arrow-b"></span>
                    </div>
                  </div>
                  <div v-else>
                    <select
                      class="input-form"
                      v-model="user_profile.city_id"
                      @change="setCity($event)"
                    >
                      <option selected hidden disabled>
                        {{
                          this.getCurrentEmployeeSelectedFetch.city != null
                            ? this.getCurrentEmployeeSelectedFetch.city
                            : "Ingrese Nacionalidad"
                        }}
                      </option>
                      <option value="" selected="selected">
                        Selecciona una Comuna
                      </option>
                      <option
                        v-for="(city, index2) in city_list"
                        :key="index2"
                        :value="city.name"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Dirección particular</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    name="email"
                    v-model="user_profile.address"
                    @input="handleDataChange($event, 'address')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        class="container_data_general"
        v-if="!this.companyDataActiveOption"
        name="fade"
      >
        <h2
          class="titles titles_edit_employee"
          @click="companyDataContainerChange()"
        >
          Datos empresa
          <span
            v-bind:class="
              this.companyDataActiveOption
                ? 'down_options_edit_icon'
                : 'up_options_edit_icon'
            "
          >
            <DownArrowIcon
          /></span>
        </h2>
      </Transition>
      <Transition class="" v-else name="fade">
        <div class="container_general_edit_employee">
          <h2
            class="titles titles_edit_employee"
            @click="companyDataContainerChange()"
          >
            Datos empresa
            <span
              v-bind:class="
                this.companyDataActiveOption
                  ? 'down_options_edit_icon'
                  : 'up_options_edit_icon'
              "
            >
              <DownArrowIcon
            /></span>
          </h2>
          <div class="container_edit_employee">
            <div class="left-side-edit">
              <div class="field_edit_employee">
                <div class="label-primary">Fecha de ingreso a la empresa</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="date"
                    name="email"
                    v-model="user_profile.company_ingress_date"
                    @input="handleDataChange($event, 'company_ingress_date')"
                  />
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Jornada de trabajo</div>
                <div class="custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.working_hours"
                  >
                    <option
                      v-for="(workingHours, index) in working_hours"
                      :key="index"
                      :value="workingHours.value"
                      @change="handleDataChange($event, 'working_hours')"
                    >
                      {{ workingHours.display_name }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        getCurrentEmployeeSelectedFetch.working_hours != null
                          ? getCurrentEmployeeSelectedFetch.working_hours
                          : "Jornada de trabajo"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Profesión u oficio</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    name=""
                    v-model="user_profile.occupation"
                  />
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Formato de trabajo</div>
                <div class="custom-select">
                  <select class="input-form" v-model="user_profile.job_type">
                    <option
                      v-for="(jobType, index) in job_type"
                      :key="index"
                      :value="jobType.value"
                      @change="handleDataChange($event, 'job_type')"
                    >
                      {{ jobType.display_name }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        getCurrentEmployeeSelectedFetch.job_type != null
                          ? getCurrentEmployeeSelectedFetch.job_type
                          : "Formato de trabajo"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">
                  Responsabilidades asociadas al cargo
                </div>
                <div class="value-primary fix__empty__field">
                  <textarea
                    class="text_area_edit_employee input-form"
                    type="text"
                    cols="20"
                    rows="10"
                    maxlength="332"
                    v-model="user_profile.position_responsabilities"
                    @input="
                      handleDataChange($event, 'position_responsabilities')
                    "
                  />
                </div>
              </div>

              <div
                class="field_edit_employee"
                v-if="
                  getCurrentEmployeeSelectedFetch.isActiveMember == 'Usuario'
                "
              >
                <div class="label-primary">Rol en la app</div>
                <div class="custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.group"
                    @change="setGroup($event)"
                  >
                    <option
                      v-for="(group, index2) in group_list"
                      :key="index2"
                      :value="group.group_name"
                    >
                      {{ group.fantasy }}
                      <!-- {{ group.group_name }} -->
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
            </div>
            <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

            <div class="right-side-edit">
              <div class="field_edit_employee">
                <div class="label-primary">Tipo de contrato</div>
                <div class="custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.contract_type"
                  >
                    <option
                      v-for="(contractType, index) in contract_type"
                      :key="index"
                      :value="contractType.value"
                      @change="handleDataChange($event, 'contract_type')"
                    >
                      {{ contractType.display_name }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        listOptionUserProfile.contract_type.display_name != null
                          ? listOptionUserProfile.contract_type.display_name
                          : "Ingrese Tipo de contrato"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>

              <div class="field_edit_employee">
                <div class="label-primary">Remuneración Bruta</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    v-model="user_profile.salary"
                    maxlength="11"
                    @input="handleDataChange($event, 'salary')"
                    @keyup="formatCurrency(user_profile)"
                  />
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Nombre del cargo en la empresa</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    name="email"
                    v-model="user_profile.position"
                    @input="handleDataChange($event, 'position')"
                  />
                </div>
              </div>

              <div class="field_edit_employee">
                <div class="label-primary">Previsión de salud</div>
                <div class="custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.health_insurance_id"
                  >
                    <option
                      v-for="(healthInsurance, index) in health_insurance"
                      :key="index"
                      :value="healthInsurance.id"
                      @change="handleDataChange($event, 'health_insurance_id')"
                    >
                      {{ healthInsurance.name }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        getCurrentEmployeeSelectedFetch.health_insurance != null
                          ? getCurrentEmployeeSelectedFetch.health_insurance
                          : "Previsión de salud"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">AFP</div>
                <div class="custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.social_security_id"
                  >
                    <option
                      v-for="(socialSecurity, index) in social_security"
                      :key="index"
                      :value="socialSecurity.id"
                      @change="handleDataChange($event, 'social_security_id')"
                    >
                      {{ socialSecurity.name }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        getCurrentEmployeeSelectedFetch.social_security != null
                          ? getCurrentEmployeeSelectedFetch.social_security
                          : "Ingrese AFP"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Representante legal</div>
                <div class="custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.is_legal_representative"
                  >
                    <option
                      v-for="(option, index) in is_legal_representative"
                      :key="index"
                      :value="option"
                    >
                      {{ index }}
                    </option>
                    <option selected hidden disabled>
                      {{ user_profile.is_legal_representative }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        class="container_data_general"
        v-if="!this.bankDataActiveOption"
        name="fade"
      >
        <h2
          class="titles titles_edit_employee"
          @click="bankDataContainerChange()"
        >
          Datos bancarios
          <span
            v-bind:class="
              this.bankDataActiveOption
                ? 'down_options_edit_icon'
                : 'up_options_edit_icon'
            "
          >
            <DownArrowIcon
          /></span>
        </h2>
      </Transition>
      <Transition class="" v-else name="fade">
        <div class="container_general_edit_employee">
          <h2
            class="titles titles_edit_employee"
            @click="bankDataContainerChange()"
          >
            Datos bancarios
            <span
              v-bind:class="
                this.bankDataActiveOption
                  ? 'down_options_edit_icon'
                  : 'up_options_edit_icon'
              "
            >
              <DownArrowIcon
            /></span>
          </h2>
          <div class="container_edit_employee">
            <div class="left-side-edit">
              <div class="field_edit_employee">
                <div class="label-primary">Banco</div>
                <div class="custom-select">
                  <select class="input-form" v-model="user_profile.bank_id">
                    <option
                      v-for="(bank, index) in bank"
                      :key="index"
                      :value="bank.id"
                      @change="handleDataChange($event, 'bank_id')"
                    >
                      {{ bank.name }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        getCurrentEmployeeSelectedFetch.bank != null
                          ? getCurrentEmployeeSelectedFetch.bank
                          : "Ingrese Banco"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
              <div class="field_edit_employee">
                <div class="label-primary">Número de cuenta</div>
                <div class="value-primary fix__empty__field">
                  <input
                    class="input-form input_edit_employee"
                    type="text"
                    name="email"
                    v-model="
                      getCurrentEmployeeSelectedFetch.bank_account_number
                    "
                    @input="handleDataChange($event, 'bank_account_number')"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

            <div class="right-side-edit">
              <div class="field_edit_employee">
                <div class="label-primary">Tipo de cuenta</div>
                <div class="custom-select">
                  <select
                    class="input-form"
                    v-model="user_profile.bank_account_type"
                  >
                    <option
                      v-for="(bankType, index) in bank_account_type"
                      :key="index"
                      :value="bankType.value"
                      @change="handleDataChange($event, 'bank_account_type')"
                    >
                      {{ bankType.display_name }}
                    </option>
                    <option selected hidden disabled>
                      {{
                        getCurrentEmployeeSelectedFetch.bank_account_type !=
                        null
                          ? getCurrentEmployeeSelectedFetch.bank_account_type
                          : "Ingrese Tipo de cuenta"
                      }}
                    </option>
                  </select>
                  <span class="custom-arrow-b"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div class="footer__profile__employees save_edit_button">
      <div class="edit__user" @click="editEmployee()">Guardar cambios</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RightArrow from "@/assets/iconos_container/right.svg";
import DownArrowIcon from "../assets/downArrowIcon.svg";
import formatRut from "../../../utils/scriptRUT";
import employeeServiceV2 from "../services/employeService";
import loaderGeneric from "../../../components/loaderGeneric/LoadingMA.vue";

export default {
  components: {
    RightArrow,
    DownArrowIcon,
    loaderGeneric,
  },
  data: () => {
    return {
      personalDataActiveOption: true,
      companyDataActiveOption: false,
      bankDataActiveOption: false,
      listCountryEmployee: null,
      user_profile: {
        first_name: "",
        middle_name: "",
        surname: "",
        second_surname: "",
        nin: "",
        occupation: "",
        marital_status: "",
        address: "",
        nationality_id: "",
        state_id: "",
        city_id: "",
        birth_date: "",
        company_ingress_date: "",
        //rol de la app
        group: "",
        position: "",
        is_legal_representative: "",
        contract_type: "",
        salary: "",
        // Formato de trabajo
        job_type: "",
        //Jornada de trabajo
        working_hours: "",
        bank_id: "",
        bank_account_type: "",
        bank_account_number: "",
        //Prevision
        health_insurance_id: "",
        //AFP
        social_security_id: "",
        phone: "",
      },
      bank: [],
      social_security: [],
      health_insurance: [],
      user_state: "",
      user_city: "",
      city_list: [],
      state_list: [],
      country_list: [],
      nationality_state: "",
      group_list: [],
      is_legal_representative: {
        Si: true,
        No: false,
      },
      loading: true,
      marital_status: {
        "Soltero/a": 0,
        "Casado/a": 1,
        "Conviviente civil": 2,
        "Divorciado/a": 3,
        "Viudo/a": 4,
      },

      addressEmpty: "",
      country_name: "",
      country_city: "",
      group_name: "",
      company_id_regionV2: "",
      salary_display: "",
      //validators
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      emailValidation: false,
      first_name_required: false,
      surname_required: false,
      nin_required: false,
      email_required: false,
      nationality_required: false,
      displayErrorMsg: "",
      errorColorEmail: false,
      errorfieldEmail: false,
      errorMsgEmailCode200: "Nuevo usuario",
      errorMsgEmailCode202: "Usuario existente en otra organización",
      errorMsgEmailCode403:
        "Este usuario no puede ser creado en esta organización",
    };
  },
  mounted() {
    this.legalRepresentative(
      this.getCurrentEmployeeSelectedFetch.is_legal_representative
    );
  },
  created() {
    this.user_profile = {
      first_name: this.getCurrentEmployeeSelected.first_name,
      middle_name: this.getCurrentEmployeeSelectedFetch.middle_name,
      surname: this.getCurrentEmployeeSelected.surname,
      second_surname: this.getCurrentEmployeeSelectedFetch.second_surname,
      nin: this.getCurrentEmployeeSelected.nin,
      marital_status: this.getCurrentEmployeeSelectedFetch.marital_status,
      address: this.getCurrentEmployeeSelectedFetch.address,
      nationality_id: this.getCurrentEmployeeSelectedFetch.nationality.code,
      state_id: this.getCurrentEmployeeSelectedFetch.state,
      city_id: this.getCurrentEmployeeSelectedFetch.city,
      birth_date: this.getCurrentEmployeeSelectedFetch.birth_date,
      // responsabilidades asociadas al cargo
      position: this.getCurrentEmployeeSelectedFetch.position,
      position_responsabilities: this.getCurrentEmployeeSelectedFetch
        .position_responsabilities,
      company_ingress_date: this.getCurrentEmployeeSelectedFetch
        .company_ingress_date,
      group: this.getCurrentEmployeeSelectedFetch.group,
      is_legal_representative: this.getCurrentEmployeeSelectedFetch
        .is_legal_representative,

      contract_type: this.getCurrentEmployeeSelectedFetch.contract_type,
      job_type: this.getCurrentEmployeeSelectedFetch.job_type,
      bank_account_type: this.getCurrentEmployeeSelectedFetch.bank_account_type,
      working_hours: this.getCurrentEmployeeSelectedFetch.working_hours,
      phone: this.getCurrentEmployeeSelectedFetch.phone,

      bank_account_number: this.getCurrentEmployeeSelectedFetch
        .bank_account_number,
      salary: this.getCurrentEmployeeSelectedFetch.salary,
      // salary_display: this.getCurrentEmployeeSelectedFetch.salary_display,
      occupation: this.getCurrentEmployeeSelectedFetch.occupation,
      bank_id: this.getCurrentEmployeeSelectedFetch.bank,
      health_insurance_id: this.getCurrentEmployeeSelectedFetch
        .health_insurance,
      social_security_id: this.getCurrentEmployeeSelectedFetch.social_security,
    };

    // set the list of values for the fields banco,AFP,Prevision de salud
    this.bank = this.listBanks;
    this.social_security = this.listSocialSecurity;
    this.health_insurance = this.listHealthInsurance;

    this.contract_type = this.listOptionUserProfile.contract_type;
    this.job_type = this.listOptionUserProfile.job_type;
    this.bank_account_type = this.listOptionUserProfile.bank_account_type;
    this.working_hours = this.listOptionUserProfile.working_hours;

    this.country_list = this.nationalityEmployee;
    this.nationality_state =
      this.getCurrentEmployeeSelectedFetch.nationality != null
        ? this.getCurrentEmployeeSelectedFetch.nationality
        : "";
    let company_id_region = this.getCurrentEmployeeSelected.company.country.id;
    this.$store.dispatch("EmployeesV2/get_list_region", company_id_region);

    setTimeout(() => {
      this.state_list = this.regionListEmployee;
    }, 1000);
    this.company_id_regionV2 = this.getCurrentEmployeeSelected.company.country.id;
    this.groupList();

    setTimeout(() => {
      this.setStateBase(this.getCurrentEmployeeSelectedFetch.state);
    }, 1500);

    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },

  computed: {
    ...mapGetters("EmployeesV2", {
      getCurrentEmployeeSelected: "getCurrentEmployeeSelected",
      getCurrentEmployeeSelectedFetch: "getCurrentEmployeeSelectedFetch",
      nationalityEmployee: "nationalityEmployee",
      regionListEmployee: "regionListEmployee",
      cityListEmployee: "cityListEmployee",
      listBanks: "listBanks",
      listHealthInsurance: "listHealthInsurance",
      listSocialSecurity: "listSocialSecurity",
      listOptionUserProfile: "listOptionUserProfile",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
      user: "user_profile",
    }),
    ...mapGetters("Profile", {
      getEditProfile: "getEditProfile",
    }),
    ...mapState({
      company: (state) => state.Login.company,
      staff: (state) => state.Login.is_staff,
    }),
  },
  methods: {
    legalRepresentative(name) {
      this.legal_representative = name;
    },
    personalDataContainerChange() {
      if (this.personalDataActiveOption) {
        this.personalDataActiveOption = false;
      } else {
        this.personalDataActiveOption = true;
      }
    },
    companyDataContainerChange() {
      if (this.companyDataActiveOption) {
        this.companyDataActiveOption = false;
      } else {
        this.companyDataActiveOption = true;
      }
    },
    bankDataContainerChange() {
      if (this.bankDataActiveOption) {
        this.bankDataActiveOption = false;
      } else {
        this.bankDataActiveOption = true;
      }
    },
    city(name) {
      this.country_city = name;
    },
    setNationality() {
      let codeNationality = this.nationality_state.code;

      this.country_list.filter((el) => {
        if (el.code == codeNationality) {
          this.user_profile.nationality_id = el.id;
        }
      });
    },
    setState(evt) {
      let searchSatate = this.state_list.filter(
        (state) => state.name === evt.target.value
      );

      this.user_profile.state_id = searchSatate[0].id;
      this.country_name = searchSatate[0].name;

      let payload = {
        countryID: this.company_id_regionV2,
        stateID: this.user_profile.state_id,
      };

      this.$store.dispatch("EmployeesV2/get_list_city", payload);

      this.city_list = this.cityListEmployee;
    },
    setStateBase(data) {
      if (data != null) {
        let searchSatate = this.state_list.filter(
          (state) => state.name === data
        );

        this.user_profile.state_id = searchSatate[0].id;
        this.country_name = searchSatate[0].name;

        let payload = {
          countryID: this.company_id_regionV2,
          stateID: this.user_profile.state_id,
        };

        this.$store.dispatch("EmployeesV2/get_list_city", payload);

        this.city_list = this.cityListEmployee;

        setTimeout(() => {
          this.setCityBase(this.getCurrentEmployeeSelectedFetch.city);
        }, 1500);
      }
    },
    setCity(evt) {
      let name_city = evt.target.value;
      this.city(name_city);
      this.country_city = name_city;

      let searchCity = this.city_list.filter((city) => city.name === name_city);

      this.user_profile.city_id = searchCity[0].id;
    },
    setCityBase(data) {
      let name_city = data;
      this.city(name_city);
      this.country_city = name_city;

      let searchCity = this.city_list.filter((city) => city.name === name_city);
      setTimeout(() => {
        this.user_profile.city_id = searchCity[0].id;
      }, 2000);
    },
    handleDataChange(evt, name) {
      if (evt.target.value === "") {
        this.user_profile[name] = "";
      } else {
        this.user_profile[name] = evt.target.value;
      }
    },
    validateRutInnitialEmployee(rut) {
      rut = rut.replace(/\./g, "");
      rut = rut.trim();
      if (rut.length >= 8) {
        let rutFormatted = formatRut.format(rut);
        let rutValid = formatRut.validaRut(rutFormatted.trim());
        rut = rutFormatted;
        this.validatorRutInnitalSigner = rutValid;
        this.rutInnitialValue = rutFormatted;
        if (!this.validatorRutInnitalSigner) {
          // this.errorRutFlag = true;
          // this.errorRut = "rut invalido";

          return true;
        } else {
          // this.errorRutFlag = false;
          // this.errorRut = "";
          return false;
        }
      } else {
        return true;
      }
    },
    setGroup(evt) {
      this.group_list.filter((el) => {
        if (el.group_name === evt.target.value) {
          this.user_profile.group = el.group_name;
        }
      });
    },
    // set(evt) {
    //   this.group_list.filter((el) => {
    //     if (el.group_name === evt.target.value) {
    //       this.user_profile.group = el.group_name;
    //     }
    //   });
    // },
    groupList() {
      if (this.inSwitch) {
        employeeServiceV2
          .employee_groups(this.idInSwitch)
          .then((data) => {
            let dataList = data.results;

            dataList.map((item) => {
              let ejecutivo =
                item.group_name == "Lawyer"
                  ? "Abogado"
                  : item.group_name == "Admin"
                  ? "Administrador"
                  : item.group_name == "Agent"
                  ? "Agente"
                  : item.group_name == "Company-Executive"
                  ? "Ejecutivo"
                  : item.group_name == "Company-Admin"
                  ? "Administrador"
                  : item.group_name == "Company-Employee"
                  ? "Empleado"
                  : item.group_name == "Company-Entel"
                  ? "Usuario Entel"
                  : "";

              item.fantasy = ejecutivo;
            });
            this.group_list = data.results;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let currentUserIdCompany = this.getCurrentEmployeeSelected.company.id;
        employeeServiceV2
          .employee_groups(currentUserIdCompany)
          .then((data) => {
            let dataList = data.results;

            dataList.map((item) => {
              let ejecutivo =
                item.group_name == "Lawyer"
                  ? "Abogado"
                  : item.group_name == "Admin"
                  ? "Administrador"
                  : item.group_name == "Agent"
                  ? "Agente"
                  : item.group_name == "Company-Executive"
                  ? "Ejecutivo"
                  : item.group_name == "Company-Admin"
                  ? "Administrador"
                  : item.group_name == "Company-Employee"
                  ? "Empleado"
                  : item.group_name == "Company-Entel"
                  ? "Usuario Entel"
                  : "";

              item.fantasy = ejecutivo;
            });
            this.group_list = data.results;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    setValidatorClear(el) {
      //set validator for mandatory fields
      let validatorName =
        this.user_profile.first_name.length > 0 ? false : true;

      let validatorSurname =
        this.user_profile.surname.length > 0 ? false : true;

      let validatorNin = this.user_profile.nin.length > 0 ? false : true;

      if (el == "first_name") {
        this.first_name_required = validatorName;
      }
      if (el == "surname") {
        this.surname_required = validatorSurname;
      }
      if (el == "nin") {
        let flagRutValidator = this.validateRutInnitialEmployee(
          this.user_profile.nin
        );

        if (!flagRutValidator) {
          this.nin_required = false;
          validatorNin = false;
        } else {
          // set error in rut validator
          this.nin_required = true;
          validatorNin = true;
        }
      }

      if (el == "all") {
        this.first_name_required = validatorName;
        this.surname_required = validatorSurname;

        let flagRutValidator = this.validateRutInnitialEmployee(
          this.user_profile.nin
        );

        if (!flagRutValidator) {
          this.nin_required = false;
          validatorNin = false;
        } else {
          // set error in rut validator
          this.nin_required = true;
          validatorNin = true;
        }
      }

      // validatorNin return true if rut is invalid

      if (validatorName || validatorSurname || validatorNin) {
        //return error set as true stop increment step
        return true;
      } else {
        // increment step in +1
        return false;
      }
    },
    editEmployee() {
      let validtorFlow = this.setValidatorClear("all");

      if (this.user_profile.position_responsabilities != null) {
        if (this.user_profile.position_responsabilities.length <= 0) {
          this.user_profile.position_responsabilities = null;
        }
      }

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company.id;

      if (!validtorFlow) {
        this.loading = true;
        if (this.user_profile.salary != null) {
          if (this.user_profile.salary.length <= 1) {
            this.user_profile.salary = null;
          } else {
            this.user_profile.salary = this.user_profile.salary.replace(
              /[^0-9]+/g,
              ""
            );
          }
        }
        if (this.inSwitch) {
          let id = this.idInSwitch;
          if (this.getEditProfile) {
            this.editOwnProfile();
          } else {
            employeeServiceV2
              .edit_employee_profile(
                id,
                this.getCurrentEmployeeSelected.id,
                this.user_profile
              )
              .then(() => {
                this.successMessage = "Usuario editado existosamente";
                this.$toasted.success(this.successMessage);
                let offset = 0;
                let company_id = admin_company_id;

                let payload = {
                  id_company: company_id,
                  limit: "10",
                  offset: offset,
                  first_name__icontains: null,
                  id: id,
                };
                this.$store.dispatch(
                  "EmployeesV2/get_list_employee_InSwitchTenancy",
                  payload
                );
                this.$store.dispatch(
                  "EmployeesV2/get_list_members_InSwitchTenancy",
                  payload
                );
                setTimeout(() => {
                  this.loading = false;
                  this.$router.push({ name: "ListEmployeesV2" });
                }, 1000);
              })
              .catch(() => {
                this.loading = false;
              });
          }
        } else if (this.getCurrentEmployeeSelected.company.id) {
           if (this.getEditProfile) {
            this.editOwnProfile();
          } else {
          employeeServiceV2
            .edit_employee_profile(
              this.getCurrentEmployeeSelected.company.id,
              this.getCurrentEmployeeSelected.id,
              this.user_profile
            )
            .then(() => {
              this.successMessage = "Usuario editado existosamente";
              this.$toasted.success(this.successMessage);
              let offset = 0;
              let company_id = admin_company_id;

              let payload = {
                company_id: company_id,
                limit: "10",
                offset: offset,
              };
              this.$store.dispatch("EmployeesV2/get_list_employee", payload);
              this.$store.dispatch(
                "EmployeesV2/get_list_employee_members",
                payload
              );
              setTimeout(() => {
                this.loading = false;
                this.$router.push({ name: "ListEmployeesV2" });
              }, 1000);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
      }
    },
    formatRUT(user_profile) {
      user_profile.nin = user_profile.nin.replace(/\./g, "");
      if (user_profile.nin.length >= 8) {
        let rutformatted = formatRut.format(user_profile.nin);
        user_profile.nin = rutformatted;
      }
    },
    userProfile() {
      console.log("user profile");
      this.$store
        .dispatch("Login/setUserProfile", this.user.company.id)
        .then(() => {
          // console.log("edited");
        })
        .catch(() => {});
    },
    editOwnProfile() {
      if (this.user_profile.position_responsabilities != null) {
        if (this.user_profile.position_responsabilities.length <= 0) {
          this.user_profile.position_responsabilities = null;
        }
      }

      employeeServiceV2
        .edit_own_profile(this.user.company.id, this.user_profile)
        .then(() => {
          this.userProfile();
          this.successMessage = "Sus datos han sido actualizados exitosamente.";
          this.$toasted.success(this.successMessage);
          setTimeout(() => {
            this.$router.push({ name: "user" });
          }, 1000);
        })
        .catch(() => {});
    },
    formatCurrency(user_profile) {
      let currency = user_profile.salary.replace(/\./g, "");

      let cleanSymbol = currency.replace(/[$]/g, "");

      cleanSymbol = cleanSymbol
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      cleanSymbol = cleanSymbol
        .split("")
        .reverse()
        .join("")
        .replace(/^[.]/, "");

      user_profile.salary = `$${cleanSymbol}`;
    },
  },
  watch: {
    cityListEmployee() {
      this.city_list = this.cityListEmployee;
    },
  },
};
</script>

<style lang="scss"></style>
