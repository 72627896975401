<template>
  <div class="container_progress_ciclebar">
    <Progress
      :transitionDuration="5000"
      :radius="60"
      :strokeWidth="80"
      :value="total_value"
      strokeColor="#9c9c9c"
    >
      <div class="content">{{ signers }} / {{ total }}</div>
      <template v-slot:footer> </template>
    </Progress>
  </div>
</template>

<script>
import Progress from "easy-circular-progress";

export default {
  props: ["total", "signers"],

  name: "ProgressCircle",
  components: {
    Progress,
  },

  data: () => {
    return {
      data: "10/10",
    };
  },
  computed: {
    total_value() {
      let totalpercentaje = (this.signers * 100) / this.total;
      return totalpercentaje;
    },
  },
};
</script>

<style>
.vue-circular-progress .percent {
  width: 130px !important;
  height: 130px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.vue-circular-progress .percent div {
  width: 100%;
}

.content {
  color: #9c9c9c;
  font-size: 22px;

  /* line-height: 43px; */
}

.percent {
  border: 1px solid #9c9c9c;
}
.circle__progress {
  opacity: 1 !important;
  stroke-linecap: initial !important;
}
.circle__progress--fill {
  stroke-opacity: 0.3 !important;
  /* fill: #f8f8f8 !important; */
  border: 1px solid #9c9c9c !important;
  opacity: 1 !important;
  stroke: #619aff !important;
  stroke-opacity: 1 !important;
}
.container_progress_ciclebar{
  margin: auto 0;
}
</style>
