<template>
  <div class="main__category__item" @click="loadCategoryScheduling">
    <div class="icon__category" v-if="icon == '1'">
      <Sesionconsultalegal />
    </div>
    <div class="icon__category" v-if="icon == '2'">
      <SchedulingOperation />
    </div>

    <div class="icon__category" v-if="icon == '3'">
      <Scheduling />
    </div>
    <div class="title-card-category">
      {{ title }}
    </div>
  </div>
</template>

<script>
import SchedulingOperation from "../assets/sesionservicio.svg";
import Sesionconsultalegal from "../assets/sesionconsultalegal.svg";
import Scheduling from "../assets/agendamiento.svg";

import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ["title", "icon"],
  components: {
    SchedulingOperation,
    Sesionconsultalegal,
    Scheduling,
  },
  computed: {
    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),
  },

  methods: {
    loadCategoryScheduling() {
      this.$store.dispatch("Schedule/cleanSelecteScheduling");
      const format = "YYYY-MM-DD";
      let schedule_date__gte = moment().format(format);
      let schedule_date__lte = moment().add(14, "days").format(format);
      let schedule_author = null;

      let admin_company_id = this.admin_company_id;
      let limit = 3;

      let params = {
        admin_company_id,
        schedule_date__gte,
        schedule_date__lte,
        schedule_author,
        limit,
      };

      if (this.icon == "1") {
        // consulta legal
        this.$router.push({ name: "legalScheduling" });

        let admin_company_id = this.admin_company_id;

        let type = 0;
        let paramsCategory = {
          admin_company_id,
          type,
        };
        this.$store.dispatch("Schedule/getResumeMeetings", params);

        this.$store.dispatch("Schedule/fetchCategoryItems", paramsCategory);
      } else if (this.icon == "2") {
        // consulta operaciones
        this.$router.push({ name: "operationScheduling" });
        let admin_company_id = this.admin_company_id;
        let type = 1;
        let paramsCategory = {
          admin_company_id,
          type,
        };
        this.$store.dispatch("Schedule/getResumeMeetings", params);

        this.$store.dispatch("Schedule/fetchCategoryItems", paramsCategory);
      } else {
        // my agenda
        let limit = 14;
        let params = {
          admin_company_id,
          schedule_date__gte,
          schedule_date__lte,
          schedule_author,
          limit,
        };

        this.$store.dispatch("Schedule/getallMeetings", params).then(() => {
          this.$router.push({ name: "myScheduling" });
        });
      }
      // desactivamos el loader del dashboard en off para cargar la view correspondiente al modulo
      // this.$store.dispatch("Schedule/loadSchedulingOff");
    },
  },
};
</script>

<style lang="scss">

.main__category__item {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 90%;
    margin: auto;
  }
  cursor: pointer;
  width: 372px;
  height: 309px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  border-radius: 12px;

  &:hover {
    background: #619aff;
  }
}

.icon__category svg {
  margin-bottom: 2rem;
  width: 109px;
  height: 109px;
  fill: var(--icon_color_schedule) !important;
}
.main__category__item {
  background: var(--icon_background) !important;
  border: 1px solid var(--border_schedule_cards) !important;
}

.title-card-category {
  color: var(--text_schedule) !important;
}

.meeting_active {
  height: 100%;
  background: var(--schedule_background) !important;
  border: 1px solid var(--border_schedule_cards) !important;
}
.title__meeting {
  color: var(--text_schedule) !important;
}
.title__meeting {
  > .link_scheduling {
    color: var(--links_schedule) !important;
  }
}
</style>
