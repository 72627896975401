<template>
  <div class="files_assistance">
    <div class="">
      <div class="files_assistance__title title-container">
        <div class="">Adjuntos</div>
        <div
          class="close_assitance_files"
          @click="closeFilterMobileAssistance()"
        >
          <CancelBtn />
        </div>
      </div>
    </div>
    <div class="files_header_container">
      <div
        class="icon_container_assistance_files_principal icon_circle_assistance"
      >
        <FilesSvg />
      </div>
      <div class="files_assistance__subtitle">
        <div class="files_subtitle_text">
          <div class="">N de Archivos</div>

          <div class="tag_assistance_files_container">
            <div class="tag_assistance_files">{{ arrayCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="line-primary"></div>

    <div class="files_assistance__content" v-if="validArrayFiles">
      <div class="" v-if="!validArrayLength">Sin Archivos</div>

      <div v-else class="" v-for="(file, index) in arrayFiles" :key="index">
        <div
          class="files_assistance__content__files"
          @click="downloadFile(file.url)"
        >
          <div class="icon_container_assistance_files">
            <FilesSvg />
          </div>
          <div class="files_assistances_description file__named">
            <div class="container__wrapper__hover__message">
              <div class="tooltip__key__message">{{ file.fullname }}</div>
              <div class="files_name_assistance">
                {{ file.name }}
              </div>
            </div>
            <div class="file_size">{{ file.size }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="files_assistance__content__files" v-else>
      <div class="icon_container_assistance_files">
        <FilesSvg />
      </div>
      <div class="files_assistances_description file__named">
        <!-- <div class="files_name_assistance">
          {{ filenameCut }}.{{ typefileCut }}
        </div> -->
        <div class="container__wrapper__hover__message">
          <div class="tooltip__key__message">{{ file.fullname }}</div>
          <div class="file__title">{{ filenameCut }}.{{ typefileCut }}</div>
        </div>
        <div class="file_size">{{ file.size }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import IconLogoAssistance from "../assets/file-solid.svg";
import FilesSvg from "../assets/filesSvg.svg";
import { mapGetters } from "vuex";
import CancelBtn from "../../../assets/iconos_container/cancel.svg";
// import callSizeFiles from "../Utils/callSizeFiles";

export default {
  components: {
    // IconLogoAssistance,
    CancelBtn,
    FilesSvg,
  },
  data: () => {
    return {
      // personalized chunks of data (SingleAssistanceData) for different components
      SingleAssistanceData: null,
      nameSingleFile: "",
      urlSingleFile: "",
      validArrayFiles: true,
      arrayFiles: null,
      arrayCount: "",
      NameSingleFileView: "",
      filenameCut: "",
      typefileCut: "",
      validArrayLength: false,
      sizeFileFront: "",
    };
  },
  created() {
    setTimeout(() => {
      this.SingleAssistanceData = this.currentSingleAssistance;
    }, 1000);

    if (!this.currentSingleAssistance.isArrayFiles) {
      this.cutNameOldFile();
    } else {
      this.cutNameNewFiles();
    }
  },

  computed: {
    ...mapGetters("Assistance", {
      currentSingleAssistance: "currentSingleAssistance",
      flagFilterMobileAssistance: "flagFilterMobileAssistance",
    }),
  },
  mounted() {
    // console.log("url", `${this.currentSingleAssistance.files[5].url}`);
    // callSizeFiles.sizeFiles(this.currentSingleAssistance.files[5].url);
  },
  methods: {
    downloadFile(url) {
      const urlDonwload = url;
      window.location.href = urlDonwload;
    },
    closeFilterMobileAssistance() {
      this.$store.dispatch("Assistance/cleanFilterInMobileAssistance");
    },
    cutNameOldFile() {
      /*--------------------------------
       @Input: File name
       Description: this function evaluate the length of the name of the file and do a format for them
       @Output: File name formatted
      //--------------------------------*/
      this.validArrayFiles = false;
      this.nameSingleFile = this.currentSingleAssistance.files.filename;

      // We separete the file name and the type of file
      //( "filesname" . "typefile"  )
      this.NameSingleFileView = this.nameSingleFile.split(".");

      // We save the separate the values and we save in 2 variables

      // ("typefile")
      this.typefileCut = this.NameSingleFileView[1];
      // ("filename")
      this.filenameCut = this.NameSingleFileView[0];

      // we evaluate if the string is "too long" , in that case we "cut" the string and we add ".."
      if (this.nameSingleFile.length > 15) {
        this.filenameCut = `${this.filenameCut.slice(0, 17)}..`;
      }
      this.urlSingleFile = this.currentSingleAssistance.files.url;

      // front value for html ("Numero de archivos: X")
      this.arrayCount = "1";
    },
    cutNameNewFiles() {
      /*--------------------------------
       @Input: File name
       Description: this function evaluate the length of the name of the file and do a format for them
       @Output: File name formatted
      //--------------------------------*/
      let arrayFilesTemp = this.currentSingleAssistance.files;
      this.validArrayLength =
        arrayFilesTemp.length > 0 ? arrayFilesTemp : false;

      this.arrayFiles = arrayFilesTemp.map((el) => {
        // We separete the file name and the type of file
        //( "filesname" . "typefile"  )
        let arrayNameTemp = el.filename.split(".");
        let fullname = el.filename;
        // We save the separate the values and we save in 2 variables

        // ("typefile")
        let typefileCut = arrayNameTemp[1];
        // ("filename")
        let filenameCut = arrayNameTemp[0];
        let resultName = "";

        // we evaluate if the string is "too long" , in that case we "cut" the string and we add ".."
        if (filenameCut.length > 15) {
          resultName = `${filenameCut.slice(0, 17)}..`;
        } else {
          resultName = filenameCut;
        }
        let fileSize = null;

        if (el.size > 1000000) {
          let tamanioEnMbTemp = Math.ceil(el.size / 1000000);
          fileSize = `${tamanioEnMbTemp}MB`;
        } else {
          let tamanioEnKbTemp = Math.ceil(el.size / 1024);
          fileSize = `${tamanioEnKbTemp}KB`;
        }

        let payload = {
          name: `${resultName}.${typefileCut}`,
          fullname: fullname,
          url: el.url,
          size: fileSize,
        };

        return payload;
      });
      // front value for html ("Numero de archivos: X")
      this.arrayCount = this.currentSingleAssistance.files.length;
    },
  },
};
</script>

<style lang="scss">
.files_header_container {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 9px;
}
.files_assistance__subtitle {
  display: flex;
  gap: 21px;
  color: var(--title_profile) !important;
  white-space: nowrap;
  width: 80% !important;
}
.icon_container_assistance_files {
  > svg {
    height: 45px !important;
    border-radius: 115%;
    padding: 5px;
    width: 25px !important;
    fill: #0064ff !important;
    stroke: #0064ff !important;
  }
}
.files_assistance__content__files {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text_profile) !important;
  cursor: pointer;
}
.files_assistance__content {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: flex;
    flex-direction: column;
  }
}
.files_assistance__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 18px !important;
  margin-bottom: 1rem;
}
.tag_assistance_files {
  color: white !important;
  background: #619aff !important;
  fill: solid;
  border-radius: 4px;
  width: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.files_assistances_description {
  display: flex;
  flex-direction: column;
  cursor: pointer !important;
  pointer-events: auto;
}
.files_date_assistance {
  font-size: 10px !important;
  color: #9c9c9c !important;
}
.files_name_assistance {
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
}

.files_subtitle_text {
  display: flex;
  width: 100% !important;
  gap: 1rem;
}
.tag_assistance_files_container {
  width: 40% !important;
}
.close_assitance_files {
  display: none !important;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: flex !important;
    justify-content: flex-end;
    > svg {
      fill: var(--cancel_icon) !important;
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
  }
}
.icon_container_assistance_files_principal {
  > svg {
    fill: var(--svg_pre_step) !important;
    stroke: var(--svg_pre_step) !important;
    height: 45px !important;
    border-radius: 115%;
    padding: 5px;
    width: 25px !important;
  }
}
.tooltip__key__message {
  position: absolute;
  display: none;
  font-size: 11.8px;
  width: 200px;
  overflow: hidden;
}

.container__wrapper__hover__message:hover .tooltip__key__message {
  transform: fade-in 500ms;
  transition: all 700ms ease;
  opacity: 1;
  display: block;
  background: #ffffff !important;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  padding: 0.5rem;
  border-radius: 17px;
  transform: translateX(-113%) translateY(2px);
  color: #000000 !important;
}
.container__wrapper__hover__message {
  display: flex;
  width: 125px !important;
  white-space: nowrap !important;
  flex-direction: initial !important;
}
.container__wrapper__hover__message:hover {
  flex-direction: row !important;
  transition: all 1.5s linear;
  > svg {
    position: absolute !important;
    opacity: 0.9;
    z-index: 99999;
  }
}
</style>
