export default {
  digital_signature_data: [],
  digital_signature_data_failure: false,
  digital_signature_data_success: false,
  error_digital_signature: "",
  datacheck: false,
  flagData: null,
  total_data: "",
  total_data_view: "",
  nextPage: null,
  prevPage: null,
  lastPage: "",
  initialPage: "",
  tagsForfront: [],
  tagForReloadRequest: [],
  tags: false,
  globalLoader: false,
  document_category: "",
  documents: [],
  documentFromApp: null,
  loaderDocuments: false,
  setDocumentName: null,
  setDocumentId: null,
  dataDumentToSingFromMB: {},
  signers: [],
  signerFromCheck: null,
  initialSigner: null,
  tracking: {},
  flagTracking: false,
  identifier: null,
  draftData: null,
  signerDraft: null,
  setSearchAll: null,
  setSearchPending: null,
  setSearchComplete: null,
  flagDelete: false,
  flagDraftData: false,
  loader_documents_from_app: false,
  urlFileDownload: null,
  missingSignerValidatorName: false,
  missingSignerValidatorEmail: false,
  missingSignerValidatorRut: false,
  stepsDigitalOnBoarding: 1,
};
