import * as types from "./mutations-types";
export default {
  // loader document from app
  [types.FETCH_REPORT_USE_TENANT_CATEGORY_DATA](state, data) {
    let categories = data.map((element, index) => {
      //saltamos los primeros cuatro elementos que son para el home
      if (index <= 7) {
        // creamos un arreglo de objetos con los valores de entrada para front
        let usageElement = {
          name: element.compute_name,
          value: element.compute_description,
          check: true,
        };

        return usageElement;
      }
    });

    let final = categories.slice(0, 8);

    //seteamos estado de data con resultado del map.
    //TODO: probar filter en caso de index no funcione.
    state.dataCategoryFilter = final;
  },

  [types.SET_REPORTS_USE_TENANTS](state, data) {
    let tempCategory = state.dataCategoryFilter;

    // recorremos las categorias para encontrar la coincidencia
    for (var i = 0; i < tempCategory.length; i++) {
      // si el elemento del array original coincide con el valor enviado entonces modificamos el valor booleano del check
      if (tempCategory[i].value == data.item.value) {
        tempCategory[i].check = data.value;
      }
    }

    // reemplazamos el valor original por el mutado
    state.dataCategoryFilter = tempCategory;
  },

  [types.FETCH_CSV_DATA](state, data) {
    state.csv = data;
  },
  [types.CLEAN_CSV_DATA](state) {
    state.csv = null;
  },
  [types.FETCH__CSV_FAILURE](state) {
    state.cvsError = true;
  },
  [types.FETCH_CSV_SUCCESS](state) {
    state.cvsError = false;
  },



};
