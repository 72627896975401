import ViewSingleAssistance from "./pages/ViewSingleAssistance.vue";
import CreateAssistance from "./pages/CreateAssistance.vue";
import EditAssistance from "./pages/EditAssistance.vue";
import ListAsssintance from "./pages/ListAsssintance.vue";
import GetSingleAssitanceUrl from "./pages/getSingleAssitanceUrl.vue";
import PreStepAssitance from "./pages/PreStepAssitance.vue";
import MessageWall from "./pages/MessageWall.vue";
import integratedAssistanceModule from "./integratedAssistanceModule.vue";
export const RequestAssistanceRoutes = [
  {
    path: "/pre-step-solicitudes",
    name: "integratedAssistance",
    component: PreStepAssitance,
  },
  {
    path: "/solicitudes",
    component: integratedAssistanceModule,
    children: [
      {
        path: "",
        name: "listRequestIntegrated",
        component: ListAsssintance,
      },
      {
        path: "solicitar-asistencia",
        name: "requestAsistencia",
        component: CreateAssistance,
      },
      {
        path: "numero-caso/:id",
        name: "ViewSingleAssistance",
        component: ViewSingleAssistance,
        props: true,
      },
      {
        path: "record/:id",
        name: "getSingleAssistanceUrl",
        component: GetSingleAssitanceUrl,
      },
      {
        path: "editar-solicitud/:id",
        name: "EditSingleAssistance",
        component: EditAssistance,
        props: true,
      },
      {
        path: "muro-mensajes",
        name: "messageWall",
        component: MessageWall,
      },
    ],
  },
];