<template>
  <div class="modal__content">
    <div
      class="image_onboarding_employee onboarding_img_fix image_onboarding_third_assistance"
    >
      <ThirdImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_third_assistance">
          Comunícate con el abogado encargado
        </div>
      </div>
      <div class="text_container_onboarding text_container_third_step">
        <div class="">
          Luego de ingresar tu solicitud, notarás que cada servicio posee su
          propio Muro de mensajes. Aquí podrás mantenerte en contacto con tu
          abogado y almacenar una bitácora del caso.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThirdImageOnBoarding from "./assets/thirdStepAssistance.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    ThirdImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Assistance", {
      stepAssistanceOnBoarding: "stepAssistanceOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepAssistanceOnBoarding + 1;

      this.$store.dispatch(
        "Assistance/counterStepAssistanceOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.title_onboarding_third_assistance {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    text-align: center !important;
  }
}
.image_onboarding_third_assistance {
  width: 339px !important;
  height: 300px !important;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 11.3rem !important;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 220px !important;
    height: 200px !important;
    margin-left: 2.5rem !important;
    margin-top: 4.8rem !important;
  }
}
</style>
