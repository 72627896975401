var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__digital__remove",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : 'modal__default',attrs:{"id":"modal__digital__remove"}},[_c('div',{staticClass:"title_remove text-filter-light"},[_vm._v(" ¿Estás seguro que deseas cancelar "),_c('span',[_vm._v(" "+_vm._s(_vm.name_modal)+" “"+_vm._s(_vm.identifier_modal)+" ”?")])]),_c('div',{staticClass:"title_remove sub-title-modal-signature .container__title"},[_vm._v(" Si cancelas un proceso de firma digital no podrás deshacer esta acción y no podrás a acceder a este nuevamente ")]),_c('div',{staticClass:"footer_modal"},[_c('div',{staticClass:"secondary_button",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Cancelar")])]),_c('div',{staticClass:"btn-vue-primary fix-btn-mac fix-width",on:{"click":function($event){return _vm.deleteSignature(_vm.id_document)}}},[_c('span',[_vm._v(" Si, continuar ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }