export const USER_ROLE = Object.freeze({
  ADMIN: "Admin",
  LAWYER: "Lawyer",
  AGENT: "Agent",
  EXECUTIVE: "Company-Executive",
  COMPANY_ADMIN: "Company-Admin",
  COMPANY_EMPLOYEE: "Company-Employee",
  COMPANY_ENTEL: "Company-Entel"

})
