<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    id="modal__delete__employee"
    ref="modal__delete__employee"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="new_modal__content" v-if="!confirmDelete">
      <div class="text-filter fix_employee_title">
        Eliminar miembro del equipo
      </div>

      <div class="text-filter-light">
        ¿Estas seguro? está acción no se puede deshacer, se eliminaran todos los
        datos asociados a este miembro del equipo.
      </div>
      <div class="new__footer__modal">
        <div
          disabled="disabled"
          class="secondary_button cancel_delete_employee"
          @click="cancelModal()"
        >
          <span> Cancelar</span>
        </div>
        <div
          class="fix-btn-mac new_button_employee_modal"
          @click="deleteEmployee"
        >
          <span v-if="verificationDelete"> Aceptar </span>
          <span class="" v-else>
            <div class="loader_delete_employee"></div>
          </span>
        </div>
      </div>
    </div>
    <div
      class="new_modal__content center_elements"
      v-else-if="confirmDeleteFailure"
    >
      <div class="">
        <ConfirmFailure />
      </div>
      <div class="text-filter fix_employee_title">
        Ocurrio un error y no pudimos eliminar al miembro del equipo, vuelve a
        intentarlo
      </div>
    </div>
    <div class="new_modal__content center_elements" v-else>
      <div class="">
        <Confirm />
      </div>
      <div class="text-filter fix_employee_title">
        El miembro del equipo ha sido eliminado con exito
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CancelIcon from "@/assets/iconos_container/cancel.svg";
import Confirm from "@/assets/Vector.svg";
import ConfirmFailure from "@/assets/ConfirmFailure.svg";
export default {
  props: ["size", "user_id", "admin_company_id_selected"],
  components: {
    CancelIcon,
    Confirm,
    ConfirmFailure,
  },
  data() {
    return {
      confirmDelete: false,
      confirmDeleteFailure: false,
      verificationDelete: true,
    };
  },
  created() {},
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
      group: "group",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapState({
      company: (state) => state.Login.company,
    }),
  },

  methods: {
    deleteEmployee() {
      this.verificationDelete = false;
      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company.id;
      let payload = {
        admin_company_id: this.admin_company_id_selected,
        user_id: this.user_id,
      };
      this.$store
        .dispatch("EmployeesV2/soft__delete", payload)
        .then(() => {
          this.confirmDelete = true;
          this.confirmDeleteFailure = false;
          this.verificationDelete = true;
          if (this.inSwitch) {
            let id = this.idInSwitch;
            let offset = 0;
            let company_id = admin_company_id;
            let payload = {
              id_company: company_id,
              limit: "10",
              offset: offset,
              first_name__icontains: null,
              id: id,
            };
            this.$store.dispatch(
              "EmployeesV2/get_list_employee_InSwitchTenancy",
              payload
            );
            this.$store.dispatch(
              "EmployeesV2/get_list_members_InSwitchTenancy",
              payload
            );
          } else {
            let offset = 0;
            let company_id = admin_company_id;
            let payload = {
              company_id: company_id,
              limit: "10",
              offset: offset,
            };
            this.$store.dispatch("EmployeesV2/get_list_employee", payload);
            this.$store.dispatch(
              "EmployeesV2/get_list_employee_members",
              payload
            );
          }
        })
        .catch(() => {
          this.confirmDelete = true;
          this.confirmDeleteFailure = true;
          this.verificationDelete = true;
        });

      // setTimeout(() => {
      //   this.cancelModal();
      // }, 4000);
    },
    open() {
      this.confirmDelete = false;
      this.confirmDeleteFailure = false;
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__delete__employee");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__delete__employee");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss"></style>
