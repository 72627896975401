<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'modal__filter_v2'
        ? 'modal__filter_v2'
        : 'modal__default'
    "
    id="modal__filter__assistance"
    ref="modal__filter__assistance"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>

    <div class="filter_assistance_content">
      <div class="container-filter-modal">
        <div class="text-filter">Filtros</div>
        <FilterIcon />
      </div>
      <div class="container_assistance_filter label-primary">
        <span>Tipo de servicio</span>
        <div class="custom-select test_input">
          <select
            class="select_field input-form input_assistance"
            v-model="typeService"
            id="typeService"
          >
            <option selected hidden disabled>Seleccione una respuesta</option>
            <option
              v-for="(el, index) in listGroupAssistance"
              :value="{ id: el.id, title: el.title }"
              :key="index"
            >
              {{ el.title }}
            </option>
          </select>
          <span class="custom-arrow-b"></span>
        </div>
      </div>
      <div class="container_assistance_filter_date label-primary">
        <div class="assistance_calendar">
          <span>Fecha Inicio</span>
          <div class="date_filter_assitance">
            <div class="input__icon">
              <b-form-datepicker
                v-model="initialDate"
                class="input-form"
                placeholder="Seleccionar Fecha "
              ></b-form-datepicker>
            </div>
          </div>
        </div>
        <div class="assistance_calendar">
          <span>Fecha Final</span>
          <div class="date_filter_assitance">
            <div class="input__icon">
              <b-form-datepicker
                v-model="endDate"
                class="input-form"
                placeholder="Seleccionar Fecha"
              ></b-form-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container_assistance_filter_modal label-primary"
        :class="requesters.length > 0 ? '' : 'inactive_requester_employee'"
      >
        <span>Solicitante</span>

        <div class="custom-select ">
          <!-- TODO: apply select/opt from requester service -->
          <input
            id="employee"
            list="data"
            @change="setEmployee"
            :value="employee"
            class="input-form m-top-m"
          />

          <datalist id="data">
            <option v-for="(el, index) in requesters" :key="index">
              {{ el.name }}
            </option>
          </datalist>
        </div>
      </div>

      <div class="footer_modal">
        <div class="btn-vue-primary fix-btn-mac" @click="applyFiltersModal()">
          <span> Confirmar </span>
        </div>
        <div
          disabled="disabled"
          class="secondary_button"
          @click="cancelModal()"
        >
          <span> Cancelar</span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/assets/iconos_container/cancel.svg";
import FilterIcon from "../../../assets/iconos_container/filters.svg";
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  props: ["title", "size", "company_for_modal", "id"],
  components: {
    CancelIcon,
    FilterIcon,
  },
  created() {
    this.getEmployes();
  },
  data: () => {
    return {
      typeService: null,
      initialDate: null,
      endDate: null,
      requesters: [],
      requesterSelected: null,
      employee: null,
      limit: "5",
    };
  },
  computed: {
    ...mapGetters("Assistance", {
      listGroupAssistance: "listGroupAssistance",
      employees: "employees",
      flagActiveInputSearch: "flagActiveInputSearch",
      dataInputSearchTags: "dataInputSearchTags",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapState({
      company: (state) => state.Login.company,
    }),
  },

  methods: {
    getEmployes() {
      let id_company = this.inSwitch ? this.idInSwitch : this.company.id;

      let fields = "id,first_name,surname";

      let params = {
        id_company: id_company,
        fields: fields,
      };
      this.$store.dispatch("Assistance/getListEmployees", params).then(() => {
        this.requesters = this.employees;
      });
    },
    setEmployee(e) {
      let currentEmployee = e.currentTarget.value;
      let employeesList = this.requesters;

      let selectedEmployee = employeesList.filter((el) => {
        return el.name == currentEmployee;
      });

      if (selectedEmployee != null) {
        this.requesterSelected = {
          id: selectedEmployee[0] != null ? selectedEmployee[0].id : "",
          name: selectedEmployee[0] != null ? selectedEmployee[0].name : "",
        };
      }
    },
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__filter__assistance");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__filter__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    async applyFiltersModal() {
      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      let fields =
        "id,title,service_type,requester,display_id,created_at,description,file_urls";

      let idTypeServiceSelected;
      let textTypeServiceSelected;

      //--------------------------------
      // @Input: typeServiceSelected, initialDate selected, endDateselected, EmployeeSelected
      // Description: validate empty or no null fields, create params to send a filter assitance service, create params to send a store for tags filters associated
      // @Output: from type service: idSelected, textTypeService, from initialDate a date, from endDate a date, from employee selected id employee
      //--------------------------------
      if (this.typeService != null) {
        let { id, title } = this.typeService;
        idTypeServiceSelected = id;
        textTypeServiceSelected = title;
      }

      let initialRangeDate = null ?? this.initialDate;
      let endRangeDate = null ?? this.endDate;

      if (initialRangeDate != null && endRangeDate == null) {
        const dateNow = new Date();
        const format = "YYYY-MM-DD";
        let today = moment(dateNow).format(format);
        endRangeDate = today;
      }

      if (endRangeDate != null && initialRangeDate == null) {
        // endRangeDate = null;
        initialRangeDate = endRangeDate;
      }

      let requesterName;
      let requesterId;

      let requesterSelected = null ?? this.requesterSelected;

      if (requesterSelected != null) {
        let { id, name } = this.requesterSelected;
        requesterName = name;
        requesterId = id;
      }

      let date__range__selected = null;
      if (initialRangeDate != null) {
        date__range__selected = `${initialRangeDate} a ${endRangeDate}`;
      }
      let created_at__date__range = null;
      // output needed 2022-08-31, 2022-09-01

      if (initialRangeDate != null) {
        created_at__date__range = `${initialRangeDate},${endRangeDate}`;
      }
      let payloadForTags = [
        {
          value: textTypeServiceSelected != null ? textTypeServiceSelected : "",
          id: idTypeServiceSelected != null ? idTypeServiceSelected : "",
          status: idTypeServiceSelected != null ? true : false,
        },
        {
          value: date__range__selected != null ? date__range__selected : "",
          id: date__range__selected != null ? created_at__date__range : "",
          status: date__range__selected != null ? true : false,
        },

        {
          value: requesterName != null ? requesterName : "",
          id: requesterId != null ? requesterId : "",
          status: requesterId != null ? true : false,
        },
      ];

      await this.$store.dispatch("Assistance/addTagsOfFilters", payloadForTags);
      if (this.flagActiveInputSearch) {
        let evaluarInput = Number(this.dataInputSearchTags);

        if (evaluarInput) {
          // we save the value from the search in variable to send to the payload
          let display_id = this.dataInputSearchTags;
          await this.$store.dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: admin_company_id,
            fields: fields,
            limit: "5",
            service_type:
              idTypeServiceSelected != null ? idTypeServiceSelected : null,
            created_at__date__range:
              created_at__date__range != null ? created_at__date__range : null,
            requester: requesterId != null ? requesterId : null,
            display_id,
          });
          // in case the value is not a number we dispatch de "title contains" payload
        } else {
          // we save the value from the search in variable to send to the payload
          let title__icontains = this.dataInputSearchTags;
          await this.$store.dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: admin_company_id,
            fields: fields,
            limit: "5",
            service_type:
              idTypeServiceSelected != null ? idTypeServiceSelected : null,
            created_at__date__range:
              created_at__date__range != null ? created_at__date__range : null,
            requester: requesterId != null ? requesterId : null,
            title__icontains,
          });
        }
      } else {
        let payload = {
          admin_company_id: admin_company_id,
          fields: fields,
          limit: "5",
          service_type:
            idTypeServiceSelected != null ? idTypeServiceSelected : null,
          created_at__date__range:
            created_at__date__range != null ? created_at__date__range : null,
          requester: requesterId != null ? requesterId : null,
        };
        await this.$store.dispatch(
          "Assistance/getAssistanceWithFilters",
          payload
        );
      }

      //  TODO:return initial values of data to null before call store
      this.typeService = null;
      this.initialDate = null;
      this.endDate = null;
      this.requesterSelected = null;
      let modal = document.querySelector("#modal__filter__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/components/modal/modal.scss";
.container_assistance_filter_modal {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  // gap: 0.8rem;
}
.container_assistance_filter_date {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
  width: 100%;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    flex-wrap: wrap;
  }
}

.container__title span {
  color: var(--title_profile) !important;
}
.container_assistance_filter_modal span {
  color: var(--text_profile) !important;
}
.container_assistance_filter {
  > .title-container {
    color: var(--text_profile) !important;
  }
}
.container_assistance_filter_date span {
  color: var(--text_profile) !important;
}
.container_assistance_filter_date {
  > .title-container {
    color: var(--text_profile) !important;
  }
}
.date_filter_assitance {
  display: flex;
  flex-direction: column;
}
.filter_assistance_content {
  width: 100%;
  height: 90% !important;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  padding-right: 35px;
  gap: 0.7rem;
   @media screen and (max-width: 500px) and (min-width: 200px) {
    height: 110% !important;
  }
}
.assistance_calendar {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 50%;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 100%;
  }
}
.inactive_requester_employee {
  opacity: 0.3 !important;
  pointer-events: none !important;
}
</style>
