export const CompanyRoutesV2 = [
  {
    path: "/companys",
    component: () =>
      import(/*webpackChunkName: "CompanyModule"*/ "./CompanyModule.vue"),

    children: [
      {
        path: "/",
        name: "ListCompanyV2",
        component: () =>
          import(
            /*webpackChunkName: "listCompany" */ "./pages/ListCompanys.vue"
          ),
      },

      {
        path: "/nueva-empresa",
        name: "CreateCompany",
        component: () =>
          import(
            /*webpackChunkName: "CreateCompany"*/ "./pages/CreateCompany.vue"
          ),
      },

      {
        path: "/editar-empresa/:company_name",
        name: "EditCompany",
        component: () =>
          import(/*webpackChunkName: "EditCompany"*/ "./pages/EditCompany.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/mi-empresa",
    name: "Company",
    component: () =>
      import(/*webpackChunkName: "Company"*/ "./pages/Company.vue"),
    props: true,
  },
];
