import * as types from "./mutations-types";

export default {
  setPorfileEdit({ commit }) {
    commit(types.SET_PROFILE_EDIT);
  },
  setPorfileEditNull({ commit }) {
    commit(types.SET_PROFILE_EDIT_FALSE);
  },
};
