<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : size == 'modal__SLA_v2'
        ? 'modal__SLA_v2'
        : 'modal__default'
    "
    id="modal__sla__message"
    ref="modal__sla__message"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="modal_content_SLA">
      <div class="container_basic_information_assistance m-top-s">
        <div class="description_assistance">
          <div class="tittle_description title_sla_modal">
            SLA generales de mis abogados
          </div>
          <div class="sla_container_assistance">
            Para todas las solicitudes de asistencia legal, MisAbogados ha
            dispuesto compromisos de tiempo de respuesta y entrega final del
            servicio, de cara a sus clientes. Para cada nueva consulta, revisión
            o redacción de contrato ingresada en la plataforma, usted recibirá
            una confirmación de su abogado(a) asignado(a) en
            <b>menos de 4 horas*</b>. Dicho profesional podrá solicitarle
            antecedentes adicionales, si fuese necesario. Al recibir los
            antecedentes adicionales solicitados, su abogado(a) tendrá un<b>
              máximo de 12 horas para responder su consulta</b
            >, o bien
            <b>
              una cantidad de días hábiles a acordar con su abogado si se
              tratase de elaborar o revisar documentos y/o contratos</b
            >
          </div>
        </div>
        <div class="description_assistance">
          <div class="tittle_description title_sla_modal">
            SLA para la atencion de tu ticket
          </div>
          <div class="sla_container_assistance">
            En caso de que el cliente no enviase los antecedentes adicionales
            solicitados, dentro de un
            <b>plazo de 24 horas desde el último recordatorio</b> enviado por
            nuestro profesional, su solicitud <b>será cerrada</b> a la espera de
            su respuesta.
          </div>
          <div
            class="sla_container_assistance text_asistance_information sla_information_modal"
          >
            *Se considera, para la definición de todos nuestros SLAs, horas
            hábiles (9:00 a 19:00 hrs, de Lunes a Viernes)
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/assets/iconos_container/cancel.svg";
export default {
  props: ["size"],
  components: {
    CancelIcon,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__sla__message");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__sla__message");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    gotoWall() {
      this.$router.push({ name: "messageWall" });
    },
    backModal() {
      let modal = document.querySelector("#modal__sla__message");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.modal_content_SLA {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 20px;
  padding-bottom: 57px;
}
.title_sla_modal {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}
.sla_information_modal {
  text-align: center;
  color: #23467a;
  font-weight: bold;
}
</style>
