<template>
  <div class="horizontal-scroll">
    <ModalConfirmDelete
      ref="modal__delete__employee"
      size="ls"
      :user_id="currentUserId"
      :admin_company_id_selected="current_admin_company_id"
    />

    <div class="body__digital_signature">
      <div class="center_loader_employee" v-if="getLoaderTableEmployee">
        <loaderGeneric />
        Cargando resultados...
      </div>
      <div class="center_loader_employee" v-else-if="flagActiveResultEmployee">
        <NoResultsImage />
        <div class="text_loader_employee" v-if="activeInputSearchUser">
          No hay resultados con esa búsqueda
        </div>
        <div class="text_loader_employee" v-else>
          No existen datos de Usuarios, Agrega a un miembro de tu equipo para
          visualizar datos
        </div>
      </div>
      <table class="table__digital__signature" v-else>
        <thead class="thead_employee thead_employees">
          <tr>
            <td class="header_table_actions_table_employees"></td>
            <td
              class="header_table_actions_table_employees mobile_buttons_verified"
              v-show="group != 'Company-Executive'"
            >
              <div class="options_employee_actions">
                <div class="">Estado</div>
                <span class="icon_options_employee_edit">
                  <DropDownOptionsState
                /></span>
              </div>
            </td>
            <td
              class="header_table_actions_table_employees mobile_buttons_verified"
              v-if="group == 'Agent' || group == 'Admin'"
            >
              <div class="options_employee_actions">
                <div class="">Verificación</div>
                <span class="icon_options_employee_edit">
                  <DropDownOptionsVerified
                /></span>
              </div>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedName()"
            >
              Nombre
              <span v-bind:class="sortedFlagName ? 'sortDateDown' : 'sortDate'">
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedCompany()"
            >
              Empresa
              <span
                v-bind:class="sortedFlagCompany ? 'sortDateDown' : 'sortDate'"
              >
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedEmail()"
            >
              Email
              <span
                v-bind:class="sortedFlagEmail ? 'sortDateDown' : 'sortDate'"
              >
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedRut()"
            >
              Rut
              <span v-bind:class="sortedFlagRut ? 'sortDateDown' : 'sortDate'">
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees desktop_buttons_verified"
              v-show="group != 'Company-Executive'"
            >
              <div class="options_employee_actions">
                <div class="">Estado</div>
                <span class="icon_options_employee_edit">
                  <DropDownOptionsState
                /></span>
              </div>
            </td>
            <td
              class="header_table_actions_table_employees desktop_buttons_verified"
              v-if="group == 'Agent' || group == 'Admin'"
            >
              <div class="options_employee_actions">
                <div class="">Verificación</div>
                <span class="icon_options_employee_edit">
                  <DropDownOptionsVerified
                /></span>
              </div>
            </td>
            <td class="header_table_actions_table_employees"></td>
            <td class="header_table_actions_table_employees"></td>
          </tr>
        </thead>

        <tbody id="item_icons" class="tbody_signature body__table_employees">
          <tr v-for="(data, index) in listTotalEmployee" :key="index">
            <td></td>
            <td
              class="mobile_buttons_verified"
              v-show="group != 'Company-Executive'"
            >
              <button
                id="stateButton"
                :class="
                  !data.is_active
                    ? 'fix-btn-mac button_state_employee f-12'
                    : 'button_state_employee_off f-12'
                "
                @click="ChangeStateEmployee(data.id)"
              >
                <div class="" v-if="!data.is_active">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedState == data.id"
                  ></div>
                  <div class="" v-else>Inactivo</div>
                </div>
                <div class="" v-if="data.is_active">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedState == data.id"
                  ></div>
                  <div class="" v-else>Activo</div>
                </div>
              </button>
            </td>
            <td
              class="mobile_buttons_verified"
              v-if="group == 'Agent' || group == 'Admin'"
            >
              <button
                id="verifiedButton"
                :class="
                  !data.is_verified
                    ? 'fix-btn-mac button_verification_employee f-12'
                    : 'button_state_verification_off f-12'
                "
                @click="ChangeVerificationEmployee(data.id, data.is_verified)"
              >
                <div class="" v-if="!data.is_verified">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedVerified == data.id"
                  ></div>
                  <div class="" v-else>No Verificado</div>
                </div>

                <div class="" v-if="data.is_verified">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedVerified == data.id"
                  ></div>
                  <div class="" v-else>Verificado</div>
                </div>
              </button>
            </td>
            <td @click="viewEmployee(data)">
              {{ data.first_name }}
              {{ data.surname }}
            </td>
            <td @click="viewEmployee(data)">
              {{ data.company.alias }}
            </td>
            <td @click="viewEmployee(data)">{{ data.user.email }}</td>
            <td @click="viewEmployee(data)">
              {{ data.nin }}
            </td>
            <td
              class="desktop_buttons_verified"
              v-show="group != 'Company-Executive'"
            >
              <button
                id="stateButton"
                :class="
                  !data.is_active
                    ? 'fix-btn-mac button_state_employee f-12'
                    : 'button_state_employee_off f-12'
                "
                @click="ChangeStateEmployee(data.id)"
              >
                <div class="" v-if="!data.is_active">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedState == data.id"
                  ></div>
                  <div class="" v-else>Inactivo</div>
                </div>
                <div class="" v-if="data.is_active">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedState == data.id"
                  ></div>
                  <div class="" v-else>Activo</div>
                </div>
              </button>
            </td>
            <td
              class="desktop_buttons_verified"
              v-if="group == 'Agent' || group == 'Admin'"
            >
              <button
                id="verifiedButton"
                :class="
                  !data.is_verified
                    ? 'fix-btn-mac button_verification_employee f-12'
                    : 'button_state_verification_off f-12'
                "
                @click="ChangeVerificationEmployee(data.id, data.is_verified)"
              >
                <div class="" v-if="!data.is_verified">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedVerified == data.id"
                  ></div>
                  <div class="" v-else>No Verificado</div>
                </div>

                <div class="" v-if="data.is_verified">
                  <div
                    class="loader_employee"
                    v-if="currentSelectedVerified == data.id"
                  ></div>
                  <div class="" v-else>Verificado</div>
                </div>
              </button>
            </td>

            <td class="icon_options_employee">
              <div
                class="icon_options_employee_delete"
                v-if="group == 'Company-Admin' || group == 'Admin'"
              >
                <DeleteIcon @click="DeleteEmployee(data.id, data.company.id)" />
              </div>
              <div
                class="icon_options_employee_delete disabled_employee_delete"
                v-else
              >
                <DeleteIcon />
              </div>
              <div class="icon_options_employee_edit">
                <!-- <DropdownThreeDots
                  :id="data.id"
                  :active="data.threedots"
                  :employee="data"
                /> -->
                <ViewEmployeeIcon @click="viewEmployee(data)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer_digital_signature footer_employee">
      <div class="per_page">
        Visualizar por página:

        <select v-model="selected_quantity" @change="selectQuantity($event)">
          <option
            v-for="(option, index) in amount_of_rows"
            :key="index"
            :value="option"
          >
            {{ index }}
          </option>
        </select>
      </div>
      <div class="btn_pagination_digital_signature">
        <firstPage
          :class="page == 1 ? 'blockPagination' : ''"
          @click="setInitialPage()"
        />
        <leftArrow
          class="leftArrow"
          :class="page == 1 ? 'blockPagination' : ''"
          @click="prevPagination()"
        />
        <rightArrow
          :class="page === totalPages ? 'blockPagination' : 'itemClick'"
          @click="nextPagination()"
        />
        <lastPage v-if="nextPage != null" @click="setLastPage()" />
        <lastPage v-else class="blockPagination" />
      </div>
      <div class="text_view_digital_signature">
        Visualizando {{ page }} de {{ totalPages }} páginas
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconBack from "../../../../assets/iconos_container/back.svg";
import DeleteIcon from "../../assets/Trashcan.svg";
import ViewEmployeeIcon from "../../assets/zoom-in.svg";
// import ThreeDots from "../../assets/dot-menu-ellipse-small.svg";
import ModalConfirmDelete from "../modals/ModalConfirmDelete.vue";
import DropDownOptionsState from "../dropDown/DropDownOptionsState.vue";
import DropDownOptionsVerified from "../dropDown/DropDownOptionsVerified.vue";
import loaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
// import DropdownThreeDots from "../dropDown/DropDownThreeDots.vue";
import NoResultsImage from "../../assets/no_results_table.svg";

// pagination
import firstPage from "@/assets/iconos_container/firstpage.svg";
import leftArrow from "@/assets/iconos_container/left.svg";
import lastPage from "@/assets/iconos_container/lastpage.svg";
import rightArrow from "@/assets/iconos_container/right.svg";

export default {
  components: {
    IconBack,
    DeleteIcon,
    ModalConfirmDelete,
    DropDownOptionsState,
    DropDownOptionsVerified,
    // ThreeDots,
    loaderGeneric,
    // DropdownThreeDots,
    firstPage,
    leftArrow,
    lastPage,
    rightArrow,
    NoResultsImage,
    ViewEmployeeIcon,
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__employee: "list__employee",
      getLoaderTableEmployee: "getLoaderTableEmployee",
      list__companies: "list__companies",
      totalPages: "totalPages",
      nextPage: "nextPage",
      prevPage: "prevPage",
      total_data_view: "total_data_view",
      skeletonFilter: "skeletonFilter",
      list__employee_members: "list__employee_members",
      flagActiveResultEmployee: "flagActiveResultEmployee",
      activeInputSearchUser: "activeInputSearchUser",
      activeTableMembers: "activeTableMembers",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
      group: "group",
      user_profile: "user_profile",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  data() {
    return {
      isActiveMember: "",
      currentUserId: null,
      current_admin_company_id: null,
      listTotalEmployee: [],

      sortedFlagName: false,
      sortedFlagCompany: false,
      sortedFlagEmail: false,
      sortedFlagRut: false,

      activeButtonEmployee: false,
      activeVerificationEmployee: false,
      loader: false,
      currentSelectedVerified: null,
      currentSelectedState: null,
      selected_quantity: "10",
      amount_of_rows: {
        10: 10,
        50: 50,
        100: 100,
      },
      page: 1,
      limit: "10",
      offset: null,
      dataSearch: "",
      loaderSkeleton: false,
      DefaultInfo: true,
      listTotalCompanies: [],
    };
  },
  created() {
    this.isActiveMember = this.activeTableMembers;

    this.sortedFlag = false;
    this.listTotalCompanies = this.list__companies;
    this.listTotalEmployee = this.list__employee;

    this.offset = null;
    if (this.total_data_view <= 10) {
      this.selected_quantity = "10";
    } else {
      this.selected_quantity = this.total_data_view;
    }
    if (this.prevPage != null) {
      this.setInitialPage();
    }
  },
  methods: {
    viewEmployee(employee) {
      let user_id = employee.id;
      let company_id = employee.company.id;
      let isActiveMember = this.isActiveMember;
      let payload = {
        company_id: company_id,
        user_id: user_id,
        isActiveMember: isActiveMember ? "Miembro" : "Usuario",
      };

      this.$store
        .dispatch("EmployeesV2/get__single__user", payload)
        .then(() => {
          this.$store
            .dispatch("EmployeesV2/setCurrentViewEmployee", employee)
            .then(() => {
              this.$router.push({
                name: "ViewEmployee",
                params: { name: employee.first_name },
              });
            });
        });
    },
    sortedName() {
      if (!this.sortedFlagName) {
        this.sortedFlagName = true;
        this.listTotalEmployee.sort((a, b) => {
          if (a.first_name > b.first_name) {
            return 1;
          }
          if (a.first_name < b.first_name) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagName = false;
        this.listTotalEmployee.sort((a, b) => {
          if (b.first_name > a.first_name) {
            return 1;
          }
          if (b.first_name < a.first_name) {
            return -1;
          }
          return 0;
        });
      }
    },
    sortedCompany() {
      if (!this.sortedFlagCompany) {
        this.sortedFlagCompany = true;
        this.listTotalEmployee.sort((a, b) => {
          if (a.company.alias > b.company.alias) {
            return 1;
          }
          if (a.company.alias < b.company.alias) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagCompany = false;
        this.listTotalEmployee.sort((a, b) => {
          if (b.company.alias > a.company.alias) {
            return 1;
          }
          if (b.company.alias < a.company.alias) {
            return -1;
          }
          return 0;
        });
      }
    },
    sortedEmail() {
      if (!this.sortedFlagEmail) {
        this.sortedFlagEmail = true;
        this.listTotalEmployee.sort((a, b) => {
          if (a.user.email > b.user.email) {
            return 1;
          }
          if (a.user.email < b.user.email) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagEmail = false;
        this.listTotalEmployee.sort((a, b) => {
          if (b.user.email > a.user.email) {
            return 1;
          }
          if (b.user.email < a.user.email) {
            return -1;
          }
          return 0;
        });
      }
    },
    sortedRut() {
      if (!this.sortedFlagRut) {
        this.sortedFlagRut = true;
        this.listTotalEmployee.sort((a, b) => {
          if (a.nin > b.nin) {
            return 1;
          }
          if (a.nin < b.nin) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagRut = false;
        this.listTotalEmployee.sort((a, b) => {
          if (b.nin > a.nin) {
            return 1;
          }
          if (b.nin < a.nin) {
            return -1;
          }
          return 0;
        });
      }
    },

    ChangeStateEmployee(id) {
      this.currentSelectedState = id;
      let payload = {
        list: this.listTotalEmployee,
        id: id,
      };
      let user = [];
      user.push(id);
      let id_company = this.inSwitch ? this.idInSwitch : this.company_id;

      let serviceUpdateStatus = {
        id_company: id_company,
        users: user,
      };
      this.$store.dispatch(
        "EmployeesV2/setStatusEmployees",
        serviceUpdateStatus
      );
      this.$store.dispatch("EmployeesV2/setIsActive", payload);
      setTimeout(() => {
        this.currentSelectedState = false;
      }, 1000);
    },

    ChangeVerificationEmployee(id_user, is_verified) {
      let id_company = this.inSwitch ? this.idInSwitch : this.company_id;

      let user = [];
      user.push(id_user);

      let payload = {
        id_company: id_company,
        list: this.listTotalEmployee,
        user_id: user,
      };
      if (is_verified == false) {
        this.currentSelectedVerified = id_user;
        this.$store.dispatch("EmployeesV2/setIsVeriefied", payload);
      }
      setTimeout(() => {
        this.currentSelectedVerified = null;
      }, 1000);
    },
    DeleteEmployee(id, id_company) {
      this.current_admin_company_id = id_company;
      this.currentUserId = id;
      let param = "modal__delete__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__delete__employee;
      element.open();
    },

    selectQuantity(evt) {
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      let limit = evt.target.value;
      let user = this.user_profile;
      let id = user.id;
      this.page = 1;
      this.limit = limit;
      this.selected_quantity = evt.target.value;
      // let offset = 0;

      let payload = {
        company_id: company_id,
        limit: limit,
        id: id,
        // offset: offset,
      };
      this.$store.dispatch("EmployeesV2/get_list_employee_quantity", payload);
    },
    prevPagination() {
      let nextUrl = this.prevPage;
      this.page = this.page - 1;

      this.$store.dispatch("EmployeesV2/nextPagination", {
        nextUrl,
      });
    },
    nextPagination() {
      let nextUrl = this.nextPage;
      this.page = this.page + 1;
      this.$store.dispatch("EmployeesV2/nextPagination", {
        nextUrl,
      });
    },
    setLastPage() {
      let limit = this.limit;

      if (this.selected_quantity == 10) {
        if (this.offset == 0) {
          this.offset = 10 * this.totalPages;
        } else {
          this.offset = this.totalPages * 10 - 10;
        }
      }
      if (this.selected_quantity == 50) {
        if (this.offset == 0) {
          this.offset = 50 * this.totalPages;
        } else {
          this.offset = this.totalPages * 50 - 50;
        }
      }
      if (this.selected_quantity == 100) {
        if (this.offset == 0) {
          this.offset = 100 * this.totalPages;
        } else {
          this.offset = this.totalPages * 100 - 100;
        }
      }

      let offset = this.offset;
      let user = this.user_profile;
      let id = user.id;
      this.page = this.totalPages;
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payload = {
        company_id: company_id,
        limit,
        offset,
        id: id,
      };

      if (this.inSwitch) {
        let payload = {
          id_company: company_id,
          limit,
          offset,
          id: id,
        };
        this.$store.dispatch(
          "EmployeesV2/get_list_employee_InSwitchTenancy",
          payload
        );
      } else {
        this.$store.dispatch("EmployeesV2/get_list_employee_quantity", payload);
      }
    },
    setInitialPage() {
      let user = this.user_profile;
      let id = user.id;
      let limit = this.limit;
      this.page = 1;
      let offset = 0;
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payload = {
        company_id: company_id,
        limit: limit,
        offset: offset,
        id: id,
      };

      if (this.inSwitch) {
        let payload = {
          id_company: company_id,
          limit,
          offset,
          id: id,
        };
        this.$store.dispatch(
          "EmployeesV2/get_list_employee_InSwitchTenancy",
          payload
        );
      } else {
        this.$store.dispatch("EmployeesV2/get_list_employee_quantity", payload);
      }
    },
  },
  watch: {
    list__employee() {
      this.listTotalEmployee = [];
      this.listTotalEmployee = this.list__employee;
    },
    flagActiveResultEmployee() {
      if (this.flagActiveResultEmployee) {
        this.page = 0;
      } else {
        this.page = 1;
      }
    },
  },
};
</script>

<style lang="scss"></style>
