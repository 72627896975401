<template>
  <div class="">
    <div class="skeleton_assistance_left">
      <div class="skeleton_assistance_left_name">
        <div class="header_skeleton_basic  ">
          <div class="">
            <VueSkeletonLoader
              type="rect"
              :width="150"
              :height="15"
              animation="fade"
            />
          </div>
          <div class="header_skeleton_basic_icons container__icons__assistance  ">
            <VueSkeletonLoader
              type="rect"
              :width="25"
              :height="25"
              animation="fade"
            />
            <VueSkeletonLoader
              type="rect"
              :width="25"
              :height="25"
              animation="fade"
            />
          </div>
        </div>
        <div class="category_container_assistance">
          <div class="skeleton_information_skeleton">
            <VueSkeletonLoader
              type="rect"
              :width="100"
              :height="15"
              animation="fade"
            />
            <VueSkeletonLoader
              type="rect"
              :width="150"
              :height="15"
              animation="fade"
            />
          </div>
          <div class="skeleton_information_skeleton">
            <VueSkeletonLoader
              type="rect"
              :width="100"
              :height="15"
              animation="fade"
            />
            <VueSkeletonLoader
              type="rect"
              :width="150"
              :height="15"
              animation="fade"
            />
          </div>
        </div>
        <VueSkeletonLoader
          type="rect"
          :width="100"
          :height="15"
          animation="fade"
        />
        <VueSkeletonLoader
          type="rect"
          :width="800"
          :height="15"
          animation="fade"
        />
        <VueSkeletonLoader
          type="rect"
          :width="800"
          :height="15"
          animation="fade"
        />
        <VueSkeletonLoader
          type="rect"
          :width="800"
          :height="15"
          animation="fade"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  components: {
    VueSkeletonLoader,
  },
};
</script>

<style lang="scss">
.header_skeleton_basic {
  display: flex;
   justify-content: space-between;
}
.header_skeleton_basic_icons {
  display: flex;
  gap: 0.5rem;
}
</style>
