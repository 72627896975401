import api from "../../../service/api";
import apiForm from "../../../service/apiFormData";

export default {
  soft__delete(admin_company_id, user_id) {
    return api.delete(`/api/v1/user_profile/${admin_company_id}/${user_id}`);
  },
  get__list__employees(
    company_id,
    limit,
    offset,
    id,
    is_member,
    fields = "first_name,surname,company,user,nin,is_active,id,contract_type"
  ) {
    return api
      .get(`/api/v1/user_profile/${company_id}`, {
        params: {
          limit: limit,
          offset: offset,
          "id!": id,
          is_member: is_member,
          fields: fields,
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },
  get__single__employee(company_id, user_id) {
    return api
      .get(`/api/v1/user_profile/${company_id}`, {
        params: {
          id: user_id,

          fields:
            "birth_date,address,nationality,occupation,marital_status,state,city,is_legal_representative,position,group,second_surname,middle_name,company_ingress_date,contract_type,job_type,bank_account_type,working_hours,phone,social_security,health_insurance,bank_account_number,bank,salary,position_responsabilities",
        },
      })
      .then((response) => response.data);
  },
  //TODO: add params associate with search values
  search__employees(company_id, limit, offset, id) {
    return api
      .get(`/api/v1/user_profile/${company_id}`, {
        params: {
          limit: limit,
          offset: offset,
          "id!": id,
          fields: "first_name,surname,company,user,nin,is_active",
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },
  //TODO: add params associate with search values

  employeesInSwitchTenancy(
    id_company,
    limit,
    offset,
    first_name__icontains,
    id,
    is_member
  ) {
    return api
      .get(`/api/v1/user_profile/${id_company}`, {
        params: {
          company: id_company,
          limit: limit,
          offset: offset,
          // is_active: is_active,
          first_name__icontains: first_name__icontains,
          "id!": id,
          is_member: is_member,
          fields:
            "first_name,surname,company,user,nin,is_active,id,company_ingress_date,contract_type",
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },

  get__list__companies() {
    return api
      .get(`/api/v1/company/`, {
        params: {
          fields: "id,name,country",
        },
      })
      .then((response) => response.data);
  },

  get__companies_by_user() {
    return api
      .get("/api/v1/user_whitelist/", {
        params: {
          is_active: true,
          fields: "id,name,country",
        },
      })
      .then((response) => response.data);
  },
  act_inact_employee(company_id, payload) {
    return api
      .post(`/api/v1/user_profile/${company_id}/active_inactive`, payload)
      .then((response) => response.data);
  },
  check_employee(company_id, payload) {
    return api
      .post(`/api/v1/user_profile/${company_id}/verify_email`, payload)
      .then((response) => response.data);
  },
  edit_own_profile(company_id, payload) {
    return api
      .patch(`/api/v1/user_profile/${company_id}/me`, payload)
      .then((response) => response.data);
  },

  edit_employee_profile(company_id, employee_id, payload) {
    return api
      .patch(`/api/v1/user_profile/${company_id}/${employee_id}`, payload)
      .then((response) => response.data);
  },
  //associated with groups: admin, company-admin for assing group to edit&create employee
  employee_groups(company_id) {
    return api
      .get(`/api/v1/group/${company_id}/available`)
      .then((response) => response.data);
  },
  create_employee(company_id, payload) {
    return apiForm
      .post(`/api/v1/user_profile/${company_id}`, payload)
      .then((response) => response.data);
  },
  //associated with validation email in creation for return available email
  getVerificationEmail(admin_company_id, email) {
    return api
      .get(`/api/v1/user_profile/${admin_company_id}/check_exists?`, {
        params: {
          email: email,
        },
      })

      .then((response) => response.status);
  },
  nextPage(nextUrl) {
    return api.get(nextUrl, {}).then((response) => response.data);
  },

  inputSearchEmployee(company_id, limit, offset, id, is_member, input_search) {
    return api
      .get(`/api/v1/user_profile/${company_id}`, {
        params: {
          company_id: company_id != null ? company_id : null,
          limit,
          offset: offset,
          // id: id != null ? id : null,
          is_member: is_member != null ? is_member : null,
          input_search: input_search != null ? input_search : null,
          is_deleted: false,
          "id!": id,
        },
      })
      .then((response) => response.data);
  },
  inputSearchEmployeeInSwitch(
    company_id,
    company,
    limit,
    offset,
    id,
    is_member,
    input_search
  ) {
    return api
      .get(`/api/v1/user_profile/${company_id}`, {
        params: {
          company_id: company_id != null ? company_id : null,
          company,
          limit,
          offset: offset,
          // id: id != null ? id : null,
          is_member: is_member != null ? is_member : null,
          input_search: input_search != null ? input_search : null,
          is_deleted: false,
          "id!": id,
        },
      })
      .then((response) => response.data);
  },
  get__employees__by_modal(
    admin_company_id,
    company,
    limit,
    offset,
    id,
    is_verified,
    is_active,
    is_member
  ) {
    return api
      .get(`/api/v1/user_profile/${admin_company_id}`, {
        params: {
          admin_company_id: admin_company_id != null ? admin_company_id : null,
          company: company != null ? company : null,
          limit: limit,
          offset: offset,
          "id!": id,
          is_verified: is_verified != null ? is_verified : null,
          is_active: is_active != null ? is_active : null,
          is_member: is_member != null ? is_member : null,
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },

  get__list__bank() {
    return api
      .get(`/api/v1/bank/`, {
        params: {
          fields: "id,name",
        },
      })
      .then((response) => response.data);
  },
  get__list__health_insurance() {
    return api
      .get(`/api/v1/health_insurance/`, {
        params: {
          fields: "id,name",
        },
      })
      .then((response) => response.data);
  },
  get__list__social_security() {
    return api
      .get(`/api/v1/social_security/`, {
        params: {
          fields: "id,name",
        },
      })
      .then((response) => response.data);
  },

  get__list__option_user_profile(admin_company_id) {
    return api
      .options(`/api/v1/user_profile/${admin_company_id}`)
      .then((response) => response.data);
  },
};
