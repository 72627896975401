<template>
  <div id="login-vue" class="container__login">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "loginModule",
};
</script>

<style lang="scss">
@import "./login.scss";
.container__login{
  overflow: hidden;
}
</style>
