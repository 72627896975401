export default {
  // cards agendamientos propios
  meetingCards: (state) => {
    return state.meetingCard;
  },
  // loader para all cards
  loaderAllMeetings: (state) => {
    return state.loaderAllMeetings;
  },
  // cards agendamientos para terceros
  meetingPastCard: (state) => {
    return state.meetingPastCard;
  },
  // cards para resume de las meetings personales
  resumeMeetings: (state) => {
    return state.resumeMeetingCard;
  },
  // items secciones de consulta legal
  scheduleLegalItems: (state) => {
    return state.scheduleLegalItems;
  },
  // items secciones de consulta operaciones
  scheduleOperationlItems: (state) => {
    return state.scheduleOperationlItems;
  },
  // items para tabla agedamientos pasados
  schedulePastMeetings: (state) => {
    return state.schedulePastMeetings;
  },
  loadCategorySections: (state) => {
    return state.loadCategorySections;
  },
  schedulingSelected: (state) => {
    return state.schedulingSelected;
  },
  selectedMeeting: (state) => {
    return state.selectedMeeting;
  },

};
