export default {
  name(state) {
    return state.name;
  },
  inSwitch(state) {
    return state.inSwitch;
  },
  idMainTenant(state) {
    return state.idMainTenant;
  },
  nameMainTenant(state) {
    return state.nameMainTenant;
  },
  idInSwitch(state) {
    return state.idInSwitch;
  },

  modalRef(state) {
    return state.modalRef;
  },
};