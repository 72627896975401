<template>
  <div class="container_module">
    <div class="">
      <!-- <div class="" @click="clickDeleteOptionsOpen()"> -->
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "integratedAssistanceModule",
  computed: {
    ...mapGetters("Assistance", {
      // currentSelectAssistanceFlag: "currentSelectAssistanceFlag",
      fetchListAssistance: "fetchListAssistance",
    }),
    ...mapGetters("Login", {
      group: "group",
    }),
  },
  created() {
    // this.$store.dispatch("Assistancek/cleanCurrentAsistance");

    if (this.group == "Company-Employee") {
      this.$router.push({ name: "home" });
    }
  },

  methods: {
    // clickDeleteOptionsOpen() {
    //   let currentFlag = this.currentSelectAssistanceFlag;
    //   let assistance_data = this.fetchListAssistance;
    //   if (currentFlag) {
    //     assistance_data.map((el) => (el.active = false));
    //     this.$store.dispatch(
    //       "Assistance/setFlagForCurrentOptionsSelectedAssistance",
    //       false
    //     );
    //   }
    // },
  },
};
</script>

<style lang="scss">
.file__named {
  gap: 0.2rem;
  > .file__title {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #000032;
  }
  > .file_size {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: #9c9c9c;
  }
}
.container__mobile__icons {
  display: none;

  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: block;
  }

  > .display__icons {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;

    > div.actions {
      background: #0064ff;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }

    // > .back__wall{
    //   transform: rotate(45deg);

    // }

    > .left__arrow_wall {
      transform: rotate(180deg);
    }

    > div.icon_logo {
      padding-top: 2%;
      > svg {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background-color: #000032;
      }
    }
  }
}

.display__icons_wall__deskstop {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;

  > div.actions {
    background: #0064ff;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  > .left__arrow_wall {
    transform: rotate(180deg);
  }

  > div.icon_logo {
    padding-top: 2%;
    > svg {
      width: 43px;
      height: 43px;
      border-radius: 50%;
      background-color: #000032;
    }
  }
}
</style>
