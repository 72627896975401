<template>
  <div
    :class="
      accessSetting ? 'container__simple__label' : 'container__simple__denied'
    "
  >
    <div class="label-primary">
      {{ title }}
    </div>
    <div class="sub_title_card">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "value", "id", "accessSetting"],
};
</script>

<style lang="scss">
.container__simple__label,
.container__simple__denied {
  cursor: pointer;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 8px;
  //TODO: eliminar background en el futuro
  background-color: var(--container__simple__label);
  margin-bottom: 17px;
  padding-left: 13px;
  padding-top: 8px;
  padding-bottom: 5px;
  justify-content: center;

  > .label-primary {
    color: var(--title__simple__label) !important;
    margin-bottom: 4px;
  }

  > .sub_title_card {
    color: var(--title__simple__label) !important;
  }
}

.container__simple__denied {
  background: rgba(140, 139, 139, 0.2) !important;
  pointer-events: none;
  border: 1px solid rgba(140, 139, 139, 0.2);

  > .label-primary,
  .sub_title_card {
    opacity: 0.4;
  }
}
</style>
