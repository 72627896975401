import api from "../../../service/api";
import apiForm from "../../../service/apiFormData";

export default {
  getSingleAssistance(admin_company_id, id_assitance) {
    return api
      .get(`/api/v1/quotation/${admin_company_id}/${id_assitance}`, {
        params: {
          fields:
            "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
        },
      })
      .then((response) => response.data);
  },
  getFilesFromAsistance(admin_company_id, id_assitance) {
    return api
      .get(`/api/v1/quotation/${admin_company_id}/${id_assitance}`, {
        params: {
          fields: "file_urls",
        },
      })
      .then((response) => response.data);
  },
  //delete assisntace
  deleteAssistance(admin_company_id, id_assitance) {
    return api.delete(`/api/v1/quotation/${admin_company_id}/${id_assitance}`);
  },

  uploadFiles(id_company_requester, id_request, payload) {
    return apiForm
      .post(
        `/api/v1/chat/${id_company_requester}/${id_request}/upload`,
        payload
      )
      .then((response) => response.data);
  },
  validateuploadFiles(admin_company_id, id_request, payload) {
    return apiForm
      .post(`/api/v1/chat/${admin_company_id}/${id_request}/check`, payload)
      .then((response) => response);
  },
  //using in pagination next/prev page
  nextPage(nextUrl) {
    return api.get(nextUrl, {}).then((response) => response.data);
  },
  //for input search in list asssistance
  searchAsisistance(admin_company_id, limit, filters) {
    return api
      .get(`/api/v1/quotation/${admin_company_id}`, {
        params: {
          admin_company_id: admin_company_id != null ? admin_company_id : null,
          limit,
          filters: filters != null ? filters : null,
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },
  inputSearchAsisistance(
    admin_company_id,
    limit,
    display_id,
    title__icontains,
    service_type,
    created_at__date__range,
    requester,
    offset
  ) {
    return api
      .get(`/api/v1/quotation/${admin_company_id}`, {
        params: {
          admin_company_id: admin_company_id != null ? admin_company_id : null,
          limit,
          display_id: display_id != null ? display_id : null,
          title__icontains: title__icontains != null ? title__icontains : null,
          service_type: service_type != null ? service_type : null,
          created_at__date__range:
            created_at__date__range != null ? created_at__date__range : null,
          requester: requester != null ? requester : null,
          offset: offset,
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },

  //for select option category asssitance in view create new assistance
  getListGroupsAssistance() {
    return api
      .get(`/api/v1/service_type/`, {
        params: {
          fields: "id,title",
        },
      })
      .then((response) => response.data);
  },
  getAllAssistance(admin_company_id, fields, limit, offset) {
    return api
      .get(`/api/v1/quotation/${admin_company_id}`, {
        params: {
          fields: fields,
          limit: limit,
          offset: offset,
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },
  getAllAssistanceFilters(
    admin_company_id,
    fields,
    limit,
    offset,
    service_type,
    created_at__date__range,
    requester
  ) {
    return api
      .get(`/api/v1/quotation/${admin_company_id}`, {
        params: {
          fields: fields,
          limit: limit,
          offset: offset,
          service_type: service_type != null ? service_type : null,
          created_at__date__range:
            created_at__date__range != null ? created_at__date__range : null,
          requester: requester != null ? requester : null,
          is_deleted: false,
        },
      })
      .then((response) => response.data);
  },
  edit_assistance(admin_company_id, payload, id_request) {
    return apiForm
      .patch(`/api/v1/quotation/${admin_company_id}/${id_request}`, payload)
      .then((response) => response.data);
  },

  //create new assistance
  createAssistance(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/quotation/${admin_company_id}`, payload)
      .then((response) => response.data);
  },

  // edit assigned
  editAssignedUsers(admin_company_id, formDataFields, id_request) {
    return apiForm
      .patch(
        `/api/v1/quotation/${admin_company_id}/${id_request}`,
        formDataFields
      )
      .then((response) => response.data);
  },

  getEmployees(id_company, fields) {
    return api
      .get(`/api/v1/user_profile/${id_company}`, {
        params: {
          company: id_company,
          fields: fields,
        },
      })
      .then((response) => response.data);
  },

  getAssignedList(admin_company_id, companyRequester, group_id_in, fields) {
    return api
      .get(`/api/v1/user_profile/${admin_company_id}`, {
        params: {
          company: companyRequester,
          group_id_in: group_id_in,
          fields: fields,
          is_deleted: false,
          is_member: false
        },
      })
      .then((response) => response.data);
  },
  getAssignedListForSwitch(admin_company_id, companyRequester, group_id_in, fields) {
    return api
      .get(`/api/v1/user_profile/${admin_company_id}`, {
        params: {
          company: companyRequester,
          group_id_in: group_id_in,
          fields: fields,
          is_deleted: false,
          is_member: false

        },
      })
      .then((response) => response.data);
  },
};
