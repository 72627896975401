<template>
  <div class="container__progressbar m-top-l">
    <ul class="progressbar">
      <li :class="steps >= 0 ? 'active_progressbar' : ''">
        Se inició el proceso de Firma Digital
      </li>
      <li :class="steps >= 1 ? 'active_progressbar' : ''">
        Comenzaron a firmar el documento
      </li>
      <li :class="steps >= 2 ? 'active_progressbar' : ''">
        La mitad de los Firmantes ya firmaron
      </li>
      <li :class="steps >= 3 ? 'active_progressbar' : ''">
        Proceso Finalizado
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["steps"],

  name: "ProgressBar",
};
</script>

<style lang="scss">
.container__progressbar {
  width: 100%;
}

.progressbar li {
  list-style-type: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
}

.progressbar li::before {
  content: "";
  width: 25px;
  height: 25px;
  // border: 1px solid #9c9c9c;
  line-height: 20px;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #e4e4e4;
  z-index: 1111111 !important;
}
.progressbar li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 8px;
  background-color: #e4e4e4;
  top: 8px;
  left: -2%;
  z-index: 1;
}

.active_progressbar::before,
.active_progressbar::after {
  background-color: #619aff !important;
}
.active_progressbar::after {
  width: 100% !important;
}
</style>
