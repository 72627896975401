<template>
  <div class="container_module">
    <div
      @click="
        clickDeleteOptionsOpenEmployee(),
          clickDeleteOptionsOpenEmployeeThreeDots()
      "
    >
      <div v-if="!dataCheck && !getEditProfile">
        <TableEmployeeSkeleton />
      </div>

      <div class="container-slots container-base" v-else-if="noResult">
        <h1>no results</h1>
      </div>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import TableEmployeeSkeleton from "./components/Skeleton/TableEmployeeSkeleton.vue";

export default {
  components: {
    TableEmployeeSkeleton,
  },
  created() {
    this.bootstrap();
    this.$store.dispatch("EmployeesV2/CleanAllDropDownSelection");
    this.$store.dispatch("EmployeesV2/get_list_country");

    this.$store.dispatch("EmployeesV2/cleanTagsOfFilter");

    let company_id_region = this.company.country.id;
    this.$store.dispatch("EmployeesV2/get_list_region", company_id_region);

    this.$store.dispatch("EmployeesV2/setDropDownThreeDots", false);
    this.$store.dispatch("EmployeesV2/getListBanks");
    this.$store.dispatch("EmployeesV2/getListHealthInsurance");
    this.$store.dispatch("EmployeesV2/getListSocialSecurity");
  },
  data: function () {
    return {
      dataCheck: false,
      isStaff: false,
      noResult: false,
    };
  },

  computed: {
    ...mapGetters("Employees", {
      employee_filtered_data: "employee_filtered_data",
      employee_data: "employee_data",
    }),
    ...mapState({
      company: (state) => state.Login.company,
      staff: (state) => state.Login.is_staff,
    }),
    ...mapGetters("Login", {
      user_profile: "user_profile",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("EmployeesV2", {
      activeDropDownV2: "activeDropDownV2",
      activeDropDownVerified: "activeDropDownVerified",
      list__employee: "list__employee",
      list__employee_members: "list__employee_members",
      activeTableMembers: "activeTableMembers",
      activeDropDownThreeDots: "activeDropDownThreeDots",
    }),
    ...mapGetters("Profile", {
      getEditProfile: "getEditProfile",
    }),
  },

  methods: {
    moduleIsReady(param = false) {
      return (this.dataCheck = param);
    },
    async bootstrap() {
      this.$store.dispatch("EmployeesV2/setInactiveInputSearchUser");
      this.$store.dispatch("EmployeesV2/setInactiveInputSearchMember");
      this.$store.dispatch("EmployeesV2/get_list_companies");
      this.$store.dispatch("EmployeesV2/cleancounterStepEmployee");

      this.isStaff = this.staff;

      let user = this.user_profile;
      let id = user.id;

      // let staff = this.isStaff;
      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company.id;

      this.$store.dispatch(
        "EmployeesV2/getListOptionUserProfile",
        admin_company_id
      );

      if (this.inSwitch) {
        let company_id = admin_company_id;
        let offset = 0;

        let payload = {
          id_company: company_id,
          limit: "10",
          offset: offset,
          first_name__icontains: null,
          id: id,
        };
        this.$store.dispatch(
          "EmployeesV2/get_list_employee_InSwitchTenancy",
          payload
        );
        this.$store.dispatch(
          "EmployeesV2/get_list_members_InSwitchTenancy",
          payload
        );
        setTimeout(() => {
          this.moduleIsReady(true);
          this.noResult = false;
        }, 900);

        //TODO: logica de evaluacion en caso de que no hay empleados en la compañia (revisar con martin)
        // .then((data) => {
        //   if (data.results.length <= 0) {
        //
        //     this.$router.push({ name: "FirstEmployeeCreation" });
        //     // resolve(true);
        //   } else {
        //
        //     // TODO: set action to transform data for table.

        //     //if we dont have any employee show case for add to first employee

        //     //else render base dashboard employee
        //     setTimeout(() => {
        //       this.moduleIsReady(true);
        //       this.noResult = false;
        //     }, 900);
        //   }
        // })
        // .catch(() => {
        //   //TODO: set catch in case we have error to connect
        //   // display loader
        //   // redirect to page for handling errors
        // });
      } else {
        // TODO: set action to transform data for table.

        //if we dont have any employee show case for add to first employee

        //else render base dashboard employee

        let offset = 0;
        let company_id = admin_company_id;

        let payload = {
          company_id: company_id,
          limit: "10",
          offset: offset,
          id: id,
        };
        this.$store.dispatch("EmployeesV2/get_list_employee", payload);
        this.$store.dispatch("EmployeesV2/get_list_employee_members", payload);
        setTimeout(() => {
          this.moduleIsReady(true);
          this.noResult = false;
        }, 900);
      }
    },
    clickDeleteOptionsOpenEmployee() {
      let activeDropDownFlag = this.activeDropDownV2;
      let activeDropDownFlagVerified = this.activeDropDownVerified;
      if (activeDropDownFlag) {
        this.$store.dispatch("EmployeesV2/setDropDown", false);
      }
      if (activeDropDownFlagVerified) {
        this.$store.dispatch("EmployeesV2/setDropDownVerified", false);
      }
    },
    clickDeleteOptionsOpenEmployeeThreeDots() {
      let currentFlag = this.activeDropDownThreeDots;
      let listaEmployees = this.list__employee;
      let listaEmployeesMembers = this.list__employee_members;
      if (currentFlag) {
        listaEmployees.map((el) => (el.threedots = false));
        listaEmployeesMembers.map((el) => (el.threedots = false));

        this.$store.dispatch("EmployeesV2/setDropDownThreeDots", false);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./employees.scss";
@import "./components/dropDown/dropDown.scss";
</style>
