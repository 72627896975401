<template>
  <div class="loader_create container_edit_employee" v-if="loading">
    <loaderGeneric />
  </div>
  <div class="" v-else>
    <CancelModal ref="modal__cancel__creation__employee" size="ls" />
    <ErrorModal ref="modal__error__creation__employee" size="ls" :message="errorMesagge" />

    <div class="container_edit_employee">
      <div class="left-side-edit">
        <div class="field_edit_employee">
          <div class="label-primary">Banco</div>
          <div class="custom-select">
            <select class="input-form" v-model="user_profile.bank_id">
              <option
                v-for="(bank, index) in banks"
                :key="index"
                :value="bank.id"
              >
                {{ bank.name }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Número de cuenta</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.bank_account_number"
            />
          </div>
        </div>
      </div>
      <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

      <div class="right-side-edit">
        <div class="field_edit_employee">
          <div class="label-primary">Tipo de cuenta</div>
          <div class="custom-select">
            <select class="input-form" v-model="user_profile.bank_account_type">
              <option
                v-for="(bankType, index) in bank_account_type_option"
                :key="index"
                :value="bankType.value"
              >
                {{ bankType.display_name }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__profile__employees">
      <div class="edit__user" @click="backStep">Volver</div>
      <div class="footer__double__button">
        <div class="edit__user__secondary" @click="CancelCreationEmployee()">
          Cancelar
        </div>
        <div class="edit__user__continue" @click="nextStep">
          Guardar y continuar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CancelModal from "../modals/ModalCancelCreationEmployee.vue";
import ErrorModal from "../modals/ModalErrorCreate.vue";
import employeeServiceV2 from "../../services/employeService";
import loaderGeneric from "../../../../components/loaderGeneric/LoadingMA.vue";

export default {
  components: {
    CancelModal,
    loaderGeneric,
    ErrorModal
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployees: "stepEmployees",
      listBanks: "listBanks",
      listOptionUserProfile: "listOptionUserProfile",
      baseInformationBank: "baseInformationBank",
      baseInformationCompany: "baseInformationCompany",
      baseInformation: "baseInformation",
      typeUser: "typeUser",
      typeUserCompanyId: "typeUserCompanyId",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      user_profile_data: "user_profile",
      company_id: "company_id",
    }),
    ...mapState({
      company: (state) => state.Login.company,
      staff: (state) => state.Login.is_staff,
    }),
  },
  created() {
    this.user_profile = this.baseInformationBank;
    this.banks = this.listBanks;
    this.bank_account_type_option =
      this.listOptionUserProfile.bank_account_type;
  },
  data: () => {
    return {
      user_profile: {
        bank_id: null,
        bank_account_type: null,
        bank_account_number: null,
        is_member: null,
      },
      banks: null,
      bank_account_type_option: null,
      loading: false,
      errorMesagge: "Ocurrió un error y no pudimos añadir al miembro del equipo, vuelve a intentarlo",
      errorMesagge2: "El miembro que estás intentando añadir ya existe. ",

    };
  },
  methods: {
    CancelCreationEmployee() {
      let param = "modal__error__creation__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__error__creation__employee;
      element.open();
    },
    backStep() {
      this.$store
        .dispatch("EmployeesV2/saveBaseUserInformationBank", this.user_profile)
        .then(() => {
          let decrement = this.stepEmployees - 1;
          this.$store.dispatch("EmployeesV2/counterStepEmployee", decrement);
        });
    },
    nextStep() {
      if (this.stepEmployees >= 2) {
        this.loading = true;
        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company.id;
        // TODO: funcion de guardado then route a list employees

        let user = {
          ...this.baseInformation,
          ...this.baseInformationCompany,
          ...this.baseInformationBank,
        };
        if (this.inSwitch) {
          let id = this.idInSwitch;
          employeeServiceV2
            .create_employee(id, user)
            .then(() => {
              this.successMessage = "Usuario creado existosamente";
              this.$toasted.success(this.successMessage);
              let offset = 0;
              let company_id = admin_company_id;

              let payload = {
                id_company: company_id,
                limit: "10",
                offset: offset,
                first_name__icontains: null,
                id: id,
              };
              this.$store.dispatch(
                "EmployeesV2/get_list_employee_InSwitchTenancy",
                payload
              );
              this.$store.dispatch(
                "EmployeesV2/get_list_members_InSwitchTenancy",
                payload
              );
              setTimeout(() => {
                this.loading = false;
                this.$router.push({ name: "ListEmployeesV2" });
              }, 1000);
            })
            .catch(() => {
              this.loading = false;
            });
        } else if (this.typeUserCompanyId != null) {
          employeeServiceV2
            .create_employee(this.typeUserCompanyId, user)
            .then(() => {
              this.successMessage = "Usuario creado existosamente";
              this.$toasted.success(this.successMessage);
              let offset = 0;
              let company_id = admin_company_id;
              let payload = {
                company_id: company_id,
                limit: "10",
                offset: offset,
              };
              this.$store.dispatch("EmployeesV2/get_list_employee", payload);
              this.$store.dispatch(
                "EmployeesV2/get_list_employee_members",
                payload
              );
              setTimeout(() => {
                this.loading = false;
                this.$router.push({ name: "ListEmployeesV2" });
              }, 1000);
            })
            .catch(() => {
              this.loading = false;
            });
        } else if (this.user_profile_data.company.id) {
          employeeServiceV2
            .create_employee(this.user_profile_data.company.id, user)
            .then(() => {
              this.successMessage = "Usuario creado existosamente";
              this.$toasted.success(this.successMessage);
              let offset = 0;
              let company_id = admin_company_id;

              let payload = {
                company_id: company_id,
                limit: "10",
                offset: offset,
              };
              this.$store.dispatch("EmployeesV2/get_list_employee", payload);
              this.$store.dispatch(
                "EmployeesV2/get_list_employee_members",
                payload
              );
              setTimeout(() => {
                this.loading = false;
                this.$router.push({ name: "ListEmployeesV2" });
              }, 1000);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.loader_create {
  margin-bottom: 7rem;
}
</style>
