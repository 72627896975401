<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__digital__remove"
    ref="modal__digital__remove"
  >
    <div class="title_remove text-filter-light">
      ¿Estás seguro que deseas cancelar
      <span> {{ name_modal }} “{{ identifier_modal }} ”?</span>
    </div>
    <div class="title_remove sub-title-modal-signature .container__title">
      Si cancelas un proceso de firma digital no podrás deshacer esta acción y
      no podrás a acceder a este nuevamente
    </div>

    <div class="footer_modal">
      <div disabled="disabled" class="secondary_button" @click="cancelModal()">
        <span> Cancelar</span>
      </div>
      <div
        class="btn-vue-primary fix-btn-mac fix-width"
        @click="deleteSignature(id_document)"
      >
        <span> Si, continuar </span>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: [
    "name_modal",
    "identifier_modal",
    "id_document",
    "caseSearch",
    "size",
  ],
  computed: {
    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__digital__remove");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__digital__remove");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    async deleteSignature(d) {
      let admin_company_id = this.inSwitch
        ? this.idInSwitch
        : this.admin_company_id;

      let id_document = d;

      let caseSearch = this.caseSearch;

      let data = {
        admin_company_id,
        id_document,
      };

      await this.$store.dispatch("DigitalSignature/deleteDocument", data);
      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      let company_id = admin_company_id;

      if (caseSearch == "All") {
        let sign_status__range = "2,3";

        await this.$store.dispatch("DigitalSignature/SetSearchAll");

        await this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
          company_id,
          fields,
          sign_status__range,
        });
      } else if (caseSearch == "Pending") {
        let sign_status__range = "2,2";
        await this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
          company_id,
          fields,
          sign_status__range,
        });
      } else if (caseSearch == "Completed") {
        let sign_status__range = "3,3";
        await this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
          company_id,
          fields,
          sign_status__range,
        });
      }

      this.$bvModal.hide("modal_remove");
    },
  },
};
</script>

<style lang="scss" scoped>


.fix-width {
  width: 120px !important;
}
</style>
