<template>
  <div class="" v-if="!loaderAllMeetings">
    <div class="container-slots container__header__np">
      <div class="border__bottom margin__breadcrumb__scheduling">
        <div class="breadcrumb">
          <span class="title-primary delete_cursor_pointer">Agenda</span>
        </div>
      </div>

      <div class="title__my_scheduling">
        <div class="fix_padding_schedule">Mi agenda</div>
      </div>

      <div class="" v-if="meetingCards != null">
        <AllCardMeetingCard />
      </div>
      <div class="message__no__data text_document" v-else>
        No tienes próximos eventos agendados.
      </div>

      <div
        v-if="!activePastItems"
        class="m-top-l past_meeting_container"
        @click="getPastMeetings"
      >
        <div class="container_link">
          <div class="fix_padding_schedule">Ver agendamientos pasados</div>
          <span class="arrow__down"></span>
        </div>
      </div>
      <div class="m-top-l past_meeting_container" v-else>
        <div class="container_link" @click="ToggleHidePastMeeting">
          <div class="fix_padding_schedule">Ocultar agendamientos pasados</div>
          <span class="arrow__down active"></span>
        </div>
        <div class="fix_padding_schedule" v-if="meetingPastCard != null">
          <tablePastMeetings />
        </div>
        <div class="message__no__data text_document m-top-s" v-else>
          No tienes eventos agendados en el pasado.
        </div>
      </div>
    </div>
  </div>
  <div class="loader__signatures center-loader" v-else>
    <LoaderGeneric />
  </div>
</template>

<script>
import AllCardMeetingCard from "../../../modules/schedule/components/AllCardMeetingCard .vue";
import tablePastMeetings from "../../../modules/schedule/components/tablePastMeetings.vue";
import LoaderGeneric from "../../../components/loaderGeneric/LoadingMA.vue";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data: () => {
    return {
      activePastItems: false,
    };
  },
  components: {
    AllCardMeetingCard,
    tablePastMeetings,
    LoaderGeneric,
  },
  computed: {
    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),
    ...mapGetters("Schedule", {
      loaderAllMeetings: "loaderAllMeetings",
      meetingCards: "meetingCards",
      meetingPastCard: "meetingPastCard",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  methods: {
    ToggleHidePastMeeting() {
      this.activePastItems = !this.activePastItems;
    },

    getPastMeetings() {
      this.activePastItems = !this.activePastItems;
      const format = "YYYY-MM-DD";
      let schedule_date__lte = moment().format(format);
      let schedule_date__gte = moment().subtract(90, "days").format(format);
      let schedule_author = null;

      let admin_company_id = this.inSwitch
        ? this.idInSwitch
        : this.admin_company_id;

      let limit = 15;

      let params = {
        admin_company_id,
        schedule_date__gte,
        schedule_date__lte,
        schedule_author,
        limit,
      };

      this.$store.dispatch("Schedule/getPastMeetings", params);
    },
  },
};
</script>

<style lang="scss">
@import "../../../modules/schedule/schedule.scss";
.breadcrumb {
  > .delete_cursor_pointer {
    pointer-events: none !important;
  }
}
.fix_padding_schedule {
  padding-left: 5px;
}
.title__my_scheduling {
  color: var(--info_meeting_title_schedule) !important;
}
.container__header__np {
  background: var(--schedule_background) !important;
}
</style>
