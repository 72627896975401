var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__send__message",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : _vm.size == 'modal__test_ios'
      ? 'modal__test_ios'
      : 'modal__default',attrs:{"id":"modal__send__message"}},[_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"image_onboarding_end_message"},[_c('img',{attrs:{"src":_vm.webpImagePath,"alt":"Imagen en formato WebP"}})]),_vm._m(0),_c('div',{staticClass:"footer_modal footer__modal__cancel"},[_c('div',{staticClass:"button_back_message",attrs:{"disabled":"disabled"},on:{"click":_vm.backModal}},[_c('span',[_vm._v(" Volver")])]),_c('div',{staticClass:"fix-btn-mac button_send_message",on:{"click":_vm.gotoWall}},[_c('span',[_vm._v(" Aceptar ")])])]),_c('div',{staticClass:"footer_check_box_modal"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkNoShowOnBoardingMessage),expression:"checkNoShowOnBoardingMessage"}],attrs:{"type":"checkbox","id":"checkbox"},domProps:{"checked":Array.isArray(_vm.checkNoShowOnBoardingMessage)?_vm._i(_vm.checkNoShowOnBoardingMessage,null)>-1:(_vm.checkNoShowOnBoardingMessage)},on:{"change":[function($event){var $$a=_vm.checkNoShowOnBoardingMessage,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkNoShowOnBoardingMessage=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkNoShowOnBoardingMessage=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkNoShowOnBoardingMessage=$$c}},function($event){return _vm.checkedOnBoarding(_vm.checkNoShowOnBoardingMessage)}]}}),_c('label',{attrs:{"for":"checkbox"}},[_vm._v("No volver a mostrar este mensaje")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_onboarding_employee"},[_c('div',{staticClass:"container_onboarding_title_message"},[_c('div',{staticClass:"title_onboarding_message"},[_vm._v("Enviar mensajes")])]),_c('div',{staticClass:"text_container_onboarding_message"},[_c('div',{},[_vm._v(" Cada solicitud que hagas dentro de la plataforma, tendrá una sección para "),_c('b',[_vm._v("intercambiar mensajes")]),_vm._v(" con tu abogado asignado. "),_c('div',{},[_vm._v(" Este responderá a cada solicitud que hagas, siempre dentro de la plataforma. ")]),_c('div',{},[_c('b',[_vm._v(" Cada solicitud tendrá su sección de mensajes única ")]),_vm._v(", y "),_c('b',[_vm._v(" recibirás notificaciones ")]),_vm._v(" cada vez que tu abogado o su staff te escriba algo por aquí” ")])])])])
}]

export { render, staticRenderFns }