<template>
  <dialog :class="size == 'xl'
      ? 'modal__xl'
      : size == 'm'
        ? 'modal__m'
        : size == 's'
          ? 'modal__s'
          : size == 'xs'
            ? 'modal__xs'
            : size == 'l'
              ? 'modal__l'
              : size == 'ls'
                ? 'modal__ls'
                : size == 'fltr'
                  ? 'modal__filter'
                  : size == 'count'
                    ? 'modal__counter'
                    : size == 'modal__filter_v2'
                      ? 'modal__filter_v2'
                      : 'modal__default'
    " id="modal_confirm_asisstance" ref="modal_confirm_asisstance">
    <div class="modal__content fix_mobile_ios">
      <div class="modal__body fix_modal_content m-bottom-xl">
        <div class="header_modal">
          <CancelIcon @click="cancelModal()" />
        </div>
        <div class="container__title title-primary fix_warning_title m-bottom-xl">
          <span>
            ¡Tu solicitud de asistencia ha sido creada exitosamente!
          </span>
        </div>
        <div class="modal__user__settings modal_assistance_confirm">
          <div class="container__description container_save_assitance value-primary">
            <div class="">¿Qué viene a continuación?</div>
            <div class="">
              1. Tu solicitud está siendo revisada, y asignada a un abogado(a),
              por nuestros administradores
            </div>
            <div class="">
              2. En breve, tu abogado(a) asignado te contactará por cualquier
              clarificación adicional
            </div>
            <div class="">
              3. Recibirás tu asistencia en el plazo señalado por el abogado(a)
              asignado
            </div>
          </div>
        </div>
      </div>

      <div class="footer__modal__confirm footer_counter">
        <div class="btn-vue-primary fix-btn-mac" @click="viewNewAssistanceModal()">
          <span> Ver asistencia solicitada </span>
        </div>
        <div class="btn-vue-primary fix-btn-mac" @click="createNewAssistanceModal()">
          <span> Solicitar nueva asistencia </span>
        </div>
        <div disabled="disabled" class="secondary_button" @click="cancelModal()">
          <span> Cerrar </span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapGetters } from "vuex";
// import for icon
import CancelIcon from "../../../assets/iconos_container/cancel.svg";
// import { USER_ROLE } from "@/utils/roles";
export default {
  data: () => {
    return {};
  },
  components: {
    CancelIcon,
  },

  mounted() {
    // if (this.inSwitch && this.group == USER_ROLE.ADMIN) {
    //   // console.log("estoy en swithc ?", this.inSwitch);

    //   // var formData = new FormData();

    //   // if (this.getIdFromForceAssigment != null) {
    //   //   formData.append("requester_id", this.getIdFromForceAssigment);
    //   // }

    //   // let admin_company_id = this.idInSwitch;

    //   // let payload = {
    //   //   admin_company_id: admin_company_id,
    //   //   formDataFields: formData,
    //   //   id_request: "",
    //   // };

    //   console.log("hey TODO eliminar?");
    // }
  },
  computed: {
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),

    ...mapGetters("Login", {
      group: "group",
    }),

    ...mapGetters("Assistance", {
      getIdFromForceAssigment: "getIdFromForceAssigment",
    }),
  },

  props: ["size", "actualAssistance"],
  methods: {
    open() {
      let modal = document.querySelector("#modal_confirm_asisstance");
      modal.showModal();

      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal_confirm_asisstance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
      this.$store.dispatch("Assistance/setTrueAssitance");
    },
    viewNewAssistanceModal() {
      let assistance = this.actualAssistance;
      let payload = {
        assistance: assistance,
        editModeFromList: false,
      };
      this.$store.dispatch("Assistance/setCurrentAssistance", payload);
      this.$router.push({
        name: "ViewSingleAssistance",
        params: {
          id: assistance.id,
        },
      });
    },
    createNewAssistanceModal() {
      this.$router.push({ name: "requestAsistencia" });
    },
  },
};
</script>

<style lang="scss">
.fix_modal_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fix_warning_title {
  margin-left: 0px !important;
  font-size: 19px !important;
}

.footer__modal__confirm {
  display: flex;
  gap: 1rem;
  margin-left: 28px;
  margin-right: 28px;
  margin-top: 11px;

  >.btn-vue-primary {
    background-color: var(--button-active-confirm_edit) !important;
  }

  >.secondary_button {
    background-color: var(--button_verify_employee) !important;
  }

  @media screen and (max-width: 767px) and (min-width: 200px) {
    display: grid;
    gap: 0.7rem;
  }
}

.container_save_assitance {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fix_mobile_ios {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    height: 142% !important;
  }
}
</style>
