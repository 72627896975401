<template>
  <div class="modal__content">
    <div class="image_onboarding_third_digital">
      <ThirdImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_digital_third">
          Administra todos tus procesos de Firma digital
        </div>
      </div>
      <div class="text_container_onboarding_digital_third">
        <div class="">
          Utiliza esta sección para revisar tus documentos pendientes y ya
          completados. Cuentas con un espacio que
          <b>
            almacena todos tus archivos firmados
          </b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThirdImageOnBoarding from "./assets/thirdStepDigital.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    ThirdImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("DigitalSignature", {
      stepDigitalOnBoarding: "stepDigitalOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepDigitalOnBoarding + 1;

      this.$store.dispatch(
        "DigitalSignature/counterStepDigitalOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector(
        "#modal__onboarding__digital__signature"
      );
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.title_onboarding_digital_third {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  margin-top: 3rem;
  text-align: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    margin-top: 0rem !important;
    font-size: 30px !important;
    line-height: 39px !important;
  }
}
.image_onboarding_third_digital {
  width: 360px !important;
  height: 300px !important;
  display: flex;
  margin-top: 4rem;
  margin-left: 10.5rem !important;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 200px !important;
    height: 200px !important;
    margin-left: 4rem !important;
    margin-top: 1.5rem !important;
  }
}
.text_container_onboarding_digital_third {
  color: var(--subtitle_report) !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    margin-top: 0rem !important;
    font-size: 16px !important;

  }
}
</style>
