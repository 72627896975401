<template>
  <div class="container__view__child__element">
    <Modal
      ref="modal"
      title="Guardar cambios"
      :confirm="true"
      :cancel="true"
      size="m"
      identifier="documentRol"
    >
      <div class="modal__body">
        <div class="modal__user__regional">
          <div class="container__description value-primary">
            Se realizarán cambios en los siguientes parámetros:
          </div>
          <div class="container_settings value-primary fix__regional__color">
            DocumentTypes enlazados a este rol
          </div>
        </div>
      </div>
    </Modal>

    <span class="title__child title-container color__tertiary fix_height_mac">
      <IconBackArrow @click="returnMainView()" />
      {{ titleBreadcrumb }}
    </span>
    <div class="container__input__roles">
      <span class="title_bold_700 color__tertiary">Selecciona un rol</span>
      <div class="custom-select">
        <select
          class="input-form input__form__fix"
          name=""
          id=""
          @change="selectedGroup($event)"
        >
          <option selected hidden disabled>Selecciona un rol</option>
          <option :value="el.id" v-for="(el, index) in list_group" :key="index">
            {{ el.group_name }}
          </option>
        </select>
        <span class="custom-arrow-b"></span>
      </div>
    </div>
    <div name="fade" v-if="flagDataContainerDocument">
      <div class="container__service__roles">
        <span v-if="listDocumentTypePermByRol != null"
          >DocumentTypes enlazados a este rol</span
        >
        <div class="box__document__type">
          <div class="container__elements__service">
            <!-- elemento iterable en base a servicio -->
            <div
              v-for="(el, index) in listDocumentTypePermByRol"
              :key="index"
              class="element__document__role"
            >
              <!-- @change="selectDocument($event, document_type.id) -->
              <input
                class="document-input"
                type="checkbox"
                :id="el.id"
                :checked="el.has_perm"
                @click="selectedValidChanges($event, el.id)"
              />
              {{ el.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="container__settings__regional">
        <div class="container__footer__settings">
          <div
            v-if="documentTypeTypePermByRolValues != null"
            :class="
              documentTypeTypePermByRolValues.length > 0
                ? 'btn-vue-primary fix-btn-mac'
                : 'desactive_draf_settings fix-btn-mac'
            "
            @click="show_modal_save_confirm()"
          >
            <!-- desactive_set_draf -->
            <span> Confirmar </span>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-else-if="flagSkeleton">
      <div class="body_skeleton_settings box__document__type__skeleton">
        <div class="title_skeleton_settings">
          <VueSkeletonLoader
            type="rect"
            :width="200"
            :height="20"
            animation="fade"
          />
        </div>
        <div class="container_skeleton_settings">
          <div class="options_skeleton_settings m-top-m">
            <VueSkeletonLoader
              type="rect"
              :width="23"
              :height="20"
              animation="fade"
            />
            <div class="options_skeleton_xl">
              <VueSkeletonLoader
                type="rect"
                :height="20"
                :width="300"
                animation="fade"
              />
            </div>
          </div>
          <div class="options_skeleton_settings">
            <VueSkeletonLoader
              type="rect"
              :width="23"
              :height="20"
              animation="fade"
            />
            <div class="options_skeleton_s">
              <VueSkeletonLoader
                type="rect"
                :height="20"
                :width="200"
                animation="fade"
              />
            </div>
          </div>
          <div class="options_skeleton_settings">
            <VueSkeletonLoader
              type="rect"
              :width="23"
              :height="20"
              animation="fade"
            />
            <div class="options_skeleton_l">
              <VueSkeletonLoader
                type="rect"
                :height="20"
                :width="250"
                animation="fade"
              />
            </div>
          </div>
          <div class="options_skeleton_settings">
            <VueSkeletonLoader
              type="rect"
              :width="23"
              :height="20"
              animation="fade"
            />
            <div class="options_skeleton_m">
              <VueSkeletonLoader
                type="rect"
                :height="20"
                :width="220"
                animation="fade"
              />
            </div>
          </div>
          <div class="options_skeleton_settings">
            <VueSkeletonLoader
              type="rect"
              :width="23"
              :height="20"
              animation="fade"
            />
            <div class="options_skeleton_xxl">
              <VueSkeletonLoader
                type="rect"
                :height="20"
                :width="350"
                animation="fade"
              />
            </div>
          </div>
          <div class="options_skeleton_settings">
            <VueSkeletonLoader
              type="rect"
              :width="23"
              :height="20"
              animation="fade"
            />
            <div class="options_skeleton_l">
              <VueSkeletonLoader
                type="rect"
                :height="20"
                :width="250"
                animation="fade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-else></div>
  </div>
</template>
<script>
import IconBackArrow from "../../assets/back.svg";
import VueSkeletonLoader from "skeleton-loader-vue";
import Modal from "@/components/modal/Modal.vue";
import { mapGetters } from "vuex";

export default {
  props: ["titleBreadcrumb", "titleService"],

  components: {
    IconBackArrow,
    Modal,
    VueSkeletonLoader,
  },

  created() {
    this.$store.dispatch("SettingsApp/getGroups", this.admin_company_id);
    this.$store.dispatch("SettingsApp/clearElementDocumentPermByRol");
  },
  data: () => {
    return {
      listDocumentTypePermByRol: null,
      flagDataContainerDocument: false,
      flagSkeleton: false,
    };
  },
  computed: {
    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),
    ...mapGetters("SettingsApp", {
      list_group: "listGroupSettingsRegional",
      defaultDocumentTypePermByRol: "defaultDocumentTypePermByRol",
      documentTypeTypePermByRolValues: "documentTypeTypePermByRolValues",
    }),
  },

  methods: {
    selectedGroup(e) {
      this.$store.dispatch("SettingsApp/clearElementDocumentPermByRol");
      this.flagDataContainerDocument = false;
      let employee_group_id = e.target.value;
      let admin_company_id = this.admin_company_id;
      //TODO: get country default value selecte in main view

      let params = {
        admin_company_id: admin_company_id,
        employee_group_id: employee_group_id,
      };

      this.$store
        .dispatch("SettingsApp/listDocumentTypePermByRol", params)
        .then(() => {
          this.flagSkeleton = true;
        })
        .then(() => {
          this.listDocumentTypePermByRol = this.defaultDocumentTypePermByRol;
          setTimeout(() => {
            this.flagDataContainerDocument = true;
          }, 1000);
        });
    },
    selectedValidChanges(event, document_type_id) {
      if (event.target.checked) {
        let data = {
          id: document_type_id,
          has_perm: true,
        };

        this.$store.dispatch(
          "SettingsApp/addDocumentTypePermByRolChanges",
          data
        );
      } else if (!event.target.checked) {
        let data = {
          id: document_type_id,
          has_perm: false,
        };
        this.$store.dispatch(
          "SettingsApp/addDocumentTypePermByRolChanges",
          data
        );
      }
    },
    returnMainView() {
      this.$store.dispatch("SettingsApp/setViewRegionSettings", 0);
    },
    show_modal_save_confirm() {
      let element = this.$refs.modal;
      element.open();
    },
  },
};
</script>

<style lang="scss">
.title_skeleton_settings {
  padding-bottom: 10px;
  > .loader {
    @media screen and (max-width: 500px) and (min-width: 200px) {
      height: 15px !important;
      width: 200px !important;
    }
  }
}
.container_skeleton_settings {
  padding-left: 0.7rem;
  height: 204px;
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 8px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.title_skeleton_settings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 5px;
  padding-right: 17px;
}

.body_skeleton_settings {
  padding-top: 1.5rem;
  > .loader {
    width: 100% !important;
  }
}
.options_skeleton_settings {
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  height: 30px;
  > .loader {
    @media screen and (max-width: 500px) and (min-width: 200px) {
      height: 15px !important;
      width: 15px !important;
    }
  }
  > .options_skeleton_l {
    > .loader {
      @media screen and (max-width: 500px) and (min-width: 200px) {
        height: 15px !important;
        width: 180px !important;
      }
    }
  }
  > .options_skeleton_xxl {
    > .loader {
      @media screen and (max-width: 500px) and (min-width: 200px) {
        height: 15px !important;
        width: 250px !important;
      }
    }
  }
  > .options_skeleton_xl {
    > .loader {
      @media screen and (max-width: 500px) and (min-width: 200px) {
        height: 15px !important;
        width: 200px !important;
      }
    }
  }
  > .options_skeleton_m {
    > .loader {
      @media screen and (max-width: 500px) and (min-width: 200px) {
        height: 15px !important;
        width: 150px !important;
      }
    }
  }
  > .options_skeleton_s {
    > .loader {
      @media screen and (max-width: 500px) and (min-width: 200px) {
        height: 15px !important;
        width: 100px !important;
      }
    }
  }
}
</style>
