import * as types from "./mutations-types";

export default {

  [types.SET_PROFILE_EDIT](state) {
    state.setProfileState = true;
  },
  [types.SET_PROFILE_EDIT_FALSE](state) {
    state.setProfileState = false;
  },

};
