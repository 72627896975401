var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__error__creation__employee",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : 'modal__default',attrs:{"id":"modal__error__creation__employee"}},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_c('div',{staticClass:"modal__content__cancel__creation"},[_c('div',{staticClass:"image_error_create"},[_c('ErrorImage')],1),_c('div',{staticClass:"title_remove_creation text_creation_cancel text_error_creation_employee"},[_vm._v(" "+_vm._s(this.message)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }