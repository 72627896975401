<template>
  <div class="center-loader" v-if="loading">
    <loaderGeneric />
  </div>

  <div class="container-slots container-base" v-else>
    <ModalChangeUser ref="modal__change__user__member" size="ls" />
    <div class="container-base-title">
      <div class="title-container">
        <span class="icon__breadcrumb">
          <router-link :to="{ name: 'ListEmployeesV2' }">
            {{ getCurrentEmployeeSelectedFetch.isActiveMember }}
          </router-link>
          <RightArrow />
        </span>
        Perfil empleado
      </div>
      <div class="">
        <!-- -->
      </div>
    </div>

    <h2 class="titles_view_employee first_title_view_employee">
      Datos personales
    </h2>
    <div class="container-profile">
      <div class="left-side">
        <div class="item-field">
          <div class="label-primary">Nombre</div>
          <div class="value-primary fix__empty__field">
            {{ getCurrentEmployeeSelected.first_name }}
            {{ getCurrentEmployeeSelected.middle_name }}
          </div>
        </div>

        <div class="line-primary-v2"></div>
        <div class="item-field">
          <div class="label-primary">Rut</div>
          <div class="value-primary fix__empty__field">
            {{ getCurrentEmployeeSelected.nin }}
          </div>
        </div>
        <div class="line-primary-v2"></div>

        <div class="item-field">
          <div class="label-primary">Fecha de nacimiento</div>
          <div class="value-primary fix__empty__field">
            {{
              getCurrentEmployeeSelectedFetch.birth_date != null
              ? getCurrentEmployeeSelectedFetch.birth_date
              : "Sin registro"
            }}
          </div>
        </div>
        <div class="line-primary-v2"></div>

        <div class="item-field">
          <div class="label-primary">Estado civil</div>
          <div class="value-primary fix__empty__field">
            {{
              getCurrentEmployeeSelectedFetch.marital_status_name.length > 0
              ? getCurrentEmployeeSelectedFetch.marital_status_name
              : "Sin registro"
            }}
          </div>
        </div>
        <div class="line-primary-v2"></div>

        <div class="item-field">
          <div class="label-primary">Telefono</div>
          <div class="value-primary fix__empty__field">
            {{
              getCurrentEmployeeSelectedFetch.phone != null
              ? getCurrentEmployeeSelectedFetch.phone
              : "Sin registro"
            }}
          </div>
        </div>
        <div class="line-primary-v2"></div>
        <div class="item-field">
          <div class="label-primary">Email</div>
          <div class="value-primary fix__empty__field">
            {{ getCurrentEmployeeSelected.user.email }}
          </div>
        </div>
        <div class="line-primary-v2"></div>
      </div>
      <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

      <div class="right-side">
        <div class="item-field">
          <div class="label-primary">Apellidos</div>
          <div class="value-primary fix__empty__field">
            {{
              getCurrentEmployeeSelected.surname.length > 0
              ? getCurrentEmployeeSelected.surname
              : "Sin registro"
            }}
          </div>
        </div>
        <div class="line-primary-v2"></div>
        <div class="item-field">
          <div class="label-primary">Nacionalidad</div>
          <div class="value-primary fix__empty__field">
            {{ getCurrentEmployeeSelectedFetch.nationality.demonym }}
          </div>
        </div>
        <div class="line-primary-v2"></div>
        <div class="item-field">
          <div class="label-primary">Región</div>
          <div class="value-primary fix__empty__field">
            {{
              getCurrentEmployeeSelectedFetch.state != null
              ? getCurrentEmployeeSelectedFetch.state
              : "Sin registro"
            }}
          </div>
        </div>

        <div class="line-primary-v2"></div>
        <div class="item-field">
          <div class="label-primary">Comuna</div>
          <div class="value-primary fix__empty__field">
            {{
              getCurrentEmployeeSelectedFetch.city != null
              ? getCurrentEmployeeSelectedFetch.city
              : "Sin registro"
            }}
          </div>
        </div>
        <div class="line-primary-v2"></div>

        <div class="item-field">
          <div class="label-primary">Dirección Particular</div>
          <div class="value-primary fix__empty__field">
            {{
              getCurrentEmployeeSelectedFetch.address != null
              ? getCurrentEmployeeSelectedFetch.address
              : "Sin registro"
            }}
          </div>
        </div>
        <div class="line-primary-v2"></div>
        <div class="item-field-empty">
          <div class="label-primary"></div>
          <div class="value-primary fix__empty__field"></div>
        </div>
        <div class="line-primary-v2"></div>
      </div>
    </div>

    <div class="" v-if="group != 'Company-Executive'">
      <h2 class="titles_view_employee">Datos Empresa</h2>
      <div class="container-profile">
        <div class="left-side">
          <div class="item-field">
            <div class="label-primary">Fecha de ingreso</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.company_ingress_date != null
                ? getCurrentEmployeeSelectedFetch.company_ingress_date
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>
          <div class="item-field">
            <div class="label-primary">Profesión u oficio</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.occupation != null
                ? getCurrentEmployeeSelectedFetch.occupation
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>

          <div class="item-field">
            <div class="label-primary">Formato de trabajo</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.job_type_display != null
                ? getCurrentEmployeeSelectedFetch.job_type_display
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>

          <div class="item-field">
            <div class="label-primary">Rol en la app</div>
            <div class="value-primary fix__empty__field">
              {{ group_display }}
            </div>
          </div>
          <div class="line-primary-v2"></div>
          <div class="item-field">
            <div class="label-primary">Representante legal</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.is_legal_representative_display !=
                null
                ? getCurrentEmployeeSelectedFetch.is_legal_representative_display
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>
          <div class="item-field">
            <div class="label-primary">Nombre del cargo</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.position != null
                ? getCurrentEmployeeSelectedFetch.position
                : "Sin registro"
              }}
            </div>
          </div>
        </div>
        <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

        <div class="right-side">
          <div class="item-field">
            <div class="label-primary">Tipo de contrato</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.contract_type_display != null
                ? getCurrentEmployeeSelectedFetch.contract_type_display
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>
          <div class="item-field">
            <div class="label-primary">Remuneración bruta</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.salary != null
                ? getCurrentEmployeeSelectedFetch.salary
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>
          <div class="item-field">
            <div class="label-primary">Jornada de trabajo</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.working_hours_display != null
                ? getCurrentEmployeeSelectedFetch.working_hours_display
                : "Sin registro"
              }}
            </div>
          </div>

          <div class="line-primary-v2"></div>
          <div class="item-field">
            <div class="label-primary">Previsión de salud</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.health_insurance_display != null
                ? getCurrentEmployeeSelectedFetch.health_insurance_display
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>

          <div class="item-field">
            <div class="label-primary">AFP</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.social_security_display != null
                ? getCurrentEmployeeSelectedFetch.social_security_display
                : "Sin registro"
              }}
            </div>
          </div>

          <div class="line-primary-v2"></div>
          <div class="item-field item_field_text_area">
            <div class="label-primary">Responsabilidades al cargo</div>
            <div class="value-primary fix__empty__field view_textarea_employee">
              {{
                getCurrentEmployeeSelectedFetch.position_responsabilities !=
                null
                ? getCurrentEmployeeSelectedFetch.position_responsabilities
                : "Sin registro"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="line-primary-v2"></div>
      <h2 class="titles_view_employee">Datos Bancarios</h2>
      <div class="container-profile">
        <div class="left-side">
          <div class="item-field">
            <div class="label-primary">Banco</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.bank_display != null
                ? getCurrentEmployeeSelectedFetch.bank_display
                : "Sin registro"
              }}
            </div>
          </div>

          <div class="line-primary-v2"></div>
          <div class="item-field">
            <div class="label-primary">Tipo de cuenta</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.bank_account_type_display !=
                null
                ? getCurrentEmployeeSelectedFetch.bank_account_type_display
                : "Sin registro"
              }}
            </div>
          </div>
          <div class="line-primary-v2"></div>
        </div>
        <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

        <div class="right-side">
          <div class="item-field">
            <div class="label-primary">Número de cuenta</div>
            <div class="value-primary fix__empty__field">
              {{
                getCurrentEmployeeSelectedFetch.bank_account_number != null
                ? getCurrentEmployeeSelectedFetch.bank_account_number
                : "Sin registro"
              }}
            </div>
          </div>

          <div class="line-primary-v2"></div>
          <div class="item-field-empty">
            <div class="label-primary"></div>
            <div class="value-primary fix__empty__field"></div>
          </div>
          <div class="line-primary-v2"></div>
        </div>
      </div>
    </div>

    <div class="footer__profile__employees" v-if="group != 'Company-Executive'">
      <div class="edit__user" @click="goEditEmployee()">Editar datos</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RightArrow from "@/assets/iconos_container/right.svg";
import employeeServiceV2 from "../services/employeService";
import loaderGeneric from "../../../components/loaderGeneric/LoadingMA.vue";
import ModalChangeUser from "../components/modals/ModalChangeUserMember.vue";

export default {
  components: {
    RightArrow,
    loaderGeneric,
    ModalChangeUser,
  },
  created() {
    this.groupList();
  },
  data: () => {
    return {
      group_list: [],
      group_display: "",
      loading: true,
    };
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      getCurrentEmployeeSelected: "getCurrentEmployeeSelected",
      getCurrentEmployeeSelectedFetch: "getCurrentEmployeeSelectedFetch",
      listOptionUserProfile: "listOptionUserProfile",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      group: "group",
    }),
  },
  methods: {
    goEditEmployee() {
      this.$store.dispatch("Profile/setPorfileEditNull");
      this.$router.push({
        name: "EditEmployeeV2",
      });
    },
    groupList() {
      if (this.inSwitch) {
        employeeServiceV2
          .employee_groups(this.idInSwitch)
          .then((data) => {
            let dataList = data.results;

            dataList.map((item) => {
              let ejecutivo =
                item.group_name == "Lawyer"
                  ? "Abogado"
                  : item.group_name == "Admin"
                    ? "Administrador"
                    : item.group_name == "Agent"
                      ? "Agente"
                      : item.group_name == "Company-Executive"
                        ? "Ejecutivo"
                        : item.group_name == "Company-Admin"
                          ? "Administrador"
                          : item.group_name == "Company-Employee"
                            ? "Empleado"
                            : item.group_name == "Company-Entel"
                              ? "Usuario Entel"
                              : "";

              item.fantasy = ejecutivo;
            });
            this.group_list = data.results;
            this.setGroup();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let currentUserIdCompany = this.getCurrentEmployeeSelected.company.id;
        employeeServiceV2
          .employee_groups(currentUserIdCompany)
          .then((data) => {
            let dataList = data.results;

            dataList.map((item) => {
              let ejecutivo =
                item.group_name == "Lawyer"
                  ? "Abogado"
                  : item.group_name == "Admin"
                    ? "Administrador"
                    : item.group_name == "Agent"
                      ? "Agente"
                      : item.group_name == "Company-Executive"
                        ? "Ejecutivo"
                        : item.group_name == "Company-Admin"
                          ? "Administrador"
                          : item.group_name == "Company-Employee"
                            ? "Empleado"
                            : item.group_name == "Company-Entel"
                              ? "Usuario Entel"
                              : "";

              item.fantasy = ejecutivo;
            });
            this.group_list = data.results;
            this.setGroup();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    setGroup() {
      this.group_list.filter((el) => {
        if (el.group_name === this.getCurrentEmployeeSelectedFetch.group) {
          this.group_display = el.fantasy;
        }
      });
      this.loading = false;
    },
    OpenChangeUserMember() {
      let param = "modal__change__user__member";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__change__user__member;
      element.open();
    },
  },
};
</script>

<style lang="scss">
.container-slots {
  padding-bottom: 1rem !important;
}

.titles_view_employee {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #0064ff;
}

.item-field-empty {
  padding-top: 35px;
  padding-bottom: 18px;
  display: flex;
  flex-wrap: nowrap;
  gap: 5rem;
  justify-content: flex-start;
  white-space: nowrap;

  @media screen and (max-width: 650px) and (min-width: 200px) {
    gap: 1rem;
  }

  >.label-primary {
    white-space: nowrap;
    width: 120px;
  }
}

.first_title_view_employee {
  margin-top: 29px;
}</style>
