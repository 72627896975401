import * as types from "./mutations-types";
//TODO: use types

export default {
  [types.SET_DATA_CARDS](state, data) {
    state.dataCards = data;
  },

  [types.SET_INDICADORES](state, data) {
    state.indicadores = data;
  },
  [types.SET_FLAG_FOR_LOADER_CARDS](state, data) {
    state.flagLoaderCards = data;
  },
  [types.SET_FLAG_FOR_LOADER_ECONOMICS](state, data) {
    state.flagLoaderEconomic = data;
  },
  [types.SET_FLAG_FOR_LOADER_LINKS](state, data) {
    state.flagLoaderLinks = data;
  },
  [types.SET_FLAG_FOR_LOADER_UTILITIES](state, data) {
    state.flagLoaderUtilities = data;
  },
  [types.SET_FLAG_FOR_LOADER_DOCUMENT](state, data) {
    state.flagLoaderDocument = data;
  },

  [types.CLEAN_COUNTER__STEP__HOME_ONBOARDING](state) {
    state.stepsHomeOnBoarding = 1;
  },
  [types.COUNTER__STEP__HOME_ONBOARDING](state, payload) {
    state.stepsHomeOnBoarding = payload;
  },
};
