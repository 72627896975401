var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal_warning_save",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : _vm.size == 'modal__counter_v2'
      ? 'modal__counter_v2'
      : 'modal__default',attrs:{"id":"modal_warning_save"}},[_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"modal__body fix_modal_content"},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_vm._m(0),_c('div',{staticClass:"modal__user__settings"},[_c('div',{staticClass:"container__description value-primary"},[_vm._v(" Hay cambios sin guardar en los siguientes parámetros: ")]),(_vm.validChangesDarkMode)?_c('div',{staticClass:"container__description"},[_vm._v(" "+_vm._s(_vm.titleDarkmode)+" - "+_vm._s(_vm.userDarkModeInstanceChange.value)+" ")]):_vm._e()])]),_c('div',{staticClass:"footer_modal footer_counter"},[(_vm.confirm)?_c('div',{staticClass:"btn-vue-primary fix-btn-mac",on:{"click":function($event){return _vm.confirmModalChanges(_vm.identifier)}}},[_c('span',[_vm._v(" Confirmar ")])]):_vm._e(),(_vm.cancel)?_c('div',{staticClass:"secondary_button",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Cancelar ")])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container__title title-primary fix_warning_title"},[_c('span',[_vm._v(" Guardar cambios ")])])
}]

export { render, staticRenderFns }