export const ProfileRoutes = [
  {
    path: "/users",
    component: () =>
      import(/*webpackChunkName: "ProfileModule" */ "./ProfileModule"),

    children: [
      {
        path: "/",
        name: "user",
        component: () =>
          import(/*webpackChunkName: "Profile" */ "./pages/ProfilePage"),
      },
      {
        path: "edit",
        name: "user.edit",
        component: () =>
          import(
            /*webpackChunkName: "EditProfile" */ "./pages/EditUserProfile"
          ),
      },
    ],
  },
];
