import api from "../../../service/api";
import apiForm from "../../../service/apiFormData";

export default {
  delete_signature(admin_company_id, id_document) {
    return api.delete(
      `/api/v1/document/signature/${admin_company_id}/${id_document}`
    );
  },
  searchTags(admin_company_id, sign_status__range, filters, limit) {
    return api
      .get(`/api/v1/document/${admin_company_id}`, {
        params: {
          admin_company_id: admin_company_id != null ? admin_company_id : null,
          sign_status__range:
            sign_status__range != null ? sign_status__range : null,
          filters: filters != null ? filters : null,
          limit,
        },
      })
      .then((response) => response.data);
  },
  getSingleDocument(admin_company_id, id_document) {
    return api
      .get(`/api/v1/document/${admin_company_id}/${id_document}`, {
        params: {
          fields: "sign_file_url",
        },
      })
      .then((response) => response.data);
  },
  document_digital_signature_for_new_request(
    company_id,
    fields = null,
    sign_status__range = null,
    limit = null
  ) {
    return api
      .get(`/api/v1/document/${company_id}`, {
        params: {
          fields: fields != null ? fields : null,
          sign_status__range:
            sign_status__range != null ? sign_status__range : null,
          limit: limit != null ? limit : null,
        },
      })
      .then((response) => response.data);
  },
  document_digital_signature(
    company_id,
    fields = null,
    sign_status = null,
    sign_status__range = null,
    sign_init_date__year = null,
    sign_init_date__month = null,
    limit = null,
    offset = null,
    created_at__year,
    created_at__month,
    created_at__day,
    document_type__categories__name__icontains,
    document_type__categories__name__icontains_filter
  ) {
    return api
      .get(`/api/v1/document/${company_id}`, {
        params: {
          fields: fields != null ? fields : null,
          sign_status: sign_status != null ? sign_status : null,

          sign_status__range:
            sign_status__range != null ? sign_status__range : null,
          sign_init_date__year:
            sign_init_date__year != null ? sign_init_date__year : null,
          sign_init_date__month:
            sign_init_date__month != null ? sign_init_date__month : null,
          limit: limit != null ? limit : null,
          offset: offset != null ? offset : null,

          created_at__year: created_at__year != null ? created_at__year : null,
          created_at__month:
            created_at__month != null ? created_at__month : null,
          created_at__day: created_at__day != null ? created_at__day : null,
          "document_type__categories__name__icontains!":
            document_type__categories__name__icontains != null
              ? document_type__categories__name__icontains
              : null,
          document_type__categories__name__icontains:
            document_type__categories__name__icontains_filter != null
              ? document_type__categories__name__icontains_filter
              : null,
        },
      })
      .then((response) => response.data);
  },
  drafDocument_digital_signature(company_id, fields = null, sign_status) {
    return api
      .get(`/api/v1/document/${company_id}`, {
        params: {
          fields: fields != null ? fields : null,
          sign_status: sign_status != null ? sign_status : null,
        },
      })
      .then((response) => response.data);
  },
  nextPage(nextUrl) {
    return api.get(nextUrl, {}).then((response) => response.data);
  },

  list_document_type_category() {
    return api
      .get(`/api/v1/document_category/`, {
        params: {
          parent__isnull: false,
          "name__icontains!": "Documentos Personales",
        },
      })
      .then((response) => response.data);
  },

  sendNewDocumentToSing(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/document/signature/${admin_company_id}`, payload)
      .then((response) => response.data);
  },
};
