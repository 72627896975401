<template>
  <Transition v-if="GetFlagLoaderCards" name="fade">
    <div class="card-single base-card">
      <div class="card-group">
        <h1>
          {{ title }}
          <br />
          <span>(últimos 6 meses)</span>
        </h1>

        <div class="value-primary-grap">
          {{ total }}
        </div>
      </div>

      <div class="container-data-request">
        <div
          class="container-months"
          v-for="(data, index) in Request"
          :key="index"
        >
          <div class="item">
            <div class="container-meter">
              <div class="value-grap">({{ data.value }})</div>
              <meter
                min="0"
                :max="maxValue"
                optimum="100"
                :value="data.value"
              ></meter>
            </div>
            <div class="month">
              {{ data.month }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
  <div v-else class="card-single base-card">
    <div class="title_skeleton">
      <VueSkeletonLoader
        type="rect"
        :width="170"
        :height="15"
        animation="fade"
      />
    </div>
    <div class="container_fix">
      <div class="">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="15"
          animation="fade"
        />
      </div>

      <div class="test">
        <VueSkeletonLoader
          type="circle"
          :width="50"
          :height="50"
          animation="fade"
        />
      </div>
    </div>
    <div class="body_skeleton_graphic">
      <VueSkeletonLoader
        type="rect"
        :width="10"
        :height="140"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="10"
        :height="140"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="10"
        :height="140"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="10"
        :height="140"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="10"
        :height="140"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="10"
        :height="140"
        animation="fade"
      />
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
import { mapGetters } from "vuex";
export default {
  props: ["title", "Request"],
  components: {
    VueSkeletonLoader,
  },

  data: function () {
    return {
      maxValue: "",
      total: 0,
      flagLoadDataCards: false,
    };
  },
  computed: {
    ...mapGetters("Home", {
      GetFlagLoaderCards: "GetFlagLoaderCards",
    }),
  },

  created() {

  },

  watch: {
    GetFlagLoaderCards(old) {
      if (old) {
        this.flagLoadDataCards = old;
        const maxValueFromArray = Math.max(
          ...this.Request.map((o) => o.valueNumber),
          0
        );

        this.Request.forEach((element) => {
          this.total += element.valueNumber;
        });

        this.maxValue = maxValueFromArray;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./cardFeatures.scss";
@import "../sharedComponents.scss";
</style>
