<template>
  <div class="modal__content">
    <div class="image_onboarding_digital_second">
      <SecondImageOnBoarding />
    </div>
    <div class="container_digital_second_text">
      <div class="container_onboarding_title">
        <div class="title_onboarding_second">
          ¡Es fácil y rápido!
        </div>
      </div>
      <div class="text_digital_second">
        <div class="">
          <div class="">
            1. Selecciona un archivo de la plataforma o carga uno desde tu
            computador
          </div>
          <div class="">
            2. Ingresa a los firmantes implicados
          </div>
          <div class="">
            3. Presiona "Enviar" para aceptar el proceso y que la plataforma
            proceda a invitar -mediante correo electrónico- a los firmantes
          </div>
        </div>
        <div class="">
          ¡Y listo! Tu proceso de firma se almacenará en la aplicación y en
          cuanto los firmantes hayan realizado lo que se les indicó en el
          correo, se te avisará que el proceso se completó con éxito.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecondImageOnBoarding from "./assets/secondStepDigital.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    SecondImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("DigitalSignature", {
      stepDigitalOnBoarding: "stepDigitalOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepDigitalOnBoarding + 1;

      this.$store.dispatch(
        "DigitalSignature/counterStepDigitalOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector(
        "#modal__onboarding__digital__signature"
      );
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.image_second_fix_assistance {
  margin-top: 2.5rem !important;
}
.content_onboarding_second_assistance {
  gap: 1rem !important;
}
.image_onboarding_digital_second {
  width: 379px !important;
  height: 250px !important;
  display: flex;
  margin-top: 4rem;
  margin-left: 9.5rem !important;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 220px !important;
    height: 200px !important;
    margin-left: 2.5rem !important;
    margin-top: 0.8rem !important;
  }
}
.container_digital_second_text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    gap: 0.1rem !important;
  }
}
.text_digital_second {
  color: var(--subtitle_report) !important;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    margin-top: 0rem !important;
    font-size: 12px !important;
    gap: 0.2rem;
  }
}
.title_onboarding_second {
  margin-top: 2.7rem;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    margin-top: 0rem !important;
    font-size: 30px !important;
  }
}
</style>
