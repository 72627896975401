<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    id="modal__error__creation__employee"
    ref="modal__error__creation__employee"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="modal__content__cancel__creation">
      <div class="image_error_create">
        <ErrorImage />
      </div>
      <div
        class="title_remove_creation text_creation_cancel text_error_creation_employee"
      >
        {{ this.message }}
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/assets/iconos_container/cancel.svg";
import ErrorImage from "../../assets/material-symbols_error-circle-rounded.svg";
export default {
  props: ["size", "message"],
  components: {
    CancelIcon,
    ErrorImage,
  },
  data() {
    return {};
  },

  computed: {},

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__error__creation__employee");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__error__creation__employee");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">

</style>
