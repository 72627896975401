<template>
  <div class="modal__content">
    <div class="image_onboarding_employee image_onboarding_second_assistance  ">
      <SecondImageOnBoarding />
    </div>
    <div
      class="content_onboarding_employee_first_step content_onboarding_second_assistance"
    >
      <div class="container_onboarding_title">
        <div class="title_onboarding_employee">
          Solicita lo que necesites
        </div>
      </div>
      <div class="text_container_onboarding_second_assistance">
        <div class="">
          <b>
            En esta sección quisimos simplificar el ingreso de tus solicitudes:
          </b>
        </div>
        <div class="">
          1. Solo debes asignarle un nombre
        </div>
        <div class="">
          2. Seleccionar una de las categorías legales
        </div>
        <div class="">
          3. Y dar una breve descripción de lo que se requiere. También puedes
          adjuntar documentos si es necesario
        </div>
        <div class="">
          Y listo! Tu solicitud
          <b>
            asignará inmediatamente a un abogado se asignará inmediatamente a un
            abogado especialista en la materia
          </b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecondImageOnBoarding from "./assets/secondStepAssistance.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    SecondImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Assistance", {
      stepAssistanceOnBoarding: "stepAssistanceOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepAssistanceOnBoarding + 1;

      this.$store.dispatch(
        "Assistance/counterStepAssistanceOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.image_onboarding_second_assistance {
  width: 339px !important;
  height: 300px !important;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 11.3rem !important;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 220px !important;
    height: 200px !important;
    margin-left: 2.5rem !important;
    margin-top: 0.8rem !important;
  }
}

.content_onboarding_second_assistance {
  gap: 1rem !important;
}

.text_container_onboarding_second_assistance {
  color: var(--subtitle_report) !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    font-size: 14px;
     line-height: 20.5px;
  }
}
</style>
