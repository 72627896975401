export default {
  dataCards: {},
  indicadores: {},
  flagLoaderCards: false,
  flagLoaderEconomic: false,
  flagLoaderLinks: false,
  flagLoaderUtilities: false,
  flagLoaderDocument: false,

  stepsHomeOnBoarding: 1,
};
