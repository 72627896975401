<template>
  <div>
    <b-container fluid>
      <slot />
    </b-container>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  created() {},
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
  watch: {},
};
</script>

<style>
.container-fluid {
  padding: 0px;
}
</style>
