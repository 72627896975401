<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'onboarding'
        ? 'modal__onboarding'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    id="modal__onboarding__digital__signature"
    ref="modal__onboarding__digital__signature"
  >
    <div class="">
      <div class="">
        <Transition v-if="stepDigitalOnBoarding == 1" name="fade">
          <OnBoardingFirstStepDigitalSignature />
        </Transition>
        <Transition v-if="stepDigitalOnBoarding == 2" name="fade">
          <OnBoardingSecondStepDigitalSignature />
        </Transition>
        <Transition v-if="stepDigitalOnBoarding == 3" name="fade">
          <OnBoardingThirdStepDigitalSignature />
        </Transition>
        <Transition v-if="stepDigitalOnBoarding == 4" name="fade">
          <OnBoardingFourthStepDigitalSignature />
        </Transition>
      </div>
      <div class="container_footer_onboarding">
        <div class="container_elements_footer">
          <div
            class="footer_modal footer__modal__cancel footer__modal__fix_onboarding"
          >
            <div
              disabled="disabled"
              class="cancel_button_onboarding"
              @click="cancelModal()"
            >
              <span> Omitir</span>
            </div>
            <div
              :class="
                stepDigitalOnBoarding == 4
                  ? 'button_step_fourth '
                  : 'fix-btn-mac button_onboarding_accept'
              "
              @click="AcceptOnBoarding()"
            >
              <span v-if="stepDigitalOnBoarding == 1">
                Comenzar
              </span>
              <span
                v-if="
                  stepDigitalOnBoarding == 2 || stepDigitalOnBoarding == 3
                "
              >
                Siguiente
              </span>

              <span v-if="stepDigitalOnBoarding == 4">
                Firmar primer documento
              </span>
            </div>
          </div>
          <div class="container__progressBar__onboarding">
            <div
              :class="
                stepDigitalOnBoarding == 1
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(1)"
            ></div>
            <div
              :class="
                stepDigitalOnBoarding == 2
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(2)"
            ></div>
            <div
              :class="
                stepDigitalOnBoarding == 3
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(3)"
            ></div>
            <div
              :class="
                stepDigitalOnBoarding == 4
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(4)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import OnBoardingFirstStepDigitalSignature from "./FirstStepDigitalSignature.vue";
import OnBoardingSecondStepDigitalSignature from "./SecondStepDigitalSignature.vue";
import OnBoardingThirdStepDigitalSignature from "./ThirdStepDigitalSignature.vue";
import OnBoardingFourthStepDigitalSignature from "./FourthStepDigitalSignature.vue";

import { mapGetters } from "vuex";

export default {
  props: ["size", "indicator"],
  components: {
    OnBoardingFirstStepDigitalSignature,
    OnBoardingSecondStepDigitalSignature,
    OnBoardingThirdStepDigitalSignature,
    OnBoardingFourthStepDigitalSignature,
  },
  data() {
    return {
      textButtonOnboarding: "",
    };
  },
  created() {
    this.$store.dispatch("Assistance/cleancounterStepAssistanceOnBoarding");
  },
  computed: {
    ...mapGetters("DigitalSignature", {
      stepDigitalOnBoarding: "stepDigitalOnBoarding",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      is_staff: "is_staff",
      group: "group",
      company: "company",
      user_profile: "user_profile",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      this.$store.dispatch(
        "DigitalSignature/cleancounterStepDigitalOnBoarding"
      );
      let modal = document.querySelector(
        "#modal__onboarding__digital__signature"
      );
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector(
        "#modal__onboarding__digital__signature"
      );
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
      this.$store.dispatch(
        "DigitalSignature/cleancounterStepDigitalOnBoarding"
      );
    },
    AcceptOnBoarding() {
      let increment = this.stepDigitalOnBoarding + 1;
      this.$store.dispatch(
        "DigitalSignature/counterStepDigitalOnBoarding",
        increment
      );
      if (this.stepDigitalOnBoarding > 4) {
        if (this.indicator == 1) {
          let modal = document.querySelector(
            "#modal__onboarding__digital__signature"
          );
          modal.setAttribute("closing", "");
          modal.addEventListener(
            "animationend",
            () => {
              modal.removeAttribute("closing");
              modal.close();
            },
            { once: true }
          );
        } else {
          this.$store.dispatch(
            "DigitalSignature/cleancounterStepDigitalOnBoarding"
          );

          this.$router.push({ name: "NewDigitalSignature" });
        }
      }
    },
    goBack(data) {
      this.$store.dispatch(
        "DigitalSignature/counterStepDigitalOnBoarding",
        data
      );
    },
  },
};
</script>

<style lang="scss"></style>
