<template>
  <div class="main-login" v-if="!isLoggIn">
    <div id="login" class="login">
      <div>
        <div class="container-main-login" v-if="!loginCompany">
          <img
            id="main-logo"
            alt="MisAbogados Logo"
            style=""
            src="../../../assets/misabogados-login.png"
          />

          <form class="row-form">
            <div class="input-group">
              <input
                class="input_exception"
                type="email"
                id="email"
                placeholder="Dirección de correo electrónico"
                v-model="email"
              />
              <span
                class=""
                :class="errorfield ? 'field_error_email' : 'hidden__for__error'"
                >Direccion de correo invalida o erronea</span
              >
            </div>
            <div class="container_password">
              <div class="input-group">
                <input
                  class="input_exception"
                  type="password"
                  placeholder="Contraseña"
                  id="password"
                  v-model="password"
                  v-on:keyup.enter="onEnter"
                />
              </div>

              <div class="container-password-icon">
                <ShowiconPassword
                  class="icon__password"
                  :class="activeIcon ? 'show_password_icon' : ''"
                  @click="show_password()"
                  v-if="activeIcon"
                />
                <HideIconPassword
                  class="icon__password"
                  @click="show_password()"
                  v-else
                />
              </div>
            </div>
            <span v-if="errorfield" class="last-item field_error_email"
              >Password invalida o erronea</span
            >
            <span v-else class="last-item"></span>
          </form>

          <div class="container-login-footer">
            <button class="btn-vue-primary fix-btn-mac" @click="login()">
              <span> Iniciar Sesión </span>
            </button>
          </div>

          <div class="buttons-oauth">
            <div>
              <a
                class="btn-oauth bg-light"
                v-bind:href="`https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${googleRedirectURL}&response_type=code&access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20openid`"
                role="button"
              >
                <img
                  class="img-oauth-google"
                  alt="Google sign-in"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                />
                Ingresa con Gmail
              </a>
            </div>
            <div>
              <a
                class="btn-oauth bg-light"
                v-bind:href="`https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?client_id=${msClientId}&redirect_uri=${msRedirectURL}&response_type=code&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default`"
                role="button"
              >
                <img
                  class="img-oauth-microsoft"
                  alt="Microsoft sign-in"
                  src="https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png"
                />
                Ingresa con Outlook
              </a>
            </div>
          </div>

          <div class="passRecovery">
            <router-link :to="{ name: 'recuperar-password' }">
              ¿Olvidaste tu contraseña?
            </router-link>
          </div>
          <div class="redirect m-top-m">
            <a
              href="https://www.misabogados.com/plan-legal-empresas-protege-tu-negocio"
              >¿Quieres ser parte de MisAbogados? Registra aquí tu empresa.</a
            >
          </div>
        </div>
        <div v-else>
          <LoginEmpresas />
        </div>
      </div>
    </div>

    <div class="background-art">
      <div class="slogan">
        <p class="slogan-title">Mantén tu Negocio a Flote</p>
        <p class="slogan-p">
          Un servicio legal transparente, hecho por abogados expertos
        </p>
      </div>
    </div>
  </div>
  <div class="center-loader" v-else>
    <LoaderGeneric />
  </div>
</template>

<script>
import LoginEmpresas from "./LoginEmpresas.vue";
import LoaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import ShowiconPassword from "../assets/eye1.svg";
import HideIconPassword from "../assets/eye2.svg";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "LoginPage",

  components: {
    LoginEmpresas,
    LoaderGeneric,
    ShowiconPassword,
    HideIconPassword,
  },
  data: function () {
    return {
      info: "",
      email: "",
      password: "",
      loginCompany: false,
      errorfield: false,
      googleClientId: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
      googleRedirectURL: process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_URL,
      msClientId: process.env.VUE_APP_MS_OAUTH_CLIENT_ID,
      msRedirectURL: process.env.VUE_APP_MS_OAUTH_REDIRECT_URL,
      activeIcon: false,
    };
  },
  computed: {
    ...mapState({
      isLoggIn: (state) => state.isLoggIn,
    }),
    ...mapGetters("Assistance", {
      getterAssistanceRouteId: "getterAssistanceRouteId",
    }),
  },
  created() {
    this.email = "";
    this.password = "";
    // QA deep test of oauthLogin();
    this.oauthLogin();
  },
  mounted() {
    // QA deep test of oauthLogin();
    // this.oauthLogin();
  },

  methods: {
    ...mapActions("Login", ["fetchLogin", "setUserProfile", "setAllCompanies"]),
    ...mapGetters(["errors"]),
    ...mapGetters("Login", ["token", "company_id"]),

    onEnter() {
      this.login();
    },
    oauthLogin() {
      //--------------------------------
      // @Input: valid token from store
      // Description: check if token is != null for redirect in case user force view login when is loggin active
      // @Output: redirect login
      //--------------------------------
      if (this.$store.state.Login.token != null) {
        // this.setAllCompanies().then(() => {
        //   this.setUserProfile(this.company_id);
        // });
        // this.SetCompany = false;
        this.loginCompany = true;
        // this.$router.push({ name: "home" });
      }
    },
    // QA deep test of oauthLogin();
    // oauthLogin() {
    //   if (this.$store.state.Login.token != null) {
    //     this.userCompanies();
    //     this.SetCompany = false;
    //   }
    // },
    // async oauthLogin() {
    //   const googleUSer = await this.$gAuth.signIn();

    //   console.log("googleuser:", googleUSer);
    // },

    login() {
      this.fetchLogin({
        email: this.email,
        password: this.password,
      })
        .then(() => {
          // in case there are a id from assistance save in the store , we reedirect from login directly to the assistance card
          if (this.getterAssistanceRouteId != null) {
            setTimeout(() => {
              this.$router.push({
                name: "getSingleAssistanceUrl",
                params: {
                  id: this.getterAssistanceRouteId,
                },
              });
            }, 3000);
          }
          this.loginCompany = true;
        })
        .catch(() => {
          this.errorfield = true;
        });
    },
    show_password() {
      let pass = document.getElementById("password");
      if (pass.type == "password") {
        pass.type = "text";
        this.activeIcon = true;
      } else {
        pass.type = "password";
        this.activeIcon = false;
      }
    },
  },

  watch: {
    "$store.state.Login.token": {
      deep: true,
      handler() {
        this.loginCompany = false;
      },
      immediate: true,
    },
    isLoggIn(val) {
      if (val) {
        console.log("loggin true");
      }
    },
  },
};
</script>

<style lang="scss">
.redirect {
  text-align: center;
  font-size: 14px;
}

.container-login-footer {
  display: flex;
  margin-top: 1rem;
}

.img-oauth-google {
  margin-bottom: 3px;
  width: 20px;
  margin-right: 0.2rem;
}
.btn-oauth {
  text-transform: none;
  border: none;
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: center;
}
.btn-oauth:hover {
  transition: all 400ms ease;
  background: #0064ff !important;
  color: white;
}
.img-oauth-microsoft {
  margin-bottom: 3px;
  margin-right: 5px;
  font-family: Segoe UI;
}

.buttons-oauth {
  display: flex;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  gap: 0.5rem;

  @media screen and (max-width: 1024px) and (min-width: 200px) {
    flex-direction: column;
  }
}
.container_password {
  gap: 17px;
  width: 103%;
  display: flex;
  align-items: center;

  > .input-group {
    width: 100%;
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    gap: 13px;
  }
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    gap: 11px;
  }
  @media screen and (max-width: 1024px) and (min-width: 1024px) {
    gap: 15px;
  }
}
.container-password-icon {
  width: 0%;
}
</style>
