<template>
  <div class="">
    <div class="main-recovery">
      <div class="container-recovery" v-if="loading">
        <router-link :to="{ name: 'LoginPage' }" class="arrow-back">
          <ArrowBack icon="chevron-circle-left" />
        </router-link>
        <img
          alt="MisAbogados Logo"
          style=""
          src="../../../assets/misabogados-login.png"
        />

        <h1>Ingrese su email para recuperar su contraseña</h1>
        <input
          type="text"
          class="form-control"
          v-model="user_email"
          placeholder="Ingresa tu correo electronico"
        />
        <span class="error-span" v-if="errorfield">Correo invalido</span>

        <button @click="resetPssword()" class="btn-vue-secondary fix-btn-mac">
          <span> Recuperar contraseña </span>
        </button>
      </div>
      <div class="container-recovery" v-else>
        <div v-if="loadMessage">
          <loadingSection />
        </div>
        <div class="response-recovery" v-else>
          <img
            alt="MisAbogados Logo"
            style=""
            src="../../../assets/misabogados-login.png"
          />
          <p>{{ message }}</p>
          <a
            :href="
              $router.resolve({
                name: 'LoginPage',
              }).href
            "
            >Puedes Iniciar sesión Aqui</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authService from "../services/authService";
import loadingSection from "../../../components/loaderGeneric/LoadingMA.vue";
import ArrowBack from "@/assets/iconos_container/back.svg";

export default {
  name: "recuperar-password",

  components: {
    loadingSection,
    ArrowBack,
  },
  data() {
    return {
      loadMessage: true,
      loading: true,
      user_email: "",
      message: "",
      errorfield: false,
    };
  },
  methods: {
    resetPssword() {
      authService
        .reset_password({
          email: this.user_email,
        })
        .then(() => {
          this.loading = false;
          this.message =
            "¡Completado! Si existe el correo en nuestros registros, te llegará a dicha dirección las instrucciones de reactivación de contraseña";

          setTimeout(() => {
            this.loadMessage = false;
          }, 1000);
        })
        .catch(() => {
          this.errorfield = true;
        });
    },
  },
};
</script>

<style lang="scss">
.container-recovery {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  > * {
    text-align: center;
  }
}
</style>
