import * as types from "./mutations-types";
import scheduleService from "../services/scheduleService";
export default {
  fetchCategoryItems({ commit }, { admin_company_id, type }) {
    // usamos para activar loader en pantallas de categorias
    commit(types.ACTIVE_LOADER_SCHEDULING);
    return new Promise((resolve, reject) => {
      scheduleService
        .searchCategoryScheduling(admin_company_id, type)
        .then((data) => {
          if (type == 0) {
            commit(types.FETCH_SCHEDULE_LEGAL_DATA, data.results);
          } else {
            commit(types.FETCH_SCHEDULE_OPERATION_DATA, data.results);
          }
          resolve();
        })
        .then(() => {
          if (type == 0) {
            commit(types.FETCH_SCHEDULE_LEGAL_SUCCESS, true);
          } else {
            commit(types.FETCH_SCHEDULE_OPERATION_SUCCESS, true);
          }

          // cerrar loader cuando este en success cualquiera de los casos de type
          commit(types.CANCEL_LOADER_SCHEDULING);
        })
        .catch((error) => {
          reject(error);
          if (type == 0) {
            commit(types.FETCH_SCHEDULE_LEGAL_SUCCESS, false);
            commit(types.FETCH_SCHEDULE_LEGAL_FAILURE, true);
          } else {
            commit(types.FETCH_SCHEDULE_OPERATION_SUCCESS, false);
            commit(types.FETCH_SCHEDULE_OPERATION_FAILURE, true);
          }
        });
    });
  },
  getResumeMeetings(
    { commit },
    {
      admin_company_id,
      schedule_date__gte,
      schedule_date__lte,
      schedule_author,
      limit,
    }
  ) {
    // usamos para activar loader en pantallas de categorias
    return new Promise((resolve, reject) => {
      scheduleService
        .getSchedulingMeetings(
          admin_company_id,
          schedule_date__gte,
          schedule_date__lte,
          schedule_author,
          limit
        )
        .then((data) => {
          commit(types.FETCH_RESUME_MEETING_DATA, data);

          resolve();
        })
        .then(() => {
          // cerrar loader cuando este en success cualquiera de los casos de type
          // commit(types.CANCEL_LOADER_SCHEDULING);
        })
        .catch((error) => {
          reject(error);
          // if (type == 0) {
          //   commit(types.FETCH_SCHEDULE_LEGAL_SUCCESS, false);
          //   commit(types.FETCH_SCHEDULE_LEGAL_FAILURE, true);
          // } else {
          //   commit(types.FETCH_SCHEDULE_OPERATION_SUCCESS, false);
          //   commit(types.FETCH_SCHEDULE_OPERATION_FAILURE, true);
          // }
        });
    });
  },
  getallMeetings(
    { commit },
    {
      admin_company_id,
      schedule_date__gte,
      schedule_date__lte,
      schedule_author,
      limit,
    }
  ) {
    // usamos para activar loader en pantallas de categorias
    commit(types.ACTIVE_LOADER_ALL_MEETINGS);

    return new Promise((resolve, reject) => {
      scheduleService
        .getSchedulingMeetings(
          admin_company_id,
          schedule_date__gte,
          schedule_date__lte,
          schedule_author,
          limit
        )
        .then((data) => {
          commit(types.FETCH_MEETING_CARD_DATA, data);

          resolve();
        })
        .then(() => {
          setTimeout(() => {
            commit(types.DESACTIVE_LOADER_ALL_MEETINGS);
          }, 1000);

          // cerrar loader cuando este en success cualquiera de los casos de type
          // commit(types.CANCEL_LOADER_SCHEDULING);
        })
        .catch((error) => {
          reject(error);
          commit(types.DESACTIVE_LOADER_ALL_MEETINGS);

          // if (type == 0) {
          //   commit(types.FETCH_SCHEDULE_LEGAL_SUCCESS, false);
          //   commit(types.FETCH_SCHEDULE_LEGAL_FAILURE, true);
          // } else {
          //   commit(types.FETCH_SCHEDULE_OPERATION_SUCCESS, false);
          //   commit(types.FETCH_SCHEDULE_OPERATION_FAILURE, true);
          // }
        });
    });
  },
  getPastMeetings(
    { commit },
    {
      admin_company_id,
      schedule_date__gte,
      schedule_date__lte,
      schedule_author,
      limit,
    }
  ) {
    // usamos para activar loader en pantallas de categorias
    return new Promise((resolve, reject) => {
      scheduleService
        .getSchedulingMeetings(
          admin_company_id,
          schedule_date__gte,
          schedule_date__lte,
          schedule_author,
          limit
        )
        .then((data) => {
          commit(types.FETCH_PAST_MEETING_DATA, data);

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  selecteScheduling({ commit }, params) {
    return new Promise((resolve) => {
      commit(types.CLEAN_SCHEDULING_CATEGORRY);
      commit(types.SELECTED_SCHEDULING_FLAG);
      resolve(true);
    }).then(() => {
      commit(types.SELECTED_SCHEDULING_CATEGORRY, params);
    });
  },
  cleanSelecteScheduling({ commit }) {
    commit(types.CLEAN_SCHEDULING_CATEGORRY);
  },
  cleanSelecteMeeting({ commit }) {
    commit(types.CLEAN_SCHEDULING_FLAG);
  },
};
