import * as types from "./mutations-types";

export default {
  // new fetch company data for new company table
  [types.FETCH_COMPANY_DATA](state, data) {
    state.company_data_table = data;

    state.prevPage = data.previous;
    state.nextPage = data.next;

    let company_data_for_table = [];
    let data_table = data.results;

    for (var i = 0; i < data.results.length; i++) {
      company_data_for_table.push({
        id: data_table[i].id ? data_table[i].id : "Sin id",
        company:
          data_table[i].name.length > 40
            ? `${data_table[i].name.slice(0, 40)}...`
            : data_table[i].name,
        rutCompany: data_table[i].nin ? data_table[i].nin : "Sin rut company",
        email:
          data_table[i].admin_email != null
            ? data_table[i].admin_email
            : "Sin rut",
        alias: data_table[i].alias ? data_table[i].alias : "Sin alias",
        active: false,
        address:
          data_table[i].address == null
            ? "Sin dirección"
            : data_table[i].address.length < 50
            ? `${data_table[i].address.toLowerCase()}`
            : `${data_table[i].address.toLowerCase().slice(0, 50)}...`,
      });
    }

    state.total_data_view = company_data_for_table.length;

    state.company_data_table = company_data_for_table;
  },

  [types.FETCH_COMPANYS_FAILURE](state, { error }) {
    state.errorCompanys = error;
    state.fetchCompanys = false;
  },
  [types.VIEW_SINGLE_COMPANY](state) {
    state.flagAdminForViewSingleCompany = true;
  },
  [types.CLEAN_VIEW_SINGLE_COMPANY](state) {
    state.flagAdminForViewSingleCompany = false;
  },

  [types.FETCH_USER_COMPANY_DATA](state, data) {
    state.fetchCompanys = true;
    state.company_user = data;

    let employees_for_table = [];

    for (var i = 0; i < data.results.length; i++) {
      var fullname = data.results[i].first_name + " " + data.results[i].surname;
      employees_for_table.push({
        id: data.results[i].id,
        company_id: data.results[i].company.id,
        company_name: data.results[i].company.name,
        first_name: fullname,
        company: data.results[i].company.name,
        nin: data.results[i].nin,
        email: data.results[i].user.email,
        status: data.results[i].is_active ? "Active" : "Inactive",
      });
    }

    state.table_user_company_admin = employees_for_table;

  },

  [types.FETCH_USER_COMPANY_FAILURE](state, { error }) {
    state.errorUsers = error;
    state.fetchUsersCompanys = false;
  },

  [types.GET_COMPANY_SELECTED_DATA](state, data) {
    let company_types = [
      {
        name: "Sociedad por acciones",
        value: 0,
      },
      {
        name: "Sociedad resposabilidad limitada",
        value: 1,
      },
      {
        name: "Empresa individual responsabilidad limitada",
        value: 2,
      },
      {
        name: "Sociedad anonima",
        value: 3,
      },
      {
        name: "Sociedad colectiva comercial",
        value: 4,
      },
      { name: "Sociedad en comandita", value: 5 },
    ];

    for (let types_company of company_types) {
      if (data.company_type == types_company.value) {
        data.company_type_alias = types_company.name;
      }
    }

    state.single_company = data;
  },

  [types.GET_COMPANY_SELECTED_FAILURE](state, error) {
    state.errorSelectedCompany = error;
  },

  // NEW TYPES FOR PAGINATION

  [types.CLEAN_SINGLE_COMPANY](state) {
    state.single_company = null;
  },
  [types.SET_FLAG](state, data) {
    state.flagData = data;
  },
  [types.SET_TOTAL_DATA](state, data) {
    state.total_data = data;
  },
  [types.SET_TOTAL_DATA_VIEW](state, data) {
    state.total_data_view = data;
  },
  [types.SET_PREV_PAGE](state, data) {
    state.prevPage = data;
  },
  [types.SET_NEXT_PAGE](state, data) {
    state.nextPage = data;
  },
  [types.LOADER_COMPANY_ON](state) {
    state.loaderCompanys = true;
  },
  [types.LOADER_COMPANY_OFF](state) {
    state.loaderCompanys = false;
  },
  // TIPOS para guardar y nulificar valores del FILTER
  [types.SET_SELECTED_COUNTRY](state, data) {
    state.selectedCountry = data;
  },
  [types.SET_SELECTED_REGION](state, data) {
    state.selectedRegion = data;
  },
  [types.SET_INITIAL_ECONOMIC_CATEGORY](state, data) {
    state.initialEconomicCategory = data;
  },
  [types.FLAG_RESET_INITIAL_ECONOMIC_CATEGORY](state) {
    state.flagInitialReset = true;
  },
  [types.FLAG_RESET_INITIAL_ECONOMIC_CATEGORY_RETURN](state) {
    state.flagInitialReset = false;
  },
  [types.SET_ECONOMIC_SERVICE](state, data) {
    state.economicCategoryForService = data;
  },
  [types.RESET_DATA_FOR_ECONOMIC_SERVICE](state) {
    state.economicCategoryForService = [];
  },
  [types.PUSH_DATA_FOR_ECONOMIC_SERVICE](state, data) {
    state.economicCategoryForService.push(data);
  },
  [types.SET_DATA_FOR_ECONOMIC_SERVICE](state, data) {
    state.economicCategoryForService = data;
  },
  [types.RESET_ACTIVITY_ECONOMIC](state, data) {
    state.initialEconomicCategory = data;
  },

  //types for TAGS

  [types.SET_TAGS_TRUE](state) {
    state.tags = true;
  },
  [types.SET_TAGS_FALSE](state) {
    state.tags = false;
    state.tagsForSwitch = null;
  },
  [types.SET_TAGS_FOR_SWITCH](state, data) {
    state.tagsForSwitch = data;
  },

  //Flag for hide selected options elements from table
  [types.SET_FLAGS_SELECTED_OPTIONS](state, data) {
    state.currentSelectCompanyFlag = data;
  },
  // set filter for mobile version view
  [types.SET_FILTER_COMPANY_MOBILE](state) {
    state.flagFilterMobile = true;
  },
  [types.CLEAN_FILTER_COMPANY_MOBILE](state) {
    state.flagFilterMobile = false;
  },
};
