<template>
  <div class="meetings__container p-left-1">
    <div
      class="border__meeting"
      v-for="(meeting, index) in meetingCards"
      :key="index"
    >
      <div class="metings__container__card" @click="openMeetingUrl(meeting.url)">
        <div class="date__meeting">
          <div class="color_gray_i f-8 m-bottom-m month__name">
            {{ meeting.date.day }}
          </div>
          <div
            :class="
              user_id == meeting.author ? 'circle__date' : 'circle__third'
            "
          >
            {{ meeting.date.dayINT }}
          </div>
          <div class="f-9 m-top-s month__space">
            {{ meeting.date.month }}
          </div>
        </div>
        <div class="info__meeting">
          <div class="info__meeting__titles">
            <div class=" f-14 color_gray_i">
              {{ meeting.title }}
            </div>

            <div class="meeting__range__hour">
              {{ meeting.hour_start }} - {{ meeting.hour_end }}
            </div>
          </div>

          <div class="color_gray_i f-12 m-bottom-s">
            {{ meeting.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Schedule", {
      meetingCards: "meetingCards",
    }),

    ...mapGetters("Login", {
      user_id: "user_id",
    }),
  },

  methods: {
    openMeetingUrl(url){
      window.open(url)
    }
  },
};
</script>

<style lang="scss">
@import "./CategoryItems.scss";
</style>
