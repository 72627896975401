<template>
  <header class="headerAdmin" id="header">
    <div class="head-title">
      <burger
        class="icon iconAdm"
        icon="sign-out-alt"
        v-if="!hiddenSelector"
        v-on:click="toggleDesktopSidebar"
        id="new-close-sidebar"
      />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 566.93 283.46"
        preserveAspectRatio="none"
        id="logo-header"
        class="logo-desktop logo-visible iconAdm"
        v-if="!mobileView"
      >
        <defs></defs>
        <g id="Logotipo">
          <path
            class=""
            d="M98.42,145.55H83.19a3.82,3.82,0,1,0,0,7.63H98.42a3.82,3.82,0,0,0,0-7.63Z"
          />
          <path
            class=""
            d="M174.77,145.55H113.65a3.82,3.82,0,0,0,0,7.63h61.12a3.82,3.82,0,0,0,0-7.63Z"
          />
          <path
            class=""
            d="M83.19,122.65h45.72a3.82,3.82,0,0,0,0-7.64H83.19a3.82,3.82,0,1,0,0,7.63Z"
          />
          <path
            class=""
            d="M174.77,160.81H128.91a3.82,3.82,0,0,0,0,7.63h45.86a3.82,3.82,0,0,0,0-7.63Z"
          />
          <path
            class=""
            d="M113.65,160.81H83.19a3.82,3.82,0,1,0,0,7.63h30.46a3.82,3.82,0,1,0,0-7.63Z"
          />
          <path
            class=""
            d="M144.18,130.29h-61a3.82,3.82,0,1,0,0,7.63h61a3.82,3.82,0,0,0,0-7.63Z"
          />
          <path
            class=""
            d="M174.77,130.28h-15.3a3.82,3.82,0,0,0,0,7.63h15.3a3.82,3.82,0,0,0,0-7.63Z"
          />
          <path
            class=""
            d="M144.18,122.66h30.59a3.82,3.82,0,0,0,0-7.63H144.18a3.82,3.82,0,1,0,0,7.64Z"
          />
          <path
            d="M291.26,125.05h6.18l11.27,33.33h-6.65l-2.44-7.86H289.05l-2.42,7.86H280Zm6.6,19.75-3.51-11.55-3.54,11.55Z"
          />
          <path
            d="M312.52,124.67h5.9v11.9a6.64,6.64,0,0,1,2.42-1.81,7.87,7.87,0,0,1,3.39-.76c3.51,0,6.23,1.11,8.11,3.37s2.84,5.22,2.84,9-.95,6.79-2.84,9-4.6,3.35-8.11,3.35a7.42,7.42,0,0,1-3.65-.89,5.23,5.23,0,0,1-2.16-2v2.47h-5.9Zm5.37,21.71a8.5,8.5,0,0,0,1.53,5.4,5,5,0,0,0,4,1.92,4.89,4.89,0,0,0,4-1.95,9.92,9.92,0,0,0,0-10.74,5.06,5.06,0,0,0-8,0A8.44,8.44,0,0,0,317.89,146.38Z"
          />
          <path
            d="M339,146.38a12.47,12.47,0,0,1,3.27-8.88,13.08,13.08,0,0,1,17.81,0,13.6,13.6,0,0,1,0,17.76,13.11,13.11,0,0,1-17.81,0A12.45,12.45,0,0,1,339,146.38Zm6.18,0a8.21,8.21,0,0,0,1.63,5.21,5.58,5.58,0,0,0,8.74,0,9.17,9.17,0,0,0,0-10.41,5.56,5.56,0,0,0-8.74,0A8.16,8.16,0,0,0,345.19,146.38Z"
          />
          <path
            d="M368.85,155.8a5.91,5.91,0,0,1,1.72-8.74,7.57,7.57,0,0,1-1.68-4.95,7.45,7.45,0,0,1,2.51-5.88,9.2,9.2,0,0,1,6.3-2.23,9.71,9.71,0,0,1,4.42,1l4.53-2.37,2.32,3.9-3.41,1.72a7.78,7.78,0,0,1,.95,3.82,7.27,7.27,0,0,1-2.53,5.83,9.31,9.31,0,0,1-6.28,2.16,11.62,11.62,0,0,1-3.49-.53,2,2,0,0,0-1.13,1.81c0,1.19.74,1.77,2.23,1.77h4.9a9.56,9.56,0,0,1,6.14,1.83,6.29,6.29,0,0,1,2.3,5.21,6.94,6.94,0,0,1-1.68,4.74,9.24,9.24,0,0,1-4,2.72,16.07,16.07,0,0,1-5.2.81,13.71,13.71,0,0,1-7.84-2,6.66,6.66,0,0,1-3-5.86A6.15,6.15,0,0,1,368.85,155.8Zm8.9,7.71c3.32,0,5-1,5-2.95,0-1.65-1.27-2.46-3.85-2.46h-3.23a18,18,0,0,1-2.77-.19,3.82,3.82,0,0,0-.54,2.19C372.35,162.33,374.49,163.51,377.75,163.51Zm-.07-18.17a3.14,3.14,0,0,0,3.3-3.23,3.29,3.29,0,1,0-6.58,0A3.15,3.15,0,0,0,377.68,145.34Z"
          />
          <path
            d="M393.94,145.76a12,12,0,0,1,7.39-2.14h4v-1.05c0-2.35-1.4-3.53-4.18-3.51A3.94,3.94,0,0,0,397,141.8l-5-1.13a7.71,7.71,0,0,1,3.05-4.84,9.87,9.87,0,0,1,6.09-1.83c6.71,0,10.08,3.34,10.08,10.06v14.32h-4.85l-.61-3a9.12,9.12,0,0,1-7.39,3.42,7.46,7.46,0,0,1-5.23-1.88,7,7,0,0,1-2-5.4A6.78,6.78,0,0,1,393.94,145.76Zm5.83,7.94a5.9,5.9,0,0,0,3.86-1.37,4.35,4.35,0,0,0,1.7-3.58v-.46h-3.95c-2.72,0-4.09,1-4.09,3.09A2.27,2.27,0,0,0,399.77,153.7Z"
          />
          <path
            d="M429.87,134.76a6.56,6.56,0,0,1,2.44,1.81v-11.9h5.9v33.71h-5.9v-2.47a5.54,5.54,0,0,1-2.19,2,7.22,7.22,0,0,1-3.62.89c-3.51,0-6.23-1.12-8.12-3.35s-2.83-5.23-2.83-9,.95-6.78,2.83-9S423,134,426.5,134A7.72,7.72,0,0,1,429.87,134.76Zm-8.14,11.62a8.31,8.31,0,0,0,1.56,5.37,5.07,5.07,0,0,0,8,0,10.11,10.11,0,0,0,0-10.79,4.86,4.86,0,0,0-4-1.93,4.92,4.92,0,0,0-4,1.95A8.24,8.24,0,0,0,421.73,146.38Z"
          />
          <path
            d="M443.62,146.38a12.43,12.43,0,0,1,3.28-8.88,13.07,13.07,0,0,1,17.8,0,13.6,13.6,0,0,1,0,17.76,13.09,13.09,0,0,1-17.8,0A12.41,12.41,0,0,1,443.62,146.38Zm6.18,0a8.27,8.27,0,0,0,1.63,5.21,5.58,5.58,0,0,0,8.74,0,9.13,9.13,0,0,0,0-10.41,5.56,5.56,0,0,0-8.74,0A8.22,8.22,0,0,0,449.8,146.38Z"
          />
          <path
            d="M476.06,149.75c.95,2.63,2.56,3.95,4.81,3.95,2,0,2.95-.86,2.95-2.55.14-1.19-1.2-1.86-2.6-2.4-.42-.16-1-.39-1.67-.62s-1.28-.47-1.72-.65a9.73,9.73,0,0,1-3.58-2.42A6,6,0,0,1,473,141a6.64,6.64,0,0,1,2.14-5,7.68,7.68,0,0,1,5.53-2,9,9,0,0,1,4.37,1.09,8.47,8.47,0,0,1,3.16,2.81l-3.53,3.25a4.94,4.94,0,0,0-4-2.09,2,2,0,0,0-2.19,2c0,1,.77,1.74,2.28,2.32l1.72.61c1,.35,1.65.56,2.05.72a15.67,15.67,0,0,1,1.74.88,6,6,0,0,1,1.67,1.28,6.44,6.44,0,0,1,1,1.63,5.08,5.08,0,0,1,.49,2.25,7.67,7.67,0,0,1-2.37,5.67,8.33,8.33,0,0,1-6.16,2.33,9.48,9.48,0,0,1-5.95-1.93,10.77,10.77,0,0,1-3.55-4.53Z"
          />
          <path
            d="M249.17,128.31a3.16,3.16,0,0,1,.94-2.33,3.37,3.37,0,0,1,4.65,0,3.19,3.19,0,0,1,.93,2.33,3.23,3.23,0,0,1-.93,2.32,3.17,3.17,0,0,1-2.33.94,3.21,3.21,0,0,1-3.26-3.26Z"
          />
          <path
            d="M212.6,125.05h7l7.85,20.8,7.86-20.8h7v33.33H236V137.81l-6.37,17.52h-4.49l-6.37-17.52v20.57h-6.2Z"
          />
          <rect x="249.48" y="135.5" width="5.9" height="22.88" />
          <path
            d="M264.7,149.75c.95,2.63,2.55,4,4.81,4,2,0,2.95-.86,2.95-2.56.14-1.19-1.21-1.86-2.6-2.4-.42-.16-1-.39-1.68-.62s-1.28-.47-1.72-.65a9.61,9.61,0,0,1-3.58-2.42,6,6,0,0,1-1.27-4.07,6.67,6.67,0,0,1,2.13-5,7.68,7.68,0,0,1,5.53-2,9,9,0,0,1,4.37,1.09,8.51,8.51,0,0,1,3.17,2.81l-3.54,3.25a4.91,4.91,0,0,0-4-2.09,2,2,0,0,0-2.18,2c0,1,.77,1.74,2.28,2.33l1.72.6c1,.35,1.65.56,2,.72a14.79,14.79,0,0,1,1.75.88,5.51,5.51,0,0,1,2.67,2.91,5.22,5.22,0,0,1,.49,2.25,7.67,7.67,0,0,1-2.37,5.67,8.35,8.35,0,0,1-6.16,2.33,9.46,9.46,0,0,1-5.95-1.93,10.8,10.8,0,0,1-3.56-4.53Z"
          />
        </g>
      </svg>
      <burger
        icon="bars"
        id="bars"
        class="logo-mobile"
        v-on:click="toggleMobile"
      />
    </div>

    <div class="in-c"></div>
    <div class="container__right_header">
      <div class="container__key__tenant">
        <div class="key__icon">
          <keyIcon />
        </div>
        <div class="key__tenant__title text_document">{{ nameMainTenant }}</div>
        <div class="key__logOut text_document" @click="closeSwitchTenancy()">
          Salir
        </div>
      </div>

      <div class="user-wrapper" id="user-wrapper" v-if="user">
        <div class="perfil">
          <div class="profile-initial">
            <div class="initial">
              {{ initials }}
            </div>
          </div>
          <div class="perfil-contact perfilAdm">
            <div class="name-user-admin">
              {{ user.first_name }} {{ user.surname }}
            </div>

            <small class="company-name">
              {{ user.company.alias }}
            </small>
          </div>
        </div>

        <div class="container-user">
          <ListOptionHeader :dataMenu="dataMenu" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import burger from "../../assets/sidebarIcons/hamburger.svg";
import ListOptionHeader from "../header/ListOptionHeader.vue";
import keyIcon from "../../assets/navBarIcon/keyfilled.svg";

export default {
  components: {
    burger,
    keyIcon,
    ListOptionHeader,
  },

  data() {
    return {
      loading: true,
      mobileView: true,
      user: null,
      hiddenSelector: false,
      initials: "",
      dataMenu: [
        {
          id: "3",
          title: "Configuraciones",
        },
        // {
        //   id: "4",
        //   title: "Soporte",
        // },
        {
          id: "5",
          title: "Cerrar sesión",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      userstate: (state) => state.Login.user_profile,
    }),
    ...mapGetters("Login", {
      group: "group",
    }),

    ...mapGetters("Config", {
      idMainTenant: "idMainTenant",
      nameMainTenant: "nameMainTenant",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    closeSwitchTenancy() {
      let id = this.idMainTenant;
      this.$store.dispatch("Config/cleanSwitchTenancy");
      this.$store.dispatch("Login/setUserProfile", id).then(() => {
        setTimeout(() => {
          let currentLocation = localStorage.getItem("currentModule");
          if (currentLocation == "request") {
            this.$store.dispatch("Assistance/cleanSingleAssitance").then(() => {
              this.$router.push({ name: "integratedAssistance" });
            });
          } else {
            this.$router.push({ name: "ListCompanyV2" });
          }
        }, 1000);
      });
    },
    isMobile() {
      if (screen.width <= 1024) {
        this.hiddenSelector = true;
      }
      if (screen.width <= 768) {
        return (this.mobileView = true);
      } else {
        return (this.mobileView = false);
      }
    },

    toggleMobile() {
      let removeLogoComplex = document.getElementById("logo-desktop-complete");
      removeLogoComplex.classList.remove("close-btn-sidebar");

      let btnvisible = document.getElementById("btn-mobile-sidebar");

      btnvisible.classList.add("close-btn-sidebar");
      //let btn = document.querySelector("#btn");
      let sidebar = document.querySelector(".sidebarAdm");
      sidebar.classList.add("sidebar-menu");

      let overlay = document.getElementById("overlay");
      overlay.classList.remove("overlay-visible");
      overlay.classList.add("overlay-visible");

      let bars = document.querySelector(".logo-mobile");
      bars.classList.toggle("active");
      sidebar.classList.toggle("active");
    },

    toggleDesktopSidebar() {
      let sidebar = document.querySelector(".sidebarAdm");

      sidebar.classList.contains("sidebar-menu")
        ? this.disableMobileSidebar()
        : this.normalDesktopSidebar();
    },
    normalDesktopSidebar() {
      // console.log("voy de normal a complex sidebar");
      let sidebar = document.querySelector(".sidebarAdm");
      let sidebarInside = document.getElementById("close-sidebar");

      let addLogoComplex = document.getElementById("logo-desktop-complete");

      addLogoComplex.classList.add("close-btn-sidebar");

      sidebarInside.classList.remove("close-btn-sidebar");
      let header = document.querySelector("header");
      let headerOpenSidebar = document.getElementById("new-close-sidebar");

      headerOpenSidebar.classList.toggle("active-icon");

      let headerLogo = document.getElementById("logo-header");
      headerLogo.classList.toggle("logo-visible");
      header.classList.toggle("active");
      sidebar.classList.toggle("active");
      let mainContent = document.querySelector(".home_content");

      mainContent.classList.toggle("home_content_active");
    },

    disableMobileSidebar() {
      let sidebar = document.querySelector(".sidebarAdm");
      sidebar.classList.toggle("active");
      sidebar.classList.remove("sidebar-menu");
    },
  },

  created() {
    this.isMobile();

    this.user = this.userstate;

    let initialCaracters = this.user.user.username.slice(0, 2).toUpperCase();
    this.initials = initialCaracters;
  },

  watch: {
    "$store.state.Login": {
      deep: true,
      handler() {
        this.user = this.userstate;
        this.loading = false;
        return this.loading;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../header/header.scss";

.perfil {
  width: 90%;
  justify-content: flex-end;
  padding-right: 22px;
}

header {
  grid-template-columns: 250px 100px 500px !important;
  padding-right: 0px !important;
}

.container__key__tenant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 234px;
  height: 38px;
  background: #1f2f59;
  border-radius: 12px;

  color: white;
  padding: 0 12px;

  > .key__icon svg {
    fill: white;
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

  > .key__tenant__title {
    margin-left: 9px;
    margin-right: 13px;
    font-size: 14px;
    color: white !important;
    white-space: nowrap;
  }

  > .key__logOut {
    font-size: 14px;
    line-height: 16.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 26px;
    border: 1px solid #000032;
    box-sizing: border-box;
    border-radius: 8px;
    color: white !important;
    cursor: pointer;

    &:hover {
      background-color: #000032;
    }
  }
}

.container__right_header {
  display: flex;
  align-items: center;
}

.in-c {
  opacity: 0;
}

.headerAdmin {
  background-color: $primary !important;
}

.iconAdm {
  fill: white !important;
}
.perfilAdm {
  color: white !important;
}
.user-wrapper {
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    padding-right: 2rem !important;
  }
}
.user-wrapper:hover .container-user {
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    margin-right: 3rem !important;
  }
}
</style>
