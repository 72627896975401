<template>
  <div class="center-loader" v-if="loading">
    <loadingSection />
  </div>
  <div class="container-activated" v-else>
    <div v-if="checked">
      ¡Tu cuenta ha sido activada con éxito! Pincha
      <a
        :href="
          $router.resolve({
            name: 'LoginPage',
          }).href
        "
        >aquí</a
      >
      para iniciar sesión en tu empresa.
    </div>
    <div v-else>
      {{ bad_message }}
      <a
        :href="
          $router.resolve({
            name: 'LoginPage',
          }).href
        "
        >regresar al login</a
      >
    </div>
  </div>
</template>

<script>
import loadingSection from "../../../components/loaderGeneric/LoadingMA.vue";
import authService from "../services/authService";

export default {
  components: {
    loadingSection,
  },
  computed: {},
  created() {
    this.checkToken();
  },
  props: {
    some_token: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      checked: false,
      bad_message: "",
    };
  },
  methods: {
    checkToken() {
      authService
        .verify_account(this.some_token)
        .then(() => {
          this.checked = true;
          this.loading = false;
          this.$store.dispatch("Login/logOut", null);
        })
        .catch(() => {
          this.bad_message = "Error al activar su cuenta";
          this.checked = false;
          this.loading = false;
        });
    },
  },
  watch: {},
};
</script>

<style>
.container-activated {
  align-items: center;
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  /* align-self: center; */
  height: 100vh;
  /* margin-bottom: 5rem; */
  padding-bottom: 5rem;
}
</style>
