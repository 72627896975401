<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    id="modal__change__user__member"
    ref="modal__change__user__member"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="modal__content__cancel__creation">
      <div class="">
        <div
          class="title_remove_creation text-filter-light title_modal_cancel_creation"
        >
          Cambiar Usuario a Miembro
        </div>
      </div>
      <div class="title_remove_creation text_creation_cancel text-filter-light text_change_user">
        Para realizar esta acción, por favor escribir directamente a
        <b> empresas@misabogados.com</b> con el nombre de usuario a modificar
      </div>
      <div class="footer_modal footer__modal__cancel">
        <div
          disabled="disabled"
          class="secondary_button_cancel_create"
          @click="cancelModal()"
        >
          <span> Volver</span>
        </div>
        <div
          class="fix-btn-mac button_cancel_create"
          @click="AcceptCancelModal()"
        >
          <span> Aceptar </span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/assets/iconos_container/cancel.svg";
export default {
  props: ["size"],
  components: {
    CancelIcon,
  },
  data() {
    return {};
  },

  computed: {},

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__change__user__member");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__change__user__member");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    AcceptCancelModal() {
      let modal = document.querySelector("#modal__change__user__member");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">

</style>
