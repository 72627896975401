<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__warning__empty__signers"
    ref="modal__warning__empty__signers"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="company_svg">
      <logoTipoEmpresa />
      <div class="logo_name">MisAbogados</div>
    </div>

    <div class="modal__user__empty">
      <div class="text-filter-light">Tienes algunos datos sin ingresar</div>

      <div
        class="text-filter-light m-top-s font__size_subtitle_empty body_empty_signers"
      >
        <span class="text-filter font__size_subtitle_empty">
          Para ingresar el firmante recuerda hacer click en el
          <span class="check">check</span>
        </span>

        <div class="container__add__signer__modal fix_color_empty_modal">
          <input
            type="text"
            class="input-form disable__input"
            disabled="disabled"
            value="Fransico"
          />
          <div class="field__rut fix_color_empty_modal">
            <input
              type="text"
              class="input-form disable__input"
              disabled="disabled"
              value="tucorreo@gmail.com"
            />
          </div>
          <div class="field__rut fix_color_empty_modal">
            <input
              type="text"
              class="input-form disable__input"
              disabled="disabled"
              value="1234567-8"
            />
          </div>

          <div class="svg_empty_signer">
            <confirmFirma />
          </div>
        </div>
      </div>
      <div class="svg_empty_signer_hand">
        <handClick />
      </div>
    </div>
    <div class="footer_modal__empty_signers">
      <div
        disabled="disabled"
        class="btn-vue-primary m-bottom-xl btn__width"
        @click="cancelModal()"
      >
        <span> Volver </span>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/components/modal/assets/cancel.svg";
import confirmFirma from "../../../../assets/iconos_container/confirm_firma.svg";
import handClick from "../../../../assets/iconos_container/tap.svg";
import logoTipoEmpresa from "../../../../assets/iconos_logo_svg/logotipo_svg/logotipo_primary_color.svg";
export default {
  props: ["size"],
  components: {
    CancelIcon,
    confirmFirma,
    handClick,
    logoTipoEmpresa,
  },
  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__warning__empty__signers");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__warning__empty__signers");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.font__size_subtitle_empty {
  font-size: 15px !important;
}

.modal__user__empty {
  padding: 1rem 2rem 1rem 2rem;
  text-align: left;
  > * {
    color: var(--title_origin) !important;
  }
  > div span {
    color: var(--title_origin) !important;
    > span {
      color: green !important;
    }
  }
}

.footer_modal__empty_signers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  > div span {
    color: var(--title_modal);
  }
}

.check {
  color: green;
}

.svg_empty_signer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > svg {
    height: 15px;
    width: 15px;
  }
}

.svg_empty_signer_hand {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) and (min-width: 200px) {
  }

  > svg {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    animation: MoveUpDown 1s linear infinite;
    position: absolute;
  }
}

.company_svg {
  display: flex;
  gap: 1rem;
  margin-left: 2rem;
  align-items: center;
  > .logo_name {
    color: var(--title_modal);
    font-size: 25px;
    font-weight: bold;
  }
  > svg {
    height: 50px;
    width: 50px;
  }
}

.fix_color_empty_modal {
  > input {
    color: #464646;
    opacity: 0.7;
    font-size: 12px;
  }
}
.container__add__signer__modal {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  gap: 0.7rem;
}
.btn__width {
  width: 100px;
}
</style>
