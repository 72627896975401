import * as types from "./mutations-types";
export default {
  [types.GET__LIST__EMPLOYEES](state, data) {
    let employees = data.map((el) => {
      let newEmployee = {
        ...el,
        is_verified: el.user.is_verified,
        threedots: false,
      };
      return newEmployee;
    });
    state.total_data_view = employees.length;
    state.employees = employees;
  },
  [types.CONFIRM_DELETE_EMPLOYEE](state) {
    state.confirm_delete = true;
  },
  [types.FAILURE_DELETE_EMPLOYEE](state) {
    state.delete_failure = true;
  },
  [types.GET__LIST__EMPLOYEES_MEMBERS](state, data) {
    let employees = data.map((el) => {
      let newEmployee = {
        ...el,
        is_verified: el.user.is_verified,
        threedots: false,
        contract_type_display:
          el.contract_type == 0
            ? "Indefinido"
            : el.contract_type == 1
            ? "A plazo"
            : null,
      };
      return newEmployee;
    });
    state.total_data_view_members = employees.length;
    state.employeesMembers = employees;
  },
  [types.SET_FIILTER__EMPLOYEES](state, data) {
    state.employees = data;
  },
  [types.SET_IS_ACTIVE_EMPLOYEE](state, { list, id }) {
    let employeesActive = list.map((el) => {
      let newEmployees = {
        ...el,
        is_active: el.id == id ? (el.is_active = !el.is_active) : el.is_active,
      };
      return newEmployees;
    });

    state.employees = employeesActive;
  },
  [types.SET_IS_VERIFIED_EMPLOYEE](state, { list, id }) {
    let employeesVerified = list.map((el) => {
      let newEmployee = {
        ...el,
        is_verified: el.id == id ? true : el.is_verified,
      };
      return newEmployee;
    });

    state.employees = employeesVerified;
  },
  [types.SET_DROPDOWN_ALL_VERIFIED](state, list) {
    let payload = {
      VerifiedAll: true,
      desactiveVeriefiedAll: false,
    };
    state.dropDownSelectedVerified = payload;

    let employeesVerified = list.map((el) => {
      let newEmployee = {
        ...el,
        is_verified: true,
      };
      return newEmployee;
    });

    state.employees = employeesVerified;
  },
  [types.SET_EMPLOYEES_IN_SWITCH](state, data) {
    state.employees = data;
  },

  [types.SET_COMPANIES_BY_USER](state, data) {
    let companies = data.map((data) => {
      return { name: data.name, id: data.id };
    });
    state.listCompanies = companies;
  },

  //Esperar proximo requerimientos sobre Onboarding
  [types.SET_SINGLE_COMPANY](state, data) {
    let companies = data.map((data) => {
      return { name: data.company_name, id: data.company };
    });
    state.listCompanies = companies;
  },
  [types.SET_LOADER_ON](state) {
    state.loader = true;
  },
  [types.SET_LOADER_OFF](state) {
    state.loader = false;
  },
  [types.CLEAN_LIST_COMPANIES](state) {
    state.listCompanies = null;
  },

  //input search employee
  [types.GET_EMPLOYE__SEARCH](state, data) {
    state.employees = data;
  },
  [types.GET_FILTER_EMPLOYEE__BY_MODAL](state, data) {
    state.employees = data;
  },
  [types.GET_FILTER_EMPLOYEE__BY_MODAL_ADMIN](state, data) {
    state.employees = data;
  },
  [types.SET_EMPLOYEE_DROPDOWN](state, data) {
    state.activeDropdownState = data;
  },
  [types.SET_EMPLOYEE_DROPDOWN_VERIFIED](state, data) {
    state.activeDropdownVerified = data;
  },

  // Dropdown mutations
  [types.SET_DROPDOWN_ALL_ACTIVATED](state, data) {
    let payload = {
      activAll: true,
      desactiveAll: false,
    };

    let employeesActive = data.list_employees.map((el) => {
      let newEmployee = {
        ...el,
        is_active: true,
      };
      return newEmployee;
    });

    state.employees = employeesActive;
    state.dropDownSelectedState = payload;
  },
  [types.SET_DROPDOWN_ALL_DESACTIVATED](state, data) {
    let payload = {
      activAll: false,
      desactiveAll: true,
    };

    let employeesActive = data.list_employees.map((el) => {
      let newEmployee = {
        ...el,
        is_active: false,
      };
      return newEmployee;
    });

    state.employees = employeesActive;

    state.dropDownSelectedState = payload;
  },
  //TODO: set all verified

  [types.CLEAN_ALL_SELECTION_DROP](state) {
    let payload = {
      activAll: false,
      desactiveAll: false,
    };
    let payload2 = {
      VerifiedAll: false,
      desactiveVeriefiedAll: false,
    };
    state.dropDownSelectedState = payload;
    state.dropDownSelectedVerified = payload2;
  },

  [types.TOTAL_PAGES_DATA](state, { totalData, table, limit }) {
    let totalPagesData = Math.ceil(totalData / limit);

    if (table == "Miembros") {
      state.totalPagesMembers = totalPagesData;
    } else {
      state.totalPages = totalPagesData;
    }
  },

  [types.SET_TOTAL_DATA](state, payload) {
    if (payload.table == "Miembros") {
      state.total_data_members = payload.data;
    } else {
      state.total_data = payload.data;
    }
  },
  [types.SET_TOTAL_DATA_VIEW](state, data) {
    state.total_data_view = data;
  },
  [types.SET_PREV_PAGE](state, payload) {
    if (payload.table == "Miembros") {
      state.prevPageMembers = payload.data;
    } else {
      state.prevPage = payload.data;
    }
  },
  [types.SET_NEXT_PAGE](state, payload) {
    if (payload.table == "Miembros") {
      state.nextPageMembers = payload.data;
    } else {
      state.nextPage = payload.data;
    }
  },
  [types.LOADER_TABLE_ON](state) {
    state.loaderTableEmployee = true;
  },
  [types.LOADER_TABLE_OFF](state) {
    state.loaderTableEmployee = false;
  },
  [types.DROP_DOWN_THREE_DOTS](state, data) {
    state.activeDropdownThreeDots = data;
  },

  // aplly tagas from modal
  [types.ADD_TAGS_OF_FILTERS](state, payloadForTags) {
    let filter = payloadForTags.filter((e) => {
      return e.status == true;
    });
    if (filter.length <= 0) {
      state.initialFilters = null;
    } else {
      state.initialFilters = payloadForTags;
    }
    state.tagsOfFilters = filter;
  },
  [types.CLEAN_TAGS_OF_FILTERS](state) {
    state.initialFilters = null;
    state.tagsOfFilters = null;
  },
  [types.CLEAN_SELECTED_TAGS_OF_FILTERS](state, selectedtag) {
    state.tagsOfFilters = selectedtag;
  },
  [types.FILTER_SELECTED_TAGS](state, selectedtag) {
    state.initialFilters = selectedtag;
  },
  [types.SET_CURRENT_VIEW_SINGLE_EMPLOYEE](state, employee) {
    state.currentEmployee = employee;
  },
  [types.CLEAN_CURRENT_VIEW_SINGLE_EMPLOYEE](state) {
    state.currentEmployee = null;
    state.currentEmployeeFetch = null;
  },
  [types.SET_MARITAL_STATUS](state, marital) {
    let marital_status_opt = [
      {
        name: "Soltero/a",
        value: 0,
      },
      {
        name: "Casado/a",
        value: 1,
      },
      { name: "Conviviente civil", value: 2 },
      { name: "Divorciado/a", value: 3 },
      { name: "Viudo/a", value: 4 },
    ];
    let searchMarital = marital_status_opt.filter(
      (status) => status.value === marital
    );

    let marital_status_name =
      searchMarital.length > 0 ? searchMarital[0].name : "";

    let marital_status =
      searchMarital.length > 0 ? searchMarital[0].value : null;

    // let marital_status_name =
    //   marital_status_opt.length > 0 ? marital_status_opt[0].name : null;

    state.currentEmployeeFetch = {
      ...state.currentEmployeeFetch,
      marital_status,
      marital_status_name,
    };
  },
  [types.SET_CURRENT_USER_FETCH](state, { data, isActiveMember }) {
    if (data.salary != null) {
      let currency = data.salary.split(".");
      let cleanSymbol = currency[0].replace(/[$]/g, "");

      cleanSymbol = cleanSymbol
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      cleanSymbol = cleanSymbol
        .split("")
        .reverse()
        .join("")
        .replace(/^[.]/, "");
      data.salary = `$${cleanSymbol}`;
    } else {
      data.salary = null;
    }

    let payload = {
      nationality: data.nationality != null ? data.nationality : null,
      city: data.city != null ? data.city.name : null,
      state: data.state != null ? data.state.name : null,
      address: data.address != null ? data.address : null,
      birth_date: data.birth_date != null ? data.birth_date : null,
      group: data.group != null ? data.group : null,
      is_legal_representative: data.is_legal_representative ? true : false,
      is_legal_representative_display: data.is_legal_representative
        ? "Si"
        : "No",
      contract_type_display:
        data.contract_type == 0
          ? "Indefinido"
          : data.contract_type == 1
          ? "A plazo"
          : null,
      job_type_display:
        data.job_type == 0
          ? "Presencial"
          : data.job_type == 1
          ? "Teletrabajo"
          : null,
      bank_account_type_display:
        data.bank_account_type == 0
          ? "Corriente"
          : data.bank_account_type == 1
          ? "Vista"
          : data.bank_account_type == 2
          ? "de Ahorro"
          : null,
      working_hours_display:
        data.working_hours == 0
          ? "Horario Fijo"
          : data.working_hours == 1
          ? "Sin horario (Art. 22 inciso 2do)"
          : null,
      marital_status: data.marital_status != null ? data.marital_status : null,
      marital_status_name:
        data.marital_status_name != null ? data.marital_status_name : null,
      occupation: data.occupation != null ? data.occupation : null,
      position: data.position != null ? data.position : null,
      position_responsabilities:
        data.position_responsabilities != null
          ? data.position_responsabilities
          : null,
      second_surname: data.second_surname != null ? data.second_surname : null,
      middle_name: data.middle_name != null ? data.middle_name : null,
      company_ingress_date:
        data.company_ingress_date != null ? data.company_ingress_date : null,
      isActiveMember: isActiveMember,
      contract_type: data.contract_type != null ? data.contract_type : null,
      job_type: data.job_type != null ? data.job_type : null,
      bank_account_type:
        data.bank_account_type != null ? data.bank_account_type : null,
      working_hours: data.working_hours != null ? data.working_hours : null,
      phone: data.phone != null ? data.phone : null,
      bank: data.bank != null ? data.bank.id : null,
      bank_display: data.bank != null ? data.bank.name : null,
      bank_account_number:
        data.bank_account_number != null ? data.bank_account_number : null,
      salary: data.salary != null ? data.salary : null,
      // salary_display: salary_display != null ? salary_display : null,
      health_insurance:
        data.health_insurance != null ? data.health_insurance.id : null,
      health_insurance_display:
        data.health_insurance != null ? data.health_insurance.name : null,
      social_security:
        data.social_security != null ? data.social_security.id : null,
      social_security_display:
        data.social_security != null ? data.social_security.name : null,
    };
    state.currentEmployeeFetch = payload;
  },
  [types.SET_ACTIVE_TABLE](state, payload) {
    state.tableMembersActive = payload;
  },

  [types.SET_COUNTRY_EMPLOYEE](state, payload) {
    state.NationalityStateEmployee = payload;
  },
  [types.SET_REGION_EMPLOYEE](state, payload) {
    state.RegionStateEmployee = payload;
  },

  [types.SET_CITY_EMPLOYEE](state, payload) {
    state.CityStateEmployee = payload;
    state.baseInformation.city_id = null;
  },
  //counter to progress bar in create employeee
  [types.COUNTER__STEP__EMPLOYEE](state, payload) {
    state.stepsEmployee = payload;
  },
  [types.COUNTER__STEP__EMPLOYEE_ONBOARDING](state, payload) {
    state.stepsEmployeeOnBoarding = payload;
  },
  [types.CLEAN_COUNTER__STEP__EMPLOYEE](state) {
    state.stepsEmployee = 0;
    state.typeUser = "";
    state.typeUserCompanyId = null;
  },
  [types.CLEAN_COUNTER__STEP__EMPLOYEE_ONBOARDING](state) {
    state.stepsEmployeeOnBoarding = 1;
  },

  // set type of user for header in create employee
  [types.SET_TYPE_USER](state, data) {
    state.typeUser = data;
  },
  [types.SET_TYPE_USER_ID_COMPANY](state, data) {
    state.typeUserCompanyId = data;
  },
  [types.SAVE_BASE_USER_INFORMATION](state, data) {
    state.baseInformation = data;
  },
  [types.SAVE_BASE_USER_COMPANY](state, data) {
    state.baseInformationCompany = data;
  },
  [types.SAVE_BASE_USER_BANK](state, data) {
    state.baseInformationBank = data;
  },
  [types.CLEAN_BASE_USER_INFORMATION](state) {
    let payload = {
      first_name: "",
      middle_name: null,
      surname: "",
      second_surname: null,
      nin: "",
      email: "",
      marital_status: null,
      address: null,
      nationality_id: "",
      state_id: null,
      city_id: null,
      birth_date: null,
      is_member: null,
      //datos no identificados
    };
    let payload_company = {
      position: null,
      position_responsabilities: null,
      company_ingress_date: null,
      group: null,
      occupation: null,
      is_legal_representative: null,
      contract_type: null,
      salary: null,
      job_type: null,
      working_hours: null,
      health_insurance_id: null,
      social_security_id: null,
    };
    let payload_bank = {
      bank_id: null,
      bank_account_type: null,
      bank_account_number: null,
    };
    state.baseInformation = payload;
    state.baseInformationCompany = payload_company;
    state.baseInformationBank = payload_bank;
  },

  //List for values in employee (edit/create)

  [types.SET_LIST_BANKS](state, data) {
    state.listBanks = data;
  },
  [types.SET_LIST_HEALTH_INSURANCE](state, data) {
    state.listHealthInsurance = data;
  },
  [types.SET_LIST_SOCIAL_SECURITY](state, data) {
    state.listSocialSecurity = data;
  },

  [types.SET_LIST_OPTION_USER_PROFILE](state, data) {
    let payload = {
      contract_type: data.contract_type.choices,
      job_type: data.job_type.choices,
      bank_account_type: data.bank_account_type.choices,
      working_hours: data.working_hours.choices,
    };
    state.listOptionUserProfile = payload;
  },

  [types.FLAG_ACTIVE_RESULTS_EMPLOYEE](state) {
    state.noResultsflagEmployee = true;
  },
  [types.FLAG_DESACTIVE_RESULTS_EMPLOYEE](state) {
    state.noResultsflagEmployee = false;
  },
  [types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER](state) {
    state.noResultsflagEmployeeMember = true;
  },
  [types.FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER](state) {
    state.noResultsflagEmployeeMember = false;
  },
  [types.ADD_DATA_FOR_DELETE_USER_THREE_DOTS](state, data) {
    state.deleteIdUser = data;
  },

  [types.FLAG_ACTIVE_INPUT_SEARCH_USER](state, data) {
    state.inputSearchActiveUser = data;
  },
  [types.FLAG_INACTIVE_INPUT_SEARCH_USER](state) {
    state.inputSearchActiveUser = false;
  },
  [types.FLAG_ACTIVE_INPUT_SEARCH_MEMBER](state, data) {
    state.inputSearchActiveMember = data;
  },
  [types.FLAG_INACTIVE_INPUT_SEARCH_MEMBER](state) {
    state.inputSearchActiveMember = false;
  },
};
