<template>
  <Transition v-if="GetFlagLoaderCards" name="fade">
    <div class="card-single base-card card-blue">
      <div class="card-group">
        <h1 class="card-special-blue">
          {{ title }}
          <br />
          <span>(últimos 6 meses)</span>
        </h1>

        <div class="value-primary-grap">
          {{ CountUsers }}
        </div>
      </div>

      <div class="container-user" v-for="(user, index) in Users" :key="index">
        <div class="container-card-user">
          <div class="title-name">{{ user.name }}</div>

          <div class="sub-title-user fix">
            {{ user.rol }}
          </div>
        </div>
        <div class="sub-title-user">
          {{ user.date }}
        </div>
      </div>
    </div>
  </Transition>
  <div v-else class="card-single base-card">
    <div class="title_skeleton">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="15"
        animation="fade"
      />
    </div>
    <div class="container_fix">
      <div class="">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="15"
          animation="fade"
        />
      </div>

      <div class="test">
        <VueSkeletonLoader
          type="circle"
          :width="50"
          :height="50"
          animation="fade"
        />
      </div>
    </div>
    <div class="body_skeleton_features">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="270"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="270"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["title", "Users"],
  components: {
    VueSkeletonLoader,
  },

  data: function () {
    return {
      maxValue: "",
      total: 0,
    };
  },

  computed: {
    ...mapGetters("Home", {
      CountUsers: "CountUsers",
      GetFlagLoaderCards: "GetFlagLoaderCards",
    }),
  },

  created() {},
};
</script>

<style lang="scss" scoped>
@import "./cardFeatures.scss";
@import "../sharedComponents.scss";
:root {
  --card-blue: #bfd8ff;
  --user-text: #1f2f59;
  --sub-title-user: #464646;
}
.container-user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}

.title-name {
  color: var(--user-text) !important;
}
.sub-title-user{
  color: var(--sub-title-user)
}

.card-blue {
  background: var(--card-blue);
}

.container-card-user {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 0.2rem;
}
</style>
