export default {
  loaderAssistance: false,
  AssignedAdminEmployees: null,
  AssignedRequesterEmployees: null,
  categoryAssistance: [],
  //get assistance for table cards assistance
  fetchListAssistance: [],
  //for managment errors or next
  listCategoryFailure: false,
  listCategorySuccess: true,
  //list table cards assistance filtered
  filterResultCategoryAssistance: [],
  //list table cards assistance search
  searchResultCategoryAssistance: [],
  // selected single cards assistance to view
  currentSingleAssistance: null,
  skeletonFilter: false,
  // for list cards
  nextPage: null,
  prevPage: null,
  lastPage: "",
  initialPage: "",
  total_data: "",
  total_data_view: "",
  flagData: null,
  confirmAssistanceModal: true,
  listGroupAssistance: null,
  categoryAssistanceForTemplate: null,
  currentSelectAssistanceFlag: false,
  flagFilterMobileAssistance: false,
  //tag asociado a la vista del front
  tagsOfFilters: null,
  initialFilters: null,
  loaderTableEmployee: false,
  flagActiveFilters: false,
  dataActiveFilters: [],
  editMode: false,
  editModeFromList: false,
  // this is used to show no results in html
  noResultsflag: false,
  employees: [],
  totalPages: null,
  flagActiveInputSearch: false,
  //loader
  loaderActive: false,
  //company of user request url
  companyCurrentRequest: null,

  dataInputSearchTags: false,


  stepsAssistanceOnBoarding: 1,
  //TODO: dummy
  newUserAsigned: false,

  tabAssigned: false,

  assistanceRouteParamsId: null,

  //id requester for force assigment in external tenant
  idRequesterExternal: null
};
