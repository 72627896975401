<template>
  <div class="modal__content">
    <div class="image_onboarding_employee onboarding_img_fix">
      <FirtsImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_employee">¡Bienvenido!</div>
      </div>
      <div class="text_container_onboarding ">
        <div class="">
          1. En esta sección podrás administrar a los miembros de tu equipo o
          empleados.
        </div>
        <div class="">
          2. Tu equipo está compuesto por “miembros” y “usuarios”
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FirtsImageOnBoarding from "./assets/First_step_onboarding.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    FirtsImageOnBoarding,
  },
  data() {
    return {
      stepOnBoarding: 1,
    };
  },
  created() {

  },
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployeesOnBoarding: "stepEmployeesOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepEmployeesOnBoarding + 1;

      this.$store.dispatch(
        "EmployeesV2/counterStepEmployeeOnBoarding",
        increment
      );
    },
     cancelModal() {
      let modal = document.querySelector("#modal__onboarding__employee");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
@import "./onBoarding.scss";
</style>
