<template>
  <div class="modal__content">
    <div class="onboarding_img_fix image_onboarding_third_assistance">
      <FifthImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_assistance ">
          ¡Listo! ¿Ingresamos tu primera solicitud?
        </div>
      </div>
      <div class="text_container_onboarding text_first_assistance">
        <div class="">
          Comencemos pidiendo tu primer servicio
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FifthImageOnBoarding from "./assets/fifthStepAssistance.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    FifthImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Assistance", {
      stepAssistanceOnBoarding: "stepAssistanceOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepAssistanceOnBoarding + 1;

      this.$store.dispatch(
        "Assistance/counterStepAssistanceOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss"></style>
