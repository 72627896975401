<template>
  <div class="" v-if="group === 'Admin'">
    <HomeUser />
  </div>

  <HomeUser v-else-if="group === 'Company-Admin'" />

  <HomePageEmployees v-else />
</template>

<script>
import { mapGetters } from "vuex";
import HomeUser from "./HomePageUser.vue";
import HomePageEmployees from "./HomePageEmployees.vue";

export default {
  name: "Home",
  components: {
    HomeUser,
    HomePageEmployees,
  },

  computed: {
    ...mapGetters("Login", ["is_staff", "group"]),
  },

  created() {},
};
</script>

<style lang="scss"></style>
