export default {
  applyDarkmoke() {
    document.documentElement.style.setProperty("--container_module", "#181818");
    // common scss
    document.documentElement.style.setProperty(
      "--footer_information",
      "#121212"
    );
    document.documentElement.style.setProperty(
      "--arrow_icon",
      "white"
    );
    document.documentElement.style.setProperty(
      "--filter_icon_employee",
      "#3ea6ff"
    );
    document.documentElement.style.setProperty("--line_primary", "#aaa");
    document.documentElement.style.setProperty("--container_base", "#2F2F2F");
    document.documentElement.style.setProperty(
      "--input_focus_background",
      "#121212"
    );
    document.documentElement.style.setProperty(
      "--input_color_placeholder",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--input_border_focus",
      "#86b7fe"
    );
    document.documentElement.style.setProperty(
      "--input_border_normal",
      "#ced4da"
    );
    document.documentElement.style.setProperty("--input_text_color", "white");
    document.documentElement.style.setProperty("--text_area_focus", "#aaa");
    document.documentElement.style.setProperty("--icon_filter", "#3ea6ff");

    //main-header
    document.documentElement.style.setProperty("--base-header", "#2F2F2F");
    document.documentElement.style.setProperty("--menu-header", "#2F2F2F");
    document.documentElement.style.setProperty(
      "--header__logo__title",
      "white"
    );
    document.documentElement.style.setProperty("--header__svg__icon", "#aaa");
    document.documentElement.style.setProperty(
      "--header__svg__icon_hover",
      "#181818"
    );
    document.documentElement.style.setProperty(
      "--header__company__name",
      "#3ea6ff"
    );

    //sidebar
    document.documentElement.style.setProperty("--base-sidebar", "#2F2F2F");
    document.documentElement.style.setProperty("--nav-active", "#212121");
    document.documentElement.style.setProperty("--burger-header", "white");
    document.documentElement.style.setProperty("--base-title-header", "white");
    document.documentElement.style.setProperty("--name-user-header", "white");

    // base body
    document.documentElement.style.setProperty("--main-color", "#181818");
    //module home
    document.documentElement.style.setProperty("--value_cards", "#aaa");
    document.documentElement.style.setProperty("--base-cards", "#2F2F2F");
    document.documentElement.style.setProperty("--title-card", "white");
    document.documentElement.style.setProperty("--title-grap", "#aaa");
    document.documentElement.style.setProperty("--month-text", "#aaa");
    document.documentElement.style.setProperty("--user-text", "#aaa");
    document.documentElement.style.setProperty("--card-blue", "#2F2F2F");
    document.documentElement.style.setProperty("--sub-title-user", "#3ea6ff");
    document.documentElement.style.setProperty("--utility__title", "white");
    document.documentElement.style.setProperty("--text-container", "#aaa");
    document.documentElement.style.setProperty("--base-sub-title-card", "#aaa");
    document.documentElement.style.setProperty("--text-link", "#3ea6ff");

    //settings
    document.documentElement.style.setProperty("--bg-active_change_edit", "#3EA6FF");

    document.documentElement.style.setProperty("--item_label", "#aaa");
    document.documentElement.style.setProperty("--base-settings", "#2F2F2F");
    document.documentElement.style.setProperty(
      "--title_active_settings",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--title_desactive_settings",
      "#aaa"
    );
    document.documentElement.style.setProperty("--fill__svg_sidebar", "#aaa");
    document.documentElement.style.setProperty(
      "--container__simple__label",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--title__simple__label",
      "black"
    );
    document.documentElement.style.setProperty("--title__labels", "#aaa");
    document.documentElement.style.setProperty("--bg__inputs", "#aaa");
    document.documentElement.style.setProperty(
      "--button-active-confirm",
      "#2F2F2F"
    );
    document.documentElement.style.setProperty(
      "--title__inside__breadcrumb",
      "#aaa"
    );

    // module documents
    document.documentElement.style.setProperty("--title__container", "white");
    document.documentElement.style.setProperty(
      "--call_to_action_button",
      "#3ea6ff"
    );
    document.documentElement.style.setProperty("--title_link_header", "white");
    document.documentElement.style.setProperty(
      "--title_secondary_link",
      "#aaa"
    );
    document.documentElement.style.setProperty("--title_link", "#3ea6ff");
    document.documentElement.style.setProperty(
      "--description_text_document",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--input_search_document",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--title_request_document",
      "white"
    );
    document.documentElement.style.setProperty(
      "--sub_title_request_docuemnt",
      "#aaa"
    );

    //module company
    document.documentElement.style.setProperty(
      "--table_list_company",
      "#2F2F2F"
    );

    //module report
    document.documentElement.style.setProperty(
      "--title_container_report",
      "white"
    );
    document.documentElement.style.setProperty("--text_item_selection", "#aaa");
    document.documentElement.style.setProperty("--subtitle_report", "#aaa");
    document.documentElement.style.setProperty("--subtitle_report_option_border", "#aaa");
    document.documentElement.style.setProperty("--subtitle_report_option", "white");
    document.documentElement.style.setProperty("--date_text_report", "#3ea6ff");

    //module company

    document.documentElement.style.setProperty(
      "--svg_company_navbar",
      "black"
    );
    document.documentElement.style.setProperty(
      "--title_container_company",
      "white"
    );
    document.documentElement.style.setProperty(
      "--text_item_selection_company",
      "#aaa"
    );
    document.documentElement.style.setProperty("--svg_company", "white");
    document.documentElement.style.setProperty(
      "--text_link_company",
      "#3ea6ff"
    );
    document.documentElement.style.setProperty("--subtitle_company", "#3ea6ff");
    document.documentElement.style.setProperty(
      "--text_container_modal",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--text_suboption_company",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--border_select_company",
      "#aaa"
    );
    document.documentElement.style.setProperty("--svg_company_key", "white");
    document.documentElement.style.setProperty(
      "--svg_company_circle",
      "#2F2F2F"
    );
    document.documentElement.style.setProperty(
      "--svg_company_pagination",
      "white"
    );
    document.documentElement.style.setProperty(
      "--title_container_card_company",
      "white"
    );
    //module employee
    document.documentElement.style.setProperty("--cancel-button", "white");
    document.documentElement.style.setProperty(
      "--button-active-confirm_edit",
      "#3EA6FF"
    );
    document.documentElement.style.setProperty(
      "--button_verify_employee",
      "#aaa"
    );
    document.documentElement.style.setProperty("--text_table_employee", "#aaa");
    document.documentElement.style.setProperty("--check_icon_employee", "#aaa");
    document.documentElement.style.setProperty(
      "--focus_table_employee",
      "transparent"
    );
    //module quotation
    document.documentElement.style.setProperty("--title_quotation", "white");
    document.documentElement.style.setProperty("--text_quotation", "#aaa");

    //module profile
    document.documentElement.style.setProperty("--title_profile", "white");
    document.documentElement.style.setProperty("--text_profile", "#aaa");
    document.documentElement.style.setProperty("--button_profile", "#aaa");
    document.documentElement.style.setProperty("--cancel_icon", "#aaa");

    //digital signature

    document.documentElement.style.setProperty(
      "--text_documents_aler",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--background_sign_selection",
      "#212121"
    );
    document.documentElement.style.setProperty(
      "--container_pre_step",
      "#2F2F2F"
    );

    document.documentElement.style.setProperty("--text-document-draf", "#aaa");
    document.documentElement.style.setProperty(
      "--text_documennt_active",
      "white"
    );
    document.documentElement.style.setProperty(
      "--card_btn_pre_step",
      "#212121"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_pre_step",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_pre_step_user",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_hover_pre_step",
      "#717171"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_hover_pre_step_invert",
      "#212121"
    );
    document.documentElement.style.setProperty(
      "--border_card_btn_hover_pre_step",
      "#717171"
    );
    document.documentElement.style.setProperty(
      "--border_card_btn_hover_pre_step_user",
      "#717171"
    );
    document.documentElement.style.setProperty("--svg_pre_step", "#aaa");
    document.documentElement.style.setProperty("--title_pre_step", "white");
    document.documentElement.style.setProperty("--title_origin", "#aaa");
    document.documentElement.style.setProperty(
      "--link_origin_document",
      "#212121"
    );
    document.documentElement.style.setProperty(
      "--border_link_origin_document",
      "#717171"
    );
    document.documentElement.style.setProperty(
      "--text_link_origin_document",
      "#aaa"
    );
    document.documentElement.style.setProperty("--bg-selected-draf", "#3ea6ff");
    document.documentElement.style.setProperty("--title_sing_from_app", "#aaa");
    document.documentElement.style.setProperty(
      "--sub_title_sing_from_app",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--head_table_dsignature",
      "white"
    );
    document.documentElement.style.setProperty(
      "--content_table_dsignature",
      "#aaa"
    );
    document.documentElement.style.setProperty("--header_table_doc", "white");
    document.documentElement.style.setProperty(
      "--bg_table_select_doc",
      "#212121"
    );
    document.documentElement.style.setProperty(
      "--bg_table_header_select_doc",
      "#2F2F2F"
    );
    document.documentElement.style.setProperty("--border_table_doc", "#aaa");

    // calendar boostrap
    document.documentElement.style.setProperty(
      "--bg_calendar_boostrap_secondary",
      "#2F2F2F"
    );
    document.documentElement.style.setProperty("--days_calendar", "white");
    document.documentElement.style.setProperty("--weekdays", "white");
    document.documentElement.style.setProperty("--month_calendar", "white");
    document.documentElement.style.setProperty(
      "--text_label_calendar",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--bg_calendar_boostrap",
      "#464646"
    );
    document.documentElement.style.setProperty(
      "--background_desactive_signature",
      "none"
    );
    document.documentElement.style.setProperty(
      "--bg_desactive_sing",
      "rgba(156, 156, 156, 0.3)"
    );
    document.documentElement.style.setProperty("--bg_active_sing", "#aaa");
    document.documentElement.style.setProperty("--bg_desactive_sing", "#aaa");
    document.documentElement.style.setProperty(
      "--bg_active_sing_border",
      "#212121"
    );
    document.documentElement.style.setProperty(
      "--sub_title_selected_sing",
      "#aaa"
    );
    document.documentElement.style.setProperty("--svg_confirm_stroke", "white");
    document.documentElement.style.setProperty("--svg_confirm_fill", "#aaa");
    document.documentElement.style.setProperty("--active_set_draf", "#3EA6FF");
    document.documentElement.style.setProperty("--title_modal", "white");

    document.documentElement.style.setProperty(
      "--bg_active_set_draf",
      "#3EA6FF"
    );
    document.documentElement.style.setProperty(
      "--bg_active_delete_draf",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--disable_input_text",
      "#2F2F2F"
    );

    // module schedule

    document.documentElement.style.setProperty("--title_schedule", "white");
    document.documentElement.style.setProperty("--icon_background", "#212121");
    document.documentElement.style.setProperty(
      "--schedule_background",
      "#2F2F2F"
    );
    document.documentElement.style.setProperty("--icon_color_schedule", "#aaa");
    document.documentElement.style.setProperty("--text_schedule", "#aaa");
    document.documentElement.style.setProperty(
      "--border_schedule_cards",
      "#717171"
    );
    document.documentElement.style.setProperty(
      "--hover_schedule_cards",
      "#212121"
    );
    document.documentElement.style.setProperty("--links_schedule", "#3ea6ff");
    document.documentElement.style.setProperty(
      "--links_my_schedule",
      "#3ea6ff"
    );
    document.documentElement.style.setProperty(
      "--background_navbar_schedule",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--info_meeting_title_schedule",
      "white"
    );
    document.documentElement.style.setProperty(
      "--info_meeting_schedule",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--arrow_down_schedule",
      "#3ea6ff"
    );
    document.documentElement.style.setProperty(
      "--navbar_text_schedule",
      "#aaa"
    );
    document.documentElement.style.setProperty(
      "--number_card_schedule",
      "black"
    );
    document.documentElement.style.setProperty(
      "--schedule_background_navbar",
      "#2F2F2F"
    );
    //button signer
    document.documentElement.style.setProperty(
      "--button_add__signer",
      "white"
    );
    document.documentElement.style.setProperty(
      "--error_message_colors",
      "white"
    );
  },
};
