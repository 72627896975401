  <template>
  <div class="" @click="displayModal($event)" v-if="hydrated">
    <div :is="layout">
      <div id="overlay2" class="overlay">
        <div
          class="spinner-border text-primary loader-spin text-center"
          role="status"
        ></div>
        <span class="text-loader">Validando información...</span>
      </div>

      <router-view />
    </div>
  </div>
</template>

<script>
const default_layout = "default";
import { mapState, mapGetters } from "vuex";
import DarkModeScript from "@/utils/darkmode.js";
import LightModeScript from "@/utils/lightmode.js";

export default {
  name: "App",
  async created() {
    this.$store.dispatch("Login/modalClose");

    await this.$apollo.provider.defaultClient.hydrated();
    this.hydrated = true;
  },
  data: () => {
    return {
      hydrated: false,
    };
  },
  components: {},
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    ...mapState(["token"]),
    ...mapGetters("Login", {
      modalstate: "modalstate",
    }),
    ...mapGetters("Config", {
      modalRef: "modalRef",
    }),
    ...mapGetters("SettingsApp", {
      UserSettingsInstance: "UserSettingsInstance",
      defaultValueLenguageUser: "defaultValueLenguageUser",
      defaultValueDarkMode: "defaultValueDarkMode",
    }),
  },
  methods: {
    displayModal(e) {
      /*--------------------------------
         @Input: modalState obtain from vuex
         @Description: 1. if is exist(modal) and modalState is true
                       2. obtain modal selector from current view if exist
                       3. close modal
         @Output: modalState = false
      //--------------------------------*/
      if (this.modalstate) {
        let id_modal = `#${this.modalRef}`;
        let modal = document.querySelector(id_modal);
        if (e.target.nodeName == "DIALOG") {
          if (this.defaultValueDarkMode == "Desactivado") {
            LightModeScript.applyLightmode();
          } else {
            DarkModeScript.applyDarkmoke();
          }
          modal.setAttribute("closing", "");
          modal.close();
          modal.removeAttribute("closing");
          this.$store.dispatch("Login/modalClose");
          this.$store.dispatch(
            "SettingsApp/activeChangeUserDarkModeInstance",
            false
          );
        }
      }
    },
  },
};
</script>

<style >
@import "./assets/styles/fonts.css";

body {
  font-family: Conv_HelveticaLTStd-Light;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100vh;
  position: absolute;
  transition: opacity 250ms ease;
  width: 100vw;
  opacity: 0;
  display: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.active-overlay {
  opacity: 0.45;
  display: flex !important;
}
.loader-spin {
  opacity: 1 !important;
  width: 120px !important;
  height: 120px !important;
}
.desactive-overlay {
  display: none;
}
.text-loader {
  position: relative !important;
  color: white;
}
</style>
