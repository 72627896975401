<template>
  <div class="container-slots container__base__setting">

    <div class="title__global__settings">Configuraciones</div>
    <div class="container__settings__view">
      <div class="container__settings__body">
        <div class="left__section__settings title-container color__tertiary">
          <div class="" v-for="(element, index) in categoryConfig" :key="index">
            <!-- revisamos cual de las settings esta activa para agregar o no la clase-->
            <div
              :class="
                element.active == true
                  ? 'activeBackgroundSetting'
                  : 'desactiveBackgroundSetting'
              "
              class="setting__item"
              v-if="element.status == true"
              @click="OpenCongif(element.id)"
            >
              <!-- Icon sidebar element -->
              <div class="" v-if="element.id == 1">
                <IconUser />
              </div>
              <div class="" v-if="element.id == 2">
                <IconCompany />
              </div>
              <div class="" v-if="element.id == 3">
                <IconRegional />
              </div>

              <!-- title sidebar element -->

              <div class="">{{ element.title }}</div>
            </div>
            <div class="setting__item__desactive" v-else>
              <!-- Elements with status in false are inaccessible items -->
              <div class="" v-if="element.id == 1">
                <IconUser />
              </div>
              <div class="" v-if="element.id == 2">
                <IconCompany />
              </div>
              <div class="" v-if="element.id == 3">
                <IconRegional />
              </div>

              <div class="" v-if="element.id == 4">
                <IconGlobal />
              </div>

              <div class="">{{ element.title }}</div>
            </div>
          </div>
        </div>

        <div
          class="right__section__settings"
          v-if="UserSettingsInstance != null"
        >
          <SettingsUser v-if="initialElement && UserSettingsInstance != null" />
          <Transition name="fade">
            <SettingsUser v-if="SettingActive == 'SettingsUserActive'" />
            <SettingsCompany v-if="SettingActive == 'SettingsCompanyActive'" />

            <SettingsRegional
              v-if="SettingActive == 'SettingsRegionalActive'"
            />
            <SettingsGlobal v-if="SettingActive == 'SettingsGlobalActive'" />
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsCompany from "./pages/SettingsCompany.vue";
import SettingsGlobal from "./pages/SettingsGlobal.vue";
import SettingsRegional from "./pages/SettingsRegional/SettingsRegionalModule.vue";
import SettingsUser from "./pages/SettingsUser.vue";
import IconUser from "./assets/setting_user.svg";
import IconCompany from "./assets/settings_company.svg";
import IconGlobal from "./assets/settings_global.svg";
import IconRegional from "./assets/settings_region.svg";


import { mapGetters } from "vuex";

export default {
  components: {
    SettingsCompany,
    SettingsGlobal,
    SettingsRegional,
    SettingsUser,
    IconUser,
    IconGlobal,
    IconRegional,
    IconCompany,

  },
  computed: {
    ...mapGetters("SettingsApp", {
      configView: "setViewSettingRegional",
    }),

    ...mapGetters("Login", {
      client_company_id: "company_id",
      countryCode: "countryCode",
    }),
    ...mapGetters("SettingsApp", {
      categoryConfig: "categoryConfigForTemplate",
      UserSettingsInstance: "UserSettingsInstance",
    }),
  },

  created() {
    localStorage.setItem("currentModule", "Settings");
    this.isMobile();

    let param = "modal";
    this.$store.dispatch("Config/setModalRef", param);
    let data = {
      client_company_id: this.client_company_id,
      countryCode: this.countryCode,
    };

    this.$store.dispatch("SettingsApp/getRetriveSettings", data);
    this.$store.dispatch("SettingsApp/activeChangeUserDarkModeInstance", false);
    this.$store.dispatch("SettingsApp/activeChangeUserLenguajeInstance", false);
    this.$store.dispatch("SettingsApp/activeChangeLimitUseInstance", false);
  },

  data: () => {
    return {
      initialElement: true,
      // flag use
      FlagSettingInterface: {
        SettingsUserActive: 1,
        SettingsCompanyActive: 2,
        SettingsRegionalActive: 3,
        SettingsGlobalActive: 4,
      },

      // variable que cambiara con cada seleccion de configuracion
      SettingActive: "",
    };
  },
  methods: {

    isMobile() {
      if (screen.width <= 1024) {
        return;
      } else {
        let sidebar = document.querySelector(".sidebar");
        let closeSidebar = document.getElementById("close-sidebar");
        if (closeSidebar != null) {
          closeSidebar.classList.add("active-btn-sidebar");
          closeSidebar.classList.remove("close-btn-sidebar");
        }

        let sibebarOutside = document.getElementById("new-close-sidebar");

        if (sibebarOutside != null) {
          sibebarOutside.classList.add("active-icon");
        }

        let removeLogoComplex = document.getElementById(
          "logo-desktop-complete"
        );

        let headerLogo = document.getElementById("logo-header");

        if (headerLogo != null) {
          headerLogo.classList.add("logo-visible");
        }

        if (removeLogoComplex != null) {
          // removeLogoComplex.classList.remove("close-btn-sidebar");
          removeLogoComplex.classList.add("close-btn-sidebar");
        }

        let btnvisible = document.getElementById("btn-mobile-sidebar");

        if (btnvisible != null) {
          btnvisible.classList.remove("close-btn-sidebar");
        }

        if (sidebar != null) {
          sidebar.classList.remove("active");
        }

        let mainContent = document.querySelector(".home_content");
        let header = document.querySelector("header");
        if (header != null) {
          header.classList.remove("active");
        }

        if (mainContent != null) {
          mainContent.classList.remove("home_content_active");
        }
      }
    },
    OpenCongif(idParam) {
      //--------------------------------
      // @Input: id element
      // Description:
      // 1.Set active element in sidebar Config's using id
      // 2.Run setViewInConfigs() for display a correspond view
      // @Output:
      // 1. Fuction setViewInConfigs()
      // 2. Active categoryConfig
      //--------------------------------

      // search id in list categoryConfig data for set active element in true/false
      let categoryConfigTemp = this.categoryConfig;
      let newCategoryConfigStatus = categoryConfigTemp.map((el) => {
        let data = {
          id: el.id,
          title: el.title,
          status: el.status,
          active: el.id == idParam ? true : false,
        };
        return data;
      });

      // set active category config with a new one for change color css
      this.$store.dispatch(
        "SettingsApp/setCategoryForTemplate",
        newCategoryConfigStatus
      );

      // run f to set correspond view
      this.setViewInConfigs(idParam, this.FlagSettingInterface);
    },
    setViewInConfigs(idParam, settingFlag) {
      // search setting correspond to idParam in settingFlag
      const foundSetting = Object.entries(settingFlag).find(([key, value]) => {
        if (key) return value === idParam;
      });

      // out-put ["keyName", value] nosotros usamos el keyName

      let permissionsName = foundSetting[0];

      // set SettingActive with keyName value correspond to idParam for display view
      this.SettingActive = permissionsName;

      this.initialElement = false;

      if (idParam == 3) {
        //--------------------------------
        // @Input: id param = 3 , default_view = 0
        //Description: set value in true for display default view in module
        // case 0 correspond default view when user click in settings regional for display default regional settings view
        // @Output: set new object for RouterViewSettingRegional state through vuex action for set this through one mutation.
        //--------------------------------
        this.$store.dispatch("SettingsApp/setViewRegionSettings", 0);
      }
    },
  },

};
</script>

<style lang="scss">
@import "./SettingsModule.scss";
</style>
