<template>
  <div class="loader_selected_employee" v-if="loading">
    <loaderGeneric />
  </div>

  <div class="container__selected__employee" v-else>
    <ModalOnBoardingEmployee
      ref="modal__onboarding__employee"
      size="onboarding"
      indicator="1"
      ref_id="modal__onboarding__employee"
    />
    <div class="">
      <div class="header_selected__employee">
        <div class="text_document">
          <div class="">Añadir nuevo miembro del equipo</div>
          <div class="icon_onBoarding_add_select" @click="OpenOnBoarding()">
            <IconOnboarding />
          </div>
        </div>

        <div class="close_selected" @click="cancelCreation()">
          <CloseIcon />
        </div>
      </div>
      <div class="sub__title__selected title__select_company">
        ¿A que empresa pertenece tu miembro del equipo?
      </div>
      <div class="custom-select test_input">
        <select
          class="select_field input-form input_assistance"
          v-model="listCompanies"
          id="listCompanies"
          @change="selectedCreateUserCompanyId()"
        >
          <option selected hidden disabled>Seleccione una respuesta</option>
          <option
            v-for="(el, index) in list__companies"
            :value="{ id: el.id, title: el.name }"
            :key="index"
          >
            {{ el.name }}
          </option>
        </select>
        <span class="custom-arrow-b"></span>
      </div>
      <div class="container_user_selection">
        <div class="sub__title__selected">
          ¿Qué tipo de miembro quieres añadir?
        </div>

        <div
          class="add__member"
          :class="listCompanies != null ? '' : 'desactive_selection'"
          @click="selectedCreateUser('Miembro')"
        >
          <div class="container__svg__icon__selected">
            <Membercards />
          </div>
          <div class="container__text_selected_user">
            <div class="title__add__member">
              Añadir nuevo <span> “Miembro” </span>
            </div>
            <div class="sub__title__add__member">
              Los “miembros” que crees no tendrán acceso a la plataforma puedes
              guardar todos sus datos en esta sección para tener un accedo
              rápido a estos.
            </div>
          </div>
        </div>

        <div class="container__option__selected">
          <div class="line__v3"></div>
          <div class="text__middle">o si lo prefieres</div>
          <div class="line__v3"></div>
        </div>

        <div
          class="add__user"
          :class="listCompanies != null ? '' : 'desactive_selection'"
          @click="selectedCreateUser('Usuario')"
        >
          <div class="container__svg__icon__selected">
            <UserCard />
          </div>
          <div class="container__text_selected_user">
            <div class="title__add__member">
              Añadir nuevo

              <span> “Usuario” </span>
            </div>

            <div class="sub__title__add__user">
              Los “usuarios” que crees tendrán acceso a la plataforma, procura
              que todos los datos de tu nuevo usuario estén actualizados.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AddEmployeeIcon from "../assets//fi-rs-user-add.svg";
import Membercards from "../assets/membercards.svg";
import UserCard from "../assets/userCard.svg";
import CloseIcon from "../assets/close_icon_create_member.svg";
import loaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import IconOnboarding from "../OnBoarding/assets/Onboarding_icon2.svg";
import ModalOnBoardingEmployee from "../OnBoarding/ModalOnBoardingEmployee.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // AddEmployeeIcon,
    CloseIcon,
    loaderGeneric,
    IconOnboarding,
    ModalOnBoardingEmployee,
    Membercards,
    UserCard,
  },
  data: () => {
    return {
      listCompanies: null,

      loading: false,
    };
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__companies: "list__companies",
    }),
  },
  methods: {
    selectedCreateUser(user) {
      this.$store.dispatch("EmployeesV2/cleanBaseUserInformation");

      if (user == "Miembro") {
        this.$store.dispatch("EmployeesV2/setTableActive", true);
      } else {
        this.$store.dispatch("EmployeesV2/setTableActive", false);
      }
      if (this.listCompanies != null) {
        this.$store.dispatch("EmployeesV2/setTypeUserToCreate", user);
        this.$router.push({ name: "createEmployeeV2" });
      } else {
        return;
      }
    },
    selectedCreateUserCompanyId() {
      let userCompanySelectedId = this.listCompanies.id;
      this.$store.dispatch(
        "EmployeesV2/setTypeUserToCreateCompnayId",
        userCompanySelectedId
      );
    },

    cancelCreation() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
      }, 800);
      setTimeout(() => {
        this.$router.push({ name: "ListEmployeesV2" });
      }, 800);
    },
    OpenOnBoarding() {
      let param = "modal__onboarding__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__employee;
      element.open();
    },
  },
};
</script>

<style lang="scss">
.container__option__selected {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0.7rem;

  > .text__middle {
  }

  > .line__v3 {
    border: 2px solid #949aa8;
    width: 35%;
  }
}
</style>
