<template>
  <!-- Default view for config regional settings -->

  <div
    class="container__settings__regional inner"
    v-if="configView.default__view__settings__regional"
  >
    <!-- primer item de settings regional -->
    <div class="container__select">
      <NotAccessSettingInstances
        ref="modal__not_access"
        size="xs"
        :description="description"
      />
      <div class="container__select__option title-container">
        <span class="label-primary color__tertiary">Selecciona Región</span>
        <div class="custom-select">
          <select
            class="input-form input__form__fix"
            name=""
            id=""
            @change="selectedRegion($event)"
          >
            <option selected hidden disabled>
              {{ DefaultCountry }}
            </option>
            <option value="CL">Chile</option>
            <option value="CO">Colombia</option>
            <option value="MX">Mexico</option>
          </select>
          <span class="custom-arrow-b"></span>
        </div>
      </div>
    </div>
    <div class="" v-if="flagSkeleton">
      <div class="">
        <div class="">
          <div class="container__simple__label gap_label skeleton_mobile">
            <div class="skeleton_s">
              <VueSkeletonLoader
                type="rect"
                :height="10"
                :width="300"
                animation="fade"
              />
            </div>

            <div class="skeleton_m">
              <VueSkeletonLoader
                type="rect"
                :height="10"
                :width="600"
                animation="fade"
              />
            </div>
          </div>
          <div class="container__simple__label gap_label">
            <div class="skeleton_s">
              <VueSkeletonLoader
                type="rect"
                :height="10"
                :width="300"
                animation="fade"
              />
            </div>
            <div class="skeleton_m">
              <VueSkeletonLoader
                type="rect"
                :height="10"
                :width="600"
                animation="fade"
              />
            </div>
          </div>
          <div class="container__simple__label gap_label">
            <div class="skeleton_s">
              <VueSkeletonLoader
                type="rect"
                :height="10"
                :width="300"
                animation="fade"
              />
            </div>
            <div class="skeleton_m">
              <VueSkeletonLoader
                type="rect"
                :height="10"
                :width="600"
                animation="fade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Segundo elemento de settings regional-->
    <div
      class=""
      @click="
        setActiveViewRegionalSettings(el.paramsToRenderChild, el.accessSetting)
      "
      v-for="(el, index) in RegionSettingsInstance"
      :key="index"
      v-else
    >
      <SimpleLabelSelector
        :title="el.title"
        :value="el.description"
        :id="el.id"
        :accessSetting="el.accessSetting"
      />
    </div>
  </div>
  <!-- View setting regional / report limitation use -->
  <Transition v-else-if="configView.view__setting_report__limitation">
    <div class="">
      <ViewReportLimitation
        titleBreadcrumb="Limitaciones de uso en Chile"
        titleService="Reportes de uso"
      />
    </div>
  </Transition>

  <!-- View setting regional / active&inactive document-type region -->
  <Transition v-else-if="configView.view__setting_document_region">
    <div class="">
      <ViewDocumentRegion
        titleBreadcrumb="Activación y desactivación DocumenTypes por región"
        titleService="DocumentTypes activos en esta región"
      />
    </div>
  </Transition>

  <!-- View setting regional / Document type role -->
  <Transition v-else-if="configView.view__setting_document_role">
    <div class="">
      <ViewDocumentRoles
        titleBreadcrumb="Enlace de DocumentTypes a roles"
        titleService="DocumentTypes activos Roles"
      />
    </div>
  </Transition>
</template>

<script>
import SimpleLabelSelector from "../../components/SimpleLabelSelector";
import ViewDocumentRegion from "./ViewDocumentRegion.vue";
import ViewDocumentRoles from "./ViewDocumentRoles.vue";
import ViewReportLimitation from "./ViewReportLimitation.vue";
import NotAccessSettingInstances from "../../components/modals/notAccessSettingInstances.vue";
import VueSkeletonLoader from "skeleton-loader-vue";

import { mapGetters } from "vuex";

export default {
  components: {
    SimpleLabelSelector,
    ViewDocumentRegion,
    ViewDocumentRoles,
    ViewReportLimitation,
    NotAccessSettingInstances,
    VueSkeletonLoader,
  },
  data: () => {
    return {
      idRegion: "CL",
      flagSkeleton: false,
      description: "",
      //TODO: hacer que estas vistas sean modificadas globalmente via store/action
    };
  },

  computed: {
    ...mapGetters("SettingsApp", {
      configView: "setViewSettingRegional",
      RegionSettingsInstance: "RegionSettingsInstance",
      DefaultCountry: "getDefaultCountryName",
      HasPermission: "HasPermission",
    }),
    ...mapGetters("Login", {
      client_company_id: "company_id",
    }),
  },
  methods: {
    setActiveViewRegionalSettings(param, accessSetting) {
      //--------------------------------
      // @Input: range in this case (1,2,3)
      //Description: set value in true for display selected view in module
      // case 1 correspond limitation view when user click in element of setting regional
      // case 2 correspond active/inactive document-type for region view when user click in element of setting regional
      // case 3 correspond document-type rol view when user click in element of setting regional
      // @Output: set new object for RouterViewSettingRegional state through vuex action for set this through one mutation.
      //--------------------------------
      if (!accessSetting) {
        this.description = "Configuracción no disponible";
        let param = "modal__not_access";
        this.$store.dispatch("Config/setModalRef", param);
        let element = this.$refs.modal__not_access;
        element.open();
      } else {
        this.$store.dispatch("SettingsApp/setViewRegionSettings", param);
      }
    },

    selectedRegion(e) {
      this.flagSkeleton = true;
      this.idRegion = e.target.value;
      let countryCode = e.target.value;
      this.$store.dispatch(
        "SettingsApp/setCountryCodeForRegionSettings",
        countryCode
      );

      let params = {
        client_company_id: this.client_company_id,
        scope: 2,
        country: countryCode,
      };

      this.$store
        .dispatch("SettingsApp/getSettingsInstances", params)
        .then(() => {
          setTimeout(() => {
            this.flagSkeleton = false;
          }, 600);
        })
        .then(() => {
          let Permission = this.HasPermission;
          if (!Permission) {
            this.description =
              "No tienes permisos a ninguna configuracion regional";
            let param = "modal__not_access";
            this.$store.dispatch("Config/setModalRef", param);
            let element = this.$refs.modal__not_access;
            element.open();
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "./SettingsRegional.scss";

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.gap_label {
  gap: 0.5rem;
}
</style>
