export default {
  employees: [],
  currentEmployee: null,
  currentEmployeeFetch: null,
  employeesMembers: [],
  offset: "",
  total_data: "",
  total_data_members: "",
  filterActive: false,
  loader: false,
  lastPage: "",
  initialPage: "",
  total_data_view: "",
  total_data_view_members: "",
  tableMembersActive: false,
  // TABLE 1
  nextPage: null,
  prevPage: null,
  totalPages: null,

  // TABLE 2
  nextPageMembers: null,
  prevPageMembers: null,
  totalPagesMembers: null,

  skeletonFilter: false,
  loaderTableEmployee: false,
  //companies
  listCompanies: null,
  companiSelected: null,

  activeDropdownThreeDots: false,
  activeDropdownState: false,
  activeDropdownVerified: false,

  dropDownSelectedState: {
    activAll: false,
    desactiveAll: false,
  },
  dropDownSelectedVerified: {
    VerifiedAll: false,
    desactiveVeriefiedAll: false,
  },

  //filter of tags
  tagsOfFilters: null,
  initialFilters: null,

  NationalityStateEmployee: null,
  RegionStateEmployee: null,
  CityStateEmployee: null,

  //create employee
  typeUser: "",
  typeUserCompanyId: null,
  stepsEmployee: 0,

  //base information creation user

  baseInformation: {
    first_name: "",
    middle_name: "",
    surname: "",
    second_surname: "",
    nin: "",
    email: "",
    marital_status: "",
    address: "",
    nationality_id: "",
    state_id: "",
    city_id: "",
    birth_date: "",
    //datos no identificados
  },
  baseInformationCompany: {
    position: "",
    company_ingress_date: "",
    group: null,
    occupation: "",
    is_legal_representative: null,
    contract_type: "",
    salary: "",
    job_type: "",
    working_hours: "",
    health_insurance: "",
    social_security: "",
  },
  baseInformationBank: {
    bank: "",
    bank_account_type: "",
    bank_account_number: "",
  },

  listBanks: null,
  listHealthInsurance: null,
  listSocialSecurity: null,

  listOptionUserProfile: null,

  noResultsflagEmployee: false,
  noResultsflagEmployeeMember: false,

  //variables for use in delete user from three dots

  deleteIdUser: null,

  stepsEmployeeOnBoarding: 1,
  inputSearchActiveUser: false,
  inputSearchActiveMember: false,
};
