/* eslint-disable */
// this is an auto generated file. This will be overwritten
import gql from 'graphql-tag'

export const getThread = /* GraphQL */ `
  query GetThread($quotation_id: String!) {
    getThread(quotation_id: $quotation_id) {
      id
      quotation_id
      messages {
        nextToken
      }
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($quotation_id: String!, $id: String!) {
    getMessage(quotation_id: $quotation_id, id: $id) {
      id
      quotation_id
      text
      files {
        filename
        url
      }
      pinned
      createdAt
      updatedAt
      user {
        id
        full_name
        email
        initials
        is_staff
        is_attendant
      }
    }
  }
`;

export const GET_MESSAGES_FROM_THREAD = gql`
  query GetMessagesFromThread(
    $quotation_id: String!
  ) {
    getMessagesFromThread(
      quotation_id: $quotation_id
    ) {
      nextToken
      messages {
        id
        quotation_id
        text
        pinned
        createdAt
        updatedAt
        is_auto
        user {
          full_name
          initials
          is_staff
          is_attendant
        }
        files {
          filename
          url
        }
      }
    }
  }
`