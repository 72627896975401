<template>
  <div>
    <div class="home-container-grid">
      <div class="container-secondary">
        <ModalOnboardingHome
          ref="modal__onboarding__home"
          size="onboarding"
          :automaticValidation="fromAutomaticOnBoarding"
        />
        <CardDocumentsMain :listDocument="listDocument" />
        <CardUtilities />
        <div class="card-others card-documents">
          <CardEconomic title="Indicadores económicos" v-if="dataEconomic" />
          <div v-else class="card-single base-card card-special">
            <div class="title_skeleton">
              <VueSkeletonLoader
                type="rect"
                :width="200"
                :height="20"
                animation="fade"
              />
            </div>
            <div class="body_skeleton">
              <div class="">
                <VueSkeletonLoader
                  type="rect"
                  :width="100"
                  :height="10"
                  animation="fade"
                />
              </div>
              <div class="container_skeleton">
                <div class="skeleton_utilities">
                  <VueSkeletonLoader
                    type="rect"
                    :height="50"
                    animation="fade"
                  />
                </div>

                <div class="skeleton_utilities">
                  <VueSkeletonLoader
                    type="rect"
                    :height="50"
                    animation="fade"
                  />
                </div>
              </div>

              <div class="">
                <VueSkeletonLoader
                  type="rect"
                  :width="100"
                  :height="10"
                  animation="fade"
                />
              </div>
              <div class="container_skeleton">
                <div class="skeleton_utilities">
                  <VueSkeletonLoader
                    type="rect"
                    :height="50"
                    animation="fade"
                  />
                </div>
                <div class="skeleton_utilities">
                  <VueSkeletonLoader
                    type="rect"
                    :height="50"
                    animation="fade"
                  />
                </div>
              </div>
            </div>
          </div>
          <CardLinks title="Sitios de interés" v-bind:Sites="Sites" />
        </div>
      </div>
    </div>
    <div
      class="container_button_onBoarding container_button_digital_onboarding"
    >
      <div class="button_onBoarding color_hover_button_standard" @click="OpenOnBoarding">
        Explora la App
        <div class="icon_onboarding_button">
          <OnBoardingIconButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardUtilities from "../components/card-utilities/CardUtilities.vue";
import CardDocumentsMain from "../components/card-documents/CardDocumentsMain.vue";
import CardEconomic from "../components/card-economic/CardEconomic.vue";
import CardLinks from "../components/card-links/card-links.vue";

import miindicadorService from "../../../service/miindicador";
import documentService from "../services/homeCardServices";
import { mapGetters } from "vuex";
import OnBoardingIconButton from "@/assets/buttonIconOnboarding.svg";
import VueSkeletonLoader from "skeleton-loader-vue";
import ModalOnboardingHome from "../OnBoarding/modalOnboardingHome.vue";

export default {
  components: {
    CardUtilities,
    CardDocumentsMain,
    CardEconomic,
    CardLinks,
    VueSkeletonLoader,
    OnBoardingIconButton,
    ModalOnboardingHome,
  },

  data: function () {
    return {
      Sites: {},
      listDocument: [],
      dataEconomic: false,
      fromAutomaticOnBoarding: false,
    };
  },
  mounted() {
    if (this.group == "Company-Admin" || this.group == "Company-Executive") {
      if (this.user.show_onboarding_info) {
        this.AutomaticOpenBoarding();
      }
    }
  },
  created() {
    this.listDocumentTypes();
    this.economicData();
  },
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
      group: "group",
      user: "user_profile"
    }),
  },

  methods: {
    OpenOnBoarding() {
      let param = "modal__onboarding__home";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__home;
      element.open();
    },
    AutomaticOpenBoarding() {
      this.fromAutomaticOnBoarding = true;
      let param = "modal__onboarding__home";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__home;
      element.open();
    },
    economicData() {
      this.$store.dispatch("Home/loadingInitialDataForEconomics", false);
      miindicadorService
        .eco_indicators()
        .then((indicador) => {
          let data = {
            Dolar: `${indicador.dolar.valor}`,
            Euro: `${indicador.euro.valor}`,
            UTM: `${indicador.utm.valor}`,
            UF: `${indicador.uf.valor}`,
          };
          this.$store.dispatch("Home/setIndicadores", data);
        })
        .then(() => {
          this.dataEconomic = true;
        })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("Home/loadingInitialDataForEconomics", true);
          }, 1100);
        })
        .catch(() => {
          this.dataEconomic = false;
        });
    },

    listDocumentTypes() {
      this.$store.dispatch("Home/loadingInitialDataForDocuments", false);
      documentService
        .list_document_type(this.company_id, "id,name,abstract", 6, 0)
        .then((data) => {
          this.listDocument = data.results;
        })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("Home/loadingInitialDataForDocuments", true);
          }, 800);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../home.scss";
@import "../components/sharedComponents.scss";

.card-others {
  width: 400px;

  @media screen and (max-width: 1200px) and (min-width: 900px) {
    flex-direction: column;
  }
}

.container-secondary {
  @media screen and (max-width: 1023px) and (min-width: 900px) {
    flex-wrap: nowrap;
  }
}

.card-documents {
  min-width: 350px;
  max-width: 520px !important;

  @media screen and (max-width: 1024px) and (min-width: 765px) {
    width: 48.9% !important;
  }

  @media screen and (max-width: 1201px) and (min-width: 900px) {
    width: 20%;
  }
}
.home-container-grid {
  grid-template-columns: 100% !important;
}
</style>
