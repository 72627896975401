var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__assitance__remove",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : 'modal__default',attrs:{"id":"modal__assitance__remove"}},[_c('div',{staticClass:"title_remove text-filter-light"},[_vm._v(" ¿Estás seguro(a) que deseas eliminar la solicitud \" "),_c('span',[_vm._v(" "+_vm._s(_vm.subject)+" ”?")])]),_c('div',{staticClass:"title_remove text-filter-light"},[_vm._v(" Esta acción no se puede revertir ")]),_c('div',{staticClass:"footer_modal"},[_c('div',{staticClass:"btn-vue-primary fix-btn-mac fix-width",on:{"click":function($event){return _vm.deleteSignature(_vm.identifier_modal)}}},[_c('span',[_vm._v(" Si, confirmar ")])]),_c('div',{staticClass:"secondary_button",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Cancelar")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }