<template>
  <div class="container__box__chat">
    <div class="container__message_single_chat">
      <div class="container__message_pin">
        <div class="aling__pinned__messages">
          <div class="container__text__pinned">
            <div class="container_pinned_message">
              <div class="pinned_logo_chat">
                <LogoMA />
                <div class="message_title_full_name">
                  {{ message.user.full_name }}
                </div>
              </div>
              <div class="container_user_data">
                <div class="">
                  {{ message.text }}
                </div>
              </div>
            </div>
            <span class="text__pin" @click="togglePinFun">
              Eliminar mensaje marcado <TrashCan
            /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoMA from "../../assets/logoMA.svg";
import TrashCan from "../../assets/TrashcanMessage.svg";

export default {
  props: ["message"],

  created() {},

  components: {
    LogoMA,
    TrashCan,
  },
  data: () => {
    return {};
  },
  methods: {
    togglePinFun() {
      this.$emit("messageSelectedEmit", this.message);
    },
  },
};
</script>

<style lang="scss">
.container_pinned_message{
  width: 90%;
}
.aling__pinned__messages {
  display: flex;
  /* justify-content: flex-end; */
  width: 100%;
  flex-wrap: nowrap;
}

.container__text__pinned {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.pinned_logo_chat {
  display: flex;
  flex-wrap: nowrap;
  gap: .5rem;
  > svg {
    width: 34px;
    height: 34px;
    background: #000032;
    border-radius: 50%;
  }
}
.container_user_data {
  gap: 0.1rem;
  display: flex;
  flex-direction: column;
}
.text__pin {
  cursor: pointer;
}
.message_title_full_name {
  font-weight: bold;
  color: #0064ff !important;
  background: #bfd8ff !important;
  fill: solid;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  white-space: nowrap;
}
</style>
