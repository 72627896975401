import * as types from "./mutations-types";
//TODO: use types

export default {
  [types.GET_CATEGORY_ASSISTANCE](state, data) {
    state.categoryAssistance = data;
  },
  [types.GET_LIST_ASSISTANCE](state, data) {
    let payload = data.map((el) => {
      return {
        ...el,
        active: false,
      };
    });

    state.fetchListAssistance = payload;
    state.total_data_view = payload.length;
  },
  [types.GET_FILTER_LIST_ASSISTANCE](state, data) {
    state.filterResultCategoryAssistance = data;
  },
  [types.GET_SEARCH_LIST_ASSISTANCE](state, data) {
    state.searchResultCategoryAssistance = data;
  },
  [types.SET_NEW_ASSIGNED_USERS](state) {
    state.newUserAsigned = true;
  },
  // SET CURRENT ASISTANCE OBTAINED
  [types.SET_CURRENT_ASSISTANCE](state, data) {
    /*--------------------------------
    @Input: data service for date
    Description: Validate if the data attendant, lawyer, requester is not null or existing data , the object of the service and the especific value we need in the object
    @Output: if data service is null  return null & if data service exist return object with name and id
    //--------------------------------*/
    let attendantAssigned = null;
    let lawyerAssigned = null;
    let requesterAssigned = null;

    if (data.attendant != null) {
      let payloadForAttendant = {
        id: data.attendant.id,
        name: data.attendant.full_name,
        user: {
          id: data.attendant.id,
          is_staff: data.attendant.user.is_staff,
          email: data.attendant.user.email,
          full_name: data.attendant.full_name,
          initials: data.attendant.user.username.slice(0, 2).toUpperCase(),
          is_attendant: true

        },
      };
      attendantAssigned = payloadForAttendant;
    }

    if (data.lawyer != null) {
      let payloadForLawyer = {
        id: data.lawyer.id,
        name: data.lawyer.full_name,
        user: {
          id: data.lawyer.id,
          is_staff: data.lawyer.user.is_staff,
          email: data.lawyer.user.email,
          full_name: data.lawyer.full_name,
          initials: data.lawyer.user.username.slice(0, 2).toUpperCase(),
          is_attendant: false
        },
      };
      lawyerAssigned = payloadForLawyer;
    }



    if (data.requester != null) {
      let payloadRequester = {
        id: data.requester.id,
        name: data.requester.full_name,
        user: {
          id: data.requester.id,
          is_staff: data.requester.user.is_staff,
          email: data.requester.user.email,
          full_name: data.requester.full_name,
          initials: data.requester.user.username.slice(0, 2).toUpperCase(),
          is_attendant: false
        },
      };
      requesterAssigned = payloadRequester;
    }



    /*--------------------------------
     @Input: data service for date
     Description: Validate if the data date is not null or existing data in two levels , the object of the service and the especific value we need in the object
     @Output: if data service is null  or existing data the value is false
    //--------------------------------*/
    let fechaValidator =
      data.created_at != null ? data.created_at.slice(0, 10) : false;
    let fechaFinal;
    if (fechaValidator != false) {
      // formatted date data for view
      fechaFinal = fechaValidator
        .split("-")
        .reverse()
        .join("-")
        .replace("‐", "/");
    }
    /*--------------------------------
     @Input: data service for complete name (first name and surname )
     Description: Validate if the data complete name is not null or existing data in two levels , the object of the service and the especific value we need in the object
     @Output: if data service is null  or existing data the value is false
    //--------------------------------*/
    let userRequestValidator = data.requester != null ? data.requester : false;
    let completeName;
    let idCompanyRequester;
    if (userRequestValidator != false) {
      let first_name =
        userRequestValidator.first_name != null
          ? userRequestValidator.first_name
          : false;
      let surname =
        userRequestValidator.surname != null
          ? userRequestValidator.surname
          : false;
      if (first_name != false && surname != false) {
        // concatenate the first name with the surname
        completeName = `${data.requester.first_name} ${data.requester.surname} `;

        idCompanyRequester = data.requester.company.id;
      } else {
        userRequestValidator = false;
      }
    }
    /*--------------------------------
     @Input: data service for serviceType
     Description: Validate if the data serviceType is not null or existing data in two levels , the object of the service and the especific value we need in the object
     @Output: if data service is null  or existing data the value is false
    //--------------------------------*/
    let serviceType;
    let serviceTypeId;
    let serviceTypeValidator =
      data.service_type != null ? data.service_type : false;
    if (serviceTypeValidator != false) {
      serviceType =
        serviceTypeValidator.title != null ? serviceTypeValidator.title : false;
      serviceTypeId =
        serviceTypeValidator.id != null ? serviceTypeValidator.id : false;
      if (!serviceType) {
        serviceTypeValidator = false;
      }
    }
    /*--------------------------------
     @Input: data service for description
     Description: Validate if the data description is not null or existing data in two levels , the object of the service and the especific value we need in the object
     @Output: if data service is null  or existing data the value is false
    //--------------------------------*/
    let descriptionValue;
    let descriptionValidator =
      data.description != null ? data.description : false;
    if (descriptionValidator != false) {
      descriptionValue =
        descriptionValidator != null ? descriptionValidator : false;
      if (!descriptionValue) {
        descriptionValidator = false;
      }
    }
    /*--------------------------------
     @Input: data service for description Agreement
     Description: Validate if the data description Agreement is not null or existing data in two levels , the object of the service and the especific value we need in the object
     @Output: if data service is null  or existing data the value is false
    //--------------------------------*/
    let descriptionAgreementValue;
    let descriptionAgreementValidator =
      data.service_type != null ? data.service_type : false;
    if (descriptionAgreementValidator != false) {
      descriptionAgreementValue =
        descriptionAgreementValidator.agreement_terms != null
          ? descriptionAgreementValidator.agreement_terms
          : false;
      if (!descriptionAgreementValue) {
        descriptionAgreementValidator = false;
      }
    }

    // Validate if the validators has value , if not , we show "No data"
    let payload = {
      nombre: userRequestValidator != false ? completeName : "No data",
      idCompanyRequester: idCompanyRequester,
      // TODO: PENDIENTE SERVICIO
      tag: "Administrador",
      caseCode: data.display_id != null ? data.display_id : "No data",
      fecha: fechaValidator != false ? fechaFinal : "No data",
      attendantAssigned: attendantAssigned,
      lawyerAssigned: lawyerAssigned,
      requesterAssigned: requesterAssigned,
      baseInformation: {
        idRequest: data.id != null ? data.id : "No data",
        title: data.title != null ? data.title : "No data",
        serviceType: serviceTypeValidator != false ? serviceType : "No data",
        serviceTypeId:
          serviceTypeValidator != false ? serviceTypeId : "No data",
        description:
          descriptionValidator != false ? descriptionValue : "No data",
        requester: userRequestValidator != false ? completeName : "No data",
      },
      files: data.file_urls,

      isArrayFiles: Array.isArray(data.file_urls) == true ? true : false,
      termsAgreement: {
        // TODO: EN DURO
        // title: data.service_type.SLA,
        descriptionAgreement:
          descriptionAgreementValidator != false
            ? descriptionAgreementValue
            : "No data",
      },
    };
    state.currentSingleAssistance = payload;
  },

  [types.CLEAN_SINGLE_ASSISTANCE](state) {
    state.currentSingleAssistance = null;
  },
  //for table list cards

  [types.SET_TOTAL_DATA](state, data) {
    state.total_data = data;
  },
  [types.SET_TOTAL_DATA_VIEW](state, data) {
    state.total_data_view = data;
  },
  [types.SET_PREV_PAGE](state, data) {
    state.prevPage = data;
  },
  [types.SET_NEXT_PAGE](state, data) {
    state.nextPage = data;
  },
  [types.SET_FLAG](state, data) {
    state.flagData = data;
  },
  [types.SET_FALSE_MODAL_ASSISTANCE](state) {
    state.confirmAssistanceModal = false;
  },
  [types.SET_TRUE_MODAL_ASSISTANCE](state) {
    state.confirmAssistanceModal = true;
  },
  [types.SET_LIST_GROUP_ASSISTANCE](state, data) {
    state.listGroupAssistance = data;
  },
  [types.GET_RETRIVE_ASSISTANCE](state, data) {
    let categoryAssistance = [];

    data.map((el, index) => {
      let newValues = {
        id: index + 1,
        status: el.has_access ? el.has_access : false,
        active: index == 0 ? true : false,
      };
      categoryAssistance.push(newValues);
    });
    state.categoryAssistanceForTemplate = categoryAssistance;
  },
  [types.SET_FLAGS_SELECTED_OPTIONS_ASSISTANCE](state, data) {
    state.currentSelectAssistanceFlag = data;
  },

  // set filter for mobile version view
  [types.SET_FILTER_ASSISTANCE_MOBILE](state) {
    state.flagFilterMobileAssistance = true;
  },
  [types.CLEAN_FILTER_ASSISTANCE_MOBILE](state) {
    state.flagFilterMobileAssistance = false;
  },
  [types.ADD_TAGS_OF_FILTERS](state, payloadForTags) {
    let filter = payloadForTags.filter((e) => {
      return e.status == true;
    });
    if (filter.length <= 0) {
      state.initialFilters = null;
    } else {
      state.initialFilters = payloadForTags;
    }
    state.tagsOfFilters = filter;
  },
  [types.CLEAN_TAGS_OF_FILTERS](state) {
    state.initialFilters = null;
    state.tagsOfFilters = null;
  },
  [types.CLEAN_SELECTED_TAGS_OF_FILTERS](state, selectedtag) {
    state.tagsOfFilters = selectedtag;
  },
  [types.FILTER_SELECTED_TAGS](state, selectedtag) {
    state.initialFilters = selectedtag;
  },
  [types.SKELETON_FILTER_ON](state) {
    state.skeletonFilter = true;
  },
  [types.SKELETON_FILTER_OFF](state) {
    state.skeletonFilter = false;
  },
  [types.FLAG_ACTIVE_FILTER](state) {
    state.flagActiveFilters = true;
  },
  [types.FLAG_DESACTIVE_FILTER](state) {
    state.flagActiveFilters = false;
  },

  //EDIT ASSISTANCE CONTROLER
  [types.ACTIVE_EDIT_ASSISTANCE_FROM_LIST](state) {
    state.editModeFromList = true;
  },
  [types.DESACTIVE_EDIT_ASSISTANCE_FROM_LIST](state) {
    state.editModeFromList = false;
  },
  [types.ACTIVE_EDIT_ASSISTANCE_MODE](state) {
    state.editMode = true;
  },
  [types.DESACTIVE_EDIT_ASSISTANCE_MODE](state) {
    state.editMode = false;
  },
  [types.FLAG_ACTIVE_RESULTS](state) {
    state.noResultsflag = true;
  },
  [types.FLAG_DESACTIVE_RESULTS](state) {
    state.noResultsflag = false;
  },

  [types.DATA_FILTERS_ACTIVE](state, payload) {
    let filters_data = [...new Set(payload)];
    state.dataActiveFilters = filters_data;
  },
  [types.CLEAN_DATA_FILTERS_ACTIVE](state) {
    state.dataActiveFilters = null;
  },
  // employees
  [types.EMPLOYE_DATA](state, data) {
    let employes = data.results;

    let employesData = employes.map((el) => {
      let payload = {
        id: el.id,
        name: `${el.first_name} ${el.surname}`,
      };
      return payload;
    });
    state.employees = employesData;
  },
  [types.SET_LIST__EMPLOYES_ASIGNED](state, data) {
    let employes = data.results;

    let employesData = employes.map((el) => {
      let payload = {
        id: el.id,
        name: el.full_name,
        user: el.user,
      };
      return payload;
    });
    state.AssignedAdminEmployees = employesData;
  },
  [types.SET_LIST_REQUESTER_EMPLOYES_ASIGNED](state, data) {
    let employes = data.results;

    let employesData = employes.map((el) => {
      let payload = {
        id: el.id,
        name: el.full_name,
        user: el.user,
      };
      return payload;
    });
    state.AssignedRequesterEmployees = employesData;
  },

  [types.TOTAL_PAGES_DATA](state, { limit, totalData }) {
    let totalPagesData = Math.ceil(totalData / limit);
    state.totalPages = totalPagesData;
  },

  [types.FLAG_ACTIVE_INPUT_SEARCH](state) {
    state.flagActiveInputSearch = true;
  },
  [types.FLAG_DESACTIVE_INPUT_SEARCH](state) {
    state.flagActiveInputSearch = false;
  },
  [types.ACTIVE_LOADER](state) {
    state.loaderActive = true;
  },
  [types.DESACTIVE_LOADER](state) {
    state.loaderActive = false;
  },
  [types.COMPANY_CURRENT_REQUEST](state, data) {
    state.companyCurrentRequest = data;
  },
  [types.CLEAN_COMPANY_CURRENT_REQUEST](state) {
    state.companyCurrentRequest = null;
  },

  [types.FLAG_ACTIVE_INPUT_SEARCH_TAGS](state, data) {
    state.dataInputSearchTags = data;
  },
  [types.FLAG_INACTIVE_INPUT_SEARCH_TAGS](state) {
    state.dataInputSearchTags = false;
  },

  [types.CLEAN_COUNTER__STEP__ASSISTANCE_ONBOARDING](state) {
    state.stepsAssistanceOnBoarding = 1;
  },
  [types.COUNTER__STEP__ASSISTANCE_ONBOARDING](state, payload) {
    state.stepsAssistanceOnBoarding = payload;
  },
  [types.UPDATE_CURRENT_FILES](state, payload) {
    state.currentSingleAssistance.files = payload;
  },

  [types.TAB_ASSIGNED_ACTIVE](state) {
    state.tabAssigned = true;
  },
  [types.TAB_ASSIGNED_DESACTIVE](state) {
    state.tabAssigned = false;
  },

  [types.ASSISTANCE_ROUTE_PARAMS_ID](state, payload) {
    state.assistanceRouteParamsId = payload;
  },
  [types.CLEAN_ASSISTANCE_ROUTE_PARAMS_ID](state) {
    state.assistanceRouteParamsId = null;
  },

  // FORCE REQUESTER ASSIGNED IN EXTERNAL TENNATN

  [types.FORCE_REQUESTER_ASIGNED](state, payload) {
    state.idRequesterExternal = payload;
  },
  [types.CLEAN_FORCE_REQUESTER_ASIGNED](state) {
    state.idRequesterExternal = null;
  },
};
