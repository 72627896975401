export const ReportTenantRoutes = [
  {
    path: "/reporte-tenant",
    component: () =>
      import(
        /*webpackChunkName: "ReportTenantModule" */ "./reportTenantModule"
      ),
    children: [
      {
        path: "/",
        name: "reportTenant",
        component: () =>
          import(
            /*webpackChunkName: "ReportTenant" */ "./pages/ReportUseTenantView"
          ),
      },
    ],
  },
];
