<template>
  <div class="skeleon_mobile_files_icon">
    <div class="">
      <VueSkeletonLoader
        type="rect"
        :width="20"
        :height="15"
        animation="fade"
      />
    </div>

    <div class="">
      <VueSkeletonLoader
        type="rect"
        :width="50"
        :height="15"
        animation="fade"
      />
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
    components: {
    VueSkeletonLoader,
  },
};
</script>

<style></style>
