//get category for select/opt
export const GET_CATEGORY_ASSISTANCE = "GET_CATEGORY_ASSISTANCE";
//get all cards assistance
export const GET_LIST_ASSISTANCE = "GET_LIST_ASSISTANCE";
//using by modal filter research
export const GET_FILTER_LIST_ASSISTANCE = "GET_FILTER_LIST_ASSISTANCE";
//using for search data
export const GET_SEARCH_LIST_ASSISTANCE = "GET_SEARCH_LIST_ASSISTANCE";
// using by store single assistance
export const SET_CURRENT_ASSISTANCE = "SET_CURRENT_ASSISTANCE";
export const CLEAN_SINGLE_ASSISTANCE = "CLEAN_SINGLE_ASSISTANCE";

//for list cards table

export const SET_TOTAL_DATA = "SET_TOTAL_DATA";
export const SET_TOTAL_DATA_VIEW = "SET_TOTAL_DATA_VIEW";
export const SET_PREV_PAGE = "SET_PREV_PAGE";
export const SET_NEXT_PAGE = "SET_NEXT_PAGE";
export const SET_FLAG = "SET_FLAG";

export const SET_FALSE_MODAL_ASSISTANCE = "SET_FALSE_MODAL_ASSISTANCE";
export const SET_TRUE_MODAL_ASSISTANCE = "SET_TRUE_MODAL_ASSISTANCE";
export const SET_LIST_GROUP_ASSISTANCE = "SET_LIST_GROUP_ASSISTANCE";

export const GET_RETRIVE_ASSISTANCE = "GET_RETRIVE_ASSISTANCE";

export const SET_FLAGS_SELECTED_OPTIONS_ASSISTANCE =
  "SET_FLAGS_SELECTED_OPTIONS_ASSISTANCE";

export const SET_FILTER_ASSISTANCE_MOBILE = "SET_FILTER_ASSISTANCE_MOBILE";

export const CLEAN_FILTER_ASSISTANCE_MOBILE = "CLEAN_FILTER_ASSISTANCE_MOBILE";

// tags of filters
export const ADD_TAGS_OF_FILTERS = "ADD_TAGS_OF_FILTERS";
export const CLEAN_TAGS_OF_FILTERS = "CLEAN_TAGS_OF_FILTERS";
export const CLEAN_SELECTED_TAGS_OF_FILTERS = "CLEAN_SELECTED_TAGS_OF_FILTERS";
export const FILTER_SELECTED_TAGS = "FILTER_SELECTED_TAGS";
export const SKELETON_FILTER_ON = "SKELETON_FILTER_ON";
export const SKELETON_FILTER_OFF = "SKELETON_FILTER_OFF";
export const FLAG_ACTIVE_FILTER = "FLAG_ACTIVE_FILTER";
export const FLAG_DESACTIVE_FILTER = "FLAG_DESACTIVE_FILTER";
export const FLAG_ACTIVE_RESULTS = "FLAG_ACTIVE_RESULTS";
export const FLAG_DESACTIVE_RESULTS = "FLAG_DESACTIVE_RESULTS";

//edit assistance state controlers
export const ACTIVE_EDIT_ASSISTANCE_FROM_LIST =
  "ACTIVE_EDIT_ASSISTANCE_FROM_LIST";
export const DESACTIVE_EDIT_ASSISTANCE_FROM_LIST =
  "DESACTIVE_EDIT_ASSISTANCE_FROM_LIST";
export const ACTIVE_EDIT_ASSISTANCE_MODE = "ACTIVE_EDIT_ASSISTANCE_MODE";
export const DESACTIVE_EDIT_ASSISTANCE_MODE = "DESACTIVE_EDIT_ASSISTANCE_MODE";
export const DATA_FILTERS_ACTIVE = "DATA_FILTERS_ACTIVE";
export const CLEAN_DATA_FILTERS_ACTIVE = "CLEAN_DATA_FILTERS_ACTIVE";

export const FLAG_ACTIVE_INPUT_SEARCH = "FLAG_ACTIVE_INPUT_SEARCH";
export const FLAG_DESACTIVE_INPUT_SEARCH = "FLAG_DESACTIVE_INPUT_SEARCH";

export const TOTAL_PAGES_DATA = "TOTAL_PAGES_DATA";

// employees data

export const EMPLOYE_DATA = "EMPLOYE_DATA";
export const SET_NEW_ASSIGNED_USERS = "SET_NEW_ASSIGNED_USERS";
export const SET_LIST__EMPLOYES_ASIGNED = "SET_LIST__EMPLOYES_ASIGNED";
export const SET_LIST_REQUESTER_EMPLOYES_ASIGNED =
  "SET_LIST_REQUESTER_EMPLOYES_ASIGNED";

//loader unit assitance
export const ACTIVE_LOADER = "ACTIVE_LOADER";
export const DESACTIVE_LOADER = "DESACTIVE_LOADER";

// clean current assistance
export const CLEAN_CURRENT_ASSISTANCE = "CLEAN_CURRENT_ASSISTANCE";

//company current request

export const COMPANY_CURRENT_REQUEST = "COMPANY_CURRENT_REQUEST";
export const CLEAN_COMPANY_CURRENT_REQUEST = "CLEAN_COMPANY_CURRENT_REQUEST";

export const FLAG_ACTIVE_INPUT_SEARCH_TAGS = "FLAG_ACTIVE_INPUT_SEARCH_TAGS";
export const FLAG_INACTIVE_INPUT_SEARCH_TAGS =
  "FLAG_INACTIVE_INPUT_SEARCH_TAGS";

export const CLEAN_COUNTER__STEP__ASSISTANCE_ONBOARDING =
  "CLEAN_COUNTER__STEP__ASSISTANCE_ONBOARDING";
export const COUNTER__STEP__ASSISTANCE_ONBOARDING =
  "COUNTER__STEP__ASSISTANCE_ONBOARDING";

export const UPDATE_CURRENT_FILES = "UPDATE_CURRENT_FILES";

export const TAB_ASSIGNED_ACTIVE = "TAB_ASSIGNED_ACTIVE";
export const TAB_ASSIGNED_DESACTIVE = "TAB_ASSIGNED_DESACTIVE";

export const ASSISTANCE_ROUTE_PARAMS_ID = "ASSISTANCE_ROUTE_PARAMS_ID";


export const CLEAN_ASSISTANCE_ROUTE_PARAMS_ID =
"CLEAN_ASSISTANCE_ROUTE_PARAMS_ID";





export const FORCE_REQUESTER_ASIGNED = "FORCE_REQUESTER_ASIGNED";
export const CLEAN_FORCE_REQUESTER_ASIGNED = "CLEAN_FORCE_REQUESTER_ASIGNED";