export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";

export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";

export const GET_COMPANYS_BY_USER = "GET_COMPANYS_BY_USER";
export const SET_COMPANY_SELECTED = "SET_COMPANY_SELECTED";

export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const MARK_LOGIN_SUCCESS = "MARK_LOGIN_SUCCESS";
export const SET_LOG_OUT = "SET_LOG_OUT";

export const FETCH_LOGIN_REQUEST_OAUTH = "FETCH_LOGIN_REQUEST_OAUTH";
export const FETCH_LOGIN_SUCCESS_OAUTH = "FETCH_LOGIN_SUCCESS_OAUTH";
export const FETCH_LOGIN_FAILURE_OAUTH = "FETCH_LOGIN_FAILURE_OAUTH";
export const OUTH_LOGIN_SUCCESS = "OUTH_LOGIN_SUCCESS";
export const OUTH_LOGIN_FAILURE = "OUTH_LOGIN_FAILURE";

// use for modal only
export const SET_MODAL_OPEN = "SET_MODAL_OPEN";
export const SET_MODAL_CLOSE = "SET_MODAL_CLOSE";
