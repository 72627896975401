// Data principal para agendamientos de consulta legal
export const FETCH_SCHEDULE_LEGAL_SUCCESS = "FETCH_SCHEDULE_LEGAL_SUCCESS";
export const FETCH_SCHEDULE_LEGAL_FAILURE = "FETCH_SCHEDULE_LEGAL_FAILURE";
export const FETCH_SCHEDULE_LEGAL_DATA = "FETCH_SCHEDULE_LEGAL_DATA";
// Data principal para agendamientos de consulta operaciones
export const FETCH_SCHEDULE_OPERATION_SUCCESS =
  "FETCH_SCHEDULE_OPERATION_SUCCESS";
export const FETCH_SCHEDULE_OPERATION_FAILURE =
  "FETCH_SCHEDULE_OPERATION_FAILURE";
export const FETCH_SCHEDULE_OPERATION_DATA = "FETCH_SCHEDULE_OPERATION_DATA";

// fetch para almacenar el resumen de las meeting en consulta legal y operaciones
export const FETCH_RESUME_MEETING_SUCCESS = "FETCH_RESUME_MEETING_SUCCESS";
export const FETCH_RESUME_MEETING_FAILURE = "FETCH_RESUME_MEETING_FAILURE";
export const FETCH_RESUME_MEETING_DATA = "FETCH_RESUME_MEETING_DATA";
export const CLEAN_RESUME_MEETING_SUCCESS = "CLEAN_RESUME_MEETING_SUCCESS";
// fetch para almacenar las meeting pasadas en consulta legal y operaciones
export const FETCH_PAST_MEETING_SUCCESS = "FETCH_PAST_MEETING_SUCCESS";
export const FETCH_PAST_MEETING_FAILURE = "FETCH_PAST_MEETING_FAILURE";
export const FETCH_PAST_MEETING_DATA = "FETCH_PAST_MEETING_DATA";

// selected SCHEDULING category item
export const SELECTED_SCHEDULING_CATEGORRY = "SELECTED_SCHEDULING_CATEGORRY";
export const CLEAN_SCHEDULING_CATEGORRY = "CLEAN_SCHEDULING_CATEGORRY";

// flag for view
//si es clean se esconden con display none elementos en la vista consulta legal y operaciones
// si es flag true se muestra contenido en la vista aplicando otra clase
export const SELECTED_SCHEDULING_FLAG = "SELECTED_SCHEDULING_FLAG";
export const CLEAN_SCHEDULING_FLAG = "CLEAN_SCHEDULING_FLAG";

// Data principal para cards de agendamientos propios
export const FETCH_MEETING_CARD_SUCCESS = "FETCH_MEETING_CARD_SUCCESS";
export const FETCH_MEETING_CARD_FAILURE = "FETCH_MEETING_CARD_FAILURE";
export const FETCH_MEETING_CARD_DATA = "FETCH_MEETING_CARD_DATA";
//loader for all meetings
export const ACTIVE_LOADER_ALL_MEETINGS = "ACTIVE_LOADER_ALL_MEETINGS";
export const DESACTIVE_LOADER_ALL_MEETINGS = "DESACTIVE_LOADER_ALL_MEETINGS";

// loader for load data for categorys scheduling
export const ACTIVE_LOADER_SCHEDULING = "ACTIVE_LOADER_SCHEDULING";
export const CANCEL_LOADER_SCHEDULING = "CANCEL_LOADER_SCHEDULING";
