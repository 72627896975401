<template>
  <div class="members">
    <div class="signers" v-for="(signer, index) in members" :key="index">
      <div
        class="members_unit"
        :class="signer.status != 'Signer' ? 'add_cursor' : ''"
        @click="linkPasteSignature(signer.sign_url, signer.status)"
      >
        <div
          :class="
            signer.status == 'Signer'
              ? 'circle_signer'
              : 'circle_signer_pending'
          "
        ></div>
        <div class="container_signer_name">
          {{ signer.name }}  ( {{ signer.email }} )
        </div>
        <div class="icon_copy_container" v-if="signer.status != 'Signer'">
          <div class="container__wrapper__hover__digitalsignature">
            <div class="tooltip__key__digitalsignature">
              Copiar link de firma
            </div>
            <CopyPasteIcon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyPasteIcon from "../assets/copyPasteIcon.svg";

export default {
  props: ["members"],
  name: "ListSignersForTracking",
  components: {
    CopyPasteIcon,
  },
  methods: {
    linkPasteSignature(link, status) {
      this.$toasted.success("Enlace copiado con éxito");

      if (status != "Signer") {
        this.$copyText(link);
      }
    },
  },
};
</script>

<style lang="scss">
.members {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-wrap: nowrap;
}
.members_unit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--content_table_dsignature) !important;
}
.add_cursor {
  cursor: pointer;
}
.signers {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 100%;
  }
  margin-bottom: 15px;
  margin-right: 5rem;
}
.circle_signer_pending {
  background-color: #f8f8f8;
  border: 2px solid #9c9c9c;
}

.circle_signer,
.circle_signer_pending {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 0.2rem;
}

.circle_signer {
  background-color: #619aff;
  border: 2px solid #619aff;
}
.icon_copy_container svg {
  height: 25px !important;
}
.container__wrapper__hover__digitalsignature {
  position: relative;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 25px;

  cursor: pointer;

  background: transparent;

  > div {
    text-align: center;
  }
}
.container__wrapper__hover__digitalsignature:hover
  .tooltip__key__digitalsignature {
  transform: fade-in 500ms;
  transition: all 700ms ease;
  opacity: 1;
  display: block;
  background: #ffffff !important;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  padding: 0.5rem;
  border-radius: 17px;

  transform: translateX(105%);
  color: #000000 !important;
}
.container__wrapper__hover__digitalsignature:hover {
  flex-direction: row !important;
  justify-content: flex-end;
  transition: all 1.5s linear;
  > svg {
    opacity: 0.9;
    z-index: 99999;
  }
}
.tooltip__key__digitalsignature {
  position: absolute;
  display: none;
  font-size: 11.8px;
}
.container_signer_name {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    white-space: nowrap;
  }
}
</style>
