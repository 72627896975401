var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__sla__message",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : _vm.size == 'modal__SLA_v2'
      ? 'modal__SLA_v2'
      : 'modal__default',attrs:{"id":"modal__sla__message"}},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal_content_SLA"},[_c('div',{staticClass:"container_basic_information_assistance m-top-s"},[_c('div',{staticClass:"description_assistance"},[_c('div',{staticClass:"tittle_description title_sla_modal"},[_vm._v(" SLA generales de mis abogados ")]),_c('div',{staticClass:"sla_container_assistance"},[_vm._v(" Para todas las solicitudes de asistencia legal, MisAbogados ha dispuesto compromisos de tiempo de respuesta y entrega final del servicio, de cara a sus clientes. Para cada nueva consulta, revisión o redacción de contrato ingresada en la plataforma, usted recibirá una confirmación de su abogado(a) asignado(a) en "),_c('b',[_vm._v("menos de 4 horas*")]),_vm._v(". Dicho profesional podrá solicitarle antecedentes adicionales, si fuese necesario. Al recibir los antecedentes adicionales solicitados, su abogado(a) tendrá un"),_c('b',[_vm._v(" máximo de 12 horas para responder su consulta")]),_vm._v(", o bien "),_c('b',[_vm._v(" una cantidad de días hábiles a acordar con su abogado si se tratase de elaborar o revisar documentos y/o contratos")])])]),_c('div',{staticClass:"description_assistance"},[_c('div',{staticClass:"tittle_description title_sla_modal"},[_vm._v(" SLA para la atencion de tu ticket ")]),_c('div',{staticClass:"sla_container_assistance"},[_vm._v(" En caso de que el cliente no enviase los antecedentes adicionales solicitados, dentro de un "),_c('b',[_vm._v("plazo de 24 horas desde el último recordatorio")]),_vm._v(" enviado por nuestro profesional, su solicitud "),_c('b',[_vm._v("será cerrada")]),_vm._v(" a la espera de su respuesta. ")]),_c('div',{staticClass:"sla_container_assistance text_asistance_information sla_information_modal"},[_vm._v(" *Se considera, para la definición de todos nuestros SLAs, horas hábiles (9:00 a 19:00 hrs, de Lunes a Viernes) ")])])])])
}]

export { render, staticRenderFns }