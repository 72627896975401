export default {
  token: (state) => {
    return state.token;
  },

  errors: (state) => {
    return state.error;
  },

  companies: (state) => {
    return state.companies;
  },

  company: (state) => {
    return state.company;
  },
  ViewCompany: (state) => {
    return state.user_profile.company;
  },

  user_profile: (state) => {
    return state.user_profile;
  },
  user_id: (state) => {
    return state.user_profile.id;
  },

  signer: (state) => {
    let secondSurname =
      state.user_profile.second_surname != null
        ? state.user_profile.second_surname
        : "";
    let middleName =
      state.user_profile.middle_name != null
        ? state.user_profile.middle_name
        : "";
    let user = {
      name:
        state.user_profile.first_name +
        " " +
        middleName +
        " " +
        state.user_profile.surname +
        " " +
        secondSurname,
      rut: state.user_profile.nin,
      email: state.email,
    };
    return user;
  },

  group: (state) => {
    return state.user_profile.group;
  },
  company_id: (state) => {
    return state.user_profile.company.id;
  },

  is_staff: (state) => {
    return state.is_staff;
  },
  totalCompanies: (state) => {
    return state.totalCompanies;
  },
  // modal only use
  modalstate: (state) => {
    return state.modal;
  },

  countryCode: (state) => {
    return state.company_selected[0].company_country_code;
  },
  validToken: (state) => {
    return state.token;
  },
};
