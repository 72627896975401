<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'onboarding'
        ? 'modal__onboarding'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    id="modal__onboarding__home"
    ref="modal__onboarding__home"
  >
    <div class="">
      <div class="">
        <Transition v-if="stepHomeOnBoarding == 1" name="fade">
          <OnBoardingFirstStepHome />
        </Transition>
        <Transition v-if="stepHomeOnBoarding == 2" name="fade">
          <OnBoardingSecondStepHome />
        </Transition>
        <Transition v-if="stepHomeOnBoarding == 3" name="fade">
          <OnBoardingThirdStepHome />
        </Transition>
        <!-- <Transition v-if="stepHomeOnBoarding == 4" name="fade">
          <OnBoardingFourthStepHome />
        </Transition> -->
        <Transition v-if="stepHomeOnBoarding == 4" name="fade">
          <OnboardingFifthStepHome />
        </Transition>
      </div>
      <div class="container_footer_onboarding">
        <div class="container_elements_footer">
          <div
            class="footer_modal footer__modal__cancel footer__modal__fix_onboarding"
          >
            <div
              disabled="disabled"
              class="cancel_button_onboarding"
              v-if="!automaticValidation"
              @click="cancelModal()"
            >
              <span> Omitir</span>
            </div>
            <div class="" v-else></div>
            <div
              :class="
                stepHomeOnBoarding == 4
                  ? 'button_step_fourth '
                  : 'fix-btn-mac button_onboarding_accept'
              "
              @click="AcceptOnBoarding()"
            >
              <span v-if="stepHomeOnBoarding == 1"> Comenzar </span>
              <span
                v-if="
                  stepHomeOnBoarding == 2 ||
                  stepHomeOnBoarding == 3
                "
              >
                Siguiente
              </span>

              <span v-if="stepHomeOnBoarding == 4">
                Ingresar nueva solicitud
              </span>
            </div>
          </div>
          <div
            :class="
              automaticValidation
                ? 'container__progressBar__onboarding onbording_container_automatic'
                : 'container__progressBar__onboarding'
            "
          >
            <div
              :class="
                stepHomeOnBoarding == 1
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(1)"
            ></div>
            <div
              :class="
                stepHomeOnBoarding == 2
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(2)"
            ></div>
            <div
              :class="
                stepHomeOnBoarding == 3
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(3)"
            ></div>
            <div
              :class="
                stepHomeOnBoarding == 4
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(4)"
            ></div>
            <!-- <div
              :class="
                stepHomeOnBoarding == 5
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(5)"
            ></div> -->
          </div>
          <div
            class="footer_check_box_modal_on_boarding"
            v-if="automaticValidation"
          >
            <input
              type="checkbox"
              id="checkbox"
              v-model="checkNoShowOnBoarding"
              @change="checkedOnBoarding(checkNoShowOnBoarding)"
            />
            <label for="checkbox">No volver a mostrar este mensaje</label>
          </div>
          <div class="" v-else></div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import OnBoardingFirstStepHome from "./FirstStepHome.vue";
import OnBoardingSecondStepHome from "./SecondStepHome.vue";
import OnBoardingThirdStepHome from "./ThirdStepHome.vue";
// import OnBoardingFourthStepHome from "./FourthStepHome.vue";
import OnboardingFifthStepHome from "./FifthStepHome.vue";
import employeeService from "../../employeesV2/services/employeService";

import { mapGetters } from "vuex";

export default {
  props: ["size", "automaticValidation"],
  components: {
    OnBoardingFirstStepHome,
    OnBoardingSecondStepHome,
    OnBoardingThirdStepHome,
    // OnBoardingFourthStepHome,
    OnboardingFifthStepHome,
  },
  data() {
    return {
      textButtonOnboarding: "",
      checkNoShowOnBoarding: false,
    };
  },
  created() {
    this.$store.dispatch("Home/cleancounterStepHomeOnBoarding");
  },
  computed: {
    ...mapGetters("Home", {
      stepHomeOnBoarding: "stepHomeOnBoarding",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      is_staff: "is_staff",
      group: "group",
      company: "company",
      user: "user_profile",
      company_id: "company_id",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      this.$store.dispatch("Home/cleancounterStepHomeOnBoarding");
      let modal = document.querySelector("#modal__onboarding__home");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    checkedOnBoarding(checkNoShowOnBoarding) {
      if (checkNoShowOnBoarding) {
        let payload = {
          show_onboarding_info: false,
        };
        employeeService
          .edit_own_profile(this.user.company.id, payload)
          .then(() => {
            this.$store.dispatch("Login/setUserProfile", this.user.company.id);
          });
      } else {
        let payload = {
          show_onboarding_info: true,
        };
        employeeService
          .edit_own_profile(this.user.company.id, payload)
          .then(() => {
            this.$store.dispatch("Login/setUserProfile", this.user.company.id);
          });
      }
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__home");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
      this.$store.dispatch("Home/cleancounterStepHomeOnBoarding");
    },
    AcceptOnBoarding() {
      let increment = this.stepHomeOnBoarding + 1;

      this.$store.dispatch("Home/counterStepHomeOnBoarding", increment);
      if (this.stepHomeOnBoarding > 4) {
        if (this.indicator == 1) {
          let modal = document.querySelector("#modal__onboarding__home");
          modal.setAttribute("closing", "");
          modal.addEventListener(
            "animationend",
            () => {
              modal.removeAttribute("closing");
              modal.close();
            },
            { once: true }
          );
        } else {
          this.$store.dispatch("Home/cleancounterStepHomeOnBoarding");

          this.$router.push({ name: "requestAsistencia" });
        }
      }
    },
    goBack(data) {
      this.$store.dispatch("Home/counterStepHomeOnBoarding", data);
    },
  },
};
</script>

<style lang="scss">
.footer_check_box_modal_on_boarding {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.onbording_container_automatic {
  width: 94% !important;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 88% !important;
  }
}
</style>
