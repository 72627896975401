<template>
    <div class="inner">
    Hola desde global Settings
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>