<template>
  <div class="container-slots">
    <div class="breadcrumb">
      <span
        class="text_document m-left-none color_gray_i"
        @click="goToDashboardPreStepAssistance"
      >
        Solicitudes >
      </span>

      <span class="text_document"
        >Caso #{{ currentSingleAssistance.caseCode }}
      </span>
    </div>
    <div class="container_edit_single_assistance">
      <div class="container_left_single_assitance">
        <div>
          <HeaderSingleAssistance
            :usuario="this.currentSingleAssistance"
            :edit="editMode"
            v-if="SingleAssistanceData != null"
          />
          <div class="header_single_assitance_skeleton" v-else>
            <div class="skeleton_assistance_left">
              <VueSkeletonLoader
                type="circle"
                :width="70"
                :height="70"
                animation="fade"
              />
              <div class="skeleton_assistance_left_name">
                <VueSkeletonLoader
                  type="rect"
                  :width="130"
                  :height="15"
                  animation="fade"
                />
                <VueSkeletonLoader
                  type="rect"
                  :width="80"
                  :height="15"
                  animation="fade"
                />
              </div>
            </div>
            <div class="skeleton_assistance_rigth">
              <div class="code_assistance_skeleton">
                <VueSkeletonLoader
                  type="rect"
                  :width="90"
                  :height="15"
                  animation="fade"
                />
              </div>
              <div class="code_date_skeleton">
                <VueSkeletonLoader
                  type="rect"
                  :width="110"
                  :height="15"
                  animation="fade"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="container_swap_assistance">
            <div
              class="selected_assistance title-container"
              :class="DefaultInfo ? 'active' : ''"
            >
              Informacion basica
            </div>
          </div>
          <div class="line-primary"></div>
        </div>

        <div class="container_basic_information_assistance">
          <Transition div class="" v-if="DefaultInfo" name="fade">
            <BasicInformationAssistance
              :usuario="SingleAssistanceBaseInformationData"
              :edit="editMode"
              v-if="SingleAssistanceBaseInformationData != null"
            />
            <div class="skeleton_main_assistance_information" v-else>
              <div class="skeleton_assistance_left">
                <div class="skeleton_assistance_left_name">
                  <VueSkeletonLoader
                    type="rect"
                    :width="150"
                    :height="15"
                    animation="fade"
                  />
                  <div class="category_container_assistance">
                    <div class="skeleton_information_skeleton">
                      <VueSkeletonLoader
                        type="rect"
                        :width="100"
                        :height="15"
                        animation="fade"
                      />
                      <VueSkeletonLoader
                        type="rect"
                        :width="150"
                        :height="15"
                        animation="fade"
                      />
                    </div>
                    <div class="skeleton_information_skeleton">
                      <VueSkeletonLoader
                        type="rect"
                        :width="100"
                        :height="15"
                        animation="fade"
                      />
                      <VueSkeletonLoader
                        type="rect"
                        :width="150"
                        :height="15"
                        animation="fade"
                      />
                    </div>
                  </div>
                  <VueSkeletonLoader
                    type="rect"
                    :width="100"
                    :height="15"
                    animation="fade"
                  />
                  <VueSkeletonLoader
                    type="rect"
                    :width="800"
                    :height="15"
                    animation="fade"
                  />
                  <VueSkeletonLoader
                    type="rect"
                    :width="800"
                    :height="15"
                    animation="fade"
                  />
                  <VueSkeletonLoader
                    type="rect"
                    :width="800"
                    :height="15"
                    animation="fade"
                  />
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSingleAssistance from "../components/HeaderSingleAssistance.vue";
import VueSkeletonLoader from "skeleton-loader-vue";
import { mapGetters } from "vuex";
import BasicInformationAssistance from "../components/optionsSelectionSingleAssistance/BasicInformationAssistance.vue";

export default {
  data: () => {
    return {
      DefaultInfo: true,
      SingleAssistanceData: null,
      SingleAssistanceBaseInformationData: null,
      editMode: true,
    };
  },
  components: {
    HeaderSingleAssistance,
    VueSkeletonLoader,
    BasicInformationAssistance,
  },
  created() {
    // this.editMode = this.editFromListAssistance;
    setTimeout(() => {
      this.SingleAssistanceData = this.currentSingleAssistance;
    }, 1000);
    setTimeout(() => {
      this.SingleAssistanceBaseInformationData =
        this.currentSingleAssistanceBaseInformation;
    }, 1000);
  },

  computed: {
    ...mapGetters("Assistance", {
      currentSingleAssistance: "currentSingleAssistance",
      currentSingleAssistanceTermsAgreement:
        "currentSingleAssistanceTermsAgreement",
      currentSingleAssistanceBaseInformation:
        "currentSingleAssistanceBaseInformation",
      listGroupAssistance: "listGroupAssistance",
      // editFromListAssistance: "editFromListAssistance",
    }),
  },

  methods: {
    goToDashboardPreStepAssistance() {
      this.$router.push({ name: "listRequestIntegrated" });
    },
  },
};
</script>

<style lang="scss">
.container_edit_single_assistance .container_left_single_assitance {
  width: 100% !important;
}
</style>
