export default {
  list__companies: (state) => {
    return state.listCompanies;
  },
  list__employee: (state) => {
    return state.employees;
  },
  list__employee_members: (state) => {
    return state.employeesMembers;
  },
  activeDropDownV2: (state) => {
    return state.activeDropdownState;
  },
  activeDropDownVerified: (state) => {
    return state.activeDropdownVerified;
  },
  getAllActiveState: (state) => {
    return state.dropDownSelectedState.activAll;
  },
  getAllDesactiveState: (state) => {
    return state.dropDownSelectedState.desactiveAll;
  },
  getAllVerified: (state) => {
    return state.dropDownSelectedVerified.VerifiedAll;
  },
  getAllNotVerified: (state) => {
    return state.dropDownSelectedVerified.desactiveVeriefiedAll;
  },
  totalPages: (state) => {
    return state.totalPages;
  },
  totalPagesMembers: (state) => {
    return state.totalPagesMembers;
  },
  nextPage: (state) => {
    return state.nextPage;
  },

  nextPageMembers: (state) => {
    return state.nextPageMembers;
  },

  prevPage: (state) => {
    return state.prevPage;
  },
  prevPageMembers: (state) => {
    return state.prevPageMembers;
  },
  total_data_view: (state) => {
    return state.total_data_view;
  },
  total_data_view_members: (state) => {
    return state.total_data_view_members;
  },
  getLoaderTableEmployee: (state) => {
    return state.loaderTableEmployee;
  },
  tagsOfFilters: (state) => {
    return state.tagsOfFilters;
  },
  activeDropDownThreeDots: (state) => {
    return state.activeDropdownThreeDots;
  },
  getCurrentEmployeeSelected: (state) => {
    return state.currentEmployee;
  },
  getCurrentEmployeeSelectedFetch: (state) => {
    return state.currentEmployeeFetch;
  },
  activeTableMembers: (state) => {
    return state.tableMembersActive;
  },
  nationalityEmployee: (state) => {
    return state.NationalityStateEmployee;
  },
  regionListEmployee: (state) => {
    return state.RegionStateEmployee;
  },
  cityListEmployee: (state) => {
    return state.CityStateEmployee;
  },
  stepEmployees: (state) => {
    return state.stepsEmployee;
  },
  stepEmployeesOnBoarding: (state) => {
    return state.stepsEmployeeOnBoarding;
  },
  typeUser: (state) => {
    return state.typeUser;
  },
  initialFilters: (state) => {
    return state.initialFilters;
  },
  typeUserCompanyId: (state) => {
    return state.typeUserCompanyId;
  },
  baseInformation: (state) => {
    return state.baseInformation;
  },
  baseInformationCompany: (state) => {
    return state.baseInformationCompany;
  },
  baseInformationBank: (state) => {
    return state.baseInformationBank;
  },

  listBanks: (state) => {
    return state.listBanks;
  },
  listHealthInsurance: (state) => {
    return state.listHealthInsurance;
  },
  listSocialSecurity: (state) => {
    return state.listSocialSecurity;
  },

  listOptionUserProfile: (state) => {
    return state.listOptionUserProfile;
  },

  flagActiveResultEmployee: (state) => {
    return state.noResultsflagEmployee;
  },
  flagActiveResultEmployeeMembers: (state) => {
    return state.noResultsflagEmployeeMember;
  },
  getCurrentUserToDelete: (state) => {
    return state.deleteIdUser;
  },

  activeInputSearchUser: (state) => {
    return state.inputSearchActiveUser;
  },
  activeInputSearchMember: (state) => {
    return state.inputSearchActiveMember;
  },
};
