<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__coming__soon"
    ref="modal__coming__soon"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="modal__coming__soon text-filter-light text-center">
        <div class="container__description">
      Firma digital avanzada (FEA) estará disponible
      <span class="text-filter">
        próximamente
      </span>
    </div>


      <div class="footer_modal_signers">
        <svg
          version="1.1"
          id="Capa_1"
          class="logo__modal"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 200 200"
          style="enable-background:new 0 0 200 200;"
          xml:space="preserve"
        >
          <g>
            <rect style="fill:#000032;" width="200" height="200" />
            <g>
              <g>
                <g>
                  <g>
                    <path
                      style="fill:#5A96FF;"
                      d="M58.91,105.13H38.42c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13H58.9
						c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13C64.04,107.43,61.74,105.13,58.91,105.13z"
                    />
                    <path
                      style="fill:#5A96FF;"
                      d="M161.58,105.13H79.39c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13c0,0,0.01,0,0.01,0
						h82.17c2.83,0,5.13-2.3,5.13-5.13C166.71,107.43,164.41,105.13,161.58,105.13z"
                    />
                    <path
                      style="fill:#5A96FF;"
                      d="M38.42,74.35h61.48c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13c0-2.83-2.3-5.13-5.13-5.13
						H38.42c-2.83,0-5.13,2.3-5.13,5.13C33.29,72.05,35.59,74.35,38.42,74.35z"
                    />
                    <path
                      style="fill:#5A96FF;"
                      d="M161.58,125.65H99.92c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13c0,0,0.01,0,0.01,0
						h61.65c2.83,0,5.13-2.3,5.13-5.13C166.71,127.95,164.41,125.65,161.58,125.65z"
                    />
                    <path
                      style="fill:#5A96FF;"
                      d="M79.39,125.65H38.42c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13h40.96
						c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13C84.52,127.95,82.23,125.65,79.39,125.65z"
                    />
                    <path
                      style="fill:#5A96FF;"
                      d="M120.44,84.61L120.44,84.61l-82.02,0c-2.83,0-5.13,2.3-5.13,5.13s2.3,5.13,5.13,5.13h81.98
						c0.01,0,0.02,0,0.03,0c2.83,0,5.13-2.3,5.13-5.13C125.57,86.91,123.27,84.61,120.44,84.61z"
                    />
                    <path
                      style="fill:#5A96FF;"
                      d="M161.58,84.61h-20.57v0c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13
						c0.01,0,0.02,0,0.03,0h20.54c2.83,0,5.13-2.3,5.13-5.13S164.41,84.61,161.58,84.61z"
                    />
                    <path
                      style="fill:#5A96FF;"
                      d="M120.44,74.35c0,0,0.01,0,0.01,0h41.13c2.83,0,5.13-2.3,5.13-5.13c0-2.83-2.3-5.13-5.13-5.13
						h-41.14c-2.83,0-5.13,2.3-5.13,5.13C115.31,72.05,117.61,74.35,120.44,74.35z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="logo__modal__footer text-filter-light">MisAbogados</div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/components/modal/assets/cancel.svg";
export default {
  props: ["size"],
  components: {
    CancelIcon,
  },
  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__coming__soon");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__coming__soon");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.modal__coming__soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 73%;
  gap: 1.8rem;
}
.footer_modal_signers{
  > .logo__modal__footer{
     color: var(--text_profile);
  }
}
.modal__coming__soon span{
 color: var(--text_profile);
}
</style>
