<template>
  <div class="container_module">
    <div class="container-slots container-base container__schedule">
      <div class="">
        <div class="breadcrumb">
          <span class="sign__digital text_document"> Agendamiento legal </span>
        </div>
      </div>
      <div class="main__container__cards__category">
        <div class="container__cards__category">
          <CategoryDashboardItem
            :title="LegalScheduling.name"
            :icon="LegalScheduling.icon"
          />
          <CategoryDashboardItem
            :title="OperationScheduling.name"
            :icon="OperationScheduling.icon"
          />
        </div>
        <div class="card__agenda">
          <div class="container__card__agenda fix__agenda">
            <CategoryDashboardItem
              :title="MyScheduling.name"
              :icon="MyScheduling.icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryDashboardItem from "../components/CategoryDashboardItem.vue";

export default {
  components: { CategoryDashboardItem },
  created() {
    // this.$store.dispatch("Schedule/loadSchedulingOn");
  },
  data: function () {
    return {
      LegalScheduling: {
        name: "Sesión de consulta legal",
        icon: "1",
      },
      OperationScheduling: {
        name: "Sesión de servicio al cliente",
        icon: "2",
      },
      MyScheduling: {
        name: "Mi Agenda",
        icon: "3",
      },

      //TODO: cambiar a loader controlado mediante vuex
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "../schedule.scss";
</style>
