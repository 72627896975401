<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'onboarding'
        ? 'modal__onboarding'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    :id="ref_id"
    :ref="ref_id"
  >
    <div class="">
      <div class="">
        <Transition v-if="stepEmployeesOnBoarding == 1" name="fade">
          <OnBoardingFirstStep />
        </Transition>
        <Transition v-if="stepEmployeesOnBoarding == 2" name="fade">
          <OnBoardingSecondStep />
        </Transition>
        <Transition v-if="stepEmployeesOnBoarding == 3" name="fade">
          <OnBoardingThirdStep />
        </Transition>
        <Transition v-if="stepEmployeesOnBoarding >= 4" name="fade">
          <OnBoardingFourthStep />
        </Transition>
      </div>
      <div class="container_footer_onboarding">
        <div class="container_elements_footer">
          <div
            class="footer_modal footer__modal__cancel footer__modal__fix_onboarding"
          >
            <div
              disabled="disabled"
              class="cancel_button_onboarding"
              @click="cancelModal()"
            >
              <span> Omitir</span>
            </div>
            <div
              :class="
                stepEmployeesOnBoarding == 4
                  ? 'button_step_fourth '
                  : 'fix-btn-mac button_onboarding_accept'
              "
              @click="AcceptOnBoarding()"
            >
              <span
                v-if="
                  stepEmployeesOnBoarding == 1 || stepEmployeesOnBoarding == 3
                "
              >
                Comenzar
              </span>
              <span v-if="stepEmployeesOnBoarding == 2"> Siguiente </span>

              <span v-if="stepEmployeesOnBoarding == 4">
                Añadir nuevo miembro del equipo
              </span>
            </div>
          </div>
          <div class="container__progressBar__onboarding">
            <div
              :class="
                stepEmployeesOnBoarding == 1
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(1)"
            ></div>
            <div
              :class="
                stepEmployeesOnBoarding == 2
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(2)"
            ></div>
            <div
              :class="
                stepEmployeesOnBoarding == 3
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(3)"
            ></div>
            <div
              :class="
                stepEmployeesOnBoarding == 4
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(4)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import OnBoardingFirstStep from "./OnBoardingFirstStep.vue";
import OnBoardingSecondStep from "./OnBoardingSecondStep.vue";
import OnBoardingThirdStep from "./OnBoardingThirdStep.vue";
import OnBoardingFourthStep from "./OnBoardingFourthStep.vue";
import { mapGetters } from "vuex";
export default {
  props: ["size", "indicator", "ref_id"],
  components: {
    OnBoardingFirstStep,
    OnBoardingSecondStep,
    OnBoardingThirdStep,
    OnBoardingFourthStep,
  },
  data() {
    return {
      stepOnBoarding: 1,
      textButtonOnboarding: "",
      ref__id: null,
    };
  },
  created() {
    this.ref__id = this.ref_id;
    this.$store.dispatch("EmployeesV2/cleancounterStepEmployeeOnBoarding");
  },

  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployeesOnBoarding: "stepEmployeesOnBoarding",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      is_staff: "is_staff",
      group: "group",
      company: "company",
      user_profile: "user_profile",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      this.$store.dispatch("EmployeesV2/cleancounterStepEmployeeOnBoarding");
      let hash = `#${this.ref__id}`;

      let modal = document.querySelector(hash);
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let hash = `#${this.ref__id}`;

      let modal = document.querySelector(hash);
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
      this.$store.dispatch("EmployeesV2/cleancounterStepEmployeeOnBoarding");
    },
    AcceptOnBoarding() {
      let increment = this.stepEmployeesOnBoarding + 1;
      this.$store.dispatch(
        "EmployeesV2/counterStepEmployeeOnBoarding",
        increment
      );
      if (this.stepEmployeesOnBoarding > 4) {
        if (this.indicator == 1) {
          let hash = `#${this.ref__id}`;

          let modal = document.querySelector(hash);
          modal.setAttribute("closing", "");
          modal.addEventListener(
            "animationend",
            () => {
              modal.removeAttribute("closing");
              modal.close();
            },
            { once: true }
          );
        } else {
          this.$store.dispatch(
            "EmployeesV2/cleancounterStepEmployeeOnBoarding"
          );

          if (this.inSwitch) {
            this.$router.push({ name: "selectedEmployee" });
          } else if (this.group == "Admin") {
            this.$router.push({ name: "selectedEmployeeAdmin" });
          } else {
            this.$router.push({ name: "selectedEmployee" });
          }
        }
      }
    },
    goBack(data) {
      this.$store.dispatch("EmployeesV2/counterStepEmployeeOnBoarding", data);
    },
  },
};
</script>

<style lang="scss"></style>
