<template>
  <div class="container__module__employeev2">
    <div class="container_base_V2 container_fix_employee">
      <div class="container__header__employee">
        <div class="container__header__assistance">
          <div class="header__employee__elements">
            <div class="header__employee__elements__left">
              <div class="header__employee__title">
                <VueSkeletonLoader
                  type="rect"
                  :width="165"
                  :height="30"
                  animation="fade"
                />
              </div>
            </div>
            <div
              class="header__employee__elements__right skeleton_elemnts_rigth"
            >
              <button class="">
                <VueSkeletonLoader
                  type="rect"
                  :width="165"
                  :height="30"
                  animation="fade"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container_search_employee">
        <div class="search__employee skeleton_elemnts_rigth">
          <VueSkeletonLoader
            type="rect"
            :width="165"
            :height="30"
            animation="fade"
          />
        </div>
      </div>
      <div class="container_swap_table_employee">
        <div class="container_table_selection_employee">
          <div class="selected_assistance container_title_table">
            <VueSkeletonLoader
              type="rect"
              :width="165"
              :height="20"
              animation="fade"
            />
          </div>
          <div class="selected_assistance container_title_table">
            <VueSkeletonLoader
              type="rect"
              :width="165"
              :height="20"
              animation="fade"
            />
          </div>
        </div>
      </div>
      <div class="container_employee_table">
        <div class="">
          <div class="horizontal-scroll">
            <div class="body__digital_signature">
              <table class="table__digital__signature">
                <thead class="thead_employee">
                  <tr>
                    <td class="header_table_actions_table_employees"></td>
                    <td class="header_table_actions_table_employees">
                      <VueSkeletonLoader
                        type="rect"
                        :width="105"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td class="header_table_actions_table_employees">
                      <VueSkeletonLoader
                        type="rect"
                        :width="105"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td class="header_table_actions_table_employees">
                      <VueSkeletonLoader
                        type="rect"
                        :width="105"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td class="header_table_actions_table_employees">
                      <VueSkeletonLoader
                        type="rect"
                        :width="105"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td class="header_table_actions_table_employees">
                      <div class="options_employee_actions">
                        <div class="">
                          <VueSkeletonLoader
                            type="rect"
                            :width="105"
                            :height="15"
                            animation="fade"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="header_table_actions_table_employees">
                      <div class="options_employee_actions">
                        <div class="">
                          <VueSkeletonLoader
                            type="rect"
                            :width="105"
                            :height="15"
                            animation="fade"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="header_table_actions_table_employees"></td>
                    <td class="header_table_actions_table_employees"></td>
                  </tr>
                </thead>
                <tbody id="item_icons" class="tbody_signature">
                  <tr v-for="(data, index) in list__employee" :key="index">
                    <td>
                      <VueSkeletonLoader
                        type="rect"
                        :width="165"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <VueSkeletonLoader
                        type="rect"
                        :width="165"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td class="">
                      <VueSkeletonLoader
                        type="rect"
                        :width="165"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <VueSkeletonLoader
                        type="rect"
                        :width="165"
                        :height="15"
                        animation="fade"
                      />
                    </td>
                    <td class="">
                      <button>
                        <div class="">
                          <div class="">
                            <VueSkeletonLoader
                              type="rect"
                              :width="165"
                              :height="15"
                              animation="fade"
                            />
                          </div>
                        </div>
                      </button>
                    </td>
                    <td class="">
                      <button>
                        <div class="">
                          <div class="">
                            <VueSkeletonLoader
                              type="rect"
                              :width="165"
                              :height="15"
                              animation="fade"
                            />
                          </div>
                        </div>
                      </button>
                    </td>

                    <td class="icon_options_employee">
                      <div class="icon_options_employee_delete">
                        <VueSkeletonLoader
                          type="rect"
                          :width="20"
                          :height="15"
                          animation="fade"
                        />
                      </div>
                      <div class="icon_options_employee_edit">
                        <VueSkeletonLoader
                          type="rect"
                          :width="20"
                          :height="15"
                          animation="fade"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  components: {
    VueSkeletonLoader,
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__employee: "list__employee",
      getLoaderTableEmployee: "getLoaderTableEmployee",
    }),
  },
};
</script>

<style lang="scss">
.skeleton_elemnts_rigth {
  margin-right: 1rem;
}
</style>
