<template>
  <div class="container__view__child__element">
    <Modal
      ref="modal"
      title="Guardar cambios"
      :confirm="true"
      :cancel="true"
      size="m"
      identifier="report"
    >
      <div class="modal__body">
        <div class="modal__user__regional">
          <div class="container__description value-primary">
            Se realizarán cambios en los siguientes parámetros:
          </div>
          <div class="container_settings">
            <div class="label-primary">Reportes de uso</div>
            <div class="value-primary" v-if="quantity_report != null">
              {{ quantity_report }} reportes diarios
            </div>
            <div class="value-primary" v-else>
              No se han modificados los reportes de uso diario
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <span class="title__child title-container fix_height_mac">
      <IconBackArrow @click="returnMainView()" />
      {{ titleBreadcrumb }}
    </span>

    <span class="title__child__service title_bold_700">
      {{ titleService }}
    </span>
    <div
      class="container__report__limitation"
      @change="selectQuantityReport($event)"
    >
    <div class="custom-select">

      <select class="input-form input__form__fix" name="" id="">
        <option selected hidden disabled>
          Reportes por defecto:

          {{ defaultReportLimit[0].value }} diarios
        </option>
        <option value="3/day">3 reportes diarios</option>
        <option value="4/day">4 reportes diarios</option>
        <option value="5/day">5 reportes diarios</option>
      </select>
      <span class="custom-arrow-b"></span>
    </div>
    </div>
    <div class="container__footer__settings m-top-xxl">
      <div
        :class="
          quantity_report != defaultReportLimit[0].value
            ? 'btn-vue-primary fix-btn-mac'
            : 'desactive_draf_settings fix-btn-mac'
        "
        class=""
        @click="show_modal_report_limitation()"
      >
        <span> Confirmar </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconBackArrow from "../../assets/back.svg";
import Modal from "@/components/modal/Modal.vue";
import { mapGetters } from "vuex";
export default {
  props: ["titleBreadcrumb", "titleService"],
  components: {
    IconBackArrow,
    Modal,
  },

  created() {
    this.quantity_report = this.defaultReportLimit[0].value;
  },

  data: () => {
    return {
      quantity_report: "",
    };
  },

  computed: {
    ...mapGetters("SettingsApp", {
      reportLimitUseId: "reportLimitUseId",
      defaultReportLimit: "defaultReportLimit",
    }),
  },

  methods: {
    returnMainView() {
      this.$store.dispatch("SettingsApp/setViewRegionSettings", 0);
    },
    show_modal_report_limitation() {
      let element = this.$refs.modal;
      element.open();
    },
    selectQuantityReport(e) {
      this.quantity_report = e.target.value.slice(0, 1);

      let defaultReportValue = this.defaultReportLimit[0].value;

      if (this.quantity_report != defaultReportValue) {
        let id = this.reportLimitUseId;

        let params = {
          id: id,
          data: e.target.value,
        };

        this.$store.dispatch(
          "SettingsApp/activeChangeLimitUseInstance",
          params
        );
      } else {
        this.$store.dispatch(
          "SettingsApp/cleanChangeLimitUseInstance"
        );
      }
    },
  },
};
</script>

<style lang="scss"></style>
