var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__not_access",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : 'modal__default',attrs:{"id":"modal__not_access"}},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_c('div',{staticClass:"modal__coming__soon text-filter-light text-center"},[_c('div',{staticClass:"not__access__modal"},[_vm._v(" "+_vm._s(_vm.description)+" "),_c('DeniedIcon')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }