<template>
  <div class="sidebar" id="sidebar">
    <ModalWarning ref="modal_warning_save" title="warning" size="modal__counter_v2" identifier="user" :confirm="true"
      :cancel="true">
    </ModalWarning>
    <div class="logo_content">
      <div class="logo" id="logo-desktop-complete">
        <!-- //TODO: importar svg desde componente carpeta correspondiente -->
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 200 200" style="enable-background: new 0 0 200 200" xml:space="preserve">
          <g>
            <rect style="fill: #000032" width="200" height="200" />
            <g>
              <g>
                <g>
                  <g>
                    <path style="fill: #5a96ff" d="M58.91,105.13H38.42c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13H58.9
						c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13C64.04,107.43,61.74,105.13,58.91,105.13z" />
                    <path style="fill: #5a96ff" d="M161.58,105.13H79.39c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13c0,0,0.01,0,0.01,0
						h82.17c2.83,0,5.13-2.3,5.13-5.13C166.71,107.43,164.41,105.13,161.58,105.13z" />
                    <path style="fill: #5a96ff" d="M38.42,74.35h61.48c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13c0-2.83-2.3-5.13-5.13-5.13
						H38.42c-2.83,0-5.13,2.3-5.13,5.13C33.29,72.05,35.59,74.35,38.42,74.35z" />
                    <path style="fill: #5a96ff" d="M161.58,125.65H99.92c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13c0,0,0.01,0,0.01,0
						h61.65c2.83,0,5.13-2.3,5.13-5.13C166.71,127.95,164.41,125.65,161.58,125.65z" />
                    <path style="fill: #5a96ff" d="M79.39,125.65H38.42c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13h40.96
						c0,0,0.01,0,0.01,0c2.83,0,5.13-2.3,5.13-5.13C84.52,127.95,82.23,125.65,79.39,125.65z" />
                    <path style="fill: #5a96ff" d="M120.44,84.61L120.44,84.61l-82.02,0c-2.83,0-5.13,2.3-5.13,5.13s2.3,5.13,5.13,5.13h81.98
						c0.01,0,0.02,0,0.03,0c2.83,0,5.13-2.3,5.13-5.13C125.57,86.91,123.27,84.61,120.44,84.61z" />
                    <path style="fill: #5a96ff" d="M161.58,84.61h-20.57v0c-2.83,0-5.13,2.3-5.13,5.13c0,2.83,2.3,5.13,5.13,5.13
						c0.01,0,0.02,0,0.03,0h20.54c2.83,0,5.13-2.3,5.13-5.13S164.41,84.61,161.58,84.61z" />
                    <path style="fill: #5a96ff" d="M120.44,74.35c0,0,0.01,0,0.01,0h41.13c2.83,0,5.13-2.3,5.13-5.13c0-2.83-2.3-5.13-5.13-5.13
						h-41.14c-2.83,0-5.13,2.3-5.13,5.13C115.31,72.05,117.61,74.35,120.44,74.35z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="logo_name">MisAbogados</div>
      </div>
      <!-- menu close -->
      <burger class="close-desktop close-btn-sidebar burger" id="close-sidebar" @click="toggleDesktopSidebar" />

      <burger class="close-mobile burger" id="btn-mobile-sidebar" @click="toggleMobile" />
    </div>

    <ul class="nav_list">
      <li class="item_list" @click="toggleNavActive">
        <router-link :to="{ name: 'home' }" id="base-link">
          <homefilled v-if="homefilledStatus" />
          <home v-else />
          <span class="links_name">Home</span>
        </router-link>
        <span class="tooltip_sidebar">Home</span>
      </li>

      <!-- <li class="item_list" @click="toggleNavActive">
        <router-link :to="{ name: 'documents' }" id="documents">
          <DocumentsFilled v-if="DocumentStatus" />
          <documentos v-else />
          <span class="links_name">Documentos</span>
        </router-link>
        <span class="tooltip_sidebar">Documentos</span>
      </li> -->
      <li class="item_list" v-if="group != 'Company-Employee'" @click="toggleNavActive">
        <router-link :to="{ name: 'integratedAssistance' }" id="request">
          <QuotationFilled v-if="RequestStatus" />
          <quotation v-else />
          <span class="links_name">Solicitudes</span>
        </router-link>
        <span class="tooltip_sidebar">Solicitud</span>
      </li>

      <li class="item_list" v-if="group == 'Company-Admin' ||
        group == 'Admin' ||
        group == 'Company-Executive'
        " @click="toggleNavActive">
        <router-link :to="{ name: 'preStep' }" id="digitalSignature">
          <DigitalSignatureIcon class="icon_digital" v-if="digitalSignature" />
          <firmadigital class="icon_digital" v-else />
          <span class="links_name">Firma Digital</span>
        </router-link>
        <span class="tooltip_sidebar">Firma Digital</span>
      </li>
      <li class="item_list" v-if="group == 'Company-Admin' ||
        group == 'Admin'
        " @click="toggleNavActive">
        <router-link :to="{ name: 'scheduleDashboard' }" id="schedule">
          <AgendamientoFilled class="icon_digital" v-if="ScheduleStatus" />
          <Agendamiento class="icon_digital" v-else />
          <span class="links_name">Agendamiento</span>
        </router-link>
        <span class="tooltip_sidebar">Agendamiento</span>
      </li>

      <div class="line" v-if="group != 'Company-Employee'"></div>

      <li class="item_list" @click="toggleNavActive" v-if="group == 'Company-Admin' ||
        group == 'Admin' ||
        group == 'Company-Executive'
        ">
        <router-link :to="{ name: 'ListCompanyV2' }" id="companys">
          <CompanyFilled v-if="CompanyStatus" />
          <empresas v-else />
          <span class="links_name">Empresas</span>
        </router-link>
        <span class="tooltip_sidebar">Empresas</span>
      </li>
      <li class="item_list" @click="toggleNavActive" v-if="group == 'Admin' ||
        group == 'Company-Admin' ||
        group == 'Company-Executive'
        ">
        <router-link :to="{ name: 'ListEmployeesV2' }" id="employees">
          <EmployeesFilled v-if="EmployeesStatus" />
          <empleados v-else />
          <span class="links_name">Equipo</span>
        </router-link>
        <span class="tooltip_sidebar">Equipo</span>
      </li>
      <li class="item_list" v-if="group == 'Agent' || group == 'Admin'" @click="toggleNavActive">
        <router-link :to="{ name: 'reportTenant' }" id="reportTenants">
          <reportfilled class="icon_digital" v-if="reportTenantStatus" />
          <report class="icon_digital" v-else />
          <span class="links_name">Reportes</span>
        </router-link>
        <span class="tooltip_sidebar">Reportes</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import home from "../../assets/sidebarIcons/home.svg";
// import documentos from "../../assets/sidebarIcons/documentos.svg";
// import support from "../../assets/sidebarIcons/support.svg";
// import question from "../../assets/sidebarIcons/question.svg";
import quotation from "../../assets/sidebarIcons/quotation.svg";
import empresas from "../../assets/sidebarIcons/empresas.svg";
import empleados from "../../assets/sidebarIcons/empleados.svg";
import burger from "../../assets/sidebarIcons/hamburger.svg";

import homefilled from "../../assets/sidebarIcons/homefilled.svg";
// import DocumentsFilled from "../../assets/sidebarIcons/documentosfilled.svg";
// import SuportFilled from "../../assets/sidebarIcons/supportfilled.svg";
// import QuestionFilled from "../../assets/sidebarIcons/questionfilled.svg";
import QuotationFilled from "../../assets/sidebarIcons/quotationfilled.svg";
import CompanyFilled from "../../assets/sidebarIcons/empresasfilled.svg";
import EmployeesFilled from "../../assets/sidebarIcons/empleadosfilled.svg";

import Agendamiento from "../../assets/sidebarIcons/agendamiento.svg";
import AgendamientoFilled from "../../assets/sidebarIcons/agendamientofilled.svg";

import report from "../../assets/sidebarIcons/report.svg";
import reportfilled from "../../assets/sidebarIcons/reportfilled.svg";

import DigitalSignatureIcon from "../../assets/iconos_container/firmadigitalfilledFIX.svg";
import firmadigital from "../../assets/iconos_container/firmadigital.svg";
import ModalWarning from "@/components/modal/modalSaveWarning.vue";

export default {
  components: {
    home,
    // documentos,
    // support,
    // question,
    quotation,
    empresas,
    empleados,
    burger,
    homefilled,
    // DocumentsFilled,
    // SuportFilled,
    // QuestionFilled,
    QuotationFilled,
    CompanyFilled,
    EmployeesFilled,
    DigitalSignatureIcon,
    firmadigital,
    Agendamiento,
    AgendamientoFilled,
    report,
    reportfilled,
    ModalWarning,
  },
  data: function () {
    return {
      homefilledStatus: false,
      // DocumentStatus: false,
      digitalSignature: false,
      RequestStatus: false,
      // QuestionStatus: false,
      // SupportStatus: false,
      CompanyStatus: false,
      EmployeesStatus: false,
      ScheduleStatus: false,
      reportTenantStatus: false,
    };
  },

  computed: {
    ...mapGetters("Login", {
      is_staff: "is_staff",
      group: "group",
      totalCompanies: "totalCompanies",
    }),
    ...mapGetters("SettingsApp", {
      defaultValueLenguageUser: "defaultValueLenguageUser",
      defaultValueDarkMode: "defaultValueDarkMode",
      userDarkModeInstanceChange: "userDarkModeInstanceChange",
    }),
  },
  methods: {
    toggleMobile() {
      let mainContent = document.querySelector(".home_content");
      mainContent.classList.contains("home_content_active")
        ? this.disableDesktopSidebar()
        : this.normalMobileSidebar();
    },

    disableDesktopSidebar() {
      let sidebar = document.querySelector(".sidebar");
      sidebar.classList.remove("sidebar-menu");
      sidebar.classList.remove("active");
      let mainContent = document.querySelector(".home_content");
      let header = document.querySelector("header");
      header.classList.remove("active");
      mainContent.classList.remove("home_content_active");
      let overlay = document.getElementById("overlay");
      overlay.classList.remove("overlay-visible");
    },
    normalMobileSidebar() {
      let removeLogoComplex = document.getElementById("btn-mobile-sidebar");

      removeLogoComplex.classList.add("close-btn-sidebar");

      // console.log("en mobiles me activo");
      let sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-menu");
      sidebar.classList.toggle("active");

      let addLogoComplex = document.getElementById("logo-desktop-complete");

      addLogoComplex.classList.toggle("close-btn-sidebar");

      let overlay = document.getElementById("overlay");
      overlay.classList.toggle("overlay-visible");

      // esconder el icono desktop del sidebar que tiene una funcion distinta
      let bars = document.querySelector(".logo-mobile");
      bars.classList.toggle("active");
    },
    // funcionamiento para desktop sidebar button
    toggleDesktopSidebar() {
      let sidebar = document.querySelector(".sidebar");
      let closeSidebar = document.getElementById("close-sidebar");
      closeSidebar.classList.add("active-btn-sidebar");

      sidebar.classList.contains("sidebar-menu")
        ? this.disableMobileSidebar()
        : this.normalDesktopSidebar();
    },
    normalDesktopSidebar() {
      // console.log("de complex a expandido");

      let removeLogoComplex = document.getElementById("logo-desktop-complete");

      removeLogoComplex.classList.remove("close-btn-sidebar");

      let sidebar = document.querySelector(".sidebar");
      let sibebarOutside = document.getElementById("new-close-sidebar");
      let sidebarCurrent = document.getElementById("close-sidebar");
      sidebarCurrent.classList.add("close-btn-sidebar");
      sibebarOutside.classList.remove("active-icon");
      let header = document.querySelector("header");

      let headerLogo = document.getElementById("logo-header");
      headerLogo.classList.toggle("logo-visible");
      header.classList.toggle("active");
      sidebar.classList.toggle("active");
      let mainContent = document.querySelector(".home_content");

      mainContent.classList.toggle("home_content_active");

      // let closeSidebar = document.getElementById("close-sidebar");
      // closeSidebar.classList.add("active-btn-sidebar");
    },

    disableMobileSidebar() {
      let sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("active");
      sidebar.classList.remove("sidebar-menu");
    },

    isMobile() {
      // console.log("pre if");
      if (screen.width <= 1024) {
        // console.log("soy mobile");

        let removeLogoComplex = document.getElementById(
          "logo-desktop-complete"
        );

        removeLogoComplex.classList.add("close-btn-sidebar");

        // let addLogoComplex = document.getElementById("logo-desktop-complete");

        // addLogoComplex.classList.add("close-btn-sidebar");
        // console.log("activo mobile");
        // let initalActiveLink = document.getElementById("base-link");
        // let currentModule = localStorage.getItem("currentModule");

        // if (currentModule != "base-link") {
        //   initalActiveLink.classList.add("no-active");
        // } else {
        //   initalActiveLink.classList.add("nav-active");
        // }

        // let sidebar = document.getElementById("sidebar");
        // sidebar.classList.remove("active");

        return;
      } else {
        // console.log("activo desktop");
        let mainContent = document.getElementById("home_content");
        let header = document.getElementById("header");
        let sidebar = document.getElementById("sidebar");

        //base link active
        let initalActiveLink = document.getElementById("base-link");
        initalActiveLink.classList.add("nav-active");

        // current active module sidebar
        let item_list = document.getElementsByClassName("item_list");

        let currentModule = localStorage.getItem("currentModule")
          ? localStorage.getItem("currentModule")
          : "base-link";

        if (currentModule == "base-link") {
          this.homefilledStatus = true;
        }

        // if (currentModule == "documents") {
        //   this.DocumentStatus = true;
        // }

        if (currentModule == "digitalSignature") {
          this.digitalSignature = true;
          // this.$store.dispatch(
          //   "DigitalSignature/searchDigitalSignature",
          //   false
          // );
        }
        if (currentModule == "request") {
          this.RequestStatus = true;
        }

        // if (currentModule == "legal-question") {
        //   this.QuestionStatus = true;
        // }
        // if (currentModule == "suport") {
        //   this.SupportStatus = true;
        // }
        if (currentModule == "companys") {
          this.CompanyStatus = true;
        }
        if (currentModule == "employees") {
          this.EmployeesStatus = true;
        }
        if (currentModule == "schedule") {
          this.ScheduleStatus = true;
        }
        if (currentModule == "reportTenants") {
          this.reportTenantStatus = true;
        }

        // console.log("current", currentModule);

        for (let i = 0; i < item_list.length; i++) {
          let activeLink = item_list[i].childNodes[0];

          if (currentModule === activeLink.id) {
            // console.log(currentModule, activeLink.id);
            activeLink.classList.add("nav-active");
          } else {
            activeLink.classList.remove("nav-active");
          }
        }

        //fin current active module sidebar

        let headerLogo = document.getElementById("logo-header");

        headerLogo.classList.remove("logo-visible");

        sidebar.classList.add("active");
        header.classList.add("active");
        mainContent.classList.add("home_content_active");
      }

      // current active module sidebar
      let item_list = document.getElementsByClassName("item_list");

      let currentModule = localStorage.getItem("currentModule")
        ? localStorage.getItem("currentModule")
        : "base-link";

      for (let i = 0; i < item_list.length; i++) {
        let activeLink = item_list[i].childNodes[0];

        // console.log(currentModule, "es igual a active link id: ", activeLink)

        let id_element = activeLink.id;

        if (currentModule == id_element) {
          activeLink.classList.add("nav-active");
        } else {
          activeLink.classList.remove("nav-active");
        }
      }

      //fin current active module sidebar
    },
    // añade clase activa a links del sidebar
    toggleNavActive(e) {
      if (this.userDarkModeInstanceChange) {
        let param = "modal_warning_save";
        this.$store.dispatch("Config/setModalRef", param);
        let element = this.$refs.modal_warning_save;
        element.open();
      }

      this.homefilledStatus = false;
      // this.DocumentStatus = false;
      this.digitalSignature = false;

      this.RequestStatus = false;
      // this.QuestionStatus = false;
      // this.SupportStatus = false;
      this.CompanyStatus = false;
      this.EmployeesStatus = false;
      this.ScheduleStatus = false;
      this.reportTenantStatus = false;

      let removeLogoComplex = document.getElementById("logo-desktop-complete");

      removeLogoComplex.classList.remove("close-btn-sidebar");

      let btnvisible = document.getElementById("btn-mobile-sidebar");

      btnvisible.classList.remove("close-btn-sidebar");

      removeLogoComplex.classList.add("close-btn-sidebar");
      let container = e.currentTarget;
      let link = container.querySelector("li a");

      if (link.id == "base-link") {
        this.homefilledStatus = true;
      }

      // if (link.id == "documents") {
      //   this.DocumentStatus = true;
      // }
      if (link.id == "digitalSignature") {
        this.digitalSignature = true;
        // console.log("click pre step")
        this.$store.dispatch("DigitalSignature/cleanDocumentName");
        this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);
        // this.$router.push({ name: "preStep" });
      }
      if (link.id == "request") {
        this.RequestStatus = true;
      }

      // if (link.id == "legal-question") {
      //   this.QuestionStatus = true;
      // }
      // if (link.id == "suport") {
      //   this.SupportStatus = true;
      // }
      if (link.id == "companys") {
        this.$store.dispatch("CompanyV2/cleanFlagAdminForSingleCompany");
        this.CompanyStatus = true;
      }
      if (link.id == "employees") {
        this.EmployeesStatus = true;
      }
      if (link.id == "schedule") {
        this.ScheduleStatus = true;
      }
      if (link.id == "reportTenants") {
        this.reportTenantStatus = true;
      }

      localStorage.setItem("currentModule", link.id);

      let sidebar = document.getElementById("sidebar");
      sidebar.classList.remove("active");

      let overlay = document.getElementById("overlay");
      overlay.classList.remove("overlay-visible");

      link.classList.add("nav-active");
      let mainContent = document.querySelector(".home_content");
      let header = document.querySelector("header");
      header.classList.remove("active");
      mainContent.classList.remove("home_content_active");

      // if(screen.width > 768){

      // }

      // this.toggleMobile();

      //close sidebar new
      // let closeSidebar = document.getElementById("close-sidebar");
      // closeSidebar.classList.remove("active-btn-sidebar");

      //clases usadas para cuando se cierra sidebar añadir boton expandir y esconder el del header

      let sidebarInside = document.getElementById("close-sidebar");
      sidebarInside.classList.remove("close-btn-sidebar");

      if (screen.width <= 1024) {
        if (screen.width <= 768) {
          // console.log("nada");
        } else {
          let headerLogo = document.getElementById("logo-header");
          headerLogo.classList.add("logo-visible");
        }
      } else {
        // console.log("nada");
        let headerLogo = document.getElementById("logo-header");
        headerLogo.classList.add("logo-visible");
        let headerOpenSidebar = document.getElementById("new-close-sidebar");

        headerOpenSidebar.classList.add("active-icon");
      }
    },
  },
  created() {
    // this.isMobile();
  },
  mounted() {
    this.isMobile();
  },
};
</script>
<style lang="scss">
@import "./Sidebar.scss";

.burger {
  margin-top: 0.7rem !important;
  width: 100% !important;
  height: 25px;
  margin-left: 0.4rem;
  cursor: pointer;
}
</style>