<template>
  <div class="container__header__assistance">
    <ModalFilterEmployee
      ref="modal__filter__employee"
      size="ls"
      ref_id="modal__filter__employee"
    />
    <ModalFilterEmployeeCompanyAdmin
      ref="modal__filter__employee__company__switch"
      size="ls"
      ref_id="modal__filter__employee__company__switch"
    />
    <ModalOnBoardingEmployee
      ref="modal__onboarding__employee"
      size="onboarding"
      ref_id="modal__onboarding__employee"
    />
    <div class="header__employee__elements">
      <div class="header__employee__elements__left">
        <div class="header__employee__title">Equipo</div>
        <div class="filter__icon__employee">
          <div class="icon" @click="OpenFilterEmployeeModal()">
            <FilterIcon />
          </div>
        </div>
        <ItemsFilterTable class="mobile__restriction" />
      </div>
      <div class="header__employee__elements__right">
        <button
         v-if="group != 'Company-Executive'"
          class="fix-btn-mac button_add_employee"
          @click="selectedTypeEmployee"
        >
          <div class="container_title_table">
            Añadir {{ activeTableMembers ? "Miembros" : "Usuarios" }}
            del equipo
          </div>
          <div class="icon_add_employee">
            <AddEmployeeIcon />
          </div>
        </button>
        <div

         :class="group != 'Company-Executive' ? '' : 'no__executive_margin'"
         class="icon_onBoarding" @click="OpenOnBoarding()">
          <IconOnboarding />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterIcon from "@/assets/iconos_container/filters.svg";
import AddEmployeeIcon from "../../assets//fi-rs-user-add.svg";
// modal
import ModalFilterEmployee from "../modals/ModalFilterEmployee.vue";
import ModalFilterEmployeeCompanyAdmin from "../modals/ModalFilterEmployeeCompanySwitch.vue";
import ItemsFilterTable from "../itemsFilterTable.vue";
import ModalOnBoardingEmployee from "../../OnBoarding/ModalOnBoardingEmployee.vue";
import IconOnboarding from "../../OnBoarding/assets/Onboarding_icon2.svg";

import { mapGetters } from "vuex";

export default {
  components: {
    FilterIcon,
    ModalFilterEmployee,
    ModalFilterEmployeeCompanyAdmin,
    ModalOnBoardingEmployee,
    AddEmployeeIcon,
    ItemsFilterTable,
    IconOnboarding,
  },
  created() {
    this.listTotalCompanies = this.list__companies;
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__companies: "list__companies",
      tagsOfFilters: "tagsOfFilters",
      activeTableMembers: "activeTableMembers",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      is_staff: "is_staff",
      group: "group",
      company: "company",
      user_profile: "user_profile",
    }),
  },
  methods: {
    selectedTypeEmployee() {
      if (this.inSwitch) {
        this.$router.push({ name: "selectedEmployee" });
      } else if (this.group == "Admin") {
        this.$router.push({ name: "selectedEmployeeAdmin" });
      } else {
        this.$router.push({ name: "selectedEmployee" });
      }
    },
    OpenOnBoarding() {
      let param = "modal__onboarding__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__employee;
      element.open();
    },
    OpenFilterEmployeeModal() {
      if (this.inSwitch) {
        let param = "modal__filter__employee__company__switch";
        this.$store.dispatch("Config/setModalRef", param);
        let element = this.$refs.modal__filter__employee__company__switch;
        element.open();
      } else {
        let param = "modal__filter__employee";
        this.$store.dispatch("Config/setModalRef", param);
        let element = this.$refs.modal__filter__employee;
        element.open();
      }
    },
  },
};
</script>

<style lang="scss">
.no__executive_margin{
  margin-right: 1.5rem;
}
@import "./headertableEmployees.scss";
</style>
