<template>
  <div class="">
    <div class="main-recovery">
      <div class="container-recovery" v-if="!loader">
        <img
          class="m-top-s"
          alt="MisAbogados Logo"
          src="../../../assets/misabogados-login.png"
        />

        <label>Introduzca su nueva contraseña</label>
        <div class="container__reset__password">
          <input
            type="password"
            id="password"
            class="input-form"
            v-model="pass1"
          />
          <div class="container-password-icon fix__icon__position">
            <ShowiconPassword
              class="icon__password"
              :class="activeIcon ? 'show_password_icon' : ''"
              @click="show_password()"
              v-if="activeIcon"
            />
            <HideIconPassword
              class="icon__password"
              @click="show_password()"
              v-else
            />
          </div>
        </div>

        <label class="m-top-xs"
          >Introduzca su nueva contraseña nuevamente</label
        >
        <div class="container__reset__password">
          <input
            type="password"
            id="confirm"
            class="input-form"
            v-model="pass2"
          />
          <div class="container-password-icon fix__icon__position">
            <ShowiconPassword
              class="icon__password"
              :class="activeIconConfirm ? 'show_password_icon' : ''"
              @click="show_password_confirm()"
              v-if="activeIconConfirm"
            />
            <HideIconPassword
              class="icon__password"
              @click="show_password_confirm()"
              v-else
            />
          </div>
        </div>

        <p
          v-if="flagfield"
          class="m-top-s fix_hint"
          :class="errorfield1.length > 1 ? 'error-span' : ''"
        >
          {{ hints }}
        </p>
        <p v-else-if="errorfield" class="m-top-s fix_hint error-span">
          {{ errorfield2 }} {{ errorfield3 }}
        </p>

        <button
          @click="resetPassword()"
          class="btn-vue-secondary fix-btn-mac m-top-xl"
        >
          <span> Cambiar contraseña </span>
        </button>
      </div>
      <div class="container-recovery" v-else>
        <LoaderGeneric />
      </div>
    </div>
  </div>
</template>

<script>
import authService from "../services/authService";
import Vue from "vue";
import LoaderGeneric from "../../../components/loaderGeneric/LoadingMA.vue";
import ShowiconPassword from "../assets/eye1.svg";
import HideIconPassword from "../assets/eye2.svg";
import { mapGetters } from "vuex";

export default {
  name: "recover-password",
  props: {
    some_id: {
      required: true,
    },
    some_token: {
      required: true,
    },
  },
  components: {
    LoaderGeneric,
    ShowiconPassword,
    HideIconPassword,
  },
  created() {
    this.flagfield = true;
  },
  data() {
    return {
      errorfield: false,

      message: "Escriba a continuación su nueva contraseña",
      hints:
        "Para su seguridad, la contraseña debe ser de al menos 8 caracteres, debe incluir un número, una letra mayúscula, un caracter especial, y no debe ser igual a su nombre de usuario o correo",
      pass1: "",
      pass2: "",
      info: "",
      modal: false,
      errorfield1: "",
      errorfield2: "",
      errorfield3: "",
      flagfield: true,
      loader: false,
      activeIcon: false,
      activeIconConfirm: false,
    };
  },
  computed: {
    ...mapGetters("Login", {
      user_profile_data: "user_profile",
    }),
  },
  methods: {
    onErrorCreateCompany(data) {
      Vue.toasted.error(data);
    },
    resetPassword() {
      let firstnamelower =
        this.user_profile_data != null
          ? this.user_profile_data.first_name.toLowerCase()
          : "";
      let fistName =
        this.user_profile_data != null ? this.user_profile_data.first_name : "";

      let surnamelower =
        this.user_profile_data != null
          ? this.user_profile_data.surname.toLowerCase()
          : "";
      let surname =
        this.user_profile_data != null ? this.user_profile_data.surname : "";

      // nombre dentro de la app
      let usernamelower =
        this.user_profile_data != null
          ? this.user_profile_data.user.username.toLowerCase()
          : "";

      // nombre dentro de la app
      let username =
        this.user_profile_data != null
          ? this.user_profile_data.user.username
          : "";

      let nameEmail =
        this.user_profile_data != null
          ? this.user_profile_data.user.email.substring(
              0,
              this.user_profile_data.user.email.lastIndexOf("@")
            )
          : "";

      let nameEmailLower = nameEmail > 0 ? nameEmail.toLowerCase() : "";

      let passwordlower = this.pass1.toLowerCase();

      if (
        // si cumple con:
        //1. mayor a 8 caracteres
        //2. Contiene caracter especial
        //3. Contiene un caracter en mayuscula
        this.pass1.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[&\\#,+()$~%.'":*?<>{}#?}!@$%^&-]).{8,}$/
        )
      ) {
        if (
          !passwordlower.match(fistName) &&
          !passwordlower.match(firstnamelower) &&
          !passwordlower.match(surnamelower) &&
          !passwordlower.match(surname) &&
          !passwordlower.match(usernamelower) &&
          !passwordlower.match(username) &&
          !passwordlower.match(nameEmailLower) &&
          !passwordlower.match(nameEmail)
        ) {
          if (this.pass1 == this.pass2) {
            this.loader = true;
            authService
              .reset_password_exec(this.some_id, this.some_token, {
                new_password1: this.pass1,
                new_password2: this.pass2,
              })
              .then(() => {
                this.$toasted.success(
                  "Su contraseña a sido actualizada correctamente"
                );

                this.$store.dispatch("Login/logOut", null);

                this.$router.push({ name: "LoginPage" });
              })
              .catch((error) => {
                console.log(error.response.data);
                this.loader = false;
                this.errorfield = true;
                let required = "Password con errores";
                this.onErrorCreateCompany(required);
              });
          } else {
            this.activeIcon = false;
            this.activeIconConfirm = false;
            this.flagfield = false;
            this.errorfield1 = "";
            this.errorfield2 = "";
            this.errorfield3 = "Las contraseñas deben ser iguales";
            this.errorfield = true;
            let required = "Password con errores";
            this.onErrorCreateCompany(required);
          }
          // si no encontro estos valores en el store, vamos a pegarle al backend
        } else {
          this.loader = true;
          authService
            .reset_password_exec(this.some_id, this.some_token, {
              new_password1: this.pass1,
              new_password2: this.pass2,
            })
            .then(() => {
              this.$toasted.success(
                "Su contraseña a sido actualizada correctamente"
              );

              this.$store.dispatch("Login/logOut", null);
              this.$router.push({ name: "LoginPage" });
            })
            .catch(() => {
              this.activeIcon = false;
              this.activeIconConfirm = false;
              this.flagfield = false;
              this.errorfield1 = "";

              // console.log(error.response.data);
              // queremos darle el valor de error que nos de el back
              this.errorfield2 =
                "Su contraseña no debe contener campos parecidos al email o al nombre de usuario";
              this.loader = false;
              this.errorfield = true;
              let required = "Password con errores";
              this.onErrorCreateCompany(required);
            });
        }
      } else {
        this.activeIcon = false;
        this.activeIconConfirm = false;
        this.errorfield1 =
          "Para su seguridad, la contraseña debe ser de al menos 8 caracteres, debe incluir un número, una letra mayúscula, un caracter especial";
        this.errorfield = true;
        let required = "Password con errores";
        this.onErrorCreateCompany(required);
      }
    },
    show_password() {
      let pass = document.getElementById("password");
      if (pass.type == "password") {
        pass.type = "text";
        this.activeIcon = true;
      } else {
        pass.type = "password";
        this.activeIcon = false;
      }
    },
    show_password_confirm() {
      let pass = document.getElementById("confirm");
      if (pass.type == "password") {
        pass.type = "text";
        this.activeIconConfirm = true;
      } else {
        pass.type = "password";
        this.activeIconConfirm = false;
      }
    },
  },
};
</script>

<style lang="scss">
.fix_hint {
  font-size: 14px;
  height: 20px;
}
.container__reset__password {
  display: flex;
  gap: 1rem;
}
.fix__icon__position {
  transform: translateY(5px);
}
</style>
