import HomeModule from "./HomeModule.vue";
import HomePage from "./pages/HomePage.vue";

export const HomeRoutes = [
  {
    path: "/",
    component: HomeModule,
    children: [
      {
        path: "/",
        name: "home",
        component: HomePage,
      },
    ],
  },
  {
    path: "/home",
    component: HomeModule,

    children: [
      {
        path: "/",
        name: "home",
        component: HomePage,
      },
    ],
  },
];
