<template>
  <div class="container-slots">
    <div class="center-loader new_loader_padding" v-if="loading">
      <LoaderGeneric />
      <span class="loader_text_question"
        >Creando solicitud, esto puede tomar unos minutos…</span
      >
    </div>

    <div class="container-base" v-else>
      <div class="breadcrumb">
        <span
          class="text_document m-left-none color_gray_i"
          @click="goToDashboardPreStep"
        >
          Solicitudes >
        </span>

        <span class="text_document">Nueva Solicitud</span>
      </div>
      <div class="container-request-document container_request_assistance">
        <div class="container__input_requester" v-if="inSwitch">
          <div>Solicitante externo</div>
          <span class="">
            Estas añadiendo una solicitud como un solicitante externo a la
            empresa en la que te encuentras. Selecciona a un usuario de la
            empresa para agregar esta solicitud.
          </span>

          <div class="container__selected__requester">
            <div class="custom-select">
              <input
                list="clientRequester"
                @change="setRequesterSelected($event)"
                class="input-form input__dalalist"
                :placeholder="requesterName"
              />

              <datalist id="clientRequester">
                <option
                  v-for="(el, index) in currentAssignedRequesterEmployees"
                  :key="index"
                  :value="el.name"
                >
                  {{ el.user.email }}
                </option>
              </datalist>
              <span class="custom-arrow-b"></span>
            </div>
            <div
              class="check__requester"
              :class="flagEditRequester ? 'active' : 'desactive'"
            >
              <Check :class="flagEditRequester ? 'active' : 'desactive'" />
            </div>
          </div>
        </div>
        <div
          class="container_options_assistance"
          :class="!flagEditRequester ? 'desactive' : 'active'"
        >
          <div class="container__input">
            <div>Asunto</div>
            <input
              class="input-form test_input"
              id="title"
              v-model="title"
              type="text"
              @keyup="validateFields($event)"
            />
            <span class="error-span" v-if="errorFieldTitle"
              >Campo requerido</span
            >
          </div>
          <div class="container__input">
            <div>Tipo de servicio</div>
            <div class="custom-select test_input">
              <select
                class="select_field input-form"
                v-model="typeService"
                id="typeService"
                @change="validateFields($event)"
              >
                <option selected hidden disabled>
                  Seleccione una respuesta
                </option>
                <option
                  :value="el.id"
                  v-for="(el, index) in listGroupAssistance"
                  :key="index"
                >
                  {{ el.title }}
                </option>
              </select>
              <span class="custom-arrow-b"></span>
            </div>
            <span class="error-span" v-if="errorFieldServiceType"
              >Campo requerido</span
            >
          </div>
        </div>
        <div
          class="container_text_area"
          :class="!flagEditRequester ? 'desactive' : 'active'"
        >
          <div>Descripción</div>
          <textarea
            class="text-area-primary"
            v-model="description"
            id="description"
            @keyup="validateFields($event)"
          >
          </textarea>
          <span class="error-span" v-if="errorFieldDecription"
            >Campo requerido</span
          >
        </div>
        <div
          class="container-request footer_selected_file"
          :class="!flagEditRequester ? 'desactive' : 'active'"
        >
          <div class="container_files_request container_pdf_limit">
            <span class="text_document">Archivo(s) adjunto</span>
            <div class="container-file container_pdf_limit">
              <div class="input__custom__file">
                <span class="span_file" v-if="files.length < 1">
                  Seleccionar un archivo</span
                >
                <span class="span_file" v-else>Agregar otro archivo</span>
                <input
                  type="file"
                  multiple
                  class="input-add"
                  accept=".doc,.docx,application/pdf"
                  ref="fileInput"
                  @change="selectedMultipleDocument($event)"
                />
              </div>
              <div v-for="(file, index) in multipleFilesTemplate" :key="index">
                <div class="container__pdf__close">
                  <div class="name__pfd">
                    {{ file.completeName }}
                    <span class="sizes__pdf">({{ file.tamanio }})</span>
                  </div>

                  <div
                    class="close__file"
                    @click="deleteFile(file.completeName)"
                  >
                    x
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="request-buttons button__send_request"
          :class="!flagEditRequester ? 'desactive' : 'active'"
        >
          <button
            class="btn-vue-primary fix-btn-mac color_hover_button_standard"
            :disabled="loading"
            @click="ConfirmAssistance()"
          >
            <span class="f-14"> Realizar solicitud </span>
          </button>
        </div>
      </div>
    </div>
    <div class="container_footer_information" v-if="!loading">
      <div class="container_footer_elements">
        <div class="container_footer_sub_elements">
          <div class="text_footer_information">
            En esta sección puedes realizar
            <span> solicitudes legales de manera personalizada.</span> Un
            abogado responderá para ti:
          </div>
          <div class="text_footer_information">
            <span>1.</span> Asigna un título y una categoría a la solicitud /
            <span>2.</span> Redacta tu solicitud legal y adjunta las imágenes o
            documentos que sean necesarios / <span>3.</span> Tu consulta será
            asignada a un abogado del equipo <br />
            /<span> 4.</span> Recibe tu respuesta en tu correo.
          </div>
        </div>
        <div class="icon_footer_information"><CreateNewAssitanceSVG /></div>
      </div>
    </div>
    <div class="" v-else></div>
  </div>
</template>

<script>
import LoaderGeneric from "../../../components/loaderGeneric/LoadingMA.vue";

// import for icon
import CreateNewAssitanceSVG from "../../../assets/iconos_container/quotation.svg";

import Check from "../assets/check.svg";

// import for service
import integratedAssistance from "../services/integratedAssistance";
import { USER_ROLE } from "@/utils/roles";

import { mapGetters } from "vuex";

export default {
  components: {
    LoaderGeneric,
    CreateNewAssitanceSVG,
    Check,
  },
  created() {
    this.$store.dispatch("Assistance/getGroupsAssitance");

    // with this we clear the current assistance for fix modal funcionality (cache problems in "ver assistencia recien creada")
    this.$store.dispatch("Assistance/cleanCurrentAsistance");

    if (this.group == USER_ROLE.ADMIN) {
      if (this.inSwitch) {
        this.flagEditRequester = false;

        let fields = "id,full_name,group,user";

        let companyRequester = this.idInSwitch;

        let admin_company_id = this.idInSwitch;

        let payloadListRequester = {
          admin_company_id: admin_company_id,
          companyRequester: companyRequester,
          group_id_in:
            "01183c63-cdfc-4836-ad3c-3fcaebcb372f,d2aed6fa-7fd3-4cd6-8733-64c07177a1ea",
          fields: fields,
        };

        this.$store.dispatch(
          "Assistance/getListRequesterAssignedForSwitch",
          payloadListRequester
        );
      }
    }
  },

  data() {
    return {
      title: "",
      requesterSwitch: "",
      requesterName: "Selecciona un solcitante",
      description: "",
      loading: false,
      files: [],
      filesNames: [],
      multipleFilesTemplate: [],
      maxSizes: 15971520,
      totalSizesOwnFiles: 0,
      typeService: "",
      errorFieldDecription: false,
      errorFieldTitle: false,
      errorFieldServiceType: false,
      totalSizeMultiple: 0,
      requesterIdForService: null,
      flagEditRequester: true,
    };
  },

  computed: {
    ...mapGetters("Assistance", {
      listGroupAssistance: "listGroupAssistance",
      currentAssignedRequesterEmployees: "currentAssignedRequesterEmployees",
      getIdFromForceAssigment: "getIdFromForceAssigment",
    }),
    ...mapGetters("Login", {
      is_staff: "is_staff",
      group: "group",
      company: "company",
      user_profile: "user_profile",
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    totalFiles() {
      return this.files.length > 1 ? true : false;
    },
  },
  methods: {
    setRequesterSelected(e) {
      let name_selected = e.target.value;

      let requesterSelected = this.currentAssignedRequesterEmployees.filter(
        (el) => el.name == name_selected
      );

      if (requesterSelected.length > 0) {
        let payload = requesterSelected[0].id;

        // this.requesterIdForService = tempData;
        this.$store.dispatch("Assistance/addAssignedUserForRequest", payload);

        if (this.requesterAssigned != null) {
          if (name_selected == this.requesterAssigned.name) {
            this.flagEditRequester = false;
          } else {
            this.flagEditRequester = true;
          }
        } else {
          this.flagEditRequester = true;
        }
      } else {
        this.flagEditRequester = false;

        this.$store.dispatch("Assistance/cleanAssignedUserForRequest");
      }
    },
    ConfirmAssistance() {
      this.loading = true;

      //Validate the inputs (requeriments)
      if (
        this.title.length > 0 &&
        this.description.length > 0 &&
        this.typeService.length > 0
      ) {
        // Data from inputs to the service
        var formData = new FormData();
        formData.append("description", this.description);
        formData.append("title", this.title);
        formData.append("service_type_id", this.typeService);

        if (this.inSwitch && USER_ROLE.ADMIN == this.group) {

          formData.append("requester_id", this.getIdFromForceAssigment);
        }

        //TODO validar si estas en un tenant externo, si eres admin entonces
        // envia el usuario asignado para la solicitud de creación en curso.
        // formData.append("requester_id", this.getIdFromForceAssigment);

        if (this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            formData.append("requested_files", this.files[i]);
          }
        }
        let id_company = this.inSwitch
          ? this.idInSwitch
          : this.user_profile.company.id;

        // Create assistance service
        integratedAssistance
          .createAssistance(id_company, formData)
          .then(() => {
            // this dispatch set the modal in false , this will open the modal in ListAsssintance (ListAsssintance has the next step of the funcionality )
            this.$store.dispatch("Assistance/setFalseAssitance");
          })
          .then(() => {
            let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

            let payload = {
              admin_company_id: company_id,
              fields:
                "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
              limit: "5",
            };
            this.$store.dispatch("Assistance/cleanTagsOfFilter");
            this.$store.dispatch("Assistance/deactiveFilter");
            this.$store.dispatch("Assistance/getAllAssistance", payload);
          })
          .then(() => {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$router.push({ name: "listRequestIntegrated" });
            }, 2000);
          })
          .catch(() => {
            this.errorFieldServiceType =
              this.typeService.length > 0 ? false : true;
            this.errorFieldTitle = this.title.length > 0 ? false : true;
            this.errorFieldDecription =
              this.description.length > 0 ? false : true;
            this.loading = false;
          });
        // in case of error , html show error fields personalized for each camp
      } else {
        this.errorFieldServiceType = this.typeService.length > 0 ? false : true;
        this.errorFieldTitle = this.title.length > 0 ? false : true;
        this.errorFieldDecription = this.description.length > 0 ? false : true;

        this.loading = false;
      }
    },
    validateFields(e) {
      // validate live the fields with errors
      let field = e.target.id;
      if (field == "title") {
        if (this.title.length > 0) {
          this.errorFieldTitle = false;
        } else {
          this.errorFieldTitle = true;
        }
      }
      if (field == "description") {
        if (this.description.length > 0) {
          this.errorFieldDecription = false;
        } else {
          this.errorFieldDecription = true;
        }
      }
      if (field == "typeService") {
        if (this.typeService.length > 0) {
          this.errorFieldServiceType = false;
        } else {
          this.errorFieldServiceType = true;
        }
      }
    },

    goToDashboardPreStep() {
      this.$router.push({ name: "integratedAssistance" });
    },
    deleteFile(file) {
      // search in the template array for all the files who are differente for the selected file (via complete name comparation)
      let arrayForTemplateNames = this.multipleFilesTemplate.filter((e) => {
        return e.completeName !== file;
      });
      // new array with the new values (except the coincidence one)
      this.multipleFilesTemplate = arrayForTemplateNames;

      // its the same process , but in this case we work with the array for the service
      let arrayForService = this.files.filter((e) => {
        return e.name !== file;
      });
      // new array with the new values (except the coincidence one)
      this.files = arrayForService;

      // Reset the input of the template , so we can add previous elemnts again
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
    },
    selectedMultipleDocument(e) {
      // We store the file selected from the user in a variable (fileArray)

      let fileArray = e.target.files;

      // this set the max of the bytes the user can send
      const MAXIMO_TAMANIO_BYTES = 15971520;

      for (let index = 0; index < fileArray.length; index++) {
        this.totalSizeMultiple = fileArray[index].size + this.totalSizeMultiple;
      }
      // We evaluate the size of the loaded file, in case is bigger , a alert is show in the template
      if (this.totalSizeMultiple > MAXIMO_TAMANIO_BYTES) {
        const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
        alert(`El tamaño máximo es ${tamanioEnMb} MB`);
      } else {
        // in case the file have a correct size , we store the this file in diferents variables
        for (let index = 0; index < fileArray.length; index++) {
          this.files.push(fileArray[index]);
        }

        // This storage the name of files and the size in the template

        // This if/else evaluate for the case we need to transform in "MB" or "KB"
        //MB case
        if (this.totalSizeMultiple > 1000000) {
          // Math.ceil returns the closest integer greater than or equal to a given number so we can have "nice" number to show in the template

          for (let index = 0; index < fileArray.length; index++) {
            this.files.push(fileArray[index]);
            let tamanioEnMbTemp = Math.ceil(fileArray[index].size / 1000000);
            let tamanioMb = `${tamanioEnMbTemp}MB`;

            this.multipleFilesTemplate.push({
              completeName: fileArray[index].name,
              tamanio: tamanioMb,
            });
          }
          //KB case
        } else {
          for (let index = 0; index < fileArray.length; index++) {
            this.files.push(fileArray[index]);
            let tamanioEnKbTemp = Math.ceil(fileArray[index].size / 1024);
            let tamanioEnKb = `${tamanioEnKbTemp}KB`;

            this.multipleFilesTemplate.push({
              completeName: fileArray[index].name,
              tamanio: tamanioEnKb,
            });
          }
        }

        // this storage the addition of the total size of the files the user is uploading , so we can evaluate if is valid (service max size requirements)
        this.totalSizesOwnFiles =
          this.totalSizesOwnFiles + this.totalSizeMultiple;

        this.filesNames.push(this.multipleFilesTemplate);
        // [file1, file2, file3]
      }
    },
    selectedDocument(e) {
      // We store the file selected from the user in a variable (file)
      let file = e.target.files[0];

      // this set the max of the bytes the user can send
      const MAXIMO_TAMANIO_BYTES = 15971520;

      // We evaluate the size of the loaded file, in case is bigger , a alert is show in the template
      if (file.size > MAXIMO_TAMANIO_BYTES) {
        const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
        alert(`El tamaño máximo es ${tamanioEnMb} MB`);
      } else {
        // in case the file have a correct size , we store the this file in diferents variables
        this.files.push(file);
        let completeName = file.name;

        // This storage the name of files and the size in the template
        let filesTemplate;

        // This if/else evaluate for the case we need to transform in "MB" or "KB"
        //MB case
        if (file.size > 1000000) {
          // Math.ceil returns the closest integer greater than or equal to a given number so we can have "nice" number to show in the template
          let tamanioEnMbTemp = Math.ceil(file.size / 1000000);
          let tamanioMb = `${tamanioEnMbTemp}MB`;

          filesTemplate = {
            completeName: completeName,
            tamanio: tamanioMb,
          };

          //KB case
        } else {
          let tamanioEnKbTemp = Math.ceil(file.size / 1024);
          let tamanioEnKb = `${tamanioEnKbTemp}KB`;
          filesTemplate = {
            completeName: completeName,
            tamanio: tamanioEnKb,
          };
        }

        // this storage the addition of the total size of the files the user is uploading , so we can evaluate if is valid (service max size requirements)
        this.totalSizesOwnFiles = this.totalSizesOwnFiles + file.size;

        this.filesNames.push(filesTemplate);
        // [file1, file2, file3]
      }
    },
  },
};
</script>

<style lang="scss">
.loader_text_question {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  color: var(--title_origin) !important;
}
.container_options_assistance {
  display: flex;
  width: 100% !important;
  gap: 1rem;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &.desactive {
    opacity: 0.5;
    pointer-events: none;
  }
}

.footer_selected_file {
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &.desactive {
    opacity: 0.5;
    pointer-events: none;
  }
}

.button__send_request {
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &.desactive {
    opacity: 0.5;
    pointer-events: none;

    > button {
      background-color: gray !important;
      border-color: gray !important;
    }
  }
}
.new_loader_padding {
  padding-bottom: 7rem;
}
.container__input {
  width: 50% !important;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.container_files_request {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  > .text_document {
    font-weight: bold;
    color: var(--text-document-draf) !important;
  }
}
.container_request_assistance {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  gap: 1rem;
}
.container__input_requester div {
  font-weight: bold;
  color: var(--text-document-draf) !important;
}
.container__input div {
  font-weight: bold;
  color: var(--text-document-draf) !important;
}
.container_text_area div {
  font-weight: bold;
  color: var(--text-document-draf) !important;
}
.container_text_area {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &.desactive {
    opacity: 0.5;
    pointer-events: none;
  }
}
.container_footer_information {
  box-shadow: 0 0 0.25rem var(--input_border_normal) !important;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container_footer_information {
  > div {
    color: var(--text_card_btn_pre_step);
  }
}
.text_footer_information span {
  color: var(--text_card_btn_pre_step);
  font-weight: bold;
}
.container_footer_information {
  background: var(--footer_information) !important;
  padding: 1rem;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.icon_footer_information {
  fill: var(--svg_pre_step) !important;
  stroke: var(--svg_pre_step) !important;
  height: 50px !important;
  width: 50px !important;
}
.container_footer_elements {
  display: flex;
  gap: 2rem;
  align-items: center;
  > .container_footer_sub_elements {
    width: 90%;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      width: 100% !important;
    }
  }
  > .icon_footer_information {
    width: 10%;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      display: none !important;
    }
  }
}
.container_options_assistance {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    flex-wrap: wrap;
  }
}
.container__input {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 100% !important;
  }
}
.span_file {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    white-space: nowrap;
    padding: 0 0.3rem !important;
  }
}
.container__pdf__close {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    white-space: nowrap;
    padding: 0 0.3rem !important;
  }
}
.container_footer_sub_elements {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.input-form option:hover {
  background-color: red !important; /* Cambia el color de fondo del elemento en hover */
  color: white !important; /* Cambia el color del texto en hover */
}
.container__selected__requester {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  > div.custom-select {
    width: 40%;
  }
}

.check__requester {
  width: 35px !important;
  height: 35px !important;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    background: #0064ff;
  }

  &.desactive {
    background: rgba(203, 212, 230, 0.3);
  }

  > svg {
    &.active {
      > path {
        stroke: white;
      }
    }

    &.desactive {
      > path {
        stroke: gray;
      }
    }
  }
}
.input-add {
  width: 40px !important;
}
</style>
