<template>
  <div class="container__progressBar">
    <ul class="progressBar">
      <li :class="stepEmployees >= 0 ? 'active_progressbar' : ''">
        Datos personales
      </li>
      <li :class="stepEmployees >= 1 ? 'active_progressbar' : ''">
        Datos empresa
      </li>
      <li :class="stepEmployees >= 2 ? 'active_progressbar' : ''">
        Datos bancarios
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProgressBarEmployee",
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployees: "stepEmployees",
    }),
  },
};
</script>

<style lang="scss">
.container__progressBar {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}
.progressBar {
  counter-reset: step;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.progressBar li,
.progressBar li.active_progressbar {
  color: #000032;
}

.progressBar li {
  float: left;
  width: 33.33%;
  position: relative;
  text-align: center;
}

.progressBar li::before {
  content: counter(step);
  counter-increment: step;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: 1px solid #9c9c9c;
  text-align: center;
  border-radius: 50%;
  background-color: #e4e4e4;
  margin: 0px auto 10px auto;
  display: block;
  position: relative;
  z-index: 1111111 !important;
}

.progressBar li::after {
  content: "";
  width: 100%;
  height: 8px;
  background-color: #ddd;
  top: 12px;
  left: -50%;
  position: absolute;
  z-index: 1;
}

.progressBar li:first-child::after {
  content: none;
}

.progressBar li.active_progressbar::before {
  border-color: #619aff;
}

// fix input edit employee

.field_edit_employee div input {
  width: 100% !important;
}

.left-side-edit,
.right-side-edit {
  width: 50% !important;
}
</style>
