// //for appsync
import Vue from "vue";

import AWSAppSyncClient from "aws-appsync";
import VueApollo from "vue-apollo";


const config = {
  url: process.env.VUE_APP_API_URL_APP_SYNC,
  region: 'us-east-1',
  auth: {
    type: 'API_KEY',
    apiKey: process.env.VUE_APP_API_KEY_APP_SYNC
  }
};

const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  }
};

const defaultClient = new AWSAppSyncClient(config, options);

const apolloProvider = new VueApollo({ defaultClient });

Vue.use(VueApollo);

export default apolloProvider;

// export default {
//   "graphqlEndpoint": "https://h5w6afbponf3tflukwwczwreu4.appsync-api.us-east-1.amazonaws.com/graphql",
//   "region": "us-east-1",
//   "authenticationType": "API_KEY",
//   "apiKey": "da2-y64fqikgfvdy3ffromhs7psnay"
// }