<template>
  <div class="">
    <div class="auto__selected">
      <input
        type="checkbox"
        name=""
        id=""
        v-model="ownUser"
        v-on:change="addOwnSignerFromCheck()"
      />
      <div class="sub-title-table">
        Incluirme a mí mismo como firmante del documento
      </div>
      <div class="error_check_signer" v-if="validationSignerForCheck">
        {{ this.errorFromCheckDuplicate }}
      </div>
    </div>
    <div
      class="container__add__signer"
      v-if="initialSignerFromCheck.length > 0"
    >
      <input
        type="text"
        class="input-form disable__input"
        placeholder="Nombre Completo"
        v-model="nombreCheck"
        disabled
      />
      <input
        type="text"
        class="input-form disable__input"
        placeholder="Correo electrónico"
        v-model="correoCheck"
        disabled
      />
      <input
        type="text"
        class="input-form disable__input"
        placeholder="RUT"
        v-model="rutCheck"
        disabled
      />
    </div>
    <!-- add signer innitial -->
    <div class="container__add__signer" v-if="validateFirstSigner != false">
      <input
        type="text"
        class="input-form"
        placeholder="Nombre Completo"
        v-model="name"
        @keyup="validateEmptyFieldsName(name)"
        :class="firstSigner == false ? 'disable__input' : ''"
        :disabled="firstSigner == false"
      />
      <div class="field__rut">
        <input
          type="text"
          class="input-form"
          placeholder="Correo electrónico"
          v-model="email"
          @keyup="
            validateEmptyFieldsEmail(email);
            validateEmailInnitialSigner(email);
          "
          :disabled="firstSigner == false"
          :class="firstSigner == false ? 'disable__input' : ''"
        />
        <div class="text_danger" v-if="emailValidation">
          {{ errorEmail }}
        </div>
      </div>
      <div class="field__rut">
        <input
          type="text"
          class="input-form"
          placeholder="RUT"
          required
          name="rut"
          v-model="rut"
          @keyup="validateRutInnitialSigner(rut)"
          :class="firstSigner == false ? 'disable__input' : ''"
          :disabled="firstSigner == false"
        />

        <div class="text_danger" v-if="errorRutFlag">
          {{ errorRut }}
        </div>
      </div>

      <button
        class="button__check"
        v-if="initialSigner == null"
        @click="addSignerInitial()"
      >
        <confirmFirma class="confirm_btn" />
      </button>
      <div class="container_valid_signer">
        <div
          v-if="!firstSigner"
          class="cancel__btn"
          @click="deleteInitialtSigner(firstSigner)"
        >
          <cancelBtn />
        </div>

        <div class="confirm_btn__rdy" v-if="!firstSigner">
          <confirmFirma class="" />
        </div>
      </div>
    </div>

    <div class="text_danger" v-if="alertFieldEmptyInitial">
      Debes llenar todos los campos solicitados antes de agregar un firmante
    </div>
    <!-- add signers -->
    <div
      class="container__add__signer"
      v-for="(signer, index) in signersForFront"
      :key="index"
    >
      <input
        type="text"
        class="input-form"
        placeholder="Nombre Completo"
        v-model="signer.name"
        @keyup="validateEmptyFieldsName(signer.name)"
        :disabled="signer.add == false"
        :class="signer.add == false ? 'disable__input' : ''"
        required
      />
      <div class="field__rut">
        <input
          type="text"
          class="input-form"
          placeholder="Correo electrónico"
          v-model="signer.email"
          @keyup="
            validateEmptyFieldsEmail(signer.email);
            validateEmailSigner(signer);
          "
          :disabled="signer.add == false"
          :class="signer.add == false ? 'disable__input' : ''"
          required
        />
        <div
          class="text_danger"
          v-if="errorEmailFlagSinger && signer.add == true"
        >
          {{ errorEmail }}
        </div>
      </div>
      <div class="field__rut">
        <input
          type="text"
          class="input-form"
          placeholder="RUT"
          @keyup="validateRutNewSigner(signer)"
          v-model="signer.rut"
          :disabled="signer.add == false"
          :class="signer.add == false ? 'disable__input' : ''"
        />

        <div
          class="text_danger"
          v-if="errorRutFlagSigner && signer.add == true"
        >
          {{ errorRut }}
        </div>
      </div>
      <button
        class="button__check"
        v-if="signer.add == true"
        @click="addSigner(signer)"
      >
        <confirmFirma class="confirm_btn" />
      </button>
      <div class="container_valid_signer">
        <div
          v-if="signer.add == false"
          class="cancel__btn"
          @click="deletetSigner(signer)"
        >
          <cancelBtn />
        </div>

        <div class="confirm_btn__rdy" v-if="signer.add == false">
          <confirmFirma class="" />
        </div>
      </div>
    </div>
    <div class="text_danger" v-if="alertFieldEmpty">
      Debes llenar todos los campos solicitados antes de agregar un firmante
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cancelBtn from "../../../assets/iconos_container/cancel.svg";
import confirmFirma from "../../../assets/iconos_container/confirm_firma.svg";
import formatRut from "../../../utils/scriptRUT";

export default {
  name: "Signer",
  components: {
    cancelBtn,
    confirmFirma,
  },

  data: () => {
    return {
      name: "",
      email: "",
      rut: "",
      nombreCheck: "",
      correoCheck: "",
      rutCheck: "",
      signersForFront: [],
      initialSigner: null,
      initialSignerFromCheck: [],
      firstSigner: true,
      validateFirstSigner: true,
      ownUser: false,
      flagUserMainFromDraft: false,
      signersFinalDraft: null,
      alertFieldEmpty: false,
      alertFieldEmptyInitial: false,
      errorRutFlag: false,
      errorRut: "",
      emailValidation: false,
      errorEmail: "",
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      errorEmailFlagSinger: false,
      errorRutFlagSigner: false,
      validatorRutInnitalSigner: "",
      validatorRutNewSigner: "",
      rutInnitialValue: null,
      validationFoundEmail: false,
      validationFoundEmailDraft: false,
      validationSignerForCheck: false,
      errorFromCheckDuplicate: "",
      validationFoundRutDraft: false,
      validationFoundRut: false,
      validationDuplicateInnitialEmail: false,
      validationDuplicateInnitialRut: false,
    };
  },

  beforeMount() {
    if (this.signerDraft != null) {
      this.signersFinalDraft = this.signerDraft;

      this.signerDraft.find((element) => {
        if (element.rut == this.signer.rut) {
          this.ownUser = true;
          // seteamos un flag de que el usuario logeado existe como firmante
          // lo usaremos luego para validar con el checkbox si este existe eliminalo del array
          // que proviene de draft
          this.flagUserMainFromDraft = true;
        }
      });
    }
    //TODO: Evaluamos si tenemos un auto check signer para bloquear el check
    // si existe un ownUser desde draft ejecutamos el metodo de añadido
    // addOwnSignerFromCheck() con this.ownUser en true;
    // y eliminamos el ownUser del arreglo de firmantes que viene de draft
    this.$store.dispatch("DigitalSignature/cleanInitialSigner");
    this.$store.dispatch("DigitalSignature/cleanSignerFromCheck");
    this.$store.dispatch("DigitalSignature/cleanSigner");
  },
  computed: {
    ...mapGetters("Login", {
      signer: "signer",
    }),
    ...mapGetters("DigitalSignature", {
      signerDraft: "signerDraft",
      signerFromCheck: "signerFromCheck",
      initialSignerGetter: "initialSigner",
      signers: "signers",
    }),
  },
  methods: {
    validateEmptyFieldsName(field) {
      if (field.length > 0) {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorName",
          true
        );
      } else {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorName",
          false
        );
      }
    },
    validateEmptyFieldsEmail(field) {
      if (field.length > 0) {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorEmail",
          true
        );
      } else {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorEmail",
          false
        );
      }
    },
    validateRutNewSigner(signer) {
      if (signer.rut.length > 0) {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorRut",
          true
        );
      } else {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorRut",
          false
        );
      }
      signer.rut = signer.rut.replace(/\./g, "");
      signer.rut = signer.rut.trim();

      if (signer.rut.length >= 8) {
        let rutFormattedNewSigner = formatRut.format(signer.rut);
        let rutValidNewSigner = formatRut.validaRut(
          rutFormattedNewSigner.trim()
        );
        signer.rut = rutFormattedNewSigner;
        this.validatorRutNewSigner = rutValidNewSigner;
        if (!this.validatorRutNewSigner) {
          this.errorRutFlagSigner = true;
          this.errorRut = "rut invalido";
        } else {
          this.errorRutFlagSigner = false;
          this.errorRut = "";
        }
      }
    },
    validateEmailInnitialSigner(email) {
      this.email = email.trim();
    },
    validateEmailSigner(signer) {
      signer.email = signer.email.trim();
    },
    validateRutInnitialSigner(rut) {
      if (rut.length > 0) {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorRut",
          true
        );
      } else {
        this.$store.dispatch(
          "DigitalSignature/missingSingerValidatorRut",
          false
        );
      }
      // let e = event || window.event;
      // let key = e.keyCode || e.which;

      // if (key == 110 || key == 190 || key == 188) {
      //   event.preventDefault();
      // } else {
      this.rut = rut.replace(/\./g, "");
      this.rut = rut.trim();
      if (rut.length >= 8) {
        let rutFormatted = formatRut.format(rut);
        let rutValid = formatRut.validaRut(rutFormatted.trim());
        this.rut = rutFormatted;
        this.validatorRutInnitalSigner = rutValid;
        this.rutInnitialValue = rutFormatted;
        if (!this.validatorRutInnitalSigner) {
          this.errorRutFlag = true;
          this.errorRut = "rut invalido";
        } else {
          this.errorRutFlag = false;
          this.errorRut = "";
        }
      }
      // }
    },
    deleteInitialtSigner() {
      this.alertFieldEmptyInitial = false;

      this.initialSigner = null;
      this.name = "";
      this.email = "";
      this.rut = "";

      // this.signers.shift();
      if (this.signersForFront.length > 0) {
        this.validateFirstSigner = false;
      } else {
        this.validateFirstSigner = true;
      }
      this.firstSigner = true;
      this.$store.dispatch("DigitalSignature/cleanInitialSigner");
    },
    deletetSigner(signer) {
      this.alertFieldEmpty = false;
      let newTotalSigners = this.signersForFront.filter((el) => {
        return el.rut !== signer.rut;
      });
      this.signersForFront = newTotalSigners;
      let newSignerForStore = this.signersForFront.filter((el) => {
        return el.add == false;
      });

      this.$store.dispatch(
        "DigitalSignature/newTotalSigners",
        newSignerForStore
      );
    },
    addSigner(signer) {
      let validEmail = this.reg.test(signer.email);
      if (signer.rut == "" || signer.name == "" || signer.email == "") {
        this.alertFieldEmpty = true;
        this.errorEmail = "";
        this.errorRut = "";
      } else if (this.validatorRutNewSigner == false) {
        this.alertFieldEmpty = false;
        this.alertFieldEmptyInitial = false;
        this.errorRutFlagSigner = true;
        this.errorRut = "rut invalido";
        if (validEmail == false) {
          this.errorEmailFlagSinger = true;
          this.errorEmail = "Correo inválido";
        } else {
          this.errorEmail = "";
          this.errorEmailFlagSinger = false;
        }
      } else if (validEmail == false) {
        this.alertFieldEmpty = false;

        this.errorEmailFlagSinger = true;
        this.errorEmail = "Correo inválido";
        this.errorRutFlagSigner = false;
        this.errorRut = "";
      } else if (validEmail == true) {
        this.duplicateEmailValidationSigner(signer);
      } else {
        this.errorEmailFlagSinger = false;
        this.errorEmail = "";
        this.errorRutFlagSigner = false;
        this.errorRut = "";
        signer.add = false;
        this.alertFieldEmpty = false;

        this.signersForFront.push({
          name: "",
          email: "",
          rut: "",
          add: true,
        });

        let signerArray = {
          name: signer.name,
          email: signer.email,
          rut: signer.rut,
        };

        this.$store.dispatch("DigitalSignature/cleanNewSignerValidator", false);
        this.$store.dispatch("DigitalSignature/setSigner", signerArray);
      }
    },
    duplicateEmailValidationSigner(signer) {
      this.validationFoundEmail = false;
      this.validationFoundRut = false;
      for (let index = 0; index < this.signers.length; index++) {
        let foundEmail = this.signers[index].email.includes(signer.email);
        let foundRut = this.signers[index].rut.includes(signer.rut);

        if (foundEmail) {
          this.validationFoundEmail = true;
        }
        if (foundRut) {
          this.validationFoundRut = true;
        }
      }
      if (this.signerDraft != null) {
        this.validationFoundEmailDraft = false;
        this.validationFoundRutDraft = false;
        for (let index = 0; index < this.signerDraft.length; index++) {
          let foundEmailDraft = this.signerDraft[index].email.includes(
            signer.email
          );
          let foundRutDraft = this.signerDraft[index].rut.includes(signer.rut);
          if (foundEmailDraft) {
            this.validationFoundEmailDraft = true;
          }

          if (foundRutDraft) {
            this.validationFoundRutDraft = true;
          }
        }
      }

      if (this.validationFoundEmailDraft) {
        this.errorEmailFlagSinger = true;
        this.errorEmail =
          "Inválido, los correos electrónicos no deben repetirse";
      }
      if (this.validationFoundEmail) {
        this.errorEmailFlagSinger = true;
        this.errorEmail =
          "Inválido, los correos electrónicos no deben repetirse";
      }
      if (this.validationFoundRutDraft) {
        this.errorRutFlagSigner = true;
        this.errorRut = "Inválido, los RUT no deben repetirse";
      }
      if (this.validationFoundRut) {
        this.errorRutFlagSigner = true;
        this.errorRut = "Inválido, los RUT no deben repetirse";
      }

      if (this.initialSignerGetter != null && signer.email != null) {
        if (this.initialSignerGetter.email == signer.email) {
          this.errorEmailFlagSinger = true;
          this.errorEmail =
            "Inválido, los correos electrónicos no deben repetirse";
        }

        if (this.initialSignerGetter.rut == signer.rut) {
          this.errorRutFlagSigner = true;
          this.errorRut = "Inválido, los RUT no deben repetirse";
        } else if (this.signerFromCheck != null && signer.email != null) {
          if (this.signerFromCheck.email == signer.email) {
            this.errorEmailFlagSinger = true;
            this.errorEmail =
              "Inválido, los correos electrónicos no deben repetirse";
          }
          if (this.signerFromCheck.email != signer.email) {
            this.errorEmailFlagSinger = false;
            this.errorEmail = "";
          }
          if (this.signerFromCheck.rut == signer.rut) {
            this.errorRutFlagSigner = true;
            this.errorRut = "Inválido, los RUT no deben repetirse";
          } else if (
            this.signerFromCheck.email != signer.email &&
            this.signerFromCheck.rut != signer.rut &&
            this.initialSignerGetter.email != signer.email &&
            this.initialSignerGetter.rut != signer.rut &&
            this.validationFoundRut == false &&
            this.validationFoundEmail == false &&
            this.validationFoundRutDraft == false &&
            this.validationFoundEmailDraft == false
          ) {
            this.errorEmailFlagSinger = false;
            this.errorEmail = "";
            this.errorRutFlagSigner = false;
            this.errorRut = "";
            signer.add = false;
            this.alertFieldEmpty = false;

            this.signersForFront.push({
              name: "",
              email: "",
              rut: "",
              add: true,
            });

            let signerArray = {
              name: signer.name,
              email: signer.email,
              rut: signer.rut,
            };

            this.$store.dispatch(
              "DigitalSignature/cleanNewSignerValidator",
              false
            );
            this.$store.dispatch("DigitalSignature/setSigner", signerArray);
          }
        } else if (this.signerFromCheck != null) {
          if (
            this.signerFromCheck.email != signer.email &&
            this.signerFromCheck.rut != signer.rut &&
            this.initialSignerGetter.email != signer.email &&
            this.initialSignerGetter.rut != signer.rut &&
            this.validationFoundRut == false &&
            this.validationFoundEmail == false &&
            this.validationFoundRutDraft == false &&
            this.validationFoundEmailDraft == false
          ) {
            this.errorEmailFlagSinger = false;
            this.errorEmail = "";
            this.errorRutFlagSigner = false;
            this.errorRut = "";
            signer.add = false;
            this.alertFieldEmpty = false;

            this.signersForFront.push({
              name: "",
              email: "",
              rut: "",
              add: true,
            });

            let signerArray = {
              name: signer.name,
              email: signer.email,
              rut: signer.rut,
            };

            this.$store.dispatch(
              "DigitalSignature/cleanNewSignerValidator",
              false
            );
            this.$store.dispatch("DigitalSignature/setSigner", signerArray);
          }
        } else if (this.signerFromCheck == null) {
          if (
            this.initialSignerGetter.email != signer.email &&
            this.initialSignerGetter.rut != signer.rut &&
            this.validationFoundRut == false &&
            this.validationFoundEmail == false &&
            this.validationFoundRutDraft == false &&
            this.validationFoundEmailDraft == false
          ) {
            this.errorEmailFlagSinger = false;
            this.errorEmail = "";
            this.errorRutFlagSigner = false;
            this.errorRut = "";
            signer.add = false;
            this.alertFieldEmpty = false;

            this.signersForFront.push({
              name: "",
              email: "",
              rut: "",
              add: true,
            });

            let signerArray = {
              name: signer.name,
              email: signer.email,
              rut: signer.rut,
            };

            this.$store.dispatch(
              "DigitalSignature/cleanNewSignerValidator",
              false
            );
            this.$store.dispatch("DigitalSignature/setSigner", signerArray);
          }
        }
      }
    },
    addSignerInitial() {
      let validEmail = this.reg.test(this.email);

      if (this.rutInnitialValue == "" || this.name == "" || this.email == "") {
        this.alertFieldEmptyInitial = true;
        this.errorEmail = "";
        this.errorRut = "";
      } else if (validEmail == false) {
        this.emailValidation = true;
        this.errorEmail = "Correo inválido";
        this.errorRutFlag = false;
        this.errorRut = "";
      } else if (validEmail == true) {
        this.duplicateValidatorEmailRutInnitial();
      } else {
        this.emailValidation = false;
        this.errorEmail = "";
        this.errorRutFlag = false;
        this.errorRut = "";
        this.alertFieldEmptyInitial = false;
        this.firstSigner = false;
        this.initialSigner = {
          id: 1,
          name: this.name,
          email: this.email,
          rut: this.rutInnitialValue,
          add: true,
        };
        this.$store.dispatch("DigitalSignature/cleanNewSignerValidator", false);
        this.$store.dispatch(
          "DigitalSignature/setInitialSigner",
          this.initialSigner
        );
        this.signersForFront.push({
          name: "",
          email: "",
          rut: "",
          add: true,
        });
      }
    },
    duplicateValidatorEmailRutInnitial() {
      if (this.signerDraft != null) {
        for (let index = 0; index < this.signerDraft.length; index++) {
          let foundEmail = this.signerDraft[index].email.includes(this.email);
          let foundRut = this.signerDraft[index].rut.includes(
            this.rutInnitialValue
          );
          if (foundEmail) {
            this.validationFoundEmailDraft++;
          }
          if (this.validationFoundEmailDraft == 0) {
            this.validationFoundEmailDraft = false;
            this.errorEmail = "";
            this.errorRutFlag = false;
            this.validationDuplicateInnitialEmail = true;
          }
          if (foundRut) {
            this.validationFoundRutDraft++;
          }
          if (this.validationFoundRutDraft == 0) {
            this.validationFoundRutDraft = false;
            this.errorRut = "";
            this.errorRutFlag = false;
            this.validationDuplicateInnitialRut = true;
          }
        }
      }
      if (this.validationFoundEmailDraft) {
        this.validationFoundEmailDraft = 0;
        this.emailValidation = true;
        this.errorEmail =
          "Inválido, los correos electrónicos no deben repetirse";
      }
      if (this.validationFoundRutDraft) {
        this.validationFoundRutDraft = 0;
        this.errorRutFlag = true;
        this.errorRut = "Inválido, los RUT no deben repetirse";
      } else if (this.validatorRutInnitalSigner == false) {
        this.alertFieldEmptyInitial = false;
        this.errorRutFlag = true;
        this.errorRut = "rut invalidos";
      } else if (this.signerFromCheck != null) {
        if (
          this.signerFromCheck.rut == this.rutInnitialValue &&
          this.signerFromCheck.email == this.email
        ) {
          this.errorRutFlag = true;
          this.errorRut = "Inválido, los RUT no deben repetirse";
          this.emailValidation = true;
          this.errorEmail =
            "Inválido, los correos electrónicos no deben repetirse";
        } else if (
          this.signerFromCheck.rut == this.rutInnitialValue &&
          this.signerFromCheck.email != this.email
        ) {
          this.errorRutFlag = true;
          this.errorRut = "Inválido, los RUT no deben repetirse";
          this.emailValidation = false;
          this.errorEmail = "";
        } else if (
          this.signerFromCheck.email == this.email &&
          this.signerFromCheck.rut != this.rutInnitialValue
        ) {
          this.emailValidation = true;
          this.errorEmail =
            "Inválido, los correos electrónicos no deben repetirse";
          this.errorRutFlag = false;
          this.errorRut = "";
        } else {
          this.emailValidation = false;
          this.errorEmail = "";
          this.errorRutFlag = false;
          this.errorRut = "";
          this.alertFieldEmptyInitial = false;
          this.firstSigner = false;
          this.initialSigner = {
            id: 1,
            name: this.name,
            email: this.email,
            rut: this.rutInnitialValue,
            add: true,
          };
          this.$store.dispatch(
            "DigitalSignature/cleanNewSignerValidator",
            false
          );
          this.$store.dispatch(
            "DigitalSignature/setInitialSigner",
            this.initialSigner
          );
          this.signersForFront.push({
            name: "",
            email: "",
            rut: "",
            add: true,
          });
        }
      } else {
        this.emailValidation = false;
        this.errorEmail = "";
        this.errorRutFlag = false;
        this.errorRut = "";
        this.alertFieldEmptyInitial = false;
        this.firstSigner = false;
        this.initialSigner = {
          id: 1,
          name: this.name,
          email: this.email,
          rut: this.rutInnitialValue,
          add: true,
        };
        this.$store.dispatch("DigitalSignature/cleanNewSignerValidator", false);
        this.$store.dispatch(
          "DigitalSignature/setInitialSigner",
          this.initialSigner
        );
        this.signersForFront.push({
          name: "",
          email: "",
          rut: "",
          add: true,
        });
      }
    },
    addOwnSignerFromCheck() {
      if (this.ownUser == true) {
        this.nombreCheck = this.signer.name;
        this.correoCheck = this.signer.email;
        this.rutCheck = this.signer.rut;

        if (this.signerDraft != null) {
          for (let index = 0; index < this.signerDraft.length; index++) {
            let foundEmail = this.signerDraft[index].email.includes(
              this.correoCheck
            );
            let foundRut = this.signerDraft[index].rut.includes(this.rutCheck);
            if (foundEmail) {
              this.validationFoundEmailDraft++;
            }
            if (this.validationFoundEmailDraft == 0) {
              this.validationFoundEmailDraft = false;
              this.validationSignerForCheck = false;
            }
            if (foundRut) {
              this.validationFoundRutDraft++;
            }
            if (this.validationFoundRutDraft == 0) {
              this.validationFoundRutDraft = false;
              this.errorRut = "";
              this.errorRutFlag = false;
              this.validationDuplicateInnitialRut = true;
            }
          }
        }
        for (let index = 0; index < this.signers.length; index++) {
          let foundEmail = this.signers[index].email.includes(this.correoCheck);
          let foundRut = this.signers[index].rut.includes(this.rutCheck);
          if (foundEmail) {
            this.validationFoundEmail++;
          }
          if (this.validationFoundEmail == 0) {
            this.validationFoundEmail = false;
            this.validationSignerForCheck = false;
          }
          if (foundRut) {
            this.validationFoundRut++;
          }
          if (this.validationFoundRut == 0) {
            this.validationFoundEmail = false;
            this.validationSignerForCheck = false;
          }
        }
        if (this.validationFoundEmailDraft && this.validationFoundRutDraft) {
          this.validationSignerForCheck = true;
          this.errorFromCheckDuplicate =
            "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo y mismo RUT)";
        } else if (this.validationFoundEmail && this.validationFoundRut) {
          this.validationSignerForCheck = true;
          this.errorFromCheckDuplicate =
            "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo y mismo RUT)";
        } else if (this.validationFoundEmailDraft) {
          this.validationFoundEmailDraft = 0;
          this.validationSignerForCheck = true;
          this.errorFromCheckDuplicate =
            "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo)";
        } else if (this.validationFoundEmail) {
          this.validationFoundEmail = 0;
          this.validationSignerForCheck = true;
          this.errorFromCheckDuplicate =
            "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo)";
        } else if (this.validationFoundRutDraft) {
          this.validationFoundRutDraft = 0;
          this.validationSignerForCheck = true;
          this.errorFromCheckDuplicate =
            "(No se puede agregar debido a que unos de los firmantes tiene el mismo RUT)";
        } else if (this.validationFoundRut) {
          this.validationFoundRut = 0;
          this.validationSignerForCheck = true;
          this.errorFromCheckDuplicate =
            "(No se puede agregar debido a que unos de los firmantes tiene el mismo RUT)";
        } else if (this.correoCheck != null && this.email != null) {
          if (this.correoCheck == this.email && this.rutCheck == this.rut) {
            this.validationSignerForCheck = true;
            this.errorFromCheckDuplicate =
              "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo y mismo RUT)";
          } else if (this.correoCheck == this.email) {
            this.validationSignerForCheck = true;
            this.errorFromCheckDuplicate =
              "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo )";
          } else if (this.rutCheck == this.rut) {
            this.validationSignerForCheck = true;
            this.errorFromCheckDuplicate =
              "(No se puede agregar debido a que unos de los firmantes tiene el mismo RUT )";
          } else if (
            this.rutCheck != this.rut &&
            this.correoCheck != this.email
          ) {
            this.initialSignerFromCheck.push({
              id: 1,
              name: this.signer.name,
              email: this.signer.email,
              rut: this.signer.rut,
            });

            this.$store.dispatch(
              "DigitalSignature/setSignerFromCheck",
              this.signer
            );
          }
        } else if (this.correoCheck != null && this.signers.email != null) {
          if (
            this.correoCheck == this.signers.email &&
            this.rutCheck == this.signers.rut
          ) {
            this.validationSignerForCheck = true;
            this.errorFromCheckDuplicate =
              "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo y mismo RUT)";
          }
          if (this.correoCheck == this.signers.email) {
            this.validationSignerForCheck = true;
            this.errorFromCheckDuplicate =
              "(No se puede agregar debido a que unos de los firmantes tiene el mismo correo )";
          }
          if (this.rutCheck == this.signers.rut) {
            this.validationSignerForCheck = true;
            this.errorFromCheckDuplicate =
              "(No se puede agregar debido a que unos de los firmantes tiene el mismo RUT )";
          } else if (
            this.correoCheck != this.signers.email &&
            this.rutCheck != this.signers.rut
          ) {
            this.initialSignerFromCheck.push({
              id: 1,
              name: this.signer.name,
              email: this.signer.email,
              rut: this.signer.rut,
            });

            this.$store.dispatch(
              "DigitalSignature/setSignerFromCheck",
              this.signer
            );
          }
        } else {
          this.initialSignerFromCheck.push({
            id: 1,
            name: this.signer.name,
            email: this.signer.email,
            rut: this.signer.rut,
          });

          this.$store.dispatch(
            "DigitalSignature/setSignerFromCheck",
            this.signer
          );
        }
      }
      // si ponemos el check en falso as si no queremos al usuario logeado como firmante
      else if (this.ownUser == false) {
        // para el checkbox si encontramos el flag del usuario logeado como firmante lo eliminamos de aqui
        if (this.flagUserMainFromDraft == true) {
          this.signersFinalDraft = this.signerDraft.filter(
            (el) => el.rut !== this.signer.rut
          ); // filtramos

          this.flagUserMainFromDraft = false;

          let payload = this.signersFinalDraft;
          this.$store.dispatch("DigitalSignature/addSignerFromDraft", payload);
        } else {
          // sino sigue el flujo normal de agrearlo y quitarlo
          this.validationSignerForCheck = false;
          this.$store.dispatch("DigitalSignature/cleanSignerFromCheck");
          this.nombreCheck = "";
          this.correoCheck = "";
          this.rutCheck = " ";
          this.initialSignerFromCheck.splice(0, 1);
        }

        // this.firstSigner = false;
      }
    },
  },
};
</script>

<style lang="scss">
.disable__input {
  background: #f8f8f8 !important;
}
// add signer
.container__add__signer {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  gap: 0.7rem;
}
.text_danger {
  color: red;
  font-size: 12px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.field__rut {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.button__check {
  display: flex;
  height: fit-content;
  margin-top: 0.5rem;
  border: none !important;
  background: white;

  > svg {
    stroke: var(--svg_confirm_stroke);
    fill: var(--svg_confirm_fill);
  }
}
.container_valid_signer {
  display: flex;
}
@media screen and (max-width: 500px) and (min-width: 200px) {
  .container__add__signer {
    flex-wrap: wrap;
  }

  .button__check,
  .cancel__btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .container_valid_signer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 0.2rem;
    margin-right: 0.2rem;
  }
}
.error_check_signer {
  color: red !important;
  font-family: Conv_HelveticaLTStd-Light;
  font-style: normal;
  font-size: 13px;
  line-height: 12px;
  color: #464646;
}
//end add signer
</style>
