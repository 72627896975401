import * as types from "./mutations-types";
import reportTenantService from "../services/reportTenantService";
export default {
  getDataCategories({ commit }, data) {
    commit(types.FETCH_REPORT_USE_TENANT_CATEGORY_DATA, data);
  },
  setDataCategories({ commit }, data) {
    commit(types.SET_REPORTS_USE_TENANTS, data);
  },

  generateCSV({ commit }, { admin_company_id, payload }) {
    return new Promise((resolve, reject) => {
      reportTenantService
        .generateDocument(admin_company_id, payload)
        .then((data) => {
          commit(types.FETCH_CSV_DATA, data);
          commit(types.FETCH_CSV_SUCCESS);
          resolve(true);
        })
        .catch((error) => {
          commit(types.CLEAN_CSV_DATA);
          commit(types.FETCH__CSV_FAILURE);
          reject(error);
          // commit(types.FETCH_DIGITAL_SIGNATURE_FAILURE, { error });
        });
    });
  },

  cleanCSV({ commit }) {
    commit(types.CLEAN_CSV_DATA);
  },
};
