<template>
  <div class="horizontal-scroll">
    <ModalConfirmDelete
      ref="modal__delete__employee"
      size="ls"
      :user_id="currentUserId"
      :admin_company_id_selected="current_admin_company_id"
    />

    <div class="body__digital_signature">
      <div class="center_loader_employee" v-if="getLoaderTableEmployee">
        <loaderGeneric />
        Cargando resultados...
      </div>
      <div
        class="center_loader_employee"
        v-else-if="flagActiveResultEmployeeMembers"
      >
        <NoResultsImage />
        <div class="text_loader_employee" v-if="activeInputSearchMember">
          No hay resultados con esa búsqueda
        </div>
        <div class="text_loader_employee" v-else>
          No existen datos de Miembros, Agrega a un miembro de tu equipo para
          visualizar datos
        </div>
      </div>
      <table class="table__digital__signature" v-else>
        <thead class="thead_employee">
          <tr>
            <td class="header_table_actions_table_employees"></td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedName()"
            >
              Nombre
              <span v-bind:class="sortedFlagName ? 'sortDateDown' : 'sortDate'">
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedCompany()"
            >
              Empresa
              <span
                v-bind:class="sortedFlagCompany ? 'sortDateDown' : 'sortDate'"
              >
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedEmail()"
            >
              Email
              <span
                v-bind:class="sortedFlagEmail ? 'sortDateDown' : 'sortDate'"
              >
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedRut()"
            >
              Rut
              <span v-bind:class="sortedFlagRut ? 'sortDateDown' : 'sortDate'">
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedDate()"
            >
              Fecha de ingreso
              <span v-bind:class="sortedFlagDate ? 'sortDateDown' : 'sortDate'">
                <IconBack
              /></span>
            </td>
            <td
              class="header_table_actions_table_employees"
              @click="sortedContract()"
            >
              Contrato
              <span
                v-bind:class="sortedFlagContract ? 'sortDateDown' : 'sortDate'"
              >
                <IconBack
              /></span>
            </td>
            <td class="header_table_actions_table_employees"></td>
            <td class="header_table_actions_table_employees"></td>
          </tr>
        </thead>

        <tbody id="item_icons" class="tbody_signature body__table_employees">
          <tr v-for="(data, index) in list__employee_members" :key="index">
            <td class="icon__flag_correct"></td>
            <td class="icon_options_member">
              <div class="icon_options_employee_edit icon_mobile_employee">
                <ViewEmployeeIcon @click="viewEmployee(data)" />
                <div
                  class="icon_options_employee_delete "
                  v-if="group == 'Company-Admin' || group == 'Admin'"
                >
                  <DeleteIcon
                    @click="DeleteEmployee(data.id, data.company.id)"
                  />
                </div>
                <div
                  class="icon_options_employee_delete disabled_employee_delete"
                  v-else
                >
                  <DeleteIcon />
                </div>
              </div>
            </td>
            <td @click="viewEmployee(data)">
              {{ data.first_name }}
              {{ data.surname }}
            </td>
            <td @click="viewEmployee(data)">
              {{ data.company.alias }}
            </td>
            <td @click="viewEmployee(data)">{{ data.user.email }}</td>
            <td>
              {{ data.nin }}
            </td>
            <td @click="viewEmployee(data)">
              {{
                data.company_ingress_date != null
                  ? data.company_ingress_date
                  : "Sin Registro"
              }}
            </td>
            <td @click="viewEmployee(data)">
              {{
                data.contract_type_display != null
                  ? data.contract_type_display
                  : "Sin Registro"
              }}
            </td>

            <td class="icon_options_employee desktop_buttons_verified">
              <div
                class="icon_options_employee_delete"
                v-if="group == 'Company-Admin' || group == 'Admin'"
              >
                <DeleteIcon @click="DeleteEmployee(data.id, data.company.id)" />
              </div>
              <div
                class="icon_options_employee_delete disabled_employee_delete"
                v-else
              >
                <DeleteIcon />
              </div>
              <div class="icon_options_employee_edit">
                <ViewEmployeeIcon @click="viewEmployee(data)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer_digital_signature footer_employee">
      <div class="per_page">
        Visualizar por página:

        <select v-model="selected_quantity" @change="selectQuantity($event)">
          <option
            v-for="(option, index) in amount_of_rows"
            :key="index"
            :value="option"
          >
            {{ index }}
          </option>
        </select>
      </div>
      <div class="btn_pagination_digital_signature">
        <firstPage
          :class="page == 1 ? 'blockPagination' : ''"
          @click="setInitialPage()"
        />
        <leftArrow
          class="leftArrow"
          :class="page == 1 ? 'blockPagination' : ''"
          @click="prevPagination()"
        />
        <rightArrow
          :class="page === totalPagesMembers ? 'blockPagination' : 'itemClick'"
          @click="nextPagination()"
        />
        <lastPage v-if="nextPageMembers != null" @click="setLastPage()" />
        <lastPage v-else class="blockPagination" />
      </div>
      <div class="text_view_digital_signature">
        Visualizando {{ page }} de {{ totalPagesMembers }} páginas
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconBack from "../../../../assets/iconos_container/back.svg";
import DeleteIcon from "../../assets/Trashcan.svg";
import ViewEmployeeIcon from "../../assets/zoom-in.svg";
// import ThreeDots from "../../assets/dot-menu-ellipse-small.svg";
import ModalConfirmDelete from "../modals/ModalConfirmDelete.vue";
import loaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
// import DropdownThreeDots from "../dropDown/DropDownThreeDots.vue";
import NoResultsImage from "../../assets/no_results_table.svg";
// pagination
import firstPage from "@/assets/iconos_container/firstpage.svg";
import leftArrow from "@/assets/iconos_container/left.svg";
import lastPage from "@/assets/iconos_container/lastpage.svg";
import rightArrow from "@/assets/iconos_container/right.svg";

export default {
  components: {
    IconBack,
    DeleteIcon,
    ModalConfirmDelete,
    // ThreeDots,
    loaderGeneric,
    // DropdownThreeDots,
    firstPage,
    leftArrow,
    lastPage,
    rightArrow,
    NoResultsImage,
    ViewEmployeeIcon,
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__employee: "list__employee",
      getLoaderTableEmployee: "getLoaderTableEmployee",
      list__employee_members: "list__employee_members",
      totalPagesMembers: "totalPagesMembers",
      nextPageMembers: "nextPageMembers",
      prevPageMembers: "prevPageMembers",
      total_data_view_members: "total_data_view_members",
      flagActiveResultEmployee: "flagActiveResultEmployee",
      flagActiveResultEmployeeMembers: "flagActiveResultEmployeeMembers",
      activeInputSearchMember: "activeInputSearchMember",
      activeTableMembers: "activeTableMembers",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
      group: "group",
      user_profile: "user_profile"
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  data() {
    return {
      isActiveMember: "",
      currentUserId: null,
      current_admin_company_id: null,
      listTotalEmployeeMembers: [],

      sortedFlagName: false,
      sortedFlagCompany: false,
      sortedFlagEmail: false,
      sortedFlagRut: false,
      sortedFlagDate: false,
      sortedFlagContract: false,

      activeButtonEmployee: false,
      activeVerificationEmployee: false,
      loader: false,
      currentSelectedVerified: null,
      currentSelectedState: null,
      selected_quantity: "10",
      amount_of_rows: {
        10: 10,
        50: 50,
        100: 100,
      },
      page: 1,
      limit: "10",
      offset: null,
      dataSearch: "",
      loaderSkeleton: false,
      DefaultInfo: true,
    };
  },
  created() {
    this.isActiveMember = this.activeTableMembers;
    this.sortedFlag = false;
    this.listTotalCompanies = this.list__companies;
    this.listTotalEmployeeMembers = this.list__employee_members;

    this.offset = null;
    if (this.total_data_view_members <= 10) {
      this.selected_quantity = "10";
    } else {
      this.selected_quantity = this.total_data_view_members;
    }
    if (this.prevPage != null) {
      this.setInitialPage();
    }
  },
  methods: {
    viewEmployee(employee) {
      let user_id = employee.id;
      let company_id = employee.company.id;
      let isActiveMember = this.isActiveMember;
      let payload = {
        company_id: company_id,
        user_id: user_id,
        isActiveMember: isActiveMember ? "Miembro" : "Usuario",
      };

      this.$store
        .dispatch("EmployeesV2/get__single__user", payload)
        .then(() => {
          this.$store
            .dispatch("EmployeesV2/setCurrentViewEmployee", employee)
            .then(() => {
              this.$router.push({
                name: "ViewEmployee",
                params: { name: employee.first_name },
              });
            });
        });
    },
    sortedName() {
      if (!this.sortedFlagName) {
        this.sortedFlagName = true;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (a.first_name > b.first_name) {
            return 1;
          }
          if (a.first_name < b.first_name) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagName = false;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (b.first_name > a.first_name) {
            return 1;
          }
          if (b.first_name < a.first_name) {
            return -1;
          }
          return 0;
        });
      }
    },
    sortedCompany() {
      if (!this.sortedFlagCompany) {
        this.sortedFlagCompany = true;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (a.company.alias > b.company.alias) {
            return 1;
          }
          if (a.company.alias < b.company.alias) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagCompany = false;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (b.company.alias > a.company.alias) {
            return 1;
          }
          if (b.company.alias < a.company.alias) {
            return -1;
          }
          return 0;
        });
      }
    },
    sortedEmail() {
      if (!this.sortedFlagEmail) {
        this.sortedFlagEmail = true;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (a.user.email > b.user.email) {
            return 1;
          }
          if (a.user.email < b.user.email) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagEmail = false;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (b.user.email > a.user.email) {
            return 1;
          }
          if (b.user.email < a.user.email) {
            return -1;
          }
          return 0;
        });
      }
    },
    sortedRut() {
      if (!this.sortedFlagRut) {
        this.sortedFlagRut = true;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (a.nin > b.nin) {
            return 1;
          }
          if (a.nin < b.nin) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagRut = false;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (b.nin > a.nin) {
            return 1;
          }
          if (b.nin < a.nin) {
            return -1;
          }
          return 0;
        });
      }
    },
    sortedDate() {
      if (!this.sortedFlagDate) {
        this.sortedFlagDate = true;

        return this.listTotalEmployeeMembers.sort(
          (a, b) =>
            new Date(a.company_ingress_date) - new Date(b.company_ingress_date)
        );
      } else {
        this.sortedFlagDate = false;
        return this.listTotalEmployeeMembers.sort(
          (a, b) =>
            new Date(b.company_ingress_date) - new Date(a.company_ingress_date)
        );
      }
    },
    sortedContract() {
      if (!this.sortedFlagContract) {
        this.sortedFlagContract = true;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (a.contract_type_display > b.contract_type_display) {
            return 1;
          }
          if (a.contract_type_display < b.contract_type_display) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedFlagContract = false;
        this.listTotalEmployeeMembers.sort((a, b) => {
          if (b.contract_type_display > a.contract_type_display) {
            return 1;
          }
          if (b.contract_type_display < a.contract_type_display) {
            return -1;
          }
          return 0;
        });
      }
    },
    ChangeStateEmployee(id) {
      this.currentSelectedState = id;
      let payload = {
        list: this.listTotalEmployee,
        id: id,
      };
      let user = [];
      user.push(id);
      let id_company = this.inSwitch ? this.idInSwitch : this.company_id;

      let serviceUpdateStatus = {
        id_company: id_company,
        users: user,
      };
      this.$store.dispatch(
        "EmployeesV2/setStatusEmployees",
        serviceUpdateStatus
      );
      this.$store.dispatch("EmployeesV2/setIsActive", payload);
      setTimeout(() => {
        this.currentSelectedState = false;
      }, 1000);
    },

    ChangeVerificationEmployee(id_user, is_verified) {
      let id_company = this.inSwitch ? this.idInSwitch : this.company_id;

      let user = [];
      user.push(id_user);

      let payload = {
        id_company: id_company,
        list: this.listTotalEmployee,
        user_id: user,
      };
      if (is_verified == false) {
        this.currentSelectedVerified = id_user;
        this.$store.dispatch("EmployeesV2/setIsVeriefied", payload);
      }
      setTimeout(() => {
        this.currentSelectedVerified = null;
      }, 1000);
    },
    DeleteEmployee(id, id_company) {
      this.current_admin_company_id = id_company;
      this.currentUserId = id;
      let param = "modal__delete__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__delete__employee;
      element.open();
    },
    selectQuantity(evt) {
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      let limit = evt.target.value;
      let user = this.user_profile;
      let id = user.id;
      this.page = 1;
      this.limit = limit;
      this.selected_quantity = evt.target.value;
      // let offset = 0;

      let payload = {
        company_id: company_id,
        limit: limit,
        id: id,
        // offset: offset,
      };
      this.$store.dispatch(
        "EmployeesV2/get_list_employee_quantity_members",
        payload
      );
    },
    prevPagination() {
      let nextUrl = this.prevPageMembers;
      this.page = this.page - 1;

      this.$store.dispatch("EmployeesV2/nextPaginationMembers", {
        nextUrl,
      });
    },
    nextPagination() {
      let nextUrl = this.nextPageMembers;
      this.page = this.page + 1;
      this.$store.dispatch("EmployeesV2/nextPaginationMembers", {
        nextUrl,
      });
    },
    setLastPage() {
      let limit = this.limit;

      if (this.selected_quantity == 10) {
        if (this.offset == 0) {
          this.offset = 10 * this.totalPagesMembers;
        } else {
          this.offset = this.totalPagesMembers * 10 - 10;
        }
      }
      if (this.selected_quantity == 50) {
        if (this.offset == 0) {
          this.offset = 50 * this.totalPagesMembers;
        } else {
          this.offset = this.totalPagesMembers * 50 - 50;
        }
      }
      if (this.selected_quantity == 100) {
        if (this.offset == 0) {
          this.offset = 100 * this.totalPagesMembers;
        } else {
          this.offset = this.totalPagesMembers * 100 - 100;
        }
      }

      let offset = this.offset;
      let user = this.user_profile;
      let id = user.id;
      this.page = this.totalPagesMembers;
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payload = {
        company_id: company_id,
        limit,
        offset,
        id: id,
      };
      this.$store.dispatch(
        "EmployeesV2/get_list_employee_quantity_members",
        payload
      );
    },
    setInitialPage() {
      let user = this.user_profile;
      let id = user.id;
      let limit = this.limit;
      this.page = 1;
      let offset = 0;
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payload = {
        company_id: company_id,
        limit: limit,
        offset: offset,
        id: id,
      };
      this.$store.dispatch(
        "EmployeesV2/get_list_employee_quantity_members",
        payload
      );
    },
  },
  watch: {
    list__employee_members() {
      this.listTotalEmployeeMembers = [];
      this.listTotalEmployeeMembers = this.list__employee_members;
    },
    flagActiveResultEmployee() {
      if (this.flagActiveResultEmployee) {
        this.page = 0;
      } else {
        this.page = 1;
      }
    },
  },
};
</script>

<style lang="scss"></style>