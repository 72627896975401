<template>
  <div class="tags_container">
    <div class="" v-for="(tag, index) in tagsOfFilters" :key="index">
      <div class="" v-if="tag.status == true">
        <FilterTagsEmployee
          :index="index"
          :id="tag.id"
          :tag="tag.name"
          :status="tag.status"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FilterTagsEmployee from "./FilterTagsEmployee.vue";

export default {
  components: {
    FilterTagsEmployee,
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__companies: "list__companies",
      tagsOfFilters: "tagsOfFilters",
    }),
  },
};
</script>

<style></style>
