<template>
  <div class="container_module">
    <div class="" v-if="!loader_documents">
      <div class="container-slots container-base" v-if="datacheck">
        <div class="">
          <div>
            <ModalFilterDigitalSignature
              ref="modal__digital__filter"
              size="count"
            />
            <ModalConfirmRemoveDocument
              :name_modal="name_modal"
              :identifier_modal="identifier_modal"
              :id_document="id_document"
              :caseSearch="caseSearch"
              ref="modal__digital__remove"
              size="count"
            />
          </div>
          <div class="container-header">
            <div class="">Firma digital</div>
          </div>

          <div class="list__digital__signature">
            <div class="header__digital_signature">
              <div class="left-section">
                <input
                  type="text"
                  v-model="filterTagsInput"
                  placeholder="Buscar documentos"
                  v-on:keyup.enter="searchSignaturesTags()"
                />
                <div class="container-filter">
                  <div class="icon-filter" @click="show_modal()">
                    <filterIcon />
                    <div class="">Filtrar documentos</div>
                  </div>
                  <div class="list_tags" v-if="tagsFlag">
                    <div
                      class=""
                      v-for="(tag, index) in tagsForfrontState"
                      :key="index"
                    >
                      <div
                        class=""
                        v-if="tag.value == null || tag.value == false"
                      ></div>
                      <div class="item_tag" v-else>
                        {{ tag.name }}
                      </div>
                    </div>
                    <cancelBtn
                      class="btn-clean-filter"
                      @click="cleanFilter()"
                    />
                  </div>
                  <div class="" v-else></div>
                </div>
              </div>
              <div class="right-section">
                <div class="borradores" @click="fetchDraft()">Borradores</div>
                <div class="container-btn-digital-signature">
                  <div class="digital_signature_icon">
                    <DigitalSignatureIcon />
                  </div>
                  <div class="document-sign" @click="goToSing()">
                    Firmar documento
                  </div>
                </div>
              </div>
            </div>

            <div class="horizontal-scroll">
              <div class="body__digital_signature">
                <table
                  class="table__digital__signature"
                  v-if="this.notFoundData == true"
                >
                  <thead class="thead_signature">
                    <tr>
                      <td>Identificador</td>
                      <td>Nombre</td>
                      <td>Responsable</td>
                      <td @click="sortedDate()">
                        Inicio
                        <span
                          v-bind:class="
                            sortedFlag ? 'sortDate' : 'sortDateDown'
                          "
                        >
                          <IconBack
                        /></span>
                      </td>
                      <td>Final</td>
                      <td>N° Firmas</td>
                      <td>Estado</td>
                    </tr>
                  </thead>

                  <tbody class="tbody_signature">
                    <tr
                      v-for="(data, index) in digital_signature_data_from_fetch"
                      :key="index"
                    >
                      <td>
                        {{ data.tags[0] }}
                      </td>
                      <td>{{ data.name }}</td>
                      <td>{{ data.responsable }}</td>
                      <td>{{ data.startDate }}</td>
                      <td>{{ data.endDate }}</td>
                      <td>{{ data.signatures }}</td>
                      <td v-if="data.status === 1">
                        <div class="container_status">
                          <div class="green_ball"></div>
                        </div>
                      </td>
                      <td v-if="data.status === 3">
                        <div class="container_status">
                          <div class="green_ball"></div>
                        </div>
                      </td>
                      <td v-if="data.status === 2">
                        <div class="container_status">
                          <div class="blue_ball"></div>
                        </div>
                      </td>
                      <td class="action_btn_signature">
                        <viewIcon @click="selectedSignature(data)" />
                      </td>
                      <td
                        class="action_btn_signature"
                        :class="data.status == 3 ? 'disable__delete_main' : ''"
                      >
                        <cancelBtn
                          @click="show_modal_remove(data)"
                          :class="data.status == 3 ? 'disable__delete' : ''"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  class="message_no_data message_empty_data text-container"
                  v-else
                >
                  No hay documentos firmados en este momento
                </div>
              </div>
            </div>

            <div class="footer_digital_signature">
              <div class="per_page">
                Visualizar por página:

                <select
                  v-model="selected_quantity"
                  @change="selectQuantity($event)"
                >
                  <option
                    v-for="(option, index) in amount_of_rows"
                    :key="index"
                    :value="option"
                  >
                    {{ index }}
                  </option>
                </select>
              </div>
              <div class="btn_pagination_digital_signature">
                <firstPage
                  :class="page == 1 ? 'blockPagination' : ''"
                  @click="setInitialPage()"
                />
                <leftArrow
                  class="leftArrow"
                  :class="page == 1 ? 'blockPagination' : ''"
                  @click="prevPagination()"
                />
                <rightArrow
                  :class="page === totalPages ? 'blockPagination' : 'itemClick'"
                  @click="nextPagination()"
                />
                <lastPage v-if="nextPage != null" @click="setLastPage()" />
                <lastPage v-else class="blockPagination" />
              </div>
              <div class="text_view_digital_signature">
                Visualizando {{ page }} de {{ totalPages }} páginas
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-view v-else />
    </div>
    <div class="loader__signatures center-loader" v-else>
      <LoaderGeneric />
    </div>
  </div>
</template>

<script>
import filterIcon from "@/assets/iconos_container/filters.svg";
import firstPage from "@/assets/iconos_container/firstpage.svg";
import leftArrow from "@/assets/iconos_container/left.svg";
import lastPage from "@/assets/iconos_container/lastpage.svg";
import rightArrow from "@/assets/iconos_container/right.svg";
import cancelBtn from "@/assets/iconos_container/cancel.svg";
import viewIcon from "@/assets/iconos_container/view_icon.svg";
import IconBack from "@/assets/iconos_container/back.svg";
import DigitalSignatureIcon from "@/assets/iconos_container/firmadigitalslim_prestep.svg";
//loader
import LoaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
//modal
import ModalFilterDigitalSignature from "../components/modals/modalFilterDigitalSignatureV2.vue";
import ModalConfirmRemoveDocument from "../components/modals/modalConfirmRemoveDocumentV2.vue";
//vuex
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    filterIcon,
    firstPage,
    leftArrow,
    lastPage,
    rightArrow,
    cancelBtn,
    viewIcon,
    DigitalSignatureIcon,
    IconBack,
    ModalFilterDigitalSignature,
    ModalConfirmRemoveDocument,
    LoaderGeneric,
  },
  name: "DigitalSignature",

  created() {
    //flagData nos indica si pillamos registros en la tabla
    //notFoundData es flag para front para presentar "no hay data disponible"
    this.offset = null;
    this.notFoundData = this.flagData;
    if (this.total_data_view <= 10) {
      this.selected_quantity = "10";
    } else {
      this.selected_quantity = this.total_data_view;
    }

    this.digital_signature_data_from_fetch = this.digital_signature_data;
    this.sortedFlag = false;
    this.amountOfPages();

    this.tagsFlag = this.tags;
    this.loaderglobal = this.globalLoaderFilterModal;
    // this.$store.dispatch("DigitalSignature/cleanDocumentName");

    // TODO: Revisar implicancia de dispatch a continuacion
    // this.$store.dispatch("DigitalSignature/cleanTracking");

    this.$store.dispatch("DigitalSignature/cleanSignerFromDraft");

    if (this.prevPage != null) {
      this.setInitialPage();
    }
  },
  data: () => {
    return {
      sortedFlag: false,
      tagsFlag: false,
      dataFetch: false,
      loader: false,
      loaderglobal: false,
      fetchCheck: false,
      identifier_modal: "",
      name_modal: "",
      id_document: "",
      selected_quantity: "10",
      amount_of_rows: {
        10: 10,
        20: 20,
        30: 30,
      },
      total: 10,
      totalPages: "",
      page: 1,
      blockNextPage: false,
      notFoundData: false,
      filterTagsInput: null,
      digital_signature_data_from_fetch: [],
      caseSearch: null,
      limit: "10",
      offset: null,
    };
  },
  computed: {
    ...mapState({
      datacheck: (state) => state.DigitalSignature.datacheck,
      searchAll: (state) => state.DigitalSignature.setSearchAll,
      searchPending: (state) => state.DigitalSignature.setSearchPending,
      searchComplete: (state) => state.DigitalSignature.setSearchComplete,
      tagsForfrontState: (state) => state.DigitalSignature.tagsForfront,
    }),
    ...mapGetters("DigitalSignature", {
      digital_signature_data: "digital_signature_data",
      flagData: "flagData",
      globalLoaderFilterModal: "globalLoader",
      total_data: "total_data",
      total_data_view: "total_data_view",
      nextPage: "nextPage",
      prevPage: "prevPage",
      tagsForfront: "tagsForfront",
      tags: "tags",
      loader_documents: "loader_documents",
      flagDelete: "flagDelete",
      datacheck: "datacheck",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  methods: {
    goToSing() {
      this.$router.push({ name: "NewDigitalSignature" });
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);
    },
    async searchSignaturesTags() {
      let filterFromTag = this.filterTagsInput; // correct: "abc%20xyz%20123"

      let filterOne = `tags__overlap=${filterFromTag}`;
      let filterTwo = `document_type__name__icontains=${filterFromTag}`;

      let filter1 = encodeURIComponent(filterOne);
      let filter2 = encodeURIComponent(filterTwo);

      let join_filters = `(${filter1}) | (${filter2})`;
      // let final_filter_encoded =

      this.page = 1;
      this.selected_quantity = "10";

      const encode = (str) =>
        encodeURIComponent(str).replace(/\(/g, "%28").replace(/\)/g, "%29");

      //TODO: encoder para caracteres especiales
      //  .replace(/-/g, "%2D")
      // .replace(/_/g, "%5F")
      // .replace(/!/g, "%21")
      // .replace(/~/g, "%7E")
      // .replace(/'/g, "%27")
      // .replace(/\./g, "%2E")
      // .replace(/\*/g, "%2A")

      let final_filter_encoded = encode(join_filters);

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let filters = final_filter_encoded;
      let sign_status__range;

      if (this.searchPending) {
        sign_status__range = "2,2";
      } else if (this.searchComplete) {
        sign_status__range = "3,3";
      } else if (this.searchAll) {
        sign_status__range = "2,3";
      }
      this.$store.dispatch("DigitalSignature/loadDocumentOn");

      await this.$store.dispatch("DigitalSignature/fetchTags", {
        admin_company_id,
        sign_status__range,
        filters,
      });

      let limitPerPage = this.limit;
      let totalDocuments = this.total_data;

      this.totalPages = Math.ceil(totalDocuments / limitPerPage);

      this.filterTagsInput = "";
    },
    fetchDraft() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);

      this.$router.push({ name: "DraftElements" });
    },
    amountOfPages() {
      if (this.total_data % this.total == 0) {
        this.totalPages = this.total_data / this.total;
      } else {
        this.totalPages = Math.floor(this.total_data / this.total) + 1;
      }
    },
    setInitialPage() {
      this.$store.dispatch("DigitalSignature/loadDocumentOn");

      let limit = this.limit;
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      this.page = 1;

      // this.page = this.totalPages;

      let sign_status__range;

      if (this.searchPending) {
        sign_status__range = "2,2";
      } else if (this.searchComplete) {
        sign_status__range = "3,3";
      } else if (this.searchAll) {
        sign_status__range = "2,3";
      }

      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
        limit,
      });
    },
    setLastPage() {
      this.$store.dispatch("DigitalSignature/loadDocumentOn");

      let limit = this.limit;

      if (this.offset == 0) {
        this.offset = 10 * this.totalPages;
      } else {
        this.offset = this.totalPages * 10 - 10;
      }

      let offset = this.offset;

      this.page = this.totalPages;

      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let sign_status__range;

      if (this.searchPending) {
        sign_status__range = "2,2";
      } else if (this.searchComplete) {
        sign_status__range = "3,3";
      } else if (this.searchAll) {
        sign_status__range = "2,3";
      }

      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
        limit,
        offset,
      });
    },
    nextPagination() {
      let nextUrl = this.nextPage;
      if (this.page == this.totalPages) {
        this.blockNextPage = true;
      } else {
        this.page = this.page + 1;
        this.$store.dispatch("DigitalSignature/nextPagination", {
          nextUrl,
        });
      }
    },
    prevPagination() {
      let nextUrl = this.prevPage;
      this.page = this.page - 1;

      this.$store.dispatch("DigitalSignature/nextPagination", {
        nextUrl,
      });
    },
    //TODO: externalizar a js de utilidades
    sortedDate() {
      if (!this.sortedFlag) {
        this.sortedFlag = true;

        return this.digital_signature_data.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );
      } else {
        this.sortedFlag = false;
        return this.digital_signature_data.sort(
          (a, b) => new Date(b.startDate) - new Date(a.startDate)
        );
      }
    },
    selectQuantity(evt) {
      let limit = evt.target.value;

      // if (limit != 10) {
      //   this.offset = 0;
      // }
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      // total esta definido como una constante en 10
      let totalDocuments = this.total_data;
      let limitPerPage = limit;

      let totalPages = Math.ceil(totalDocuments / limitPerPage);

      this.totalPages = totalPages;

      // total data  = 21

      this.page = 1;

      this.limit = limit;

      // this.page = limit / 10;

      this.selected_quantity = evt.target.value;
      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      let sign_status__range;

      if (this.searchPending) {
        sign_status__range = "2,2";
      } else if (this.searchComplete) {
        sign_status__range = "3,3";
      } else if (this.searchAll) {
        sign_status__range = "2,3";
      }
      this.$store.dispatch("DigitalSignature/loadDocumentOn");

      this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
        limit,
      });
    },

    show_modal() {
      let param = "modal__digital__filter";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__digital__filter;
      element.open();
    },
    show_modal_remove(data) {
      this.identifier_modal = data.responsable;
      this.name_modal = data.name;
      this.id_document = data.id;

      this.caseSearch =
        this.searchAll != false
          ? "All"
          : this.searchPending != false
          ? "Pending"
          : this.searchComplete != false
          ? "Completed"
          : null;
      let param = "modal__digital__remove";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__digital__remove;
      element.open();
    },
    async cleanFilter() {
      let tagsList = false;
      let tagForReloadRequest = [];
      let tagsForfront = [];
      this.$store.dispatch("DigitalSignature/setTags", {
        tagsForfront,
        tagForReloadRequest,
        tagsList,
      });
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      let sign_status__range = "2,3";
      if (this.searchPending) {
        sign_status__range = "2,2";
        if (this.searchAll) {
          sign_status__range = "2,3";
        }
      } else if (this.searchComplete) {
        sign_status__range = "3,3";
        if (this.searchAll) {
          sign_status__range = "2,3";
        }
      } else if (this.searchAll) {
        sign_status__range = "2,3";
      }

      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      await this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
      });

      // let totalDocuments = this.total_data;
      // let limitPerPage = this.limit;

      setTimeout(() => {
        let totalDocuments = this.total_data;
        let limitPerPage = this.limit;
        this.page = 1;

        this.totalPages = Math.ceil(totalDocuments / limitPerPage);
      }, 1000);

      // setTimeout(() => {
      //   this.totalPages = Math.ceil(totalDocuments / limitPerPage);
      // }, 500);
    },
    selectedSignature(data) {
      let totalsigners = data.sign_archived;

      let members = data.sign_compute_body.members
        ? data.sign_compute_body.members
        : 0;

      let trackingStep = data.sign_compute_body.tracking_step
        ? data.sign_compute_body.tracking_step
        : 0;

      let signer = parseInt(totalsigners.slice(0, 1));
      let totalSigner = parseInt(totalsigners.slice(2, 3));

      let name = data.name;
      let typeSignature = data.sign_type;
      let tags = data.tags;

      let id = data.id;

      let dataForTracking = {
        id,
        members,
        totalSigner,
        signer,
        trackingStep,
        name,
        typeSignature,
        tags,
      };
      this.$store.dispatch("DigitalSignature/setTracking", dataForTracking);
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", false);

      this.$router.push({ name: "Tracking" });
    },
  },
  watch: {
    globalLoaderFilterModal() {
      this.loaderglobal = this.globalLoaderFilterModal;
    },
    tagsForfront() {
      this.tagsFlag = this.tags;
      if (this.tags) {
        setTimeout(() => {
          this.page = 1;
          let totalDocuments = this.total_data;
          let limitPerPage = this.limit;

          this.totalPages = Math.ceil(totalDocuments / limitPerPage);
        }, 1000);
      }
    },
    flagData() {
      this.notFoundData = this.flagData;
    },
    datacheck() {
      this.notFoundData = this.datacheck;
    },

    digital_signature_data() {
      this.loader = true;
      this.digital_signature_data_from_fetch = [];
      if (this.digital_signature_data) {
        this.digital_signature_data_from_fetch = this.digital_signature_data;
      }
    },
    flagDelete(val) {
      if (val == false) {
        setTimeout(() => {
          let totalDocuments = this.total_data;
          let limitPerPage = this.limit;
          this.page = 1;

          this.totalPages = Math.ceil(totalDocuments / limitPerPage);
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss">
.sign__digital {
  cursor: pointer !important;
}

.horizontal-scroll {
  overflow: hidden;
  overflow-x: auto;
  clear: both;
  width: 100%;
}
.table__digital__signature {
  min-width: rem-calc(640);
}

.btn-clean-filter {
  width: 15px;
  color: #464646;
  cursor: pointer;
}
.item_tag {
  background: #f1f1f1;
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 4px;
  color: #464646;
  padding: 0.1rem 0.3rem;
}
.list_tags {
  display: flex;
  width: fit-content;
  gap: 0.2rem;
  align-items: center;
}
.itemClick {
  cursor: pointer;
}
.blockPagination {
  fill: gray;
  opacity: 0.3;
  pointer-events: none;
  // cursor: none !important;
}
.sortDate > svg {
  width: 10px;
  transform: rotate(-90deg);
  fill: var(--call_to_action_button) !important;
}
.sortDateDown > svg {
  width: 10px;
  transform: rotate(90deg);
}

.container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > div {
    color: var(--header_table_doc) !important;
  }
}
.arrow-back-signature {
  width: 15px;
  > svg:hover {
    fill: #0064ff;
    transition: all 100ms ease-in-out;
  }
}
.message_no_data {
  height: 400px;
}
.position_loader {
  padding: 7rem;
}
.message_empty_data {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  color: #464646 !important;
  font-size: 14px !important;
}
.header__digital_signature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.7px solid #9c9c9c;
}

.left-section {
  padding: 0.6rem;
  padding-left: 0rem;
  display: flex;
  > input {
    background: #f8f8f8;
    width: 170px;
    height: 26px;
    border: 1px solid #a5a5a5;
    box-sizing: border-box;
    border-radius: 16px;
    margin-right: 0.8rem;
    padding: 0.5rem 0.7rem;
    padding-bottom: 0.5rem;
    font-size: 12px;
    text-align: center;
    cursor: pointer;

    &:focus {
      color: #000032;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0.25rem rgba(13, 110, 253, 25);
    }
  }
}

.container-filter,
.left-section,
.right-section,
.container-btn-digital-signature {
  display: flex;
  white-space: nowrap;

  @media screen and (max-width: 800px) and (min-width: 700px) {
    font-size: 15px;
  }
}

.icon-filter {
  width: 200px !important;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 0.5rem;

  > div {
    color: var(--title_origin) !important;
  }
  > svg {
    width: 30px;
    height: 30px;
    stroke: var(--icon_filter);
    fill: var(--icon_filter);
  }
}
.digital_signature_icon {
  > svg g path {
    stroke: var(--svg_confirm_stroke);
    fill: var(--svg_confirm_fill);
  }
}

.right-section {
  gap: 1rem;

  > .container-btn-digital-signature {
    align-items: center;
    cursor: pointer;
  }

  > .container-btn-digital-signature > .digital_signature_icon {
    width: 25px;
    height: 25px;
  }
}
.borradores {
  opacity: 0.95;
  padding-right: 1rem;
  border-right: 1px solid #9c9c9c;
  color: #9c9c9c;
  cursor: pointer;
}
.container-btn-digital-signature {
  gap: 0.2rem;
}

.container-filter {
  gap: 0.5rem;
  align-items: center;
}

// table styles

.container_status {
  width: 70%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1000px) and (min-width: 200px) {
    width: 100%;
  }
}

@media screen and (max-width: 600px) and (min-width: 200px) {
  .header__digital_signature {
    flex-wrap: wrap;
    > .right-section {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 1rem;
      order: -1;

      > .borradores {
        border-right: none;
      }
    }

    & > .left-section,
    .right-section {
      flex-wrap: wrap;
    }

    > .left-section {
      width: 100%;
      flex-direction: column;
      justify-content: space-around;
      gap: 0.5rem;
      align-items: center;

      > .container-filter {
        margin-left: 3rem;
      }
    }
  }
}

.blue_ball,
.green_ball,
.gray_ball {
  width: 19px;
  height: 18px;
  border-radius: 50%;
}

.gray_ball {
  background: gray;
}

.green_ball {
  background: #00d461;
}

.borradores:hover {
  color: #0064ff;
}

.blue_ball {
  background: #0064ff;
}
table {
  width: 100%;
  border-collapse: collapse;
}

.thead_signature {
  border-bottom: 1px solid #464646;
  > tr > td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--head_table_dsignature);
  }
}

.tbody_signature > tr > td {
  padding: 15px;
  padding-bottom: 0px !important;
  color: var(--content_table_dsignature);
}

.action_btn_signature {
  cursor: pointer;
  padding-left: 5px !important;
  padding-right: 0px !important;

  > svg {
    width: 20px;
    height: 20px;
    fill: var(--content_table_dsignature) !important;
  }
}

// pagination style
.footer_digital_signature {
  white-space: nowrap;
  // margin-top: 39px;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_pagination_digital_signature {
  > svg {
    > path {
      stroke: var(--svg_company_pagination) !important;
      fill: transparent !important;
    }
    > g {
      > path {
        stroke: var(--svg_company_pagination) !important;
        fill: transparent;
      }
    }
  }
}

.btn_pagination_digital_signature {
  > svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  > .leftArrow {
    margin-right: 3rem;
  }
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.text_view_digital_signature {
  margin-left: 2rem;
  color: var(--title_origin) !important;
}
.disable__delete_main {
  pointer-events: none;
}
.disable__delete {
  pointer-events: none;
  opacity: 0.5;

  & * {
    fill: gray;
  }
}
.document-sign {
  color: var(--title_origin);
}
</style>
