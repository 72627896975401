import * as types from "./mutations-types";
import moment from "moment";

export default {
  // loader for load data for categorys scheduling
  [types.ACTIVE_LOADER_SCHEDULING](state) {
    state.loadDatacategoryScheduling = true;
  },
  [types.CANCEL_LOADER_SCHEDULING](state) {
    state.loadDatacategoryScheduling = false;
  },
  // loader for load data for all scheduling cards in my agenda
  [types.ACTIVE_LOADER_ALL_MEETINGS](state) {
    state.loaderAllMeetings = true;
  },
  [types.DESACTIVE_LOADER_ALL_MEETINGS](state) {
    state.loaderAllMeetings = false;
  },

  // Fetch data for schedule legal
  [types.FETCH_SCHEDULE_LEGAL_DATA](state, data) {
    let scheduleLegalData = [];

    scheduleLegalData = data.map((element) => {
      let data = {
        name: element.name,
        title: element.title,
        value: element.abstract,
        id: element.id,
        url: element.action_url,
      };
      return data;
    });

    state.scheduleLegalItems = scheduleLegalData;
  },

  [types.FETCH_SCHEDULE_LEGAL_SUCCESS](state, data) {
    state.scheduleLegalItemsSuccess = data;
  },
  [types.FETCH_SCHEDULE_LEGAL_FAILURE](state, data) {
    state.scheduleLegalItemsFailure = data;
  },

  // types para almacenar el item de agendamiento seleccionado

  [types.SELECTED_SCHEDULING_CATEGORRY](state, params) {
    state.schedulingSelected = params;
  },
  // limpiar categoria seleccionada
  [types.CLEAN_SCHEDULING_CATEGORRY](state) {
    state.schedulingSelected = "";
  },

  [types.SELECTED_SCHEDULING_FLAG](state) {
    state.selectedMeeting = true;
  },
  [types.CLEAN_SCHEDULING_FLAG](state) {
    state.selectedMeeting = null;
  },

  // procesamiento datos para seccion de consulta operacional
  // Fetch data for schedule operation
  [types.FETCH_SCHEDULE_OPERATION_DATA](state, data) {
    let scheduleOperationData = [];

    scheduleOperationData = data.map((element) => {
      let data = {
        name: element.name,
        title: element.title,
        value: element.abstract,
        id: element.id,
        url: element.action_url,
      };
      return data;
    });

    state.scheduleOperationlItems = scheduleOperationData;
  },

  [types.FETCH_SCHEDULE_OPERATION_SUCCESS](state, data) {
    state.scheduleOperationlItemsSuccess = data;
  },
  [types.FETCH_SCHEDULE_OPERATION_FAILURE](state, data) {
    state.scheduleOperationlItemsFailure = data;
  },

  // guardar el resumen de las meeting para consulta legal y operaciones
  [types.FETCH_RESUME_MEETING_DATA](state, totalData) {
    let data = totalData.results;
    if (totalData.count > 0) {
      let dataMeetings = data.map((element) => {
        let date = element.schedule_date;
        let day = moment(date).format("dddd");
        let month = moment(date).format("MMMM");
        let dayINT =
          element.schedule_date != null
            ? element.schedule_date.slice(8, 10)
            : "";

        let ampm =
          element.schedule_end_time != null
            ? element.schedule_end_time.slice(0, 2) >= 12
              ? "pm"
              : "am"
            : "";

        let card = {
          hour_start:
            element.schedule_init_time != null
              ? element.schedule_init_time.slice(0, 5)
              : "",
          hour_end:
            element.schedule_end_time != null
              ? `${element.schedule_end_time.slice(0, 5)}${ampm}`
              : "",
          url: element.schedule_link,
          name: `${element.schedule_author.first_name} ${element.schedule_author.surname}`,
          date: {
            day,
            month: month.slice(0, 3).toUpperCase(),
            dayINT,
          },
          title: element.schedule_type.title,
          author: element.schedule_author.id,
        };

        return card;
      });
      state.resumeMeetingCard = dataMeetings;
    } else {
      state.resumeMeetingCard = null;
    }
  },
  // Fetch data for own schedule
  //procesamiento data para la card de agendamientos propios
  [types.FETCH_MEETING_CARD_DATA](state, totalData) {
    let data = totalData.results;
    if (totalData.count > 0) {
      let dataMeetings = data.map((element) => {
        let date = element.schedule_date;
        // let day = moment(date).format("DDDD");

        let day = moment(date).format("dddd");
        let month = moment(date).format("MMMM");
        let dayINT = element.schedule_date.slice(8, 10);

        let ampm = element.schedule_end_time.slice(0, 2) >= 12 ? "pm" : "am";

        let card = {
          hour_start: element.schedule_init_time.slice(0, 5),
          hour_end: `${element.schedule_end_time.slice(0, 5)}${ampm}`,
          url: element.schedule_link,
          name: `${element.schedule_author.first_name} ${element.schedule_author.surname}`,
          date: {
            day,
            month: month.slice(0, 3).toUpperCase(),
            dayINT,
          },
          title: element.schedule_type.title,
          author: element.schedule_author.id,
        };

        return card;
      });

      state.meetingCard = dataMeetings;
    } else {
      state.meetingCard = null;
    }
  },
  //procesamiento data para la card de agendamientos pasados
  [types.FETCH_PAST_MEETING_DATA](state, totalData) {
    let data = totalData.results;
    if (totalData.count > 0) {
      let dataMeetings = data.map((element) => {
        let date = element.schedule_date.toString();

        let splitString = date.split("-");
        let reverseArray = splitString.reverse();
        let joindates = reverseArray.join("-");

        let ampm =
          element.schedule_end_time != null
            ? element.schedule_end_time.slice(0, 2) >= 12
              ? "pm"
              : "am"
            : "";

        let card = {
          id: element.id,
          title: element.schedule_type.title,
          name: `${element.schedule_author.first_name} ${element.schedule_author.surname}`,
          date: joindates,
          hour: `${element.schedule_init_time.slice(
            0,
            5
          )}  - ${element.schedule_end_time.slice(0, 5)}${ampm}`,
        };

        return card;
      });
      state.meetingPastCard = dataMeetings;
    } else {
      state.meetingPastCard = null;
    }
  },
};
