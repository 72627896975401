var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__delete__employee",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : 'modal__default',attrs:{"id":"modal__delete__employee"}},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),(!_vm.confirmDelete)?_c('div',{staticClass:"new_modal__content"},[_c('div',{staticClass:"text-filter fix_employee_title"},[_vm._v(" Eliminar miembro del equipo ")]),_c('div',{staticClass:"text-filter-light"},[_vm._v(" ¿Estas seguro? está acción no se puede deshacer, se eliminaran todos los datos asociados a este miembro del equipo. ")]),_c('div',{staticClass:"new__footer__modal"},[_c('div',{staticClass:"secondary_button cancel_delete_employee",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Cancelar")])]),_c('div',{staticClass:"fix-btn-mac new_button_employee_modal",on:{"click":_vm.deleteEmployee}},[(_vm.verificationDelete)?_c('span',[_vm._v(" Aceptar ")]):_c('span',{},[_c('div',{staticClass:"loader_delete_employee"})])])])]):(_vm.confirmDeleteFailure)?_c('div',{staticClass:"new_modal__content center_elements"},[_c('div',{},[_c('ConfirmFailure')],1),_c('div',{staticClass:"text-filter fix_employee_title"},[_vm._v(" Ocurrio un error y no pudimos eliminar al miembro del equipo, vuelve a intentarlo ")])]):_c('div',{staticClass:"new_modal__content center_elements"},[_c('div',{},[_c('Confirm')],1),_c('div',{staticClass:"text-filter fix_employee_title"},[_vm._v(" El miembro del equipo ha sido eliminado con exito ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }