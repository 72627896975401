export default {
  setViewSettingRegional: (state) => {
    return state.RouterViewSettingRegional;
  },
  getDefaultCountryName: (state) => {
    return state.countryCode.name;
  },
  getDefaultCountryCode: (state) => {
    return state.countryCode.value;
  },

  // getter for retrive settings failure service state
  errorServiceRetriveSettings: (state) => {
    return state.failureCategoryInitialSettings;
  },
  categoryConfigForTemplate: (state) => {
    return state.categoryConfigForTemplate;
  },

  // default value for user:
  UserSettingsInstance: (state) => {
    return state.UserSettingsInstance;
  },

  defaultValueLenguageUser: (state) => {
    return state.UserSettingsInstance[0].defaultValue;
  },
  userLenguageInstanceChange: (state) => {
    return state.userLenguageInstanceChange;
  },
  defaultValueDarkMode: (state) => {
    return state.UserSettingsInstance[1].defaultValue;
  },
  userDarkModeInstanceChange: (state) => {
    return state.userDarkModeInstanceChange;
  },
  listGroupSettingsRegional: (state) => {
    return state.listGroup;
  },
  TenantSettingsInstance: (state) => {
    return state.tenantSettingsInstance;
  },
  TenantSettingsInstanceID: (state) => {
    return state.tenantSettingsInstance.id;
  },
  RegionSettingsInstance: (state) => {
    return state.regionalSettingsInstance;
  },
  reportLimitUseId: (state) => {
    return state.regionalSettingsInstance[0].id;
  },

  reportLimitUseValueChange: (state) => {
    return state.SaveValidRegionalSettings.limitUseInstanceChange;
  },

  activeDesactiveDocumentTypesId: (state) => {
    return state.regionalSettingsInstance[1].id;
  },
  documentTypesByRolId: (state) => {
    return state.regionalSettingsInstance[2].id;
  },
  documentTypeByRegionValues: (state) => {
    return state.documentTypeByRegionValues;
  },
  defaultDocumentTypeByRegion: (state) => {
    return state.defaultListDocumentTypeByregion;
  },

  defaultReportLimit: (state) => {
    return state.defaultReportLimit;
  },
  documentTypeTypePermByRolValues: (state) => {
    return state.documentTypeByPermByRolValues;
  },
  groupIdforDocumentTypePermByRol: (state) => {
    return state.groupIdforDocumentTypePermByRol;
  },
  defaultDocumentTypePermByRol: (state) => {
    return state.defaultDocumentTypePermByRol;
  },
  HasPermission: (state) => {
    return state.noHasPermission;
  },
};
