<template>
  <div class="container__settings__company inner">
    <Modal
      ref="modal"
      title="Cambiar Api-Key"
      size="m"
      :confirm="true"
      :cancel="true"
      identifier="company"
    >
      <div class="modal__body">
        <div class="container__description value-primary">
          Se desactivará tu Api-Key actual y será enviada una nueva clave a tu
          email.
        </div>
        <!-- <div class="container__password label-primary">
          <span>Contraseña</span>
          <input class="input__text__password" type="password" />
        </div> -->
      </div>
    </Modal>
    <NotAccessSettingInstances
      description="Configuración no disponible"
      ref="modal__not_access"
      size="xs"
    />
    <div
      class="container__api__key"
      @click="show_modal_apikey(TenantSettingsInstance.accessSetting)"
    >
      <SimpleLabelSelector
        :title="TenantSettingsInstance.title"
        :value="TenantSettingsInstance.description"
        :id="TenantSettingsInstance.id"
        :accessSetting="TenantSettingsInstance.accessSetting"
      />
    </div>
    <!-- <ModalClose ref="modal_close_account" />
    <div class="container__close_account" @click="show_modal_close_account()">
      <SimpleLabelSelector
        title=" Cerrar cuenta"
        value="Ponerte en contacto con administradores para
        desactivar esta cuenta"
      />
    </div> -->

    <!-- TODO: boton confirmar oculto por accion inecesaria  -->
    <!-- <div class="container__footer__settings m-top-xxl">
       <div class="btn-vue-primary fix-btn-mac">
        <span> Confirmar </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import SimpleLabelSelector from "../components/SimpleLabelSelector";
import Modal from "@/components/modal/Modal.vue";
import { mapGetters } from "vuex";
import NotAccessSettingInstances from "../components/modals/notAccessSettingInstances.vue";

export default {
  components: {
    SimpleLabelSelector,
    Modal,
    NotAccessSettingInstances,
  },

  computed: {
    ...mapGetters("SettingsApp", {
      TenantSettingsInstance: "TenantSettingsInstance",
    }),
  },
  methods: {
    show_modal_apikey() {
      if (!this.TenantSettingsInstance.accessSetting) {
        let param = "modal__not_access";
        this.$store.dispatch("Config/setModalRef", param);
        let element = this.$refs.modal__not_access;
        element.open();
      } else {
        let element = this.$refs.modal;
        element.open();
      }
    },
  },
};
</script>

<style lang="scss">
.container__settings__company {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
}
// .modal__body__apikey {
//   margin-left: 28px;
//   margin-right: 28px;
//   margin-top: 11px;
//   > .container__password,
//   .container__alert {
//     display: flex;
//     flex-direction: column;
//     gap: 5px;
//     margin-top: 28px;
//   }
// }
.container__title__apikey {
  margin-bottom: 5px;
  color: #000032 !important;
}
.container__description {
  margin-bottom: 28px;
  opacity: 0.7 !important;
  color: black !important;
  margin-bottom: 0px;
}

.input__text__password {
  background: #f8f8f8;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 12px 15px;
  height: 48px;
  border: 1px solid #a5a5a5;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 1px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.input__text__password:focus {
  color: $primary;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0.25rem rgba(13, 110, 253, 25);
}
</style>
