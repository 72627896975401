import * as types from "./mutations-types";

export default {
  // fetch al usuario en login
  [types.FETCH_LOGIN_REQUEST](state) {
    state.fetchingData = true;
    state.error = null;
  },
  [types.FETCH_LOGIN_SUCCESS](state, { user }) {
    state.fetchingData = false;
    state.error = null;
    state.token = user.token;
    localStorage.setItem("token", user.token);
    state.isLoggIn = true;
    state.is_staff = user.user.is_staff;
    state.email = user.user.email;
  },
  [types.OUTH_LOGIN_SUCCESS](state,  token ) {
    state.error = null;
    state.token = token;
    state.isLoggIn = true;
  },
  [types.OUTH_LOGIN_FAILURE](state) {
    state.error = null;
    state.token = null;
    state.isLoggIn = false;
  },
  [types.FETCH_LOGIN_FAILURE](state, { error }) {
    state.fetchingData = false;
    state.error = error;
    state.isLoggIn = false;
  },

  [types.FETCH_LOGIN_SUCCESS_OAUTH](state, { user }) {
    state.fetchingData = false;
    state.error = null;
    state.token = user.token;
    localStorage.setItem("token", user.token);
    state.isLoggIn = true;
    state.is_staff = user.user.is_staff;
    state.email = user.user.email;
  },

  [types.FETCH_LOGIN_REQUEST_OAUTH](state) {
    state.fetchingData = true;
    state.error = null;
  },
  [types.FETCH_LOGIN_FAILURE_OAUTH](state, { error }) {
    state.fetchingData = false;
    state.error = error;
    state.isLoggIn = false;
  },

  [types.GET_COMPANYS_BY_USER](state, { companys, totalCompanies }) {
    state.companies = companys;
    state.totalCompanies = totalCompanies;
  },

  [types.SET_COMPANY_SELECTED](state, company_id) {
    state.company_selected = state.companies.filter(
      (companies) => companies.company === company_id
    );
    /*[{id:1, name:pepe}, {id:2, name:papu}, {id:3, name pepu} ]

    }*/
  },

  [types.SET_USER_PROFILE](state, userProfile) {
    state.user_profile = userProfile;
    state.company = userProfile.company;
  },

  [types.SET_LOG_OUT](state, value) {
    state.token = value;
  },

  // use for modal only
  [types.SET_MODAL_OPEN](state) {
    state.modal = true;
  },
  [types.SET_MODAL_CLOSE](state) {
    state.modal = false;
  },
};
