import * as types from "./mutations-types";
import companyService from "../services/companyService";

export default {
  getSingleCompany({ commit }, id_company) {
    return new Promise((resolve, reject) => {
      companyService
        .get_company(id_company)
        .then((data) => {
          commit(types.GET_COMPANY_SELECTED_DATA, data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.GET_COMPANY_SELECTED_FAILURE, { error });
        });
    });
  },

  flagAdminForSingleCompany({ commit }) {
    commit(types.VIEW_SINGLE_COMPANY);
  },
  cleanFlagAdminForSingleCompany({ commit }) {
    commit(types.CLEAN_VIEW_SINGLE_COMPANY);
  },

  //TODO: funciones recicladas de firma digital

  //TODO: refactorizando:

  setFilterActiveInMobile({ commit }) {
    commit(types.SET_FILTER_COMPANY_MOBILE);
  },
  cleanFilterInMobile({ commit }) {
    commit(types.CLEAN_FILTER_COMPANY_MOBILE);
  },

  cleanSingleCompanySelected({ commit }) {
    commit(types.CLEAN_SINGLE_COMPANY);
  },

  fetchCompanyData(
    { commit },
    { limit = "10", filters, offset = null, filtersSearch }
  ) {
    commit(types.LOADER_COMPANY_OFF);

    return new Promise((resolve, reject) => {
      companyService
        .list_companies(filters, limit, offset, filtersSearch)
        .then((data) => {
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.FETCH_COMPANY_DATA, data);
          commit(types.SET_FLAG, true);

          if (data.count == 0) {
            commit(types.SET_FLAG, false);
          } else {
            commit(types.LOADER_COMPANY_ON);
          }

          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_COMPANYS_FAILURE, { error });
          commit(types.LOADER_COMPANY_ON);
        });
    });
  },

  nextPagination({ commit }, { nextUrl }) {
    commit(types.LOADER_COMPANY_OFF);

    return new Promise((resolve, reject) => {
      companyService
        .nextPage(nextUrl)
        .then((data) => {
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.FETCH_COMPANY_DATA, data);
          resolve(true);

          if (data.count == 0) {
            commit(types.SET_FLAG, false);
          }
        })
        .then(() => {
          commit(types.LOADER_COMPANY_ON);
        })
        .catch((error) => {
          reject(error);

          commit(types.SET_FLAG, false);
          commit(types.LOADER_COMPANY_ON);
        });
    });
  },

  fethFromFilterAdmin(
    { commit },
    {
      fields,
      limit = "10",
      offset,
      country,
      state,
      filters,
      economic_categories__name__icontains,
    }
  ) {
    commit(types.LOADER_COMPANY_OFF);

    return new Promise((resolve, reject) => {
      companyService
        .list_companies_from_filter(
          fields,
          limit,
          offset,
          country,
          state,
          filters,
          economic_categories__name__icontains
        )
        .then((data) => {
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.FETCH_COMPANY_DATA, data);
          commit(types.SET_FLAG, true);

          if (data.count == 0) {
            commit(types.SET_FLAG, false);
          } else {
            commit(types.LOADER_COMPANY_ON);
          }

          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FETCH_COMPANYS_FAILURE, { error });
          commit(types.LOADER_COMPANY_ON);
        });
    });
  },

  setSelectedRegion({ commit }, data) {
    commit(types.SET_SELECTED_REGION, data);
  },
  setSelectedCountry({ commit }, data) {
    commit(types.SET_SELECTED_COUNTRY, data);
  },
  setInitialEconomicCategory({ commit }, data) {
    commit(types.SET_INITIAL_ECONOMIC_CATEGORY, data);
  },
  setFlagEconomicCategoryTrue({ commit }) {
    commit(types.FLAG_RESET_INITIAL_ECONOMIC_CATEGORY);
  },
  setFlagEconomicCategoryFalse({ commit }) {
    commit(types.FLAG_RESET_INITIAL_ECONOMIC_CATEGORY_RETURN);
  },
  setEconomicData({ commit }, data) {
    commit(types.SET_ECONOMIC_SERVICE, data);
  },
  cleanActivityEconomic({ commit }, data) {
    commit(types.RESET_ACTIVITY_ECONOMIC, data);
  },
  cleanDataEconomicForService({ commit }) {
    commit(types.RESET_DATA_FOR_ECONOMIC_SERVICE);
  },
  pushDataEconomicForService({ commit }, data) {
    commit(types.PUSH_DATA_FOR_ECONOMIC_SERVICE, data);
  },
  setDataEconomicForService({ commit }, data) {
    commit(types.SET_DATA_FOR_ECONOMIC_SERVICE, data);
  },
  activateTags({ commit }) {
    commit(types.SET_TAGS_TRUE);
  },
  desactivateTags({ commit }) {
    commit(types.SET_TAGS_FALSE);
  },
  setTagsForSwitch({ commit }, data) {
    commit(types.SET_TAGS_FOR_SWITCH, data);
  },
  setFlagForCurrentOptionsSelected({ commit }, data) {
    commit(types.SET_FLAGS_SELECTED_OPTIONS, data);
  },
};
