var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__confirm__document",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'xs'
      ? 'modal__xs'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : _vm.size == 'fltr'
      ? 'modal__filter'
      : _vm.size == 'count'
      ? 'modal__counter'
      : 'modal__default',attrs:{"id":"modal__confirm__document"}},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_c('div',{staticClass:"modal__confirm"},[_c('div',{staticClass:"text-filter-light title_modal_confirm"},[_vm._v(" ¿Deseas enviar "),_c('span',{staticClass:"text-filter"},[_vm._v(" "+_vm._s(_vm.name_document)+" ")]),_vm._v(" para "),_c('span',{staticClass:"text-filter"},[_vm._v(" "+_vm._s(_vm.totalSigners)+" firmantes? ")])]),_c('div',{staticClass:"text-container text_modal_user_title"},[_vm._v(" El documento será recibido para ser firmado por: ")]),_vm._l((_vm.signers),function(signer,index){return _c('div',{key:index,staticClass:"container_signer_modal text-container"},[_c('div',{},[_vm._v(" "+_vm._s(signer.name)+" ")]),_c('div',{},[_vm._v(" "+_vm._s(signer.rut)+" ")])])})],2),_c('div',{staticClass:"footer_modal__confirm"},[_c('div',{staticClass:"secondary_button",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Cancelar")])]),_c('div',{staticClass:"btn-vue-primary fix-btn-mac",on:{"click":function($event){return _vm.ConfirmSend()}}},[_c('span',[_vm._v(" Aceptar ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }