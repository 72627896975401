import api from "@/service/api";
const validateToken = {
  verify_token(payload) {
    return api
      .post("/verify_token/", payload)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
};
export default validateToken;
