<template>
  <div class="close_tags_container" v-if="status">
    <div class="input_search_filter">
      <div class="container_filter_input_search">
        {{ tag }}
        <CloseIcon
          class="close_filter_tags_assistance"
          @click="deleteTags(id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CloseIcon from "../assets/close-input-search.svg";

// import moment from "moment";
export default {
  props: ["index", "id", "tag", "status"],
  data: () => {
    return {
      selectedtag: null,
      selectedtagService: null,
    };
  },
  components: {
    CloseIcon,
  },
  computed: {
    ...mapGetters("Assistance", {
      // state for tags (use for filters (HTML funcionality))
      tagsOfFilters: "tagsOfFilters",
      // innitial state of filters (have all options)
      initialFilters: "initialFilters",
      flagActiveInputSearch: "flagActiveInputSearch",
      dataInputSearchTags: "dataInputSearchTags",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  created() {
    //we save the filter tags in two variable (this renovate in "watch()")
    this.selectedtag = this.tagsOfFilters;
    this.selectedtagService = this.initialFilters;
  },

  methods: {
    deleteTags(idSelected) {
      this.$store.dispatch("Assistance/loadFilterOn");
      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      /*--------------------------------
 @Input: state of tags selected
 Description: we map the array and we test if the id are the same (id selected and id in the array) in the case if true we change the status value to false (for html funcionality)
 @Output: array with changes on status
//--------------------------------*/
      this.selectedtag.map((el) => {
        if (el.id == idSelected) {
          el.status = false;
        }
        return this.selectedtag;
      });

      /*--------------------------------
 @Input: new array with status change (see previous .map)
 Description: we filter the array and we leave only the elements with the status = true , this is for front/Html funcionality
 @Output: array with only status = true
//--------------------------------*/
      let newfilterTags = this.selectedtag.filter((el) => {
        return el.status == true;
      });
      // we save the array with filters(newfilterTags) in a state
      this.$store.dispatch(
        "Assistance/cleanSelectedTagsOfFilter",
        newfilterTags
      );

      /*--------------------------------
 @Input: state of tags (service) selected
 Description: we map the array and we test if the id are the same (id selected and id in the array) in the case if true we change the status value to false (for html funcionality)
 @Output: array with changes on status
//--------------------------------*/
      if (newfilterTags.length > 0) {
        this.selectedtagService.map((el) => {
          if (el.id == idSelected) {
            el.status = false;
          }
          return;
        });

        this.$store.dispatch(
          "Assistance/filterSelectedTags",
          this.selectedtagService
        );

        //payload for innitial filter ( all filters options )
        let payload = {
          admin_company_id: admin_company_id,
          service_type:
            this.selectedtagService[0].status != false
              ? this.selectedtagService[0].id
              : null,
          created_at__date__range:
            this.selectedtagService[1].status != false
              ? this.selectedtagService[1].id
              : null,
          requester:
            this.selectedtagService[2].status != false
              ? this.selectedtagService[2].id
              : null,
        };

        this.$store.dispatch("Assistance/getAssistanceWithFilters", payload);
      } else {
        if (this.flagActiveInputSearch) {
          let evaluarInput = Number(this.dataInputSearchTags);

          let service_type = null;
          let created_at__date__range = null;
          let requester = null;
          if (evaluarInput) {
            // we save the value from the search in variable to send to the payload
            let display_id = this.dataInputSearchTags;
            this.$store.dispatch("Assistance/inputSearchAssistance", {
              admin_company_id,
              display_id,
              service_type,
              created_at__date__range,
              requester,
            });
            // in case the value is not a number we dispatch de "title contains" payload
          } else {
            // we save the value from the search in variable to send to the payload
            let title__icontains = this.dataInputSearchTags;
            this.$store.dispatch("Assistance/inputSearchAssistance", {
              admin_company_id,
              title__icontains,
              service_type,
              created_at__date__range,
              requester,
            });
          }
        } else {
          let payload = {
            admin_company_id: admin_company_id,
            fields:
              "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
            limit: "5",
          };
          this.$store.dispatch("Assistance/setInactiveInputSearchTags");
          this.$store.dispatch("Assistance/getAllAssistance", payload);
        }
      }
      setTimeout(() => {
        this.$store.dispatch("Assistance/loadFilterOff");
      }, 1000);
    },
  },
  watch: {
    // this renovate the tags array
    tagsOfFilters() {
      this.selectedtag = this.tagsOfFilters;
    },
    // this renovate the tags array
    tagsOfFiltersService() {
      this.selectedtagService = this.initialFilters;
    },
  },
};
</script>

<style lang="scss">
.close_tags_container {
  position: relative;
}
.close__tags {
  background: black;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -15%;
  right: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.2rem;
  color: white;
  font-size: 10px;
  cursor: pointer;

  &:hover {
    background-color: #0064ff;
    color: white;
  }
}
.tags_container {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: none;
  }
}
.name_tags_assistance {
  padding: 2px 8px;
  white-space: nowrap;
}
.close_filter_tags_assistance {
  cursor: pointer;
}
</style>
