<template>
  <div>
    <div class="center-valid">
      <!-- <modalMissingField ref="modal-trigger-missingFieldsModal" :info="info" /> -->
      <div v-if="checked">
        ¡Autenticado! Pincha
        <a
          :href="
            $router.resolve({
              name: 'LoginPage',
            }).href
          "
          >aquí</a
        >
        para iniciar sesión en tu empresa.
      </div>
      <div v-else>
        <!-- {{ bad_message }} -->
      </div>
    </div>
  </div>
</template>

<script>
// import ErrorService from "../../../service/errorService";
// import modalMissingField from "../../../components/moddalMissingFields/modalMissingField.vue";

export default {
  name: "oauthGoogle",
  components: {
    // modalMissingField,
  },
  computed: {},
  created() {
    this.checkToken();
  },
  data() {
    return {
      info: "",
      modal: false,
      checked: false,
      bad_message: "Procesando...",
    };
  },
  methods: {
    // openDocumentMissingField() {
    //   this.$refs["modal-trigger-missingFieldsModal"].open();
    // },
    activeOverlay() {
      setTimeout(() => {
        let overlay = parent.window.document.getElementById("overlay2");

        overlay.classList.add("active-overlay");
      }, 200);
    },
    desactiveOverlay() {
      let overlay = parent.window.document.getElementById("overlay2");
      // console.log("overlay", overlay);
      overlay.classList.remove("active-overlay");
      overlay.classList.add("desactive-overlay");
    },
    checkToken() {
      this.activeOverlay();
      let payload = {
        code: this.$route.query.code,
        response_type: "code",
        client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_URL,
      };

      this.$store
        .dispatch("Login/fetchLoginOauth", payload)
        .then(() => {
          this.checked = true;

          setTimeout(() => {
            this.desactiveOverlay();
            this.$router.replace({ name: "LoginPage" });
          }, 1500);
        })
        .catch((error) => {
          this.desactiveOverlay();
          console.log("error:", error);
        });

    },
  },
  watch: {},
};
</script>

<style></style>
