<template>
  <div>
    <div v-if="loading">
      <loadingSection />
    </div>

    <div class="container-main-login" v-else>
      <img
        id="main-logo"
        alt="MisAbogados Logo"
        style=""
        src="../../../assets/misabogados-login.png"
      />
      <div class="row-custom-select  ">
        <h4>Empresas</h4>
        <div class="custom-select ">
          <select class="input_exception" name="" id="select" @change="setCompany">
            <option value="" selected disabled hidden>
              Selecciona tu empresa
            </option>

            <option
              class="short"
              data-limit="30"
              v-for="(company, index) in companies"
              :value="JSON.stringify(company)"
              :key="index"
            >
              {{ company.company_name }}
            </option>
          </select>

          <span class="custom-arrow"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import loadingSection from "../../../components/loaderGeneric/LoadingMA.vue";

function shortString(selector) {
  const elements = document.querySelectorAll(selector);
  const tail = "...";
  if (elements && elements.length) {
    for (const element of elements) {
      let text = element.innerText;
      if (element.hasAttribute("data-limit")) {
        if (text.length > element.dataset.limit) {
          element.innerText = `${text
            .substring(0, element.dataset.limit - tail.length)
            .trim()}${tail}`;
        }
      } else {
        throw Error("Cannot find attribute 'data-limit'");
      }
    }
  }
}

window.onload = function () {
  shortString(".short");
};
export default {
  name: "seleccionar-empresa",
  components: {
    loadingSection,
  },

  data: function () {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapGetters("Login", ["company"]),
    ...mapState({
      companies: (state) => state.Login.companies,
    }),
  },
  methods: {
    ...mapActions("Login", [
      "setCompanySelected",
      "setUserProfile",
      "setAllCompanies",
    ]),

    setCompany(e) {
      const id = e.target.value;
      const company = JSON.parse(id);
      this.setCompanySelected(company.company);
      this.setUserProfile(company.company);
      this.loading = true;

      setTimeout(() => {
        this.$router.push({ name: "home" });
      }, 2000);
    },

    async bootstrap() {
      //const companies = localStorage.getItem("companies");
      await this.setAllCompanies()
        .then(() => {})
        .catch((error) => {
          this.$store.dispatch("Login/setLogginFalse", error);
        });
    },
  },

  created() {
    this.bootstrap().then(() => {
      if (this.companies.length <= 1) {
        this.setUserProfile(this.companies[0].company);
        setTimeout(() => {
          this.$router.push({ name: "home" });
        }, 1500);
      } else {
        this.loading = false;
      }
    });

    //funcion para desviar al usuario que solo tiene 1 compañia
  },
};
</script>

<style lang="scss">
.row-custom-select {
  width: calc(100% - 35%);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
select {
  width: 250px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $primary;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $secondary !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: $primary;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0.25rem rgba(13, 110, 253, 25);
  }
}

.custom-select {
  position: relative;
  display: flex;
  justify-content: space-between;

  > select {
    width: 100%;
  }
}

option {
  padding: 1rem;
}
</style>
