<template>
  <div class="">
    <ModalOnBoardingEmployee ref="modal__onboarding__employee" size="xl" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalOnBoardingEmployee from "../OnBoarding/ModalOnBoardingEmployee.vue";

export default {
  components: {
    ModalOnBoardingEmployee,
  },
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  data: () => {
    return {};
  },
  created() {},
  mounted() {
    this.OpenOnBoarding();
  },
  methods: {
    OpenOnBoarding() {
      let param = "modal__onboarding__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__employee;
      element.open();
    },
  },
};
</script>

<style></style>
