<template>
  <Transition v-if="GetFlagLoaderEconomic" name="fade">
    <div class="card-single base-card card-special">
      <div class="card-group utility__title title-container">
        <h1 class="title-container">
          {{ title }}
        </h1>
      </div>

      <div class="container-economic utility__title">
        <div class="card-economic m-top-s">
          <div class="title-economic">DÓLAR</div>
          <div class="value-economic">
            {{ Dolar }}<span class="value-clp"> CLP</span>
          </div>
        </div>
        <div class="card-economic m-top-s">
          <div class="title-economic">UTM</div>
          <div class="value-economic">
            {{ UTM }}<span class="value-clp"> CLP</span>
          </div>
        </div>
        <div class="card-economic m-top-l">
          <div class="title-economic">EURO</div>
          <div class="value-economic">
            {{ euro }}<span class="value-clp"> CLP</span>
          </div>
        </div>
        <div class="card-economic m-top-l">
          <div class="title-economic">UF</div>
          <div class="value-economic">
            {{ UF }}<span class="value-clp"> CLP</span>
          </div>
        </div>
      </div>
    </div>
  </Transition>
  <div v-else class="card-single base-card card-special">
    <div class="title_skeleton">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="20"
        animation="fade"
      />
    </div>
    <div class="body_skeleton">
      <div class="">
        <VueSkeletonLoader
          type="rect"
          :width="100"
          :height="10"
          animation="fade"
        />
      </div>
      <div class="container_skeleton">
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>

        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>

      <div class="">
        <VueSkeletonLoader
          type="rect"
          :width="100"
          :height="10"
          animation="fade"
        />
      </div>
      <div class="container_skeleton">
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
        <div class="skeleton_utilities">
          <VueSkeletonLoader type="rect" :height="50" animation="fade" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["title"],
  components: {
    VueSkeletonLoader,
  },

  data: () => {
    return {
      isLoad: false,
    };
  },
  created() {},

  computed: {
    ...mapGetters("Home", {
      euro: "GetEuro",
      UF: "GetUF",
      Dolar: "GetDolar",
      UTM: "GetUTM",
      GetIndicadores: "GetIndicadores",
      GetFlagLoaderEconomic: "GetFlagLoaderEconomic",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../sharedComponents.scss";
.container-economic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0.1rem;
  padding-right: 2rem;

  > .card-economic {
    width: 50%;

    > .title-economic:hover,
    .value-economic:hover {
      color: $secondary;
    }
  }
}

.card-special {
  @media screen and (max-width: 1200px) and (min-width: 766px) {
    height: 250px;
  }
}

.container_economic_title {
  display: flex;
  flex-direction: column;
}
</style>
