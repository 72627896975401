<template>
  <div class="loader_selected_employee" v-if="loading">
    <loaderGeneric />
  </div>
  <div class="container__selected__employee" v-else>
    <ModalOnBoardingEmployee
      ref="modal__onboarding__employee"
      size="onboarding"
      indicator="1"
      ref_id="modal__onboarding__employee"
    />
    <div class="">
      <div class="header_selected__employee">
        <div class="text_document">
          <div class="">Añadir nuevo miembro del equipo</div>
          <div class="icon_onBoarding_add_select" @click="OpenOnBoarding()">
            <IconOnboarding />
          </div>
        </div>
        <div class="close_selected" @click="cancelCreation()">
          <CloseIcon />
        </div>
      </div>

      <div class="sub__title__selected">
        ¿Qué tipo de miembro quieres añadir?
      </div>

      <div class="add__member" @click="selectedCreateUser('Miembro')">
        <div class="container__svg__icon__selected">
          <Membercards />
        </div>
        <div class="container__text_selected_user">
          <div class="title__add__member">Añadir nuevo “Miembro”</div>
          <div class="sub__title__add__member">
            Dentro de tu equipo los “miembros” son los registro de cada
            empleado, puedes agregar todos sus datos y estos se guardarán.
            <b>Estos usuarios no se pueden logear a la plataforma</b>
          </div>
        </div>
      </div>

      <div class="container__option__selected">
        <div class="line__v3"></div>
        <div class="text__middle">o si lo prefieres</div>
        <div class="line__v3"></div>
      </div>
      <div class="add__user" @click="selectedCreateUser('Usuario')">
        <div class="container__svg__icon__selected">
          <UserCard />
        </div>
        <div class="container__text_selected_user">
          <div class="title__add__member">Añadir nuevo “Usuario”</div>

          <div class="sub__title__add__user">
            Dentro del equipo los “usuarios” también son miembros, con la misma
            cantidad de datos, pero
            <b>estos usuarios si tienen acceso a la plataforma</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Membercards from "../assets/membercards.svg";
import UserCard from "../assets/userCard.svg";
// import AddEmployeeIcon from "../assets//fi-rs-user-add.svg";
import CloseIcon from "../assets//close_icon_create_member.svg";
import loaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import IconOnboarding from "../OnBoarding/assets/Onboarding_icon2.svg";
import ModalOnBoardingEmployee from "../OnBoarding/ModalOnBoardingEmployee.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // AddEmployeeIcon,
    CloseIcon,
    loaderGeneric,
    IconOnboarding,
    ModalOnBoardingEmployee,
    Membercards,
    UserCard,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__companies: "list__companies",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
    }),
  },
  methods: {
    selectedCreateUser(user) {
      this.$store.dispatch("EmployeesV2/cleanBaseUserInformation");
      this.$store.dispatch("EmployeesV2/setTypeUserToCreate", user);

      if (user == "Miembro") {
        this.$store.dispatch("EmployeesV2/setTableActive", true);
      } else {
        this.$store.dispatch("EmployeesV2/setTableActive", false);
      }
      if (this.inSwitch) {
        let userCompanySelectedId = this.inSwitch
          ? this.idInSwitch
          : this.company.id;
        this.$store.dispatch(
          "EmployeesV2/setTypeUserToCreateCompnayId",
          userCompanySelectedId
        );
      } else {
        let userCompanySelectedId = this.company_id;
        this.$store.dispatch(
          "EmployeesV2/setTypeUserToCreateCompnayId",
          userCompanySelectedId
        );
      }
      this.$router.push({ name: "createEmployeeV2" });
    },
    cancelCreation() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
      }, 800);
      setTimeout(() => {
        this.$router.push({ name: "ListEmployeesV2" });
      }, 800);
    },
    OpenOnBoarding() {
      let param = "modal__onboarding__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__employee;
      element.open();
    },
  },
};
</script>

<style lang="scss"></style>
