
import * as types from "./mutations-types";

export default {
  setDataCards({ commit }, data) {
    commit(types.SET_DATA_CARDS, data);
  },

  setIndicadores({ commit }, data) {
    commit(types.SET_INDICADORES, data);
  },
  loadingInitialDataForCards({ commit }, data) {
    commit(types.SET_FLAG_FOR_LOADER_CARDS, data);
  },
  loadingInitialDataForEconomics({ commit }, data) {
    commit(types.SET_FLAG_FOR_LOADER_ECONOMICS, data);
  },
  loadingInitialDataForLinks({ commit }, data) {
    commit(types.SET_FLAG_FOR_LOADER_LINKS, data);
  },
  loadingInitialDataForUtilities({ commit }, data) {
    commit(types.SET_FLAG_FOR_LOADER_ECONOMICS, data);
  },
  loadingInitialDataForDocuments({ commit }, data) {
    commit(types.SET_FLAG_FOR_LOADER_DOCUMENT, data);
  },

  cleancounterStepHomeOnBoarding({ commit }, payload) {
    commit(types.CLEAN_COUNTER__STEP__HOME_ONBOARDING, payload);
  },

  counterStepHomeOnBoarding({ commit }, payload) {
    commit(types.COUNTER__STEP__HOME_ONBOARDING, payload);
  },
};
