<template>
  <div class="">
    <div
      class="container__add__signer"
      v-for="(signerDraft, indexDraftComponent) in signerDraft"
      :key="indexDraftComponent"
    >
      <input
        type="text"
        class="input-form disable__input"
        placeholder="Nombre Completo"
        :value="signerDraft.name"
        disabled
      />
      <input
        type="text"
        class="input-form disable__input"
        placeholder="Correo electrónico"
        :value="signerDraft.email"
        disabled
      />
      <input
        type="text"
        class="input-form disable__input"
        placeholder="RUT"
        :value="signerDraft.rut"
        disabled
      />
      <div class="cancel__btn" @click="deleteDraftElement(signerDraft)">
        <cancelBtn />
      </div>
      <div class="confirm_btn__rdy">
        <confirmFirma class="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cancelBtn from "../../../assets/iconos_container/cancel.svg";
import confirmFirma from "../../../assets/iconos_container/confirm_firma.svg";

export default {
  name: "SignerFromDraft",
  components: {
    cancelBtn,
    confirmFirma,
  },
  data: () => {
    return {
      draftElements: null,
    };
  },

  mounted() {
    this.draftElements = this.signerDraft;
  },
  computed: {
    ...mapGetters("DigitalSignature", {
      signerDraft: "signerDraft",
    }),
  },
  methods: {
    deleteDraftElement(data) {
      let tempArrayDrafts = [];
      tempArrayDrafts = this.signerDraft.filter((el) => el.rut !== data.rut);

      this.draftElements = tempArrayDrafts;

      this.$store.dispatch("DigitalSignature/deleteDraftSigner", data);
    },
  },
};
</script>

<style lang="scss">
.confirm_btn {
  width: 15px !important;
  height: 15px !important;
}

.confirm_btn__rdy {
  background: green;
  width: 17px !important;
  height: 17px !important;
  border-radius: 50%;
  display: flex;
  margin-top: 0.5rem;
  padding: 0.2rem;
  & svg {
    fill: white !important;
  }
}
.cancel__btn {
  width: 17px !important;
  height: 17px !important;
  border-radius: 50%;
  display: flex;
  margin-top: 0.5rem;
  padding: 0.1rem;
  cursor: pointer;


  & svg {
    padding: 0.1rem;
    // fill: #464646 !important;
    stroke: var(--svg_confirm_stroke);
    fill: var(--svg_confirm_fill);
  }
}
</style>
