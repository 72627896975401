<template>
  <div class="modal__content">
    <div class="image_onboarding_employee">
      <ThirdStepOnboarding />
    </div>
    <div class="content_onboarding_employee">
      <div class="container_onboarding_title">
        <div class="title_onboarding_employee title_thrid_step">
          Administra tu equipo como quieras
        </div>
      </div>
      <div class="text_container_onboarding text_third_step">
        <div class=""> Verifica y actualiza el estado de tus usuarios.</div>
        <div class=""> Edita sus datos personales cuando lo necesites.</div>

      </div>
    </div>

  </div>
</template>

<script>
import ThirdStepOnboarding from "./assets/Third_step_onboarding.svg";
import { mapGetters } from "vuex";

export default {
  props: ["size"],
  components: {
    ThirdStepOnboarding,
  },
  data() {
    return {
      stepOnBoarding: 1,
    };
  },
  created() {},
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployeesOnBoarding: "stepEmployeesOnBoarding",
    }),
  },
  methods: {
    AcceptOnBoarding() {
      let increment = this.stepEmployeesOnBoarding + 1;
      this.$store.dispatch(
        "EmployeesV2/counterStepEmployeeOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__employee");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
@import "./onBoarding.scss";
</style>
