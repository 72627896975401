<template>
  <div class="container__base__information_chat">
    <div class="field__base__information">
      <div class="title__container__base">
        {{ currentSingleAssistanceBaseInformation.title }}.
      </div>
      <div class="content__base"></div>
    </div>
    <div class="base__information_message_wall">
      <div class="assistance_created_information">
        <div class="field__base__information">
          <div class="sub__title__container__base">Categoría</div>
          <div class="content__base">
            {{ currentSingleAssistanceBaseInformation.serviceType }}
          </div>
        </div>
        <div class="field__base__information">
          <div class="sub__title__container__base">Descripción</div>
          <div class="content__base" v-if="showFullText">
            {{ currentSingleAssistanceBaseInformation.description }}
          </div>
          <div class="content__base container_toggle_text" v-else>
            {{ assistanceDescription }}
            <div class="toggle_text_button" @click="toggleText">
              {{ buttonTextTrim }}
            </div>
          </div>
        </div>
      </div>
      <div class="assistance_created_information">
        <div class="field__base__information">
          <div class="sub__title__container__base message_title_description">
            <div class="text_title_description">Solicitante</div>
          </div>
          <div class="content__base">
            {{ currentSingleAssistanceBaseInformation.requester }}
          </div>
        </div>
        <div class="field__base__information">
          <div class="sub__title__container__base message_title_description">
            <div class="text_title_description">Encargado</div>
          </div>
          <div class="content__base">
            {{ lawyerAssigned != null ? lawyerAssigned.name : "Pendiente" }}
          </div>
        </div>
        <div class="field__base__information">
          <div class="sub__title__container__base message_title_description">
            <div class="text_title_description">Coordinador</div>
          </div>
          <div class="content__base">
            {{
              attendantAssigned != null ? attendantAssigned.name : "Pendiente"
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="files__chat">
      <div class="files_header_container">
        <div class="icon_container_assistance_files icon_circle_wall">
          <FilesSvg />
        </div>
        <div class="files_assistance__subtitle">
          <div class="files_subtitle_text">
            <div>N de Archivos</div>
            <div class="tag_assistance_files_container">
              <div class="tag_assistance_files">
                {{ totalFiles }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container__files__elements">
        <div
          class="file__element"
          v-for="(file, index) in filesRender"
          :key="index"
        >
          <FilesSvg />

          <div class="file__named" @click="downloadFile(file.url)">
            <div class="container__wrapper__hover__message_chat">
              <div class="tooltip__key__message">{{ file.fullname }}</div>
              <div class="file__title">
                {{ file.name }}
              </div>
            </div>
            <div class="file_size">{{ file.size }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilesSvg from "../../assets/filesSvg.svg";
import { mapGetters } from "vuex";
export default {
  components: {
    FilesSvg,
  },
  computed: {
    ...mapGetters("Assistance", {
      currentSingleAssistanceBaseInformation:
        "currentSingleAssistanceBaseInformation",
      currentSingleAssistance: "currentSingleAssistance",
      attendantAssigned: "attendantAssigned",
      lawyerAssigned: "lawyerAssigned",
    }),
  },

  mounted() {
    if (!this.currentSingleAssistance.isArrayFiles) {
      // this.cutNameOldFile();
    } else {
      this.cutNameNewFiles();
    }
    this.trimmedDescription();
  },
  data: () => {
    return {
      totalFiles: "",
      filesRender: "",
      showFullText: false,
      togggleFullText: false,
      assistanceDescription: "",
      buttonTextTrim: "Mostrar más",
    };
  },
  methods: {
    downloadFile(url) {
      const urlDonwload = url;
      window.location.href = urlDonwload;
    },
    cutNameNewFiles() {
      this.filesRender = [];

      let totalFiles = [];

      //  TODO:This can change into a service
      // this.data.getThread.messages.messages.map((el) => {
      //   if (el.files.length > 0) {
      //     this.filesRender.push(el.files[0]);
      //   }
      // });

      totalFiles = this.filesRender.concat(this.currentSingleAssistance.files);

      /*--------------------------------
       @Input: File name
       Description: this function evaluate the length of the name of the file and do a format for them
       @Output: File name formatted
      //--------------------------------*/
      let arrayFilesTemp = totalFiles;
      this.validArrayLength =
        arrayFilesTemp.length > 0 ? arrayFilesTemp : false;

      this.arrayFiles = arrayFilesTemp.map((el) => {
        // We separete the file name and the type of file

        //( "filesname" . "typefile"  )
        let arrayNameTemp = el.filename.split(".");
        let fullname = el.filename;
        // We save the separate the values and we save in 2 variables

        // ("typefile")
        let typefileCut = arrayNameTemp[1];
        // ("filename")
        let filenameCut = arrayNameTemp[0];
        let resultName = "";

        // we evaluate if the string is "too long" , in that case we "cut" the string and we add ".."
        if (filenameCut.length > 15) {
          resultName = `${filenameCut.slice(0, 17)}..`;
        } else {
          resultName = filenameCut;
        }
        let fileSize = null;

        if (el.size > 1000000) {
          let tamanioEnMbTemp = Math.ceil(el.size / 1000000);
          fileSize = `${tamanioEnMbTemp}MB`;
        } else {
          let tamanioEnKbTemp = Math.ceil(el.size / 1024);
          fileSize = `${tamanioEnKbTemp}KB`;
        }
        let payload = {
          name: `${resultName}.${typefileCut}`,
          fullname: fullname,
          url: el.url,
          size: fileSize,
        };

        return payload;
      });
      // front value for html ("Numero de archivos: X") from base request

      this.filesRender = this.arrayFiles;
      this.totalFiles = totalFiles.length;
    },
    trimmedDescription() {
      if (
        this.currentSingleAssistanceBaseInformation.description.length <= 300
      ) {
        this.showFullText = true;
        this.assistanceDescription =
          this.currentSingleAssistanceBaseInformation.description;
      } else {
        this.showFullText = false;
        this.assistanceDescription =
          this.currentSingleAssistanceBaseInformation.description.substring(
            0,
            300
          ) + "...";
      }
    },
    toggleText() {
      if (!this.togggleFullText) {
        this.assistanceDescription =
          this.currentSingleAssistanceBaseInformation.description;
        this.togggleFullText = true;
        this.buttonTextTrim = "Mostrar menos";
      } else {
        this.assistanceDescription =
          this.currentSingleAssistanceBaseInformation.description.substring(
            0,
            300
          ) + "...";
        this.togggleFullText = false;
        this.buttonTextTrim = "Mostrar más";
      }
    },
  },
  watch: {
    currentSingleAssistance: {
      handler: function (newVal) {
        if (newVal) {
          this.cutNameNewFiles();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.container__wrapper__hover__message {
  transform: translateY(4px);
  position: relative;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 19px;

  cursor: pointer;

  // overflow: hidden !important;
  background: transparent;
  // width: 80%;

  > div {
    text-align: center;
  }
}
.container__wrapper__hover__message_chat {
  transform: translateY(4px);
  position: relative;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 19px;

  cursor: pointer;

  // overflow: hidden !important;
  background: transparent;
  // width: 80%;

  > div {
    text-align: center;
  }
}
.tooltip__key__message {
  position: absolute;
  display: none;
  font-size: 11.8px;
  width: fit-content;
  overflow: hidden;
}

.container__wrapper__hover__message:hover .tooltip__key__message {
  transform: fade-in 500ms;
  transition: all 700ms ease;
  opacity: 1;
  display: block;
  background: #ffffff !important;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  padding: 0.5rem;
  border-radius: 17px;
  transform: translateX(-113%) translateY(2px);
  color: #000000 !important;
}
.container__wrapper__hover__message {
  display: flex;
  width: 125px !important;
  white-space: nowrap !important;
  flex-direction: initial !important;
}
.container__wrapper__hover__message:hover {
  flex-direction: row !important;
  transition: all 1.5s linear;
  > svg {
    position: absolute !important;
    opacity: 0.9;
    z-index: 99999;
  }
}
.container__wrapper__hover__message_chat:hover .tooltip__key__message {
  transform: fade-in 500ms;
  transition: all 700ms ease;
  opacity: 1;
  display: block;
  background: #ffffff !important;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  padding: 0.5rem;
  border-radius: 17px;
  transform: translateX(0%) translateY(27px);
  color: #000000 !important;
}
.container__wrapper__hover__message_chat:hover .file__title {
  color: #0064ff;
}
.container__wrapper__hover__message_chat {
  display: flex;
  width: 125px !important;
  white-space: nowrap !important;
  flex-direction: initial !important;
}
.container__wrapper__hover__message_chat:hover {
  flex-direction: row !important;
  transition: all 1.5s linear;
  > svg {
    position: absolute !important;
    opacity: 0.9;
    z-index: 99999;
  }
}
.container__files__elements {
  height: 200px !important;
  min-height: 90%;
  overflow-y: auto !important;
}
.base__information_message_wall {
  display: flex;
  flex-direction: column;
  gap: 2rem !important;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    flex-wrap: wrap;
  }
}
.assistance_created_information {
  display: flex;
  flex-direction: column;
  gap: 0.6rem !important;
}
.title__container__base {
  margin-bottom: 1rem;
}
.message_title_description {
  background: #bfd8ff !important;
  fill: solid;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
}
.text_title_description {
  padding: 1px 5px;
}
.toggle_text_button {
  color: blue;
  cursor: pointer;
}
.container_toggle_text {
  height: 118px;
  overflow-y: auto;
  padding-right: 0.4rem;
}
</style>
