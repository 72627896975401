<template>
    <div class="skeleton_main_assistance_information" >
              <div class="skeleton_assistance_left">
                <div class="skeleton_assistance_left_name">
                  <VueSkeletonLoader
                    type="rect"
                    :width="100"
                    :height="15"
                    animation="fade"
                  />
                  <VueSkeletonLoader
                    type="rect"
                    :width="800"
                    :height="15"
                    animation="fade"
                  />
                  <VueSkeletonLoader
                    type="rect"
                    :width="800"
                    :height="15"
                    animation="fade"
                  />

                  <VueSkeletonLoader
                    type="rect"
                    :width="100"
                    :height="15"
                    animation="fade"
                  />
                  <VueSkeletonLoader
                    type="rect"
                    :width="800"
                    :height="15"
                    animation="fade"
                  />
                  <VueSkeletonLoader
                    type="rect"
                    :width="800"
                    :height="15"
                    animation="fade"
                  />
                </div>
              </div>
            </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
    components: {
    VueSkeletonLoader,
  },
};
</script>

<style>

</style>