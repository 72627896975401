<template>
  <div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
// import NavBar from "../components/sections/navBar";
// import authService from "../service/authService";
export default {};
</script>

<style>
.container-fluid {
  padding: 0px;
  overflow: hidden;
}
</style>
