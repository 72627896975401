<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : size == 'modal__counter_v2'
        ? 'modal__counter_v2'
        : 'modal__default'
    "
    id="modal_warning_save"
    ref="modal_warning_save"
  >
    <div class="modal__content">
      <div class="modal__body fix_modal_content">
        <div class="header_modal">
          <CancelIcon @click="cancelModal()" />
        </div>
        <div class="container__title title-primary fix_warning_title">
          <span> Guardar cambios </span>
        </div>
        <div class="modal__user__settings">
          <div class="container__description value-primary">
            Hay cambios sin guardar en los siguientes parámetros:
          </div>
          <div class="container__description" v-if="validChangesDarkMode">
            {{ titleDarkmode }} - {{ userDarkModeInstanceChange.value }}
          </div>
        </div>
      </div>

      <div class="footer_modal footer_counter">
        <div
          class="btn-vue-primary fix-btn-mac"
          @click="confirmModalChanges(identifier)"
          v-if="confirm"
        >
          <span> Confirmar </span>
        </div>
        <div
          v-if="cancel"
          disabled="disabled"
          class="secondary_button"
          @click="cancelModal()"
        >
          <span> Cancelar </span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "./assets/cancel.svg";
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import updateSettins from "../../modulesAdmin/modules/Settings/services/SettingService";
import DarkModeScript from "@/utils/darkmode.js";
import LightModeScript from "@/utils/lightmode.js";
export default {
  data: () => {
    return {
      Darkmode: null,
      dataChanged: [],
      titleDarkmode: "Modo Oscuro",
    };
  },
  components: {
    CancelIcon,
  },
  computed: {
    ...mapGetters("SettingsApp", {
      UserSettingsInstance: "UserSettingsInstance",
      defaultValueLenguageUser: "defaultValueLenguageUser",
      defaultValueDarkMode: "defaultValueDarkMode",
      TenantSettingsInstanceID: "TenantSettingsInstanceID",
      userLenguageInstanceChange: "userLenguageInstanceChange",
      userDarkModeInstanceChange: "userDarkModeInstanceChange",
      reportLimitUseValueChange: "reportLimitUseValueChange",
      groupIdforDocumentTypePermByRol: "groupIdforDocumentTypePermByRol",
      documentTypeTypePermByRolValues: "documentTypeTypePermByRolValues",
      documentTypeByRolIdInstance: "documentTypesByRolId",
      countryCodeForRegionalSettings: "getDefaultCountryCode",
      activeDesactiveDocumentTypesId: "activeDesactiveDocumentTypesId",
      documentTypeByRegionValues: "documentTypeByRegionValues",
    }),
    ...mapGetters("Login", {
      client_company_id: "company_id",
      countryCode: "countryCode",
    }),
    ...mapState({
      userstate: (state) => state.Login.user_profile,
    }),
    validChangesDarkMode() {
      return this.userDarkModeInstanceChange != false ? true : false;
    },
  },

  props: ["title", "confirm", "cancel", "size", "identifier"],
  methods: {
    // validChangesLenguaje() {
    //   if (this.userLenguageInstanceChange != false) {
    //     let textChange = "Lenguaje";
    //     this.dataChanged.push(textChange);
    //   }
    // },
    // validChangesDocumentLimit() {
    //   if (this.limitUseInstanceChange != false) {
    //     let textChange = "Limites de uso";
    //     this.dataChanged.push(textChange);
    //   }
    // },
    // validChangesDocumentRegionTypes() {
    //   if (this.documentTypeByRegionValues.length > 0) {
    //     let textChange = "Document types activos";
    //     this.dataChanged.push(textChange);
    //   }
    // },
    // validChangesDocumentByRol() {
    //   if (this.documentTypeTypePermByRolValues.length > 0) {
    //     let textChange = "Document types por roles";
    //     this.dataChanged.push(textChange);
    //   }
    // },
    open() {
      let modal = document.querySelector("#modal_warning_save");
      modal.showModal();

      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
      // this.validChangesLenguaje();
      // this.validChangesDocumentLimit();
      // this.validChangesDocumentRegionTypes();
      // this.validChangesDocumentByRol();
    },
    cancelModal() {
      let modal = document.querySelector("#modal_warning_save");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
      this.$store.dispatch(
        "SettingsApp/activeChangeUserDarkModeInstance",
        false
      );
      setTimeout(() => {
        if (this.defaultValueDarkMode == "Desactivado") {
          LightModeScript.applyLightmode();
        } else {
          DarkModeScript.applyDarkmoke();
        }
      }, 2000);
      this.dataChanged = [];
    },
    confirmModalChanges(indentifier) {
      if (indentifier == "user") {
        this.saveSettingsUser();
      }
      if (indentifier == "company") {
        this.saveSettingsCompany();
      }
      if (indentifier == "report") {
        this.saveSettinsRegionalReportLimit();
      }
      if (indentifier == "documentRegion") {
        this.saveSettinsRegionalDocumentTypeByRegion();
      }
      if (indentifier == "documentRol") {
        this.saveSettinsRegionalDocumentTypeByRol();
      }
    },
    saveSettingsUser() {
      let userLenguaje = this.userLenguageInstanceChange;
      let darkmode = this.userDarkModeInstanceChange;

      let lenguajeChanes = null;
      let darkmodeChanges = null;
      let payload = [];

      this.lenguageSucces = null;
      this.darkModeSucces = null;

      if (userLenguaje != false) {
        this.lenguageSucces = "Idioma modificado con éxito";
        lenguajeChanes = {
          id: userLenguaje.id,
          data: userLenguaje.value,
        };
        payload.push(lenguajeChanes);
      }
      if (darkmode != false) {
        this.darkModeSucces = "Color de la interfaz modificada con éxito";
        darkmodeChanges = {
          id: darkmode.id,
          data: darkmode.value,
        };
        payload.push(darkmodeChanges);
      }

      let admin_company_id = this.client_company_id;

      //params to get user settings instance
      let params = {
        client_company_id: this.client_company_id,
        scope: 0,
        country: this.countryCode,
      };

      updateSettins
        .sendChangeUserInstance(admin_company_id, payload)
        .then(() => {
          this.responseApiKeyAction = true;
          this.loader = true;

          setTimeout(() => {
            this.$store.dispatch("SettingsApp/getSettingsInstances", params);
            if (this.lenguageSucces != null && this.darkModeSucces != null) {
              let message = `Configuraciones de usuario modificadas con éxito`;
              this.$toasted.success(message);
              this.$store.dispatch(
                "SettingsApp/activeChangeUserDarkModeInstance",
                false
              );
              this.$store.dispatch(
                "SettingsApp/activeChangeUserLenguajeInstance",
                false
              );
            } else if (this.lenguageSucces != null) {
              this.$store.dispatch(
                "SettingsApp/activeChangeUserLenguajeInstance",
                false
              );
              let messageLenaujeSuccess = `Idioma modificado con éxito`;
              this.$toasted.success(messageLenaujeSuccess);
            } else if (this.darkModeSucces != null) {
              this.$store.dispatch(
                "SettingsApp/activeChangeUserDarkModeInstance",
                false
              );
              let messageDarkModeSucces = `Color de la interfaz modificada con éxito`;
              this.$toasted.success(messageDarkModeSucces);
            }

            this.cancelModal();
          }, 500);
        })

        .catch(() => {
          this.errorMessages = "Error al guardar";
          this.loader = false;
          this.responseApiKeyAction = false;
          this.errorFieldApiKey = true;
          this.errorField = "Error en la solicitud";
          Vue.toasted.error(this.errorMessages);
        });
      this.dataChanged = [];
    },
    // saveSettinsRegionalDocumentTypeByRol() {
    //   let idInstance = this.documentTypeByRolIdInstance;
    //   let group_id = this.groupIdforDocumentTypePermByRol;
    //   let document_type = this.documentTypeTypePermByRolValues;

    //   let data = {
    //     id: idInstance,
    //     data: {
    //       group_id: group_id,
    //       document_type: document_type,
    //     },
    //   };

    //   let payload = [];
    //   payload.push(data);
    //   let admin_company_id = this.client_company_id;

    //   let params = {
    //     admin_company_id: admin_company_id,
    //     employee_group_id: group_id,
    //   };

    //   updateSettins
    //     .sendChangeDocumentTypeByRol(admin_company_id, payload)
    //     .then(() => {
    //       this.responseApiKeyAction = true;
    //       this.loader = true;
    //       setTimeout(() => {
    //         this.$store.dispatch(
    //           "SettingsApp/listDocumentTypePermByRol",
    //           params
    //         );
    //         let message = `Enlace de DocumentTypes a roles modificados con éxito`;
    //         this.$toasted.success(message);
    //         this.cancelModal();
    //       }, 2000);
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //       this.responseApiKeyAction = false;
    //       this.errorFieldApiKey = true;
    //       this.errorField = "Error en la solicitud";
    //     });
    //   this.$store.dispatch("SettingsApp/clearElementDocumentPermByRol");
    // },
    // saveSettinsRegionalDocumentTypeByRegion() {
    //   let idInstance = this.activeDesactiveDocumentTypesId;

    //   let arrayChanges = this.documentTypeByRegionValues;

    //   let data = {
    //     id: idInstance,
    //     data: arrayChanges,
    //   };

    //   let payload = [];
    //   payload.push(data);

    //   let admin_company_id = this.client_company_id;

    //   this.$store.dispatch(
    //     "SettingsApp/cleanDefaultValuesForDocumentTypeByRegion"
    //   );
    //   updateSettins
    //     .sendChangeDocumentTypeByRegion(admin_company_id, payload)
    //     .then(() => {
    //       this.responseApiKeyAction = true;
    //       this.loader = true;
    //       setTimeout(() => {
    //         updateSettins.list_document_type_category().then((data) => {
    //           let document_category_list = data.results;
    //           // recorrer 3 veces el arreglo para filtrar los datos correspondientes al default
    //           for (
    //             let index = 0;
    //             index < document_category_list.length;
    //             index++
    //           ) {
    //             let categoryElement =
    //               document_category_list[index].document_category_children;

    //             for (let index = 0; index < categoryElement.length; index++) {
    //               let grandChildCategoryElement =
    //                 categoryElement[index].document_type;

    //               for (
    //                 let index = 0;
    //                 index < grandChildCategoryElement.length;
    //                 index++
    //               ) {
    //                 this.$store.dispatch(
    //                   "SettingsApp/setDefaultDocumentTypeByRegion",
    //                   grandChildCategoryElement[index]
    //                 );
    //               }
    //             }
    //           }
    //           this.$store.dispatch("SettingsApp/clearElementDocumentByRegion");
    //           let message = `DocumentTypes activos en esta región modificados con éxito`;
    //           this.$toasted.success(message);
    //           this.cancelModal();
    //         });
    //       }, 2000);
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //       this.responseApiKeyAction = false;
    //       this.errorFieldApiKey = true;
    //       this.errorField = "Error en la solicitud";
    //     });
    // },
    // saveSettinsRegionalReportLimit() {
    //   let admin_company_id = this.client_company_id;
    //   let payload = [];

    //   let country__code = this.countryCodeForRegionalSettings;

    //   let params = {
    //     client_company_id: this.client_company_id,
    //     scope: 2,
    //     country: country__code,
    //   };

    //   let reportvaluesChange = this.reportLimitUseValueChange;

    //   let data = {
    //     id: reportvaluesChange.id,
    //     data: reportvaluesChange.data,
    //   };

    //   payload.push(data);

    //   updateSettins
    //     .sendChangeReportLimitSettingRegional(admin_company_id, payload)
    //     .then(() => {
    //       this.responseApiKeyAction = true;
    //       this.loader = true;
    //       setTimeout(() => {
    //         this.$store.dispatch("SettingsApp/getSettingsInstances", params);
    //         let message = `Configuraciones de limites modificadas con éxito`;
    //         this.$toasted.success(message);
    //         this.cancelModal();
    //       }, 2000);
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //       this.responseApiKeyAction = false;
    //       this.errorFieldApiKey = true;
    //       this.errorField = "Error en la solicitud";
    //       Vue.toasted.error(this.errorMessages);
    //     });
    // },
    // saveSettingsCompany() {
    //   this.loader = true;

    //   setTimeout(() => {
    //     this.loader = false;
    //   }, 1000);

    //   this.responseApiKeyAction = true;

    //   let id = this.TenantSettingsInstanceID;
    //   let data = true;

    //   let dataApiKey = {
    //     id: id,
    //     data: data,
    //   };

    //   let arrayApiKey = [];
    //   arrayApiKey.push(dataApiKey);
    //   let admin_company_id = this.client_company_id;

    //   updateSettins
    //     .sendChangeApiKey(admin_company_id, arrayApiKey)
    //     .then(() => {
    //       // this.loader = false;
    //     })
    //     .catch(() => {
    //       setTimeout(() => {
    //         this.loader = false;
    //         this.responseApiKeyAction = false;
    //         this.errorFieldApiKey = true;
    //       }, 1000);
    //       this.errorField = "Error en la solicitud";
    //     });
    // },
  },
};
</script>

<style lang="scss">
.fix_modal_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fix_warning_title {
  margin-left: 0px !important;
}
</style>
