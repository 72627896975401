<template>
  <div class="container-slots" v-if="isCheck">
    <router-view />
  </div>
  <div class="container-slots" v-else>
    <HomePageEmployees />
  </div>
</template>

<script>
import homeService from "@/modules/home/services/homeCardServices";
import HomePageEmployees from "@/modules/home/pages/HomePageEmployees.vue";

import { USER_ROLE } from "@/utils/roles";

import { mapGetters } from "vuex";
export default {
  name: "HomeModule",
  components: {
    HomePageEmployees,
  },
  data: function () {
    return {
      payload: {},
      datacheck: false,
    };
  },

  created() {
    if (this.group != USER_ROLE.EXECUTIVE) {
      this.loadDataforHome();
    }
  },

  computed: {
    ...mapGetters("Login", {
      user: "user_profile",
      is_staff: "is_staff",
      group: "group",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
    }),
    isCheck() {
      return this.datacheck;
    },
  },

  methods: {
    loadDataforHome() {
      this.$store.dispatch("Home/loadingInitialDataForCards", false);
      if (this.group === "Admin" || this.group === "Company-Admin") {
        this.payload = {
          computes: [
            "home_docs_card",
            "home_quotations_card",
            "home_questions_card",
            "home_users_card",
          ],
        };

        homeService
          .requestHomeCards(this.idInSwitch, this.payload)
          .then((data) => {
            this.$store.dispatch("Home/setDataCards", data);
            this.datacheck = true;
          })
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("Home/loadingInitialDataForCards", true);
            }, 1100);
          })
          .catch(() => {
            // this.$store.dispatch("Home/loadingInitialDataForCards", false);
          });
      }
    },
  },
};
</script>

<style lang="scss"></style>
