<template>
  <div class="container_basic_information_assistance m-top-s">
    <div class="description_assistance">
      <div class="tittle_description label-primary">
        SLA generales de mis abogados
      </div>
      <div class="sla_container_assistance">
        Para todas las solicitudes de asistencia legal, MisAbogados ha dispuesto
        compromisos de tiempo de respuesta y entrega final del servicio, de cara
        a sus clientes. Para cada nueva consulta, revisión o redacción de
        contrato ingresada en la plataforma, usted recibirá una confirmación de
        su abogado(a) asignado(a) en <b>menos de 4 horas*</b>. Dicho profesional
        podrá solicitarle antecedentes adicionales, si fuese necesario. Al
        recibir los antecedentes adicionales solicitados, su abogado(a) tendrá
        un<b> máximo de 12 horas para responder su consulta</b>, o bien
        <b>
          una cantidad de días hábiles a acordar con su abogado si se tratase de
          elaborar o revisar documentos y/o contratos</b
        >
      </div>
    </div>
    <div class="description_assistance">
      <div class="tittle_description label-primary">
        SLA para la atencion de tu ticket
      </div>
      <div class="sla_container_assistance">
        En caso de que el cliente no enviase los antecedentes adicionales
        solicitados, dentro de un
        <b>plazo de 24 horas desde el último recordatorio</b> enviado por
        nuestro profesional, su solicitud <b>será cerrada</b> a la espera de su
        respuesta.
      </div>
      <div class="sla_container_assistance text_asistance_information ">
        *se considera, para la definición de todos nuestros SLAs, horas hábiles
        (9:00 a 19:00 hrs, de Lunes a Viernes)
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  created() {},
  props: ["usuario"],
};
</script>

<style lang="scss">
.description_assistance {
  > .label-primary {
    color: var(--line_primary) !important;
    font-size: 12px !important;
  }
}
.sla_container_assistance {
  font-size: 14px !important;
  text-align: justify;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    text-align: left;
  }
}
.text_asistance_information{
  margin-top: 2rem;
}
</style>
