var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__cancel__creation__employee",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : 'modal__default',attrs:{"id":"modal__cancel__creation__employee"}},[_c('div',{staticClass:"header_modal"},[_c('CancelIcon',{on:{"click":function($event){return _vm.cancelModal()}}})],1),_c('div',{staticClass:"modal__content__cancel__creation"},[_vm._m(0),_c('div',{staticClass:"title_remove_creation text_creation_cancel text-filter-light"},[_vm._v(" ¿Estas seguro? Al cancelar, los datos de tu usuario no se guardarán ")]),_c('div',{staticClass:"footer_modal footer__modal__cancel"},[_c('div',{staticClass:"secondary_button_cancel_create",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Volver")])]),_c('div',{staticClass:"fix-btn-mac button_cancel_create",on:{"click":function($event){return _vm.AcceptCancelModal()}}},[_c('span',[_vm._v(" Aceptar ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"title_remove_creation text-filter-light title_modal_cancel_creation"},[_vm._v(" Cancelar ")])])
}]

export { render, staticRenderFns }