<template>
  <Transition v-if="GetFlagLoaderDocuments" name="fade">
    <div class="card-single base-card card-special card-overflow-y">
      <div class="card-group utility__title title-container">
        <h1 class="title-container">
          {{ title }}
        </h1>
      </div>

      <div
        class="container-card-user utility__title"
        v-for="(data, index) in sites"
        :key="index"
      >
        <div class="contianer-links">
          <a target="_blank" :href="data.url" class="text-link">
            {{ data.title }}
          </a>
        </div>
      </div>
    </div>
  </Transition>
  <div v-else class="card-single base-card card-special card-overflow-y">
    <div class="title_skeleton">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="20"
        animation="fade"
      />
    </div>

    <div class="body_skeleton">
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
      <VueSkeletonLoader type="rect" :height="20" animation="fade" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["title", "Sites"],
  components: {
    VueSkeletonLoader,
  },
  computed: {
    ...mapGetters("Home", {
      GetFlagLoaderDocuments: "GetFlagLoaderDocuments",
    }),
  },

  data: function () {
    return {
      sites: [
        {
          title: "Consulta estado de situación tributaria de terceros",
          url: "https://zeus.sii.cl/cvc/stc/stc.html",
        },
        {
          title: "Registro de comercio del Conservador de Bienes Raíces",
          url: "https://www.conservador.cl/portal/tramites_comercio",
        },
        {
          title: "Trámites digitales para empresas en un día",
          url: "https://www.registrodeempresasysociedades.cl/",
        },
        {
          title: "Emprendimiento e Innovación de Chile Atiende",
          url: "https://www.chileatiende.gob.cl/temas/emprendimiento-e-innovacion",
        },
        {
          title: "Atención a Empleadores de la Dirección del trabajo",
          url: "https://www.dt.gob.cl/portal/1626/w3-propertyvalue-26863.html",
        },
        {
          title: "Actualización de mi información en SII",
          url: "https://www.sii.cl/servicios_online/1125-.html",
        },
        {
          title: "Programa Valor Pyme de Banco Bci",
          url: "https://www.valorpyme.cl/",
        },
        {
          title: "Programa Pymes en Línea de CORFO",
          url: "https://pymesenlinea.cl/",
        },
        {
          title: "Acceso a Previred",
          url: "https://www.previred.com/wPortal/login/login.jsp",
        },
      ],
      isLoad: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../sharedComponents.scss";

.base-card {
  min-height: 230px !important;
}

.title-container {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.card-overflow-y {
  overflow-y: auto;
}

.contianer-links a {
  &:hover {
    font-size: 12px;
    color: black;
  }
}
</style>
