import ScheduleModule from "./pages/ScheduleModule";
export const ScheduleRoutes = [
  {
    path: "/Agendamiento",
    name: "scheduleDashboard",
    component: ScheduleModule,

  },

  {
    path: "/Agendamiento/Consulta-legal",
    name: "legalScheduling",
    component: () =>
      import(/*webpackChunkName: "LegalSchedule" */ "./pages/LegalScheduling"),
  },
  {
    path: "/Agendamiento/Consulta-Operaciones",
    name: "operationScheduling",
    component: () =>
      import(
        /*webpackChunkName: "OperationModule" */ "./pages/OperationScheduling"
      ),
  },
  {
    path: "/Agendamiento/Mi-agenda",
    name: "myScheduling",
    component: () =>
      import(/*webpackChunkName: "MySchedule" */ "./pages/MySchedule"),
  },
];
