export const GET__LIST__EMPLOYEES = "GET__LIST__EMPLOYEES";
export const GET__LIST__EMPLOYEES_MEMBERS = "GET__LIST__EMPLOYEES_MEMBERS";
export const SET_FIILTER__EMPLOYEES = "FIILTER__EMPLOYEES";
export const SET_EMPLOYEES_IN_SWITCH = "SET_EMPLOYEES_IN_SWITCH";

//companies

// export const GET__COMPANIES = "GET__COMPANIES";
export const CLEAN_LIST_COMPANIES = "CLEAN_LIST_COMPANIES";
export const SET_COMPANIES_BY_USER = "SET_COMPANIES_BY_USER";
export const SET_SINGLE_COMPANY = "SET_SINGLE_COMPANY";

//Loader flags
export const SET_LOADER_ON = "SET_LOADER_ON";
export const SET_LOADER_OFF = "SET_LOADER_OFF";

//using in input search
export const GET_EMPLOYE__SEARCH = "GET_EMPLOYE__SEARCH";
export const GET_FILTER_EMPLOYE_FAILURE = "GET_FILTER_EMPLOYE_FAILURE";
export const GET_EMPLOYE__SEARCH_BY_ADMIN = "GET_EMPLOYE__SEARCH_BY_ADMIN";

//from filter modal admin
export const GET_FILTER_EMPLOYEE__BY_MODAL_ADMIN =
  "GET_FILTER_EMPLOYEE__BY_MODAL_ADMIN";
// from filter modal users
export const GET_FILTER_EMPLOYEE__BY_MODAL = "GET_FILTER_EMPLOYEE__BY_MODAL";
//filters of modal
export const ADD_TAGS_OF_FILTERS = "ADD_TAGS_OF_FILTERS";
export const ADD_INITIAL_FILTERS = "ADD_INITIAL_FILTERS";
export const CLEAN_TAGS_OF_FILTERS = "CLEAN_TAGS_OF_FILTERS";
export const FILTER_SELECTED_TAGS = "FILTER_SELECTED_TAGS";
export const CLEAN_SELECTED_TAGS_OF_FILTERS = "CLEAN_SELECTED_TAGS_OF_FILTERS";

// from list employee
export const SET_IS_ACTIVE_EMPLOYEE = "SET_IS_ACTIVE_EMPLOYEE";
export const SET_IS_VERIFIED_EMPLOYEE = "SET_IS_VERIFIED_EMPLOYEE";
export const CONFIRM_DELETE_EMPLOYEE = "CONFIRM_DELETE_EMPLOYEE";
export const FAILURE_DELETE_EMPLOYEE = "FAILURE_DELETE_EMPLOYEE";

// Dropdown Mutations
export const SET_EMPLOYEE_DROPDOWN = "SET_EMPLOYEE_DROPDOWN";
export const SET_EMPLOYEE_DROPDOWN_VERIFIED = "SET_EMPLOYEE_DROPDOWN_VERIFIED";
export const SET_DROPDOWN_ALL_ACTIVATED = "SET_DROPDOWN_ALL_ACTIVATED";
export const SET_DROPDOWN_ALL_DESACTIVATED = "SET_DROPDOWN_ALL_DESACTIVATED";
export const SET_DROPDOWN_ALL_VERIFIED = "SET_DROPDOWN_ALL_VERIFIED";
export const DROP_DOWN_THREE_DOTS = "DROP_DOWN_THREE_DOTS";
// export const SET_DROPDOWN_ALL_NOT_VERIFIED = "SET_DROPDOWN_ALL_NOT_VERIFIED";
export const CLEAN_ALL_SELECTION_DROP = "CLEAN_ALL_SELECTION_DROP";

export const TOTAL_PAGES_DATA = "TOTAL_PAGES_DATA";
export const TOTAL_PAGES_DATA_MEMBERS = "TOTAL_PAGES_DATA_MEMBERS";
export const SET_TOTAL_DATA = "SET_TOTAL_DATA";
export const SET_TOTAL_DATA_VIEW = "SET_TOTAL_DATA_VIEW";
export const SET_PREV_PAGE = "SET_PREV_PAGE";
export const SET_NEXT_PAGE = "SET_NEXT_PAGE";

export const LOADER_TABLE_ON = "LOADER_TABLE_ON";
export const LOADER_TABLE_OFF = "LOADER_TABLE_OFF";

// view single employee
export const SET_CURRENT_VIEW_SINGLE_EMPLOYEE =
  "SET_CURRENT_VIEW_SINGLE_EMPLOYEE";
export const CLEAN_CURRENT_VIEW_SINGLE_EMPLOYEE =
  "CLEAN_CURRENT_VIEW_SINGLE_EMPLOYEE";
export const SET_CURRENT_USER_FETCH = "SET_CURRENT_USER_FETCH";
export const SET_MARITAL_STATUS = "SET_MARITAL_STATUS";

// for toggle selected table
export const SET_ACTIVE_TABLE = "SET_ACTIVE_TABLE";

export const SET_ACTIVE_EDIT_TABLE = "SET_ACTIVE_EDIT_TABLE";

export const SET_COUNTRY_EMPLOYEE = "SET_COUNTRY_EMPLOYEE";
export const SET_REGION_EMPLOYEE = "SET_REGION_EMPLOYEE";
export const SET_CITY_EMPLOYEE = "SET_CITY_EMPLOYEE";

// step create employee
export const COUNTER__STEP__EMPLOYEE = "COUNTER__STEP__EMPLOYEE";
export const CLEAN_COUNTER__STEP__EMPLOYEE = "CLEAN_COUNTER__STEP__EMPLOYEE";

export const SET_TYPE_USER = "SET_TYPE_USER";
export const SET_TYPE_USER_ID_COMPANY = "SET_TYPE_USER_ID_COMPANY";

export const SAVE_BASE_USER_INFORMATION = "SAVE_BASE_USER_INFORMATION";
export const SAVE_BASE_USER_BANK = "SAVE_BASE_USER_BANK";
export const SAVE_BASE_USER_COMPANY = "SAVE_BASE_USER_COMPANY";
export const CLEAN_BASE_USER_INFORMATION = "CLEAN_BASE_USER_INFORMATION";

//List for values in employee (edit/create)
export const SET_LIST_BANKS = "SET_LIST_BANKS";
export const SET_LIST_HEALTH_INSURANCE = "SET_LIST_HEALTH_INSURANCE";
export const SET_LIST_SOCIAL_SECURITY = "SET_LIST_SOCIAL_SECURITY";

export const SET_LIST_OPTION_USER_PROFILE = "SET_LIST_OPTION_USER_PROFILE";

export const FLAG_ACTIVE_RESULTS_EMPLOYEE = "FLAG_ACTIVE_RESULTS_EMPLOYEE";
export const FLAG_DESACTIVE_RESULTS_EMPLOYEE =
  "FLAG_DESACTIVE_RESULTS_EMPLOYEE";
export const FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER = "FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER";
export const FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER =
  "FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER";

export const ADD_DATA_FOR_DELETE_USER_THREE_DOTS =
  "ADD_DATA_FOR_DELETE_USER_THREE_DOTS";


export const COUNTER__STEP__EMPLOYEE_ONBOARDING =
  "COUNTER__STEP__EMPLOYEE_ONBOARDING";

export const CLEAN_COUNTER__STEP__EMPLOYEE_ONBOARDING =
  "CLEAN_COUNTER__STEP__EMPLOYEE_ONBOARDING";
export const FLAG_ACTIVE_INPUT_SEARCH_USER = "FLAG_ACTIVE_INPUT_SEARCH_USER";
export const FLAG_INACTIVE_INPUT_SEARCH_USER = "FLAG_INACTIVE_INPUT_SEARCH_USER";

export const FLAG_ACTIVE_INPUT_SEARCH_MEMBER =
  "FLAG_ACTIVE_INPUT_SEARCH_MEMBER";
export const FLAG_INACTIVE_INPUT_SEARCH_MEMBER =
  "FLAG_INACTIVE_INPUT_SEARCH_MEMBER";
