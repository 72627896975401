<template>
  <div class="container-slots container-base min__container__employee">

    <div class="container-base-title">
      <div class="title-container">
        <span class="icon__breadcrumb">
          <router-link :to="{ name: 'ListEmployeesV2' }">
            Añadir nuevo {{ typeUser }}
          </router-link>
          <RightArrow />
        </span>
        Datos personales
      </div>
      <div class="">
        <!-- -->
      </div>
    </div>
    <progressBarEmployee />

    <div class="">
      <Transition v-if="stepEmployees == 0" name="fade">
        <BaseInformation />
      </Transition>
      <Transition v-if="stepEmployees == 1" name="fade">
        <CompanyFields />
      </Transition>
      <Transition v-if="stepEmployees == 2" name="fade">
        <BankFields />
      </Transition>
    </div>
  </div>
</template>

<script>
import RightArrow from "@/assets/iconos_container/right.svg";
import progressBarEmployee from "../components/progressBarEmployee.vue";
import BaseInformation from "../components/CreateEmployee/BaseInformation.vue";
import BankFields from "../components/CreateEmployee/BankFields.vue";
import CompanyFields from "../components/CreateEmployee/CompanyFields.vue";
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {};
  },
  components: {
    RightArrow,
    progressBarEmployee,
    BaseInformation,
    BankFields,
    CompanyFields,
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployees: "stepEmployees",
      typeUser: "typeUser",
    }),
  },
};
</script>

<style lang="scss">
// .container_edit_employee {
//   min-height: 300px;
// }
</style>
