
import gql from 'graphql-tag'

export const suscribeToNewMessages = gql`
  subscription SuscribeToNewMessages($quotation_id: String) {
    suscribeToNewMessages(quotation_id: $quotation_id) {
      id
      quotation_id
      text
      files {
        filename
        url
      }
      pinned
      createdAt
      updatedAt
      user {
        id
        full_name
        email
        initials
        is_staff
        is_attendant
      }
    }
  }
`;


export const UPDATE_MESSAGE = gql`
  subscription suscribeToUpdatedMessages($quotation_id: String!) {
    suscribeToUpdatedMessages(
      quotation_id: $quotation_id,
    ) {
      id
      quotation_id
      pinned
    }
  }
`;