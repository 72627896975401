import { ProfileRoutes } from "./modules/profile/route";
import { HomeRoutes } from "./modules/home/route";
import { RequestAssistanceRoutes } from "./modules/integratedAssistance/route";
// import { DocumentsRoute } from "./modules/documents/route";
import { CompanyRoutesV2 } from "./modules/companyv2/route";
// import { EmployeesRoutes } from "./modules/employees/route";

import { DigitalSignatureRoutes } from "./modules/digitalSignature/route";
import { ScheduleRoutes } from "./modules/schedule/route";
import { ReportTenantRoutes } from "./modules/reportTenant/route";
import { AdminRoutes } from "./modulesAdmin/routes";

import { LoginRoutes } from "./modules/Login/route";
import { EmployeesRoutesV2 } from "./modules/employeesV2/route";
import { SettingsRoutes } from "./modulesAdmin/modules/Settings/route";

export default [
  ...LoginRoutes,
  ...ProfileRoutes,
  ...HomeRoutes,
  ...EmployeesRoutesV2,
  ...RequestAssistanceRoutes,
  // ...DocumentsRoute,
  ...CompanyRoutesV2,
  // ...EmployeesRoutes,
  ...DigitalSignatureRoutes,
  ...ScheduleRoutes,
  ...ReportTenantRoutes,
  ...AdminRoutes,
  ...SettingsRoutes,
];
