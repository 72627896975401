import state from "../../Login/store/state";
import * as types from "./mutations-types";

export default {
  [types.FETCH_DIGITAL_SIGNATURE_SUCCESS](state, data) {
    state.digital_signature_data_success = true;
    state.digital_signature_data = data;
  },

  [types.FETCH_DIGITAL_SIGNATURE_FAILURE](state, { error }) {
    state.error_digital_signature = error;
    state.digital_signature_data_failure = true;
  },

  [types.SET_DATA_CHECK](state, data) {
    state.datacheck = data;
  },
  [types.SET_FLAG](state, data) {
    state.flagData = data;
  },
  [types.SET_TOTAL_DATA](state, data) {
    state.total_data = data;
  },
  [types.SET_TOTAL_DATA_VIEW](state, data) {
    state.total_data_view = data;
  },
  [types.SET_PREV_PAGE](state, data) {
    state.prevPage = data;
  },
  [types.SET_NEXT_PAGE](state, data) {
    state.nextPage = data;
  },

  [types.SET_TAGS_REQUEST](state, data) {
    state.tagForReloadRequest = data;
  },
  [types.SET_TAGS_REQUEST_FRONT](state, data) {
    state.tagsForfront = data;
  },
  [types.SET_TAGS](state, data) {
    state.tags = data;
  },
  [types.SET_GLOBAL_LOADER](state, data) {
    state.globalLoader = data;
  },
  [types.FETCH_DRAFT_DIGITAL_SIGNATURE_DATA](state, data) {
    let digital_signature_for_table = [];

    let data_table = data.results;

    for (var i = 0; i < data.results.length; i++) {
      digital_signature_for_table.push({
        id: data.results[i].id,
        name: data_table[i].document_type.name,
        startDate: data_table[i].sign_init_date,
        sign_user_body: data_table[i].sign_user_body,
        sign_type: data_table[i].sign_type == 0 ? 1 : 2,
        tags: data_table[i].tags,
      });
    }
    state.flagDraftData = true;

    state.draftData = digital_signature_for_table;
  },

  [types.FETCH_DIGITAL_SIGNATURE_DATA](state, data) {
    state.digital_signature_data = data;

    state.prevPage = data.previous;
    state.nextPage = data.next;

    let digital_signature_for_table = [];
    let data_table = data.results;

    for (var i = 0; i < data.results.length; i++) {
      digital_signature_for_table.push({
        id: data.results[i].id,
        name: data_table[i].document_type.name,
        responsable:
          data_table[i].sign_author != null
            ? data_table[i].sign_author.first_name +
              " " +
              data_table[i].sign_author.surname
            : "Sin responsable",
        startDate: data_table[i].sign_init_date,
        endDate:
          data_table[i].sign_end_date === null
            ? "En proceso"
            : data_table[i].sign_end_date,
        signatures: data_table[i].sign_archived,
        status: data_table[i].sign_status,
        sign_compute_body: data_table[i].sign_compute_body,
        sign_archived: data_table[i].sign_archived,
        sign_type:
          data_table[i].sign_type == 0
            ? "Firma Electrónica Simple"
            : "Firma Electrónica Avanzada",
        tags: data_table[i].tags,
      });
    }

    state.total_data_view = digital_signature_for_table.length;

    if (digital_signature_for_table.length > 0) {
      state.flagLoader = true;
    } else {
      state.flagLoader == null;
    }

    state.flagLoader = true;
    state.digital_signature_data = digital_signature_for_table;
  },

  // for documents

  [types.LIST_CATEGORYS](state, payload) {
    state.document_category = payload;
  },

  // TODO: Revisar implicancia de dispatch a continuacion
  // [types.CLEAN_TRACKING](state) {
  //   state.dataDumentToSingFromMB = {};
  // },

  [types.SET_DOCUMENT](state, data) {
    state.dataDumentToSingFromMB = { ...data };
  },

  [types.SET_DOCUMENT_FROM_LOCAL](state, data) {
    state.documentFromLocal = { ...data };
  },
  [types.CLEAN_DOCUMENTS_NAME](state) {
    state.dataDumentToSingFromMB = null;
    state.documentFromLocal = null;
  },

  [types.LIST_DOCUMENTS](state, data) {
    if (data.length > 0) {
      let arrayData = [];

      for (let index = 0; index < data.length; index++) {
        arrayData.push({
          created_at: data[index].created_at,
          document_type: data[index].document_type,
          file_url: data[index].file_url,
          id: data[index].id,
        });
      }

      state.documents = arrayData;
    } else {
      state.documents = null;
    }
  },

  [types.CLEAN_LOAD_DOCUMENT](state) {
    state.loaderDocuments = false;
  },

  [types.DELETE_DIGITAL_SIGNATURE]() {
    state.digital_signature_data = [];
  },
  [types.FLAG_DELETE_DIGITAL_SIGNATURE_ON](state) {
    state.flagDelete = true;
  },
  [types.FLAG_DELETE_DIGITAL_SIGNATURE_OFF](state) {
    state.flagDelete = false;
  },

  [types.CLEAN_DOCUMENTS](state) {
    state.documents = [];
  },
  [types.LOADER_DOCUMENTS_ON](state) {
    state.loaderDocuments = true;
  },
  [types.LOADER_DOCUMENTS_OFF](state) {
    state.loaderDocuments = false;
  },

  // signer from check
  [types.ADD_SIGNER_FROM_CHECK](state, data) {
    state.signerFromCheck = data;
  },
  [types.CLEAN_SIGNER_FROM_CHECK](state) {
    state.signerFromCheck = null;
  },

  // signer initial from interface

  [types.ADD_INITIAL_SIGNER](state, data) {
    state.initialSigner = data;
  },
  [types.CLEAN_INITIAL_SIGNER](state) {
    state.initialSigner = null;
  },

  // another signer
  [types.ADD_SIGNER](state, data) {
    let finalArray = [];

    finalArray = state.signers;
    finalArray.push(data);
    state.signers = finalArray;
  },

  //DRAFT

  [types.ADD_SIGNER_FROM_DRAFT](state, data) {
    state.signerDraft = data;
  },

  [types.CLEAN_FLAG_DRAFT_DATA](state) {
    state.flagDraftData = false;
  },
  [types.SET_FLAG_DRAFT_DATA](state) {
    state.flagDraftData = true;
  },

  [types.CLEAN_SIGNER_FROM_DRAFT](state) {
    state.signerDraft = null;
  },
  [types.DELETE_SIGNER_FROM_DRAFT](state, data) {
    let arrayTemp = state.signerDraft;

    let resultArray = arrayTemp.filter((e) => {
      return e.rut !== data.rut;
    });

    if (resultArray.length <= 0) {
      state.signerDraft = null;
    } else {
      state.signerDraft = resultArray;
    }
  },

  [types.CLEAN_SIGNER](state) {
    state.signers = [];
  },
  [types.MISSING_SIGNER_VALIDATOR_NAME](state, data) {
    state.missingSignerValidatorName = data;
  },
  [types.MISSING_SIGNER_VALIDATOR_EMAIL](state, data) {
    state.missingSignerValidatorEmail = data;
  },
  [types.MISSING_SIGNER_VALIDATOR_RUT](state, data) {
    state.missingSignerValidatorRut = data;
  },

  [types.NEW_TOTAL_SIGNERS](state, data) {
    state.signers = [];
    state.signers = data;
  },
  [types.SET_DOCUMENT_FROM_APP_ACTIVE](state) {
    state.documentFromApp = true;
    // state.flagDraftData = false;
  },
  [types.SET_DOCUMENT_FROM_APP_DESACTIVE](state) {
    state.documentFromApp = false;
  },

  // tracking mutations

  [types.ADD_TRACKING](state, data) {
    state.tracking = { ...data };
    state.flagTracking = true;
  },

  [types.CLEAN_TRACKING](state) {
    state.tracking = {};
    state.flagTracking = false;
  },
  [types.SEND_DOCUMENT_TO_SING]() {},
  [types.SEND_DOCUMENT_TO_SING_FROM_APP]() {},

  // types para modificar el tipo de busqueda desde pre-step

  [types.SET_FILE_URL](state, data) {
    state.urlFileDownload = data;
  },
  [types.CLEAN_FILE_URL](state) {
    state.urlFileDownload = null;
  },
  [types.SET_SEARCH_FINALIZADOS](state) {
    // state.setSearchAll = false;
    state.setSearchPending = false;
    state.setSearchComplete = true;
  },
  [types.SET_SEARCH_ALL](state) {
    state.setSearchAll = true;
    state.setSearchPending = false;
    state.setSearchComplete = false;
  },
  [types.SET_SEARCH_PENDING](state) {
    // state.setSearchAll = false;
    state.setSearchPending = true;
    state.setSearchComplete = false;
  },

  // loader document from app
  [types.LOAD_DOCUMENT_FROM_APP](state) {
    state.loader_documents_from_app = true;
  },
  [types.CANCEL_LOAD_DOCUMENT_FROM_APP](state) {
    state.loader_documents_from_app = false;
  },

  [types.CLEAN_COUNTER__STEP__DIGITAL_ONBOARDING](state) {
    state.stepsDigitalOnBoarding = 1;
  },
  [types.COUNTER__STEP__DIGITAL_ONBOARDING](state, payload) {
    state.stepsDigitalOnBoarding = payload;
  },
};
