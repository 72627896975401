import api from "../../../service/api";
import apiForm from "../../../service/apiFormData";

export default {
  searchCategoryScheduling(admin_company_id, type) {
    return api
      .get(`/api/v1/scheduling_type/${admin_company_id}`, {
        params: {
          type: type != null ? type : null,
        },
      })
      .then((response) => response.data);
  },
  getSchedulingMeetings(
    admin_company_id,
    schedule_date__gte,
    schedule_date__lte,
    schedule_author,
    limit = 3
  ) {
    return api
      .get(`/api/v1/scheduling/${admin_company_id}`, {
        params: {
          schedule_date__gte:
            schedule_date__gte != null ? schedule_date__gte : null,
          schedule_date__lte:
            schedule_date__lte != null ? schedule_date__lte : null,
          schedule_author: schedule_author != null ? schedule_author : null,
          limit: limit != null ? limit : null,
          ordering: "schedule_date",
        },
      })
      .then((response) => response.data);
  },

  createNewScheduling(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/scheduling/${admin_company_id}`, payload)
      .then((response) => response.data);
  },
};
