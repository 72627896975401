import RememberPasswordModule from "./pages/RememberPassword.vue";
import LoginEmpresas from "./pages/LoginEmpresas.vue";
import ConfirmResetPassword from "./pages/ConfirmResetPassword.vue";
import WelcomeUser from "./pages/welcomeUser.vue";
import ConfirmEmailChange from "./pages/confirmEmailChange.vue";
import LoginPageModule from "./pages/LoginPage.vue";
import LoginModule from "./LoginModule.vue";

import ConfirmUpdatePassword from "./pages/ConfirmUpdatePassword.vue";
import oauth_goole from "./pages/oauthGoogle.vue";
import oauth_ms from "./pages/oauthMicrosoft.vue";

export const LoginRoutes = [
  {
    path: "/login",
    component: LoginModule,

    children: [
      {
        path: "/",
        name: "LoginPage",
        component: LoginPageModule,
        meta: { layout: "login" },
      },
      {
        path: "recuperar-password",
        name: "recuperar-password",
        component: RememberPasswordModule,
        meta: { layout: "login" },
      },
      {
        path: "seleccionar-empresas",
        name: "seleccionar-empresa",
        component: LoginEmpresas,
        meta: { layout: "login" },
      },
    ],
  },
  {
    // path: "/recover_password/:some_id/:some_token",
    path: "/recover_password/:some_id/:some_token",
    name: "recover-password",
    props: true,
    component: ConfirmResetPassword,
    meta: { layout: "login" },
  },
  {
    name: "confirmAccount",
    path: "/account_confirm_email/:some_token",
    component: WelcomeUser,
    props: true,
    meta: { layout: "welcome" },
  },
  {
    name: "confirmEmailChange",
    path: "/confirm_email_change/:some_token",
    component: ConfirmEmailChange,
    props: true,
    meta: { layout: "welcome" },
  },
  {
    name: "oauthGoogle",
    path: "/oauth_google",
    component: oauth_goole,
    props: true,
    meta: { layout: "login" },
  },
  {
    name: "oauthMicrosoft",
    path: "/oauth_ms",
    component: oauth_ms,
    props: true,
    meta: { layout: "login" },
  },
  {
    name: "confirmUpdatePassword",
    path: "/confirm_update_password/:some_token",
    component: ConfirmUpdatePassword,
    props: true,
    meta: { layout: "login" },
  },
];
