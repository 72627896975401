import api from "../../../service/api";
import apiForm from "../../../service/apiFormData";

export default {
  requesComputedData() {
    return api
      .get(`/api/v1/compute/`)
      .then((response) => response.data);
  },

  generateDocument(admin_company_id, payload) {
    return apiForm
      .post(`/api/v1/compute/${admin_company_id}/usage_report`, payload)
      .then((response) => response.data);
  },
};
