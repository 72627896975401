<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : size == 'count'
        ? 'modal__counter'
        : 'modal__default'
    "
    id="modal__assitance__remove"
    ref="modal__assitance__remove"
  >
    <div class="title_remove text-filter-light">
      ¿Estás seguro(a) que deseas eliminar la solicitud "

      <span> {{ subject }} ”?</span>
    </div>
    <div class="title_remove text-filter-light">
      Esta acción no se puede revertir
    </div>

    <div class="footer_modal">
      <div
        class="btn-vue-primary fix-btn-mac fix-width"
        @click="deleteSignature(identifier_modal)"
      >
        <span> Si, confirmar </span>
      </div>
      <div disabled="disabled" class="secondary_button" @click="cancelModal()">
        <span> Cancelar</span>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["name_modal", "identifier_modal", "subject", "size"],
  computed: {
    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__assitance__remove");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__assitance__remove");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    async deleteSignature(d) {
      let admin_company_id = this.inSwitch
        ? this.idInSwitch
        : this.admin_company_id;

      let id_assitance = d;

      let payload = {
        admin_company_id,
        id_assitance,
      };

      let payloadAllAssistance = {
        admin_company_id: admin_company_id,
        fields:
          "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
        limit: "5",
      };

      await this.$store
        .dispatch("Assistance/deleteSingleAssitance", payload)
        .then(() => {
          this.$store
            .dispatch("Assistance/getAllAssistance", payloadAllAssistance)
            .then(() => {
              setTimeout(() => {
                this.$router.push({ name: "listRequestIntegrated" });
              }, 1500);
            });
        });
      // let fields =
      //   "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      // let company_id = admin_company_id;

      // this.$bvModal.hide("modal_remove");
    },
  },
};
</script>

<style lang="scss" scoped>
.title_remove > span {
  pointer-events: none;
  font-weight: bold;
  color: var(--content_table_dsignature) !important;
}

.title_remove {
  margin-right: 2rem;
  margin-left: 2rem;
  margin-top: 2rem;
  text-align: center;
  color: var(--content_table_dsignature) !important;
}

.title_remove:first-child {
  margin-bottom: 31px;
}

.fix-width {
  width: 120px !important;
}
</style>
