<template>
  <div class="container_module">
    <ModalConfirmAsisstance
      ref="modal_confirm_asisstance"
      size="modal__filter_v2"
      :actualAssistance="actualAssistance"
    />
    <FilterModalAssistance ref="modal__filter__assistance" size="modal__filter_v2" />

    <div class="container-slots container-base">
      <div class="container__header__assistance">
        <div class="header__assistance__title">Solicitudes</div>
        <div class="container_header_elements_assistance">
          <div class="header__assistance__elements">
            <div class="header__assistance__elements__left">
              <div class="search__assistance">
                <input
                  class="input__search__table"
                  type="text"
                  v-model="dataSearch"
                  placeholder="Buscar aquí"
                  v-on:keyup.enter="searchAssistance()"
                />
              </div>

              <div class="filter__icon__assistance">
                <div class="icon" @click="OpenFilterAssistanceModal()">
                  <FilterIcon />
                </div>
              </div>

              <div class="tags_container">
                <div
                  class=""
                  v-for="(tag, index) in tagsOfFilters"
                  :key="index"
                >
                  <div class="" v-if="tag.status == true">
                    <FilterTagsAssistance
                      :index="index"
                      :id="tag.id"
                      :tag="tag.value"
                      :status="tag.status"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="header__assistance__elements__right">
              <button
                class="btn-vue-primary new_assistance_button color_hover_button_standard"
                @click="createAssistance"
              >
                + Nueva solicitud
              </button>
            </div>
          </div>
          <div class="input_search_support">
            <div
              class="container_filter_input_search input_search_filter_support"
              v-if="dataInputSearchTags"
            >
              <div class="container_elements_tags_support">
                {{ dataInputSearchTags }}
                <CloseIcon
                  class="close_filter_tags_assistance"
                  @click="CloseInputSearchTags()"
                />
              </div>
            </div>

            <div class="" v-else></div>
          </div>
        </div>
        <div class="list_tags_assistance tags_container_mobile">
          <div class="" v-for="(tag, index) in tagsOfFilters" :key="index">
            <FilterTagsAssistance
              :index="index"
              :id="tag.id"
              :tag="tag.value"
              :status="tag.status"
            />
          </div>
        </div>
        <div class="" v-if="!flagActiveResult">
          <div class="container_assistance_module" v-if="!skeletonFilter">
            <div
              v-for="(assistance, index) in assistance_data_from_fetch"
              :key="index"
            >
              <CardAssistance :assistance="assistance" />
            </div>
          </div>

          <div class="container_assistance_module" v-else>
            <div
              v-for="(assistance, index) in assistance_data_from_fetch"
              :key="index"
            >
              <ListAssistanceSkeleton />
            </div>
          </div>
        </div>
        <div class="message_no_data message_empty_data text-container" v-else>
          No hay resultados con esa búsqueda
        </div>
        <div class="footer_digital_signature">
          <div class="per_page">
            Visualizar por página:

            <select
              v-model="selected_quantity"
              @change="selectQuantity($event)"
            >
              <option
                v-for="(option, index) in amount_of_rows"
                :key="index"
                :value="option"
              >
                {{ index }}
              </option>
            </select>
          </div>
          <div class="btn_pagination_digital_signature">
            <firstPage
              :class="page == 1 ? 'blockPagination' : ''"
              @click="setInitialPage()"
            />
            <leftArrow
              class="leftArrow"
              :class="page == 1 ? 'blockPagination' : ''"
              @click="prevPagination()"
            />
            <rightArrow
              :class="page === totalPages ? 'blockPagination' : 'itemClick'"
              @click="nextPagination()"
            />
            <lastPage v-if="nextPage != null" @click="setLastPage()" />
            <lastPage v-else class="blockPagination" />
          </div>
          <div class="text_view_digital_signature">
            Visualizando {{ page }} de {{ totalPages }} páginas
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// modal
import ModalConfirmAsisstance from "../components/ConfirmAssistanceModal.vue";
import CardAssistance from "../components/cardAssistance.vue";
import FilterIcon from "../../../assets/iconos_container/filters.svg";
// pagination
import firstPage from "../../../assets/iconos_container/firstpage.svg";
import leftArrow from "../../../assets/iconos_container/left.svg";
import lastPage from "../../../assets/iconos_container/lastpage.svg";
import rightArrow from "../../../assets/iconos_container/right.svg";
// modal
import FilterModalAssistance from "../components/FilterModalAssistance.vue";
// skeleton
import ListAssistanceSkeleton from "../components/Skeleton/ListAssistanceSkeleton.vue";
// tagss for filter
import FilterTagsAssistance from "../components/FilterTagsAssistance.vue";
import CloseIcon from "../assets/close-input-search.svg";
export default {
  components: {
    ModalConfirmAsisstance,
    CardAssistance,
    FilterIcon,
    firstPage,
    leftArrow,
    lastPage,
    rightArrow,
    FilterModalAssistance,
    ListAssistanceSkeleton,
    FilterTagsAssistance,
    CloseIcon,
  },
  created() {
    this.selectedtagService = this.initialFilters;
    this.$store.dispatch("Assistance/desactiveEditMode");
    this.$store.dispatch("Assistance/cleanCurrentAsistance");
    this.offset = null;
    //flagData nos indica si pillamos registros en la tabla
    //notFoundData es flag para front para presentar "no hay data disponible"
    this.notFoundData = this.flagData;

    if (this.total_data_view <= 5) {
      this.selected_quantity = "5";
    } else {
      this.selected_quantity = this.total_data_view;
    }

    this.assistance_data_from_fetch = this.fetchListAssistance;

    this.actualAssistance = this.assistance_data_from_fetch[0];

    this.sortedFlag = false;

    if (this.prevPage != null) {
      this.setInitialPage();
    }

    if (this.flagActiveResult) {
      this.page = 0;
    }
  },

  mounted() {
    // In the case the value of modalConfirmationState is false , this open the modal confirmation and then set the value in true.
    if (!this.modalConfirmationState) {
      this.openConfirmModal();
      this.$store.dispatch("Assistance/setTrueAssitance");
    }
  },

  data: () => {
    return {
      assistance_data_from_fetch: [],
      sortedFlag: false,
      selected_quantity: "5",
      amount_of_rows: {
        5: 5,
        10: 10,
        15: 15,
      },
      total: 5,
      page: 1,
      limit: "5",
      offset: null,
      notFoundData: false,
      dataSearch: "",
      actualAssistance: null,
      loaderSkeleton: false,
      typeServiceFilter: false,
      dateFilter: false,
      requestFilter: false,
      noResultsflag: false,
      tagsActive: false,
      selectedtagService: null,
    };
  },
  computed: {
    ...mapGetters("Assistance", {
      fetchListAssistance: "fetchListAssistance",
      loaderAssistance: "loaderAssistance",
      total_data: "total_data",
      total_data_view: "total_data_view",
      nextPage: "nextPage",
      prevPage: "prevPage",
      flagData: "flagData",
      totalPages: "totalPages",
      modalConfirmationState: "modalConfirmationState",
      tagsOfFilters: "tagsOfFilters",
      skeletonFilter: "skeletonFilter",
      flagActiveFilter: "flagActiveFilter",
      flagActiveResult: "flagActiveResult",
      initialFilters: "initialFilters",
      flagActiveFilters: "flagActiveFilters",
      dataActiveFilters: "dataActiveFilters",
      flagActiveInputSearch: "flagActiveInputSearch",
      dataInputSearchTags: "dataInputSearchTags",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  methods: {
    openConfirmModal() {
      let param = "modal_confirm_asisstance";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal_confirm_asisstance;
      element.open();
    },

    OpenFilterAssistanceModal() {
      let param = "modal__filter__assistance";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__filter__assistance;
      element.open();
    },

    //methods for pagination

    setInitialPage() {
      /*--------------------------------
 @Input: First page of the list (this.page = 1)
 Description: We evaluate differente values (flagActiveInputSearch (if input search is in use) , tagsOfFilters(if modal filter is in use)) with this we send diferent payloads to the service
 @Output: payloads for service with the initial page
//--------------------------------*/

      // this.$store.dispatch("DigitalSignature/loadDocumentOn");
      this.loaderSkeleton = false;
      let limit = this.limit;
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      this.page = 1;

      // we evaluate the case is a filter tag in use with the addition of a input search
      if (this.flagActiveInputSearch != false && this.tagsOfFilters != null) {
        let display_id =
          this.dataActiveFilters[0] != null ? this.dataActiveFilters[0] : null;
        let title__icontains =
          this.dataActiveFilters[1] != null ? this.dataActiveFilters[1] : null;

        let service_type = null;
        let created_at__date__range = null;
        let requester = null;

        if (this.tagsOfFilters != null) {
          if (this.tagsOfFilters.length > 0) {
            this.initialFilters.map((el, index) => {
              if (index == 0) {
                service_type = el.status ? el.id : null;
              }
              if (index == 1) {
                created_at__date__range = el.status ? el.id : null;
              }
              if (index == 2) {
                requester = el.status ? el.id : null;
              }
            });
          }
        }
        this.$store
          .dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: company_id,
            display_id,
            title__icontains,
            service_type,
            created_at__date__range,
            requester,
            limit: limit,
          })
          .then(() => {
            this.loaderSkeleton = true;
          });
        // we evaluate "flagActiveInputSearch" this indicate there is a input search filter active
      } else if (this.flagActiveInputSearch != false) {
        let display_id =
          this.dataActiveFilters[0] != null ? this.dataActiveFilters[0] : null;
        let title__icontains =
          this.dataActiveFilters[1] != null ? this.dataActiveFilters[1] : null;

        this.$store
          .dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: company_id,
            display_id,
            title__icontains,
            service_type: null,
            created_at__date__range: null,
            requester: null,
            limit: limit,
          })
          .then(() => {
            this.loaderSkeleton = true;
          });
        // we evaluate "flagActiveFilters" this indicate there is a filter tags  active
      } else if (this.flagActiveFilters) {
        let payload = {
          admin_company_id: company_id,
          service_type:
            this.selectedtagService[0].status != false
              ? this.selectedtagService[0].id
              : null,
          created_at__date__range:
            this.selectedtagService[1].status != false
              ? this.selectedtagService[1].id
              : null,
          requester:
            this.selectedtagService[2].status != false
              ? this.selectedtagService[2].id
              : null,
          limit: limit,
        };
        this.$store
          .dispatch("Assistance/getAssistanceWithFilters", payload)
          .then(() => {
            this.loaderSkeleton = true;
          });
      } else {
        let payload = {
          admin_company_id: company_id,
          fields:
            "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
          limit: limit,
        };

        this.$store
          .dispatch("Assistance/getAllAssistance", payload)
          .then(() => {
            this.loaderSkeleton = true;
          });
      }
    },
    setLastPage() {
      /*--------------------------------
 @Input: Last page of the list (this.page = this.totalPages)
 Description: We evaluate differente values (flagActiveInputSearch (if input search is in use) , tagsOfFilters(if modal filter is in use)) with this we send diferent payloads to the service
 @Output: payloads for service with the last page
//--------------------------------*/

      // this.$store.dispatch("DigitalSignature/loadDocumentOn");
      this.loaderSkeleton = false;
      let limit = this.limit;

      if (this.offset == 0) {
        this.offset = 5 * this.totalPages;
      } else {
        this.offset = this.totalPages * 5 - 5;
      }

      let offset = this.offset;

      this.page = this.totalPages;
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      // we evaluate the case is a filter tag in use with the addition of a input search
      if (this.flagActiveInputSearch != false && this.tagsOfFilters != null) {
        let display_id =
          this.dataActiveFilters[0] != null ? this.dataActiveFilters[0] : null;
        let title__icontains =
          this.dataActiveFilters[1] != null ? this.dataActiveFilters[1] : null;

        let service_type = null;
        let created_at__date__range = null;
        let requester = null;

        if (this.tagsOfFilters != null) {
          if (this.tagsOfFilters.length > 0) {
            this.initialFilters.map((el, index) => {
              if (index == 0) {
                service_type = el.status ? el.id : null;
              }
              if (index == 1) {
                created_at__date__range = el.status ? el.id : null;
              }
              if (index == 2) {
                requester = el.status ? el.id : null;
              }
            });
          }
        }
        this.$store
          .dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: company_id,
            display_id,
            title__icontains,
            service_type,
            created_at__date__range,
            requester,
            limit: limit,
            offset: offset,
          })
          .then(() => {
            this.loaderSkeleton = true;
          });
        // we evaluate "flagActiveInputSearch" this indicate there is a input search filter active
      } else if (this.flagActiveInputSearch != false) {
        let display_id =
          this.dataActiveFilters[0] != null ? this.dataActiveFilters[0] : null;
        let title__icontains =
          this.dataActiveFilters[1] != null ? this.dataActiveFilters[1] : null;

        this.$store
          .dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: company_id,
            display_id,
            title__icontains,
            service_type: null,
            created_at__date__range: null,
            requester: null,
            limit: limit,
            offset: offset,
          })
          .then(() => {
            this.loaderSkeleton = true;
          });
        // we evaluate "flagActiveFilters" this indicate there is a filter tags  active
      } else if (this.flagActiveFilters) {
        let payload = {
          admin_company_id: company_id,
          service_type:
            this.selectedtagService[0].status != false
              ? this.selectedtagService[0].id
              : null,
          created_at__date__range:
            this.selectedtagService[1].status != false
              ? this.selectedtagService[1].id
              : null,
          requester:
            this.selectedtagService[2].status != false
              ? this.selectedtagService[2].id
              : null,
          limit: limit,
          offset: offset,
        };
        this.$store
          .dispatch("Assistance/getAssistanceWithFilters", payload)
          .then(() => {
            this.loaderSkeleton = true;
          });
      } else {
        let payload = {
          admin_company_id: company_id,
          fields:
            "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
          limit: limit,
          offset: offset,
        };

        this.$store
          .dispatch("Assistance/getAllAssistance", payload)
          .then(() => {
            this.loaderSkeleton = true;
          });
      }
    },
    nextPagination() {
      this.loaderSkeleton = false;
      let nextUrl = this.nextPage;

      this.page = this.page + 1;
      // this.assistance_data_from_fetch = [];
      this.$store
        .dispatch("Assistance/nextPagination", {
          nextUrl,
        })
        .then(() => {
          setTimeout(() => {
            this.loaderSkeleton = true;
          }, 1000);
        });
    },
    prevPagination() {
      this.loaderSkeleton = false;
      let nextUrl = this.prevPage;
      this.page = this.page - 1;

      this.$store
        .dispatch("Assistance/nextPagination", {
          nextUrl,
        })
        .then(() => {
          this.loaderSkeleton = true;
        });
    },
    selectQuantity(evt) {
      /*--------------------------------
 @Input: Quantity selected (5,10,15) (let limit = evt.target.value;)
 Description: We evaluate differente values (flagActiveInputSearch (if input search is in use) , tagsOfFilters(if modal filter is in use)) with this we send diferent payloads to the service
 @Output: payloads for service with the limit modify (5 or 10 or 15)
//--------------------------------*/

      this.loaderSkeleton = false;
      let limit = evt.target.value;

      // if (limit != 10) {
      //   this.offset = 0;
      // }
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      // total esta definido como una constante en 10

      this.page = 1;

      this.limit = limit;
      this.selected_quantity = evt.target.value;
      // we evaluate the case is a filter tag in use with the addition of a input search
      if (this.flagActiveInputSearch != false && this.tagsOfFilters != null) {
        let display_id =
          this.dataActiveFilters[0] != null ? this.dataActiveFilters[0] : null;
        let title__icontains =
          this.dataActiveFilters[1] != null ? this.dataActiveFilters[1] : null;

        let service_type = null;
        let created_at__date__range = null;
        let requester = null;

        if (this.tagsOfFilters != null) {
          if (this.tagsOfFilters.length > 0) {
            this.initialFilters.map((el, index) => {
              if (index == 0) {
                service_type = el.status ? el.id : null;
              }
              if (index == 1) {
                created_at__date__range = el.status ? el.id : null;
              }
              if (index == 2) {
                requester = el.status ? el.id : null;
              }
            });
          }
        }
        this.$store
          .dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: company_id,
            display_id,
            title__icontains,
            service_type,
            created_at__date__range,
            requester,
            limit: limit,
          })
          .then(() => {
            this.loaderSkeleton = true;
          });
        // we evaluate "flagActiveInputSearch" this indicate there is a input search filter active
      } else if (this.flagActiveInputSearch != false) {
        let display_id =
          this.dataActiveFilters[0] != null ? this.dataActiveFilters[0] : null;
        let title__icontains =
          this.dataActiveFilters[1] != null ? this.dataActiveFilters[1] : null;

        this.$store
          .dispatch("Assistance/inputSearchAssistance", {
            admin_company_id: company_id,
            display_id,
            title__icontains,
            service_type: null,
            created_at__date__range: null,
            requester: null,
            limit: limit,
          })
          .then(() => {
            this.loaderSkeleton = true;
          });
        // we evaluate "flagActiveFilters" this indicate there is a filter tags  active
      } else if (this.flagActiveFilters) {
        let payload = {
          admin_company_id: company_id,
          service_type:
            this.selectedtagService[0].status != false
              ? this.selectedtagService[0].id
              : null,
          created_at__date__range:
            this.selectedtagService[1].status != false
              ? this.selectedtagService[1].id
              : null,
          requester:
            this.selectedtagService[2].status != false
              ? this.selectedtagService[2].id
              : null,
          limit: limit,
        };
        this.$store
          .dispatch("Assistance/getAssistanceWithFilters", payload)
          .then(() => {
            this.loaderSkeleton = true;
          });
      } else {
        let payload = {
          admin_company_id: company_id,
          fields:
            "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
          limit: limit,
        };

        this.$store
          .dispatch("Assistance/getAllAssistance", payload)
          .then(() => {
            this.loaderSkeleton = true;
          });
      }
    },
    //use search to new request of assistance specifict

    async searchAssistance() {
      /*--------------------------------
       @Input: Value from Input search
       Description: We evaluate the input value with Number() and we change the payload in case of the result (Back-end
                    requirements , number = display_id payload , "Not Number" = title__icontains payload)
       @Output: payload for the service
      //--------------------------------*/

      // we save the value of the input search
      let filterFromInput = this.dataSearch;

      let service_type = null;
      let created_at__date__range = null;
      let requester = null;

      if (this.dataSearch == "") {
        this.$store.dispatch("Assistance/setInactiveInputSearchTags");
      } else {
        this.$store.dispatch(
          "Assistance/setActiveInputSearchTags",
          this.dataSearch
        );
      }

      if (this.tagsOfFilters != null) {
        if (this.tagsOfFilters.length > 0) {
          this.initialFilters.map((el, index) => {
            if (index == 0) {
              service_type = el.status ? el.id : null;
            }
            if (index == 1) {
              created_at__date__range = el.status ? el.id : null;
            }
            if (index == 2) {
              requester = el.status ? el.id : null;
            }
          });
        }
      }

      //we evluate with the function Number() if the value is a number or not
      let evaluarInput = Number(filterFromInput);

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      this.page = 1;
      this.selected_quantity = "5";

      // in case the value is a number we dispatch de "display id" payload
      if (evaluarInput) {
        // we save the value from the search in variable to send to the payload
        let display_id = filterFromInput;
        await this.$store.dispatch("Assistance/inputSearchAssistance", {
          admin_company_id,
          display_id,
          service_type,
          created_at__date__range,
          requester,
        });
        // in case the value is not a number we dispatch de "title contains" payload
      } else {
        // we save the value from the search in variable to send to the payload
        let title__icontains = filterFromInput;
        await this.$store.dispatch("Assistance/inputSearchAssistance", {
          admin_company_id,
          title__icontains,
          service_type,
          created_at__date__range,
          requester,
        });
      }

      this.dataSearch = null;
    },
    //route to view create assistance
    createAssistance() {
      this.$router.push({ name: "requestAsistencia" });
    },
    CloseInputSearchTags() {
      let service_type = null;
      let created_at__date__range = null;
      let requester = null;

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      if (this.tagsOfFilters != null) {
        if (this.tagsOfFilters.length > 0) {
          this.initialFilters.map((el, index) => {
            if (index == 0) {
              service_type = el.status ? el.id : null;
            }
            if (index == 1) {
              created_at__date__range = el.status ? el.id : null;
            }
            if (index == 2) {
              requester = el.status ? el.id : null;
            }
          });
        }
        let payload = {
          admin_company_id: admin_company_id,
          service_type: service_type,
          created_at__date__range: created_at__date__range,
          requester: requester,
        };
        this.$store.dispatch("Assistance/setInactiveInputSearchTags");
        this.$store.dispatch("Assistance/getAssistanceWithFilters", payload);
      } else {
        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company_id;
        let payload = {
          admin_company_id: admin_company_id,
          fields:
            "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
          limit: "5",
        };
        this.$store.dispatch("Assistance/setInactiveInputSearchTags");
        this.$store.dispatch("Assistance/getAllAssistance", payload);
      }
    },
  },
  watch: {
    flagData() {
      this.notFoundData = this.flagData;
    },
    fetchListAssistance() {
      // this.loader = true;

      this.assistance_data_from_fetch = [];
      // TODO:REVISION DE VALORES DE ESTADO CON FILTROS DE ESTADO (ESPERANDO EL SERVICIO)
      this.assistance_data_from_fetch = this.fetchListAssistance;
    },
    initialFilters() {
      this.selectedtagService = this.initialFilters;
    },
    // this puts the "page" in 0 of 0 if there are not results in the list
    flagActiveResult() {
      if (this.flagActiveResult) {
        this.page = 0;
      } else {
        this.page = 1;
      }
    },
  },
};
</script>

<style lang="scss">
.container_assistance_module {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container__header__assistance {
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
  color: var(--title_pre_step);
}

.header__assistance__elements {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__left {
    display: flex;
    gap: 13px;

    > .search__assistance {
      > .input__search__table {
        color: var(--text_item_selection_company) !important;
      }
    }

    > .filter__icon__assistance {
      display: flex;
      gap: 5px;
      color: var(--title_container_company) !important;
      align-items: center;

      > .icon > svg {
        cursor: pointer;
        width: 32px;
        height: 32px;

        fill: var(--text_link_company) !important;
      }
    }
  }

  &____right {
    button {
      width: 141px !important;
      height: 32px !important;
    }
  }
}
.header__assistance__elements__left {
  align-items: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 100% !important;
    justify-content: space-between;
  }
}

.filter_text_title {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: none;
  }
}
.header__assistance__elements__right {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
.container__assistance {
  color: var(--title_pre_step);

  // height: 110px;
  border: 1px solid #9c9c9c;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 19px;
  &__left,
  &__right {
    margin-top: 16px;
  }

  &__left {
    margin-left: 16px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 23px;
    > .base__information {
      display: flex;
      gap: 1rem;
      @media screen and (max-width: 500px) and (min-width: 200px) {
        flex-wrap: wrap;
      }
      > .base__information__labels {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 30%;
        @media screen and (max-width: 500px) and (min-width: 200px) {
          width: 100% !important;
        }
      }
    }
  }

  &__right {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 21px;
    align-items: flex-end;
    justify-content: center;
    margin-right: 12px;
    @media screen and (max-width: 500px) and (min-width: 200px) {
      justify-content: start !important;
    }

    > .number__case__skeleton {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      > .number__case__title {
        text-align: right;
      }
    }

    > .float__button__card {
      cursor: pointer;
      padding: 0.2rem;
      display: flex;
      border: 1px solid #464646;
      border-radius: 22px;

      > svg {
        transform: scale(1.3);
        width: 26px;
        height: 23px;
        cursor: pointer;
        margin-bottom: 0.1rem;
        fill: var(--title_pre_step);
      }
    }
  }
}
.base__information__labels {
  > .label-primary {
    color: var(--text_profile) !important;
    font-size: 12px;
  }
  > .value-primary {
    color: var(--text_profile) !important;
    font-size: 14px;
  }
}
.list_tags_assistance {
  display: flex;
  width: fit-content;
  gap: 1rem;
  align-items: center;
}
.item_tag {
  background: #f1f1f1;
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 4px;
  color: #464646;
  padding: 0.1rem 0.3rem;
  font-size: 14px;
}
.name__tags {
  background: #f1f1f1;
  color: #464646;
  padding: 5px 10px;
  font-weight: bold;
}

.close_tags_container {
  position: relative;
}
.close__tags {
  background: black;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -15%;
  right: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.2rem;
  color: white;
  font-size: 10px;
  cursor: pointer;

  &:hover {
    background-color: #0064ff;
    color: white;
  }
}

.tags_container {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: none;
  }
  display: flex;
  gap: 1rem;
}
.tags_container_mobile {
  @media screen and (max-width: 5000px) and (min-width: 501px) {
    display: none !important;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.input_search_support {
  width: 50% !important;
}
.input_search_filter_support {
  color: var(--title_profile) !important;
  font-size: 14px !important;
  background-color: #bfd8ff;
  fill: solid;
  border-radius: 4px;
  height: auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}
.container_elements_tags_support {
  margin: 0rem 1rem 0rem 1rem;
}
</style>
