<template>
  <div class="">
    <CancelModal ref="modal__cancel__creation__employee" size="ls" />

    <div class="container_edit_employee">
      <div class="left-side-edit">
        <div class="field_edit_employee">
          <div class="label-primary">Fecha de ingreso a la empresa</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="date"
              name=""
              v-model="user_profile.company_ingress_date"
            />
          </div>
        </div>
        <div class="field_edit_employee">
          <div class="label-primary">Jornada de trabajo</div>
          <div class="custom-select">
            <select class="input-form" v-model="user_profile.working_hours">
              <option
                v-for="(workingHours, index) in working_hours"
                :key="index"
                :value="workingHours.value"
              >
                {{ workingHours.display_name }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>
        <div class="field_edit_employee">
          <div class="label-primary">Profesión u oficio</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.occupation"
            />
          </div>
        </div>
        <div class="field_edit_employee">
          <div class="label-primary">Formato de trabajo</div>
          <div class="custom-select">
            <select class="input-form" v-model="user_profile.job_type">
              <option
                v-for="(jobType, index) in job_type"
                :key="index"
                :value="jobType.value"
              >
                {{ jobType.display_name }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>
        <div class="field_edit_employee">
          <div class="label-primary">Responsabilidades asociadas al cargo</div>
          <div class="value-primary fix__empty__field">
            <textarea
              class="text_area_edit_employee input-form"
              type="text"
              cols="20"
              rows="10"
              maxlength="332"
              v-model="user_profile.position_responsabilities"
            />
          </div>
        </div>
        <div class="field_edit_employee" v-if="typeUserValidation == 'Usuario'">
          <div class="label-primary">
            Rol en la app <span class="error__required">*</span>
          </div>
          <div class="custom-select">
            <select
              class="input-form"
              :class="!group_required ? '' : 'error_field_required'"
              v-model="user_profile.group"
              @change="setValidatorClear('group')"
            >
              <option
                v-for="(group, index) in group_list"
                :key="index"
                :value="group.group_name"
              >
                {{ group.fantasy }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>
      </div>
      <!-- <div class="line-primary-v2 mobile__valid" v-if="mobileView"></div> -->

      <div class="right-side-edit">
        <div class="field_edit_employee">
          <div class="label-primary">Tipo de contrato</div>
          <div class="custom-select">
            <select class="input-form" v-model="user_profile.contract_type">
              <option
                v-for="(contracType, index) in contract_type"
                :key="index"
                :value="contracType.value"
              >
                {{ contracType.display_name }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Remuneración Bruta</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.salary"
              maxlength="11"
              @keyup="formatCurrency(user_profile)"
            />
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Nombre del cargo en la empresa</div>
          <div class="value-primary fix__empty__field">
            <input
              class="input-form input_edit_employee"
              type="text"
              name=""
              v-model="user_profile.position"
            />
          </div>
        </div>

        <div class="field_edit_employee">
          <div class="label-primary">Previsión de salud</div>
          <div class="custom-select">
            <select
              class="input-form"
              v-model="user_profile.health_insurance_id"
            >
              <option
                v-for="(healthInsurance, index) in health_insurance"
                :key="index"
                :value="healthInsurance.id"
              >
                {{ healthInsurance.name }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>
        <div class="field_edit_employee">
          <div class="label-primary">AFP</div>
          <div class="custom-select">
            <select
              class="input-form"
              v-model="user_profile.social_security_id"
            >
              <option
                v-for="(socialSecurity, index) in social_security"
                :key="index"
                :value="socialSecurity.id"
              >
                {{ socialSecurity.name }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>
        <div class="field_edit_employee">
          <div class="label-primary">
            Representante legal<span class="error__required">*</span>
          </div>
          <div class="custom-select">
            <select
              class="input-form"
              :class="
                !is_legal_representative_required ? '' : 'error_field_required'
              "
              v-model="user_profile.is_legal_representative"
              @change="setValidatorClear('is_legal_representative')"
            >
              <option
                v-for="(el, index) in is_legal_representative_options"
                :key="index"
                :value="el"
              >
                {{ index }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__profile__employees">
      <div class="edit__user" @click="backStep">Volver</div>
      <div class="footer__double__button">
        <div class="edit__user__secondary" @click="CancelCreationEmployee()">
          Cancelar
        </div>
        <div class="edit__user__continue" @click="nextStep">
          Guardar y continuar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CancelModal from "../modals/ModalCancelCreationEmployee.vue";
import employeeServiceV2 from "../../services/employeService";

export default {
  components: {
    CancelModal,
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      stepEmployees: "stepEmployees",
      listHealthInsurance: "listHealthInsurance",
      listSocialSecurity: "listSocialSecurity",
      listOptionUserProfile: "listOptionUserProfile",
      baseInformationCompany: "baseInformationCompany",
      getCurrentEmployeeSelected: "getCurrentEmployeeSelected",
      typeUserCompanyId: "typeUserCompanyId",
      typeUser: "typeUser",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),

    ...mapGetters("Login", {
      user_profile_data: "user_profile",
    }),
  },
  created() {
    this.typeUserValidation = this.typeUser;

    if (this.inSwitch) {
      this.groupList(this.idInSwitch);
    } else if (this.typeUserCompanyId != null) {
      this.groupList(this.typeUserCompanyId);
    } else {
      this.groupList(this.user_profile_data.company.id);
    }

    this.user_profile = this.baseInformationCompany;

    this.contract_type = this.listOptionUserProfile.contract_type;
    this.job_type = this.listOptionUserProfile.job_type;
    this.working_hours = this.listOptionUserProfile.working_hours;

    // set the list of values for the fields AFP,Prevision de salud
    this.social_security = this.listSocialSecurity;
    this.health_insurance = this.listHealthInsurance;
  },
  data: () => {
    return {
      user_profile: {
        position: null,
        company_ingress_date: null,
        group: null,
        occupation: null,
        is_legal_representative: null,
        contract_type: null,
        salary: null,
        job_type: null,
        working_hours: null,
        health_insurance_id: null,
        social_security_id: null,
        position_responsabilities: null,
        typeUserValidation: "",
      },
      group_list: [],
      social_security: [],
      health_insurance: [],
      job_type: [],
      contract_type: [],
      working_hours: [],
      is_legal_representative_options: {
        Si: true,
        No: false,
      },
      is_legal_representative_required: false,
      group_required: false,
    };
  },
  methods: {
    CancelCreationEmployee() {
      let param = "modal__cancel__creation__employee";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__cancel__creation__employee;
      element.open();
    },
    backStep() {
      this.$store
        .dispatch(
          "EmployeesV2/saveBaseUserInformationCompany",
          this.user_profile
        )
        .then(() => {
          let decrement = this.stepEmployees - 1;
          this.$store.dispatch("EmployeesV2/counterStepEmployee", decrement);
        });
    },
    nextStep() {
      let validtorFlow = this.setValidatorClear("all");
      if (!validtorFlow) {
        if (this.user_profile.salary != null) {
          if (this.user_profile.salary.length <= 1) {
            this.user_profile.salary = null;
          } else {
            this.user_profile.salary = this.user_profile.salary.replace(
              /[^0-9]+/g,
              ""
            );
          }
        }
    if (this.typeUserValidation == "Miembro") {
       this.user_profile.group = "Company-Employee"
      }
        this.$store
          .dispatch(
            "EmployeesV2/saveBaseUserInformationCompany",
            this.user_profile
          )
          .then(() => {
            let increment = this.stepEmployees + 1;
            this.$store.dispatch("EmployeesV2/counterStepEmployee", increment);
          });
      }
    },

    groupList(company_id) {
      // let currentUserIdCompany = this.inSwitch
      //   ? this.idInSwitch
      //   : this.user_profile_data.company.id;

      employeeServiceV2
        .employee_groups(company_id)
        .then((data) => {
          let dataList = data.results;

          dataList.map((item) => {
            let ejecutivo =
              item.group_name == "Lawyer"
                ? "Abogado"
                : item.group_name == "Admin"
                ? "Administrador"
                : item.group_name == "Agent"
                ? "Agente"
                : item.group_name == "Company-Executive"
                ? "Ejecutivo"
                : item.group_name == "Company-Admin"
                ? "Administrador"
                : item.group_name == "Company-Employee"
                ? "Empleado"
                : item.group_name == "Company-Entel"
                ? "Usuario Entel"
                : "";

            item.fantasy = ejecutivo;
          });
          this.group_list = data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setValidatorClear(name) {
      let validatorGroup = this.user_profile.group != null ? false : true;
      let validatorIsLegalRepres =
        this.user_profile.is_legal_representative != null ? false : true;

      if (this.typeUserValidation == "Miembro") {
        validatorGroup = false;
      }

      if (name == "group") {
        this.group_required = validatorGroup;
      }
      if (name == "is_legal_representative") {
        this.is_legal_representative_required = validatorIsLegalRepres;
      }
      if (name == "all") {
        this.group_required = validatorGroup;
        this.is_legal_representative_required = validatorIsLegalRepres;

        if (validatorGroup || validatorIsLegalRepres) {
          return true;
        } else {
          return false;
        }
      }
    },
    formatCurrency(user_profile) {
      let currency = user_profile.salary.replace(/\./g, "");

      let cleanSymbol = currency.replace(/[$]/g, "");

      cleanSymbol = cleanSymbol
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      cleanSymbol = cleanSymbol
        .split("")
        .reverse()
        .join("")
        .replace(/^[.]/, "");

      user_profile.salary = `$${cleanSymbol}`;
    },
  },
};
</script>

<style></style>
