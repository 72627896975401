export default {
  //company_data: contenedor viejo de resultado de peticion api
  company_data: [],
  company_user: [],
  fetchCompanys: false,
  fetchUsersCompanys: false,
  errorCompanys: false,
  errorUsers: false,
  single_company: null,
  errorSelectedCompany: false,
  table_user_company_admin: [],
  tags: false,
  tagsForSwitch: null,
  // new states in refactor module
  company_data_table: [],
  total_data: null,
  total_data_view: null,
  nextPage: "",
  prevPage: null,
  flagData: null,
  loaderCompanys: false,
  // valores para reiniciar el filter en admin
  selectedCountry: null,
  selectedRegion: null,
  flagInitialReset: false,
  initialEconomicCategory: null,
  finalDataEconomic: null,
  economicCategoryForService: [],
  currentSelectCompanyFlag: false,
  flagAdminForViewSingleCompany: false,
  flagFilterMobile: false
};
