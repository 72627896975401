export const FETCH_COMPANY_DATA = "FETCH_COMPANY_DATA";
export const FETCH_COMPANYS_FAILURE = "FETCH_COMPANYS_FAILURE";

export const FETCH_USER_COMPANY_DATA = "FETCH_USER_COMPANY_DATA";
export const FETCH_USER_COMPANY_FAILURE = "FETCH_USER_COMPANY_FAILURE";

export const GET_COMPANY_SELECTED_DATA = "GET_COMPANY_SELECTED_DATA";
export const GET_COMPANY_SELECTED_FAILURE = "GET_COMPANY_SELECTED_FAILURE";

export const UPDATE_STATE_COUNTRY = "UPDATE_STATE_COUNTRY";

// new types

export const SET_FLAG = "SET_FLAG";
export const SET_TOTAL_DATA = "SET_TOTAL_DATA";
export const SET_TOTAL_DATA_VIEW = "SET_TOTAL_DATA_VIEW";
export const SET_PREV_PAGE = "SET_PREV_PAGE";
export const SET_NEXT_PAGE = "SET_NEXT_PAGE";


export const LOADER_COMPANY_OFF = "LOADER_COMPANY"
export const LOADER_COMPANY_ON = "LOADER_COMPANY_ON"

// tipos para selected region in filter component
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY"
export const SET_SELECTED_REGION = "SET_SELECTED_REGION"
export const SET_INITIAL_ECONOMIC_CATEGORY= "SET_INITIAL_ECONOMIC_CATEGORY"
export const FLAG_RESET_INITIAL_ECONOMIC_CATEGORY= "FLAG_RESET_INITIAL_ECONOMIC_CATEGORY"
export const FLAG_RESET_INITIAL_ECONOMIC_CATEGORY_RETURN= "FLAG_RESET_INITIAL_ECONOMIC_CATEGORY_RETURN"
export const RESET_ACTIVITY_ECONOMIC= "RESET_ACTIVITY_ECONOMIC"
export const RESET_DATA_FOR_ECONOMIC_SERVICE= "RESET_DATA_FOR_ECONOMIC_SERVICE"
export const SET_DATA_FOR_ECONOMIC_SERVICE= "SET_DATA_FOR_ECONOMIC_SERVICE"
export const PUSH_DATA_FOR_ECONOMIC_SERVICE= "PUSH_DATA_FOR_ECONOMIC_SERVICE"
//data final para el service
export const SET_ECONOMIC_SERVICE= "SET_ECONOMIC_SERVICE"


// types for TAGS
export const SET_TAGS_FOR_SWITCH= "SET_TAGS_FOR_SWITCH"
export const SET_TAGS_TRUE= "SET_TAGS_TRUE"
export const SET_TAGS_FALSE= "SET_TAGS_FALSE"

//Flag for hide selected options elements from table
export const SET_FLAGS_SELECTED_OPTIONS= "SET_FLAGS_SELECTED_OPTIONS"

export const CLEAN_SINGLE_COMPANY= "CLEAN_SINGLE_COMPANY"

// tag validador para verificar si somos admin de mis abogados para ver una empresa en particular

export const VIEW_SINGLE_COMPANY= "VIEW_SINGLE_COMPANY"
export const CLEAN_VIEW_SINGLE_COMPANY= "CLEAN_VIEW_SINGLE_COMPANY"

// For view filters in mobile version
export const SET_FILTER_COMPANY_MOBILE= "SET_FILTER_COMPANY_MOBILE"
export const CLEAN_FILTER_COMPANY_MOBILE= "CLEAN_FILTER_COMPANY_MOBILE"




