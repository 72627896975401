<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'onboarding'
        ? 'modal__onboarding'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    id="modal__onboarding__assistance"
    ref="modal__onboarding__assistance"
  >
    <div class="">
      <div class="">
        <Transition v-if="stepAssistanceOnBoarding == 1" name="fade">
          <OnBoardingFirstStepAssistance />
        </Transition>
        <Transition v-if="stepAssistanceOnBoarding == 2" name="fade">
          <OnBoardingSecondStepAssistance />
        </Transition>
        <Transition v-if="stepAssistanceOnBoarding == 3" name="fade">
          <OnBoardingThirdStepAssistance />
        </Transition>
        <Transition v-if="stepAssistanceOnBoarding == 4" name="fade">
          <OnBoardingFourthStepAssistance />
        </Transition>
        <Transition v-if="stepAssistanceOnBoarding == 5" name="fade">
          <OnboardingFifthStepAssistance />
        </Transition>
      </div>
      <div class="container_footer_onboarding">
        <div class="container_elements_footer">
          <div
            class="footer_modal footer__modal__cancel footer__modal__fix_onboarding"
          >
            <div
              disabled="disabled"
              class="cancel_button_onboarding"
              @click="cancelModal()"
            >
              <span> Omitir</span>
            </div>
            <div
              :class="
                stepAssistanceOnBoarding == 5
                  ? 'button_step_fourth '
                  : 'fix-btn-mac button_onboarding_accept'
              "
              @click="AcceptOnBoarding()"
            >
              <span v-if="stepAssistanceOnBoarding == 1">
                Comenzar
              </span>
              <span
                v-if="
                  stepAssistanceOnBoarding == 2 ||
                    stepAssistanceOnBoarding == 3 ||
                    stepAssistanceOnBoarding == 4
                "
              >
                Siguiente
              </span>

              <span v-if="stepAssistanceOnBoarding == 5">
                Crea tu primera solicitud
              </span>
            </div>
          </div>
          <div class="container__progressBar__onboarding">
            <div
              :class="
                stepAssistanceOnBoarding == 1
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(1)"
            ></div>
            <div
              :class="
                stepAssistanceOnBoarding == 2
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(2)"
            ></div>
            <div
              :class="
                stepAssistanceOnBoarding == 3
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(3)"
            ></div>
            <div
              :class="
                stepAssistanceOnBoarding == 4
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(4)"
            ></div>
            <div
              :class="
                stepAssistanceOnBoarding == 5
                  ? 'active_progressbar_onboarding'
                  : 'progressBar_onboarding'
              "
              @click="goBack(5)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import OnBoardingFirstStepAssistance from "./FirstStepAssistance.vue";
import OnBoardingSecondStepAssistance from "./SecondStepAssistance.vue";
import OnBoardingThirdStepAssistance from "./ThirdStepAssistance.vue";
import OnBoardingFourthStepAssistance from "./FourthStepAssistance.vue";
import OnboardingFifthStepAssistance from "./FifthStepAssistance.vue";

import { mapGetters } from "vuex";

export default {
  props: ["size", "indicator"],
  components: {
    OnBoardingFirstStepAssistance,
    OnBoardingSecondStepAssistance,
    OnBoardingThirdStepAssistance,
    OnBoardingFourthStepAssistance,
    OnboardingFifthStepAssistance,
  },
  data() {
    return {
      textButtonOnboarding: "",
    };
  },
  created() {
    this.$store.dispatch("Assistance/cleancounterStepAssistanceOnBoarding");
  },
  computed: {
    ...mapGetters("Assistance", {
      stepAssistanceOnBoarding: "stepAssistanceOnBoarding",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Login", {
      is_staff: "is_staff",
      group: "group",
      company: "company",
      user_profile: "user_profile",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      this.$store.dispatch("Assistance/cleancounterStepAssistanceOnBoarding");
      let modal = document.querySelector("#modal__onboarding__assistance");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
      this.$store.dispatch("Assistance/cleancounterStepAssistanceOnBoarding");
    },
    AcceptOnBoarding() {
      let increment = this.stepAssistanceOnBoarding + 1;
      this.$store.dispatch(
        "Assistance/counterStepAssistanceOnBoarding",
        increment
      );
      if (this.stepAssistanceOnBoarding > 5) {
        if (this.indicator == 1) {
          let modal = document.querySelector("#modal__onboarding__assistance");
          modal.setAttribute("closing", "");
          modal.addEventListener(
            "animationend",
            () => {
              modal.removeAttribute("closing");
              modal.close();
            },
            { once: true }
          );
        } else {
          this.$store.dispatch(
            "Assistance/cleancounterStepAssistanceOnBoarding"
          );

          this.$router.push({ name: "requestAsistencia" });
        }
      }
    },
    goBack(data) {
      this.$store.dispatch("Assistance/counterStepAssistanceOnBoarding", data);
    },
  },
};
</script>

<style lang="scss"></style>
