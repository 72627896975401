<template>
  <div>
    <ModalOnboardingHome
      ref="modal__onboarding__home"
      size="onboarding"
      :automaticValidation="fromAutomaticOnBoarding"
    />
    <div class="cards metrics">
      <cardDocuments
        title="Documentos"
        v-bind:DocumentosEmitidos="DocumentosEmitidos"
      />

      <cardRequest title="Solicitudes" v-bind:Request="Request" />
      <cardQuestion title="Preguntas Realizadas" v-bind:Question="Question" />
      <cardUsers title="Usuarios" v-bind:Users="Users" />
    </div>
    <div>
      <div class="home-container-grid">
        <div class="container-secondary">
          <CardDocumentsMain v-bind:listDocument="listDocument" />
          <CardUtilities />
          <div class="card-others card-documents">
            <CardEconomic title="Indicadores económicos" v-if="dataEconomic" />
            <div v-else class="card-single base-card card-special">
              <div class="title_skeleton">
                <VueSkeletonLoader
                  type="rect"
                  :width="200"
                  :height="20"
                  animation="fade"
                />
              </div>
              <div class="body_skeleton">
                <div class="">
                  <VueSkeletonLoader
                    type="rect"
                    :width="100"
                    :height="10"
                    animation="fade"
                  />
                </div>
                <div class="container_skeleton">
                  <div class="skeleton_utilities">
                    <VueSkeletonLoader
                      type="rect"
                      :height="50"
                      animation="fade"
                    />
                  </div>

                  <div class="skeleton_utilities">
                    <VueSkeletonLoader
                      type="rect"
                      :height="50"
                      animation="fade"
                    />
                  </div>
                </div>

                <div class="">
                  <VueSkeletonLoader
                    type="rect"
                    :width="100"
                    :height="10"
                    animation="fade"
                  />
                </div>
                <div class="container_skeleton">
                  <div class="skeleton_utilities">
                    <VueSkeletonLoader
                      type="rect"
                      :height="50"
                      animation="fade"
                    />
                  </div>
                  <div class="skeleton_utilities">
                    <VueSkeletonLoader
                      type="rect"
                      :height="50"
                      animation="fade"
                    />
                  </div>
                </div>
              </div>
            </div>
            <CardLinks title="Sitios de interés" v-bind:Sites="Sites" />
          </div>
        </div>
      </div>
    </div>
    <div class="container_button_onBoarding container_button_home_onboarding">
      <div class="button_onBoarding color_hover_button_standard" @click="OpenOnBoarding">
        Explora la App
        <div class="icon_onboarding_button">
          <OnBoardingIconButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardDocuments from "../components/card-features/cardDocuments.vue";
import cardRequest from "../components/card-features/cardRequest.vue";
import cardQuestion from "../components/card-features/cardQuestion.vue";
import cardUsers from "../components/card-features/cardUsers.vue";
import { mapGetters } from "vuex";
import CardUtilities from "../components/card-utilities/CardUtilities.vue";
import CardDocumentsMain from "../components/card-documents/CardDocumentsMain.vue";
import CardEconomic from "../components/card-economic/CardEconomic.vue";
import CardLinks from "../components/card-links/card-links.vue";

import miindicadorService from "../../../service/miindicador";

import documentService from "../services/homeCardServices";
import VueSkeletonLoader from "skeleton-loader-vue";

import OnBoardingIconButton from "@/assets/buttonIconOnboarding.svg";
import ModalOnboardingHome from "../OnBoarding/modalOnboardingHome.vue";

export default {
  components: {
    cardDocuments,
    cardRequest,
    cardQuestion,
    cardUsers,
    CardUtilities,
    CardDocumentsMain,
    CardEconomic,
    CardLinks,
    VueSkeletonLoader,
    OnBoardingIconButton,
    ModalOnboardingHome,
  },

  data: function () {
    return {
      DocumentosEmitidos: [],
      Request: {},
      countQuestion: "",
      Question: {},
      Users: {},
      Sites: {},
      listDocument: [],
      dataEconomic: false,
      flagLoadDataCards: false,
      fromAutomaticOnBoarding: false,
    };
  },

  computed: {
    ...mapGetters("Home", {
      dataDocuments: "dataDocuments",
      dataRequest: "dataRequest",
      dataQuestion: "dataQuestion",
      dataUsers: "dataUsers",
      dataFeatures: "dataFeatures",
      GetIndicadores: "GetIndicadores",
      GetFlagLoaderCards: "GetFlagLoaderCards",
    }),

    ...mapGetters("Login", {
      company_id: "company_id",
      group: "group",
      user: "user_profile",
    }),
  },
  created() {},
  methods: {
    OpenOnBoarding() {
      this.fromAutomaticOnBoarding = false;
      let param = "modal__onboarding__home";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__home;
      element.open();
    },
    AutomaticOpenBoarding() {
      this.fromAutomaticOnBoarding = true;
      let param = "modal__onboarding__home";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__onboarding__home;
      element.open();
    },
    loadDataDocuments() {
      const aux = this.dataDocuments;
      const arrayDocuments = Object.entries(aux);

      let resultArray = [];

      arrayDocuments.forEach((element) => {
        let data = {
          name: element[0],
          value: element[1].toString(),
          valueNumber: element[1],
        };

        resultArray.push(data);
      });

      this.DocumentosEmitidos = resultArray;

      // this.Request = this.dataCards.home_quotations_card;
      // this.Question = this.dataCards.home_questions_card;
      // this.Users = this.dataCards.home_users_card;
    },
    loadDataRequest() {
      const aux = this.dataRequest;
      const arrayRequest = Object.entries(aux);

      let resultArray = [];

      arrayRequest.forEach((element) => {
        let data = {
          month: element[0].slice(0, 3),
          value: element[1].toString(),
          valueNumber: element[1],
        };

        resultArray.push(data);
      });

      this.Request = resultArray;
    },
    loadDataQuestion() {
      const aux = this.dataQuestion;
      const arrayQuestions = Object.entries(aux);

      let resultArray = [];

      arrayQuestions.forEach((element) => {
        let data = {
          month: element[0].slice(0, 3),
          value: element[1].toString(),
          valueNumber: element[1],
        };

        resultArray.push(data);
      });

      this.Question = resultArray;

      // this.Users = this.dataCards.home_users_card;
    },
    loadDataUsers() {
      let aux = this.dataUsers;
      let resultArray = [];

      aux.forEach((element) => {
        let data = {
          name: element.user,
          rol:
            element.role === "Company-Admin"
              ? "Administrador"
              : element.role === "Company-Executive"
              ? "Ejecutivo"
              : element.role === "Company-Employee"
              ? "Empleado"
              : element.role === "Admin"
              ? "Superadmin"
              : element.role === "Agent"
              ? "Agente"
              : element.role === "Lawyer"
              ? "Abogado"
              : element.role === "Entel-Employee"
              ? "Entel"
              : "",
          date: element.date,
        };

        resultArray.push(data);
      });
      this.Users = resultArray;
    },

    economicData() {
      this.$store.dispatch("Home/loadingInitialDataForEconomics", false);
      miindicadorService
        .eco_indicators()
        .then((indicador) => {
          let data = {
            Dolar: `${indicador.dolar.valor}`,
            Euro: `${indicador.euro.valor}`,
            UTM: `${indicador.utm.valor}`,
            UF: `${indicador.uf.valor}`,
          };
          this.$store.dispatch("Home/setIndicadores", data);
        })
        .then(() => {
          this.dataEconomic = true;
        })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("Home/loadingInitialDataForEconomics", true);
          }, 1100);
        })
        .catch(() => {
          this.dataEconomic = false;
        });
    },

    listDocumentTypes() {
      this.$store.dispatch("Home/loadingInitialDataForDocuments", false);
      documentService
        .list_document_type(this.company_id, "id,name,abstract", 6, 0)
        .then((data) => {
          this.listDocument = data.results;
        })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("Home/loadingInitialDataForDocuments", true);
          }, 800);
        });
    },
  },

  mounted() {
    this.listDocumentTypes();
    this.economicData();
    if (this.group == "Company-Admin" || this.group == "Company-Executive") {
      if (this.user.show_onboarding_info) {
        this.AutomaticOpenBoarding();
      }
    }
  },

  watch: {
    GetFlagLoaderCards(old) {
      if (old) {
        this.flagLoadDataCards = old;
      }
      this.loadDataDocuments();
      this.loadDataRequest();
      this.loadDataQuestion();
      this.loadDataUsers();
    },
  },
};
</script>

<style lang="scss">
@import "../home.scss";
@import "../components/sharedComponents.scss";

.container_button_home_onboarding {
  @media screen and (max-width: 768px) and (min-width: 200px) {
    margin-right: 0px !important;
    margin-bottom: -5px !important;
    position: fixed !important;
    bottom: 80px !important;
    right: 40px !important;
  }
}
// card economic and utilitys
</style>
