<template>
  <div class="center-loader loader__assitance" v-if="loaderActive">
    <LoaderGeneric />
    <div class="">Eliminando solicitud...</div>
  </div>
  <div class="container-slots" v-else-if="this.currentSingleAssistance">
    <div class="breadcrumb">
      <span
        class="text_document m-left-none color_gray_i"
        @click="goToDashboardPreStepAssistance"
      >
        Solicitudes >
      </span>

      <span class="text_document"
        >Caso #{{ currentSingleAssistance.caseCode }}
      </span>
    </div>
    <div
      class="container_base_single_assistance"
      v-if="!flagFilterMobileAssistance"
    >
      <div class="container_left_single_assitance">
        <div
          :class="
            this.currentSingleAssistance != null
              ? 'header_single_assitance'
              : ''
          "
        >
          <HeaderSingleAssistance
            :usuario="this.currentSingleAssistance"
            v-if="SingleAssistanceData != null"
          />
          <div class="header_single_assitance_skeleton" v-else>
            <SingleHeaderSkeleton />
          </div>
        </div>
        <div
          class="icon_container_assistance_files_mobile close_assitance_files"
          v-if="SingleAssistanceData != null"
        >
          <IconLogoAssistance @click="setFilterActiveAssistance()" />
          <div
            class="tag_assistance_my_files"
            @click="setFilterActiveAssistance()"
          >
            Mis Archivos...
          </div>
        </div>
        <div
          class="icon_container_assistance_files_mobile close_assitance_files"
          v-else
        >
          <div class="skeleon_mobile_files_icon">
            <SingleMobileIconSkeleton />
          </div>
        </div>
        <div class="">
          <div class="container_swap_assistance">
            <div
              class="selected_assistance title-container"
              :class="DefaultInfo ? 'active' : ''"
              @click="selectedAssistance('default')"
            >
              Información básica
            </div>
            <div
              v-if="group == 'Admin'"
              class="selected_assistance title-container"
              :class="AssignedUser ? 'active' : ''"
              @click="selectedAssistance('users')"
            >
              Usuarios asignados
            </div>
            <div
              class="selected_assistance title-container"
              :class="slaInfo ? 'active' : ''"
              @click="selectedAssistance('SLA')"
            >
              Términos de atención
            </div>
          </div>
          <div class="line-primary"></div>
        </div>

        <div class="container_basic_information_assistance">
          <Transition div class="" v-if="DefaultInfo" name="fade">
            <BasicInformationAssistance
              :usuario="SingleAssistanceBaseInformationData"
              v-if="SingleAssistanceBaseInformationData != null"
            />
            <div class="skeleton_main_assistance_information" v-else>
              <BasicInformationHeaderSkeleton />
            </div>
          </Transition>
          <Transition div class="" v-else-if="AssignedUser" name="fade">
            <AssignedUsersQuotations
              :usuario="SingleAssistanceTermsAgreementData"
              v-if="SingleAssistanceTermsAgreementData != null"
            />
            <div class="skeleton_main_assistance_information" v-else>
              <SingleCareInformationSkeleton />
            </div>
          </Transition>
          <Transition div class="" v-else name="fade">
            <TerminationCareAssistance
              :usuario="SingleAssistanceTermsAgreementData"
              v-if="SingleAssistanceTermsAgreementData != null"
            />
            <div class="skeleton_main_assistance_information" v-else>
              <SingleCareInformationSkeleton />
            </div>
          </Transition>
        </div>
      </div>

      <div class="container_rigth_single_assitance">
        <FilesSingleAssistance />
      </div>
    </div>
    <div class="" v-else>
      <div class="container_rigth_single_assitance_mobile">
        <FilesSingleAssistance />
      </div>
    </div>
  </div>

  <div class="container-slots" v-else>no data</div>
</template>

<script>
import { mapGetters } from "vuex";

// Skeleton components
import SingleHeaderSkeleton from "../components/Skeleton/SingleHeaderSkeleton.vue";
import BasicInformationHeaderSkeleton from "../components/Skeleton/SingleBasicInformationSkeleton.vue";
import SingleCareInformationSkeleton from "../components/Skeleton/SingleCareInformationSkeleton.vue";
import SingleMobileIconSkeleton from "../components/Skeleton/SingleMobileIconSkeleton.vue";

//loader generic

import LoaderGeneric from "@/components/loaderGeneric/Loading.vue";

// Header component
import HeaderSingleAssistance from "../components/HeaderSingleAssistance.vue";

// Basic information component, toggle components
import BasicInformationAssistance from "../components/optionsSelectionSingleAssistance/BasicInformationAssistance.vue";
import AssignedUsersQuotations from "../components/optionsSelectionSingleAssistance/AssignedUsersQuotations.vue";

// Termination care component, toggle components
import TerminationCareAssistance from "../components/optionsSelectionSingleAssistance/TerminationCareAssistance.vue";

import FilesSingleAssistance from "../components/FilesSingleAssistance.vue";

import IconLogoAssistance from "../assets/file-solid.svg";

export default {
  components: {
    HeaderSingleAssistance,
    BasicInformationAssistance,
    TerminationCareAssistance,
    FilesSingleAssistance,
    IconLogoAssistance,
    SingleHeaderSkeleton,
    BasicInformationHeaderSkeleton,
    SingleCareInformationSkeleton,
    AssignedUsersQuotations,
    SingleMobileIconSkeleton,
    LoaderGeneric,
  },
  data: () => {
    return {
      // personalized chunks of data (SingleAssistanceData) for different components
      SingleAssistanceData: null,
      SingleAssistanceBaseInformationData: null,
      SingleAssistanceTermsAgreementData: null,
      // data for toggle for html , basic information / termination care
      DefaultInfo: true,
      slaInfo: false,
      AssignedUser: false,
      nameSingleFile: "",
      urlSingleFile: "",
      validArrayFiles: true,
      arrayFiles: null,
      arrayCount: "",
      idRequester: null,
      getThread: {},
      loading: 0,
      date: null,
    };
  },
  created() {
    this.$store.dispatch("Assistance/desactiveLoader");
    setTimeout(() => {
      console.log("currentSingleAssistance", this.currentSingleAssistance)
      this.SingleAssistanceData = this.currentSingleAssistance;
    }, 1000);
    setTimeout(() => {
      this.SingleAssistanceBaseInformationData =
        this.currentSingleAssistanceBaseInformation;
    }, 1000);

    this.SingleAssistanceTermsAgreementData =
      this.currentSingleAssistanceTermsAgreement;

    if (!this.currentSingleAssistance.isArrayFiles) {
      this.validArrayFiles = false;

      this.nameSingleFile = this.currentSingleAssistance.isArrayFiles.filename;
      this.urlSingleFile = this.currentSingleAssistance.isArrayFiles.url;
    } else {
      let arrayFilesTemp = this.currentSingleAssistance.files;
      this.arrayFiles = arrayFilesTemp.length > 0 ? arrayFilesTemp : false;
    }
    this.arrayCount = this.currentSingleAssistance.files.length;

    if (this.group == "Admin") {
      let fields = "id,full_name,group,user";

      let companyRequester = this.currentIdCompanyRequester;

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payloadListRequester = {
        admin_company_id: admin_company_id,
        companyRequester: companyRequester,
        group_id_in:
          "01183c63-cdfc-4836-ad3c-3fcaebcb372f,d2aed6fa-7fd3-4cd6-8733-64c07177a1ea",
        fields: fields,
      };

      let id_admin_assigned = this.inSwitch ? this.company_id : this.company_id;

      let payloadAdminAssigned = {
        admin_company_id: id_admin_assigned,
        companyRequester: id_admin_assigned,
        group_id_in: "325ec0e8-9ba9-4bc8-88ff-8812f0d7197c",
        fields: fields,
      };

      this.$store.dispatch(
        "Assistance/getListEmployeesAssigned",
        payloadAdminAssigned
      );

      this.$store.dispatch(
        "Assistance/getListRequesterAssigned",
        payloadListRequester
      );
    }
    if (this.getterTabAssigned) {
      this.selectedAssistance("users");
    }
  },

  computed: {
    ...mapGetters("Assistance", {
      currentSingleAssistance: "currentSingleAssistance",
      currentSingleAssistanceTermsAgreement:
        "currentSingleAssistanceTermsAgreement",
      currentSingleAssistanceBaseInformation:
        "currentSingleAssistanceBaseInformation",
      flagFilterMobileAssistance: "flagFilterMobileAssistance",
      loaderActive: "loaderActive",
      requestID: "currentSingleAssistanceBaseInformationID",
      currentIdCompanyRequester: "currentIdCompanyRequester",
      getterTabAssigned: "getterTabAssigned",
    }),
    ...mapGetters("Login", {
      group: "group",
      company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    setFilterActiveAssistance() {
      this.$store.dispatch("Assistance/setFilterActiveInMobileAssistance");
    },
    goToDashboardPreStepAssistance() {
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payload = {
        admin_company_id: company_id,
        fields:
          "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
        limit: "5",
      };

      this.$store.dispatch("Assistance/cleanTagsOfFilter");
      this.$store.dispatch("Assistance/deactiveFilter");
      this.$store.dispatch("Assistance/cleanFiltersResult");
      this.$store.dispatch("Assistance/getAllAssistance", payload);

      this.$router.push({ name: "listRequestIntegrated" });
    },
    // function toggle for html , basic information / termination care
    selectedAssistance(value) {
      // AssignedUser
      // slaInfo
      if (value == "default") {
        this.DefaultInfo = true;
        this.AssignedUser = false;
        this.slaInfo = false;
      } else if (value == "users") {
        this.DefaultInfo = false;
        this.AssignedUser = true;
        this.slaInfo = false;
      } else {
        this.DefaultInfo = false;
        this.AssignedUser = false;
        this.slaInfo = true;
      }
    },
  },
};
</script>

<style lang="scss">
.files_assistance {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;

  &__title {
    height: 10%;
  }

  &__content {
    height: 9 0%;
  }
}
.container_base_single_assistance {
  display: flex;
  gap: 16px;
  width: 100%;
}
.container_left_single_assitance {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 60px;
    padding-top: 0px;
  }
  display: flex;
  flex-direction: column;
  background: var(--container_pre_step);
  width: 80%;
  gap: 1rem;
  padding-left: 34px;
  padding-right: 18px;
  padding-top: 17px;
  padding-bottom: 44px;
  color: var(--text-document-draf) !important;
}
.container_rigth_single_assitance {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: none;
  }
  background: var(--container_pre_step);
  width: 20%;
  padding: 1rem 1rem;
  color: var(--text-document-draf) !important;
}
.container_rigth_single_assitance_mobile {
  background: var(--container_pre_step);
  height: 100vh;
  width: 100%;
  padding: 0rem 1rem 1rem;
  color: var(--text-document-draf) !important;
}
.header_single_assitance {
  display: flex;
  justify-content: space-between;
  height: 104px;
  width: 100%;
}
.header_single_assitance_skeleton {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header_content_assistance {
  display: flex;
  gap: 1rem;
}

.category_container_assistance,
.category_container_assistance__subject {
  display: flex;
  gap: 84px;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    justify-content: space-between;
  }
}
.icon_container_assistance {
  > svg {
    fill: var(--svg_pre_step) !important;
    stroke: var(--svg_pre_step) !important;
    height: 65px;
    border-radius: 115%;
    padding: 5px;
  }
}
.icon_circle_assistance {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container_swap_assistance {
  display: flex;
  gap: 63px;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    justify-content: space-between;
    gap: 37px;
    margin-top: 7px;
  }

  > .active::before {
    content: "";
    width: 120%;
    height: 3px;
    position: absolute;
    background-color: #0064ff;
    top: 96%;
  }
  > .title-container {
    color: var(--title_profile) !important;
    font-size: 14px !important;
    @media screen and (max-width: 500px) and (min-width: 200px) {
      font-size: 14px !important;
      margin-top: 0px !important;
    }
  }
}

.skeleton_assistance_rigth {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 34% !important;
  }
}

.skeleton_assistance_left {
  display: flex;
  gap: 2rem;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    gap: 1rem;
  }
  &_name {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
.container_basic_information_assistance {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.selected_assistance {
  cursor: pointer;
  position: relative;
}
.skeleton_information_skeleton {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.files_header_container {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 9px;
}
.files_assistance__subtitle {
  display: flex;
  gap: 21px;
  color: var(--title_profile) !important;
  white-space: nowrap;
  width: 80% !important;
}
.icon_container_assistance_files_mobile {
  display: none !important;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    display: flex !important;
    justify-content: flex-end;
    margin-left: 0.7rem;
    gap: 0.5rem;
    cursor: pointer;

    > svg {
      fill: var(--svg_pre_step) !important;
      stroke: var(--svg_pre_step) !important;
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
  }
}
.tag_assistance_my_files {
  color: var(--title_profile) !important;
  font-size: 10px !important;
  background-color: #fa5f49;
  fill: solid;
  border-radius: 4px;
  width: 93px;
  padding-left: 15px;
  color: white !important;
}
.skeleon_mobile_files_icon {
  display: flex;
  gap: 0.7rem;
}

.loader__assitance {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
  color: var(--name-user-header) !important;
}
</style>
