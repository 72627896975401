<template>
  <div class="modal__content">
    <div class="onboarding_img_fix image_onboarding_first_assistance">
      <FirtsImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_assistance ">
          ¡Bienvenido a la sección Solicitudes!
        </div>
      </div>
      <div class="text_container_onboarding text_first_assistance">
        <div class="">
          Aquí podrás pedir el servicio que necesites, comunicarte directamente
          con el abogado encargado del caso y consultar toda la información
          respectiva a cada solicitud.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirtsImageOnBoarding from "./assets/firstStepAssistance.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    FirtsImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Assistance", {
      stepAssistanceOnBoarding: "stepAssistanceOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepAssistanceOnBoarding + 1;

      this.$store.dispatch(
        "Assistance/counterStepAssistanceOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.text_first_assistance {
  gap: 5rem !important;
}
.title_onboarding_assistance {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  margin-top: 3rem !important;

  @media screen and (max-width: 500px) and (min-width: 200px) {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
  }
}
.image_onboarding_first_assistance {
  width: 339px !important;
  height: 300px !important;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 10.7rem !important;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 220px !important;
    height: 200px !important;
    margin-left: 2.8rem !important;
    margin-top: 1rem !important;
  }
}
</style>
