<template>
  <Transition v-if="GetFlagLoaderCards" name="fade">
    <div class="card-single base-card">
      <div class="card-group">
        <h1>
          {{ title }}
          <br />
          <span>(Mes Actual)</span>
        </h1>

        <div class="value-primary-grap">
          {{ total }}
        </div>
      </div>

      <div class="documets-card">
        <div v-for="(data, index) in DocumentosEmitidos" :key="index">
          <div class="container-data">
            <div class="title-grap">
              {{ data.name }}
            </div>
            <div class="horizonta-bar">
              <div class="value-grap">({{ data.value }})</div>
              <meter
                min="0"
                :max="maxValue"
                optimum="100"
                :value="data.value"
              ></meter>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
  <div v-else class="card-single base-card">
    <div class="title_skeleton">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="15"
        animation="fade"
      />
    </div>
    <div class="container_fix">
      <div class="">
        <VueSkeletonLoader
          type="rect"
          :width="70"
          :height="15"
          animation="fade"
        />
      </div>

      <div class="test">
        <VueSkeletonLoader
          type="circle"
          :width="50"
          :height="50"
          animation="fade"
        />
      </div>
    </div>
    <div class="body_skeleton_features">
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="270"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />

      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="270"
        :height="10"
        animation="fade"
      />
      <VueSkeletonLoader
        type="rect"
        :width="200"
        :height="10"
        animation="fade"
      />
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
import { mapGetters } from "vuex";
export default {
  props: ["title", "DocumentosEmitidos"],
  components: {
    VueSkeletonLoader,
  },

  data: function () {
    return {
      maxValue: "",
      total: 0,
    };
  },

  // mounted () {
  //   const maxValueFromArray = this.DocumentosEmitidos.reduce(function (
  //     prev,
  //     current
  //   ) {
  //     return prev.value > current.value ? prev : current;
  //   });

  //   this.DocumentosEmitidos.forEach((element) => {
  //     this.total += element.valueNumber;
  //   });

  //   this.maxValue = maxValueFromArray.value;
  // },

  created() {

  },

  computed: {
    ...mapGetters("Home", {
      GetFlagLoaderCards: "GetFlagLoaderCards",
    }),
  },

  watch: {
    GetFlagLoaderCards() {
      const maxValueFromArray = this.DocumentosEmitidos.reduce(function (
        prev,
        current
      ) {
        return prev.value > current.value ? prev : current;
      });

      this.DocumentosEmitidos.forEach((element) => {
        this.total += element.valueNumber;
      });

      this.maxValue = maxValueFromArray.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./cardFeatures.scss";
@import "../sharedComponents.scss";
</style>
