import homeAdmin from "./pages/home/homeAdmin.vue";
import homeAdminModule from "./pages/home/HomeAdminModule.vue";
import SchedulingModule from "./pages/SchedulingAdmin/SchedulingModule.vue";
import SchedulingAdmin from "./pages/SchedulingAdmin/SchedulingAdmin.vue";

export const AdminRoutes = [
  {
    path: "/home-tenant",
    component: homeAdminModule,

    children: [
      {
        path: "/",
        name: "homeTenant",
        component: homeAdmin,
      },
      // {
      //   path: "edit",
      //   name: "user.edit",
      //   component: ProfileAboutPage,

      // },
    ],
  },
  {
    path: "/tenant-mi-agenda",
    component: SchedulingModule,

    children: [
      {
        path: "/",
        name: "shedulingTenant",
        component: SchedulingAdmin,
      },
      // {
      //   path: "edit",
      //   name: "user.edit",
      //   component: ProfileAboutPage,

      // },
    ],
  },
];
