<template>
  <div>
    <div class="alignment__flex">
      <input
        class="document-input"
        type="checkbox"
        :id="document_type.id"
        :checked="document_type.is_active ? true : false"
        @change="selectDocument($event, document_type.id)"
      />
      <label :for="document_type.id">{{ document_type.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    document_type: {
      required: true,
    },
  },
  components: {},
  computed: {},
  created() {
    this.$store.dispatch(
      "SettingsApp/setDefaultDocumentTypeByRegion",
      this.document_type
    );
  },
  data: () => {
    return {};
  },
  methods: {
    selectDocument(event, document_type_id) {
      if (event.target.checked) {
        let data = {
          id: document_type_id,
          is_active: true,
        };

        this.$store.dispatch("SettingsApp/addElementDocumentByRegion", data);
      } else if (!event.target.checked) {
        let data = {
          id: document_type_id,
          is_active: false,
        };
        this.$store.dispatch("SettingsApp/addElementDocumentByRegion", data);
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.alignment__flex {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
