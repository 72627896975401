/* eslint-disable */
// this is an auto generated file. This will be overwritten
import gql from 'graphql-tag'

export const createThread = /* GraphQL */ `
  mutation CreateThread($input: CreateThreadInput) {
    createThread(input: $input) {
      id
      quotation_id
    }
  }
`;

export const updateMessagePinnedMutation = gql`
  mutation UpdateMessagePinned($quotation_id: String!, $id: ID!, $pinned: Boolean!) {
    updateMessage(input: { quotation_id: $quotation_id, id: $id, pinned: $pinned }) {
      quotation_id
      id
      pinned
    }
  }
`;





export const createMessage = gql`
  mutation CreateMessage($quotation_id: String!, $text: String!, $user: UserInput!) {
    createMessage(input: {
      quotation_id: $quotation_id,
      text: $text,
      user: $user
    }) {
      id
      quotation_id
      text
      files {
        filename
        url
      }
      pinned
      createdAt
      updatedAt
      user {
        id
        full_name
        email
        initials
        is_staff
        is_attendant
      }
    }
  }
`;
export const createMessageFile = gql`
  mutation CreateMessage($quotation_id: String!, $text: String!, $user: UserInput!, $attachments: [AttachmentInput]!) {
    createMessage(input: {
      quotation_id: $quotation_id,
      text: $text,
      user: $user,
      attachments: $attachments
    }) {
      id
      quotation_id
      text
      files {
        filename
        url
      }
      pinned
      createdAt
      updatedAt
      user {
        id
        full_name
        email
        is_staff
        initials
        is_attendant
      }
    }
  }
`;
