export default {
  company_data: (state) => {
    return state.company_data;
  },

  company_user: (state) => {
    return state.company_user;
  },

  single_company: (state) => {
    return state.single_company;
  },

  company_type: (state) => {
    return state.single_company.company_type;
  },
  table_user_company_admin: (state) => {
    return state.table_user_company_admin;
  },

  // for tags in header table company admin

  tags: (state) => {
    return state.tags;
  },
  tagsForSwitch: (state) => {
    return state.tagsForSwitch;
  },
  economicCategoryGetter: (state) => {
    return state.tagsForSwitch.economic_categories__name__icontains;
  },

  // nuevos getters

  flagData: (state) => {
    return state.flagData;
  },
  company_data_table: (state) => {
    return state.company_data_table;
  },
  total_data_view: (state) => {
    return state.total_data_view;
  },
  // is for pagination
  total_data: (state) => {
    return state.total_data;
  },
  nextPage: (state) => {
    return state.nextPage;
  },
  prevPage: (state) => {
    return state.prevPage;
  },
  loader_companys: (state) => {
    return state.loaderCompanys;
  },
  selected_country: (state) => {
    return state.selectedCountry;
  },
  selected_region: (state) => {
    return state.selectedRegion;
  },
  initialEconomicCategory: (state) => {
    return state.initialEconomicCategory;
  },
  flagInitialReset: (state) => {
    return state.flagInitialReset;
  },
  dataFinalEconomicCategory: (state) => {
    return state.finalDataEconomic;
  },
  economicCategoryForServiceStore: (state) => {
    return state.economicCategoryForService;
  },
  currentSelectCompanyFlag: (state) => {
    return state.currentSelectCompanyFlag;
  },

  // validator user admin from list companys for view single company
  flagAdminForViewSingleCompany: (state) => {
    return state.flagAdminForViewSingleCompany;
  },
  flagFilterMobile: (state) => {
    return state.flagFilterMobile;
  },
};
