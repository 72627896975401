<template>
  <div class="modal__content">
    <div
      class="image_onboarding_employee onboarding_img_fix image_onboarding_second_assistance image_second_fix_assistance"
    >
      <FourthImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_assistance">
          Administra tus solicitudes activas y resueltas
        </div>
      </div>
      <div class="text_container_onboarding ">
        <div class="">
          Podrás consultar todas las solicitudes que hayas realizado,
          <b>revisar su información y descargar los archivos relacionados. </b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FourthImageOnBoarding from "./assets/fourthStepAssistance.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    FourthImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Assistance", {
      stepAssistanceOnBoarding: "stepAssistanceOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepAssistanceOnBoarding + 1;

      this.$store.dispatch(
        "Assistance/counterStepAssistanceOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__assistance");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.title_onboarding_assistance {
  text-align: center;
}
</style>
