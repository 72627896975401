import { HomeStore } from "../modules/home/store/index";
import { LoginStore } from "../modules/Login/store/index";
import { CompanyStoreV2 } from "../modules/companyv2/store/index";
// import { DocumentStore } from "../modules/documents/store/index";
// import { EmployeeStore } from "../modules/employees/store/index";
import { EmployeeStoreV2 } from "../modules/employeesV2/store/index";
import { AssistanceStore } from "../modules/integratedAssistance/store/index";
import { ProfileStore } from "../modules/profile/store/index";

import { DigitalSignatureStore } from "../modules/digitalSignature/store/index";
import { ScheduleStore } from "../modules/schedule/store/index";
import { ReportUseTenantStore } from "../modules/reportTenant/store/index";
import { ConfigStore } from "../modulesAdmin/store/index";

import { SettingsAppStore } from "../modulesAdmin/modules/Settings/store/index";

import createPersistedState from "vuex-persistedstate";

const LoginStorePersist = createPersistedState({
  paths: [
    "Home",
    "Login",
    // "Documents",
    "CompanyV2",
    // "Employees",
    "EmployeesV2",
    "Assistance",
    "Profile",
    "DigitalSignature",
    "Schedule",
    "ReportUseTenant",
    "Config",
    "SettingsApp",
  ],
});

 const RootStore = {
  state: {
    applicationName: "Modularized Vue App",
    mainToken: null,
  },
  mutations: {
    setUserLoginEmpty() {
      localStorage.removeItem("vuex");
      localStorage.clear();
    },
  },
  getters: {
    appName(state) {
      return state.applicationName;
    },
  },
  modules: {
    Login: { ...LoginStore },
    Home: { ...HomeStore },
    CompanyV2: { ...CompanyStoreV2 },
    // Documents: { ...DocumentStore },
    // Employees: { ...EmployeeStore },
    EmployeesV2: { ...EmployeeStoreV2 },
    Assistance: { ...AssistanceStore },
    Profile: { ...ProfileStore },
    DigitalSignature: { ...DigitalSignatureStore },
    Schedule: { ...ScheduleStore },
    ReportUseTenants: { ...ReportUseTenantStore },
    Config: { ...ConfigStore },
    SettingsApp: { ...SettingsAppStore },
  },

  plugins: [LoginStorePersist],
};

export default RootStore;
