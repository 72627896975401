// let vuex = JSON.parse(localStorage.getItem("vuex") || "[]");

// const vuexLoginData = vuex.Login;

let state = {
  // status: 'authenticating',
  token: null,
  user_profile: null,
  is_staff: true,
  companies: [],
  totalCompanies: null,
  company: [],
  company_selected: [],
  error: null,
  isLoggIn: false,
  fetchingData: false,
  selected_company_data: null, // podria no usarse en el login
  oauth_id: "",
  modal: false,
};

export default state;
