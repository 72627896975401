<template>
  <div class="modal__content">
    <div class="image_onboarding_second_home">
      <SecondImageOnBoarding />
    </div>
    <div class="content_onboarding_employee_first_step ">
      <div class="container_onboarding_title">
        <div class="title_onboarding_assistance">
          ¡Resuelve todas tus necesidades legales!
        </div>
      </div>
      <div class="text_container_onboarding ">
        Ingresa tus solicitudes en un portal simple y rápido. ¡!
      </div>
    </div>
  </div>
</template>

<script>
import SecondImageOnBoarding from "./assets/secondHome.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    SecondImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("Home", {
      stepHomeOnBoarding: "stepHomeOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepHomeOnBoarding + 1;

      this.$store.dispatch("Home/counterStepHomeOnBoarding", increment);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__onboarding__home");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.image_onboarding_second_home {
  width: 360px !important;
  height: 300px !important;
  display: flex;
  margin-top: 5rem;
  margin-left: 10.5rem !important;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 220px !important;
    height: 200px !important;
    margin-left: 2.5rem !important;
    margin-top: 1.8rem !important;
  }
}
</style>
