<template>
  <div class="container-slots">
    <div class="container-base">
      <div class="border__bottom">
        <div class="breadcrumb">
          <span
            class="sign__digital text_document"
            @click="goToListSignature()"
          >
            Firma Digital >
          </span>

          <span class="text_document">Seguimiento </span>
        </div>
      </div>
      <ProgressBar :steps="trackingState.trackingStep" />
      <div class="footer__tracking">
        <div class="info__tracking">
          <infoTracking title="Documentos" :data="trackingState.name" />
          <infoTracking title="Identificador" :data="trackingState.tags[0]" />
          <infoTracking
            title="Tipo de Firma"
            :data="trackingState.typeSignature"
          />
        </div>
        <div class="signers__tracking">
          <div class="label-primary m-bottom-m">Firmantes</div>
          <ListSignersForTracking :members="trackingState.members" />
        </div>

        <ProgressCircle
          :total="trackingState.totalSigner"
          :signers="trackingState.signer"
        />
      </div>
      <div class="footer__file">
        <button class="secondary_button" @click="backButton()">Volver</button>
        <a
          download
          class="footer__download"
          @click="loadUrlToDownload()"
          :class="this.flagUrl ? 'btn-vue-primary color_hover_button_standard' : 'bloqued__file'"
          :disabled="!this.flagUrl"
        >
          <Download />
          <div class="" v-if="downloadDoc">Descargar Documento Firmado</div>
          <div class="" v-else>Descargando documento ...</div>
        </a>
      </div>
    </div>
    <div class="container_footer_information container_signer_mobile">
      <div class="container_footer_elements">
        <div class="container_footer_sub_elements">
          <div class="text_footer_information">
            En esta sección puedes revisar el <span> estado de avance </span> un
            abogado responderá para ti: de tu proceso de firma,
            <span> copiar y compartir el link </span>de firmado con el ícono
            <CopyPasteIcon class="icon_copy_paste" /> , y descargar el documento
            una vez esté firmado por todas las personas
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../../../components/progressBar/ProgressBar.vue";

import ProgressCircle from "../../../components/progressCircleBar/ProgressCircleBar.vue";

import ListSignersForTracking from "../components/ListSignersForTracking.vue";

import infoTracking from "../components/infoTracking.vue";

import { mapGetters, mapState } from "vuex";
import Download from "../../../assets/iconos_container/download.svg";

// import digitalSignatureService from "../services/digitalSignatureService";
import axios from "axios";

import CopyPasteIcon from "../assets/copyPasteIcon.svg";

export default {
  name: "TrakingSigners",

  components: {
    ProgressBar,
    ProgressCircle,
    ListSignersForTracking,
    infoTracking,
    Download,
    CopyPasteIcon,
  },
  mounted() {
    if (this.trackingState.trackingStep == 3) {
      this.flagUrl = true;
    }
  },

  data: () => {
    return {
      flagUrl: false,
      downloadDoc: true,
    };
  },

  computed: {
    ...mapState({
      idDocument: (state) => state.DigitalSignature.tracking.id,
    }),
    ...mapGetters("DigitalSignature", {
      tracking: "tracking",
      urlGetter: "urlFileDownload",
    }),
    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapState("DigitalSignature", {
      trackingState: "tracking",
    }),
  },
  methods: {
    backButton() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
      this.$router.push({ name: "DigitalSignature" });
    },

    loadUrlToDownload() {
      this.downloadDoc = false;
      let admin_company_id = this.inSwitch
        ? this.idInSwitch
        : this.admin_company_id;

      let id_document = this.idDocument;
      let defaultUrl = (axios.defaults.baseURL = process.env.VUE_APP_API_URL);
      axios.defaults.xsrfCookieName = "csrftoken";
      axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

      axios({
        url: `${defaultUrl}/api/v1/document/${admin_company_id}/${id_document}/`,
        params: {
          fields: "sign_file_url",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: {
            toString() {
              return `SDB ${localStorage.getItem("token")}`;
            },
          },
        },
        method: "GET",
        // responseType: "blob",
      }).then((response) => {
        // console.log(response.data.sign_file_url);
        let urlToCreateBlob = response.data.sign_file_url;

        // url for second axios call", urlToCreateBlob

        axios({
          url: urlToCreateBlob, // File URL Goes Here
          method: "GET",
          responseType: "blob",
        })
          .then((res) => {
            let FILE = window.URL.createObjectURL(new Blob([res.data]));

            let fileLink = document.createElement("a");

            fileLink.href = FILE;

            let nameDocument = this.trackingState.tags[0];
            fileLink.setAttribute("download", `${nameDocument}.pdf`);
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .then(() => {
            this.downloadDoc = true;
          });
      });
    },

    goToListSignature() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
      this.$router.push({ name: "DigitalSignature" });
    },
  },
};
</script>

<style lang="scss">
.footer__file {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    flex-direction: inherit;
    flex-wrap: wrap;
  }
  width: fit-content;
  display: flex;
  gap: 1rem;
  width: 100% !important;
  justify-content: flex-end;
}
.bloqued__file {
  // algo
  font-family: Conv_HelveticaLTStd-Light;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  text-align: center !important;
  transition: all 250ms ease;
  background: #9c9c9c;
  border-radius: 2px;
  border: 1px solid #9c9c9c;
  color: white;
  pointer-events: none;

  &:hover {
    background: #9c9c9c !important;
  }
}
.footer__download {
  display: flex;
  align-items: center;
  gap: 0.2rem;

  &:hover {
    color: white;
  }
}
.footer__download svg {
  width: 15px;
  height: 15px;
}
.footer__download > svg .st0 {
  stroke: white !important;
}

.footer__tracking {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    font-size: 14px !important;
  }
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 765px) and (min-width: 200px) {
  .footer__tracking {
    gap: 0.5rem;
  }
  .signers__tracking,
  .info__tracking {
    width: 100%;
  }

  .progressbar li {
    font-size: 8px;
  }
}

.progressbar li {
  color: var(--content_table_dsignature) !important;
}

.signers__tracking {
  > .label-primary {
    color: var(--content_table_dsignature) !important;
  }
}
@media screen and (max-width: 1300px) and (min-width: 768px) {
  .info__tracking {
    width: 100%;
  }
}

.info__tracking,
.info__tracking + div {
  margin-top: 4rem;
}
.icon_copy_paste {
  height: 25px !important;
}
.container_signer_mobile {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    font-size: 14px !important;
  }
}
</style>
