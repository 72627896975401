import api from "../../../service/api";
import apiForm from "../../../service/apiFormData";

export default {
  // retorna el usuario perteneciente a la empresa seleccionada

  own_profile(company_id) {
    return api
      .get(`/api/v1/user_profile/${company_id}/me`)
      .then((response) => response.data);
  },

  user_whitelist() {
    return api
      .get("/api/v1/user_whitelist/", {
        params: {
          is_active: true,
        },
      })
      .then((response) => response.data);
  },

  list_companies(filters, limit = null, offset = null, filtersSearch = null) {
    return api
      .get(`/api/v1/company/`, {
        params: {
          fields: filters,
          limit: limit,
          offset: offset,
          filters: filtersSearch != null ? filtersSearch : null,
        },
      })
      .then((response) => response.data);
  },
  list_companies_from_filter(
    fields,
    limit = null,
    offset = null,
    country = null,
    state = null,
    filters = null,
    economic_categories__name__icontains = null
  ) {
    return api
      .get(`/api/v1/company/`, {
        params: {
          fields: fields,
          limit: limit,
          offset: offset,
          country: country,
          state: state,
          filters: filters,
          economic_categories__name__icontains: economic_categories__name__icontains,
        },
      })
      .then((response) => response.data);
  },
  search_companies_name(fields, name) {
    return api
      .get(`/api/v1/company/`, {
        params: {
          fields: fields,
          name__icontains: name,
        },
      })
      .then((response) => response.data);
  },
  get_company(company_id) {
    return api
      .get(`/api/v1/company/${company_id}`)
      .then((response) => response.data);
  },
  get_company_summary(company_id) {
    return api
      .get(`/api/v1/company/${company_id}/summary`)
      .then((response) => response.data);
  },
  patch_company(company_id, payload) {
    return apiForm
      .patch(`/api/v1/company/${company_id}`, payload)
      .then((response) => response.data);
  },
  create_company(payload) {
    return apiForm
      .post(`/api/v1/company/`, payload)
      .then((response) => response.data);
  },
  list_economic_categories() {
    return api
      .get(`/api/v1/economic_category/`)
      .then((response) => response.data);
  },

  list_document_type_category() {
    return api
      .get(`/api/v1/document_category/`, {
        params: {
          parent__isnull: true,
          "name__icontains!": "Documentos Personales",
        },
      })
      .then((response) => response.data);
  },

  nextPage(nextUrl) {
    return api.get(nextUrl, {}).then((response) => response.data);
  },

  economic__category(params = "") {
    return api
      .get(`/api/v1/economic_category/`, {
        params: {
          fields: params,
        },
      })
      .then((response) => response.data);
  },
};
