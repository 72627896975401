// import DigitalSignatureModule from "./DigitalSignatureModule.vue";
import DigitalSignature from "./pages/ListDigitalSignature.vue";
import OriginFromApp from "./pages/OriginFromApp.vue";
import OriginFromLocal from "./pages/OriginFromLocal.vue";
import TrakingSigners from "./pages/TrakingSigners.vue";
import DraftDocument from "./pages/DraftDocument.vue";

import CreateNewDigitalSignature from "./pages/CreateNewDigitalSignature.vue";
import AddSigners from "./pages/AddSigners.vue";
import PreStepSignature from "./pages/PreStepSignature.vue";

export const DigitalSignatureRoutes = [
  {
    path: "/pre-step",
    name: "preStep",
    component: PreStepSignature,
  },
  {
    path: "/Firma-digital",
    name: "DigitalSignature",
    component: DigitalSignature,

    children: [
      {
        path: "selecciona-origen",
        name: "NewDigitalSignature",
        component: CreateNewDigitalSignature,

      },
      {
        path: "firmar-documento-desde-app",
        name: "OriginFromApp",
        component: OriginFromApp,
      },
      {
        path: "agregar-firmantes",
        name: "AddSigners",
        component: AddSigners,
      },
      {
        path: "firmar-documento-desde-local",
        name: "OriginFromLocal",
        component: OriginFromLocal,
      },
      {
        path: "seguimiento",
        name: "Tracking",
        component: TrakingSigners,
      },
      {
        path: "borradores",
        name: "DraftElements",
        component: DraftDocument,
      },
    ],
  },
];
