import * as types from "./mutations-types";

export default {
  setSwitchTenancy({ commit }, data) {
    commit(types.SET_SWITCH_TENANCY, data);
  },
  cleanSwitchTenancy({ commit }) {
    commit(types.CLEAN_SWITCH_TENANCY);
  },

  setModalRef({ commit }, param) {
    commit(types.SET_MODAL_REF, param);
  },
  cleanModalRef({ commit }) {
    commit(types.CLEAN_MODAL_REF);
  },
};
