import SettingsModule from "./SettingsModule";

export const SettingsRoutes = [
  {
    path: "/settings",
    component: SettingsModule,

    children: [
      {
        path: "/",
        name: "dashboardSettings",
      },
    ],
  },
];

