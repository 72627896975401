<template>
  <div class="container__assistance_card">
    <div class="container__assistance_card__left">
      <div class="title__assistance">
        {{ assistance.title }}
      </div>
      <div class="base__information">
        <div class="base__information__labels">
          <div class="label-primary">Tipo de solicitud</div>
          <div class="value-primary">{{ assistance.service_type.title }}</div>
        </div>
        <div class="base__information__labels">
          <div class="label-primary">Solicitante</div>
          <div class="value-primary">
            {{ assistance.requester.first_name }}
            {{ assistance.requester.surname }}
          </div>
        </div>
      </div>
    </div>
    <div class="container__assistance_card__rightCard">
      <div class="number__case">
        <div class="number__case__title">Caso</div>
        <div class="">#{{ assistance.display_id }}</div>
      </div>

      <div
        class="view__button__assistance"
        @click="viewSingleRequest(assistance, assistance.display_id)"
      >
        <ViewAssistanceIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// import ThreeDotsIcon from "../../../assets/companyicons/threedots.svg";
import ViewAssistanceIcon from "../assets/zoom-in.svg";
// import EditAssistanceIcon from "../../../assets/companyicons/editarempresa.svg";
// import ShowAsssitanceIcon from "../../../assets/companyicons/verempresa.svg";

export default {
  props: ["assistance"],
  components: {
    // ThreeDotsIcon,
    // EditAssistanceIcon,
    // ShowAsssitanceIcon,
    ViewAssistanceIcon,
  },
  data: () => {
    return {
      assistance_data: [],
    };
  },
  computed: {
    ...mapGetters("Assistance", {
      setFlagForCurrentOptionsSelectedAssistance:
        "setFlagForCurrentOptionsSelectedAssistance",
      fetchListAssistance: "fetchListAssistance",
    }),
    ...mapGetters("Login", {
      group: "group",
    }),
  },
  methods: {
    viewSingleRequest(assistance, display_id) {
      this.$store.dispatch("Assistance/desactiveTabAssigned");
      let payload = {
        assistance: assistance,
        editModeFromList: false,
      };
      this.$store.dispatch("Assistance/setCurrentAssistance", payload);
      this.$router.push({
        name: "ViewSingleAssistance",
        params: {
          id: display_id,
        },
      });
    },
    EditSingleAssistance(assistance, display_id) {
      let payload = {
        assistance: assistance,
        editModeFromList: true,
      };
      this.$store.dispatch("Assistance/setCurrentAssistance", payload);
      this.$router.push({
        name: "EditSingleAssistance",
        params: {
          id: display_id,
        },
      });
    },
    show_options_assitance(id) {
      this.$store.dispatch(
        "Assistance/setFlagForCurrentOptionsSelectedAssistance",
        false
      );
      this.fetchListAssistance.map((el) => {
        if (el.id == id) {
          if (el.active == true) {
            el.active = false;
            this.$store.dispatch(
              "Assistance/setFlagForCurrentOptionsSelectedAssistance",
              false
            );
          } else {
            el.active = true;
            setTimeout(() => {
              this.$store.dispatch(
                "Assistance/setFlagForCurrentOptionsSelectedAssistance",
                true
              );
            }, 500);
          }
        } else {
          el.active = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.container__assistance_card {
  color: var(--title_pre_step);

  // height: 110px;
  border: 1px solid #9c9c9c;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 19px;
  &__left,
  &__rightCard {
    margin-top: 16px;
  }
  &__left {
    margin-left: 16px;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 23px;
    > .base__information {
      display: flex;
      gap: 1rem;
      @media screen and (max-width: 500px) and (min-width: 200px) {
        flex-wrap: wrap;
      }
      > .base__information__labels {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 30%;
        @media screen and (max-width: 500px) and (min-width: 200px) {
          width: 100% !important;
        }
      }
    }
  }
  &__rightCard {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 65px;
    align-items: flex-end;
    justify-content: center;
    margin-right: 12px;
    @media screen and (max-width: 500px) and (min-width: 200px) {
      justify-content: start !important;
    }

    > .number__case {
      display: flex;
      flex-direction: column;

      > .number__case__title {
        text-align: right;
      }
    }

    > .float__button__card {
      cursor: pointer;
      padding: 0.2rem;
      display: flex;
      border: 1px solid #464646;
      border-radius: 22px;

      > svg {
        transform: scale(1.3);
        width: 26px;
        height: 23px;
        cursor: pointer;
        margin-bottom: 0.1rem;
        fill: var(--title_pre_step);
      }
    }
  }

  .open_options {
    > .nav_options_assistance {
      display: flex;
      flex-direction: column;
      transform: translateY(10%);
      opacity: 1;
      top: 7px;
      left: -161px;
      transition: all 2s ease;
      background: #ffffff;
      width: 156px;
      border-radius: 4px;
      transform: translateY(0%);
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
}
.base__information__labels {
  > .label-primary {
    color: var(--text_profile) !important;
    font-size: 12px;
  }
  > .value-primary {
    color: var(--text_profile) !important;
    font-size: 14px;
  }
}
.container__assistance_card__left {
  > .title__assistance {
    font-size: 20px !important;
  }
}
.fix_color_svg_nav_assistance {
  width: 26px;
  height: 23px;
}
.container__actions__assistance {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  margin-bottom: 5px;
}
.view__button__assistance {
  cursor: pointer;
  padding: 0.2rem;
  display: flex;

  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
