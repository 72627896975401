<template>
  <div class="container-slots container-base">
    <div class="border__bottom">
      <div class="breadcrumb">
        <span class="sign__digital text_document" @click="goToListSignature()">
          Firma Digital >
        </span>

        <span class="text_document">Firmar Documento</span>
      </div>
    </div>

    <div class="title-container m-bottom-l title_origin">
      Selecciona cual es el origen de tu documento
    </div>
    <div class="select_origin">
      <router-link class="select___misabogados" :to="{ name: 'OriginFromApp' }">
        <div class="">
          <span class="title-container">
            Documento redactado por mí en MisAbogados</span
          >
          <img
            class="img__rounded"
            src="../../../assets/icono48px.png"
            alt=""
          />
        </div>
      </router-link>

      <router-link class="select__pc" :to="{ name: 'OriginFromLocal' }">
        <div class=" ">
          <span class="title-container">
            Documento externo a la plataforma</span
          >
          <img class="img" src="../../../assets/cargalocal42px.png" alt="" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewDigitalSignature",
  data: () => {
    return {
      route: "",
    };
  },

  created() {
    let name = this.$route.name;
    this.route = name;
  },

  methods: {
    goToListSignature() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
      this.$router.push({ name: "DigitalSignature" });
    },
  },
};
</script>

<style lang="scss">
.title_origin {
  color: var(--title_origin) !important;
}
.breadcrumb span {
  margin-left: 5px;
}
.title__origin {
  font-size: 14px;
  line-height: 17px;
  margin-top: 16px;
  margin-bottom: 11px;
  color: #464646;
}

.select_origin {
  display: flex;
  gap: 12px;
  > a {
    background: var(--link_origin_document);
  }
  @media screen and (max-width: 600px) and (min-width: 200px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 400px) and (min-width: 200px) {
    & .select___misabogados div,
    .select__pc div {
      text-align: center;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 700px) {
    & > .select___misabogados,
    .select__pc {
      height: 90px !important;
    }
    white-space: nowrap;
    & > .select___misabogados div .title-container,
    .select__pc div .title-container {
      font-size: 12px !important;
    }
    & > .select___misabogados div .img__rounded {
      width: 35px;
    }
  }

  > .select___misabogados,
  .select__pc {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 568px;
    height: 104px;

    border: 1px solid var(--border_link_origin_document);
    box-sizing: border-box;
    border-radius: 12px;

    > div span {
      font-size: 14px;
      line-height: 17px;
      color: var(--text_link_origin_document) !important;
    }
  }
}

.img {
  margin-left: 18px;

  &__rounded {
    margin-left: 18px;
    border-radius: 50%;
  }
}
</style>
