<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'xs'
        ? 'modal__xs'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : size == 'fltr'
        ? 'modal__filter'
        : 'modal__default'
    "
    id="modal__digital__filter"
    ref="modal__digital__filter"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>
    <div class="container__title">
      <div class="title_modal_signature sub-title-pre-step">
        Documentos Firma Digital
      </div>
    </div>
    <div class="container__modal__filters">
      <div class="text-filter title_fecha">Por Fecha</div>
      <div class="container_input_date_modal">
        <div class="custom-select-b">
          <select name="" id="" class="" v-model="year">
            <option value="" selected disabled hidden>Año</option>

            <option v-for="(item, index) in years" :key="index" :value="item">
              {{ item }}
            </option>
          </select>
          <span class="custom-arrow-modal"></span>
        </div>
        <div class="custom-select-b">
          <select name="" id="" class="" v-model="month">
            <option value="" selected disabled hidden>Mes</option>

            <option
              v-for="(item, index) in months"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
          <span class="custom-arrow-modal"></span>
        </div>
      </div>

      <div class="text-filter title_fecha">Por Estado</div>

      <div class="container_check_modal">
        <div class="check_pending">
          <input type="checkbox" value="1" id="" v-model="inputPendiente" />
          <div class="">Pendiente</div>
        </div>

        <div class="check_end">
          <input type="checkbox" value="2" v-model="inputFinalizado" id="" />
          <div class="">Finalizado</div>
        </div>
      </div>
      <div class="footer_modal">
        <div
          disabled="disabled"
          class="secondary_button"
          @click="cancelModal()"
        >
          <span> Cancelar</span>
        </div>
        <div
          class="btn-vue-primary fix-btn-mac"
          :class="
            inputFinalizado ||
            inputPendiente ||
            month.length > 0 ||
            year.length > 0
              ? ''
              : 'desactive_set_draf'
          "
          @click="FilterDataDigitalSignature()"
        >
          <span> Aceptar </span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/components/modal/assets/cancel.svg";

import { mapGetters, mapState } from "vuex";

export default {
  props: ["size"],
  components: {
    CancelIcon,
  },

  data: function () {
    return {
      years: ["2020", "2021", "2022"],
      year: "",
      inputFinalizado: false,
      inputPendiente: false,
      months: [
        { name: "Enero", value: "01" },
        { name: "Febrero", value: "02" },
        { name: "Marzo", value: "03" },
        { name: "Abril", value: "04" },
        { name: "Mayo", value: "05" },
        { name: "Junio", value: "06" },
        { name: "Julio", value: "07" },
        { name: "Agosto", value: "08" },
        { name: "Septiembre", value: "09" },
        { name: "Octubre", value: "10" },
        { name: "Noviembre", value: "11" },
        { name: "Diciembre", value: "12" },
      ],
      month: "",
    };
  },

  computed: {
    ...mapState({
      searchAll: (state) => state.DigitalSignature.setSearchAll,
      searchPending: (state) => state.DigitalSignature.setSearchPending,
      searchComplete: (state) => state.DigitalSignature.setSearchComplete,
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let modal = document.querySelector("#modal__digital__filter");
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      let modal = document.querySelector("#modal__digital__filter");
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    async FilterDataDigitalSignature() {
      this.$bvModal.hide("modal");

      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let sign_status__range;

      if (this.inputPendiente == true || this.searchPending == true) {
        this.$store.dispatch("DigitalSignature/SetSearchPending");
        sign_status__range = "2,2";
      }
      if (this.inputFinalizado == true || this.searchComplete == true) {
        sign_status__range = "3,3";
        this.$store.dispatch("DigitalSignature/SetSearchComplete");
      }
      if (this.inputFinalizado && this.inputPendiente) {
        sign_status__range = "2,3";
        // this.$store.dispatch("DigitalSignature/SetSearchAll");
      }

      let inputFinalizadoTags = this.inputFinalizado;
      let inputPendienteTags = this.inputPendiente;

      let sign_init_date__year = this.year ? this.year : null;
      let sign_init_date__month = this.month ? this.month : null;

      let valueMonth =
        sign_init_date__month == "01"
          ? "Enero"
          : sign_init_date__month == "02"
          ? "Febrero"
          : sign_init_date__month == "03"
          ? "Marzo"
          : sign_init_date__month == "04"
          ? "Abril"
          : sign_init_date__month == "05"
          ? "Mayo"
          : sign_init_date__month == "06"
          ? "Junio"
          : sign_init_date__month == "07"
          ? "Julio"
          : sign_init_date__month == "08"
          ? "Agosto"
          : sign_init_date__month == "09"
          ? "Septiembre"
          : sign_init_date__month == "10"
          ? "Octubre"
          : sign_init_date__month == "11"
          ? "Noviembre"
          : sign_init_date__month == "12"
          ? "Diciembre"
          : "Año";

      let tagsForfront;
      if (this.searchComplete == true) {
        tagsForfront = [
          {
            name: sign_init_date__year,
            value: sign_init_date__year,
          },
          { name: valueMonth, value: sign_init_date__month },
          { name: "Finalizados", value: true },
          { name: "Pendiente", value: inputPendienteTags },
        ];
      } else if (this.searchPending == true) {
        tagsForfront = [
          {
            name: sign_init_date__year,
            value: sign_init_date__year,
          },
          { name: valueMonth, value: sign_init_date__month },
          { name: "Finalizados", value: inputFinalizadoTags },
          { name: "Pendiente", value: true },
        ];
      } else {
        tagsForfront = [
          {
            name: sign_init_date__year,
            value: sign_init_date__year,
          },

          { name: valueMonth, value: sign_init_date__month },
          { name: "Finalizados", value: inputFinalizadoTags },
          { name: "Pendiente", value: inputPendienteTags },
        ];
      }

      let tagForReloadRequest = [
        sign_init_date__year,
        sign_init_date__month,
        inputFinalizadoTags,
        inputPendienteTags,
      ];

      let tagsList = true;

      await this.$store.dispatch("DigitalSignature/setTags", {
        tagsForfront,
        tagForReloadRequest,
        tagsList,
      });
      let fields =
        "id,document_type,sign_author,tags,sign_init_date,sign_end_date,sign_user_body,sign_compute_body,sign_archived,sign_status,sign_type";

      // await this.$store.dispatch("DigitalSignature/setGlobalLoader");

      await this.$store.dispatch("DigitalSignature/fetchDigitalSignature", {
        company_id,
        fields,
        sign_status__range,
        sign_init_date__year,
        sign_init_date__month,
      });

      setTimeout(() => {
        this.$store.dispatch("DigitalSignature/closeGlobalLoader");
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.container_modal_title_signature {
  margin-left: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

.title_modal_signature {
  line-height: 0 !important;
}

.title_fecha {
  margin-top: 20px;
  margin-bottom: 11px;
}

.icon_modal_filter {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.cancel-icon {
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-right: 4rem;
}

.container_input_date_modal {
  display: flex;
  gap: 1rem;
  @media screen and (max-width: 768px) and (min-width: 200px) {
    flex-wrap: wrap;
  }
}

.custom-select-b {
  position: relative;
  display: flex;

  > select {
    border: 1px solid #a5a5a5 !important;
    width: 175px;
  }
}

.custom-arrow-modal {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 1.5em;
  pointer-events: none;

  &::before,
  &::after {
    --size: 0.35em;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.custom-arrow-modal::after {
  border-color: #000000;
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid $primary;
  top: 55%;
}

//container check
.container_check_modal {
  gap: 6.5rem;
}
.check_pending,
.check_end {
  gap: 0.5rem;
}
.container_check_modal,
.check_pending,
.check_end {
  display: flex;
  align-items: center;
}
.container__modal__filters {
  margin-left: 2rem;
}
.container__title {
  margin-bottom: 2rem;
  > .title_modal_signature {
    color: var(--title_pre_step) !important;
  }
}
.container__modal__filters {
  > .title_fecha {
    color: var(--title_origin) !important;
  }
}
.check_pending {
  > div {
    color: var(--title_origin) !important;
  }
}
.check_end {
  > div {
    color: var(--title_origin) !important;
  }
}
.custom-select-b select {
  color: var(--text_item_selection_company) !important;
  background-color: var(--input_focus_background) !important;
}
.footer_modal{
.secondary_button{
  background-color: var(--button_verify_employee) !important;
}
}
.footer_modal{
.desactive_set_draf{
  background-color: var(--button_verify_employee) !important;
}
}
</style>
