import api from '../../../service/loginApi';
import simpleRequest from '../../../service/simpleRequestService';

export default {
    auth_login(payload) {
        return api.post('/auth/login/', payload).then(response => response.data);
    },
    reset_password(payload) {
        return api.post('/auth/password/reset/', payload).then(response => response.data);
    },
    reset_password_exec(uuid, token, payload) {
        return api.post(`/recover_password/${uuid}/${token}/`, payload).then(response => response.data);
    },

    verify_account(token) {
        return simpleRequest.get(`/account_confirm_email/${token}/`).then(response => response.data);
    },

    verify_oauth_google(payload) {
        return simpleRequest.post(`/social-login/google/`, payload).then(response => response.data);
    },
    verify_ms_google(payload) {
        return simpleRequest.post(`/social-login/microsoft/`, payload).then(response => response.data);
    }
};
