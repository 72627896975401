<template>
  <div class="container__module__employeev2">
    <div class="container_base_V2 container_fix_employee">
      <div class="container__header__employee">
        <HeadListEmployee />
      </div>
      <div class="container__header__employee__mobile">
        <HeadListEmployeeMobile />
      </div>

      <div class="container_search_employee_mobile">
        <div class="search__employee_mobile">
          <div class="icon_search_employee">
            <IconSearch />
          </div>
          <input
            class="input__search__table__employee__mobile"
            type="text"
            v-model="dataSearch"
            placeholder="Buscar un miembro del equipo "
            v-on:keyup.enter="searchEmployee()"
          />
        </div>
      </div>

      <div class="container_swap_table_employee">
        <div class="container_table_selection_employee">
          <div
            class="selected_assistance container_title_table"
            :class="activeTableMembers == false ? 'active' : 'desactive'"
            @click="selectedTable('table1')"
          >
            Usuarios
          </div>
          <div
            class="selected_assistance container_title_table"
            :class="activeTableMembers ? 'active' : 'desactive'"
            @click="selectedTable('table2')"
          >
            Miembros
          </div>
          <div id="tab-indicator" class="tab-indicator"></div>
        </div>
        <div class="container_search_employee">
          <div class="search__employee">
            <div class="icon_search_employee">
              <IconSearch />
            </div>
            <input
              class="input__search__table__employee"
              type="text"
              v-model="dataSearch"
              placeholder="Buscar un miembro del equipo "
              v-on:keyup.enter="searchEmployee()"
            />
          </div>
          <div class="input_search_filter">
            <div
              class="container_filter_input_search"
              v-if="!activeTableMembers && activeInputSearchUser"
            >
              {{ activeInputSearchUser }}
              <CloseIcon @click="CloseInputSearchUsers()" />
            </div>
            <div
              class="container_filter_input_search"
              v-if="activeTableMembers && activeInputSearchMember"
            >
              {{ activeInputSearchMember }}
              <CloseIcon @click="CloseInputSearchMembers()" />
            </div>
            <div class="" v-else></div>
          </div>
        </div>
      </div>

      <div class="container_employee_table">
        <Transition class="" v-if="!activeTableMembers" name="fade">
          <TableEmployee1 />
        </Transition>
        <Transition class="" v-else name="fade">
          <TableEmployee2 />
        </Transition>
      </div>
      <div class="list_tags_assistance tags_container_mobile"></div>
    </div>
  </div>
</template>

<script>
// table components
import TableEmployee1 from "../components/Tables/TableEmployee1.vue";
import TableEmployee2 from "../components/Tables/TableEmployee2.vue";
import CloseIcon from "../assets/close-input-search.svg";

// header componets
import HeadListEmployee from "../components/headerTable/HeadListEmployee.vue";
import HeadListEmployeeMobile from "../components/headerTable/HeadListEmployeeMobile.vue";

// import loaderGeneric from "@/components/loaderGeneric/Loading.vue";

import IconSearch from "../assets/icon-search.svg";

import { mapGetters, mapState } from "vuex";

export default {
  components: {
    TableEmployee1,
    TableEmployee2,
    HeadListEmployee,
    IconSearch,
    CloseIcon,
    HeadListEmployeeMobile,
    // loaderGeneric,
  },
  data: () => {
    return {
      selected_quantity: "10",
      amount_of_rows: {
        10: 10,
        50: 50,
        100: 100,
      },
      page: 1,
      limit: "10",
      offset: null,
      dataSearch: null,
      loaderSkeleton: false,
      DefaultInfo: true,
      listTotalCompanies: [],
      listTotalCompaniesMembers: [],
      inputSearchUsers: "",
      inputSearchMembers: "",
    };
  },
  created() {
    this.$store.dispatch("EmployeesV2/cleanCurrentViewEmployee");
    this.listTotalCompanies = this.list__companies;
    this.listTotalCompaniesMembers = this.list__employee_members;
    // this.$store.dispatch("EmployeesV2/setTableActive", false);
    this.$store.dispatch("EmployeesV2/cleancounterStepEmployee");
  },
  mounted() {
    if (this.activeTableMembers) {
      let indicator = document.getElementById("tab-indicator");
      indicator.style.left = "50%";
    }
  },

  computed: {
    ...mapGetters("EmployeesV2", {
      list__companies: "list__companies",
      totalPages: "totalPages",
      nextPage: "nextPage",
      prevPage: "prevPage",
      total_data_view: "total_data_view",
      skeletonFilter: "skeletonFilter",
      list__employee_members: "list__employee_members",
      activeDropDownThreeDots: "activeDropDownThreeDots",
      activeTableMembers: "activeTableMembers",
      activeInputSearchUser: "activeInputSearchUser",
      activeInputSearchMember: "activeInputSearchMember",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
      user_profile: "user_profile",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapState({
      company: (state) => state.Login.company,
    }),
  },
  methods: {
    searchEmployee() {
      let filterFromInput = this.dataSearch;

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      this.page = 1;
      this.selected_quantity = "10";
      let user = this.user_profile;
      let id = user.id;
      let input_search = filterFromInput;

      if (this.inSwitch) {
        let company_id = admin_company_id;

        if (this.activeTableMembers) {
          if (this.dataSearch != null) {
            this.inputSearchMembers = this.dataSearch;
            this.$store.dispatch(
              "EmployeesV2/setActiveInputSearchMember",
              this.inputSearchMembers
            );
          } else {
            this.inputSearchMembers = "";
            this.$store.dispatch("EmployeesV2/setInactiveInputSearchMember");
          }
          this.$store.dispatch(
            "EmployeesV2/inputSearchEmployeeMembersInSwitch",
            {
              company_id: company_id,
              company: company_id,
              input_search,
              is_member: true,
              id: id,
            }
          );
        } else {
          if (this.dataSearch != null) {
            this.inputSearchUsers = this.dataSearch;
            this.$store.dispatch(
              "EmployeesV2/setActiveInputSearchUser",
              this.inputSearchUsers
            );
          } else {
            this.$store.dispatch("EmployeesV2/setInactiveInputSearchUser");
            this.inputSearchUsers = "";
          }
          this.$store.dispatch("EmployeesV2/inputSearchEmployeeInSwitch", {
            company_id: company_id,
            company: company_id,
            input_search,
            id: id,
          });
        }
      } else {
        let company_id = admin_company_id;
        if (this.activeTableMembers) {
          if (this.dataSearch != null) {
            this.inputSearchMembers = this.dataSearch;
            this.$store.dispatch(
              "EmployeesV2/setActiveInputSearchMember",
              this.inputSearchMembers
            );
          } else {
            this.$store.dispatch("EmployeesV2/setInactiveInputSearchMember");
          }
          this.$store.dispatch("EmployeesV2/inputSearchEmployeeMembers", {
            company_id,
            input_search,
            is_member: true,
            id: id,
          });
        } else {
          if (this.dataSearch != null) {
            this.inputSearchUsers = this.dataSearch;
            this.$store.dispatch(
              "EmployeesV2/setActiveInputSearchUser",
              this.inputSearchUsers
            );
          } else {
            this.$store.dispatch("EmployeesV2/setInactiveInputSearchUser");
          }
          this.$store.dispatch("EmployeesV2/inputSearchEmployee", {
            company_id,
            input_search,
            id: id,
          });
        }
      }

      this.dataSearch = null;
    },

    selectedTable(value) {
      let indicator = document.getElementById("tab-indicator");

      if (value == "table1") {
        this.$store.dispatch("EmployeesV2/setTableActive", false);

        indicator.style.left = "0%";
      } else {
        this.$store.dispatch("EmployeesV2/setTableActive", true);
        indicator.style.left = "50%";
      }
    },
    CloseInputSearchMembers() {
      this.$store.dispatch("EmployeesV2/setInactiveInputSearchMember");
      if (this.inSwitch) {
        let user = this.user_profile;
        let id = user.id;
        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company.id;
        let company_id = admin_company_id;
        let offset = 0;

        let payload = {
          id_company: company_id,
          limit: "10",
          offset: offset,
          first_name__icontains: null,
          id: id,
        };

        this.$store.dispatch(
          "EmployeesV2/get_list_members_InSwitchTenancy",
          payload
        );
      } else {
        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company.id;
        let offset = 0;
        let company_id = admin_company_id;
        let payload = {
          company_id: company_id,
          limit: "10",
          offset: offset,
        };
        this.dataSearch = null;
        this.inputSearchMembers = "";
        this.$store.dispatch("EmployeesV2/get_list_employee_members", payload);
      }
    },
    CloseInputSearchUsers() {
      this.$store.dispatch("EmployeesV2/setInactiveInputSearchUser");

      if (this.inSwitch) {
        let user = this.user_profile;
        let id = user.id;
        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company.id;
        let company_id = admin_company_id;
        let offset = 0;

        let payload = {
          id_company: company_id,
          limit: "10",
          offset: offset,
          first_name__icontains: null,
          id: id,
        };

        this.$store.dispatch(
          "EmployeesV2/get_list_employee_InSwitchTenancy",
          payload
        );
      } else {
        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company.id;
        let offset = 0;
        let company_id = admin_company_id;
        let payload = {
          company_id: company_id,
          limit: "10",
          offset: offset,
        };
        this.dataSearch = null;
        this.inputSearchUsers = "";
        this.$store.dispatch("EmployeesV2/get_list_employee", payload);
      }
    },
  },
};
</script>

<style lang="scss">
.container__module__employeev2 {
  display: grid;
  grid-template-columns: 98%;

  // margin-top: 1rem;

  @media screen and (max-width: 500px) and (min-width: 200px) {
    grid-template-columns: 99%;
    margin-top: 0.5rem;
  }
}

.publicity_principal_information {
  background-color: #bfd8ff !important;
  margin-left: 24px;
  margin-top: 80px;
  margin-right: 24px;
  padding-bottom: 94px;
}
.contianer_footer_publicity_employee {
  margin-left: 24px;
  margin-right: 24px;
  padding-bottom: 94px;
}
.container_fix_employee {
  margin-right: 0px !important;
  margin-left: 24px;
  margin-top: 80px;
  padding-bottom: 1rem;
}
.container_fix_employee_table {
  margin-right: 34px !important;
  margin-left: 24px;
  background: var(--container_base);
  padding: 1rem;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container_swap_table_employee {
  margin-top: 0.8rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  @media screen and (max-width: 500px) and (min-width: 200px) {
    justify-content: space-between;
    gap: 37px;
    margin-top: 7px;
  }
}
.container_table_selection_employee {
  display: flex;
  width: calc(100% / 4);

  @media screen and (max-width: 600px) and (min-width: 200px) {
    width: calc(95%);
    margin-left: 0.5rem;
  }

  //TODO: add mediaquery divide in 2
  // width: calc(100% / 2);
  position: relative;

  > .selected_assistance {
    color: #000032;
    cursor: pointer;
    width: 50%;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
  }
  > .selected_assistance.active {
    color: white !important;
  }
  > .selected_assistance.desactive {
    background-color: rgba(128, 131, 147, 0.2);
    border-radius: 8px 8px 0px 0px;
    transition: all 250ms ease-in-out;
  }
}

.tab-indicator {
  background: #000032;
  position: absolute;
  width: calc(100% / 2);
  height: 39px;
  border-radius: 8px 8px 0px 0px;
  left: 0%;
  transition: all 350ms ease-in-out;
}
.input__search__table__employee {
  width: 400px;
  margin-right: 20px;
}
.input__search__table__employee__mobile {
  width: 100%;
}
.input__search__table__employee,
.input__search__table__employee__mobile {
  height: 38px;
  border: 1px solid #a5a5a5;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  padding: 0.5rem 0.7rem;
  cursor: pointer;

  &:focus {
    color: #000032;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0.25rem rgba(13, 110, 253, 25);
  }
}
.search__employee {
  position: relative;
  bottom: 18%;
}
.search__employee_mobile {
  width: 100%;
  margin-top: 1rem;
  margin: 0.8rem 0.8rem;
}

.search__employee,
.search__employee_mobile {
  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: flex;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  > .input__search__table__employee {
    color: var(--text_item_selection_company) !important;
  }
}
.container_search_employee {
  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: none !important;
  }
}
.container_search_employee,
.container_search_employee_mobile {
  flex-direction: row-reverse !important;
  display: flex;
  gap: 1rem;

  align-items: center;
}
.container_search_employee_mobile {
  @media screen and (max-width: 4000px) and (min-width: 601px) {
    display: none;
  }
}
.icon_search_employee {
  position: absolute;
  display: flex;
  margin-top: 0.6rem;
  margin-left: 1rem;

  > svg {
    fill: var(--text_link_company) !important;
    width: 30px;
    // position: absolute;
  }
}
.container_employee_table {
  // margin-left: -16px !important;
  width: 100% !important;
}
.container__header__employee {
  display: flex;
  @media screen and (max-width: 767px) and (min-width: 200px) {
    display: none !important;
  }
}

.container__header__employee__mobile {
  display: none !important;

  @media screen and (max-width: 767px) and (min-width: 200px) {
    display: flex !important;
  }
}
.container__header__employee,
.container__header__employee__mobile {
  flex-direction: column;
  gap: 13px;
  width: 100%;
  color: var(--title_pre_step);
}
.input_search_filter {
  color: var(--title_profile) !important;
  font-size: 14px !important;
  background-color: #bfd8ff;
  fill: solid;
  border-radius: 4px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_filter_input_search {
  display: flex;
  white-space: nowrap;
  gap: 0.5rem;
  margin: 0.1rem 0.7rem 0.2rem 0.2rem;
  > svg {
    margin-top: 7px;
  }
}
</style>
