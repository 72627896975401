<template>
  <div class="container-slots">
    <router-view v-if="moduleIsReady" />
    <div class="center-loader" v-else>
      <loaderGeneric />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  created() {
    this.loadCategoryScheduling();
  },
  data: () => {
    return {
      dataCheck: true,
    };
  },
  computed: {
    moduleIsReady() {
      return this.dataCheck;
    },
    ...mapGetters("Login", {
      admin_company_id: "company_id",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
    }),

  },
  methods: {
    loadCategoryScheduling() {
      this.$store.dispatch("Schedule/cleanSelecteScheduling");
      const format = "YYYY-MM-DD";
      let schedule_date__gte = moment().format(format);
      let schedule_date__lte = moment()
        .add(14, "days")
        .format(format);
      let schedule_author = null;

      let admin_company_id = this.idInSwitch;
      let limit = 14;

      let params = {
        admin_company_id,
        schedule_date__gte,
        schedule_date__lte,
        schedule_author,
        limit,
      };
      this.$store.dispatch("Schedule/getallMeetings", params);
    },
  },
};
</script>

<style></style>
