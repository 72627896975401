import * as types from "./mutations-types";
import integratedAssistance from "../services/integratedAssistance";

export default {
  getSingleAssitanceFromUrl({ commit }, { admin_company_id, id_assitance }) {
    return new Promise((resolve, reject) => {
      commit(types.SKELETON_FILTER_ON);
      integratedAssistance
        .getSingleAssistance(admin_company_id, id_assitance)
        .then((data) => {
          let currentCompany = data.requester.company;

          let company = {
            name: currentCompany.name,
            id: currentCompany.id,
          };

          commit(types.SET_CURRENT_ASSISTANCE, data);
          commit(types.COMPANY_CURRENT_REQUEST, company);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSingleAssitance({ commit }, { admin_company_id, id_assitance }) {
    return new Promise((resolve, reject) => {
      integratedAssistance
        .getSingleAssistance(admin_company_id, id_assitance)
        .then((data) => {
          commit(types.SET_CURRENT_ASSISTANCE, data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cleanSingleAssitance({ commit }) {
    commit(types.CLEAN_COMPANY_CURRENT_REQUEST);
  },
  setFalseAssitance({ commit }) {
    commit(types.SET_FALSE_MODAL_ASSISTANCE);
  },

  setTrueAssitance({ commit }) {
    commit(types.SET_TRUE_MODAL_ASSISTANCE);
  },

  getAssistanceWithFilters(
    { commit },
    {
      admin_company_id,
      fields,
      limit = "5",
      offset = null,
      service_type = null,
      created_at__date__range = null,
      requester = null,
    }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.CLEAN_DATA_FILTERS_ACTIVE);
      commit(types.SKELETON_FILTER_ON);
      commit(types.FLAG_ACTIVE_FILTER);
      commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
      integratedAssistance
        .getAllAssistanceFilters(
          admin_company_id,
          fields,
          limit,
          offset,
          service_type,
          created_at__date__range,
          requester
        )
        .then((data) => {
          let totalData = data.count;
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.GET_LIST_ASSISTANCE, data.results);
          commit(types.TOTAL_PAGES_DATA, { limit, totalData });
          resolve(true);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS);

            // with this we desactive the filter tags (this indicate there is no actual filter begin use)
            commit(types.FLAG_DESACTIVE_FILTER);

            // with this we clean (value = null) the dataActiveFilters (where we save the input search)
            commit(types.CLEAN_DATA_FILTERS_ACTIVE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS);
          }

          commit(types.SKELETON_FILTER_OFF);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS);
          commit(types.FLAG_DESACTIVE_FILTER);
          commit(types.CLEAN_DATA_FILTERS_ACTIVE);
        });
    });
  },

  getAllAssistance({ commit }, { admin_company_id, fields, limit = "5" }) {
    return new Promise((resolve, reject) => {
      commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
      commit(types.SKELETON_FILTER_ON);
      integratedAssistance
        .getAllAssistance(admin_company_id, fields, limit)
        .then((data) => {
          let totalData = data.count;
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.GET_LIST_ASSISTANCE, data.results);
          commit(types.TOTAL_PAGES_DATA, { limit, totalData });
          resolve(true);

          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS);

            // with this we desactive the filter tags (this indicate there is no actual filter begin use)
            commit(types.FLAG_DESACTIVE_FILTER);
            // with this we active noResultFlaf for watch in list asssitance for update pagination values.
            commit(types.FLAG_ACTIVE_RESULTS);

            // with this we clean (value = null) the dataActiveFilters (where we save the input search)
            commit(types.CLEAN_DATA_FILTERS_ACTIVE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS);
          }
          setTimeout(() => {
            commit(types.SKELETON_FILTER_OFF);
          }, 1000);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  inputSearchAssistance(
    { commit },
    {
      admin_company_id,
      limit = "5",
      display_id = null,
      title__icontains = null,
      service_type = null,
      created_at__date__range = null,
      requester = null,
      offset = null,
    }
  ) {
    return new Promise((resolve, reject) => {
      let payload = [display_id, title__icontains];
      // with this commit we know a filter is active
      commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      commit(types.DATA_FILTERS_ACTIVE, payload);
      commit(types.SKELETON_FILTER_ON);
      // with this we activate the input search (this indicate there is  input search begin use)
      commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      integratedAssistance
        .inputSearchAsisistance(
          admin_company_id,
          limit,
          display_id,
          title__icontains,
          service_type,
          created_at__date__range,
          requester,
          offset
        )
        .then((data) => {
          let totalData = data.count;
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.GET_LIST_ASSISTANCE, data.results);
          commit(types.TOTAL_PAGES_DATA, { limit, totalData });
          resolve(true);
          commit(types.SKELETON_FILTER_OFF);

          // we evaluate if the results of the filter are 0 , in that case we use the flag for No results (HTML)
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS);

            commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
            // with this we desactive the filter tags (this indicate there is no actual filter begin use)
            commit(types.FLAG_DESACTIVE_FILTER);

            // with this we clean (value = null) the dataActiveFilters (where we save the input search)
            commit(types.CLEAN_DATA_FILTERS_ACTIVE);
          } else {
            // with this we desactive the commit of "No datos disponibles"
            commit(types.FLAG_DESACTIVE_RESULTS);
          }
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS);
          commit(types.FLAG_DESACTIVE_FILTER);
          commit(types.CLEAN_DATA_FILTERS_ACTIVE);
          commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },

  getGroupsAssitance({ commit }) {
    return new Promise((resolve, reject) => {
      integratedAssistance
        .getListGroupsAssistance()
        .then((data) => {
          commit(types.SET_LIST_GROUP_ASSISTANCE, data.results);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setCurrentAssistance({ commit }, payload) {
    if (payload.editModeFromList) {
      commit(types.SET_CURRENT_ASSISTANCE, payload.assistance);
      commit(types.ACTIVE_EDIT_ASSISTANCE_FROM_LIST);
    } else {
      //do not enter edit mode from list assistance
      commit(types.SET_CURRENT_ASSISTANCE, payload.assistance);
      commit(types.DESACTIVE_EDIT_ASSISTANCE_FROM_LIST);
      commit(types.DESACTIVE_EDIT_ASSISTANCE_MODE);
    }
  },
  cleanCurrentAsistance({ commit }) {
    commit(types.CLEAN_SINGLE_ASSISTANCE);
  },

  activeEditMode({ commit }) {
    commit(types.DESACTIVE_EDIT_ASSISTANCE_FROM_LIST);
    commit(types.ACTIVE_EDIT_ASSISTANCE_MODE);
  },

  desactiveEditMode({ commit }) {
    commit(types.DESACTIVE_EDIT_ASSISTANCE_FROM_LIST);
    commit(types.DESACTIVE_EDIT_ASSISTANCE_MODE);
  },
  desactiveEditModeLocal({ commit }) {
    commit(types.DESACTIVE_EDIT_ASSISTANCE_MODE);
  },

  setFlagForCurrentOptionsSelectedAssistance({ commit }, data) {
    commit(types.SET_FLAGS_SELECTED_OPTIONS_ASSISTANCE, data);
  },

  // next pagination

  nextPagination({ commit }, { nextUrl }) {
    // commit(types.LOADER_COMPANY_OFF);
    commit(types.SKELETON_FILTER_ON);
    return new Promise((resolve, reject) => {
      integratedAssistance
        .nextPage(nextUrl)
        .then((data) => {
          commit(types.SET_PREV_PAGE, data.previous);
          commit(types.SET_NEXT_PAGE, data.next);
          commit(types.SET_TOTAL_DATA, data.count);
          commit(types.GET_LIST_ASSISTANCE, data.results);
          commit(types.SKELETON_FILTER_OFF);
          resolve(true);

          if (data.count == 0) {
            commit(types.SET_FLAG, false);
          }
        })
        .then(() => {
          // commit(types.LOADER_COMPANY_ON);
        })
        .catch((error) => {
          reject(error);

          commit(types.SET_FLAG, false);
          // commit(types.LOADER_COMPANY_ON);
        });
    });
  },
  setFilterActiveInMobileAssistance({ commit }) {
    commit(types.SET_FILTER_ASSISTANCE_MOBILE);
  },
  cleanFilterInMobileAssistance({ commit }) {
    commit(types.CLEAN_FILTER_ASSISTANCE_MOBILE);
  },
  addTagsOfFilters({ commit }, payloadForTags) {
    commit(types.FLAG_ACTIVE_FILTER);
    // skeleton loader for when we add tag (skeleton ON)
    commit(types.SKELETON_FILTER_ON);
    commit(types.ADD_TAGS_OF_FILTERS, payloadForTags);
    // skeleton loader for when we add tag (skeleton OFF)
    setTimeout(() => {
      commit(types.SKELETON_FILTER_OFF);
    }, 1000);
  },
  cleanTagsOfFilter({ commit }) {
    commit(types.CLEAN_TAGS_OF_FILTERS);
  },
  cleanSelectedTagsOfFilter({ commit }, payloadForTags) {
    commit(types.SKELETON_FILTER_ON);
    if (payloadForTags.length <= 0) {
      commit(types.CLEAN_TAGS_OF_FILTERS);
      commit(types.FLAG_DESACTIVE_FILTER);
      setTimeout(() => {
        commit(types.SKELETON_FILTER_OFF);
      }, 1000);
    } else {
      // skeleton loader for clean a singular tag (skeleton ON)
      commit(types.CLEAN_SELECTED_TAGS_OF_FILTERS, payloadForTags);
      // skeleton loader for clean a singular tag (skeleton OFF)
      setTimeout(() => {
        commit(types.SKELETON_FILTER_OFF);
      }, 1000);
    }
  },
  filterSelectedTags({ commit }, payload) {
    commit(types.FILTER_SELECTED_TAGS, payload);
  },

  loadFilterOn({ commit }) {
    commit(types.SKELETON_FILTER_ON);
  },
  loadFilterOff({ commit }) {
    commit(types.SKELETON_FILTER_OFF);
  },

  activeFilter({ commit }) {
    commit(types.FLAG_ACTIVE_FILTER);
  },
  deactiveFilter({ commit }) {
    commit(types.FLAG_DESACTIVE_FILTER);
  },
  activeNoResultsFlag({ commit }) {
    commit(types.FLAG_ACTIVE_RESULTS);
  },
  cleanFiltersResult({ commit }) {
    commit(types.CLEAN_DATA_FILTERS_ACTIVE);
  },
  //employees

  getListEmployees({ commit }, { id_company, fields }) {
    return new Promise((resolve, reject) => {
      integratedAssistance
        .getEmployees(id_company, fields)
        .then((data) => {
          commit(types.EMPLOYE_DATA, data);

          resolve(true);
          // this.loading1 = false;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //get list employees for assigned quotation
  getListEmployeesAssigned(
    { commit },
    { admin_company_id, companyRequester, group_id_in, fields }
  ) {
    return new Promise((resolve, reject) => {
      integratedAssistance
        .getAssignedList(
          admin_company_id,
          companyRequester,
          group_id_in,
          fields
        )
        .then((data) => {
          commit(types.SET_LIST__EMPLOYES_ASIGNED, data);

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //edit assigned employees
  editEmployeesAsigned(
    { commit },
    { admin_company_id, formDataFields, id_request }
  ) {
    return new Promise((resolve, reject) => {
      integratedAssistance
        .editAssignedUsers(admin_company_id, formDataFields, id_request)
        .then(() => {
          commit(types.SET_NEW_ASSIGNED_USERS);

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getListRequesterAssigned(
    { commit },
    { admin_company_id, companyRequester, group_id_in, fields }
  ) {
    return new Promise((resolve, reject) => {
      integratedAssistance
        .getAssignedList(
          admin_company_id,
          companyRequester,
          group_id_in,
          fields
        )
        .then((data) => {
          commit(types.SET_LIST_REQUESTER_EMPLOYES_ASIGNED, data);

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getListRequesterAssignedForSwitch(
    { commit },
    { admin_company_id, companyRequester, group_id_in, fields }
  ) {
    return new Promise((resolve, reject) => {
      integratedAssistance
        .getAssignedListForSwitch(
          admin_company_id,
          companyRequester,
          group_id_in,
          fields
        )
        .then((data) => {
          commit(types.SET_LIST_REQUESTER_EMPLOYES_ASIGNED, data);

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  desactiveInputSearchFlag({ commit }) {
    commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
  },
  activeInputSearchFlag({ commit }) {
    commit(types.FLAG_ACTIVE_INPUT_SEARCH);
  },

  desactiveLoader({ commit }) {
    commit(types.DESACTIVE_LOADER);
  },

  deleteSingleAssitance({ commit }, { admin_company_id, id_assitance }) {
    commit(types.ACTIVE_LOADER);

    return new Promise((resolve, reject) => {
      integratedAssistance
        .deleteAssistance(admin_company_id, id_assitance)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.DESACTIVE_LOADER);
        });
    });
  },

  setActiveInputSearchTags({ commit }, data) {
    commit(types.FLAG_ACTIVE_INPUT_SEARCH_TAGS, data);
  },
  setInactiveInputSearchTags({ commit }) {
    commit(types.FLAG_INACTIVE_INPUT_SEARCH_TAGS);
  },

  cleancounterStepAssistanceOnBoarding({ commit }, payload) {
    commit(types.CLEAN_COUNTER__STEP__ASSISTANCE_ONBOARDING, payload);
  },

  counterStepAssistanceOnBoarding({ commit }, payload) {
    commit(types.COUNTER__STEP__ASSISTANCE_ONBOARDING, payload);
  },

  updateCurrentFiles({ commit }, payload) {
    commit(types.UPDATE_CURRENT_FILES, payload);
  },

  activeTabAssigned({ commit }) {
    commit(types.TAB_ASSIGNED_ACTIVE);
  },
  desactiveTabAssigned({ commit }) {
    commit(types.TAB_ASSIGNED_DESACTIVE);
  },

  assistanceSaveRouteParamsId({ commit }, route_paramsID) {
    commit(types.ASSISTANCE_ROUTE_PARAMS_ID, route_paramsID);
  },
  cleanAssistanceSaveRouteParamsId({ commit }) {
    commit(types.CLEAN_ASSISTANCE_ROUTE_PARAMS_ID);
  },

  //action for force assigment
  addAssignedUserForRequest({ commit }, payload) {
    commit(types.FORCE_REQUESTER_ASIGNED, payload);
  },
  cleanAssignedUserForRequest({ commit }) {
    commit(types.CLEAN_FORCE_REQUESTER_ASIGNED);
  },
};
