<template>
  <div class="">
    <ModalConfirmRemove
      name_modal="modal__assitance__remove"
      :identifier_modal="idRquest"
      :subject="title"
      size="count"
      ref="modal__assitance__remove"
    />

    <div class="container_basic_information_assistance" v-if="!edit">
      <div class="header_base_information">
        <div class="title-container">{{ title }}</div>
        <div
          class="container__icons__assistance"
          v-if="
            group == 'Lawyer' ||
            group == 'Agent' ||
            group == 'Admin' ||
            group == 'Company-Executive'
          "
        >
          <EditIcon @click="editMode()" />
          <Trash
            v-if="group != 'Company-Executive'"
            class="trash__ico"
            @click="show_modal_remove()"
          />
        </div>
      </div>
      <div class="category_container_assistance">
        <div class="fix_assistance_style">
          <div class="label-primary">Categoria</div>
          <div class="value-primary">{{ currentNameServiceSelected }}</div>
        </div>
        <div class="fix_assistance_style">
          <div class="label-primary">Solicitante</div>
          <div class="value-primary request_assistance_text">
            {{ requester }}
          </div>
        </div>
      </div>

      <div class="description_assistance">
        <div class="tittle_description label-primary">Descripción</div>
        <div class="description_assistance value-primary">
          {{ description }}
        </div>
      </div>
    </div>
    <div
      class="container_basic_information_assistance edit_container_assistance"
      v-else-if="loader"
    >
      <div class="category_container_assistance__subject">
        <div class="fix_assistance_style subject__assistance">
          <div class="label-primary">Asunto</div>
          <input
            type="text"
            class="form-control"
            id="title"
            v-model="title"
            @keyup="validateFields($event)"
          />
          <span class="error-span" v-if="errorFieldTitle">Campo requerido</span>
        </div>
        <div class="icon__cancel__edit__mode" v-if="editModeCheck">
          <CancelBtn @click="cancelEditMode()" />
        </div>
      </div>
      <div class="category_container_assistance">
        <div class="fix_assistance_style">
          <div class="label-primary">Categoria</div>
          <div class="custom-select test_input">
            <select
              class="select_field input-form"
              v-model="typeService"
              id="typeService"
              @change="validateFields($event), setNameService($event)"
            >
              <option value="" selected disabled hidden>
                {{ currentNameServiceSelected }}
              </option>
              <option
                :value="el.id"
                v-for="(el, index) in listGroupAssistance"
                :key="index"
              >
                {{ el.title }}
              </option>
            </select>
            <span class="custom-arrow-b"></span>
            <span class="error-span" v-if="errorFieldServiceType"
              >Campo requerido</span
            >
          </div>
        </div>
        <div class="fix_assistance_style">
          <div class="label-primary">Solicitante</div>
          <div class="value-primary request_assistance_text">
            {{ requester }}
          </div>
        </div>
      </div>

      <div class="description_assistance edit_description">
        <div class="tittle_description label-primary">Descripción</div>
        <div class="description_assistance value-primary">
          <textarea
            class="text-area-primary"
            v-model="description"
            id="description"
            @keyup="validateFields($event)"
          >
          </textarea>
          <span class="error-span" v-if="errorFieldDecription"
            >Campo requerido</span
          >
        </div>
      </div>
      <div class="footer__edition">
        <button
          :class="
            noChangesEdit
              ? 'btn-vue-primary fix-btn-mac color_hover_button_standard'
              : 'desactive_draf_settings fix-btn-mac'
          "
          @click="editSingleAssistance()"
        >
          <span class="f-14"> Guardar Cambios </span>
        </button>
      </div>
    </div>
    <div class="loader_edit_assistance" v-else>
      <div class="">
        <LoaderGeneric />
      </div>
      Editando asistencia...
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import integratedAssistance from "../../services/integratedAssistance";
import EditIcon from "../../assets/edit-icon.svg";
import Trash from "../../assets/trash.svg";
import LoaderGeneric from "@/components/loaderGeneric/Loading.vue";
import ModalConfirmRemove from "../modals/modalConfirmRemoveAssistance.vue";

import CancelBtn from "@/assets/iconos_container/cancel.svg";

export default {
  components: {
    EditIcon,
    Trash,
    LoaderGeneric,
    ModalConfirmRemove,
    CancelBtn,
  },
  created() {
    this.edit = this.editFromListAssistance;

    let baseInformation =
      this.currentSingleAssistance != null
        ? this.currentSingleAssistance.baseInformation
        : null;

    this.description = baseInformation.description;
    this.title = baseInformation.title;
    this.currentNameServiceSelected = baseInformation.serviceType;
    this.requester = baseInformation.requester;
    this.typeService = baseInformation.serviceTypeId;
    this.idRquest = baseInformation.idRequest;
    let initialFieldToCompare = {
      title: baseInformation.title,
      description: baseInformation.description,
      serviceTypeId: baseInformation.serviceTypeId,
    };
    this.initialFieldsUser = initialFieldToCompare;

    if (this.edit) {
      this.$store.dispatch("Assistance/getGroupsAssitance");
    }
  },
  props: ["usuario"],
  computed: {
    ...mapGetters("Assistance", {
      listGroupAssistance: "listGroupAssistance",
      editFromListAssistance: "editFromListAssistance",
      currentSingleAssistance: "currentSingleAssistance",
      getCurrentAssitance: "getCurrentAssitance",
      editModeValidator: "editMode",
    }),
    ...mapGetters("Login", {
      group: "group",
      user_profile: "user_profile",
      //values for valid access to wall messages
      company_id: "company_id",
      validToken: "validToken",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  data: () => {
    return {
      editModeCheck: false,
      edit: false,
      description: null,
      typeService: "",
      title: "",
      idRquest: "",
      errorFieldTitle: "",
      errorFieldDecription: "",
      errorFieldServiceType: "",
      noChangesEdit: false,
      initialFieldsUser: null,
      typeServiceInitial: "",
      loader: true,
      currentNameServiceSelected: "",
    };
  },

  methods: {
    gotoWall() {
      // this.$router.push({ name: "messageWall" });
      let param = "modal__send__message";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__send__message;
      element.open();
    },
    show_modal_remove() {
      let param = "modal__assitance__remove";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__assitance__remove;
      element.open();
    },
    cancelEditMode() {
      this.edit = false;
      this.editModeCheck = false;
      this.$store.dispatch("Assistance/desactiveEditModeLocal");
    },
    editMode() {
      this.editModeCheck = true;
      this.$store.dispatch("Assistance/activeEditMode");
      this.edit = true;
      this.$store.dispatch("Assistance/getGroupsAssitance");
    },
    setNameService(e) {
      let id_selected = e.target.value;
      let serviceSelected = this.listGroupAssistance.filter(
        (el) => el.id == id_selected
      );

      let currentNameOfService = serviceSelected[0].title;
      this.currentNameServiceSelected = currentNameOfService;
    },
    validateFields(e) {
      // we catch the value of the target
      let field = e.target.id;

      // we evaluate what value are we working (in this case title)
      if (field == "title") {
        // in case the title is "empty" a field error exist and desactive the button
        if (this.title.length > 0) {
          this.errorFieldTitle = false;
        } else {
          this.errorFieldTitle = true;
          this.noChangesEdit = false;
        }
        // we evaluate if the title is the same as the innitial title (usuario.title) and we evluate the other field are not empty (requerimients) in case in fail the evaluation the button is disabled
        if (
          this.initialFieldsUser.title != this.title &&
          this.title.length > 0 &&
          this.description.length > 0
        ) {
          // noChangesEdit = variable for the enable/disable button
          this.noChangesEdit = true;
        }
        if (this.initialFieldsUser.title == this.title) {
          this.noChangesEdit = false;
        }
      }
      if (field == "description") {
        // in case the title is "empty" a field error exist and desactive the button
        if (this.description.length > 0) {
          this.errorFieldDecription = false;
        } else {
          this.errorFieldDecription = true;
          this.noChangesEdit = false;
        }
        // we evaluate if the title is the same as the innitial title (usuario.title) and we evluate the other field are not empty (requerimients) in case in fail the evaluation the button is disabled
        if (
          this.initialFieldsUser.description != this.description &&
          this.description.length > 0 &&
          this.title.length > 0
        ) {
          // noChangesEdit = variable for the enable/disable button
          this.noChangesEdit = true;
        }
        if (this.initialFieldsUser.description == this.description) {
          this.noChangesEdit = false;
        }
      }
      if (field == "typeService") {
        // in case the title is "empty" a field error exist and desactive the button
        if (this.typeService.length > 0) {
          this.errorFieldServiceType = false;
        } else {
          this.errorFieldServiceType = true;
          this.noChangesEdit = false;
        }
        // we evaluate if the title is the same as the innitial title (usuario.title) and we evluate the other field are not empty (requerimients) in case in fail the evaluation the button is disabled
        if (
          this.initialFieldsUser.serviceTypeId != this.typeService &&
          this.typeService.length > 0 &&
          this.description.length > 0 &&
          this.title.length > 0
        ) {
          // noChangesEdit = variable for the enable/disable button
          this.noChangesEdit = true;
        }
        if (this.initialFieldsUser.serviceTypeId == this.typeService) {
          this.noChangesEdit = false;
        }
      }
    },
    editSingleAssistance() {
      // if (
      // (this.title.length > 0 && this.description.length > 0) ||
      // this.usuario.serviceType != this.typeService ||
      // this.usuario.description != this.description ||
      // this.usuario.title != this.title
      // )
      //  {

      this.initialFieldsUser = {
        title: this.title,
        description: this.description,
        serviceTypeId: this.typeService,
      };

      var formData = new FormData();
      formData.append("title", this.title);
      formData.append("service_type_id", this.typeService);
      formData.append("description", this.description);

      let id_company = this.inSwitch
        ? this.idInSwitch
        : this.user_profile.company.id;

      integratedAssistance
        .edit_assistance(id_company, formData, this.idRquest)

        .then(() => {
          let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

          let payload = {
            admin_company_id: company_id,
            fields:
              "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
            limit: "5",
          };
          this.$store
            .dispatch("Assistance/getAllAssistance", payload)
            .then(() => {
              let payload = {
                assistance: this.getCurrentAssitance,
                editModeFromList: false,
              };

              this.$store.dispatch("Assistance/setCurrentAssistance", payload);
            });
        })
        .then(() => {
          this.$toasted.success("Asistencia editada correctamente");
          if (this.editFromListAssistance) {
            this.loader = false;
            setTimeout(() => {
              this.$router.push({ name: "listRequestIntegrated" });
            }, 2000);
          } else {
            this.edit = !this.edit;
          }
        })
        .catch(() => {});
    },
  },
  watch: {
    currentSingleAssistance() {
      this.noChangesEdit = false;
    },
  },
  // },
};
</script>

<style lang="scss">
.icon__cancel__edit__mode svg {
  width: 20px;
  cursor: pointer;
}
.header_base_information {
  > div svg {
    width: 25px;
    cursor: pointer;
  }
  display: flex;
  justify-content: space-between;
}
.container__icons__assistance {
  display: flex;
  > .trash__ico {
    height: 30px;
    width: 30px;
    fill: #fa4c35;
  }
}
.fix_assistance_style,
.subject__assistance {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > .label-primary {
    // color en edit gray
    color: var(--text_profile) !important;
    font-size: 12px;
  }
}
.fix_assistance_style {
  > .value-primary {
    color: var(--text_profile) !important;
    font-size: 14px;
  }
}

.subject__assistance {
  width: 50%;
}
.request_assistance_text {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    white-space: nowrap;
  }
}
.description_assistance {
  > .label-primary {
    color: var(--line_primary) !important;
  }
}
.description_assistance {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.container_basic_information_assistance {
  > .title-container {
    color: var(--title_profile) !important;
    font-size: 20px;
  }
}

//edit mode

.footer__edition {
  display: flex;
  justify-content: flex-end;
}

.edit_description {
  margin-top: 1rem;
}
.edit_description .description_assistance {
  width: 60% !important;
}

.edit_container_assistance .title-container input {
  width: 60%;
}

.subject__edit > div {
  margin-bottom: 0.7rem;
}
.loader_edit_assistance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 500px;
  gap: 2rem;
}

.category_container_assistance__subject {
  justify-content: space-between;
}
</style>
