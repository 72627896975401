export default {
  fetchListAssistance: (state) => {
    return state.fetchListAssistance;
  },
  loaderAssistance: (state) => {
    return state.loaderAssistance;
  },
  total_data: (state) => {
    return state.total_data;
  },
  total_data_view: (state) => {
    return state.total_data_view;
  },
  nextPage: (state) => {
    return state.nextPage;
  },
  prevPage: (state) => {
    return state.prevPage;
  },
  flagData: (state) => {
    return state.flagData;
  },
  modalConfirmationState: (state) => {
    return state.confirmAssistanceModal;
  },
  listGroupAssistance: (state) => {
    return state.listGroupAssistance;
  },
  currentSingleAssistance: (state) => {
    return state.currentSingleAssistance;
  },
  currentSingleAssistanceBaseInformation: (state) => {
    return state.currentSingleAssistance.baseInformation;
  },
  currentIdCompanyRequester: (state) => {
    return state.currentSingleAssistance.idCompanyRequester;
  },


  currentAssignedAdminEmployees: (state) => {
    return state.AssignedAdminEmployees;
  },
  currentAssignedRequesterEmployees: (state) => {
    return state.AssignedRequesterEmployees;
  },


  lawyerAssigned: (state) => {
    return state.currentSingleAssistance.lawyerAssigned;
  },
  requesterAssigned: (state) => {
    return state.currentSingleAssistance.requesterAssigned;
  },
  attendantAssigned: (state) => {
    return state.currentSingleAssistance.attendantAssigned;
  },



  currentSingleAssistanceBaseInformationID: (state) => {
    return state.currentSingleAssistance.baseInformation.idRequest;
  },
  currentSingleAssistanceTermsAgreement: (state) => {
    return state.currentSingleAssistance.termsAgreement;
  },
  currentSelectAssistanceFlag: (state) => {
    return state.currentSelectAssistanceFlag;
  },
  flagFilterMobileAssistance: (state) => {
    return state.flagFilterMobileAssistance;
  },
  tagsOfFilters: (state) => {
    return state.tagsOfFilters;
  },
  initialFilters: (state) => {
    return state.initialFilters;
  },
  skeletonFilter: (state) => {
    return state.skeletonFilter;
  },
  flagActiveFilter: (state) => {
    return state.flagActiveFilers;
  },
  //edit flags getter

  editMode: (state) => {
    return state.editMode;
  },
  editFromListAssistance: (state) => {
    return state.editModeFromList;
  },

  getCurrentAssitance: (state) => {
    return state.fetchListAssistance[0];
  },
  flagActiveResult: (state) => {
    return state.noResultsflag;
  },
  inputSearchActive: (state) => {
    return state.inputSearchActive;
  },

  employees: (state) => {
    return state.employees;
  },
  flagActiveFilters: (state) => {
    return state.flagActiveFilters;
  },
  totalPages: (state) => {
    return state.totalPages;
  },
  dataActiveFilters: (state) => {
    return state.dataActiveFilters;
  },
  flagActiveInputSearch: (state) => {
    return state.flagActiveInputSearch;
  },
  loaderActive: (state) => {
    return state.loaderActive;
  },
  currentCompanyId: (state) => {
    return state.companyCurrentRequest.id
  },
  currentCompanyName: (state) => {
    return state.companyCurrentRequest.name
  },
  dataInputSearchTags: (state) => {
    return state.dataInputSearchTags
  },
  stepAssistanceOnBoarding: (state) => {
    return state.stepsAssistanceOnBoarding;
  },
  getterTabAssigned: (state) => {
    return state.tabAssigned;
  },

  getterAssistanceRouteId: (state) => {
    return state.assistanceRouteParamsId;
  },

  // get id for external assigned user to request
  getIdFromForceAssigment: (state) => {
    return state.idRequesterExternal;
  },
};
