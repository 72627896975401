<template>
  <div class="modal__content">
    <div class="image_onboarding_digital_fourth">
      <FourthImageOnBoarding />
    </div>
    <div class="content_onboarding_digital_fourth_step">
      <div class="container_onboarding_title">
        <div class="title_onboarding_assistance">
          ¡Comencemos!
        </div>
      </div>
      <div class="text_container_onboarding text_container_digital_fourth">
        <div class="">
          ¿Te parece si empezamos con tu primer proceso de Firma digital?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FourthImageOnBoarding from "./assets/fourthStepDigital.svg";
import { mapGetters } from "vuex";
export default {
  props: ["size"],
  components: {
    FourthImageOnBoarding,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters("DigitalSignature", {
      stepDigitalOnBoarding: "stepDigitalOnBoarding",
    }),
  },

  methods: {
    AcceptOnBoarding() {
      let increment = this.stepDigitalOnBoarding + 1;

      this.$store.dispatch(
        "DigitalSignature/counterStepDigitalOnBoarding",
        increment
      );
    },
    cancelModal() {
      let modal = document.querySelector(
        "#modal__onboarding__digital__signature"
      );
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
.title_onboarding_assistance {
  text-align: center;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    gap: 3rem !important;
  }
}
.image_onboarding_digital_fourth {
  display: flex;
  margin-top: 5.5rem;
  margin-left: 9.2rem;
  align-items: center;
  justify-content: center;
  width: 383px;
  height: 352px;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 250px !important;
    height: 150px !important;
    margin-left: 2rem !important;
  }
}
.content_onboarding_digital_fourth_step {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    gap: 3rem !important;
  }
}
.text_container_digital_fourth {
  @media screen and (max-width: 500px) and (min-width: 200px) {
    font-size: 16px !important;
    line-height: 23px !important;
  }
}
</style>
