<template>
  <div class="container-slots container-base">
    <div class="border__bottom">
      <div class="breadcrumb">
        <span class="sign__digital text_document" @click="goToListSignature()">
          Firma Digital >
        </span>

        <span class="text_document">Firmar Documento</span>
      </div>
    </div>

    <div class="container_signature">
      <div class="origin__document">
        <div class="label-primary top__margin__signature">
          Fecha de creación del documento
        </div>
        <div class="select_origin_document">
          <div class="m-top-s container__origin__document">
            <div class="label__signature label__signature__dates">
              <div class="input__icon">
                <!-- <input class="input-form" type="text" /> -->
                <b-form-datepicker
                  v-model="datePicker"
                  class="input-form"
                  placeholder="Selecciona fecha del documento"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="label__signature label__signature__category">
              <div class="container-check">
                <input
                  class="document-input fix__checkbox"
                  type="checkbox"
                  v-model="checked"
                  :disabled="disableCheckded"
                />

                <div class="text-container title__month">
                  Ver documentos de todo el mes
                </div>

                <!-- <Date/> -->
              </div>
            </div>
          </div>
          <div class="category__check">
            <div class="label-primary">Categoría del documento</div>
            <div class="custom-select">
              <select
                class="select_field input-form"
                v-model="documentCategory"
                v-if="!datapickerFlag"
              >
                <option
                  v-for="(d, index) in document_category"
                  :key="index"
                  :value="d.name"
                >
                  {{ d.name }}
                </option>
              </select>

              <select
                v-else
                class="select_field input-form"
                v-model="documentCategory"
              >
                <option selected="selected" :value="null">
                  Selecciona un fecha en primer lugar
                </option>
              </select>

              <span class="custom-arrow-b"></span>
            </div>
          </div>
          <div class="category__check__text">
            <div class="label-primary">
              Selecciona documento a firmar
              {{ documentCategory }}
            </div>
            <span class="sub_title_card">
              Documentos creados en la plataforma, que ya hayan sido previamente
              firmados, no serán mostrados en esta tabla
            </span>
          </div>
          <div class="table table__documents">
            <div class="table__head">
              <div class="sub-title-table">
                <div>Tipo de documento</div>
                <div>Fecha de creación</div>
              </div>
            </div>
            <div class="table__body">
              <div class="m-top-xl" v-if="load_document_from_app">
                <LoaderGeneric />
              </div>
              <!-- <div class="loader__" v-else-if="loader">
                  <loaderGeneric></loaderGeneric>
                </div> -->
              <div
                class="document__fields cursor_document"
                v-else-if="documents"
              >
                <div
                  v-for="(document, index) in documents"
                  :key="index"
                  @click="selectedDocument(document)"
                  class=""
                  :class="
                    document_id == document.id
                      ? 'active__document'
                      : 'desactive'
                  "
                >
                  <div class="text_document">
                    {{ document.document_type.name }}
                  </div>
                  <div class="text_document">
                    {{ document.created_at.slice(0, 10) }}
                  </div>
                </div>
              </div>

              <div class="" v-else>Sin Documentos</div>
            </div>
          </div>
        </div>
      </div>

      <div class="pre__view">
        <!-- {{ name_document }} -->

        <!-- <button @click="request" class="btn-primary">
          click
        </button>
        <iframe :src="blob_url" frameborder="0"></iframe>
        <pdf class="hola ariel" :src="blob_url"></pdf>
        <pdf v-bind:src="blob_url" /> -->
      </div>
    </div>

    <div class="footer__selected__signature">
      <div class="identifier__input">
        <div class="label-primary">
          Identificador del documento
          <span class="sub-title-table">(ej. contrato-juan-perez)</span>
        </div>
        <input type="text" name="" id="" class="input-form" v-model="tags" />
      </div>
      <div class="signature__footer__buttons">
        <button class="secondary_button" @click="backButton()">Volver</button>
        <button
          class="btn-vue-primary color_hover_button_standard"
          @click="setDocument()"
        >
          Seleccionar Documento
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import LoaderGeneric from "../../../components/loaderGeneric/LoadingMA.vue";
// import pdf from "vue-pdf";
// import axios from "axios";

// import Date from "../assets/date.svg";
export default {
  name: "OriginFromApp",
  components: {
    // Date,
    LoaderGeneric,
    // pdf,
  },

  data: () => {
    return {
      datePicker: null,
      datapickerFlag: true,
      month: null,
      day: null,
      year: null,
      checked: false,
      documentCategory: null,
      disableCheckded: false,
      selected: false,
      document_id: null,
      loader: false,
      loaderDocuments: false,
      url_document: "",
      name_document: null,
      blob_url: null,
      report: null,
      errorSetDocument: false,
      tags: null,
    };
  },
  beforeCreate() {
    this.$store.dispatch("DigitalSignature/listCategorys");
    this.$store.dispatch("DigitalSignature/cleanFields");
    this.$store.dispatch("DigitalSignature/setClearLoadDocument");
  },
  methods: {
    backButton() {
      this.$router.push({ name: "NewDigitalSignature" });
    },
    goToListSignature() {
      this.$store.dispatch("DigitalSignature/searchDigitalSignature", true);
      this.$router.push({ name: "DigitalSignature" });
    },
    async request() {
      // test fetch
      // fetch(this.url_document, {
      //   responseType: "blob",
      //   headers: {
      //     Accept: "application/pdf",
      //   },
      //   // responseType: "blob",
      // })
      //   .then((response) => {
      //     if (response.ok) {
      //       return response.blob();
      //     }
      //   })
      //   .then((blob) => {
      //     const fileURL = URL.createObjectURL(blob);
      //     console.log(fileURL);
      //     this.blob_url = fileURL;
      //     window.open(fileURL, "_blank");
      //     // FileSaver.saveAs(blob, "file.pdf");
      //   });
      // test 1
      // const api = axios.create({
      //   baseURL: `${this.url_document} `,
      //   // responseType: "blob",
      //   responseType: "arraybuffer",
      //   headers: {
      //     Accept: "application/pdf",
      //     // "Content-Type": "binary/octet-stream",
      //     // "Content-Type": "application/python-pickle",
      //     // "content-type": " application/json",
      //   },
      // });
      // const blob = await api.get().then((response) => {
      //   console.log("success ", response);
      //   console.log("wsp: ", response.data);
      //   var file = new Blob([response.data]);
      //   console.log("file type: ", file.type);
      //   const fileURL = window.URL.createObjectURL(file, {
      //     type: "application/pdf",
      //   });
      //   console.log("create url: ", fileURL);
      //   window.open(fileURL, "_blank");
      //   return { data: fileURL };
      // });
      // return {
      //   blob_url: blob,
      // };
      //test 2
      // axios
      //   .get(`${this.url_document}`, {
      //     responseType: "arraybuffer",
      //     // responseType: "blob",
      //     headers: {
      //       "Content-Type": "binary/octet-stream",
      //       // "Content-Type": "application/python-pickle",
      //     },
      //   })
      //   .then((response) => {
      //     console.log("Success", response);
      //     var file = new Blob([response.data]);
      //     // const fileURL = window.URL.createObjectURL(file);
      //     const fileURL = window.URL.createObjectURL(file, {
      //       type: "application/pdf",
      //     });
      //     this.blob_url = fileURL;
      //     window.open(this.blob_url, "_blank");
      //   });
      // axios
      //   .get(`${this.url_document}`, {
      //     responseType: "arraybuffer",
      //     headers: {
      //       // "Content-Type": "binary/octet-stream",
      //       // "Content-Type": "application/python-pickle",
      //     },
      //   })
      //   .then((res) => {
      //     console.log("Success", res);
      //     // let fileURL = window.URL.createObjectURL(res.data, {
      //     //   type: "application/pdf",
      //     // });
      //     const fileURL = window.URL.createObjectURL(new Blob([res.data]), {
      //       type: "application/pdf",
      //     });
      //     this.blob_url = fileURL;
      //     window.open(fileURL, "target_blank", "height=650,width=840");
      //   });
    },

    // selectedData(ctx) {},
    selectedDocument(d) {
      this.url_document = d.file_url;
      this.name_document = d.document_type.name;

      //store para almacenar documento seleccionado

      this.selected = !this.selected;
      this.document_id = d.id;
    },

    setDocument() {


      if (this.name_document != null && this.document_id != null) {
        let documentName = this.name_document;
        let documentID = this.document_id;
        let tags = this.tags;

        let data = {
          name: documentName,
          id: documentID,
          tags: tags,
        };

        this.$store.dispatch("DigitalSignature/setDocument", data);

        this.$router.push({ name: "AddSigners" });
      } else {
        this.errorSetDocument = true;
      }
    },
  },
  computed: {
    ...mapGetters("Login", {
      company_id: "company_id",
    }),
    ...mapGetters("DigitalSignature", {
      loader_documents: "loader_documents",
      load_document_from_app: "loader_documents_from_app",
      documents_validate: "documents",
    }),
    ...mapState("DigitalSignature", {
      document_category: "document_category",
      documents: "documents",
      // loader_documents: "loader_documents",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },
  watch: {
    checked: {
      handler(val) {
        if (val == true) {
          let created_at__year = this.year != null ? this.year : null;
          let created_at__month = this.month != null ? this.month : null;
          let sign_status = "0";

          let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

          let document_type__categories__name__icontains =
            "Documentos Personales";
          let document_type__categories__name__icontains_filter =
            this.documentCategory != null ? this.documentCategory : null;

          if (created_at__year != null && created_at__month != null) {
            this.$store.dispatch("DigitalSignature/loadDocuments", {
              company_id,
              sign_status,
              document_type__categories__name__icontains,
              created_at__year,
              created_at__month,
              document_type__categories__name__icontains_filter,
            });
          }

          // llevar day a null
        } else {
          let created_at__year = this.year != null ? this.year : null;
          let created_at__day = this.day != null ? this.day : null;
          let created_at__month = this.month != null ? this.month : null;

          let sign_status = "0";
          let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

          let document_type__categories__name__icontains =
            "Documentos Personales";

          let document_type__categories__name__icontains_filter =
            this.documentCategory != null ? this.documentCategory : null;

          if (created_at__year != null && created_at__day != null) {
            this.$store.dispatch("DigitalSignature/loadDocuments", {
              company_id,
              sign_status,
              created_at__year,
              created_at__month,
              created_at__day,
              document_type__categories__name__icontains,
              document_type__categories__name__icontains_filter,
            });
          }
        }
      },
    },

    datePicker: {
      handler(val) {
        this.datapickerFlag = false;
        let month = val.slice(5, 7);
        this.month = month;
        let day = val.slice(8, 10);
        this.day = day;
        let year = val.slice(0, 4);
        this.year = year;

        let sign_status = "0";

        let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

        let created_at__year = year;
        let created_at__month = month;
        let created_at__day = day;
        let document_type__categories__name__icontains =
          "Documentos Personales";

        let document_type__categories__name__icontains_filter =
          this.documentCategory != null ? this.documentCategory : null;

        this.$store.dispatch("DigitalSignature/loadDocumentOn");

        if (val != null) {
          this.disableCheckded = false;

          if (this.checked == true) {
            this.$store.dispatch("DigitalSignature/loadDocuments", {
              company_id,
              sign_status,
              created_at__year,
              created_at__month,
              document_type__categories__name__icontains,
              document_type__categories__name__icontains_filter,
            });
          } else {
            this.$store.dispatch("DigitalSignature/loadDocuments", {
              company_id,
              sign_status,
              created_at__year,
              created_at__month,
              created_at__day,
              document_type__categories__name__icontains,
              document_type__categories__name__icontains_filter,
            });
          }
        }
      },

      deep: true,
    },

    documentCategory: {
      handler() {
        if (this.documentCategory != null) {
          let document_type__categories__name__icontains =
            "Documentos Personales";

          let document_type__categories__name__icontains_filter =
            this.documentCategory;

          let created_at__year = this.year != null ? this.year : null;
          let created_at__day = this.day != null ? this.day : null;
          let created_at__month = this.month != null ? this.month : null;
          let sign_status = "0";
          let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

          if (this.checked == true) {
            this.$store.dispatch("DigitalSignature/loadDocuments", {
              company_id,
              sign_status,
              created_at__year,
              created_at__month,
              document_type__categories__name__icontains,
              document_type__categories__name__icontains_filter,
            });
          } else {
            this.$store.dispatch("DigitalSignature/loadDocuments", {
              company_id,
              sign_status,
              created_at__year,
              created_at__month,
              created_at__day,
              document_type__categories__name__icontains,
              document_type__categories__name__icontains_filter,
            });
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
//footer
.footer__selected__signature {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) and (min-width: 200px) {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
.footer__selected__signature {
  @media screen and (max-width: 300px) and (min-width: 200px) {
    .signature__footer__buttons {
      margin-left: 5%;
    }
  }
  @media screen and (max-width: 400px) and (min-width: 301px) {
    .signature__footer__buttons {
      margin-left: 30%;
    }
  }
  @media screen and (max-width: 600px) and (min-width: 500px) {
    .signature__footer__buttons {
      margin-left: 45%;
    }
  }
}

.signature__footer__buttons {
  display: flex;
  gap: 1rem;

  > .active_draf {
    background: var(--bg_active_delete_draf) !important;
    color: #000032 !important;
  }

  > .desactive_draf {
    color: #9c9c9c !important;
  }

  > .desactive_set_draf {
    background: #9c9c9c !important;
    color: white !important;
  }
}

//end footer
.loader__ {
  margin-top: 3rem;
}
.cursor_document {
  cursor: pointer;
}

.identifier__input {
  > .label-primary {
    margin-bottom: 0.5rem;
    color: var(--title_sing_from_app);
  }

  > .label-primary span {
    color: var(--sub-title-user) !important;
  }
}
//tabla

//input with icon

// .input__icon{

// }
</style>
