<template>
  <dialog
    :class="
      size == 'xl'
        ? 'modal__xl'
        : size == 'm'
        ? 'modal__m'
        : size == 's'
        ? 'modal__s'
        : size == 'l'
        ? 'modal__l'
        : size == 'ls'
        ? 'modal__ls'
        : 'modal__default'
    "
    :id="ref_id"
    :ref="ref_id"
  >
    <div class="header_modal">
      <CancelIcon @click="cancelModal()" />
    </div>

    <div class="filter_assistance_content">
      <div class="container-filter-modal">
        <div class="text-filter fix_employee_title">Filtrar Empleados</div>
      </div>
      <div class="container_assistance_filter_date">
        <div class="employee_title_option f-14">
          <span class="label-primary">Por estado</span>
          <div class="container_check_box_employee">
            <div class="input__icon_employee">
              <input
                type="checkbox"
                name="example_accordion"
                id="section2"
                class="accordion__input"
                @click="check_one_active()"
                v-model="activeEmployees"
              />
              <div class="text_sub_option_employee">Activos</div>
            </div>
            <div class="input__icon_employee">
              <input
                type="checkbox"
                name="example_accordion"
                id="section2"
                class="accordion__input"
                @click="check_one_active()"
                v-model="inactiveEmployees"
              />
              <div class="text_sub_option_employee">inactivos</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container_assistance_filter_date"
        v-if="group == 'Agent' || group == 'Admin'"
      >
        <div class="employee_title_option f-14">
          <span class="label-primary">Por Verificación</span>
          <div class="container_check_box_employee">
            <div class="input__icon_employee">
              <input
                type="checkbox"
                name="example_accordion"
                id="section2"
                class="accordion__input"
                @click="check_one_verified()"
                v-model="verifiedEmployees"
              />
              <div class="text_sub_option_employee">Verificados</div>
            </div>
            <div class="input__icon_employee">
              <input
                type="checkbox"
                name="example_accordion"
                id="section2"
                class="accordion__input"
                @click="check_one_verified()"
                v-model="notVerified"
              />
              <div class="text_sub_option_employee">No Verificados</div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer_modal">
        <div
          disabled="disabled"
          class="delete_filters_modal_employee"
          @click="cancelModal()"
        >
          <span> Eliminar Filtros</span>
        </div>
        <div
          class="fix-btn-mac new_button_employee_modal"
          @click="applyFilterModal()"
        >
          <span> Aceptar </span>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import CancelIcon from "@/assets/iconos_container/cancel.svg";

import { mapGetters } from "vuex";

export default {
  props: ["title", "size", "company_for_modal", "id", "ref_id"],
  components: {
    CancelIcon,
  },
  created() {
    // this.getEmployes();
    this.ref__id = this.ref_id;
  },
  data: () => {
    return {
      activeEmployees: null,
      inactiveEmployees: null,
      verifiedEmployees: null,
      notVerified: null,
      ref__id: null,
    };
  },
  computed: {
    ...mapGetters("EmployeesV2", {
      list__companies: "list__companies",
    }),
    ...mapGetters("Login", {
      company_id: "company_id",
      user_profile: "user_profile",
      group: "group",
    }),

    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
  },

  methods: {
    // getEmployes() {
    //   let id_company = this.inSwitch ? this.idInSwitch : this.company.id;

    // },

    open() {
      /*--------------------------------
       @Input: Select current modal
       Description: 1. Execute method showModal() from <DIALOG></DIALOG> for execute backdrop style
                    2. Open modal
       @Output: modalState = true
      //--------------------------------*/
      let hash = `#${this.ref__id}`;

      let modal = document.querySelector(hash);
      modal.showModal();
      setTimeout(() => {
        this.$store.dispatch("Login/modalOpen");
      }, 550);
    },
    cancelModal() {
      this.$store.dispatch("EmployeesV2/cleanTagsOfFilter");
      let user = this.user_profile;
      let id = user.id;
      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;
      let company_id = admin_company_id;
      let offset = 0;

      let payload = {
        id_company: company_id,
        limit: "10",
        offset: offset,
        first_name__icontains: null,
        id: id,
      };
      this.$store.dispatch(
        "EmployeesV2/get_list_employee_InSwitchTenancy",
        payload
      );
      this.$store.dispatch(
        "EmployeesV2/get_list_members_InSwitchTenancy",
        payload
      );
      let hash = `#${this.ref__id}`;

      let modal = document.querySelector(hash);

      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
    check_one_verified() {
      if (this.notVerified) {
        this.notVerified = null;
      } else if (this.verifiedEmployees) {
        this.verifiedEmployees = null;
      }
    },
    check_one_active() {
      if (this.activeEmployees) {
        this.activeEmployees = null;
      } else if (this.inactiveEmployees) {
        this.inactiveEmployees = null;
      }
    },
    async applyFilterModal() {
      this.$store.dispatch("EmployeesV2/setInactiveInputSearchUser");
      this.$store.dispatch("EmployeesV2/setInactiveInputSearchMember");
      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let currentActiveEmployees = null ?? this.activeEmployees;
      let currentInactive = null ?? this.inactiveEmployees;
      let currentVerified = null ?? this.verifiedEmployees;
      let currentNotVerified = null ?? this.notVerified;

      let payloadForTags = [
        {
          name: null,
          value: null,
          status: null,
        },
        {
          name: currentActiveEmployees != null ? "Activos" : null,
          value: currentActiveEmployees != null ? currentActiveEmployees : null,
          status: currentActiveEmployees ? true : false,
        },
        {
          name: currentInactive != null ? "Inactivos" : null,
          value: currentInactive != null ? currentInactive : null,
          status: currentInactive ? true : false,
        },
        {
          name: currentVerified != null ? "Verificados" : null,
          value: currentVerified != null ? currentVerified : null,
          status: currentVerified ? true : false,
        },
        {
          name: currentNotVerified != null ? "No Verificados" : null,
          value: currentNotVerified != null ? currentNotVerified : null,
          status: currentNotVerified ? true : false,
        },
      ];

      if (currentVerified) {
        this.verifiedFilterModal = true;
      } else if (currentNotVerified) {
        this.verifiedFilterModal = false;
      }
      if (currentActiveEmployees) {
        this.activeUserFilterModal = true;
      } else if (currentInactive) {
        this.activeUserFilterModal = false;
      }

      await this.$store.dispatch(
        "EmployeesV2/addTagsOfFilters",
        payloadForTags
      );

      let payload = {
        admin_company_id: admin_company_id,
        company: admin_company_id,
        is_verified: this.verifiedFilterModal,
        is_active: this.activeUserFilterModal,
      };
      if (this.activeTableMembers) {
        await this.$store.dispatch("EmployeesV2/filterMembersByModal", payload);
      } else {
        await this.$store.dispatch(
          "EmployeesV2/filterEmployeeByModal",
          payload
        );
      }

      this.selectedEmployee = null;
      this.activeEmployees = null;
      this.inactiveEmployees = null;
      this.verifiedEmployees = null;
      this.notVerified = null;

      this.verifiedFilterModal = null;
      this.activeUserFilterModal = null;
      this.companyIdFilterModal = null;

      let hash = `#${this.ref__id}`;

      let modal = document.querySelector(hash);
      modal.setAttribute("closing", "");
      modal.addEventListener(
        "animationend",
        () => {
          modal.removeAttribute("closing");
          modal.close();
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/components/modal/modal.scss";
.container_assistance_filter {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.container_assistance_filter_date {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
  width: 100%;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    flex-wrap: wrap;
  }
}

.container__title span {
  color: var(--title_profile) !important;
}
.container_assistance_filter span {
  color: var(--text_profile) !important;
}
.container_assistance_filter {
  > .title-container {
    color: var(--text_profile) !important;
  }
}
.container_assistance_filter_date span {
  color: var(--text_profile) !important;
}
.container_assistance_filter_date {
  > .title-container {
    color: var(--text_profile) !important;
  }
}
.date_filter_assitance {
  display: flex;
  flex-direction: column;
}
.filter_assistance_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  padding-right: 35px;
  gap: 0.7rem;
}
.assistance_calendar {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 50%;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 100%;
  }
}
.container_check_box_employee {
  display: flex;
  gap: 70px;
  width: 100%;
}
.input__icon_employee {
  gap: 6px;
  display: flex;
  align-items: center;
}
.employee_title_option {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 50%;
  @media screen and (max-width: 500px) and (min-width: 200px) {
    width: 100%;
  }
}

.text_sub_option_employee {
  color: var(--input_color_placeholder) !important;
  white-space: nowrap;
  width: 50%;
}
</style>
