import * as types from "./mutations-types";
import employeService from "../services/employeService";
import countryInfoService from "@/service/countryInfoService";

export default {
  soft__delete({ commit }, { admin_company_id, user_id }) {
    return new Promise((resolve, reject) => {
      employeService
        .soft__delete(admin_company_id, user_id)
        .then(() => {
          commit(types.CONFIRM_DELETE_EMPLOYEE);
          resolve(true);
        })
        .catch((err) => {
          commit(types.FAILURE_DELETE_EMPLOYEE);
          console.log(err);
          reject();
        });
    });
  },
  get_list_companies({ commit }) {
    return new Promise((resolve, reject) => {
      employeService
        .get__list__companies()
        .then((data) => {
          commit(types.SET_COMPANIES_BY_USER, data.results);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  get_list_country({ commit }) {
    return new Promise((resolve, reject) => {
      let fields = "name,code,id,demonym";
      countryInfoService
        .list_country(fields)
        .then((data) => {
          commit(types.SET_COUNTRY_EMPLOYEE, data.results);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  get_list_region({ commit }, { countryID }) {
    return new Promise((resolve, reject) => {
      countryInfoService
        .list_state(countryID)
        .then((data) => {
          commit(types.SET_REGION_EMPLOYEE, data.results);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  get_list_city({ commit }, { countryID, stateID }) {
    return new Promise((resolve, reject) => {
      countryInfoService
        .list_city(countryID, stateID)
        .then((data) => {
          commit(types.SET_CITY_EMPLOYEE, data.results);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },

  get_list_employee(
    { commit },
    { company_id, limit = "10", offset, id, is_member = false }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.LOADER_TABLE_ON);
      // commit(types.LOADER_TABLE_OFF);
      employeService
        .get__list__employees(company_id, limit, offset, id, is_member)
        .then((data) => {
          let memberTable = "Usuarios";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  get_list_employee_members(
    { commit },
    { company_id, limit = "10", offset, id, is_member = true }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.LOADER_TABLE_ON);
      // commit(types.LOADER_TABLE_ON);
      let fields =
        "first_name,surname,company,user,nin,is_active,id,company_ingress_date,contract_type";
      employeService
        .get__list__employees(company_id, limit, offset, id, is_member, fields)
        .then((data) => {
          let memberTable = "Miembros";
          let totalData = data.count;

          let prevpagePayload = {
            data: data.previous,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: data.next,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  get__single__user({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // commit(types.LOADER_TABLE_ON);
      let activeMember = payload.isActiveMember;
      employeService
        .get__single__employee(payload.company_id, payload.user_id)
        .then((data) => {
          let payload = {
            data: data.results[0],
            isActiveMember: activeMember,
          };
          let marital = data.results[0].marital_status;
          commit(types.SET_CURRENT_USER_FETCH, payload);
          commit(types.SET_MARITAL_STATUS, marital);
        })
        .then(() => {
          resolve(true);
        })

        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  get__single__user__profile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // commit(types.LOADER_TABLE_ON);
      let activeMember = null;
      employeService
        .get__single__employee(payload.company_id, payload.user_id)
        .then((data) => {
          let payload = {
            data: data.results[0],
            isActiveMember: activeMember,
          };
          let marital = data.results[0].marital_status;
          commit(types.SET_CURRENT_USER_FETCH, payload);
          commit(types.SET_MARITAL_STATUS, marital);
        })
        .then(() => {
          resolve(true);
        })

        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  get_list_employee_InSwitchTenancy(
    { commit },
    {
      id_company,
      limit = "10",
      offset,
      first_name__icontains,
      id,
      is_member = false,
    }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.LOADER_TABLE_ON);
      employeService
        .employeesInSwitchTenancy(
          id_company,
          limit,
          offset,
          first_name__icontains,
          id,
          is_member
        )
        .then((data) => {
          let memberTable = "Usuarios";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          resolve(true);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  get_list_members_InSwitchTenancy(
    { commit },
    {
      id_company,
      limit = "10",
      offset,
      first_name__icontains,
      id,
      is_member = true,
    }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.LOADER_TABLE_ON);
      employeService
        .employeesInSwitchTenancy(
          id_company,
          limit,
          offset,
          first_name__icontains,
          id,
          is_member
        )
        .then((data) => {
          let memberTable = "Miembros";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  get_list_employee_quantity(
    { commit },
    { company_id, limit = "10", offset, id, is_member = false }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.LOADER_TABLE_ON);
      employeService
        .get__list__employees(company_id, limit, offset, id, is_member)
        .then((data) => {

          let totalData = data.count;
          let memberTable = "Usuarios";

          let prevpagePayload = {
            data: data.previous,
            table: memberTable,
          };

          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: data.next,
            table: memberTable,
          };

          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  get_list_employee_quantity_members(
    { commit },
    { company_id, limit = "10", offset, id, is_member = true }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.LOADER_TABLE_ON);
      employeService
        .get__list__employees(company_id, limit, offset, id, is_member)
        .then((data) => {


          let totalData = data.count;
          let memberTable = "Miembros";

          let prevpagePayload = {
            data: data.previous,
            table: memberTable,
          };

          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: data.next,
            table: memberTable,
          };

          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  nextPagination({ commit }, { nextUrl }) {
    commit(types.LOADER_TABLE_ON);
    return new Promise((resolve, reject) => {
      employeService
        .nextPage(nextUrl)
        .then((data) => {
          let memberTable = "Usuarios";
          let totalData = data.count;

          let prevpagePayload = {
            data: data.previous,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: data.next,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);
          commit(types.GET__LIST__EMPLOYEES, data.results);
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  nextPaginationMembers({ commit }, { nextUrl }) {
    commit(types.LOADER_TABLE_ON);
    return new Promise((resolve, reject) => {
      employeService
        .nextPage(nextUrl)
        .then((data) => {
          let memberTable = "Miembros";
          let totalData = data.count;

          let prevpagePayload = {
            data: data.previous,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: data.next,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);
          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cleanListCompanies({ commit }) {
    commit(types.CLEAN_LIST_COMPANIES);
  },
  //companies associates by user
  async get__all_companies_by_user({ commit }) {
    await employeService.get__companies_by_user().then((data) => {
      let companies = data.results;

      if (companies.length <= 2) {
        commit(types.SET_SINGLE_COMPANY, companies);
      } else {
        commit(types.SET_COMPANIES_BY_USER, companies);
      }
    });
  },
  //using in input search empoyee
  //TODO: test service for optimization params using in query
  searchEmployee(
    { commit },
    { id_company, total, offset, is_active, selected_company, name, staff, id }
  ) {
    commit(types.SET_LOADER_ON);
    return new Promise((resolve, reject) => {
      employeService
        .search__employees(
          id_company,
          total,
          offset,
          is_active,
          selected_company,
          name,
          id
        )
        .then((data) => {
          if (staff) {
            commit(types.GET_EMPLOYE__SEARCH_BY_ADMIN, data);
            commit(types.SET_LOADER_OFF);
          } else {
            commit(types.GET_EMPLOYE__SEARCH, data);
            commit(types.SET_LOADER_OFF);
          }
          resolve(true);
          // this.loading1 = false;
        })
        .catch((error) => {
          reject(error);
          commit(types.GET_FILTER_EMPLOYE_FAILURE, { error });
        });
    });
  },
  //TODO: test service for optimization params using in query

  filterEmployeeByModal(
    { commit },
    {
      admin_company_id,
      company,
      limit = "10",
      offset,
      id,
      is_verified = null,
      is_active = null,
      is_member = false,
    }
  ) {
    return new Promise((resolve, reject) => {
      // with this commit we know a filter is active
      // commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      // commit(types.DATA_FILTERS_ACTIVE, payload);
      // with this we activate the input search (this indicate there is  input search begin use)
      // commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      commit(types.LOADER_TABLE_ON);
      employeService
        .get__employees__by_modal(
          admin_company_id,
          company,
          limit,
          offset,
          id,
          is_verified,
          is_active,
          is_member
        )
        .then((data) => {
          let memberTable = "Usuarios";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  //TODO: test service for optimization params using in query
  filterMembersByModal(
    { commit },
    {
      admin_company_id,
      company,
      limit = "10",
      offset,
      id,
      is_verified = null,
      is_active = null,
      is_member = true,
    }
  ) {
    return new Promise((resolve, reject) => {
      // with this commit we know a filter is active
      // commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      // commit(types.DATA_FILTERS_ACTIVE, payload);
      // with this we activate the input search (this indicate there is  input search begin use)
      // commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      commit(types.LOADER_TABLE_ON);
      employeService
        .get__employees__by_modal(
          admin_company_id,
          company,
          limit,
          offset,
          id,
          is_verified,
          is_active,
          is_member
        )
        .then((data) => {
          let memberTable = "Miembros";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },

  //set values for set tags
  addTagsOfFilters({ commit }, payloadForTags) {
    //TODO: active skeleton for reload data table
    // commit(types.FLAG_ACTIVE_FILTER);
    // skeleton loader for when we add tag (skeleton ON)
    // commit(types.SKELETON_FILTER_ON);
    commit(types.ADD_TAGS_OF_FILTERS, payloadForTags);
    // skeleton loader for when we add tag (skeleton OFF)
    // setTimeout(() => {
    //   commit(types.SKELETON_FILTER_OFF);
    // }, 1000);
  },

  cleanSelectedTagsOfFilter({ commit }, payloadForTags) {
    // commit(types.SKELETON_FILTER_ON);
    if (payloadForTags.length <= 0) {
      commit(types.CLEAN_TAGS_OF_FILTERS);
      // commit(types.FLAG_DESACTIVE_FILTER);
      setTimeout(() => {
        // commit(types.SKELETON_FILTER_OFF);
      }, 1000);
    } else {
      // skeleton loader for clean a singular tag (skeleton ON)
      commit(types.CLEAN_SELECTED_TAGS_OF_FILTERS, payloadForTags);
      // skeleton loader for clean a singular tag (skeleton OFF)
      setTimeout(() => {
        // commit(types.SKELETON_FILTER_OFF);
      }, 1000);
    }
  },
  cleanTagsOfFilter({ commit }) {
    commit(types.CLEAN_TAGS_OF_FILTERS);
  },
  filterSelectedTags({ commit }, payload) {
    commit(types.FILTER_SELECTED_TAGS, payload);
  },

  //actions for dropdowns
  setIsActive({ commit }, payload) {
    commit(types.SET_IS_ACTIVE_EMPLOYEE, payload);
  },

  setDropDown({ commit }, payload) {
    commit(types.SET_EMPLOYEE_DROPDOWN, payload);
  },
  setDropDownVerified({ commit }, payload) {
    commit(types.SET_EMPLOYEE_DROPDOWN_VERIFIED, payload);
  },
  setDropDownThreeDots({ commit }, payload) {
    commit(types.DROP_DOWN_THREE_DOTS, payload);
  },
  setSelectedOptionState({ commit }, payload) {
    if (payload.status == true) {
      commit(types.SET_DROPDOWN_ALL_ACTIVATED, payload);
    } else {
      commit(types.SET_DROPDOWN_ALL_DESACTIVATED, payload);
    }
  },
  setSelectedOptionVerified({ commit }, payload) {
    if (payload.status == true) {
      commit(types.SET_DROPDOWN_ALL_VERIFIED, payload);
    }
  },
  CleanAllDropDownSelection({ commit }, payload) {
    commit(types.CLEAN_ALL_SELECTION_DROP, payload);
  },

  // set unit change status of employee
  setStatusEmployees({ commit }, { id_company, users }) {
    commit(types.SET_LOADER_ON);

    return new Promise((resolve, reject) => {
      employeService
        .act_inact_employee(id_company, {
          users: users,
        })
        .then(() => {
          resolve(true);
          commit(types.SET_LOADER_OFF);

          // this.loading1 = false;
        })
        .catch((error) => {
          commit(types.SET_LOADER_OFF);

          reject(error);
        });
    });
  },
  //set all status employee active and desactive
  setToggleStatusEmployees({ commit }, { id_company, users }) {
    commit(types.SET_LOADER_ON);

    return new Promise((resolve, reject) => {
      employeService
        .act_inact_employee(id_company, {
          users: users,
        })
        .then(() => {
          resolve(true);
          commit(types.SET_LOADER_OFF);

          // this.loading1 = false;
        })
        .catch((error) => {
          commit(types.SET_LOADER_OFF);

          reject(error);
        });
    });
  },
  setIsVeriefied({ commit }, { list, user_id, id_company }) {
    return new Promise((resolve, reject) => {
      employeService
        .check_employee(id_company, {
          users: user_id,
        })
        .then(() => {
          resolve(true);

          let payload = {
            list: list,
            id: user_id[0],
          };
          commit(types.SET_IS_VERIFIED_EMPLOYEE, payload);
          commit(types.SET_LOADER_OFF);

          // this.loading1 = false;
        })
        .catch((error) => {
          commit(types.SET_LOADER_OFF);

          reject(error);
        });
    });
  },
  setAllVerified({ commit }, { id_company, users, list }) {
    commit(types.SET_LOADER_ON);

    return new Promise((resolve, reject) => {
      employeService
        .check_employee(id_company, {
          users: users,
        })
        .then(() => {
          resolve(true);

          commit(types.SET_DROPDOWN_ALL_VERIFIED, list);
          commit(types.SET_LOADER_OFF);

          // this.loading1 = false;
        })
        .catch((error) => {
          commit(types.SET_LOADER_OFF);

          reject(error);
        });
    });
  },

  // employee viewS

  setCurrentViewEmployee({ commit }, employee) {
    commit(types.SET_CURRENT_VIEW_SINGLE_EMPLOYEE, employee);
  },
  cleanCurrentViewEmployee({ commit }) {
    commit(types.CLEAN_CURRENT_VIEW_SINGLE_EMPLOYEE);
  },
  setTableActive({ commit }, payload) {
    commit(types.SET_ACTIVE_TABLE, payload);
  },
  counterStepEmployee({ commit }, payload) {
    commit(types.COUNTER__STEP__EMPLOYEE, payload);
  },
  counterStepEmployeeOnBoarding({ commit }, payload) {
    commit(types.COUNTER__STEP__EMPLOYEE_ONBOARDING, payload);
  },
  cleancounterStepEmployee({ commit }, payload) {
    commit(types.CLEAN_COUNTER__STEP__EMPLOYEE, payload);
  },
  cleancounterStepEmployeeOnBoarding({ commit }, payload) {
    commit(types.CLEAN_COUNTER__STEP__EMPLOYEE_ONBOARDING, payload);
  },
  setTypeUserToCreate({ commit }, data) {
    commit(types.SET_TYPE_USER, data);
  },
  setTypeUserToCreateCompnayId({ commit }, data) {
    commit(types.SET_TYPE_USER_ID_COMPANY, data);
  },
  inputSearchEmployee(
    { commit },
    {
      company_id,
      limit = "10",
      offset,
      id,
      is_member = false,
      input_search = null,
    }
  ) {
    return new Promise((resolve, reject) => {
      // with this commit we know a filter is active
      // commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      // commit(types.DATA_FILTERS_ACTIVE, payload);
      // with this we activate the input search (this indicate there is  input search begin use)
      // commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      commit(types.LOADER_TABLE_ON);
      employeService
        .inputSearchEmployee(
          company_id,
          limit,
          offset,
          id,
          is_member,
          input_search
        )
        .then((data) => {
          let memberTable = "Usuarios";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  inputSearchEmployeeMembers(
    { commit },
    {
      company_id,
      limit = "10",
      offset,
      id,
      is_member = false,
      input_search = null,
    }
  ) {
    return new Promise((resolve, reject) => {
      // with this commit we know a filter is active
      // commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      // commit(types.DATA_FILTERS_ACTIVE, payload);
      // with this we activate the input search (this indicate there is  input search begin use)
      // commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      commit(types.LOADER_TABLE_ON);
      employeService
        .inputSearchEmployee(
          company_id,
          limit,
          offset,
          id,
          is_member,
          input_search
        )
        .then((data) => {
          let memberTable = "Miembros";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  inputSearchEmployeeInSwitch(
    { commit },
    {
      company_id,
      company,
      limit = "10",
      offset,
      id,
      is_member = false,
      input_search = null,
    }
  ) {
    return new Promise((resolve, reject) => {
      // with this commit we know a filter is active
      // commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      // commit(types.DATA_FILTERS_ACTIVE, payload);
      // with this we activate the input search (this indicate there is  input search begin use)
      // commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      commit(types.LOADER_TABLE_ON);
      employeService
        .inputSearchEmployeeInSwitch(
          company_id,
          company,
          limit,
          offset,
          id,
          is_member,
          input_search
        )
        .then((data) => {
          let memberTable = "Usuarios";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  inputSearchEmployeeMembersInSwitch(
    { commit },
    {
      company_id,
      company,
      limit = "10",
      offset,
      id,
      is_member = false,
      input_search = null,
    }
  ) {
    return new Promise((resolve, reject) => {
      // with this commit we know a filter is active
      // commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      // commit(types.DATA_FILTERS_ACTIVE, payload);
      // with this we activate the input search (this indicate there is  input search begin use)
      // commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      commit(types.LOADER_TABLE_ON);
      employeService
        .inputSearchEmployeeInSwitch(
          company_id,
          company,
          limit,
          offset,
          id,
          is_member,
          input_search
        )
        .then((data) => {
          let memberTable = "Miembros";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);
          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE_MEMBER);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE_MEMBER);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  // save base user information in create employee step
  saveBaseUserInformation({ commit }, data) {
    commit(types.SAVE_BASE_USER_INFORMATION, data);
  },
  saveBaseUserInformationCompany({ commit }, data) {
    commit(types.SAVE_BASE_USER_COMPANY, data);
  },
  saveBaseUserInformationBank({ commit }, data) {
    commit(types.SAVE_BASE_USER_BANK, data);
  },
  cleanBaseUserInformation({ commit }) {
    commit(types.CLEAN_BASE_USER_INFORMATION);
  },

  getListBanks({ commit }) {
    return new Promise((resolve, reject) => {
      employeService
        .get__list__bank()
        .then((data) => {
          commit(types.SET_LIST_BANKS, data.results);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getListHealthInsurance({ commit }) {
    return new Promise((resolve, reject) => {
      employeService
        .get__list__health_insurance()
        .then((data) => {
          commit(types.SET_LIST_HEALTH_INSURANCE, data.results);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getListSocialSecurity({ commit }) {
    return new Promise((resolve, reject) => {
      employeService
        .get__list__social_security()
        .then((data) => {
          commit(types.SET_LIST_SOCIAL_SECURITY, data.results);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getListOptionUserProfile({ commit }, id_company) {
    return new Promise((resolve, reject) => {
      employeService
        .get__list__option_user_profile(id_company)
        .then((data) => {
          commit(types.SET_LIST_OPTION_USER_PROFILE, data.actions.POST);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  ModalFilterSearchEmployee(
    { commit },
    {
      company_id,
      limit = "10",
      offset,
      id,
      is_member = false,
      input_search = null,
    }
  ) {
    return new Promise((resolve, reject) => {
      // with this commit we know a filter is active
      // commit(types.FLAG_ACTIVE_FILTER);
      // with this commit we save the filters value in a array
      // commit(types.DATA_FILTERS_ACTIVE, payload);
      // with this we activate the input search (this indicate there is  input search begin use)
      // commit(types.FLAG_ACTIVE_INPUT_SEARCH);
      // commit(types.FLAG_DESACTIVE_FILTER);
      commit(types.LOADER_TABLE_ON);
      employeService
        .inputSearchEmployee(
          company_id,
          limit,
          offset,
          id,
          is_member,
          input_search
        )
        .then((data) => {
          let memberTable = "Usuarios";
          let totalData = data.count;
          let dataPrevious = data.previous;
          let dataNext = data.next;

          let prevpagePayload = {
            data: dataPrevious,
            table: memberTable,
          };
          commit(types.SET_PREV_PAGE, prevpagePayload);

          let nextpagePayload = {
            data: dataNext,
            table: memberTable,
          };
          commit(types.SET_NEXT_PAGE, nextpagePayload);

          let totalDataPayload = {
            data: totalData,
            table: memberTable,
          };

          commit(types.SET_TOTAL_DATA, totalDataPayload);

          commit(types.GET__LIST__EMPLOYEES_MEMBERS, data.results);

          let totalPagesPayload = {
            totalData: totalData,
            table: memberTable,
            limit: limit,
          };
          commit(types.TOTAL_PAGES_DATA, totalPagesPayload);

          if (data.results <= 0) {
            // with this in case the data is 0 , we show a front message "No datos disponibles"
            commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          } else {
            commit(types.FLAG_DESACTIVE_RESULTS_EMPLOYEE);
          }
        })
        .then(() => {
          commit(types.LOADER_TABLE_OFF);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FLAG_ACTIVE_RESULTS_EMPLOYEE);
          // commit(types.FLAG_DESACTIVE_INPUT_SEARCH);
        });
    });
  },
  saveDataforDeleteThreeDots({ commit }, payload) {
    commit(types.ADD_DATA_FOR_DELETE_USER_THREE_DOTS, payload);
  },

  setActiveInputSearchUser({ commit } , data) {
    commit(types.FLAG_ACTIVE_INPUT_SEARCH_USER, data);
  },
  setInactiveInputSearchUser({ commit }) {
    commit(types.FLAG_INACTIVE_INPUT_SEARCH_USER);
  },
  setActiveInputSearchMember({ commit }, data) {
    commit(types.FLAG_ACTIVE_INPUT_SEARCH_MEMBER, data);
  },
  setInactiveInputSearchMember({ commit }) {
    commit(types.FLAG_INACTIVE_INPUT_SEARCH_MEMBER);
  },
};
