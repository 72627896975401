<template>
  <div class="container_basic_information_assistance m-top-s">
    <!-- <div class="tittle_description label-primary">Asignaciones</div> -->

    <div class="fix_assistance_style">
      <div class="label-primary">Solicitante</div>
      <!-- <div class="custom-select">
        <select
          class="input-form"
          v-model="requesterName"
          id="typeService"
          @change="setRequesterSelected($event)"
        >
          <option selected disabled hidden>
            {{ requesterName }}
          </option>

          <option
            :value="el.name"
            v-for="(el, index) in currentAssignedRequesterEmployees"
            :key="index"
          >
            {{ el.name }}
          </option>
        </select>
        <span class="custom-arrow-b"></span>
      </div> -->

      <div class="custom-select">
        <input
          list="clientRequester"
          @change="setRequesterSelected($event)"
          class="input-form input__dalalist"
          :placeholder="requesterName"
        />

        <datalist id="clientRequester">
          <option
            v-for="(el, index) in currentAssignedRequesterEmployees"
            :key="index"
            :value="el.name"
          >
            {{ el.user.email }}
          </option>
        </datalist>
        <span class="custom-arrow-b"></span>
      </div>
    </div>
    <div class="fix_assistance_style">
      <div class="label-primary">Encargado de solicitud</div>
      <!-- <div class="custom-select">
        <select
          class="select_field input-form"
          v-model="inChargeQuotation"
          id="typeService"
          @change="setInchargeSelected($event)"
        >
          <option selected disabled hidden>
            {{ inChargeQuotation }}
          </option>
          <option
            :value="el.name"
            v-for="(el, index) in currentAssignedAdminEmployees"
            :key="index"
          >
            {{ el.name }}
          </option>
        </select>
        <span class="custom-arrow-b"></span>
      </div> -->
      <div class="custom-select">
        <input
          list="adminInCharge"
          @change="setInchargeSelected($event)"
          class="input-form input__dalalist"
          :placeholder="inChargeQuotation"
        />

        <datalist id="adminInCharge">
          <option
            v-for="(el, index) in currentAssignedAdminEmployees"
            :key="index"
            :value="el.name"
          >
            {{ el.user.email }}
          </option>
        </datalist>
        <span class="custom-arrow-b"></span>
      </div>
    </div>
    <div class="fix_assistance_style">
      <div class="label-primary">Coordinador de solicitud</div>
      <!-- <div class="custom-select">
        <select
          class="select_field input-form"
          v-model="coordinatorQuotation"
          id="typeService"
          @change="setCoordinatorSelected($event)"
        >
          <option selected disabled hidden>
            {{ coordinatorQuotation }}
          </option>
          <option
            v-for="(el, index) in currentAssignedAdminEmployees"
            :key="index"
            :value="el.name"
          >
            {{ el.name }}
          </option>
        </select>
        <span class="custom-arrow-b"></span>
      </div> -->
      <div class="custom-select">
        <input
          list="adminCordinator"
          @change="setCoordinatorSelected($event)"
          class="input-form input__dalalist"
          :placeholder="coordinatorQuotation"
        />

        <datalist id="adminCordinator">
          <option
            v-for="(el, index) in currentAssignedAdminEmployees"
            :key="index"
            :value="el.name"
          >
            {{ el.user.email }}
          </option>
        </datalist>
        <span class="custom-arrow-b"></span>
      </div>
    </div>

    <div class="footer__edition">
      <button
        @click="editParticipantsQuotation()"
        :class="
          flagEditLawyer || flagEditRequester || flagEditCoordinator
            ? 'btn-vue-primary fix-btn-mac button_verification_quotation'
            : 'desactive_draf_settings fix-btn-mac button_verification_quotation'
        "
      >
        <span class="f-14" v-if="!loaderActiveButtonEdit"> Aceptar </span>
        <div class="loader_button_quotation" v-else></div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  created() {
    if (this.attendantAssigned != null) {
      this.coordinatorQuotation = this.attendantAssigned.name;
      this.coordinatorQuotationForService = this.attendantAssigned.id;
    } else {
      this.coordinatorQuotation = "Seleccionar coordinador de la solicitud";
    }
    if (this.lawyerAssigned != null) {
      this.inChargeQuotation = this.lawyerAssigned.name;
      this.inChargeQuotationForService = this.lawyerAssigned.id;
    } else {
      this.inChargeQuotation = "Seleccionar encargado de la solicitud";
    }
    if (this.requesterAssigned != null) {
      this.requesterName = this.requesterAssigned.name;
      this.requesterNameForService = this.requesterAssigned.id;
    } else {
      this.inChargeQuotation = "Seleccionar solicitante";
    }
  },
  data: () => {
    return {
      requesterName: null,
      requesterNameForService: null,
      inChargeQuotation: null,
      inChargeQuotationForService: null,
      coordinatorQuotation: null,
      coordinatorQuotationForService: null,
      flagEditLawyer: false,
      flagEditRequester: false,
      flagEditCoordinator: false,
      loaderActiveButtonEdit: false,
    };
  },
  computed: {
    ...mapGetters("Assistance", {
      currentAssignedAdminEmployees: "currentAssignedAdminEmployees",
      currentAssignedRequesterEmployees: "currentAssignedRequesterEmployees",
      attendantAssigned: "attendantAssigned",
      lawyerAssigned: "lawyerAssigned",
      requesterAssigned: "requesterAssigned",
      currentSingleAssistanceBaseInformationID:
        "currentSingleAssistanceBaseInformationID",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapState({
      company: (state) => state.Login.company,
    }),
  },
  methods: {
    setRequesterSelected(e) {
      let name_selected = e.target.value;

      let requesterSelected = this.currentAssignedRequesterEmployees.filter(
        (el) => el.name == name_selected
      );
      if (requesterSelected.length > 0) {
        let tempData = requesterSelected[0].id;

        this.requesterNameForService = tempData;

        if (this.requesterAssigned != null) {
          if (name_selected == this.requesterAssigned.name) {
            this.flagEditRequester = false;
          } else {
            this.flagEditRequester = true;
          }
        } else {
          this.flagEditRequester = true;
        }
      }
    },
    setInchargeSelected(e) {
      let name__user = e.target.value;
      let inChargeSelected = this.currentAssignedAdminEmployees.filter(
        (el) => el.name == name__user
      );
      if (inChargeSelected.length > 0) {
        let tempData = inChargeSelected[0].id;
        this.inChargeQuotationForService = tempData;

        if (this.lawyerAssigned != null) {
          if (name__user == this.lawyerAssigned.name) {
            this.flagEditLawyer = false;
          } else {
            this.flagEditLawyer = true;
          }
        } else {
          this.flagEditLawyer = true;
        }
      }
    },
    setCoordinatorSelected(e) {
      let name__user = e.target.value;
      let CoordinatorSelected = this.currentAssignedAdminEmployees.filter(
        (el) => el.name == name__user
      );
      if (CoordinatorSelected.length > 0) {
        let tempData = CoordinatorSelected[0].id;

        this.coordinatorQuotationForService = tempData;

        if (this.attendantAssigned != null) {
          if (name__user == this.attendantAssigned.name) {
            this.flagEditCoordinator = false;
          } else {
            this.flagEditCoordinator = true;
          }
        } else {
          this.flagEditCoordinator = true;
        }
      }
    },
    editParticipantsQuotation() {
      // console.log(this.requesterNameForService);
      // console.log(this.inChargeQuotationForService);
      // console.log(this.coordinatorQuotationForService);
      this.loaderActiveButtonEdit = true;
      var formData = new FormData();

      if (this.requesterNameForService != null) {
        formData.append("requester_id", this.requesterNameForService);
      }
      if (this.inChargeQuotationForService != null) {
        formData.append("lawyer_id", this.inChargeQuotationForService);
      }
      if (this.coordinatorQuotationForService != null) {
        formData.append("attendant_id", this.coordinatorQuotationForService);
      }

      let id_request = this.currentSingleAssistanceBaseInformationID;

      let admin_company_id = this.inSwitch ? this.idInSwitch : this.company.id;

      let payload = {
        admin_company_id: admin_company_id,
        formDataFields: formData,
        id_request: id_request,
      };

      this.$store
        .dispatch("Assistance/editEmployeesAsigned", payload)
        .then(() => {
          let payloadReloadData = {
            admin_company_id: admin_company_id,
            id_assitance: id_request,
          };
          let message = `Usuario asignado correctamente`;
          this.$toasted.success(message);
          this.loaderActiveButtonEdit = false;
          this.flagEditLawyer = false;
          this.flagEditRequester = false;
          this.flagEditCoordinator = false;
          setTimeout(() => {
            this.$store.dispatch(
              "Assistance/getSingleAssitance",
              payloadReloadData
            );
          }, 500);
        });
    },
  },
  watch: {
    requesterAssigned: {
      handler: function (newVal) {
        if (newVal) {
          this.requesterName = this.requesterAssigned.name;
        }
      },
      deep: true,
    },
    lawyerAssigned: {
      handler: function (newVal) {
        if (newVal) {
          this.inChargeQuotation = this.lawyerAssigned.name;
        }
      },
      deep: true,
    },
    attendantAssigned: {
      handler: function (newVal) {
        if (newVal) {
          this.coordinatorQuotation = this.attendantAssigned.name;
        }
      },
      deep: true,
    },

    // this renovate the tags array
  },
};
</script>

<style lang="scss" scoped>
.button_verification_quotation {
  // justify-content: center;
  // display: flex;
  // align-items: center;

  height: 35px !important;
  width: 102px !important;
}

input::placeholder {
  font-size: 17px !important;
}
</style>
