import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import VueMoment from "vue-moment";
import routes from "./routes";
import Toasted from "vue-toasted";
import { BootstrapVue } from "bootstrap-vue";

import oauthValidator, { excludedRoutes } from "@/utils/oauth-guard";

import "moment/locale/es";

moment.locale("es");

import VueClipboard from "vue-clipboard2";
import VueSkeletonLoader from "skeleton-loader-vue";

import Default from "./layout/default";
import Login from "./layout/login";
import Welcome from "./layout/welcome";

// add boostrap styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Vuex from "vuex";
import RootStore from "./store/index.js";

import { API } from "aws-amplify";
import awsconfig from "./aws-exports";

API.configure(awsconfig);

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
Vue.component("vue-skeleton-loader", VueSkeletonLoader);
Vue.use(VueMoment, { moment });
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(Toasted, {
  position: "bottom-center",
  duration: 5000,
  theme: "outline",
  action: {
    text: "x",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
Vue.component("default-layout", Default);
Vue.component("login-layout", Login);
Vue.component("welcome-layout", Welcome);

Vue.config.productionTip = false;
import apolloProvider from "@/AppSync";


const router = new VueRouter({
  routes,
  mode: "history",
});
const store = new Vuex.Store(RootStore);

router.beforeEach((to, from, next) => {
  //this evaluate only the routes there are not exclude (logins route)
  if (!excludedRoutes.includes(to.name)) {

    // in case the route have to be evaluate , we run the function "oauthValidator"
    oauthValidator(to, from, next, store);
  } else {
    // in the case the route is exclude from evalutate of routes , the user can keep navigating
    next();
  }
});


new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
