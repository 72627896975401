export default {
  applyLightmode() {
    document.documentElement.style.setProperty("--container_module", "#f8f8f8");
    // common scss
    document.documentElement.style.setProperty(
      "--footer_information",
      "#FFF9E3"
    );
    document.documentElement.style.setProperty(
      "--arrow_icon",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--filter_icon_employee",
      "#0064ff"
    );
    document.documentElement.style.setProperty(
      "--input_border_focus",
      "#0D6EFD"
    );
    document.documentElement.style.setProperty(
      "--input_border_normal",
      "#ced4da"
    );
    document.documentElement.style.setProperty("--line_primary", "#000032");
    document.documentElement.style.setProperty("--container_base", "#ffffff");
    document.documentElement.style.setProperty(
      "--input_focus_background",
      "#fff"
    );
    document.documentElement.style.setProperty(
      "--input_color_placeholder",
      "gray"
    );
    document.documentElement.style.setProperty("--input_text_color", "#212529");
    document.documentElement.style.setProperty("--text_area_focus", "#86b7fe");
    document.documentElement.style.setProperty("--icon_filter", "#000032");

    //main-header
    document.documentElement.style.setProperty("--base-header", "white");
    document.documentElement.style.setProperty("--menu-header", "white");
    document.documentElement.style.setProperty(
      "--header__logo__title",
      "#1f2f59ed"
    );
    document.documentElement.style.setProperty(
      "--header__svg__icon",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--header__svg__icon_hover",
      "#e4e4e4"
    );
    document.documentElement.style.setProperty(
      "--header__company__name",
      "#0064ff"
    );

    //sidebar
    document.documentElement.style.setProperty("--base-sidebar", "#000032");
    document.documentElement.style.setProperty("--nav-active", "#1f2f59ed");
    document.documentElement.style.setProperty("--burger-header", "#212121");
    document.documentElement.style.setProperty(
      "--base-title-header",
      "#000032"
    );
    document.documentElement.style.setProperty("--name-user-header", "#000032");

    // base body
    document.documentElement.style.setProperty("--main-color", "#f8f8f8");
    //module home
    document.documentElement.style.setProperty("--value_cards", "#808099");
    document.documentElement.style.setProperty("--base-cards", "white");
    document.documentElement.style.setProperty("--title-card", "#1f2f59");
    document.documentElement.style.setProperty("--title-grap", "#1f2f59");
    document.documentElement.style.setProperty("--month-text", "#1f2f59");
    document.documentElement.style.setProperty("--user-text", "#1f2f59");
    document.documentElement.style.setProperty("--card-blue", "#bfd8ff");
    document.documentElement.style.setProperty("--sub-title-user", "#464646");
    document.documentElement.style.setProperty("--utility__title", "#1f2f59");
    document.documentElement.style.setProperty("--text-container", "#1f2f59");
    document.documentElement.style.setProperty(
      "--base-sub-title-card",
      "#808099"
    );
    document.documentElement.style.setProperty("--text-link", "#000032");

    //settings
    document.documentElement.style.setProperty(
      "--bg-active_change_edit",
      "#000032"
    );
    document.documentElement.style.setProperty("--item_label", "#555555");
    document.documentElement.style.setProperty("--base-settings", "#ffffff");
    document.documentElement.style.setProperty(
      "--title_active_settings",
      "#e4e4e4"
    );
    document.documentElement.style.setProperty(
      "--title_desactive_settings",
      "#464646"
    );
    document.documentElement.style.setProperty(
      "--fill__svg_sidebar",
      "#464646"
    );
    document.documentElement.style.setProperty(
      "--container__simple__label",
      "#fff"
    );
    document.documentElement.style.setProperty(
      "--title__simple__label",
      "#464646"
    );
    document.documentElement.style.setProperty("--title__labels", "#464646");
    document.documentElement.style.setProperty("--bg__inputs", "#f8f8f8");
    document.documentElement.style.setProperty(
      "--button-active-confirm",
      "white"
    );
    document.documentElement.style.setProperty(
      "--button-active-confirm_edit",
      "#0064FF"
    );
    document.documentElement.style.setProperty(
      "--title__inside__breadcrumb",
      "#464646"
    );

    // module documents
    document.documentElement.style.setProperty("--title__container", "#000032");
    document.documentElement.style.setProperty(
      "--call_to_action_button",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--title_link_header",
      "#0064ff"
    );
    document.documentElement.style.setProperty(
      "--title_secondary_link",
      "#0064ff"
    );
    document.documentElement.style.setProperty("--title_link", "#0064ff");
    document.documentElement.style.setProperty(
      "--description_text_document",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--input_search_document",
      "white"
    );
    document.documentElement.style.setProperty(
      "--title_request_document",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--sub_title_request_docuemnt",
      "#000032"
    );

    //module company
    document.documentElement.style.setProperty(
      "--svg_company_navbar",
      "#000032"
    );
    document.documentElement.style.setProperty("--table_list_company", "white");

    //module report
    document.documentElement.style.setProperty(
      "--title_container_report",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--text_item_selection",
      "#464646"
    );
    document.documentElement.style.setProperty("--subtitle_report", "#000032");
    document.documentElement.style.setProperty(
      "--subtitle_report_option_border",
      "rgb(0, 100, 255)"
    );
    document.documentElement.style.setProperty(
      "--subtitle_report_option",
      "#000032"
    );
    document.documentElement.style.setProperty("--date_text_report", "#000032");

    //module company
    document.documentElement.style.setProperty(
      "--title_container_company",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--text_item_selection_company",
      "#000032"
    );
    document.documentElement.style.setProperty("--svg_company", "#000032");
    document.documentElement.style.setProperty(
      "--text_link_company",
      "#000032"
    );
    document.documentElement.style.setProperty("--subtitle_company", "#000032");
    document.documentElement.style.setProperty(
      "--text_container_modal",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--text_suboption_company",
      "#555555"
    );
    document.documentElement.style.setProperty(
      "--border_select_company",
      "#0064ff"
    );
    document.documentElement.style.setProperty("--svg_company_key", "white");
    document.documentElement.style.setProperty(
      "--svg_company_circle",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--svg_company_pagination",
      "black"
    );
    document.documentElement.style.setProperty(
      "--title_container_card_company",
      "#464646"
    );
    //module employee
    document.documentElement.style.setProperty("--cancel-button", "#000032");
    document.documentElement.style.setProperty(
      "--button_verify_employee",
      "#fff"
    );
    document.documentElement.style.setProperty(
      "--text_documennt_active",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--text_table_employee",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--check_icon_employee",
      "rgba(0, 0, 0, 0.54)"
    );
    document.documentElement.style.setProperty(
      "--focus_table_employee",
      "#f5f5f5"
    );
    //module quotation
    document.documentElement.style.setProperty("--title_quotation", "#000032");
    document.documentElement.style.setProperty("--text_quotation", "#000032");

    //module profile
    document.documentElement.style.setProperty("--title_profile", "#000032");
    document.documentElement.style.setProperty("--text_profile", "#000032");
    document.documentElement.style.setProperty("--button_profile", "#fff");
    document.documentElement.style.setProperty("--cancel_icon", "black");

    //digital signature

    document.documentElement.style.setProperty(
      "--background_sign_selection",
      "#000032"
    );

    document.documentElement.style.setProperty(
      "--text_documents_aler",
      "black"
    );
    document.documentElement.style.setProperty(
      "--container_pre_step",
      "#ffffff"
    );
    document.documentElement.style.setProperty(
      "--card_btn_pre_step",
      "#ffffff"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_pre_step",
      "rgba(0, 0, 0, 0.6)"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_pre_step_user",
      "#0064FF"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_hover_pre_step",
      "white"
    );
    document.documentElement.style.setProperty(
      "--text_card_btn_hover_pre_step_invert",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--border_card_btn_hover_pre_step",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--border_card_btn_hover_pre_step_user",
      "#0064FF"
    );
    document.documentElement.style.setProperty("--svg_pre_step", "#000032");
    document.documentElement.style.setProperty("--title_pre_step", "#000032");
    document.documentElement.style.setProperty("--title_origin", "#000032");
    document.documentElement.style.setProperty(
      "--link_origin_document",
      "#ffffff"
    );
    document.documentElement.style.setProperty(
      "--border_link_origin_document",
      "#9c9c9c"
    );
    document.documentElement.style.setProperty(
      "--text_link_origin_document",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--title_sing_from_app",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--sub_title_sing_from_app",
      "#464646"
    );
    document.documentElement.style.setProperty(
      "--head_table_dsignature",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--content_table_dsignature",
      "#000032"
    );
    document.documentElement.style.setProperty("--header_table_doc", "#000032");
    document.documentElement.style.setProperty(
      "--bg_table_select_doc",
      "#f8f8f8"
    );
    document.documentElement.style.setProperty(
      "--bg_table_header_select_doc",
      "#f8f8f8"
    );
    document.documentElement.style.setProperty("--border_table_doc", "#a5a5a5");

    // calendar boostrap
    document.documentElement.style.setProperty(
      "--bg_calendar_boostrap_secondary",
      "white"
    );
    document.documentElement.style.setProperty("--days_calendar", "#000032");
    document.documentElement.style.setProperty("--weekdays", "#000032");
    document.documentElement.style.setProperty("--month_calendar", "#000032");
    document.documentElement.style.setProperty(
      "--text_label_calendar",
      "#6c757d"
    );
    document.documentElement.style.setProperty(
      "--bg_calendar_boostrap",
      "white"
    );
    document.documentElement.style.setProperty(
      "--background_desactive_signature",
      "#f8f8f8"
    );
    document.documentElement.style.setProperty(
      "--bg_desactive_sing",
      "#ffffff"
    );
    document.documentElement.style.setProperty("--bg_active_sing", "#ffffff");
    document.documentElement.style.setProperty(
      "--bg_desactive_sing",
      "#ffffff"
    );
    document.documentElement.style.setProperty(
      "--bg_active_sing_border",
      "#212121"
    );
    document.documentElement.style.setProperty(
      "--sub_title_selected_sing",
      "#464646"
    );
    document.documentElement.style.setProperty(
      "--svg_confirm_stroke",
      "#000032"
    );
    document.documentElement.style.setProperty("--svg_confirm_fill", "#000032");
    document.documentElement.style.setProperty("--active_set_draf", "#000032");
    document.documentElement.style.setProperty("--title_modal", "#000032");

    document.documentElement.style.setProperty(
      "--bg_active_set_draf",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--bg_active_delete_draf",
      "white"
    );

    document.documentElement.style.setProperty("--disable_input_text", "gray");

    // module schedule

    document.documentElement.style.setProperty("--title_schedule", "#000032");
    document.documentElement.style.setProperty("--icon_background", "white");
    document.documentElement.style.setProperty(
      "--schedule_background",
      "white"
    );
    document.documentElement.style.setProperty(
      "--icon_color_schedule",
      "black"
    );
    document.documentElement.style.setProperty("--text_schedule", "#000032");
    document.documentElement.style.setProperty(
      "--border_schedule_cards",
      "#b9b9b9"
    );
    document.documentElement.style.setProperty(
      "--hover_schedule_cards",
      "#f8f8f8"
    );
    document.documentElement.style.setProperty("--links_schedule", "#0064ff");
    document.documentElement.style.setProperty(
      "--links_my_schedule",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--background_navbar_schedule",
      "#000032"
    );
    document.documentElement.style.setProperty(
      "--info_meeting_title_schedule",
      "#464646"
    );
    document.documentElement.style.setProperty(
      "--info_meeting_schedule",
      "#464646"
    );
    document.documentElement.style.setProperty(
      "--arrow_down_schedule",
      "black"
    );
    document.documentElement.style.setProperty(
      "--navbar_text_schedule",
      "white"
    );
    document.documentElement.style.setProperty(
      "--number_card_schedule",
      "white"
    );
    document.documentElement.style.setProperty(
      "--schedule_background_navbar",
      "#000032"
    );
    //draft doc
    document.documentElement.style.setProperty(
      "--text-document-draf",
      "#000032"
    );
    document.documentElement.style.setProperty("--bg-selected-draf", "#bfd8ff");
    document.documentElement.style.setProperty(
      "--text_documennt_active",
      "#000032"
    );
    //button signer
    document.documentElement.style.setProperty(
      "--button_add__signer",
      "white"
    );
  },
};
