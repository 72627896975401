export default {
  // para definir vista principal en condiguraciones regionales
  RouterViewSettingRegional: {
    default__view__settings__regional: true,
    view__setting_document_region: false,
    view__setting_document_role: false,
    view__setting_report__limitation: false,
  },

  countryCode: null,

  //guardar los valores de los cambios no reactivos de la pestaña configuracion regional
  // en las vistas internas de cada una de las selecciones de configuraciones regioanles definimos un estado boolean para ver si hay cambios que guardar.
  SaveValidRegionalSettings: {
    documentTypeRegionalInstanceChange: false,
    documentTypeRoleInstanceChange: false,
    limitUseInstanceChange: false,
  },

  //-------------------------------
  regionalSettingsInstance: null,
  defaultReportLimit: [],


  //---------------------------------
  tenantSettingsInstance: null,
  countryRegionalSettings: "",
  //list group id from services list group
  listGroup: null,
  noHasPermission: false,
  //id of goup selected in input group
  documentTypeRolIdSelected: null,


  // view: document type perm by rol
  defaultDocumentTypePermByRol: [],
  groupIdforDocumentTypePermByRol: null,
  // almacenar valores a enviar al servicio para document type perm by rol
  documentTypeByPermByRolValues: [],



  defaultListDocumentTypeByregion: [],
  //Almacen de valores a enviar a servicio para editar
  documentTypeByRegionValues: [],

  // end state for regional settings
  //---------------------------------------------------------------------------

  //guardar los valores de los cambios no reactivos de la pestaña configuracion empresa
  SaveValidCompanySettings: {},

  //guardar los valores de los cambios no reactivos de la pestaña configuracion  usuario
  UserSettingsInstance: null,
  userLenguageInstanceChange: false,
  userDarkModeInstanceChange: false,

  //campos para categorias iniciales
  categoryConfigForTemplate: null,
  failureCategoryInitialSettings: false,
};
//settingRegional
