<template>
  <div class="container__assistance">
    <div class="container__assistance__left">
      <div class="title__assistance">
        <VueSkeletonLoader
          type="rect"
          :width="165"
          :height="15"
          animation="fade"
        />
      </div>
      <div class="base__information">
        <div class="base__information__labels">
          <div class="label-primary">
            <VueSkeletonLoader
              type="rect"
              :width="110"
              :height="15"
              animation="fade"
            />
          </div>
          <div class="value-primary">
            <VueSkeletonLoader
              type="rect"
              :width="180"
              :height="15"
              animation="fade"
            />
          </div>
        </div>
        <div class="base__information__labels">
          <div class="label-primary">
            <VueSkeletonLoader
              type="rect"
              :width="110"
              :height="15"
              animation="fade"
            />
          </div>
          <div class="value-primary">
            <VueSkeletonLoader
              type="rect"
              :width="180"
              :height="15"
              animation="fade"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container__assistance__right">
      <div class="number__case__skeleton">
        <div class="number__case__title">
          <VueSkeletonLoader
            type="rect"
            :width="40"
            :height="15"
            animation="fade"
          />
        </div>
        <div class="">
          <VueSkeletonLoader
            type="rect"
            :width="40"
            :height="15"
            animation="fade"
          />
        </div>
      </div>

      <div class="container_options container_navbar">
        <VueSkeletonLoader
          type="circle"
          :width="40"
          :height="40"
          animation="fade"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  components: {
    VueSkeletonLoader,
  },
};
</script>

<style></style>
