import * as types from "./mutations-types";
import settingService from "../services/SettingService";

export default {
  //for active view's inside regional settings this way we implement multiple active views
  // Set view for regional settings and instances inside parent view (default_view as 0)
  // params = 1 is for limitation report use view
  // params = 2  active/inactive document-type for region
  // params = 3 documentType rol view

  //--------------------------------
  setViewRegionSettings({ commit }, param) {
    commit(types.SET__ACTIVE_VIEW__REGIONAL__SETTINGS, param);
  },

  // Set active category elements for template category settings.
  setCategoryForTemplate({ commit }, data) {
    commit(types.SET_CATEGORY_ACTIVE_TEMPLATE, data);
  },
  setCountryCodeForRegionSettings({ commit }, countryCode) {
    commit(types.SET_COUNTRY_CODE, countryCode);
  },
  // end actions set view regional settings
  // ----------------------------------------------------

  // Set view for retrive settings for user, tenant, regional
  getRetriveSettings({ commit }, { client_company_id, countryCode }) {
    return new Promise((resolve, reject) => {
      settingService
        .getRetriveSettings(client_company_id, countryCode)
        .then((data) => {
          commit(types.SET_COUNTRY_CODE, countryCode);
          commit(types.GET_RETRIVE_SETTINGS, data);
          // apply initial values for user settings instances
          if (data[0].name == "User") {
            let userInstanceConfigTemp =
              data[0].setting_types != null ? data[0].setting_types : null;
            commit(types.SET_USER_SETTING_INSTANCE, userInstanceConfigTemp);
          }

          if (data[1].name == "Tenant") {
            let tenantInstanceConfigTemp =
              data[1].setting_types != null ? data[1].setting_types : null;

            commit(types.SET_TENANT_SETTING_INSTANCE, tenantInstanceConfigTemp);
          }

          if (data[2].name == "Region") {
            let tenantInstanceConfigTemp =
              data[2].setting_types != null ? data[2].setting_types : null;

            commit(types.SET_REGION_SETTING_INSTANCE, tenantInstanceConfigTemp);
          }

          //TODO: apply initial values for regional settings instances
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit(types.FAILURE_RETRIVE_SETTINGS);
        });
    });
  },
  // end actions set view retrive settings
  // ----------------------------------------------------

  //--------------------------------
  // @Input: params are new values
  // Description: persist new values if user set a new one for lenguage settings
  // @Output: false or id/value of new settings for lenguage
  //--------------------------------
  activeChangeUserLenguajeInstance({ commit }, params) {
    commit(types.SET_LENGUAGE_VALID_CHANGE, params);
  },
  //--------------------------------
  // @Input: params are new values
  // Description: persist new values if user set a new one for darkmode settings
  // @Output: false or id/value of new settings for darkmode
  //--------------------------------
  activeChangeUserDarkModeInstance({ commit }, params) {
    commit(types.SET_DARKMODE_VALID_CHANGE, params);
  },
  // ----------------------------------------------------
  // retrive settings instance to get new values before changes added
  //--------------------------------
  // @Input: cliente_id, scope is related to 0 for user, 1 for company, 2 for regional
  // Description: get specific instances of settings according to scope selected for retrive new values
  // @Output: instances of scope sended
  //--------------------------------
  getSettingsInstances({ commit }, { client_company_id, scope, country }) {
    return new Promise((resolve, reject) => {
      settingService
        .settingsInstance(client_company_id, scope, country)
        .then((data) => {
          if (scope == 0) {
            // scope 0 correspond to user settings instances set
            let userInstanceConfigTemp =
              data[0].setting_types != null ? data[0].setting_types : null;
            commit(types.SET_USER_SETTING_INSTANCE, userInstanceConfigTemp);
          } else if (scope == 1) {
            // scope 1 correspond to tenant settings instances set
            //TODO COMMIT SETTINGS TENANT INSTANCES
          } else if (scope == 2) {
            // scope 1 correspond to regional settings instances set
            //TODO COMMIT SETTINGS REGIONAL TENANT INSTANCES
            let tenantInstanceConfigTemp =
              data[0].setting_types != null ? data[0].setting_types : null;

            commit(types.SET_REGION_SETTING_INSTANCE, tenantInstanceConfigTemp);
          }

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // end-retrive user settings instance
  // ----------------------------------------------------

  //get list groups for bind document type to rol
  getGroups({ commit }, client_company_id) {
    return new Promise((resolve, reject) => {
      settingService
        .getListGroups(client_company_id)
        .then((data) => {
          commit(types.SET_LIST_GROUP, data.results);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // ----------------------------------------------------
  // end-retrive user settings instance

  //get document type perm by rol
  listDocumentTypePermByRol(
    { commit },
    { admin_company_id, employee_group_id, country = "CL" }
  ) {
    return new Promise((resolve, reject) => {
      settingService
        .getDocumentTypePermByRol(admin_company_id, employee_group_id, country)
        .then((data) => {
          commit(types.SET_DOCUMENT_TYPE_PERM_BY_ROL, data);
          commit(
            types.SET_ID_GROUP_DOCUMENT_TYPE_PERM_BY_ROL,
            employee_group_id
          );
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  activeChangeLimitUseInstance({ commit }, params) {
    commit(types.SET_REPORT_LIMIT_USE_VALID_CHANGE, params);
  },
  cleanChangeLimitUseInstance({ commit }) {
    commit(types.CLEAN_LIMIT_USE_VALID_CHANGE);
  },
  cleanDefaultValuesForDocumentTypeByRegion({ commit }) {
    commit(types.CLEAN_DEFAULT_DOCUMENT_TYPE_BY_REGION);
  },
  setDefaultDocumentTypeByRegion({ commit }, params) {
    commit(types.SET_DEFAULT_DOCUMENT_TYPE_BY_REGION, params);
  },
  addElementDocumentByRegion({ commit }, data) {
    commit(types.ADD_ELEMENT_DOCUMENT_TYPE_BY_REGION, data);
  },
  //--------------------------------
  // @Input: object with value from checkbox
  // Description: push value from checkbox to state.documentTypeByPermByRolValues only witch valid changes
  // @Output: documentTypeByPermByRolValues changes
  //--------------------------------
  addDocumentTypePermByRolChanges({ commit }, data) {
    commit(types.ADD_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL, data);
  },
  clearElementDocumentByRegion({ commit }) {
    commit(types.CLEAR_ELEMENT_DOCUMENT_TYPE_BY_REGION);
  },
  clearElementDocumentPermByRol({ commit }) {
    commit(types.CLEAR_ELEMENT_DOCUMENT_TYPE_PERM_BY_ROL);
  },
};
