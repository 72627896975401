<template>
  <div class="loader_container">
    <div class="loader_MA_left"></div>

    <div class="loader_MA_Circle"></div>
    <div class="loader_MA_rigth"></div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  created() {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss">
.loader_container {
  align-content: center;
  align-items: center;
  height: 22px;
  width: 90px;
  position: relative;
  margin: auto;
}

.loader_MA_Circle {
  left: calc(100% - 10px);
  top: -5px;
  width: 13px;
  height: 13px;

  border-radius: 100%;
  background: #619aff;
  position: absolute;
  animation: bounceXL 1.7s infinite;
  animation-timing-function: ease;
}
.loader_MA_rigth {
  width: 59px;
  height: 14px;

  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background: #0064ff;
  position: absolute;
  animation: move-right 1.7s infinite;
}
.loader_MA_left {
  width: 59px;
  height: 14px;

  top: 100%;
  left: 45%;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background: #0064ff;
  position: absolute;
  animation: move-left 1.7s infinite;
}

@keyframes bouncex {
  0% {
    height: 13px;
    width: 11px;
  }

  10% {
    height: 13px;
    width: 11px;
  }
  30% {
    height: 11px;
    width: 13px;
    transform: translateY(20px);
  }
  50% {
    height: 11px;
    width: 13px;
    transform: translateY(20px);
  }
  60% {
    height: 13px;
    width: 11px;
  }

  80% {
    height: 11px;
    width: 13px;
    transform: translateY(0px);
  }

  100% {
    height: 11px;
    width: 13px;
    transform: translateY(0px);
  }
}
@keyframes bounceXL {
  0% {
    height: 22px;
    width: 18px;
  }

  10% {
    height: 22px;
    width: 18px;
  }
  30% {
    height: 18px;
    width: 22px;
    transform: translateY(26px);
  }
  50% {
    height: 18px;
    width: 22px;
    transform: translateY(26px);
  }
  60% {
    height: 22px;
    width: 18px;
  }

  80% {
    height: 18px;
    width: 22px;
    transform: translateY(0px);
  }

  100% {
    height: 18px;
    width: 22px;
    transform: translateY(0px);
  }
}

@keyframes move-left {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-60%);
  }
  50% {
    transform: translateX(-60%);
  }
  80% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes move-right {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(60%);
  }
  50% {
    transform: translateX(60%);
  }
  80% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
