var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog',{ref:"modal__onboarding__assistance",class:_vm.size == 'xl'
      ? 'modal__xl'
      : _vm.size == 'm'
      ? 'modal__m'
      : _vm.size == 's'
      ? 'modal__s'
      : _vm.size == 'l'
      ? 'modal__l'
      : _vm.size == 'onboarding'
      ? 'modal__onboarding'
      : _vm.size == 'ls'
      ? 'modal__ls'
      : 'modal__default',attrs:{"id":"modal__onboarding__assistance"}},[_c('div',{},[_c('div',{},[(_vm.stepAssistanceOnBoarding == 1)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnBoardingFirstStepAssistance')],1):_vm._e(),(_vm.stepAssistanceOnBoarding == 2)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnBoardingSecondStepAssistance')],1):_vm._e(),(_vm.stepAssistanceOnBoarding == 3)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnBoardingThirdStepAssistance')],1):_vm._e(),(_vm.stepAssistanceOnBoarding == 4)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnBoardingFourthStepAssistance')],1):_vm._e(),(_vm.stepAssistanceOnBoarding == 5)?_c('Transition',{attrs:{"name":"fade"}},[_c('OnboardingFifthStepAssistance')],1):_vm._e()],1),_c('div',{staticClass:"container_footer_onboarding"},[_c('div',{staticClass:"container_elements_footer"},[_c('div',{staticClass:"footer_modal footer__modal__cancel footer__modal__fix_onboarding"},[_c('div',{staticClass:"cancel_button_onboarding",attrs:{"disabled":"disabled"},on:{"click":function($event){return _vm.cancelModal()}}},[_c('span',[_vm._v(" Omitir")])]),_c('div',{class:_vm.stepAssistanceOnBoarding == 5
                ? 'button_step_fourth '
                : 'fix-btn-mac button_onboarding_accept',on:{"click":function($event){return _vm.AcceptOnBoarding()}}},[(_vm.stepAssistanceOnBoarding == 1)?_c('span',[_vm._v(" Comenzar ")]):_vm._e(),(
                _vm.stepAssistanceOnBoarding == 2 ||
                  _vm.stepAssistanceOnBoarding == 3 ||
                  _vm.stepAssistanceOnBoarding == 4
              )?_c('span',[_vm._v(" Siguiente ")]):_vm._e(),(_vm.stepAssistanceOnBoarding == 5)?_c('span',[_vm._v(" Crea tu primera solicitud ")]):_vm._e()])]),_c('div',{staticClass:"container__progressBar__onboarding"},[_c('div',{class:_vm.stepAssistanceOnBoarding == 1
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(1)}}}),_c('div',{class:_vm.stepAssistanceOnBoarding == 2
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(2)}}}),_c('div',{class:_vm.stepAssistanceOnBoarding == 3
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(3)}}}),_c('div',{class:_vm.stepAssistanceOnBoarding == 4
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(4)}}}),_c('div',{class:_vm.stepAssistanceOnBoarding == 5
                ? 'active_progressbar_onboarding'
                : 'progressBar_onboarding',on:{"click":function($event){return _vm.goBack(5)}}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }